import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { useMessageNotificationStyles } from '..'
import { GlobalNotificationVariant } from '../types'

export interface MessageColorPickerProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    selectedColor: GlobalNotificationVariant
    onColorChange: (color: GlobalNotificationVariant) => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(1),
        },
        label: {
            fontWeight: theme.typography.fontWeightBold as number,
            color: theme.palette.grey[600],
            fontSize: 10,
        },
        item: {
            minWidth: 80,
            height: 40,
            borderRadius: 20,
            justifyContent: 'center',
            marginRight: theme.spacing(1),
            boxSizing: 'border-box',
            cursor: 'pointer',
            '&$selected': {
                outline: `3px solid ${theme.palette.grey[600]}`,
                'outline-offset': 2,
            },
        },
        selected: {},
    }),
    { name: 'MessageColorPicker' }
)

export const MessageColorPicker = (props: MessageColorPickerProps) => {
    const { selectedColor, onColorChange, ...other } = props
    const classes = useStyles()
    const notificationClasses = useMessageNotificationStyles()
    const colorVariant: GlobalNotificationVariant[] = ['blue', 'yellow', 'gray']
    return (
        <div {...other}>
            <Typography className={classes.label} variant="caption">
                Message color variant
            </Typography>
            <div className={classes.content}>
                {colorVariant.map((v) => (
                    <div
                        key={v}
                        className={clsx(
                            notificationClasses.root,
                            classes.item,
                            notificationClasses[v],
                            selectedColor === v && classes.selected
                        )}
                        onClick={() => onColorChange(v)}
                    >
                        {v}
                    </div>
                ))}
            </div>
        </div>
    )
}
