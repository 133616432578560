import { Grid, Input, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { Content } from '../../components'
import { RichTextEditor } from '../../Issue/SendMessage/components'
import { EditorSection } from './EditorSection'
import { getTemplate } from './getTemplate'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginTop: theme.spacing(2),
            width: '100%',
        },
    }),
    { name: 'EmailThemeEditor' }
)

export const EmailThemeEditor = () => {
    const classes = useStyles()
    const [__html, setHtml] = useState('')
    return (
        <Content
            coverBackground={false}
            title="Email templates editor"
            variant="medium"
        >
            <Typography variant="h5">Email templates editor</Typography>
            <Grid className={classes.root} container spacing={2}>
                <EditorSection title="Rich text editor">
                    <RichTextEditor
                        onStateChange={(state, html) => setHtml(html)}
                    />
                </EditorSection>
                <EditorSection title="Html code preview">
                    <Input
                        disableUnderline={true}
                        value={__html}
                        disabled
                        multiline
                        placeholder="Generated html..."
                    />
                </EditorSection>
                <EditorSection title="Email preview">
                    <iframe
                        style={{ width: '100%' }}
                        title="Email template preview"
                        srcDoc={getTemplate(__html)}
                        allowFullScreen={false}
                    />
                </EditorSection>
            </Grid>
        </Content>
    )
}

export default EmailThemeEditor
