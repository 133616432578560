export enum IssueStatuses {
    Empty = 0,
    New = 1,
    Draft = 2,
    Not_Assigned = 3,
    In_Process = 4,
    In_Process_Overdue = 5,
    In_Process_Verification = 11,
    In_Process_Verification_Overdue = 12,
    Resolved = 7,
    Completed = 8,
    Completed_Execution_Pending = 13,
}

export enum IssueRoles {
    Global_CCRP_Process_Owner = 1,
    Support_Desk = 2,
    Issue_Owner = 3,
    Issue_Owner_Supervisor = 4,
    Issue_Owner_Team = 5,
    Issue_Coordinator = 6,
    Resolution_Owner = 7,
    Resolution_Owner_Team = 8,
    Ru_Process_Owner = 10,
    Process_Owner = 16,
    Div_Process_Owner = 17,
    Bu_Process_Owner = 18,
    PG_Process_Owner = 19,
    Hub_Process_Owner = 20,
    Developer = 21,
    Action_Owner = 22,
    Action_Creator = 23,
}

export enum IssueActionsTypes {
    To_Submit = 0,
    To_Assign = 1,
    To_Resolve = 2,
    To_Complete = 3,
    To_Verify_Resolution = 4,
    Resolution_Rejected = 5,
    To_Submit_Returned_To_Draft = 6,
    To_Replace_Inactive_RO = 7,
    To_Replace_Inactive_IO = 8,
    To_Replace_Inactive_RU = 9,
    To_Assign_Updated_RU = 10,
    To_Resend_Closure_Confirmation = 11,
    To_Execute_Containment_Actions = 12,
    To_Complete_After_Customer_Execution = 13,
    Effectiveness_Sustainability_Check = 14,
    To_Verify_HI = 15,
}

export enum AttachmentTypes {
    Undefined = 1,
    Information = 2,
    Quickfix = 3,
    RCA = 4,
    Resolution = 5,
    Validation = 6,
    Acceptance = 7,
    Resolution_Rca = 8,
    Issue_Resolution = 9,
    Effectiveness = 10,
}

export enum IssueTypes {
    External,
    Internal,
    Internal_NC,
}

export enum QuickFixVariants {
    Create_RCA,
    Link_RCA,
    No_RCA,
}

export enum FailureModeValidation {
    None,
    SerialNo,
    ABBProject,
    PurchaseOrder,
    QuantityAndPurchase,
}

export enum EffectivenessTypes {
    YES = 1,
    NO = 2,
    NA = 3,
}

export enum TargetResolvingDateChangeReasons {
    MoreTimeRequired = 3,
    FurtherInformationRequired = 4,
    Other = 7,
}

export enum RcaReportTypes {
    Report8D,
    DMAIC,
    RCA_Tools,
    RcaTemplates,
}

export enum IssueCreationTypes {
    Unknown,
    Manual,
    SFDC,
    NPS,
}

export enum MessageTemplateType {
    System = 1,
    User = 2,
    Predefined = 3,
}

export enum IssueValuesKeys {
    IssueOwner = 'IssueOwner.Email',
    IssueOwnerTeamEmails = 'IssueOwnerTeamEmails',
    IssueOwnerSupervisorEmail = 'IssueOwnerSupervisorEmail',
    IssueOwnerSupervisor = 'IssueOwnerSupervisor',
    IssueType = 'IssueType',
    Customer = 'Customer.Guid',
    CustomerContact = 'CustomerContact.Email',
    Subject = 'Subject',
    Summary = 'Summary',
    WhatIsHappening = 'WhatIsHappening',
    WhyIsThisAProblem = 'WhyIsThisAProblem',
    HowDoWeKnowWeHaveAProblem = 'HowDoWeKnowWeHaveAProblem',
    WhereDoesDidProblemHappen = 'WhereDoesDidProblemHappen',
    WhenDoesDidTheProblemHappen = 'WhenDoesDidTheProblemHappen',
    WhatAreCustomerExpectations = 'WhatAreCustomerExpectations',
    IsContainmentActionRequired = 'IsContainmentActionRequired',
    ContainmentActionDeadlineDate = 'ContainmentActionDeadlineDate',
    ContainmentActionDescription = 'ContainmentActionDescription',
    WhoIsAffectedByTheProblem = 'WhoIsAffectedByTheProblem',
    HowManyDefectsFound = 'HowManyDefectsFound',
    IsHighImpact = 'HighImpact.IsHighImpact',
    IsHealthAndSafetyImpact = 'HighImpact.IsHealthAndSafetyImpact',
    IsABBOrCustomerReputationAtStake = 'HighImpact.IsABBOrCustomerReputationAtStake',
    IsNegativeBusinessImpactToCustomer = 'HighImpact.IsNegativeBusinessImpactToCustomer',
    IsNegativeBusinessImpactToABB = 'HighImpact.IsNegativeBusinessImpactToABB',
    IsNegativeImpactOnABBBrandImage = 'HighImpact.IsNegativeImpactOnABBBrandImage',
    IsPotentialToLossCustomer = 'HighImpact.IsPotentialToLossCustomer',
    IsPotentialSystemicIssuesOrProductRecall = 'HighImpact.IsPotentialSystemicIssuesOrProductRecall',
    ImpactReason = 'HighImpact.ImpactReason',
    PotentialBusinessImpact = 'HighImpact.PotentialBusinessImpact',
    IsRootCauseRequiredByCustomer = 'IsRootCauseRequiredByCustomer',
    TargetResolutionAndVerificationDate = 'TargetResolutionAndVerificationDate',
    TargetResolutionDateChangeReasonId = 'TargetResolutionDateChangeReasonId',
    TargetResolutionDateChangeReason = 'TargetResolutionDateChangeReason',
    ResolvingUnitId = 'ResolvingUnitId',
    ProposedResolutionOwner = 'ProposedResolutionOwner.Email',
    CoordinatorsEmails = 'CoordinatorsEmails',
    ProductCID = 'ProductCID',
    ProductLineLocationId = 'ProductLineLocationId',
    FailureModeId = 'FailureModeId',
    FailureModeValidation = 'FailureModeValidation',
    FailureModeDescription = 'FailureModeDescription',
    IsResolvingUnitOrganizationalInfoDivisionCode = 'ResolvingUnit.OrganizationalInfo.Division.Code',
    IsResolvingUnitOrganizationalInfoBusinessUnitCode = 'ResolvingUnit.OrganizationalInfo.BusinessUnit.Code',
    SerialNo = 'SerialNo',
    PurchaseOrderNo = 'PurchaseOrderNo',
    AbbProject = 'AbbProject',
    ProjectName = 'ProjectName',
    Quantity = 'Quantity',
    CustomerReference = 'CustomerReference',
    SupplierGuid = 'SupplierGuid',
    CommissioningDate = 'CommissioningDate',
    ManufacturingDate = 'ManufacturingDate',
    WarrantyReference = 'Warranty.Reference',
    WarrantyStartDate = 'Warranty.StartDate',
    WarrantyEndDate = 'Warranty.EndDate',
    IsCopySentToParties = 'IsCopySentToParties',
    PartiesReference = 'PartiesReference',
    PartiesReferenceEmails = 'PartiesReferenceEmails',
    PrimaryResolutionOwner = 'PrimaryResolutionOwner.Email',
    PrimaryResolutionOwnerActive = 'PrimaryResolutionOwner.IsActive',
    QuickFixVariant = 'QuickFixVariant',
    QuickFixIssueId = 'QuickFixIssueId',
    WhatContainmentActionsWereTaken = 'WhatContainmentActionsWereTaken',
    WhatWasTheRootCause = 'WhatWasTheRootCause',
    HowDidWeResolveThisIssue = 'HowDidWeResolveThisIssue',
    ResolutionDescription = 'ResolutionDescription',
    RcaAttachments = 'RcaAttachments',
    ResolutionAttachments = 'ResolutionAttachments',
    PgRcaCategory = 'PgRcaCategory.Id',
    ReasonWhyNotEffectiveOrNotRequired = 'ReasonWhyNotEffectiveOrNotRequired',
    EffectivenessDeadlineDate = 'EffectivenessDeadlineDate',
    CaptureAttachments = 'CaptureAttachments',
    CustomerComments = 'CustomerComments',
    IsWaitingForExecution = 'IsWaitingForExecution',
    ExpectedExecutionDate = 'ExpectedExecutionDate',
    ClosedAttachments = 'ClosedAttachments',
    EffectivenessAttachments = 'EffectivenessAttachments',
    SolutionWillBeValidatedByCoordinator = 'SolutionWillBeValidatedByCoordinator',
    ResolutionOwnerTeam = 'ResolutionOwnerTeam',
    EffectivenessTypeId = 'EffectivenessTypeId',
    IsSendCustomerSurvey = 'IsSendCustomerSurvey',
    ReasonNotToSendCustomerSurvey = 'ReasonNotToSendCustomerSurvey',
    NextEscalationDate = 'NextEscalationDate',
    BehalfBuCode = 'BehalfBuCode',
    BehalfCountryCode = 'BehalfCountryCode',
    Classifications = 'Classifications',
}

export enum ActionsKeys {
    Submit = 'submit',
    SaveAsDraft = 'saveAsDraft',
    Delete = 'delete',
    SaveCaptureSection = 'saveCaptureSection',
    SaveAssignSection = 'saveAssignSection',
    SaveResolutionSection = 'saveResolutionSection',
    SaveCompletionSection = 'saveCompletionSection',
    SaveEffectivenessSection = 'saveEffectivenessSection',
    ReturnToIssueOwner = 'returnToIssueOwner',
    Assign = 'assign',
    RevertToNotAssigned = 'revertToNotAssigned',
    ContainmentActionsExecuted = 'containmentActionsExecuted',
    Resolved = 'resolved',
    Verified = 'verified',
    ReturnToResolutionOwner = 'returnToResolutionOwner',
    ReturnToROFromResolved = 'returnToROFromResolved',
    ReturnToROFromInProcessVerification = 'returnToROFromInProcessVerification',
    Completed = 'completed',
    CompletedExecutionPending = 'completedExecutionPending',
    ReopenIssue = 'reopenIssue',
    Confirmed = 'confirmed',
    ReopenConfirmedIssue = 'reopenConfirmedIssue',
    ExtendTargetResolutionDate = 'extendTargetResolutionDate',
    ConfirmHighImpact = 'confirmHighImpact',
    DowngradeHighImpact = 'downgradeHighImpact',
}

export enum ActionMessageType {
    Any = 0,
    SystemAction = 1,
    SystemNotification = 2,
    ManualAction = 4,
    UserNotification = 8,
}

export enum FollowUpByMethod {
    Meeting = 0, //Meeting
    Phone = 1, //Phone
    Email = 2, //Email
    Chat = 3, //Chat/Messaging
    FollowUpNotPossible = 4, //Follow-up not possible
}
