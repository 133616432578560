import { FilterSection } from '.'
import { IssueOwnerFiltersState } from 'types/reportTypes'
import { FiltersSectionBaseProps } from '../types'
import {
    FormGridItem,
    GridContainer,
    MultiCitySelect,
    MultiCountrySelect,
    MultiRegionSelect,
    OwnerMngrLvl1Select,
    OwnerMngrLvl2Select,
    OwnerMngrLvl3Select,
} from 'components'
import { useMemo } from 'react'

export interface IssueOwnerFiltersProps extends FiltersSectionBaseProps {
    value: IssueOwnerFiltersState
    onChange: (value: IssueOwnerFiltersState) => void
}

export const IssueOwnerFilters = (props: IssueOwnerFiltersProps) => {
    const { value, onChange, ...other } = props
    const createChangeHandler =
        (key: keyof IssueOwnerFiltersState) => (newValue: any) => {
            value[key] = newValue
            onChange({ ...value })
        }
    const createPlaceholder = (
        key: keyof IssueOwnerFiltersState,
        placeholder: string = 'Click or type to select'
    ) => ((value[key] as Array<any>)?.length <= 0 ? placeholder : '')
    const divisionsLvl1: string[] = useMemo(
        () =>
            value?.managementLevel1?.reduce((prev, x) => {
                const divisionCode = x?.organizationInfo?.division?.code
                if (divisionCode) {
                    prev.push(x.organizationInfo.division.code)
                }
                return prev
            }, []),
        [value.managementLevel1]
    )
    const divisionsLvl2: string[] = useMemo(
        () =>
            value?.managementLevel2?.reduce((prev, x) => {
                const divisionCode = x?.organizationInfo?.division?.code
                if (divisionCode) {
                    prev.push(divisionCode)
                }
                return prev
            }, []),
        [value.managementLevel2]
    )
    const businessUnitLvl2: string[] = useMemo(
        () =>
            value?.managementLevel2?.reduce((prev, x) => {
                const businessUnit = x?.organizationInfo?.businessUnit?.code
                if (businessUnit) {
                    prev.push(businessUnit)
                }
                return prev
            }, []),
        [value.managementLevel2]
    )
    return (
        <FilterSection {...other}>
            <GridContainer>
                <FormGridItem>
                    <OwnerMngrLvl1Select
                        placeholder={createPlaceholder('managementLevel1')}
                        selectedItems={value?.managementLevel1}
                        onSelectedItemsChanged={createChangeHandler(
                            'managementLevel1'
                        )}
                    />
                    <OwnerMngrLvl2Select
                        placeholder={createPlaceholder('managementLevel2')}
                        divisionCodes={divisionsLvl1 ?? []}
                        selectedItems={value.managementLevel2}
                        onSelectedItemsChanged={createChangeHandler(
                            'managementLevel2'
                        )}
                    />
                    <OwnerMngrLvl3Select
                        placeholder={createPlaceholder('managementLevel3')}
                        divisionCodes={[
                            ...(divisionsLvl1 ?? []),
                            ...(divisionsLvl2 ?? []),
                        ]}
                        businessUnitCodes={businessUnitLvl2}
                        selectedItems={value.managementLevel3}
                        onSelectedItemsChanged={createChangeHandler(
                            'managementLevel3'
                        )}
                    />
                </FormGridItem>
                <FormGridItem>
                    <MultiRegionSelect
                        placeholder={createPlaceholder('regions')}
                        selectedRegions={value?.regions ?? []}
                        onRegionsChanged={createChangeHandler('regions')}
                    />
                    <MultiCountrySelect
                        placeholder={createPlaceholder('countries')}
                        regions={value?.regions ?? []}
                        selectedCountries={value?.countries ?? []}
                        onCountriesChanged={createChangeHandler('countries')}
                    />
                    <MultiCitySelect
                        regions={value.regions ?? []}
                        countries={value.countries ?? []}
                        placeholder={createPlaceholder('cities')}
                        selectedCities={value?.cities ?? []}
                        onCitiesChanged={createChangeHandler('cities')}
                        displayAll
                    />
                </FormGridItem>
            </GridContainer>
        </FilterSection>
    )
}
