import { DeleteOutline, Done, Edit } from '@mui/icons-material'
import {
    IconButton,
    ListItem,
    ListItemProps,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChipLabel } from 'components'
import { useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { AllInOneReportSettings } from 'types/reportTypes'
import { dataTestId, getPrettyDateTime } from 'utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        button: {
            marginRight: theme.spacing(2),
        },
        primaryText: {
            fontWeight: theme.typography.fontWeightBold as number,
        },
        chipLabel: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'ReportItemSettings' }
)

export interface ReportItemSettingsProps
    extends Omit<ListItemProps, 'children' | 'button'> {
    settings: AllInOneReportSettings
    onEdit: (settings: AllInOneReportSettings) => void
    onDelete: (settings: AllInOneReportSettings) => void
    onAccept: (settings: AllInOneReportSettings) => void
}

export const ReportItemSettings = (props: ReportItemSettingsProps) => {
    const { settings, onEdit, onDelete, onAccept, ...other } = props
    const { name, isDefault, date, notificationSettings } = settings
    const recipients = notificationSettings?.recipients ?? []
    const classes = useStyles()
    const secendaryText = useMemo(() => {
        let results = ''
        if (date) {
            results = `Created: ${getPrettyDateTime(date)}`
        }
        if (0 < recipients?.length && notificationSettings?.isActive) {
            results = results
                ? `${results}, `
                : '' +
                  'Email notification will be send to: ' +
                  recipients.map((r) => r?.email ?? 'unknown').join(', ')
        }
        return results
    }, [recipients, date])
    return (
        <ListItem disableGutters {...other}>
            <ListItemText        
                primary={
                    <>
                        {name}{' '}                       
                        {isDefault && (
                            <ChipLabel
                                className={classes.chipLabel}
                                label="default"
                                variant="grey"
                            />
                        )}                      
                    </>
                }
                primaryTypographyProps={{
                    className: classes.primaryText,
                }}
                secondary={secendaryText}
                {...dataTestId(`SETTINGS_NAME_${name}`)}
            />
            <ListItemSecondaryAction>
                <IconButton
                    onClick={() => onAccept({ ...settings })}
                    className={classes.button}
                    size="small"
                    {...dataTestId('SETTINGS_ACCEPT')}
                >
                    <Done />
                </IconButton>
                <IconButton
                    onClick={() => onEdit({ ...settings })}
                    className={classes.button}
                    size="small"
                    {...dataTestId('SETTINGS_EDIT')}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    onClick={() => onDelete({ ...settings })}
                    size="small"
                    {...dataTestId('SETTINGS_DELETE')}
                >
                    <DeleteOutline />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
}
