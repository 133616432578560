import { FC, Fragment, useEffect, useState } from 'react'
import { useIsInternetExplorer, useProfile } from '../hooks'
import { APP_VERSION } from '../utils'
import { InternetExplorerAlert } from './InternetExplorerAlert'
import { getIEMessageDocuments, setIEMessageDocument } from './utils'

export const LegacyBrowserAlert: FC<unknown> = (props) => {
    const { children } = props
    const isIE = useIsInternetExplorer()
    const { email } = useProfile()
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (isIE && !open) {
            const items = getIEMessageDocuments()
            const alertWasDisplayed = items.some(
                (i) => i.email === email && i.version === APP_VERSION
            )
            if (alertWasDisplayed) {
                return
            }

            setOpen(true)
        }
    }, [])
    const handleClose = () => {
        setIEMessageDocument({ email, version: APP_VERSION })
        setOpen(false)
    }
    return (
        <Fragment>
            {children}
            <InternetExplorerAlert open={open} onClose={handleClose} />
        </Fragment>
    )
}
