import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { ActionItem } from 'types/actionPlanTypes'
import {
    ActionsKeys,
    IssueCreationTypes,
    IssueRoles,
    IssueStatuses,
    IssueTypes,
    IssueValuesKeys,
} from 'types/enums'
import {
    AttachmentNewIssueStatus,
    ContainmentAction,
    HighImpact,
    HighImpactCategory,
    Issue,
    Warranty,
} from 'types/issueTypes'
import { AccessMatrixItem, AccessMatrixSetting } from 'types/matrixTypes'

dayjs.extend(utc)

export const getIssueInformation = () => ({
    reason: '',
    whatIsHappening: '',
    whyIsThisAProblem: '',
    howDoWeKnowWeHaveAProblem: '',
    whereDoesDidProblemHappen: '',
    whenDoesDidTheProblemHappen: '',
    whatAreCustomerExpectations: '',
})

export const getContainmentAction = (): ContainmentAction => ({
    isContainmentActionRequired: false,
    containmentActionDescription: '',
    containmentActionDeadlineDate: null,
    containmentActionConfirmationDate: null,
})

export const getHightImpactCategory = (): HighImpactCategory => ({
    isHealthAndSafetyImpact: false,
    isABBOrCustomerReputationAtStake: false,
    isNegativeBusinessImpactToCustomer: false,
    isNegativeBusinessImpactToABB: false,
    isNegativeImpactOnABBBrandImage: false,
    isPotentialToLossCustomer: false,
    isPotentialSystemicIssuesOrProductRecall: false,
})

export const getHighImpact = (): HighImpact => ({
    isHighImpact: false,
    impactReason: '',
    potentialBusinessImpact: 0,
    validationDeadlineDate: null,
    validationActionDate: null,
    ...getHightImpactCategory(),
})

export const getWarranty = (): Warranty => ({
    reference: '',
    startDate: '',
    endDate: '',
})

export const getAdditionalInformation = (): any => ({
    serialNo: '',
    purchaseOrderNo: '',
    abbProject: '',
    projectName: '',
    quantity: 0,
    customerReferenceId: '',
    supplier: null,
    commissioningDate: '',
    manufacturingDate: '',
    warranty: { ...getWarranty() },
})

const draftState: IssueStoreState = {
    lightMode: false,
    loading: false,
    error: null,
    loadingDesc: null,
    issueStatusId: IssueStatuses.New,
    context: {
        actions: [],
        myRoles: [IssueRoles.Issue_Owner],
    },
    attachments: [],
    attachmentsNewIssue: AttachmentNewIssueStatus.Disabled,
    issueLoadDone: false,
    captureSection: {
        issueStatusId: IssueStatuses.New,
        isCopySentToParties: false,
        isContainmentActionRequired: false,
        targetResolutionAndVerificationDate: null,
        highImpact: {
            isHighImpact: false,
        },
        issueType: IssueTypes.External,
        issueCreationType: IssueCreationTypes.Manual,
        warranty: {},
        behalfBuCode: '',
        behalfCountryCode: '',
    },
}

export const getDraftState = () => ({
    ...defaultState,
    ...draftState,
})

export interface DependedFields {
    [key: string]: AccessMatrixSetting
}

export interface ExpandedIssueState {
    capture: boolean
    assign: boolean
    resolve: boolean
    closed: boolean
    effectiveness: boolean
}

export interface FieldError {
    field: string
    message: string
}

export interface SendMessageState {
    isActive: boolean
    maximize: boolean
    fullScreen: boolean
}

export type IssueChangeLog = Partial<Record<IssueValuesKeys, [string, string]>>

export interface IssueChangeLogState {
    capture: IssueChangeLog
    assign: IssueChangeLog
    resolve: IssueChangeLog
    closed: IssueChangeLog
    effectiveness: IssueChangeLog
}

export interface BaseIssueStoreState
    extends Partial<{
        sendMessage: SendMessageState
        expanded: ExpandedIssueState
        lightMode: boolean
        loading: boolean
        loadingActionPlan: boolean
        actionItems: ActionItem[]
        error?: any
        warrnings?: Array<string>
        loadingDesc?: string
        selectedRolesCodes?: Array<IssueRoles>
        currentActions: Array<ActionsKeys>
        dependedFields: DependedFields
        oldTargetResolutionAndVerificationDate?: string
        accessMatrixes: Array<AccessMatrixItem>
        errorsList: Array<FieldError>
        changeLog: Partial<IssueChangeLogState>
        [key: string]: any
    }> {}

export interface IssueStoreState extends Issue, BaseIssueStoreState {}

const defaultState: BaseIssueStoreState = {
    sendMessage: {
        isActive: false,
        maximize: false,
        fullScreen: false,
    },
    expanded: {
        capture: false,
        assign: false,
        resolve: false,
        closed: false,
        effectiveness: false,
    },
    lightMode: false,
    loading: false,
    error: null,
    loadingActionPlan: false,
    loadingDesc: null,
    actionItems: [],
    oldTargetResolutionAndVerificationDate: null,
    selectedRolesCodes: [],
    currentActions: [],
    dependedFields: {},
    accessMatrixes: [],
    errorsList: [],
    attachments: [],
    test: false,
    warrnings: [],
    changeLog: {
        capture: {},
        assign: {},
        resolve: {},
        closed: {},
        effectiveness: {},
    },
}

export const getDefaultState = (): IssueStoreState => ({
    ...defaultState,
})
