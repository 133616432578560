import MuiPaper, { PaperProps } from '@mui/material/Paper'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyle = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            padding: theme.spacing(2),
        },
    }),
    { name: 'Paper' }
)

export const Paper = (props: PaperProps) => {
    const classes = useStyle()
    const { elevation, square, ...other } = props
    return (
        <MuiPaper
            classes={classes}
            component="section"
            elevation={elevation || 0}
            square={square || true}
            {...other}
        />
    )
}

Paper.muiName = 'Paper'

export default Paper
