import { IssueRoles } from './enums'
import { Employee, ResolvingUnit } from './issueTypes'

export interface MatrixItem {
    isRequired: boolean
    isDisabled: boolean
    key: string
    name: string
    dependentOn: any[]
}

export type ActionItemAccessMatrix = Array<{
    roleId: IssueRoles
    matrix: Record<ActionItemActions, MatrixItem[]>
}>

export type ActionPlanStatusTitle = 'Draft' | 'In progress' | 'Closed'

export enum ActionPlanStatusEnum {
    Draft = 'Draft',
    InProgress = 'InProgress',
    Closed = 'Closed',
}

export type ActionPlanStatusType =
    | ActionPlanStatusEnum.Draft
    | ActionPlanStatusEnum.InProgress
    | ActionPlanStatusEnum.Closed

export enum ActionItemStatus {
    New = 0,
    ToDo = 1,
    InProgress = 2,
    Complated = 3,
    InProgressOverdue = 4,
    Deleted = 5,
}

export enum ActionItemActions {
    Save = 'actionPlan_Save',
    Assign = 'actionPlan_Assign',
    Delete = 'actionPlan_Delete',
    Reject = 'actionPlan_Reject',
    Close = 'actionPlan_Close',
    Reopen = 'actionPlan_Reopen',
}

export enum ActionItemAttachmentType {
    Description = 1,
    Activities = 2,
}

export enum ActionItemFields {
    ActionName,
    ActionDescription,
    ActivitiesDescription,
    ActionCreator,
    ActionOwnerEmail,
    ActionDeadline,
    ActionTypeId,
    DescriptionAttachments,
    ActivitiesAttachments,
    InterestedParties,
}

export interface ActionItemContext {
    actions: ActionItemActions[]
    enabledFields: ActionItemFields[]
}

export interface ActionType {
    selectable: boolean
    id: number
    name: string
    parentId: number
    groupName?: string
}

export interface ActionItemAttachment {
    id: number
    actionItemId: number
    type: ActionItemAttachmentType
    displayName: string
    fileExtension: string
}

export interface ActionItemBase {
    actionStatus: ActionItemStatus
    actionItemId: number
    issueId: number
    issueNumber: string
    resolvingUnit: ResolvingUnit
    resolutionOwner: Employee
    actionCreator: Employee
    actionOwner: Employee
    issueCoordinator: Employee
    actionDeadline: string
    creationDate: string
    completionDate: string
    actionType: ActionType
    actionName: string
    actionDescription: string
    activitiesDescription: string
    isRejected: boolean
    isReopened: boolean
    isDeleted: boolean
}

export interface ActionItem extends ActionItemBase {
    accessMatrix: ActionItemAccessMatrix
    isDeleted: boolean
    actionItemAttachments: ActionItemAttachment[]
    rowVersion?: string
    interestedParties: Employee[]
}
