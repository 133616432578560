import MuiChip, { ChipProps } from '@mui/material/Chip'
import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            ...theme.typography.caption,
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.grey[100],
            transition: theme.transitions.create(
                'background-color color border-color',
                {
                    duration: theme.transitions.duration.shorter,
                    easing: theme.transitions.easing.easeOut,
                }
            ),
        },
        outlined: {
            backgroundColor: 'transparent',
            borderColor: theme.palette.grey[200],
        },
        deleteIcon: {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1.5),
            color: theme.palette.grey[400],
            '&:hover': {
                color: theme.palette.grey[300],
            },
        },
        clickable: {
            '&:focus': {
                color: theme.palette.common.white,
                backgroundColor: theme.palette.grey[400],
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[600],
            },
            '&$outlined': {
                '&:focus': {
                    color: theme.palette.grey[200],
                    backgroundColor: 'transparent',
                    borderColor: theme.palette.grey[600],
                },
                '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: theme.palette.grey[300],
                },
            },
        },
        label: {},
        icon: {
            width: 20,
            height: 20,
            marginLeft: theme.spacing(1),
            color: theme.palette.grey[600],
        },
    }),
    { name: 'Chip' }
)

export const Chip = forwardRef((props: ChipProps, ref: any) => {
    const classes = useStyles(props)
    return <MuiChip ref={ref} {...props} classes={classes} />
})

export default Chip
