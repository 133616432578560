import { ErrorOutline, Info } from '@mui/icons-material'
import { Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { memo } from 'react'
import { useDispatch } from 'react-redux'
import { selectCustomerContact, selectCustomerName } from 'store/issue'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueTypes, IssueValuesKeys } from 'types/enums'
import { Customer, CustomerContact } from 'types/issueTypes'
import PickerTextField from '../../components/Base/PickerTextField'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { CustomerDetails } from '../components/CustomerDetails'
import { useFieldWithError } from '../matrix'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
        label: {
            display: 'block',
            transform: 'translate(0, 1.5px) scale(0.75) !important',
            transformOrigin: '0px 0px',
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        inactiveIcon: {
            margin: theme.spacing(0, 1),
            color: theme.palette.secondary.main,
        },
        icon: {
            margin: theme.spacing(0, 1),
        },
    }),
    {
        name: 'CustomerSelection',
    }
)

export interface CustomerSelectionProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        Partial<{
            issueType: IssueTypes
            customer: Customer
            customerContact?: CustomerContact
            onEditCustomerName?: () => void
            onEditCustomerContactName?: () => void
            disabled: boolean
        }> {}

const CustomerSelection = (props: CustomerSelectionProps) => {
    const {
        disabled,
        issueType,
        customerContact,
        customer,
        onEditCustomerName,
        onEditCustomerContactName,
        ...other
    } = props

    const dispatch = useDispatch()

    const customerActive = customer?.isActive ?? true
    const customerContactActive = customerContact?.isActive ?? true

    const classes = useStyles(props)
    const customerGuidMatrix = useFieldWithError(IssueValuesKeys.Customer, {
        disabled,
        defaultError: !customerActive,
        defaultHelperText: !customerActive ? "Customer isn't active" : null,
    })
    const customerContactEmailMatrix = useFieldWithError(
        IssueValuesKeys.CustomerContact,
        {
            disabled,
            defaultError: !customerContactActive,
            defaultHelperText: !customerContactActive
                ? "Contact isn't active"
                : 'Once issue has been submitted, you can send confirmation to the customer by using "Send message" button on top of page and selecting "ABB has recorded issue based on your feedback" template.',
            matrixModifier: (matrix) => {
                if (!customer && issueType === IssueTypes.External) {
                    return {
                        ...matrix,
                        isRequired: true,
                        isDisabled: true,
                    }
                }
                return matrix
            },
        }
    )

    const fullName = `${customerContact?.firstName} ${customerContact?.lastName}`

    const customerContactName = customerContact
        ? `${fullName} ${
              customerContact?.email && `(${customerContact?.email})`
          }`
        : ''
    return (
        <div {...other}>
            <Typography variant="h6">Customer selection</Typography>
            <PickerTextField
                {...ISSUE_VIEW_PAGE.CUSTOMER.NAME_INPUT}
                className={classes.field}
                label="Customer name"
                value={customer?.name}
                onSelect={() => onEditCustomerName && onEditCustomerName()}
                startAdornment={
                    !customerActive ? (
                        <ErrorOutline
                            className={classes.inactiveIcon}
                            fontSize="small"
                        />
                    ) : (
                        <CustomerDetails
                            className={classes.icon}
                            customer={customer}
                        />
                    )
                }
                {...customerGuidMatrix}
                onClearValue={() => dispatch(selectCustomerName(null))}
            />
            <Collapse in={issueType === IssueTypes.External}>
                <PickerTextField
                    {...ISSUE_VIEW_PAGE.CUSTOMER.CONTACT_NAME_INPUT}
                    className={classes.field}
                    label="Customer contact name"
                    value={customerContactName}
                    helperTextIcon={<Info fontSize="inherit" />}
                    onSelect={() =>
                        onEditCustomerContactName && onEditCustomerContactName()
                    }
                    startAdornment={
                        !customerContactActive && (
                            <ErrorOutline
                                fontSize="small"
                                className={classes.inactiveIcon}
                            />
                        )
                    }
                    {...customerContactEmailMatrix}
                    onClearValue={() => dispatch(selectCustomerContact(null))}
                />
            </Collapse>
        </div>
    )
}

CustomerSelection.defaultProps = {
    issueType: IssueTypes.External,
}

export default memo(CustomerSelection)
