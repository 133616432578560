import { List, Typography } from '@mui/material'
import { useMemo } from 'react'
import { ActionItem } from 'types/actionPlanTypes'
import { ActionPlanSectionItem } from '.'
import { useActionPlanSectionStyles } from '../ActionPlanSection.style'

export interface ActionPlanSectionColumnProps {
    items: ActionItem[]
}

export const ActionPlanSectionColumn = (
    props: ActionPlanSectionColumnProps
) => {
    const { items } = props
    const classes = useActionPlanSectionStyles()

    const parseItems = useMemo(
        () =>
            items.map((item) => (
                <ActionPlanSectionItem key={item.actionItemId} item={item} />
            )),
        [items]
    )

    return (
        <List className={classes.list}>
            {parseItems.length > 0 ? (
                parseItems
            ) : (
                <Typography>
                    You do not have action items in the given status
                </Typography>
            )}
        </List>
    )
}
