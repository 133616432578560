export * from './Issue/routes'
export * from './Admin/routes'
export * from './Reports/routes'

export const ROOT: string = '/'
export const TEST: string = ROOT + 'akamai/testobject.html'
export const MY_ACTIONS = ROOT + 'my/actions'
export const MY_ISSUES = ROOT + 'my/issues'
export const MY_ACTION_PLAN_ITEMS = ROOT + 'my/action-plan-items'
export const GDPR: string = ROOT + 'gdpr'
export const DASHBOARD: string = ROOT
export const RELEASE_NOTES: string = ROOT + 'release-notes'
export const LEGACY_RELEASE_NOTES: string = ROOT + 'release-notes-1x'
export const OFFLINE: string = ROOT + 'offline'
export const RENEW_TOKEN: string = '/renew'
export const ERROR_500: string = ROOT + '500'
export const NOT_FOUND_404: string = ROOT + '404'
export const OVERRIDE_USER: string =
    ROOT + '6af3d520-7c1d-4a32-978b-aba6cf27457c'
export const MATRIX_VIEWER: string =
    ROOT + 'b11b0625-d1b7-439e-93e6-e41f2deabadc'
export const TOKEN_GENERATOR: string = ROOT + 'token-generator'
export const LAB: string = ROOT + 'labs'

export const DEVELOPER_TOOLS_ROOT = ROOT + 'developer-tools'

export const ACCESS_MATRIX_ROOT = DEVELOPER_TOOLS_ROOT + '/access-matrix'
export const ACCESS_MATRIX_REQUIRED_FIELDS_EDITOR: string =
    ACCESS_MATRIX_ROOT + '/required-fields'
export const ACCESS_MATRIX_ENABLED_FIELDS_EDITOR: string =
    ACCESS_MATRIX_ROOT + '/enabled-fields'

export const EMAIL_THEME_EDITOR: string =
    DEVELOPER_TOOLS_ROOT + '/email-theme-editor'

export const USER_IMPERSONATE: string =
    DEVELOPER_TOOLS_ROOT + '/user-impersonate'

export const MANAGE_SUPPORT_GROUP: string =
    DEVELOPER_TOOLS_ROOT + '/manage-support-group'

export const CUSTOMER_ROOT = '/customer'
export const CUSTOMER_SURVEY = CUSTOMER_ROOT + '/survey/:surveyKey'
export const CUSTOMER_SURVEY_LANG = CUSTOMER_ROOT + '/:lang/survey/:surveyKey'
export const CUSTOMER_SURVEY_EFFORT_SCORE =
    CUSTOMER_ROOT + '/survey/:surveyKey/:effortScore'
export const CUSTOMER_SURVEY_EFFORT_SCORE_LANG =
    CUSTOMER_ROOT + '/:lang/survey/:surveyKey/:effortScore'
export const CUSTOMER_UNSUBSCRIBE = CUSTOMER_ROOT + '/unsubscribe/:surveyKey'
export const CUSTOMER_SUBSCRIPTIONS_LANG =
    CUSTOMER_ROOT + '/:lang/survey/subscriptions/:surveyKey'
export const CUSTOMER_SUBSCRIPTIONS =
    CUSTOMER_ROOT + '/survey/subscriptions/:surveyKey'

export const NOTIFICATION_SETTINGS: string =
    DEVELOPER_TOOLS_ROOT + '/notification-settings'

export const GLOBAL_SEARCH = ROOT + 'global-search'

export const ANALYZER_RAPORT = '/file/CCRP Analyzer.xlsx'
export const ANALYZER_NC_REPORT = '/file/CCRP Unit Internal Analyzer.xlsx'
