import { TableColumnProperties, Workbook, Worksheet } from 'exceljs'
import * as fs from 'file-saver'
import { ExcelFilters } from '.'

export const saveWorkbookAndDownload = (
    workbook: Workbook,
    fileName: string
) => {
    return workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        fs.saveAs(blob, `${fileName}.xlsx`)
    })
}

export const addFiltersToExcel = (
    worksheet: Worksheet,
    filters: ExcelFilters
) => {
    worksheet.mergeCells('A1', 'B1')
    worksheet.getCell('A1').value =
        'The report generated based on the following filters: '
    worksheet.getCell('A1').style = {
        font: {
            bold: true,
        },
    }
    filters.forEach(({ name, value }) => {
        worksheet.addRow([name, value])
    })

    worksheet.getRows(1, filters.length + 1).forEach((row) => {
        row.outlineLevel = 1
    })
}

export const addFilterButtonToColumnsExcel = (
    columns: TableColumnProperties[]
) => {
    return columns.map((column) => {
        return {
            ...column,
            filterButton: true,
        }
    })
}
