import { useState } from 'react'
import { AdminCustomer, createContactName, updateContactName } from 'api'
import {
    CreateCustomerContactCommand,
    CustomerContact,
    CustomerContactErrors,
    UpdateCustomerContactCommand,
} from 'types/issueTypes'
import {
    CustomerContactsManagePageContext,
    CustomerContactsManagePageDialog,
    getInitErrorsForCustomerContactDialog,
} from 'Admin'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'

export type CustomerContactsManagePageDialogModeType = 'edit' | 'add' | null

export const CustomerContactsManagePageContextProvider: React.FC = (props) => {
    const dispatch = useDispatch()
    const { children } = props
    const [dialogMode, setDialogMode] =
        useState<CustomerContactsManagePageDialogModeType>(null)
    const [errors, setErrors] = useState<CustomerContactErrors>(
        getInitErrorsForCustomerContactDialog()
    )
    const [loading, setLoading] = useState(false)

    const [state, setState] = useState<{
        customer: AdminCustomer
        customerContact: CustomerContact
        customerTabSearchResults: AdminCustomer[]
        customerContactTabSearchResults: AdminCustomer[]
    }>({
        customer: null,
        customerContact: null,
        customerTabSearchResults: [],
        customerContactTabSearchResults: [],
    })

    const updateCustomerTabSearchResults = (customers: AdminCustomer[]) => {
        setState((prev) => ({
            ...prev,
            customerTabSearchResults: customers,
        }))
    }

    const updateCustomerContactTabSearchResults = (
        customers: AdminCustomer[]
    ) => {
        setState((prev) => ({
            ...prev,
            customerContactTabSearchResults: customers,
        }))
    }

    const openCustomerContactDialog = (
        dialogMode: CustomerContactsManagePageDialogModeType,
        options: {
            customerContact?: CustomerContact
            customer: AdminCustomer
        }
    ) => {
        setDialogMode(dialogMode)
        setErrors(getInitErrorsForCustomerContactDialog())
        if (dialogMode === 'edit') {
            setState((prev) => ({
                ...prev,
                customer: options.customer,
                customerContact: options.customerContact,
            }))
        } else if (dialogMode === 'add') {
            setState((prev) => ({
                ...prev,
                customer: options.customer,
                customerContact: null,
            }))
        }
    }

    const catchErrors = async (cb: () => void) => {
        try {
            if (loading) {
                return
            }
            setLoading(true)
            await cb()
        } catch (err: any) {
            if (err.error) {
                dispatch(showSnackbar(err.error, true))
            } else if (err.errors) {
                const newErrors = {}

                err.errors.forEach((item) => {
                    const newField = item.field.split('')
                    newField[0] = newField[0].toLowerCase()
                    const key = newField.join('')
                    if (newErrors[key]) {
                        newErrors[key] = `${newErrors[key]}, ${item.message}`
                    } else {
                        newErrors[key] = item.message
                    }
                })

                setErrors((prev) => ({ ...prev, ...newErrors }))
            }
        } finally {
            setLoading(false)
        }
    }

    const addCustomerContact = (query: CreateCustomerContactCommand) => {
        catchErrors(async () => {
            const { data } = await createContactName(query)
            dispatch(showSnackbar('Customer contact added successfully'))
            setDialogMode(null)
            setState((prev) => {
                const updateList = (
                    currentList: AdminCustomer[],
                    newItem: CustomerContact
                ): AdminCustomer[] => {
                    const items = [...currentList]
                    const index = items.findIndex(
                        (item) => item.guid === query.customerGuid
                    )
                    const customer = { ...items[index] }
                    if (
                        Array.isArray(customer.contacts) &&
                        0 < customer.contacts.length
                    ) {
                        customer.contacts = customer.contacts
                            ? [...customer.contacts, { ...newItem }]
                            : []
                    } else {
                        customer.contacts = [{ ...newItem }]
                    }

                    items[index] = customer
                    return items
                }

                return {
                    ...prev,
                    customerTabSearchResults: updateList(
                        prev.customerTabSearchResults,
                        data
                    ),
                    customerContactTabSearchResults: updateList(
                        prev.customerContactTabSearchResults,
                        data
                    ),
                }
            })
        })
    }

    const updateCustomerContact = async (
        customerContactId: string,
        customer2: UpdateCustomerContactCommand
    ) => {
        catchErrors(async () => {
            const { data } = await updateContactName(
                customerContactId,
                customer2
            )
            dispatch(showSnackbar('Customer contact updated successfully'))
            setDialogMode(null)
            setState((prev) => {
                const updateList = (
                    currentList: AdminCustomer[]
                ): AdminCustomer[] => {
                    const items = [...currentList]
                    const index = items.findIndex(
                        (item) => item.guid === data.customerGuid
                    )
                    const customer = { ...items[index] }
                    customer.contacts = customer.contacts
                        ? customer.contacts.map((x) =>
                              x.id === data.id ? { ...data } : x
                          )
                        : []
                    items[index] = customer
                    return items
                }

                return {
                    ...prev,
                    customerTabSearchResults: updateList(
                        prev.customerTabSearchResults
                    ),
                    customerContactTabSearchResults: updateList(
                        prev.customerContactTabSearchResults
                    ),
                }
            })
        })
    }

    return (
        <CustomerContactsManagePageContext.Provider
            value={{
                ...state,
                updateCustomerTabSearchResults,
                updateCustomerContactTabSearchResults,
                openCustomerContactDialog,
                addCustomerContact,
                updateCustomerContact,
            }}
        >
            {children}
            <CustomerContactsManagePageDialog
                errors={errors}
                setErrors={setErrors}
                dialogMode={dialogMode}
                onClose={() => setDialogMode(null)}
            />
        </CustomerContactsManagePageContext.Provider>
    )
}
