import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            boxSizing: 'border-box',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
        },
        description: {
            margin: theme.spacing(1, 0, 4, 0),
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(4),
        },
        question: {
            marginBottom: theme.spacing(4),
        },
        send: {
            margin: theme.spacing(2, 0, 8, 0),
        },
        space: {
            minHeight: '10vh',
        },
        footer: {
            textAlign: 'center',
            color: theme.palette.grey[300],
        },
    }),
    { name: 'Survey' }
)
