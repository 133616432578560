import {
    Divider,
    ListItem,
    ListItemProps,
    ListItemText,
    Theme,
    useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { SEARCH_RESULTS_ITEM } from 'consts/selectorIds'
import { memo } from 'react'
import { arrayToStringLine, customize } from 'utils'
import { ChipLabel } from '../Chips'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        gutters: {
            padding: theme.spacing(0, 0.5),
        },
        tags: {
            display: 'inline-flex',
            width: '100%',
        },
        primary: {
            ...theme.typography.h5,
            fontWeight: theme.typography.fontWeightMedium as number,
            color: theme.palette.grey[900],
        },
        secondary: {
            ...theme.typography.body2,
            color: theme.palette.grey[600],
        },
    }),
    { name: 'SearchResultItem' }
)

export interface SearchResultItemProps extends ListItemProps {
    heading?: Array<string>
    subject?: Array<string>
    tags?: Array<string>
    [key: string]: any
}

export interface TagListProps {
    tags?: Array<string>
}

export const TagList = (props: TagListProps) => {
    const { tags } = props
    const theme = useTheme()
    return tags ? (
        <>
            {tags.map((t, i) => (
                <ChipLabel
                    {...customize(SEARCH_RESULTS_ITEM.TAG, t)}
                    style={{
                        marginTop: theme.spacing(1),
                        marginRight: theme.spacing(1),
                    }}
                    key={i}
                    label={t}
                    variant="grey"
                />
            ))}
        </>
    ) : null
}

export const SearchResultItem = memo<SearchResultItemProps>((props) => {
    const classes = useStyles(props)
    const {
        subject: subjectProp,
        heading: headingProp,
        tags: tagsProp,
        button: boolean,
        ...other
    } = props
    const heading = arrayToStringLine(headingProp)
    const subject = arrayToStringLine(subjectProp)
    const tags = <TagList tags={tagsProp} />
    return (
        <div>
            <ListItem
                {...other}
                classes={{
                    gutters: classes.gutters,
                }}
                className={classes.gutters}
                {...customize(SEARCH_RESULTS_ITEM.CONTAINER, heading)}
            >
                <ListItemText
                    classes={{
                        primary: classes.primary,
                        secondary: classes.secondary,
                    }}
                    primary={heading}
                    secondary={
                        <>
                            {subject}
                            <span
                                {...SEARCH_RESULTS_ITEM.TAGS}
                                className={classes.tags}
                            >
                                {tags}
                            </span>
                        </>
                    }
                />
            </ListItem>
            <Divider variant="fullWidth" component="li" />
        </div>
    )
})

export default SearchResultItem
