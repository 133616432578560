import { Search } from '@mui/icons-material'
import {
    BaseTextFieldProps,
    IconButton,
    ListItemText,
    Theme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import { searchAdminCustomers } from 'api'
import { useState } from 'react'
import { useDebounce } from 'react-use'
import { CustomerDialogPicker } from 'Reports'
import { Customer } from 'types/issueTypes'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from '.'
import { Chip, ChipLabel } from '..'

export interface MultiCustomerSelectProps extends Partial<BaseTextFieldProps> {
    selectedCustomers: Customer[]
    onCustomersChanged: (customers: Customer[]) => void
}

export const useMultiCustomerSelectStyles = makeStyles<Theme>(
    (theme) => ({
        inputRoot: {},
        endAdornment: {
            right: `${theme.spacing(5)} !important`,
        },
        searchButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: 'calc(50% - 14px)',
        },
        chipLabel: {
            marginLeft: theme.spacing(2),
        },
    }),
    { name: 'MultiCustomerSelect' }
)

export const MultiCustomerSelect = (props: MultiCustomerSelectProps) => {
    const {
        selectedCustomers,
        onCustomersChanged,
        disabled = false,
        ...other
    } = props
    const classes = useMultiCustomerSelectStyles()
    const [openPicker, setOpenPicker] = useState(false)
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState<Customer[]>([])
    const [helperText, setHelperText] = useState('')
    const [error, setError] = useState(null)
    const [searchText, setSearchText] = useState('')
    const handleChange = (customers: Customer[]) => {
        onCustomersChanged(customers)
        setSearchText('')
    }
    useDebounce(
        async () => {
            try {
                if (loading || !searchText || searchText.length < 4) {
                    return
                }
                setLoading(true)
                setHelperText('')
                setError(null)
                const { data } = await searchAdminCustomers({
                    name: searchText,
                })

                if (data?.length === 1) {
                    onCustomersChanged([...selectedCustomers, ...data])
                    setSearchText('')
                    setOptions([])
                } else {
                    setOptions(data)
                }
            } catch (error) {
                setError(error)
                setHelperText("'Cannot load employees list'")
            } finally {
                setLoading(false)
            }
        },
        1000,
        [searchText]
    )
    return (
        <>
            <Autocomplete
                classes={{
                    endAdornment: classes.endAdornment,
                }}
                inputValue={searchText}
                onInputChange={(e, value, reason) => {
                    if (reason === 'input') {
                        setSearchText(value)
                    } else if (reason === 'clear') {
                        setSearchText('')
                    }
                }}
                onBlur={() => setSearchText('')}
                loading={loading}
                loadingText="Loading customers…"
                noOptionsText="No customers. Enter at least 3 characters…"
                disabled={disabled}
                multiple={true}
                value={selectedCustomers}
                options={options}
                clearOnBlur={true}
                disableClearable={Boolean(loading || disabled)}
                isOptionEqualToValue={(option, value) =>
                    option.guid === value.guid
                }
                onChange={(e, customers) =>
                    handleChange(customers as Customer[])
                }
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                    <li {...props}>
                        <ListItemText
                            primary={
                                <>
                                    {option.name}
                                    <ChipLabel
                                        className={classes.chipLabel}
                                        label={option.guid}
                                        variant="grey"
                                    />
                                </>
                            }
                            secondary={option?.address?.full ?? ''}
                        />
                    </li>
                )}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                        return (
                            <Chip
                                label={option.name}
                                {...getTagProps({ index })}
                            ></Chip>
                        )
                    })
                }
                renderInput={(params) => (
                    <AutoCompleteInput
                        params={params}
                        label="Customer"
                        helperText={helperText}
                        loading={loading}
                        {...dataTestId('CUSTOMER_COMMON')}
                        error={Boolean(error)}
                        {...other}
                        endAdornment={
                            <>
                                {params.InputProps.endAdornment}
                                <IconButton
                                    className={classes.searchButton}
                                    onClick={() => setOpenPicker(true)}
                                    size="small"
                                    {...dataTestId('CUSTOMER_SEARCH')}
                                >
                                    <Search fontSize="small" />
                                </IconButton>
                            </>
                        }
                    />
                )}
            />
            <CustomerDialogPicker
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                onAddCustomers={(customers) =>
                    handleChange([...customers, ...selectedCustomers])
                }
            />
        </>
    )
}
