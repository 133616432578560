import { ClassNameMap, makeStyles } from '@mui/styles'
import {
    AccessDeniedSvg,
    AllDoneSvg,
    GenericErrorSvg,
    LoadingSvg,
    NoInternetSvg,
    NoResultsSvg,
} from 'components'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { ApiError, ValidationException } from 'types/errors'

const useStyles = makeStyles(
    () => ({
        image: {
            margin: '0 auto',
            textAlign: 'center',
        },
    }),
    { name: 'ContentConfig' }
)

export interface ConfigInterface {
    showError: boolean
    image: ReactNode
    title: string
    subTitle: string
}

export const getInitContentConfig = (
    classes: ClassNameMap<'image'>
): ConfigInterface => ({
    showError: false,
    image: <AllDoneSvg className={classes.image} />,
    title: '',
    subTitle: '',
})

export const useContentConfig = (error: ApiError) => {
    const classes = useStyles()
    const history = useHistory()

    const [config, setConfig] = useState<ConfigInterface>(
        getInitContentConfig(classes)
    )
    const exceptionMessage = useMemo(() => {
        const errors = error?.errors ?? null
        const exceptionTypeKey: keyof ValidationException = 'exceptionType'
        const exceptionMessageKey: keyof ValidationException =
            'exceptionMessage'
        return errors
            ?.filter((x) => Object.keys(x).includes(exceptionTypeKey))
            .reduce(
                (prev, current) => `${prev} ${current[exceptionMessageKey]}`,
                ''
            )
    }, [error])
    const getMessage = (
        defaultTitle: string,
        subTitle: string = ''
    ): Omit<ConfigInterface, 'image' | 'showError'> => ({
        title: Boolean(exceptionMessage) ? 'Exception' : defaultTitle,
        subTitle,
    })
    const errorsConfig: Record<number, ConfigInterface> = {
        400: {
            showError: true,
            image: <GenericErrorSvg className={classes.image} />,
            ...getMessage('Bad request'),
        },
        403: {
            showError: true,
            image: <AccessDeniedSvg className={classes.image} />,
            ...getMessage('You are not allowed to perform action.'),
        },
        404: {
            image: <NoResultsSvg className={classes.image} />,
            showError: true,
            ...getMessage('Not found'),
        },
        0: {
            image: <NoInternetSvg className={classes.image} />,
            showError: true,
            ...getMessage('Connection problem'),
        },
        408: {
            image: <LoadingSvg className={classes.image} />,
            showError: true,
            ...getMessage('Timeout'),
        },
        409: {
            image: <AllDoneSvg className={classes.image} />,
            showError: true,
            ...getMessage(
                'Complaint Verison is not match.',
                Boolean(exceptionMessage)
                    ? exceptionMessage
                    : 'The complaint was modified by someone in the meantime. Please refresh to get new Verison.'
            ),
        },
        412: {
            image: <AllDoneSvg className={classes.image} />,
            showError: !Array.isArray(error?.errors),
            ...getMessage("Issue model isn't valid"),
        },
        417: {
            image: <AllDoneSvg className={classes.image} />,
            showError: true,
            ...getMessage('Operation canceled by user'),
        },
        422: {
            image: <AllDoneSvg className={classes.image} />,
            showError: true,
            ...getMessage('Invalid operation for issue'),
        },
    }

    useEffect(() => {
        if (error && Number.isInteger(error?.status)) {
            const { status } = error
            let innerConfig = errorsConfig[status]

            if (
                [0, 502, 504].includes(status) &&
                history.location.pathname === '/reports/all-in-one'
            ) {
                innerConfig = {
                    image: <AllDoneSvg className={classes.image} />,
                    title: '',
                    showError: true,
                    subTitle: error.message
                        ? error.message
                        : 'There are too many records. Please narrow down search criteria to display results.',
                }
            }
            if (!innerConfig) {
                if (500 <= status) {
                    innerConfig = {
                        image: <AllDoneSvg className={classes.image} />,
                        showError: true,
                        title: `Error ${status}`,
                        subTitle:
                            'Try to click go back or feel free to contact us if the problem persists.',
                    }
                } else {
                    innerConfig = {
                        ...getInitContentConfig(classes),
                        showError: true,
                        subTitle: error.message
                            ? error.message
                            : 'Try to click go back or feel free to contact us if the problem persists.',
                    }
                }
            }

            setConfig(innerConfig)
        }
    }, [error])

    return config
}
