import { Typography } from '@mui/material'
import { useHistory } from 'react-router'
import { NO_RESULTS_PAGE } from '../../consts/selectorIds'
import { DASHBOARD } from '../../routes'
import RoundedButton from '../Base/RoundedButton'
import { PageNotFoundSvg } from '../Images'
import useStyles from './styles'

export const PageNotFound = (props: any) => {
    const classes = useStyles(props)

    const history = useHistory()
    const title = 'Page not found'
    const buttonLabel = 'Go to dashboard'
    const errorHandler = () => history.replace(DASHBOARD)
    const subTitle = 'Check your url address and try again.'
    return (
        <div className={classes.content}>
            <PageNotFoundSvg />
            <section className={classes.info} {...NO_RESULTS_PAGE.INFO_SECTION}>
                <Typography variant="h4" {...NO_RESULTS_PAGE.HEADLINE}>
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    {...NO_RESULTS_PAGE.SUB_HEADLINE}
                >
                    {subTitle}
                </Typography>
            </section>
            {errorHandler && (
                <RoundedButton
                    color="secondary"
                    variant="contained"
                    onClick={errorHandler}
                    {...NO_RESULTS_PAGE.LOAD_ISSUES_BUTTON}
                >
                    {buttonLabel}
                </RoundedButton>
            )}
        </div>
    )
}

export default PageNotFound
