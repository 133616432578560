import dayjs from 'dayjs'
import { HtmlHTMLAttributes, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { UserProfile } from 'types/profile'
import { GlobalNotification, GlobalNotificationContext } from '.'

export const GlobalNotificationContainer = (
    props: HtmlHTMLAttributes<HTMLDivElement>
) => {
    const { notification } = useContext(GlobalNotificationContext)
    const profile = useSelector<RootState, UserProfile>(
        (store) => store?.dashboard?.profile
    )
    const visible = useMemo(() => {
        const { hrgtOrganizationInfo } = profile
        const { hrgtBusinessUnit } = hrgtOrganizationInfo

        if (
            notification?.businessUnit &&
            hrgtBusinessUnit?.code !== notification?.businessUnit
        )
            return false
        const messageFrom = notification?.messageFrom
        const messageTo = notification?.messageTo
        const visible = notification?.messageVisible ?? false
        if (!messageFrom || !messageTo) {
            return visible
        }
        const from = dayjs(messageFrom)
        const to = dayjs(messageTo)
        if (from.isValid() && to.isValid()) {
            return dayjs().isBetween(from, to, 'seconds')
        }
        return visible
    }, [notification])
    return (
        <GlobalNotification
            message={notification?.message}
            visible={visible}
            variant={notification?.messageVariant}
            {...props}
        />
    )
}
