import { makeStyles } from '@mui/styles'
import {
    AdminSearchPanelActions,
    DialogModeType,
    DialogOrganizationalInfo,
    getInitDialogOrganizationalInfo,
    getInitQueryProcessOwnersManagePage,
    ProcessOwnersManagePageDialog,
    ProcessOwnersManagePageFields,
    ProcessOwnersManagePageResults,
} from 'Admin'
import {
    addAdminProcessOwner,
    deleteAdminProcessOwner,
    searchAdminProcessOwners,
} from 'api/admin'
import {
    AddPersonButton,
    Content,
    EmployeeSelect,
    FormGridItem,
    GridContainer,
    MultiBusinessUnitSelect,
    MultiCountrySelect,
    MultiDivisionSelect,
    MultiProductGroupSelect,
    MultiRegionSelect,
    PageTitle,
    SearchPanel,
} from 'components'
import { useProfile } from 'hooks'
import { ReactNode, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AdminProcessOwner } from 'types/adminTypes'
import { AdminProcessOwnerAddQuery } from 'types/queries'
import { checkIsProcessOwner, uuid } from 'utils'
import { loadProfile } from '../../store/dashboard'
import { ApiError } from '../../types/errors'

const useStyles = makeStyles(
    () => ({
        field: {
            marginBottom: 0,
        },
    }),
    { name: 'ProcessOwnersManagePage' }
)

export const ProcessOwnersManagePage = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [dialogMode, setDialogMode] = useState<DialogModeType>(null)
    const [noResults, setNoResults] = useState(false)
    const [page, setPage] = useState(1)
    const [organizationalInfo, setOrganizationalInfo] =
        useState<DialogOrganizationalInfo>(getInitDialogOrganizationalInfo())
    const [error, setError] = useState(null)
    const [searchResults, setSearchResults] = useState<AdminProcessOwner[]>([])
    const [isSearching, setISearching] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const profile = useProfile()
    const isProcessOwner = useMemo(() => {
        return checkIsProcessOwner(profile.roles)
    }, [profile])
    const [query, setQuery] = useState<ProcessOwnersManagePageFields>(
        getInitQueryProcessOwnersManagePage()
    )

    const resetForm = () => {
        setQuery(getInitQueryProcessOwnersManagePage())
        setSearchText('')
    }

    const handleSearch = async () => {
        try {
            setISearching(true)
            setError(null)
            setPage(1)
            setNoResults(false)
            const { data, status } = await searchAdminProcessOwners({
                email: query.employee?.email,
                divisions: query.businessAreas.map((item) => item.code),
                businessUnits: query.divisions.map((item) => item.code),
                productGroups: query.productGroups.map((item) => item.code),
                regions: query.regions.map((item) => item.code),
                countries: query.countries.map((item) => item.code),
            })
            data &&
                setSearchResults(
                    data?.map((item) => ({ ...item, reactId: uuid() })) ?? []
                )

            if (status === 204) {
                setNoResults(true)
            }
        } catch (err) {
            setError(err)
        } finally {
            setISearching(false)
        }
    }

    const openDialog = (
        mode: DialogModeType,
        organizationalInfo: DialogOrganizationalInfo
    ) => {
        setDialogMode(mode)
        setOrganizationalInfo(organizationalInfo)
    }

    const handleChange =
        (fieldName: keyof ProcessOwnersManagePageFields) =>
        (newValue: ReactNode) => {
            setQuery((prev: ProcessOwnersManagePageFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }

    const deleteProcessOwner = async (processOwnerId: number) => {
        try {
            await deleteAdminProcessOwner(processOwnerId)
            dispatch(showSnackbar('Process owner deleted successfully'))
            setSearchResults((prev) => {
                return prev.map((item) => {
                    return {
                        ...item,
                        processOwners: item.processOwners.filter(
                            (x) => x.id !== processOwnerId
                        ),
                    }
                })
            })
        } catch {
            dispatch(showSnackbar('Process owner deletion failed', true))
        }
    }
    const handleAddProcessOwners = async (query: AdminProcessOwnerAddQuery) => {
        try {
            const currentPage = page
            setLoading(true)
            setLoadingDesc('Adding Process owners...')
            setDialogMode(null)
            await addAdminProcessOwner(query)
            handleSearch()
            setPage(currentPage)
            dispatch(loadProfile())
            dispatch(showSnackbar('Process owner added successfully'))
        } catch (err) {
            const error: Partial<ApiError> = err
            if (error.status === 409) {
                dispatch(showSnackbar('Process owner exist', true))
            } else if (error.status === 403) {
                dispatch(
                    showSnackbar(
                        "You haven't permission to add process owner",
                        true
                    )
                )
            } else if (error.errors) {
                dispatch(showSnackbar(error?.errors[0]?.message, true))
            } else {
                dispatch(showSnackbar('Adding a process owner failed', true))
            }
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    return (
        <Content
            loading={loading}
            loadingDesc={loadingDesc}
            onRetry={() => setError(null)}
            variant="medium"
        >
            <PageTitle
                title="Process owners"
                desc="This panel allows you to manage Process owners who are responsible for their Unit (BA, DIV, PG) or Location (Region, Country)."
                to="	
                https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B510DF76B-C934-4DF9-A6E6-160E86F43F89%7D&file=CCRP%20Managing%20Process%20Owners.pptx&action=edit&mobileredirect=true"
            />

            {isProcessOwner && (
                <AddPersonButton onClick={() => setDialogMode('all')}>
                    Add Process owners
                </AddPersonButton>
            )}

            <SearchPanel
                initOpen={true}
                disabledTextSearch
                placeholder="Process owner search"
                onEnter={() => !isSearching && handleSearch()}
                searchText={searchText}
                onChange={(text) => {
                    setSearchText(text)
                }}
                onClearText={() => setSearchText('')}
                disabled={isSearching}
            >
                <GridContainer>
                    <FormGridItem fullWidth>
                        <EmployeeSelect
                            className={classes.field}
                            disabled={isSearching}
                            label="Employee"
                            employee={query.employee}
                            onEmployeeChanged={handleChange('employee')}
                        />
                    </FormGridItem>

                    <FormGridItem fullWidth>
                        <MultiDivisionSelect
                            className={classes.field}
                            disabled={isSearching}
                            selectedDivisions={query.businessAreas}
                            onBusinessAreasChanged={handleChange(
                                'businessAreas'
                            )}
                        />
                    </FormGridItem>

                    <FormGridItem fullWidth>
                        <MultiBusinessUnitSelect
                            className={classes.field}
                            divisions={query.businessAreas}
                            disabled={isSearching}
                            selectedBusinessUnits={query.divisions}
                            onBusinessUnitChanged={handleChange('divisions')}
                            displayAll
                        />
                    </FormGridItem>

                    <FormGridItem fullWidth>
                        <MultiProductGroupSelect
                            className={classes.field}
                            division={query.businessAreas}
                            businessUnit={query.divisions}
                            disabled={isSearching}
                            selectedProductsGroups={query.productGroups}
                            onProductsGroupsChanged={handleChange(
                                'productGroups'
                            )}
                            displayAll
                        />
                    </FormGridItem>

                    <FormGridItem>
                        <MultiRegionSelect
                            className={classes.field}
                            disabled={isSearching}
                            selectedRegions={query.regions}
                            onRegionsChanged={handleChange('regions')}
                        />
                    </FormGridItem>

                    <FormGridItem>
                        <MultiCountrySelect
                            className={classes.field}
                            disabled={
                                Boolean(query?.regions?.length) && isSearching
                            }
                            regions={query.regions}
                            selectedCountries={query.countries}
                            onCountriesChanged={handleChange('countries')}
                        />
                    </FormGridItem>
                </GridContainer>

                <AdminSearchPanelActions
                    spacing={true}
                    isSearching={isSearching}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>

            <ProcessOwnersManagePageResults
                noResults={noResults}
                page={page}
                isSearching={isSearching}
                searchResults={searchResults}
                openDialog={openDialog}
                deleteProcessOwner={deleteProcessOwner}
                errorVisible={Boolean(error)}
                onPageChange={handlePageChange}
            />

            <ProcessOwnersManagePageDialog
                dialogMode={dialogMode}
                onClose={() => setDialogMode(null)}
                onAddProcessOwners={handleAddProcessOwners}
                organizationalInfo={organizationalInfo}
            />
        </Content>
    )
}
