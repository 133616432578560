import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    saveResolutionSectionDone,
    setIssueActionFail,
    ISSUE_SAVE_RESOLUTION_CALL,
    updateIssueRowVersion,
} from '..'

import { RootState } from 'store/types'
import { saveResolutionSection } from 'api'
import { AttachmentTypes } from 'types/enums'
import { showSnackbar } from 'store/app'

export function* saveResolutionSectionAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { resolutionSection, attachments, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        if (!resolutionSection) {
            return
        }
        const { data } = yield call(
            saveResolutionSection,
            {
                ...resolutionSection,
                rcaAttachments: attachments.filter(
                    (a) => a.attachmentType === AttachmentTypes.Resolution_Rca
                ),
            },
            changeLog?.resolve ?? {},
            cancelSource.token
        )
        yield put(saveResolutionSectionDone(data))
        yield put(updateIssueRowVersion(data.rowVersion))
        yield put(showSnackbar('Issue resolution section was saved'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_SAVE_RESOLUTION_CALL, error))
        yield put(showSnackbar('Cannot save Issue resolution section', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveResolutionSectionSaga() {
    yield takeLatest([ISSUE_SAVE_RESOLUTION_CALL], saveResolutionSectionAction)
}
