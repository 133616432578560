import EditIcon from '@mui/icons-material/Edit'
import { IconButton, ListItem, Typography } from '@mui/material'
import { ActionPlanContext } from 'ActionPlan/context/ActionPlanContext'
import { useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { ActionItem } from 'types/actionPlanTypes'
import { dataTestId, humanizeDateTime } from 'utils'
import { useActionPlanSectionStyles } from '../ActionPlanSection.style'

export interface ActionPlanSectionItemProps {
    item: ActionItem
}

export const ActionPlanSectionItem = (props: ActionPlanSectionItemProps) => {
    const { id: issueIdOrNumber } = useParams<{ id: string }>()

    const { openActionItem } = useContext(ActionPlanContext)
    const { item } = props
    const classes = useActionPlanSectionStyles()
    const time = useMemo(() => {
        humanizeDateTime(item.actionDeadline)
    }, [item])
    return (
        <ListItem
            className={classes.item}
            {...dataTestId(`ACTION_ITEM_ISSUE_FORM_${item.actionName}`)}
        >
            <div>
                <Typography variant="caption">
                    Action deadline:{time}
                </Typography>
                <Typography className={classes.actionName} variant="body2">
                    {item.actionName}
                </Typography>
            </div>
            <IconButton
                onClick={() =>
                    openActionItem(issueIdOrNumber, item.actionItemId)
                }
                size="large">
                <EditIcon
                    className={classes.icon}
                    {...dataTestId('ACTION_EDIT_BUTTON')}
                />
            </IconButton>
        </ListItem>
    );
}
