export const DASHBOARD_LOAD_PROFILE_CALL = 'DASHBOARD_LOAD_PROFILE_CALL'
export const DASHBOARD_LOAD_PROFILE_DONE = 'DASHBOARD_LOAD_PROFILE_DONE'
export const DASHBOARD_LOAD_PROFILE_FAIL = 'DASHBOARD_LOAD_PROFILE_FAIL'
export const DASHBOARD_LOAD_AVATAR_CALL = 'DASHBOARD_LOAD_AVATAR_CALL'
export const DASHBOARD_LOAD_AVATAR_DONE = 'DASHBOARD_LOAD_AVATAR_DONE'
export const DASHBOARD_OPEN_SEARCH = 'DASHBOARD_OPEN_SEARCH'
export const DASHBOARD_LOAD_ISSUES_CALL = 'DASHBOARD_LOAD_ISSUES_CALL'
export const DASHBOARD_LOAD_ISSUES_DONE = 'DASHBOARD_LOAD_ISSUES_DONE'
export const DASHBOARD_LOAD_ISSUES_FAIL = 'DASHBOARD_LOAD_ISSUES_FAIL'
export const DASHBOARD_DOWNLOAD_ANALYZER = 'DASHBOARD_DOWNLOAD_ANALYZER'
