import { useSelector } from 'react-redux'
import { DashboardStoreState } from '../store/dashboard'
import { RootState } from 'store/types'
import { UserProfile } from '../types/profile'

export const useProfile = (): UserProfile => {
    const { profile } = useSelector<RootState, DashboardStoreState>(
        (state) => state?.dashboard
    )
    return profile
}
