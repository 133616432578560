import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useIssueAppBarStyle = makeStyles(
    (theme: AbbTheme) => ({
        title: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            margin: theme.spacing(0, 2),
            overflow: 'hidden',
        },
        ellipsis: {
            display: 'inline',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        back: {
            // marginRight: theme.spacing(3),
        },
        menuItem: {
            minHeight: 42,
            minWidth: 160,
        },
        label: {
            marginLeft: theme.spacing(2),
        },
    }),
    { name: 'IssueAppBar' }
)
