import {
    Accordion,
    AccordionDetails,
    AccordionDetailsProps,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const useInternalAccordionStyles = makeStyles(
    {
        root: {
            margin: 0,
            paddingLeft: 0,
            zIndex: 0,
            '&$expanded': {
                margin: 0,
            },
        },
        expanded: {},
    },
    { name: 'InternalAccordionStyles' }
)

export const InternalAccordion = (props: AccordionProps) => {
    const classes = useInternalAccordionStyles()
    return <Accordion {...props} className={classes.root} />
}

const useInternalAccordionSummary = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginLeft: 0,
            minHeight: 56,
            marginRight: theme.spacing(0.5),
            paddingLeft: 0,
            paddingRight: 0,
            '&$expanded': {
                minHeight: 56,
            },
        },
        expanded: {},
        content: {
            margin: 0,
            '&$expanded': {
                margin: 0,
            },
        },
        expandIcon: {
            marginRight: 0,
        },
    }),
    { name: 'InternalAccordionSummary' }
)

export const InternalAccordionSummary = (props: AccordionSummaryProps) => {
    const classes = useInternalAccordionSummary()
    return (
        <AccordionSummary
            {...props}
            className={clsx(classes.root, classes.expandIcon)}
        />
    )
}

const useInternalAccordionDetails = makeStyles(
    () => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            padding: 0,
        },
    }),
    { name: 'InternalAccordionDetails' }
)

export const InternalAccordionDetails = (props: AccordionDetailsProps) => {
    const classes = useInternalAccordionDetails()
    return <AccordionDetails {...props} className={classes.root} />
}
