import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { ExcelExportColumnConfig, generateExcelFromTemplate1 } from 'utils'
import { ExportButton } from 'components'
import { AllInOneColumn } from 'types/models'
import { ReportResult } from 'types/reportTypes'

export interface ReportExcelExportProps {
    fileName: string
    columns: AllInOneColumn[]
    rows: ReportResult[]
    additionalColumnSettings: Record<
        string,
        Partial<ExcelExportColumnConfig<ReportResult>>
    >
}

export const ReportExcelExport = (props: ReportExcelExportProps) => {
    const dispatch = useDispatch()
    const { rows, columns, fileName, additionalColumnSettings } = props
    const [loading, setLoading] = useState(false)

    const excelExport = async () => {
        setLoading(true)
        try {
            await generateExcelFromTemplate1<ReportResult>(
                {
                    columns: columns.map((c) => {
                        const config = additionalColumnSettings[c.code]
                        return {
                            name: c.name,
                            getValue: (item) => {
                                const value = item[c.code] ?? ''
                                return typeof value == 'boolean'
                                    ? value
                                        ? 'TRUE'
                                        : 'FALSE'
                                    : value ?? ''
                            },
                            ...(config ?? {}),
                        }
                    }),
                },
                rows,
                {
                    fileName,
                }
            )
        } catch {
            dispatch(showSnackbar('Export failed'))
        } finally {
            dispatch(showSnackbar('Export completed successfully'))
            setTimeout(() => setLoading(false), 1000)
        }
    }

    return (
        rows.length > 0 && (
            <ExportButton loading={loading} onClick={excelExport}>
                Export to Excel
            </ExportButton>
        )
    )
}
