import axios from 'axios'
import { call, cancelled, takeLatest, put } from 'redux-saga/effects'
import { returnToIssueOwner as returnToIssueOwnerApi } from 'api'
import {
    loadIssue,
    returnToIssueOwnerDone,
    setIssueActionFail,
    ISSUE_RETURN_TO_OWNER_CALL,
} from '..'

import { ReturnToIssueOwnerCommand } from '../types'
import { removeMyActionById } from 'store/myActions/actions'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { ISSUE_ACTION_FAIL } from '../actionsTypes'

export function* returnToIssueOwnerAction(action: ReturnToIssueOwnerCommand) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    try {
        yield call(returnToIssueOwnerApi, query)
        yield put(returnToIssueOwnerDone())
        yield put(
            removeMyActionById(query.issueId, IssueActionsTypes.To_Assign)
        )
        yield put(loadIssue(query.issueId))
        yield put(showSnackbar('Issue was returned to Issue owner'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_ACTION_FAIL, error))
        yield put(showSnackbar("Issue wasn't return to Issue owner!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* returnToIssueOwnerSaga() {
    yield takeLatest([ISSUE_RETURN_TO_OWNER_CALL], returnToIssueOwnerAction)
}
