import { Typography, useTheme } from '@mui/material'
import { useEffect } from 'react'

import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Button, Content } from 'components'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import {
    clearIssue,
    initNewIssue,
    IssueStoreState,
    ISSUE_SUBMIT_CALL,
    saveAsDraft,
    setIssueActionFail,
    submitDraft,
} from 'store/issue'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueValuesKeys } from 'types/enums'
import { NEW_ISSUE_PAGE } from '../consts/selectorIds'
import LightModeSwitch from './components/LightModeSwitch'
import CaptureContent from './Sections/CaptureContent'
import { checkCharactersLimit } from './Sections/utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
        },
        content: {
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            '&$dense': {
                padding: theme.spacing(0.5, 2),
            },
        },
        dense: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        section: {
            marginTop: theme.spacing(2),
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        primaryAction: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'NewIssue' }
)

export const NewIssue = (props: any) => {
    const classes = useStyles(props)
    const theme = useTheme()
    const dispatch = useDispatch()
    const { loading, loadingDesc, error, issueNumber, captureSection } =
        useSelector<RootState, IssueStoreState>((state) => state.issue)
    const initIssueDraft = () => {
        if (!Boolean(captureSection)) dispatch(initNewIssue())
    }
    useEffect(() => {
        if (issueNumber) dispatch(clearIssue())
        initIssueDraft()
    }, [issueNumber])
    const handleSaveAsDraft = () => {
        dispatch(saveAsDraft())
    }
    const handleSubmitIssue = () => {
        if (checkCharactersLimit(captureSection?.summary?.length, 2000)) {
            dispatch(showSnackbar('Issue not submitted!', true))
            dispatch(
                setIssueActionFail(ISSUE_SUBMIT_CALL, {
                    status: 412,
                    errors: [
                        {
                            field: IssueValuesKeys.Summary,
                            message:
                                'The field Issue Summary must be a string with a maximum length of 2000.',
                        },
                    ],
                })
            )
            return
        }
        dispatch(submitDraft(true))
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Content
            coverBackground={false}
            loading={loading}
            loadingDesc={loadingDesc}
            error={error}
            onRetry={initIssueDraft}
            variant="medium"
        >
            <Helmet>
                <title>New Issue</title>
            </Helmet>
            <div className={classes.root}>
                <section className={clsx(classes.content, classes.dense)}>
                    <Typography
                        variant="h2"
                        style={{
                            fontWeight: theme.typography
                                .fontWeightBold as number,
                        }}
                    >
                        New Issue
                    </Typography>
                    <LightModeSwitch />
                </section>
                <section className={classes.content}>
                    <CaptureContent />
                </section>
                <section className={clsx(classes.content, classes.actions)}>
                    <Button
                        onClick={handleSaveAsDraft}
                        color="primary"
                        {...NEW_ISSUE_PAGE.ACTION_BUTTONS.SAVE_AS_DRAFT}
                        eventName="Save as draft"
                    >
                        Save as draft
                    </Button>
                    <Button
                        className={classes.primaryAction}
                        onClick={handleSubmitIssue}
                        color="secondary"
                        eventName="Submit issue (New draft)"
                        {...NEW_ISSUE_PAGE.ACTION_BUTTONS.SUBMIT_ISSUE}
                    >
                        Submit issue
                    </Button>
                </section>
            </div>
        </Content>
    )
}

export default NewIssue
