import axios, { AxiosResponse, CancelToken } from 'axios'
import { MyDashboardQuery } from '../types/queries'

export const exportMyIssues = (
    query: MyDashboardQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(`/my/issues/export2`, query, {
        cancelToken,
        responseType: 'blob',
    })

export const exportMyActions = (
    query: MyDashboardQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(`/my/actions/export`, query, {
        cancelToken,
        responseType: 'blob',
    })
