import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { generateExcelFromTemplate1, getPrettyDate } from 'utils'
import {
    ActionPlanExcelRowItem,
    ActionPlanItemsQuery,
    ActionPlanItemViewModel,
    generateRolesAndStatuses,
} from '.'
import { ExportButton, ExportButtonProps } from 'components'

export interface ActionPlanExportSectionProps extends ExportButtonProps {
    actionPlanItemsQuery: ActionPlanItemsQuery
    itemsFiltred: ActionPlanItemViewModel[]
}

export const ActionPlanExportSection = (
    props: ActionPlanExportSectionProps
) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const { actionPlanItemsQuery, itemsFiltred, ...other } = props
    const exportItems = async () => {
        setLoading(true)
        const [statuses, roles] = generateRolesAndStatuses(actionPlanItemsQuery)
        const filters = [
            {
                name: 'Statuses',
                value: statuses,
            },
            {
                name: 'Roles',
                value: roles,
            },
        ]

        const items = itemsFiltred.map((item) => {
            const { resolvingUnit } = item
            const { organizationalInfo, address, balId, countryCode, city } =
                resolvingUnit
            const { division, businessUnit, productGroup } = organizationalInfo
            return {
                actionName: item.actionName,
                actionType: item.actionTypeName,
                actionOwner: item.actionOwnerFullName,
                actionCreator: item.actionCreatorFullName,
                creationDate: item.creationDate,
                actionDeadline: item.actionDeadline,
                completionDate: item.completionDate,
                activities: item.actionDescription,
                CCRPNo: item.issueNumber,
                ruBusinessArea: division.name,
                ruBusinessAreaCode: division.code,
                ruDIV: businessUnit.name,
                ruDIVCode: businessUnit.code,
                ruPG: productGroup.name,
                ruPGCode: productGroup.code,
                ruCountry: countryCode,
                ruCity: city,
                ruAddress: address,
                ruBALID: balId,
            }
        })

        try {
            await generateExcelFromTemplate1<ActionPlanExcelRowItem>(
                {
                    columns: [
                        {
                            name: 'Action name',
                            getValue: ({ actionName }) => actionName,
                        },
                        {
                            name: 'Action type',
                            getValue: ({ actionType }) => actionType,
                        },
                        {
                            name: 'Action owner',
                            getValue: ({ actionOwner }) => actionOwner,
                        },
                        {
                            name: 'Action creator',
                            getValue: ({ actionCreator }) => actionCreator,
                        },
                        {
                            name: 'Creation date (UTC+2)',
                            getValue: ({ creationDate }) =>
                                getPrettyDate(creationDate),
                        },
                        {
                            name: 'Action deadline (UTC+2)',
                            getValue: ({ actionDeadline }) =>
                                getPrettyDate(actionDeadline),
                        },
                        {
                            name: 'Completion date (UTC+2)',
                            getValue: ({ completionDate }) =>
                                getPrettyDate(completionDate),
                        },
                        {
                            name: 'Activities',
                            getValue: ({ activities }) => activities,
                        },
                        { name: 'CCRP No.', getValue: ({ CCRPNo }) => CCRPNo },
                        {
                            name: 'Resolving Unit Business Area',
                            getValue: ({ ruBusinessArea }) => ruBusinessArea,
                        },
                        {
                            name: 'Resolving Unit Business Area Code',
                            getValue: ({ ruBusinessAreaCode }) =>
                                ruBusinessAreaCode,
                        },
                        {
                            name: 'Resolving Unit DIV',
                            getValue: ({ ruDIV }) => ruDIV,
                        },
                        {
                            name: 'Resolving Unit DIV Code',
                            getValue: ({ ruDIVCode }) => ruDIVCode,
                        },
                        {
                            name: 'Resolving Unit PG',
                            getValue: ({ ruPG }) => ruPG,
                        },
                        {
                            name: 'Resolving Unit PG Code',
                            getValue: ({ ruPGCode }) => ruPGCode,
                        },
                        {
                            name: 'Resolving Unit Country',
                            getValue: ({ ruCountry }) => ruCountry,
                        },
                        {
                            name: 'Resolving Unit City',
                            getValue: ({ ruCity }) => ruCity,
                        },
                        {
                            name: 'Resolving Unit Address',
                            getValue: ({ ruAddress }) => ruAddress,
                        },
                        {
                            name: 'Resolving Unit BALID',
                            getValue: ({ ruBALID }) => ruBALID,
                        },
                    ],
                },
                items,
                {
                    filters,
                    fileName: 'export-my-action-plan-items',
                }
            )
        } catch (err) {
            dispatch(showSnackbar('Export failed'))
        } finally {
            dispatch(showSnackbar('Export completed successfully'))
            setTimeout(() => setLoading(false), 1000)
        }
    }

    const isVisible = 0 < itemsFiltred.length

    return isVisible ? (
        <ExportButton loading={loading} onClick={exportItems} {...other}>
            Export to Excel
        </ExportButton>
    ) : null
}
