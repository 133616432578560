import { call, put, takeEvery } from 'redux-saga/effects'
import { getMyIssue, getMyIssue2 } from 'api'
import { refreshMyIssueByIdDone, rrefreshMyIssueByIdError } from '../actions'
import { MY_ISSUES_REFRESH_BY_ID_CALL } from '../actionTypes'
import { RefreshMyIssueCommand } from '../types'
import { NEW_DASHBOARD } from 'features'

export function* refreshMyIssue() {
    yield takeEvery(
        MY_ISSUES_REFRESH_BY_ID_CALL,
        function* (action: RefreshMyIssueCommand) {
            try {
                const { data } = NEW_DASHBOARD
                    ? yield call(getMyIssue2, action.payload)
                    : yield call(getMyIssue, action.payload)
                yield put(refreshMyIssueByIdDone(data))
            } catch (error) {
                yield put(rrefreshMyIssueByIdError(error))
            }
        }
    )
}
