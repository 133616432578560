import { FlagOutlined } from '@mui/icons-material'
import { Pagination } from '@mui/lab'
import {
    Button,
    Collapse,
    List,
    ListItem,
    ListItemText,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AdminDialog, AdminDialogProps } from 'Admin'
import { AdminCustomer, searchAdminCustomers } from 'api'
import clsx from 'clsx'
import {
    ChipLabel,
    CircularProgressLoader,
    CitySelect,
    CountrySelect,
    ExpandButton,
    FlexGrow,
    FormGridItem,
    GridContainer,
    TextField,
} from 'components'
import { ChangeEvent, ReactNode, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AbbTheme } from 'styles/createAbbTheme'
import { FilterBase } from 'types/models'
import { SelectedCustomersField } from '.'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginBottom: 0,
        },
        icon: {
            margin: theme.spacing(0, 1),
        },
        filters: {
            padding: theme.spacing(1, 2),
            boxSizing: 'border-box',
        },
        actions: {
            display: 'flex',
            flexDirection: 'row',
            height: 48,
            alignItems: 'center',
            paddingRight: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            '&$collapsedFilters': {
                borderTop: `none`,
            },
        },
        results: {
            minHeight: 60,
            maxHeight: 320,
            overflowY: 'scroll',
            overflowX: 'hidden',
        },
        noResults: {
            marginLeft: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        chipLabel: {
            marginLeft: theme.spacing(1),
        },
        collapsedFilters: {},
    }),
    { name: 'CustomerDialogPicker' }
)

export interface CustomerDialogPickerProps
    extends Omit<AdminDialogProps, 'children' | 'title' | 'renderActions'> {
    onAddCustomers: (selectedCustomers: AdminCustomer[]) => void
}

interface CustomerContactsManagePageFields {
    customerName: string
    country: FilterBase
    city: FilterBase
    address: string
    zipCode: string
    bussinesPartnerGUID: string
}

const getInitQuery = (): CustomerContactsManagePageFields => ({
    customerName: '',
    country: null,
    city: null,
    address: '',
    zipCode: '',
    bussinesPartnerGUID: '',
})

const PAGE_SIZE = 20

export const CustomerDialogPicker = (props: CustomerDialogPickerProps) => {
    const { onAddCustomers, onClose, ...other } = props
    const classes = useStyles()
    const [selectedCustomers, setSelectedCustomers] = useState<AdminCustomer[]>(
        []
    )
    const [loading, setLoading] = useState(false)
    const [expanded, setExpanded] = useState(true)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<AdminCustomer[]>([])
    const results = useMemo<AdminCustomer[]>(() => {
        if (!Array.isArray(data)) {
            return []
        }
        const startIndex = (page - 1) * PAGE_SIZE
        const endIndex = page * PAGE_SIZE - 1
        return data.reduce((prev, item, index) => {
            if (startIndex <= index && index <= endIndex) {
                prev.push(item)
            }
            return prev
        }, [])
    }, [data, page, loading])
    const [query, setQuery] = useState<CustomerContactsManagePageFields>(
        getInitQuery()
    )
    const dispatch = useDispatch()
    const handleSearch = async () => {
        const someFilters = Object.keys(query).some((x) => Boolean(query[x]))
        if (!someFilters && query.customerName === '') {
            dispatch(showSnackbar('Add search criterias', true))
            return
        }
        if (!someFilters && query.customerName.length < 3) {
            dispatch(showSnackbar('Too short search phrease', true))
            return
        }

        try {
            setLoading(true)
            const { data } = await searchAdminCustomers({
                cityCode: query.city?.code ? query.city.code : null,
                countryCode: query.country?.code ? query.country.code : null,
                guid: query.bussinesPartnerGUID
                    ? query.bussinesPartnerGUID
                    : null,
                name: query.customerName,
                street: query.address ? query.address : null,
                zipCode: query.zipCode ? query.zipCode : null,
            })
            setData(data)
            setPage(1)
            setExpanded(false)
        } catch (err) {
            dispatch(showSnackbar('Customer search error', true))
        } finally {
            setLoading(false)
        }
    }

    const handleChange =
        (fieldName: keyof CustomerContactsManagePageFields) =>
        (newValue: ReactNode) => {
            setQuery((prev: CustomerContactsManagePageFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }

    const handleChangeText =
        (fieldName: keyof CustomerContactsManagePageFields) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setQuery((prev) => ({
                ...prev,
                [fieldName]: e.target.value,
            }))
        }

    const getTextFieldProps = (
        fieldName: keyof CustomerContactsManagePageFields
    ) => {
        return {
            value: query[fieldName] ?? '',
            onChange: handleChangeText(fieldName),
            disabled: loading,
        }
    }
    const handleAddItems = () => {
        onAddCustomers([...selectedCustomers])
        onClose()
        setSelectedCustomers([])
    }
    return (
        <AdminDialog
            title="Add customer"
            onClose={onClose}
            renderActions={() => (
                <>
                    {0 < data.length && (
                        <Pagination
                            page={page}
                            size="small"
                            count={Math.ceil(data.length / PAGE_SIZE)}
                            hidePrevButton={page === 1}
                            hideNextButton={
                                page === Math.ceil(data.length / PAGE_SIZE)
                            }
                            onChange={(_, page) => setPage(page)}
                        />
                    )}
                    <FlexGrow />
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        disabled={0 === selectedCustomers.length}
                        color="secondary"
                        onClick={handleAddItems}
                    >
                        Add
                    </Button>
                </>
            )}
            disabledContentPadddings={true}
            {...other}
        >
            <SelectedCustomersField
                selectedItems={selectedCustomers}
                onClear={() => setSelectedCustomers([])}
                onDelete={(guid) => {
                    setSelectedCustomers((prev) => {
                        const index = prev.findIndex((s) => s.guid === guid)
                        prev.splice(index, 1)
                        return [...prev]
                    })
                }}
            />
            <Collapse in={expanded}>
                <GridContainer className={classes.filters}>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Customer name"
                            {...getTextFieldProps('customerName')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Bussines Partner GUID"
                            {...getTextFieldProps('bussinesPartnerGUID')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <CountrySelect
                            className={classes.field}
                            disabled={loading}
                            selectedCountry={query.country}
                            onCountryChanged={handleChange('country')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <CitySelect
                            className={classes.field}
                            disabled={loading}
                            country={query.country}
                            selectedCity={query.city}
                            onCityChanged={handleChange('city')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Address"
                            value={query.address}
                            onChange={handleChangeText('address')}
                            disabled={loading}
                            startAdornment={
                                <FlagOutlined
                                    fontSize="small"
                                    className={classes.icon}
                                />
                            }
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Zip code"
                            {...getTextFieldProps('zipCode')}
                        />
                    </FormGridItem>
                </GridContainer>
            </Collapse>
            <div
                className={clsx(
                    classes.actions,
                    !expanded && classes.collapsedFilters
                )}
            >
                <ExpandButton
                    expanded={expanded}
                    onClick={() => setExpanded(!expanded)}
                />
                <FlexGrow />
                <Button
                    disabled={loading}
                    onClick={() => {
                        setQuery(getInitQuery())
                        setExpanded(true)
                        setData([])
                    }}
                >
                    Clear
                </Button>
                <Button
                    disabled={loading}
                    onClick={handleSearch}
                    color="secondary"
                >
                    Search
                </Button>
            </div>
            <div className={classes.results}>
                {loading ? (
                    <CircularProgressLoader />
                ) : results.length === 0 ? (
                    <Typography className={classes.noResults}>
                        No results...
                    </Typography>
                ) : (
                    <List>
                        {results.map((c) => {
                            const isSelected = selectedCustomers.some(
                                (s) => s.guid === c.guid
                            )
                            return (
                                <ListItem
                                    key={c.guid}
                                    onClick={() => {
                                        if (isSelected) {
                                            setSelectedCustomers((prev) => {
                                                const index = prev.findIndex(
                                                    (s) => s.guid === c.guid
                                                )
                                                prev.splice(index, 1)
                                                return [...prev]
                                            })
                                        } else {
                                            setSelectedCustomers((prev) => [
                                                ...prev,
                                                { ...c },
                                            ])
                                        }
                                    }}
                                    selected={isSelected}
                                    dense
                                    button
                                >
                                    <ListItemText
                                        primary={
                                            <>
                                                {c.name}
                                                <ChipLabel
                                                    className={
                                                        classes.chipLabel
                                                    }
                                                    label={c.guid}
                                                    variant="grey"
                                                />
                                            </>
                                        }
                                        secondary={c?.address?.full ?? ''}
                                    />
                                </ListItem>
                            )
                        })}
                    </List>
                )}
            </div>
        </AdminDialog>
    )
}
