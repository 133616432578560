import AddCircle from '@mui/icons-material/AddCircle'
import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { DialogBase } from 'components/Dialogs'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    IssueStoreState,
    newIssueAddAttachment,
    saveAsDraft,
    setLightMode,
} from 'store/issue'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueStatuses } from 'types/enums'
import { AttachmentNewIssueStatus } from 'types/issueTypes'
import { FILE_MANAGER } from '../../consts/selectorIds'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1),
        },
        fileInput: {
            display: 'none',
        },
    }),
    { name: 'FileButton' }
)

export interface FileButtonProps {
    onFilesSelected: (files: Array<File>) => void
    acceptFileTypes?: Array<string>
    multiple: boolean
}

export const FileButton = (props: FileButtonProps) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const fileInput = useRef(null)
    const addFileButton = useRef(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const { onFilesSelected, acceptFileTypes, multiple } = props
    const {
        issueNumber,
        issueStatusId,
        lightMode,
        issueLoadDone,
        attachmentsNewIssue,
    } = useSelector<RootState, IssueStoreState>((state) => state.issue)

    useEffect(() => {
        if (
            issueLoadDone &&
            issueNumber &&
            attachmentsNewIssue === AttachmentNewIssueStatus.Add
        ) {
            dispatch(setLightMode(false))

            if (!lightMode) {
                setTimeout(() => {
                    window.scrollTo({
                        top: addFileButton?.current?.offsetTop,
                        behavior: 'smooth',
                    })
                }, 750)
                dispatch(
                    newIssueAddAttachment(AttachmentNewIssueStatus.Disabled)
                )
            }
        }
    }, [issueNumber, lightMode, issueLoadDone])

    const handleConfirmDialog = () => {
        setDialogOpen(false)
        dispatch(saveAsDraft())
        dispatch(newIssueAddAttachment(AttachmentNewIssueStatus.Add))
    }

    return (
        <>
            <DialogBase
                open={dialogOpen}
                title={'Confirm action?'}
                message={
                    'Adding an attachment saves the issue as a draft. Do you confirm?'
                }
                closeLabel={'Cancel'}
                confirmLabel={'Confirm'}
                onClose={() => setDialogOpen(false)}
                onConfirm={handleConfirmDialog}
            ></DialogBase>
            <Button
                ref={addFileButton}
                onClick={() => {
                    if (issueStatusId === IssueStatuses.New) {
                        setDialogOpen(true)
                        return
                    }
                    fileInput.current.click()
                }}
                {...FILE_MANAGER.UPLOAD_FILES_BUTTON}
            >
                <AddCircle className={classes.icon} />
                Upload files
            </Button>
            <input
                {...FILE_MANAGER.UPLOAD_FILES_INPUT}
                ref={fileInput}
                className={classes.fileInput}
                type="file"
                multiple={multiple}
                accept={acceptFileTypes && acceptFileTypes.join(',')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    onFilesSelected && onFilesSelected([...e.target.files])
                    e.target.value = ''
                }}
            />
        </>
    )
}

FileButton.defaultTypes = {
    acceptFileTypes: [],
    multiple: false,
}

export default FileButton
