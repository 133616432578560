import { Switch, Route } from 'react-router'
import {
    CUSTOMER_SUBSCRIPTIONS_LANG,
    CUSTOMER_SUBSCRIPTIONS,
    CUSTOMER_SURVEY,
    CUSTOMER_SURVEY_EFFORT_SCORE,
    CUSTOMER_SURVEY_EFFORT_SCORE_LANG,
    CUSTOMER_SURVEY_LANG,
    CUSTOMER_UNSUBSCRIBE,
} from './routes'
import { Survey, SurveyTranslationProvider, Unsubscribe } from './Survey'

export const SurveyApp = () => {
    return (
        <SurveyTranslationProvider>
            <Switch>
                <Route
                    path={[
                        CUSTOMER_SUBSCRIPTIONS_LANG,
                        CUSTOMER_SUBSCRIPTIONS,
                    ]}
                    component={Unsubscribe}
                    exact
                />
                <Route
                    path={[
                        CUSTOMER_SURVEY,
                        CUSTOMER_SURVEY_LANG,
                        CUSTOMER_SURVEY_EFFORT_SCORE,
                        CUSTOMER_SURVEY_EFFORT_SCORE_LANG,
                    ]}
                    component={Survey}
                    exact
                />
                <Route
                    path={[
                        CUSTOMER_UNSUBSCRIBE
                    ]}
                    component={Unsubscribe}
                    exact
                />
            </Switch>
        </SurveyTranslationProvider>
    )
}
