import { SvgIconProps, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { HtmlHTMLAttributes, ReactNode } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(0.5),
        },
        content: {
            paddingTop: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
        buttonIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'AdminEmployeesChips' }
)

export interface AdminEmployeesChipsSimpleProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    label?: string
    renderIcon?: (iconProps: Partial<SvgIconProps>) => ReactNode
    newConceptRender?: (children: ReactNode) => ReactNode
}

export const AdminEmployeesChipsSimple = (
    props: AdminEmployeesChipsSimpleProps
) => {
    const theme = useTheme()
    const classes = useStyles()
    const {
        label,
        renderIcon,
        className,
        children,
        newConceptRender,
        ...other
    } = props

    return (
        <div className={className} {...other}>
            {newConceptRender ? (
                newConceptRender(children)
            ) : (
                <div className={classes.root}>
                    <div className={classes.header}>
                        {renderIcon &&
                            renderIcon({
                                className: classes.icon,
                            })}
                        <Typography
                            variant="caption"
                            style={{
                                fontWeight: theme.typography
                                    .fontWeightMedium as number,
                            }}
                        >
                            {label}
                        </Typography>
                    </div>
                    <div className={classes.content}>{children}</div>
                </div>
            )}
        </div>
    )
}
