import { Cached as Retry, Clear as Cancel, Error } from '@mui/icons-material'
import { CircularProgress, IconButton, ListItem } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { uploadFile } from 'api/attachments'
import axios, { CancelTokenSource } from 'axios'
import { useEffect, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { AttachmentTypes } from 'types/enums'
import { Attachment } from 'types/issueTypes'
import { AttachmentUploadDto } from './FileManager'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        gutter: {
            paddingLeft: 8,
            paddingRight: 0,
            height: 36,
            paddingTop: 0,
            paddingBottom: 0,
        },
        flexGrow: {
            flexGrow: 1,
        },
        error: {
            color: theme.palette.secondary.main,
            width: 24,
            height: 24,
        },
        fileName: {
            width: 'calc(100% - 48px)',
            marginLeft: 16,
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: 14,
            fontWeight: 500,
            color: theme.palette.grey[700],
        },
        fileNameEror: {
            width: 'calc(100% - 48px)',
            marginLeft: 16,
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: 14,
            fontWeight: 500,
            color: theme.palette.secondary.main,
        },
        icon: {
            width: 24,
            height: 24,
            color: theme.palette.grey[700],
        },
        button: {
            marginRight: theme.spacing(1.5),
            padding: theme.spacing(0.75),
        },
        cancelButton: {
            marginRight: theme.spacing(0.75),
            padding: theme.spacing(0.75),
        },
    }),
    { name: 'FileUploadItem' }
)

enum FileUploadItemStatus {
    Uploading,
    Error,
    Cancel,
    Done,
}

interface FileUploadItemState {
    status: FileUploadItemStatus
    progress: number
    cancelToken?: CancelTokenSource
    variant?: 'determinate' | 'indeterminate'
}

export interface FileUploadItemProps {
    file: AttachmentUploadDto
    onFileUploaded: (attachment: Attachment, id: string) => void
    onFileRemoveFromPending: (id: string) => void
    issueId: number
    attachmentType: AttachmentTypes
}

export const FileUploadItem = (props: FileUploadItemProps) => {
    const {
        file,
        onFileUploaded,
        onFileRemoveFromPending,
        issueId,
        attachmentType,
        ...other
    } = props
    const fileData = file.file
    const classes = useStyles()
    const [state, setState] = useState<FileUploadItemState>({
        progress: 0,
        status: FileUploadItemStatus.Uploading,
        cancelToken: null,
        variant: 'indeterminate',
    })
    useEffect(() => {
        handleUploadFile()
    }, [file.id, fileData])

    const handleUploadFile = async () => {
        try {
            const cancelToken: CancelTokenSource = axios.CancelToken.source()
            setState((prev) => ({
                ...prev,
                status: FileUploadItemStatus.Uploading,
                variant: 'determinate',
                cancelToken,
            }))
            const { data } = await uploadFile(
                issueId,
                attachmentType,
                fileData,
                (e: any) => {
                    setState((prev) => ({
                        ...prev,
                        progress: 100 * (e.loaded / e.total),
                    }))
                },
                cancelToken.token
            )
            setState((prev) => ({
                ...prev,
                status: FileUploadItemStatus.Done,
                progress: 0,
                variant: 'indeterminate',
                cancelToken: null,
            }))
            onFileUploaded && onFileUploaded(data, file.id)
        } catch (err) {
            setState((prev) => ({
                ...prev,
                status: FileUploadItemStatus.Error,
                progress: 0,
                cancelToken: null,
            }))
        }
    }

    useEffect(() => {
        if (state.progress === 100) {
            setState((prev) => ({
                ...prev,
                variant: 'indeterminate',
            }))
        }
    }, [state.progress])

    return (
        <ListItem className={classes.gutter} dense {...other}>
            {state.status === FileUploadItemStatus.Error && (
                <Error className={classes.error} />
            )}
            {state.status === FileUploadItemStatus.Uploading && (
                <CircularProgress
                    color="secondary"
                    size={20}
                    variant={state.variant}
                    value={state.progress}
                ></CircularProgress>
            )}
            <div
                className={
                    state.status === FileUploadItemStatus.Error
                        ? classes.fileNameEror
                        : classes.fileName
                }
            >
                {fileData.name}
            </div>
            <span className={classes.flexGrow} />
            {state.status === FileUploadItemStatus.Error && (
                <IconButton onClick={handleUploadFile} size="large">
                    <Retry className={classes.icon} />
                </IconButton>
            )}
            {(state.variant !== 'indeterminate' ||
                state.status === FileUploadItemStatus.Error) && (
                <IconButton
                    className={classes.cancelButton}
                    onClick={() => {
                        state.cancelToken && state.cancelToken.cancel()
                        setState((prev) => ({
                            ...prev,
                            status: FileUploadItemStatus.Cancel,
                        }))
                        onFileRemoveFromPending(file.id)
                    }}
                    size="large"
                >
                    <Cancel className={classes.icon} />
                </IconButton>
            )}
        </ListItem>
    )
}

export default FileUploadItem
