import { DialogProps, Theme } from '@mui/material'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { makeStyles } from '@mui/styles'
import { KeyboardDatePicker } from 'components/Base'
import dayjs, { Dayjs } from 'dayjs'
import { useMinTargetDate } from 'hooks'
import { useEffect, useState } from 'react'
import { TargetResolvingDateChangeReasons } from 'types/enums'
import { EXTEND_TARGET_DATE_DIALOG } from '../../consts/selectorIds'
import { ExtendTargetResolutionDateQuery } from '../../types/actions'
import TextField from '../Base/TextField'
import TargetResolvingDataChangeReasonSelect from '../Fields/TargetResolvingDataChangeReasonSelect'
import Dialog from './Dialog'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        dialogContent: {
            paddingTop: theme.spacing(1),
            overflow: 'hidden',
        },
        field: {
            marginBottom: theme.spacing(3),
        },
        reasonDesc: {
            marginTop: theme.spacing(2),
        },
        description: {
            marginBottom: theme.spacing(3),
        },
    }),
    { name: 'ExtendTargetResolutionDateDialog' }
)

const MAX_LENGTH_CHANGE_REASON = 250

export interface ExtendTargetResolutionDateDialogProps extends DialogProps {
    issueId: number
    rowVersion: string
    reason?: string
    currentDate: string
    onExtendTargetDate: (date: ExtendTargetResolutionDateQuery) => void
}

export const ExtendTargetResolutionDateDialog = (
    props: ExtendTargetResolutionDateDialogProps
) => {
    const {
        open,
        onExtendTargetDate,
        onClose,
        issueId,
        rowVersion,
        reason = '',
        currentDate = dayjs().utc(),
        ...other
    } = props
    const classes = useStyles()
    const minDate = useMinTargetDate(5)
    const [
        newTargetResolutionAndVerificationDate,
        setNewTargetResolutionAndVerificationDate,
    ] = useState<Dayjs>(minDate.utc())
    const [
        targetResolutionDateChangeReasonId,
        setTargetResolutionDateChangeReasonId,
    ] = useState<TargetResolvingDateChangeReasons>(
        TargetResolvingDateChangeReasons.FurtherInformationRequired
    )
    const [
        targetResolutionDateChangeReason,
        setTargetResolutionDateChangeReason,
    ] = useState(reason)
    const [reasonValidationMessage, setReasonValidationMessage] = useState('')
    useEffect(() => {
        const date = dayjs(currentDate).utc()
        if (date.isSameOrAfter(minDate)) {
            setNewTargetResolutionAndVerificationDate(date)
        } else {
            setNewTargetResolutionAndVerificationDate(minDate.clone())
        }
    }, [issueId, rowVersion])
    const dialogCleanup = () => {
        setNewTargetResolutionAndVerificationDate(dayjs(currentDate).utc())
        setTargetResolutionDateChangeReasonId(
            TargetResolvingDateChangeReasons.MoreTimeRequired
        )
        setTargetResolutionDateChangeReason('')
        setReasonValidationMessage('')
    }

    const dialogFormValidation = () => {
        const changeReasonLength = targetResolutionDateChangeReason.length
        let isValid = true
        if (
            newTargetResolutionAndVerificationDate.isBefore(
                dayjs().utc(),
                'day'
            )
        ) {
            isValid = false
        }

        if (
            targetResolutionDateChangeReasonId ===
            TargetResolvingDateChangeReasons.Other
        ) {
            if (changeReasonLength === 0) {
                setReasonValidationMessage('Change reason is required')
                isValid = false
            } else if (MAX_LENGTH_CHANGE_REASON < changeReasonLength) {
                setReasonValidationMessage(
                    `The field Change reason must be a string with a maximum length of ${MAX_LENGTH_CHANGE_REASON}.`
                )
                isValid = false
            }
        }
        return isValid
    }

    const onExtend = () => {
        if (!dialogFormValidation()) {
            return
        }

        onExtendTargetDate &&
            onExtendTargetDate({
                issueId,
                targetResolutionAndVerificationDate:
                    newTargetResolutionAndVerificationDate
                        .endOf('day')
                        .toISOString(),
                targetResolutionDateChangeReasonId,
                targetResolutionDateChangeReason,
                rowVersion,
                changeLog: {
                    TargetResolutionAndVerificationDate: [
                        dayjs(currentDate).utc().format('YYYY-MM-DD'),
                        newTargetResolutionAndVerificationDate.format(
                            'YYYY-MM-DD'
                        ),
                    ],
                },
            })
        onClose({}, 'backdropClick')
    }

    const onReasonChange = (reasonId: TargetResolvingDateChangeReasons) => {
        setTargetResolutionDateChangeReasonId(reasonId)
        if (targetResolutionDateChangeReasonId !== reasonId) {
            setTargetResolutionDateChangeReason('')
            setReasonValidationMessage('')
        }
    }
    const dateLocal = dayjs(newTargetResolutionAndVerificationDate)
        .utc()
        .endOf('day')
        .local()
        .format('YYYY-MM-DD • HH:mm')

    return (
        <Dialog
            open={open}
            {...other}
            TransitionProps={{
                onExited: dialogCleanup,
            }}
        >
            <DialogTitle>Extend Target Date</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <div className={classes.description}>
                    Please note that the all dates are displayed in UTC.
                </div>
                <KeyboardDatePicker
                    onChange={(date) => {
                        setNewTargetResolutionAndVerificationDate(date.clone())
                    }}
                    value={newTargetResolutionAndVerificationDate}
                    slotProps={{
                        textField: {
                            helperText: `Local Date ${dateLocal}`,
                            ...EXTEND_TARGET_DATE_DIALOG.NEW_TARGET_DATE_PICKER,
                        },
                    }}
                    minDate={minDate}
                    className={classes.field}
                    label="New Target Date"
                />
                <TargetResolvingDataChangeReasonSelect
                    selectedReason={targetResolutionDateChangeReasonId}
                    onReasonChange={onReasonChange}
                    required
                    {...EXTEND_TARGET_DATE_DIALOG.DATE_CHANGE_REASON_SELECT}
                />
                <Collapse
                    in={
                        targetResolutionDateChangeReasonId ===
                        TargetResolvingDateChangeReasons.Other
                    }
                    className={classes.reasonDesc}
                >
                    <TextField
                        variant="filled"
                        {...EXTEND_TARGET_DATE_DIALOG.REASON_DESCRIPTION}
                        label="Reason description"
                        value={targetResolutionDateChangeReason}
                        helperText={reasonValidationMessage}
                        className={classes.field}
                        onChange={(e) =>
                            setTargetResolutionDateChangeReason(e.target.value)
                        }
                        multiline
                        error={0 < reasonValidationMessage.length}
                        fullWidth
                        rows={4}
                        required
                    />
                </Collapse>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => onClose(e, 'backdropClick')}
                    {...EXTEND_TARGET_DATE_DIALOG.ACTION_BUTTONS.CANCEL}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onExtend}
                    color="secondary"
                    {...EXTEND_TARGET_DATE_DIALOG.ACTION_BUTTONS
                        .CHANGE_TARGET_DATE}
                >
                    Change Target Date
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ExtendTargetResolutionDateDialog
