import { all } from 'redux-saga/effects'
import { loadAvatar } from './loadAvatar'
import { loadProfile } from './loadProfile'
import { loadMyDashboard } from './loadMyDashboard'
import { loadProfileDone } from './loadProfileDone'
import { downloadAnalyzerSaga } from './downloadAnalyzer'

export default function* root() {
    yield all([
        loadAvatar(),
        loadProfile(),
        loadMyDashboard(),
        loadProfileDone(),
        downloadAnalyzerSaga(),
    ])
}
