import { Typography, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { memo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            transition: 'marginLeft .4s',
            '@media (max-width: 768px)': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
    { name: 'ListTitle' }
)

export interface ListTitleProps extends TypographyProps {
    title: string
    count?: number | null
}

export const ListTitle = memo((props: ListTitleProps) => {
    const { className, title: titleProp, count = null, ...other } = props
    const classes = useStyles()
    const title = `${titleProp}  ${count !== null ? `(${count})` : ''}`
    return (
        <Typography
            className={clsx(className, classes.root)}
            variant="h6"
            {...other}
        >
            {title}
        </Typography>
    )
})

export default ListTitle
