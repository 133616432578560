import { ExitToApp, RefreshOutlined } from '@mui/icons-material'
import {
    Avatar,
    Button,
    CircularProgress,
    Drawer,
    DrawerProps,
    IconButton,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FlexGrow, Label, ValueView } from 'components'
import { DASHBOARD_PAGE } from 'consts/selectorIds'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DashboardStoreState, loadAvatar, loadProfile } from 'store/dashboard'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { CcrpRole } from 'types/profile'
import { dataTestId, EMPTY_VALUE_PLACEHOLDER, formatAddress } from 'utils'
import { RoleView } from './RoleView'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: 300,
            minHeight: '100%',
            '@media (max-width: 350px)': {
                width: 260,
            },
        },
        profile: {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingRight: theme.spacing(2),
            overflow: 'hi',
        },
        name: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            overflow: 'auto',
        },
        fullName: {
            lineHeight: '1rem',
            fontWeight: theme.typography.fontWeightBold as number,
        },
        geid: {
            color: theme.palette.grey[400],
        },
        email: {
            color: theme.palette.grey[600],
        },
        ellipsis: {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        avatar: {
            margin: theme.spacing(2),
            fontSize: theme.typography.body1.fontSize,
        },
        section: {
            ...theme.typography.body2,
            margin: theme.spacing(1, 2),
        },
        roles: {
            margin: theme.spacing(0.5, 2),
            padding: 0,
        },
        role: {
            ...theme.typography.body2,
            marginTop: theme.spacing(2),
            fontWeight: theme.typography.fontWeightMedium as number,
            '&:first-child': {
                marginTop: 0,
            },
        },
        actions: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: theme.spacing(2),
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            borderTop: `1px solid ${theme.palette.divider}`,
            height: 56,
        },
        exitToAppIcon: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'ProfileDetailsShell' }
)

export interface ProfileDetailsShellProps extends Omit<DrawerProps, 'anchor'> {
    logout: () => void
}

export const ProfileDetailsShell = (props: ProfileDetailsShellProps) => {
    const { logout, ...other } = props
    const classes = useStyles(props)
    const dispatch = useDispatch()
    const { profile, avatar, loadingProfile } = useSelector<
        RootState,
        DashboardStoreState
    >((state) => state?.dashboard)

    useEffect(() => {
        dispatch(loadAvatar())
    }, [dispatch])
    const address =
        profile &&
        (formatAddress({
            countryCode: profile?.countryCode,
            city: profile?.locationCity,
            address: profile?.address,
            zipCode: profile?.zipCode,
        }) ??
            EMPTY_VALUE_PLACEHOLDER)
    const phoneNumber =
        profile?.mobilePhone ?? profile?.officePhone ?? EMPTY_VALUE_PLACEHOLDER
    const roles: CcrpRole[] = profile?.roles
    const hasSystemRoles = Array.isArray(roles) && Boolean(roles.length)
    const handleRefreshProfile = () => dispatch(loadProfile())
    return (
        <Drawer anchor="right" {...other}>
            <div className={classes.content}>
                <div className={classes.profile}>
                    <Avatar className={classes.avatar} src={avatar}>
                        {profile?.initials}
                    </Avatar>
                    <div className={classes.name}>
                        {profile?.geid && (
                            <Typography
                                className={classes.geid}
                                variant="caption"
                            >
                                {profile.geid}
                            </Typography>
                        )}
                        <Typography
                            className={clsx(classes.ellipsis, classes.fullName)}
                            variant="body2"
                            {...DASHBOARD_PAGE.TOP_BAR.USER.NAME}
                        >
                            {clsx(profile?.firstName, profile?.lastName)}
                        </Typography>
                        <Typography
                            className={clsx(classes.email, classes.ellipsis)}
                            variant="body2"
                            {...DASHBOARD_PAGE.TOP_BAR.USER.EMAIL}
                        >
                            {profile?.email}
                        </Typography>
                    </div>
                </div>
                {address && (
                    <div className={classes.section}>
                        <ValueView
                            {...dataTestId('ADDRESS_DETAILS')}
                            label="Address"
                            value={address}
                            singleLine={false}
                        />
                    </div>
                )}
                {phoneNumber && (
                    <div className={classes.section}>
                        <ValueView
                            {...dataTestId('PHONE_DETAILS')}
                            label="Phone number"
                            value={phoneNumber}
                            singleLine={false}
                        />
                    </div>
                )}
                {hasSystemRoles && (
                    <div className={classes.section}>
                        <Label {...dataTestId('ROLES_IN_SYSTEM')}>
                            Roles in system
                        </Label>

                        <ul className={classes.roles}>
                            {roles.map((r) => (
                                <RoleView
                                    key={r.roleId}
                                    className={classes.role}
                                    roleDetails={r}
                                />
                            ))}
                        </ul>
                    </div>
                )}
                <span style={{ flexGrow: 1 }} />
                <div className={classes.actions}>
                    <IconButton
                        disabled={loadingProfile}
                        onClick={handleRefreshProfile}
                        size="large"
                    >
                        {loadingProfile ? (
                            <CircularProgress size={20} color="secondary" />
                        ) : (
                            <RefreshOutlined />
                        )}
                    </IconButton>
                    <FlexGrow />
                    <Button
                        {...dataTestId('LOGOUT')}
                        onClick={logout}
                        color="secondary"
                    >
                        Logout
                        <ExitToApp className={classes.exitToAppIcon} />
                    </Button>
                </div>
            </div>
        </Drawer>
    )
}

export default ProfileDetailsShell
