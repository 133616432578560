import GetAppIcon from '@mui/icons-material/GetApp'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { downloadAllAttachment } from 'api'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AbbTheme } from 'styles/createAbbTheme'
import { AttachmentTypes } from 'types/enums'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            width: 24,
            height: 24,
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'FileDownloadAll' }
)

export interface FileDownloadAllProps {
    issueId: number
    attachmentType: AttachmentTypes
}

export const FileDownloadAll = (props: FileDownloadAllProps) => {
    const classes = useStyles()
    const { attachmentType, issueId, ...other } = props
    const dispatch = useDispatch()
    const [downloading, setDownloading] = useState(false)

    const handleDownloadAllAttachments = () => {
        setDownloading(true)
        downloadAllAttachment(issueId, attachmentType)
            .then((resp) => {
                let a = document.createElement('a')
                document.body.appendChild(a)
                a.style.display = 'none'
                let blob = new Blob([resp.data], { type: 'application/zip' }),
                    url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = `${dayjs().local().format('YYYY-MM-DD')}_${
                    AttachmentTypes[attachmentType]
                }_Attachments`
                a.click()
                window.URL.revokeObjectURL(url)
                setDownloading(false)
            })
            .catch(() => {
                setDownloading(false)
                dispatch(
                    showSnackbar('Failed to download the attachments!', true)
                )
            })
    }
    return (
        <Button
            onClick={handleDownloadAllAttachments}
            disabled={downloading}
            {...other}
        >
            <GetAppIcon className={classes.icon} />
            Download all attachments
        </Button>
    )
}
