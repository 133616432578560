import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {},
        label: {
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        astrisk: {
            color: theme.palette.secondary.main,
        },
    }),
    { name: 'QuestionLabel' }
)

export interface QuestionLabelProps extends HtmlHTMLAttributes<HTMLDivElement> {
    label: string
    required?: boolean
}

export const QuestionLabel: FC<QuestionLabelProps> = (props) => {
    const { className, label, required = false, children, ...other } = props
    const classes = useStyles()
    return (
        <div className={clsx(classes.root, className)} {...other}>
            <Typography className={classes.label} variant="body2">
                {label} {required && <span className={classes.astrisk}>*</span>}
            </Typography>
            {children}
        </div>
    )
}
