import axios, { AxiosResponse } from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    ISSUE_SUBMIT_DONE,
    IssueStoreState,
    loadIssue,
    prepareDraft,
    SubmitDraftCommand,
    ISSUE_SUBMIT_CALL,
    setIssueActionFail,
} from '..'
import { showSnackbar } from 'store/app'
import { removeMyActionById } from 'store/myActions'
import { IssueActionsTypes, IssueStatuses } from 'types/enums'
import { replace } from 'connected-react-router'
import { urlBuilder } from 'utils'
import { ISSUE_VIEW } from 'Issue/routes'
import { RootState } from 'store/types'
import { submitDraft } from 'api'
import { Issue } from 'types/issueTypes'

export function* submitDraftAction(action: SubmitDraftCommand) {
    const cancelSource = axios.CancelToken.source()
    try {
        yield put(prepareDraft())
        const issue = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const coordinatorEmails =
            issue?.captureSection?.resolvingUnit?.coordinatorsEmails ?? []
        const draft = {
            coordinatorEmails: [...coordinatorEmails],
            ...issue.captureSection,
        }
        const { data } = (yield call(
            submitDraft,
            draft,
            issue.changeLog?.capture &&
                issue.issueStatusId !== IssueStatuses.New
                ? issue.changeLog?.capture
                : {},
            cancelSource.token
        )) as AxiosResponse<Issue>
        yield put(showSnackbar('Issue was submitted', false))
        const issueId = data?.issueId
        if (action?.payload?.saveAndSubmit) {
            yield put({ type: ISSUE_SUBMIT_DONE })
            yield put(
                replace(
                    urlBuilder(ISSUE_VIEW, {
                        id: issueId,
                    })
                )
            )
        } else {
            yield put(loadIssue(issueId))
            yield put(
                removeMyActionById(issue.issueId, IssueActionsTypes.To_Submit)
            )
            yield put(
                removeMyActionById(
                    issue.issueId,
                    IssueActionsTypes.To_Submit_Returned_To_Draft
                )
            )
        }
    } catch (error) {
        yield put(showSnackbar('Issue not submitted!', true))
        yield put(setIssueActionFail(ISSUE_SUBMIT_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* submitDraftSaga() {
    yield takeLatest([ISSUE_SUBMIT_CALL], submitDraftAction)
}
