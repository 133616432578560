import { Activity } from 'types/actions'
import { Employee } from 'types/issueTypes'
import { FilterBase } from 'types/models'

export enum EmployeeTypeEnum {
    All = 'All',
    RUProcessOwner = 'RU Process Owner',
    Coordinator = 'Coordinator',
}

export type EmployeeType =
    | EmployeeTypeEnum.All
    | EmployeeTypeEnum.RUProcessOwner
    | EmployeeTypeEnum.Coordinator

export enum EmployeeStatusEnum {
    All = 'All',
    OnlyActiveRUs = 'Only active RUs',
    InactiveRUs = 'Inactive RUs',
}

export type EmployeeStatus =
    | EmployeeStatusEnum.All
    | EmployeeStatusEnum.OnlyActiveRUs
    | EmployeeStatusEnum.InactiveRUs

export enum BusinessAreaActivityEnum {
    All = 'All',
    Local = 'Local',
    Global = 'Global',
}

export type BusinessAreaActivity =
    | BusinessAreaActivityEnum.All
    | BusinessAreaActivityEnum.Local
    | BusinessAreaActivityEnum.Global

export enum RUPersonsAssociationEnum {
    All = 'All',
    Assigned = 'Assigned',
    NotAssigned = 'Not assigned',
}

export type RUPersonsAssociation =
    | RUPersonsAssociationEnum.All
    | RUPersonsAssociationEnum.Assigned
    | RUPersonsAssociationEnum.NotAssigned

export interface ResolvingUnitsManagePageFields {
    businessArea: FilterBase
    division: FilterBase
    productGroup: FilterBase
    ruPersonsAssociation: RUPersonsAssociation
    region: FilterBase
    country: FilterBase
    city: FilterBase
    activity: Activity
    businessAreaActivity: BusinessAreaActivity
    employee: Employee
    employeeType: EmployeeType
    employeeStatus: EmployeeStatus
    ruPersonAssigned: boolean
    withoutActivePeople: boolean
}
