import { PlaceOutlined } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip, getShortOptionLabel } from 'components'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { CityFilterBase, CountryFilter, FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected } from './utils'

export interface MultiCitySelectProps extends Partial<BaseTextFieldProps> {
    selectedCities: CityFilterBase[]
    regions?: FilterBase[]
    countries: CountryFilter[]
    onCitiesChanged: (countries: CityFilterBase[]) => void
    displayAll?: boolean
}

export const MultiCitySelect = (props: MultiCitySelectProps) => {
    const {
        selectedCities = [],
        onCitiesChanged,
        regions = [],
        countries = [],
        error,
        helperText,
        disabled,
        displayAll = false,
        ...other
    } = props

    const { cities: allOptions, countries: allCountries } = useSelector(
        (state: RootState) => state.resources
    )
    const options = useMemo(() => {
        const filterByRegion = Array.isArray(regions) && 0 < regions.length
        const filterByCountry = Array.isArray(countries) && 0 < countries.length
        const regionMap = allCountries.reduce<Record<string, string>>(
            (prev, x) => {
                prev[x.code] = x.regionCode
                return prev
            },
            {}
        )
        return allOptions
            .filter((b) =>
                filterByRegion
                    ? regions.some((r) => r.code === regionMap[b.countryCode])
                    : true
            )
            .filter((b) =>
                filterByCountry
                    ? countries.some((d) => d.code === b.countryCode)
                    : true
            )
    }, [allOptions, allCountries, countries, regions])
    useEffect(() => {
        const filterByRegion = Array.isArray(regions) && 0 < regions.length
        const filterByCountry = Array.isArray(countries) && 0 < countries.length
        if (filterByRegion || filterByCountry) {
            const newSelectedCities = [...selectedCities]
            const productGroupsToRemove = []
            newSelectedCities.forEach((x, i) => {
                if (
                    !(
                        regions.map((r) => r.code).includes(x.regionCode) ||
                        countries.map((c) => c.code).includes(x.countryCode)
                    )
                ) {
                    productGroupsToRemove.push(i)
                }
            })
            if (0 < productGroupsToRemove.length) {
                productGroupsToRemove.forEach((i) => {
                    newSelectedCities.splice(i, 1)
                })
                onCitiesChanged(newSelectedCities)
            }
        }
    }, [countries, regions])
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | CityFilterBase>
    ) =>
        onCitiesChanged && onCitiesChanged([...values] as Array<CityFilterBase>)
    const isDisabled = !displayAll
        ? Array.isArray(countries) && countries?.length === 0
        : false
    return (
        <Autocomplete
            disabled={isDisabled || disabled}
            multiple={true}
            value={selectedCities}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            disableClearable={isDisabled}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          `(${f.countryCode}) ${f.name} `
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            noOptionsText={'No match cities'}
            renderOption={(props, option, state) => {
                return (
                    <li
                        {...props}
                        key={state.index}
                    >{`(${option.countryCode}) ${option.name}`}</li>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={`(${option.countryCode}) ${option.name}`}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    error={error}
                    helperText={helperText}
                    params={params}
                    label="Cities"
                    {...dataTestId('CITIES_COMMON')}
                    renderIcon={(props) => <PlaceOutlined {...props} />}
                    {...other}
                />
            )}
        />
    )
}
