import { Theme } from '@mui/material'
import { ClassNameMap, makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ActionsView } from 'components'
import { HtmlHTMLAttributes, ReactNode } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            marginBottom: theme.spacing(3),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        collapse: {
            width: '100%',
        },
        header: {},
        content: {
            padding: theme.spacing(0, 2),
        },
        actions: {
            padding: theme.spacing(0, 2),
        },
    }),
    { name: 'CardViewSimple' }
)

export interface CardViewSimpleProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    renderHeader?: () => ReactNode
    renderActions?: () => ReactNode
    classes?: Partial<ClassNameMap<'content' | 'header' | 'actions'>>
}

export const CardViewSimple = (props: CardViewSimpleProps) => {
    const {
        className,
        renderHeader,
        children,
        renderActions,
        classes,
        ...other
    } = props
    const classesLocal = useStyles()
    const actions = renderActions && renderActions()
    return (
        <div className={clsx(classesLocal.root, className)} {...other}>
            {renderHeader && (
                <div className={clsx(classesLocal.header, classes?.header)}>
                    {renderHeader()}
                </div>
            )}
            {children && (
                <div className={clsx(classesLocal.content, classes?.content)}>
                    {children}
                </div>
            )}
            {actions && (
                <ActionsView
                    className={clsx(classesLocal.actions, classes?.actions)}
                >
                    {actions}
                </ActionsView>
            )}
        </div>
    )
}
