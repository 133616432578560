import { Public } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface MultiRegionSelectProps extends Partial<BaseTextFieldProps> {
    selectedRegions: FilterBase[]
    onRegionsChanged: (regions: FilterBase[]) => void
}

export const MultiRegionSelect = (props: MultiRegionSelectProps) => {
    const options = useSelector((state: RootState) => state.resources.regions)
    const { selectedRegions = [], onRegionsChanged, disabled, ...other } = props
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | FilterBase>
    ) => onRegionsChanged && onRegionsChanged([...values] as Array<FilterBase>)
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedRegions}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            disableClearable={Boolean(disabled)}
            noOptionsText={'No match regions'}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Regions"
                    {...dataTestId('REGIONS_COMMON')}
                    renderIcon={(props) => <Public {...props} />}
                    {...other}
                />
            )}
        />
    )
}
