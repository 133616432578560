import axios, { AxiosResponse, CancelToken } from 'axios'
import {
    ExtendTargetResolutionDateQuery,
    ReturnToIssueOwnerQuery,
    VerifyContainmentActionQuery,
    RejectResolutionQuery,
    ReopenIssueQuery,
} from '../types/actions'
import {
    Issue,
    CaptureSection,
    AssignSection,
    ClosingSection,
    EffectivenessSection,
    ResolutionSection,
} from 'types/issueTypes'
import {
    ActionLog,
    Base64Attachment,
    IssueGlobalSearchResult,
    IssueSearchResult,
} from '../types/models'
import { IssueChangeLog } from '../store/issue'

const controllerName = '/issue'

/**
 * @param {number} issueIdOrNumber - issue id
 * @param {CancelToken} cancelToken - Axios CancelToken
 */
export const getIssue = (
    issueIdOrNumber: number | string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Issue>> =>
    axios.get(`${controllerName}/${issueIdOrNumber}`, { cancelToken })

/**
 * @param {object} captureSection - captureSection
 * @param {CancelToken} cancelToken - Axios CancelToken
 */
export const saveAsDraft = (
    captureSection: CaptureSection,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Issue>> =>
    axios.post(
        `${controllerName}/actions/save-draft`,
        { ...captureSection },
        { cancelToken }
    )

/**
 * @param {0bject} formData
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const extendTargetResolvingDate = (
    query: ExtendTargetResolutionDateQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.put(
        `${controllerName}/${query.issueId}/capture/target-resolution-date`,
        {
            ...query,
        },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const returnToIssueOwner = (
    query: ReturnToIssueOwnerQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/actions/return-to-io`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const saveAssignSection = (
    query: AssignSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AssignSection>> =>
    axios.put(
        `${controllerName}/${query.issueId}/assignment`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {number} issueId
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const getAssignSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AssignSection>> =>
    axios.get(`${controllerName}/${issueId}/assignment`, { cancelToken })

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const assignIssue = (
    query: AssignSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AssignSection>> =>
    axios.post(
        `${controllerName}/${query.issueId}/actions/assign`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const saveClosingSection = (
    query: ClosingSection,
    changeLog: IssueChangeLog,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ClosingSection>> =>
    axios.put(
        `${controllerName}/${query.issueId}/completion`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {number} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of GET request
 */
export const getClosingSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ClosingSection>> =>
    axios.get(`${controllerName}/${issueId}/completion`, { cancelToken })

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const verifyContainmentAction = (
    query: VerifyContainmentActionQuery,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/containment-actions/confirm`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const verifyContainmentActionExtended = (
    query: ResolutionSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.put(
        `${controllerName}/${query.issueId}/containment-actions/confirm`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {number} issueId
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const verifyResolution = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(`${controllerName}/${issueId}/resolution/verify`, null, {
        cancelToken,
    })

/**
 * @param {number} issueId
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const putVerifyResolution = (
    issueId: number,
    resolutionSection: ResolutionSection,
    changeLog: IssueChangeLog,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.put(
        `${controllerName}/${issueId}/resolution/verify`,
        { ...resolutionSection, changeLog },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const rejectResolution = (
    query: RejectResolutionQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/resolution/reject`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const rejectResolutionToRO = (
    query: RejectResolutionQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/resolution/io-reject`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const completeIssue = (
    query: ClosingSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/actions/complete`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of POST request
 */
export const reopenIssue = (
    query: ReopenIssueQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/${query.issueId}/effectiveness/reopen`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const putEffectivenessSection = (
    query: EffectivenessSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<EffectivenessSection>> =>
    axios.put(
        `${controllerName}/${query.issueId}/effectiveness`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {number} issueId
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getEffectivenessSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<EffectivenessSection>> =>
    axios.get(`${controllerName}/${issueId}/effectiveness`, { cancelToken })

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const postEffectivenessSection = (
    query: EffectivenessSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<EffectivenessSection>> =>
    axios.post(
        `${controllerName}/${query.issueId}/effectiveness/confirm`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {int} issueId
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const deleteDraft = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.delete(`${controllerName}/${issueId}/actions/delete`, { cancelToken })

/**
 * @param {object} captureSection
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const submitDraft = (
    captureSection: CaptureSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Issue>> =>
    axios.post(
        `${controllerName}/actions/submit`,
        { ...captureSection, changeLog },
        { cancelToken }
    )

export const searchIssues = (
    partialIssueNo: string,
    page: number = 1,
    pageSize: number = 10,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<IssueSearchResult>> =>
    axios.get(`/issues/search`, {
        cancelToken,
        params: {
            partialIssueNo,
            page,
            pageSize,
        },
    })

export const searchCognitiveIssues = (
    partialIssueNo: string,
    page: number = 1,
    pageSize: number = 10,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<IssueGlobalSearchResult>> =>
    axios.get(`/issues/cognitive-search`, {
        cancelToken,
        params: {
            partialIssueNo,
            page,
            pageSize,
        },
    })

export const getActionsLogs = (
    issueNo: string | number,
    pageNumber: number = null,
    pageSize: number = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<ActionLog>>> =>
    axios.get(controllerName + `/${issueNo}/action-log`, {
        cancelToken,
        params: {
            pageNumber,
            pageSize,
        },
    })

export const saveManualEntry = (
    issueIdOrNumber: string | number,
    command: { subject: string; body: string; attachments: Base64Attachment[] },
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionLog>> =>
    axios.post(
        controllerName + `/${issueIdOrNumber}/action-log/manual-entry`,
        { ...command },
        { cancelToken }
    )

export const getPowerPointOverview = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse> =>
    axios.get(controllerName + `/${issueId}/issue-power-point`, {
        cancelToken,
        responseType: 'blob',
    })

export const getSummaryReport = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse> =>
    axios.get(controllerName + `/${issueId}/report/CCRPSummary`, {
        cancelToken,
        responseType: 'blob',
    })
