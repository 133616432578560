import GetAppIcon from '@mui/icons-material/GetApp'
import { LinearProgress, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AdminEmployeesChipsProps } from 'Admin'
import { getEmployee } from 'api/employees'
import { AxiosResponse } from 'axios'
import clsx from 'clsx'
import { Button } from 'components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { Employee } from 'types/issueTypes'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(0.5),
        },
        content: {
            paddingTop: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
        buttonIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'AdminEmployeesChips' }
)

export const AdminEmployeesChips = (props: AdminEmployeesChipsProps) => {
    const theme = useTheme()
    const classes = useStyles()
    const { guids, label, renderIcon, renderEmployees, className, ...other } =
        props
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [employees, setEmployees] = useState<Employee[]>([])

    const loadEmployees = async () => {
        try {
            setLoading(true)
            const items: AxiosResponse<Employee>[] = await Promise.all(
                guids.map((guid) => getEmployee(guid))
            )
            const parseItems = items.map((item) => item.data)
            setEmployees(parseItems)
        } catch (err) {
            dispatch(showSnackbar('Failed to fetch Employees', true))
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={clsx(classes.root, className)} {...other}>
            <div className={classes.header}>
                {renderIcon &&
                    renderIcon({
                        className: classes.icon,
                    })}
                <Typography
                    variant="caption"
                    style={{
                        fontWeight: theme.typography.fontWeightMedium as number,
                    }}
                >
                    {label}
                </Typography>
            </div>
            <div className={classes.content}>
                {loading ? (
                    <LinearProgress
                        style={{ margin: theme.spacing(2, 0) }}
                        color="secondary"
                        variant="indeterminate"
                    />
                ) : (
                    <>
                        {employees.length > 0
                            ? renderEmployees(employees)
                            : guids &&
                              guids.length > 0 && (
                                  <Button onClick={loadEmployees}>
                                      <GetAppIcon
                                          className={classes.buttonIcon}
                                          fontSize="small"
                                      />
                                      Load {guids.length} people data
                                  </Button>
                              )}
                    </>
                )}
            </div>
        </div>
    )
}
