import { makeStyles } from '@mui/styles'
import { AdminSearchPanelActions } from 'Admin'
import {
    addHubProcessOwners as addHubProcessOwnersApi,
    deleteHubProcessOwner,
    searchHubs,
} from 'api'
import {
    BusinessUnitSelect,
    Content,
    CountrySelect,
    DivisionSelect,
    EmployeeSelect,
    FormGridItem,
    GridContainer,
    MultiCountrySelect,
    PageTitle,
    ProductGroupSelect,
    SearchPanel,
} from 'components'
import { NullableBooleanSelect } from 'components/Fields/NullableBooleanSelect'
import { ReactNode, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { AdminHubInfo } from 'types/adminTypes'
import { Employee } from 'types/issueTypes'
import { FilterBase } from 'types/models'
import { UserProfile } from 'types/profile'
import { dataTestId } from 'utils'
import { AdminConfirmDialog } from '../components/AdminDialog/AdminConfirmDialog'
import {
    HubsManagePageDialog,
    HubsManagePageResults,
    HubStructureTree,
} from './components'

export interface HubsManagePageFields {
    belongingCountry: FilterBase[]
    businessUnit: FilterBase
    division: FilterBase
    hubProcessOwner: Employee
    isHubActive: boolean | null
    leadCountry: FilterBase
    productGroup: FilterBase
    processOwnerAssigned: boolean | null
}

const getInitQuery = (): HubsManagePageFields => ({
    belongingCountry: [],
    businessUnit: null,
    division: null,
    hubProcessOwner: null,
    isHubActive: true,
    leadCountry: null,
    productGroup: null,
    processOwnerAssigned: null,
})

const PAGE_SIZE = 20

const useStyles = makeStyles(
    () => ({
        field: {
            marginBottom: 0,
        },
    }),
    { name: 'HubsManagePage' }
)

export const HubsManagePage = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [noResults, setNoResults] = useState(false)
    const [error, setError] = useState<unknown>(null)
    const [page, setPage] = useState<number>(1)
    const [loading, setLoading] = useState(false)
    const profile = useSelector<RootState, UserProfile>(
        (store) => store?.dashboard?.profile
    )
    const [addHubProcessOwners, setAddHubProcessOwners] = useState<
        number | null
    >(null)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [hubs, setHubs] = useState<AdminHubInfo[]>([])
    const [searching, setSearching] = useState<boolean>(false)
    const [selectedHubs, setSelectedHubs] = useState<number[]>([])
    const [searchText, setSearchText] = useState<string>('')
    const [removeProcessOwner, setRemoveProcessOwner] = useState<
        [Employee, AdminHubInfo] | null
    >(null)
    const [query, setQuery] = useState<HubsManagePageFields>(getInitQuery())
    const resetForm = () => {
        setQuery(getInitQuery())
    }
    const handleSearch = async () => {
        try {
            setSearching(true)
            setNoResults(false)
            const { data, status } = await searchHubs({
                belongingCountryCodes: query.belongingCountry.map(
                    (c) => c.code
                ),
                buCode: query.division?.code ?? null,
                divisionCode: query.businessUnit?.code ?? null,
                hubProcessOwner: query?.hubProcessOwner?.geid ?? null,
                isHubActive: query.isHubActive,
                leadCountryCode: query.leadCountry?.code ?? null,
                pgCode: query.productGroup?.code ?? null,
                processOwnerAssigned: query.processOwnerAssigned,
            })
            setHubs(data?.items ?? [])
            setPage(1)
            if (status === 204) {
                setNoResults(true)
            }
        } catch (error) {
            setError(error)
        } finally {
            setSearching(false)
        }
    }
    const handleChange =
        (fieldName: keyof HubsManagePageFields) => (newValue: ReactNode) => {
            setQuery((prev: HubsManagePageFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }
    const openDeleteProcessOwnerDialog = (
        employee: Employee,
        hub: AdminHubInfo
    ) => setRemoveProcessOwner([employee, hub])
    const handleRemoveProcessOwner = async () => {
        try {
            const geid = removeProcessOwner[0].geid
            const hubId = removeProcessOwner[1].id
            setLoading(true)
            setLoadingDesc('Removing Hub process owner')
            setRemoveProcessOwner(null)
            await deleteHubProcessOwner(hubId, geid)
            const index = hubs.findIndex((hub) => hub.id === hubId)
            const hub = { ...hubs[index] }
            const hubOwners = [...hub.hubProcessOwners]
            const hubOwnersIndex = hubOwners.findIndex((o) => o.geid === geid)
            hubOwners.splice(hubOwnersIndex, 1)
            hub.hubProcessOwners = hubOwners
            hubs[index] = hub
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot remove Hub process owner', true))
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handleHubSelect = (hubId: number, selected: boolean) => {
        if (selected) {
            setSelectedHubs((prev) => [...prev, hubId])
        } else {
            setSelectedHubs((prev) => {
                const newState = [...prev]
                const index = prev.findIndex((id) => id === hubId)
                newState.splice(index, 1)
                return newState
            })
        }
    }
    const handleOpenAddHubProcessOwnersDialog = (hubId: number) => {
        if (!selectedHubs.includes(hubId)) {
            setSelectedHubs((prev) => [...prev, hubId])
        }
        setAddHubProcessOwners(hubId)
    }
    const handleAddProcessOwners = async (
        selectedHubs: number[],
        newHubProcessOwners: Employee[]
    ) => {
        try {
            setLoading(true)
            setLoadingDesc('Adding Hub process owners')
            setAddHubProcessOwners(null)
            await addHubProcessOwnersApi(
                selectedHubs,
                newHubProcessOwners.map((e) => e.geid)
            )
            selectedHubs.forEach((hubId) => {
                setHubs((prev) => {
                    const hubIndex = prev.findIndex((x) => x.id === hubId)
                    if (0 <= hubIndex) {
                        const hub = { ...prev[hubIndex] }
                        const hubProcessOwners: Record<string, Employee> = {}
                        hub?.hubProcessOwners?.forEach(
                            (e) => (hubProcessOwners[e.geid] = e)
                        )
                        newHubProcessOwners?.forEach(
                            (e) => (hubProcessOwners[e.geid] = e)
                        )
                        hub.hubProcessOwners = Object.keys(
                            hubProcessOwners
                        ).map((geid) => hubProcessOwners[geid])
                        prev[hubIndex] = hub
                        return [...prev]
                    }
                    return prev
                })
            })
            setSelectedHubs([])
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot add Hub process owners', true))
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    return (
        <Content
            loading={loading}
            loadingDesc={loadingDesc}
            onRetry={() => setError(null)}
            error={error}
            variant="medium"
        >
            <PageTitle
                title="HUBs"
                desc="This panel allows you to manage Hub Process Owners."
                to="	
                https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B2C8C223D-13D9-4931-9F13-82B0F38D63DF%7D&file=CCRP%20Managing%20HUBs.pptx&action=edit&mobileredirect=true"
            />
            <HubStructureTree />
            <SearchPanel
                initOpen={true}
                placeholder="Search HUBs"
                onEnter={() => !searching && handleSearch()}
                searchText={searchText}
                onChange={(text) => {
                    setSearchText(text)
                }}
                onClearText={() => setSearchText('')}
                disabled={searching}
                disabledTextSearch={true}
            >
                <GridContainer>
                    <FormGridItem fullWidth {...dataTestId(`HUBS_BA`)}>
                        <DivisionSelect
                            className={classes.field}
                            disabled={searching}
                            selectedDivision={query.businessUnit}
                            onDivisionChanged={handleChange('businessUnit')}
                            {...dataTestId(`HUBS_BA`)}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <BusinessUnitSelect
                            className={classes.field}
                            disabled={searching}
                            division={query.businessUnit}
                            selectedBusinessUnit={query.division}
                            onBusinessUnitChanged={handleChange('division')}
                            {...dataTestId(`HUBS_DIV`)}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <ProductGroupSelect
                            className={classes.field}
                            disabled={searching}
                            division={query.businessUnit}
                            businessUnit={query.division}
                            selectedProductGroup={query.productGroup}
                            onProductGroupChanged={handleChange('productGroup')}
                            {...dataTestId(`HUBS_PG`)}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <CountrySelect
                            className={classes.field}
                            label="Lead country"
                            disabled={searching}
                            selectedCountry={query.leadCountry}
                            onCountryChanged={handleChange('leadCountry')}
                            {...dataTestId(`HUBS_LEAD_COUNTRY`)}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <MultiCountrySelect
                            className={classes.field}
                            label="Belonging countries"
                            disabled={searching}
                            selectedCountries={query.belongingCountry}
                            onCountriesChanged={handleChange(
                                'belongingCountry'
                            )}
                            {...dataTestId(`HUBS_BELONGING_COUNTRY`)}
                        />
                    </FormGridItem>

                    <FormGridItem fullWidth>
                        <EmployeeSelect
                            className={classes.field}
                            label="Hub Process Owner"
                            employee={query.hubProcessOwner}
                            onEmployeeChanged={handleChange('hubProcessOwner')}
                            {...dataTestId(`HUBS_PROCESS_OWNER`)}
                        />
                    </FormGridItem>

                    <FormGridItem>
                        <NullableBooleanSelect
                            className={classes.field}
                            label="Is any Process Owner assigned"
                            value={query.processOwnerAssigned}
                            onChange={handleChange('processOwnerAssigned')}
                            optionNullLabel="Irrelevant"
                            optionTrueLabel="Yes"
                            optionFalseLabel="No"
                        />
                    </FormGridItem>

                    <FormGridItem>
                        <NullableBooleanSelect
                            className={classes.field}
                            label="Hub status"
                            value={query.isHubActive}
                            onChange={handleChange('isHubActive')}
                            optionNullLabel="All"
                            optionTrueLabel="Active"
                            optionFalseLabel="Inactive"
                        />
                    </FormGridItem>
                </GridContainer>

                <AdminSearchPanelActions
                    isSearching={searching}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>
            <HubsManagePageResults
                noResults={noResults}
                page={page}
                pageSize={PAGE_SIZE}
                onPageChange={handlePageChange}
                searching={searching}
                hubs={hubs}
                profile={profile}
                selectedHubs={selectedHubs}
                onDeleteHubProcessOwner={openDeleteProcessOwnerDialog}
                onAddProcessOnwer={handleOpenAddHubProcessOwnersDialog}
                onSelectHub={handleHubSelect}
            />
            <AdminConfirmDialog
                open={Array.isArray(removeProcessOwner)}
                onAccept={handleRemoveProcessOwner}
                onClose={() => setRemoveProcessOwner(null)}
                disabledMobile={true}
                title="Remove Process owner"
            >
                {`Do you want remove
                    ${
                        (removeProcessOwner &&
                            removeProcessOwner[0]?.fullName) ??
                        '??'
                    } from
                    ${
                        (removeProcessOwner && removeProcessOwner[1]?.code) ??
                        '??'
                    }?`}
            </AdminConfirmDialog>
            <HubsManagePageDialog
                open={Boolean(addHubProcessOwners)}
                onClose={() => setAddHubProcessOwners(null)}
                onUnselectHub={(hubId) => handleHubSelect(hubId, false)}
                onSave={handleAddProcessOwners}
                selectedHubs={selectedHubs}
                hubs={hubs}
            />
        </Content>
    )
}
