import { IssueRoles, IssueStatuses } from 'types/enums'
import { MyIssueItem, MyIssueItemProps } from '../components/MyIssueItem'
import { MyIssue2 } from 'types/myDashboard'

interface MyIssueTile2Props extends Omit<MyIssueItemProps, 'renderActions'> {}

export const MyIssueTile2 = (props: MyIssueTile2Props) => {
    const { data, onExtendTargetResolutionDate, onOpenIssue, ...other } = props
    const canExtendDate: (item: MyIssue2) => boolean = (item) =>
        item.roleCodes.some((i) =>
            [
                IssueRoles.Issue_Owner,
                IssueRoles.Issue_Owner_Supervisor,
                IssueRoles.Support_Desk,
                IssueRoles.Developer,
            ].includes(+i)
        ) &&
        ![
            IssueStatuses.Completed,
            IssueStatuses.Completed_Execution_Pending,
            IssueStatuses.Resolved,
        ].includes(item.issueStatusId)

    return (
        <MyIssueItem
            data={data as MyIssue2}
            onExtendTargetResolutionDate={onExtendTargetResolutionDate}
            canExtendTargetResolutionDate={canExtendDate(data as MyIssue2)}
            onOpenIssue={onOpenIssue}
            {...other}
        />
    )
}
