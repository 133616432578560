import { useMemo } from 'react'

import { useSelector } from 'react-redux'
import { ProcessStep } from '.'
import { RootState } from 'store/types'
import { IssueRoles, IssueStatuses, IssueTypes } from 'types/enums'
import { Dates } from 'types/issueTypes'

export const useIssueProcessFlow = (
    issueStatus: IssueStatuses,
    highImpact: boolean,
    solutionWillBeValidatedByCoordinator: boolean,
    issueType: IssueTypes,
    isWaitingForExecution: boolean,
    dates: Dates = {
        assignedDate: null,
        completionDate: null,
        creationDate: null,
        effectivenessCheckDate: null,
        resolvedDate: null,
        solutionSubmittedDate: null,
        submitDate: null,
        targetResolutionAndVerificationDate: null,
        verifiedDate: null,
    }
) => {
    const resources = useSelector((state: RootState) => {
        let results = { ...state.resources }
        results.complaintStatus = [
            { code: IssueStatuses.New, name: 'New' },
            ...results.complaintStatus,
        ]
        return results
    })
    const results = useMemo<[Array<ProcessStep>, number]>(() => {
        const getStatusName = (status: IssueStatuses): string =>
            resources?.complaintStatus?.find(
                (s) => String(s.code) === String(status)
            )?.name
        const getRoleName = (role: IssueRoles): string =>
            resources.roleCode.find((r) => String(r.code) === String(role))
                ?.name
        const steps: Array<ProcessStep> = []
        let finished = false
        let currentStep = 0
        let step = 0
        let statusTmp: IssueStatuses =
            issueStatus === IssueStatuses.New
                ? IssueStatuses.New
                : IssueStatuses.Draft
        steps.push({
            status: statusTmp,
            statusName: getStatusName(statusTmp),
            finished,
            createDateUtc: dates?.creationDate,
            finishDateUtc: dates?.submitDate,
            order: ++step,
            role: IssueRoles.Issue_Owner,
            roleName: getRoleName(IssueRoles.Issue_Owner),
        })
        steps.push({
            status: IssueStatuses.Not_Assigned,
            statusName: getStatusName(IssueStatuses.Not_Assigned),
            finished,
            finishDateUtc: dates?.assignedDate,
            order: ++step,
            role: IssueRoles.Issue_Coordinator,
            roleName: getRoleName(IssueRoles.Issue_Coordinator),
        })
        statusTmp =
            issueStatus === IssueStatuses.In_Process_Overdue
                ? IssueStatuses.In_Process_Overdue
                : IssueStatuses.In_Process
        steps.push({
            status: statusTmp,
            statusName: getStatusName(statusTmp),
            finished,
            finishDateUtc: dates?.solutionSubmittedDate,
            order: ++step,
            role: IssueRoles.Resolution_Owner,
            roleName: getRoleName(IssueRoles.Resolution_Owner),
        })
        if (
            highImpact ||
            solutionWillBeValidatedByCoordinator ||
            issueType === IssueTypes.Internal_NC
        ) {
            statusTmp =
                issueStatus === IssueStatuses.In_Process_Verification_Overdue
                    ? IssueStatuses.In_Process_Verification_Overdue
                    : IssueStatuses.In_Process_Verification
            const roleTmp = highImpact
                ? IssueRoles.Bu_Process_Owner
                : IssueRoles.Issue_Coordinator
            steps.push({
                status: statusTmp,
                statusName: getStatusName(statusTmp),
                finished,
                finishDateUtc: dates?.solutionSubmittedDate,
                order: ++step,
                role: roleTmp,
                roleName: getRoleName(roleTmp),
            })
        }
        steps.push({
            status: IssueStatuses.Resolved,
            statusName: getStatusName(IssueStatuses.Resolved),
            finished,
            finishDateUtc: dates?.resolvedDate,
            order: ++step,
            role: IssueRoles.Issue_Owner,
            roleName: getRoleName(IssueRoles.Issue_Owner),
        })
        if (isWaitingForExecution) {
            steps.push({
                status: IssueStatuses.Completed_Execution_Pending,
                statusName: getStatusName(
                    IssueStatuses.Completed_Execution_Pending
                ),
                finished,
                finishDateUtc: dates?.completionDate,
                order: ++step,
                role: IssueRoles.Issue_Owner,
                roleName: getRoleName(IssueRoles.Issue_Owner),
            })
        }
        steps.push({
            status: IssueStatuses.Completed,
            statusName: 'Completed (To Verify Effectiveness)',
            finished,
            finishDateUtc: dates?.completionDate,
            order: ++step,
            role: IssueRoles.Resolution_Owner,
            roleName: getRoleName(IssueRoles.Issue_Owner),
        })
        steps.push({
            status: IssueStatuses.Completed,
            statusName: 'Verified Effectiveness',
            finished:
                Boolean(dates?.effectivenessCheckDate) &&
                issueStatus === IssueStatuses.Completed,
            finishDateUtc: dates?.effectivenessCheckDate,
            order: ++step,
            role: IssueRoles.Resolution_Owner,
            roleName: getRoleName(IssueRoles.Issue_Owner),
        })
        if (issueStatus === IssueStatuses.Completed) {
            const effectivnessChecked = Boolean(dates?.effectivenessCheckDate)
            const results = steps.map((s) => {
                s.finished = true
                return s
            })
            if (!effectivnessChecked) {
                results[results.length - 1].finished = false
                results[results.length - 2].finished = false
            }
            return [
                results,
                effectivnessChecked ? results.length + 1 : results.length - 1,
            ]
        } else {
            return [
                steps.map((s) => {
                    if (s.status === issueStatus) {
                        finished = true
                        currentStep = s.order
                    } else {
                        s.finished = !finished
                    }
                    return s
                }),
                currentStep,
            ]
        }
    }, [
        issueStatus,
        highImpact,
        solutionWillBeValidatedByCoordinator,
        issueType,
        dates,
        isWaitingForExecution,
        resources.roleCode,
        resources.complaintStatus,
    ])
    return results
}
