import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    getUnitInternalNcColumnFilter,
    SearchResultsWithPagination,
} from 'components'
import dayjs from 'dayjs'
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { AllInOneColumn } from 'types/models'
import { ReportResult } from 'types/reportTypes'
import { getUtcAppendix, uuid } from 'utils'
import { ReportExcelExport } from './ReportExcelExport'
import { additionalColumnSettings } from './utils'

export interface ReportGrtidProps {
    columns: AllInOneColumn[]
    data: ReportResult[]
    isUnitInternal: boolean
    page: number
    onPageChange: (page: number) => void
}

const REPORT_PAGE_SIZE = 25

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginTop: theme.spacing(1),
        },
        column: {
            minWidth: 160,
        },
        content: {
            backgroundColor: theme.palette.common.white,
            overflowX: 'scroll',
            marginBottom: theme.spacing(2),
        },
        topScrollWraper: {
            width: `calc(100% + 17px)`,
            height: 20,
            overflowX: 'scroll',
        },
        topScroll: {
            height: 20,
        },
        table: {
            width: 'auto',
            minWidth: '100%',
        },
        tableHeader: {
            whiteSpace: 'nowrap',
        },
        link: {
            width: 'fit-content',
            cursor: 'pointer',
            borderBottom: `2px solid ${theme.palette.grey[200]}`,
            '&:hover': {
                borderBottom: `2px solid ${theme.palette.secondary.main}`,
            },
            '&:visited &:active': {
                borderBottom: `2px solid ${theme.palette.shades.red1_10}`,
            },
        },
    }),
    { name: 'ReportGrid' }
)

export const ReportGrid = (props: ReportGrtidProps) => {
    const { columns, page, onPageChange, data, isUnitInternal = false } = props
    const classes = useStyles()
    const handlePageChange = (page: number) => {
        onPageChange(page)
        window.scrollTo({ top: 0 })
    }
    const [state, setState] = useState(null)
    const elementRef = useRef<HTMLTableElement>()
    const scrollRefBottom = useRef<HTMLDivElement>()
    const scrollRefTop = useRef<HTMLDivElement>()

    useEffect(() => {
        setState(elementRef?.current?.clientWidth)
    }, [elementRef])

    const allColumns = useSelector((state: RootState) =>
        state?.resources?.allInOneReportColumns
            .filter(getUnitInternalNcColumnFilter(isUnitInternal))
            .filter((x) => x.code !== '00')
            .sort((a, b) => a.order - b.order)
    )
    const rows = useMemo(() => data.map((x) => ({ id: uuid(), ...x })), [data])
    const paginatedRows = useMemo(
        () =>
            rows.filter(
                (_, i) =>
                    REPORT_PAGE_SIZE * (page - 1) <= i &&
                    i < REPORT_PAGE_SIZE * page
            ),
        [page, rows]
    )
    const displayColumns = useMemo(() => {
        if (0 < columns?.length) {
            const hasAll = columns.some((x) => x.code === '00')
            const selectedColumns = (hasAll ? allColumns : columns).reduce<
                Record<string, string>
            >((prev, curr) => {
                prev[curr.code] = curr.name
                return prev
            }, {})
            return allColumns
                .filter((c1) => Boolean(selectedColumns[c1.code]))
                .reduce<AllInOneColumn[]>((prev, col) => {
                    if (col?.children) {
                        const children = col?.children ?? []
                        let maxIndex = 0
                        data.forEach((d) => {
                            let objectIndex = 0
                            for (const child of children) {
                                if (d[child.code]) {
                                    objectIndex++
                                    continue
                                }
                                break
                            }
                            if (maxIndex < objectIndex) {
                                maxIndex = objectIndex
                            }
                        })
                        children.forEach((c, i) => {
                            if (i <= maxIndex - 1) {
                                prev.push(c)
                            }
                        })
                    } else {
                        prev.push(col)
                    }
                    return prev
                }, [])
        }

        return allColumns
    }, [columns, allColumns, data])
    const getAppendix = (columnCode: string) => {
        const config = additionalColumnSettings[columnCode] ?? null
        return config?.isDate ? getUtcAppendix(!config.localDate) : ''
    }
    const getColumnValue = (
        column: AllInOneColumn,
        row: any
        // isTest?: boolean
    ): string | ReactNode => {
        const config = additionalColumnSettings[column.code]
        const value = row[column.code] ?? ''
        if (config?.isDate) {
            const date = dayjs(value).utc()
            if (date.isValid()) {
                return (config?.localDate ? date.local() : date).format(
                    'YYYY-M-D h:mm:ss A'
                )
            } else {
                return ''
            }
        } else if (config?.getHyperLink) {
            return (
                <div
                    className={classes.link}
                    onClick={() => window.open(`/issue/${row['0_9'] ?? value}`)}
                >
                    {value}
                </div>
            )
        }
        return typeof value == 'boolean'
            ? value
                ? 'TRUE'
                : 'FALSE'
            : value ?? ''
    }
    return (
        <SearchResultsWithPagination
            className={classes.root}
            isSearching={false}
            page={page}
            onPageChange={handlePageChange}
            pageSize={REPORT_PAGE_SIZE}
            itemCount={data?.length ?? 0}
            errorTitle="No results!"
            errorSubTitle="Change search criteria and try again."
            errorVisible={false}
            renderAction={() => (
                <ReportExcelExport
                    columns={displayColumns}
                    rows={rows}
                    fileName={
                        isUnitInternal
                            ? 'nc-all-in-one-report'
                            : 'all-in-one-report'
                    }
                    additionalColumnSettings={additionalColumnSettings}
                />
            )}
        >
            <div
                className={classes.topScrollWraper}
                ref={scrollRefTop}
                onScroll={(e) => {
                    scrollRefBottom.current.scrollLeft =
                        e?.currentTarget?.scrollLeft
                }}
            >
                <div
                    className={classes.topScroll}
                    style={{ width: `${state}px` }}
                ></div>
            </div>
            <div
                className={classes.content}
                ref={scrollRefBottom}
                onScroll={(e) => {
                    scrollRefTop.current.scrollLeft =
                        e?.currentTarget?.scrollLeft
                }}
            >
                <Table
                    className={classes.table}
                    size="small"
                    stickyHeader={true}
                    ref={elementRef}
                >
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            {displayColumns.map((c) => (
                                <TableCell
                                    className={classes.column}
                                    key={c.code}
                                >
                                    {`${c.name} ${getAppendix(c.code)}`}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedRows.map((row) => (
                            <TableRow key={row.id}>
                                {displayColumns.map((c) => {
                                    let widerColumn = ''
                                    if (
                                        ['23', '36', '59', '78', '79'].includes(
                                            c.code
                                        )
                                    )
                                        widerColumn = '900px'
                                    return (
                                        <TableCell
                                            key={row.id + c.code}
                                            style={{ minWidth: widerColumn }}
                                            // {...dataTestId(
                                            //     `${c.name}_${getColumnValue(
                                            //         c,
                                            //         row,
                                            //         true
                                            //     ).toString()}`
                                            // )}
                                        >
                                            {getColumnValue(c, row)}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </SearchResultsWithPagination>
    )
}
