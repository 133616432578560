import AttachmentIcon from '@mui/icons-material/Attachment'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ActionItemAttachment } from 'types/actionPlanTypes'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1),
        },
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
        label: {
            fontWeight: theme.typography.fontWeightMedium as number,
            textTransform: 'none',
        },
    }),
    { name: 'AttachmentNext' }
)

export interface AttachmentNextProps {
    disabled: boolean
    attachment: ActionItemAttachment
    downloadAttachment: (attachment: ActionItemAttachment) => void
    deleteAttachment: (attachment: ActionItemAttachment) => void
}

const AttachmentNext = (props: AttachmentNextProps) => {
    const { deleteAttachment, downloadAttachment, disabled } = props
    const classes = useStyles()
    const { attachment } = props

    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <IconButton
                    onClick={() => downloadAttachment(attachment)}
                    size="small"
                >
                    <AttachmentIcon />
                </IconButton>
                <Typography className={classes.label} variant="button">
                    {attachment.displayName}
                </Typography>
            </div>
            <IconButton
                disabled={disabled}
                onClick={() => deleteAttachment(attachment)}
                size="small"
            >
                <CloseIcon />
            </IconButton>
        </div>
    )
}

export default AttachmentNext
