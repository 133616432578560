import * as ACTIONS from './actionTypes'
import { MyAction, MyAction2 } from 'types/myDashboard'
import {
    ReturnToIssueOwnerCommand,
    ConfirmHighImpactCommand,
    DowngradeHighImpactCommand,
    VerifyContainmentActionCommand,
    VerifyResolutionCommand,
    RejectResolutionCommand,
    ReopenIssueCommand,
} from './types'
import { IssueActionsTypes } from 'types/enums'
import {
    ConfirmHIIssueQuery,
    DowngradeHIIssueQuery,
    RejectResolutionQuery,
    ReopenIssueQuery,
    ReturnToIssueOwnerQuery,
    VerifyContainmentActionQuery,
} from 'types/actions'
import { MyActionsQuery } from 'types/queries'
import { AnyAction } from 'redux'

export const clearError = () => ({
    type: ACTIONS.MY_ACTIONS_CLEAR_ERROR,
})

export const loadMyActionsError = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_LOAD_ERROR,
    payload: error,
})
export const loadMyActionsDone = (actions: Array<MyAction | MyAction2>) => ({
    type: ACTIONS.MY_ACTIONS_LOAD_DONE,
    payload: actions,
})

export const removeMyActionById = (
    issueId: number,
    actionCode: IssueActionsTypes
) => ({
    type: ACTIONS.MY_ACTION_REMOVE_BY_ID,
    payload: { issueId, actionCode },
})
export const addMyActionsClear = () => ({ type: ACTIONS.MY_ACTIONS_CLEAR })
export const updateMyAction = (action: MyAction2) => ({
    type: ACTIONS.MY_ACTION_UPDATE_BY_ID,
    payload: action,
})

export const returnToIO = (
    query: ReturnToIssueOwnerQuery
): ReturnToIssueOwnerCommand => ({
    type: ACTIONS.MY_ACTIONS_RETURN_TO_IO_CALL,
    payload: query,
})

export const returnToIOFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_RETURN_TO_IO_FAIL,
    payload: error,
})

export const returnToIODone = () => ({
    type: ACTIONS.MY_ACTIONS_RETURN_TO_IO_DONE,
})

export const confirmHI = (
    query: ConfirmHIIssueQuery
): ConfirmHighImpactCommand => ({
    type: ACTIONS.MY_ACTIONS_CONFIRM_HI_CALL,
    payload: query,
})

export const confirmHIFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_CONFIRM_HI_FAIL,
    payload: error,
})

export const confirmHIDone = () => ({
    type: ACTIONS.MY_ACTIONS_CONFIRM_HI_DONE,
})

export const downgradeHI = (
    query: DowngradeHIIssueQuery
): DowngradeHighImpactCommand => ({
    type: ACTIONS.MY_ACTIONS_DOWNGRADE_HI_CALL,
    payload: query,
})

export const downgradeHIFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_DOWNGRADE_HI_FAIL,
    payload: error,
})

export const downgradeHIDone = () => ({
    type: ACTIONS.MY_ACTIONS_DOWNGRADE_HI_DONE,
})

export const verifyContainment = (
    query: VerifyContainmentActionQuery
): VerifyContainmentActionCommand => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_CALL,
    payload: query,
})

export const verifyContainmentFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_FAIL,
    payload: error,
})

export const verifyContainmentDone = () => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_DONE,
})

export const reopenIssue = (query: ReopenIssueQuery): ReopenIssueCommand => ({
    type: ACTIONS.MY_ACTIONS_REOPEN_ISSUE_CALL,
    payload: query,
})

export const reopenIssueFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_REOPEN_ISSUE_FAIL,
    payload: error,
})

export const reopenIssueDone = () => ({
    type: ACTIONS.MY_ACTIONS_REOPEN_ISSUE_DONE,
})

export const verifyResolution = (issueId: number): VerifyResolutionCommand => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_CALL,
    payload: issueId,
})

export const verifyResolutionFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_FAIL,
    payload: error,
})

export const verifyResolutionDone = () => ({
    type: ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_DONE,
})

export const rejectResolution = (
    query: RejectResolutionQuery,
    actionType: IssueActionsTypes = IssueActionsTypes.To_Complete
): RejectResolutionCommand => ({
    type: ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_CALL,
    payload: { query, actionType },
})

export const rejectResolutionFail = (error: any) => ({
    type: ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_FAIL,
    payload: error,
})

export const rejectResolutionDone = () => ({
    type: ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_DONE,
})

export const exportMyActions = (query: MyActionsQuery = {}): AnyAction => ({
    type: ACTIONS.MY_ACTIONS_EXPORT_CALL,
    payload: { query },
})

export const exportMyActionsDone = (): AnyAction => ({
    type: ACTIONS.MY_ACTIONS_EXPORT_DONE,
})

export const exportMyActionsFail = (error: any): AnyAction => ({
    type: ACTIONS.MY_ACTIONS_EXPORT_FAIL,
    payload: error,
})
