import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    SelectProps,
    Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

export interface NullableBooleanSelectProps
    extends Omit<SelectProps, 'onChange'> {
    label: string
    value: boolean | null | undefined
    defaultValue?: boolean | null | undefined
    onChange: (value: boolean | null) => void
    optionTrueLabel: string
    optionFalseLabel: string
    optionNullLabel: string
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'NullableBooleanSelect' }
)

export const NullableBooleanSelect = (props: NullableBooleanSelectProps) => {
    const {
        label,
        value = null,
        defaultValue = null,
        onChange,
        optionFalseLabel,
        optionTrueLabel,
        optionNullLabel,
        ...other
    } = props
    const classes = useStyles()
    const handleChange = (event: SelectChangeEvent<string>) => {
        switch (event?.target?.value) {
            case String(null):
                onChange(null)
                break
            case String(true):
                onChange(true)
                break
            case String(false):
                onChange(false)
                break
            default:
                onChange(null)
        }
    }
    return (
        <FormControl className={classes.root} fullWidth variant="outlined">
            <InputLabel>{label}</InputLabel>
            <Select
                value={String(value)}
                defaultValue={String(defaultValue)}
                onChange={handleChange}
                variant="filled"
                fullWidth
                {...other}
            >
                <MenuItem value={String(null)}>{optionNullLabel}</MenuItem>
                <MenuItem value={String(true)}>{optionTrueLabel}</MenuItem>
                <MenuItem value={String(false)}>{optionFalseLabel}</MenuItem>
            </Select>
            <FormHelperText />
        </FormControl>
    )
}
