import CloseIcon from '@mui/icons-material/Close'
import MaximizeIcon from '@mui/icons-material/Maximize'
import MinimizeIcon from '@mui/icons-material/Minimize'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { CloseFullScreen, OpenInFull } from 'components/Icons'
import { HtmlHTMLAttributes } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    SendMessageState,
    updateSendMessageActive,
    updateSendMessageFullScreen,
    updateSendMessageMaximize,
} from 'store/issue'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            height: 56,
            boxSizing: 'border-box',
            alignItems: 'center',
            padding: theme.spacing(0, 1, 0, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
            color: theme.palette.common.white,
            backgroundColor: theme.palette.grey[600],
            borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
            '&$fullScreen': {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.common.white,
            },
        },
        fullScreen: {},
        iconButton: {
            marginLeft: theme.spacing(0.5),
        },
    }),
    {
        name: 'SendMessageHeader',
    }
)

export const SendMessageHeader = (
    props: HtmlHTMLAttributes<HTMLDivElement>
) => {
    const { className, ...other } = props
    const dispatch = useDispatch()
    const classes = useStyles()

    const { fullScreen: fullScreenState, maximize } = useSelector<
        RootState,
        SendMessageState
    >((state) => state.issue.sendMessage)

    const handleClose = () => {
        dispatch(updateSendMessageActive(false))
    }
    const handleMinimize = () => dispatch(updateSendMessageMaximize(false))
    const handleMaximize = () => dispatch(updateSendMessageMaximize(true))
    const handleFullscreenMaximize = (fullScreen: boolean) => () => {
        if (maximize) {
            dispatch(updateSendMessageFullScreen(fullScreen))
        } else {
            dispatch(updateSendMessageMaximize(true))
            dispatch(updateSendMessageFullScreen(fullScreen))
        }
    }
    return (
        <div
            className={clsx(
                classes.root,
                fullScreenState && maximize && classes.fullScreen
            )}
            {...other}
        >
            <Typography style={{ flexGrow: 1 }} variant="h6">
                Send message
            </Typography>
            <div>
                {maximize ? (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={handleMinimize}
                        color="inherit"
                    >
                        <MinimizeIcon fontSize="small" color="inherit" />
                    </IconButton>
                ) : (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        color="inherit"
                        onClick={handleMaximize}
                    >
                        <MaximizeIcon fontSize="small" color="inherit" />
                    </IconButton>
                )}
                {fullScreenState ? (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={handleFullscreenMaximize(false)}
                        color="inherit"
                    >
                        <CloseFullScreen fontSize="small" color="inherit" />
                    </IconButton>
                ) : (
                    <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={handleFullscreenMaximize(true)}
                        color="inherit"
                    >
                        <OpenInFull fontSize="small" color="inherit" />
                    </IconButton>
                )}
                <IconButton
                    className={classes.iconButton}
                    size="small"
                    onClick={handleClose}
                    color="inherit"
                >
                    <CloseIcon fontSize="small" color="inherit" />
                </IconButton>
            </div>
        </div>
    )
}
