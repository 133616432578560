import { searchRca as searchRcaApi, copyRca as copyRcaApi } from 'api/rcaLink'
import {
    saveAssignSectionDone,
    updateIssueRowVersion,
    loadAttachmentsDone,
    saveResolutionSectionDone,
} from '../issue/index'

import { ISSUE_VIEW } from 'Issue/routes'
import { urlBuilder } from 'utils'
import { replace } from 'connected-react-router'
import { SearchRcaQuery } from 'types/queries'
import { FilterBase } from 'types/models'
import { Issue, IssueRcaPreview } from 'types/issueTypes'
import { AnyAction } from 'redux'
import { RootState } from 'store/types'
import { getResolutionSection } from 'api/resolutionRca'
import { showSnackbar } from 'store/app'
import { getAttachments } from 'api'
import { AuthenticationActions } from 'react-aad-msal'
import { ASSIGNMENT_SECTION_ID } from 'Issue/Sections/utils'

export const NUMBER_OF_FETCHED_ITEMS = 20
export const SEARCH_RCA_REQUESTED = 'SEARCH_RCA_REQUESTED'
export const SEARCH_RCA_DONE = 'SEARCH_RCA_DONE'
export const SEARCH_RCA_FAIL = 'SEARCH_RCA_FAIL'
export const SEARCH_RCA_CLEAR_LIST = 'SEARCH_RCA_CLEAR_LIST'
export const SEARCH_RCA_ADD_SEARCH_TEXT = 'SEARCH_RCA_ADD_SEARCH_TEXT'
export const SEARCH_RCA_INIT = 'SEARCH_RCA_INIT'
export const SEARCH_RCA_CLEAR = 'SEARCH_RCA_CLEAR'
export const SEARCH_RCA_CLEAR_ERROR = 'SEARCH_RCA_CLEAR_ERROR'
export const SEARCH_RCA_MORE_RESULTS = 'SEARCH_RCA_MORE_RESULTS'
export const SEARCH_RCA_SELECT_BUSINESS = 'SEARCH_RCA_SELECT_BUSINESS'
export const SEARCH_RCA_SELECT_BUSINESS_UNIT = 'SEARCH_RCA_SELECT_BUSINESS_UNIT'
export const SEARCH_RCA_SELECT_ACTIVITY = 'SEARCH_RCA_SELECT_ACTIVITY'
export const SEARCH_RCA_SELECT_COUNTRY = 'SEARCH_RCA_SELECT_COUNTRY'
export const SEARCH_RCA_SELECT_ISSUE_TYPE = 'SEARCH_RCA_SELECT_ISSUE_TYPE'
export const SEARCH_RCA_SELECT_PRODUCT_GROUP = 'SEARCH_RCA_SELECT_PRODUCT_GROUP'
export const SEARCH_RCA_ADD_SET_FILTERS_EXPAND =
    'SEARCH_RCA_ADD_SET_FILTERS_EXPAND'
export const SEARCH_RCA_LOAD_MORE_RESULTS_REQUESTED =
    'SEARCH_RCA_LOAD_MORE_RESULTS_REQUESTED'
export const SEARCH_RCA_LOAD_MORE_RESULTS_FAIL =
    'SEARCH_RCA_LOAD_MORE_RESULTS_FAIL'
export const SEARCH_RCA_LOAD_MORE_RESULTS_DONE =
    'SEARCH_RCA_LOAD_MORE_RESULTS_DONE'
export const SEARCH_RCA_COPY_RCA_REQUESTED = 'SEARCH_RCA_COPY_RCA_REQUESTED'
export const SEARCH_RCA_COPY_RCA_DONE = 'SEARCH_RCA_COPY_RCA_DONE'
export const SEARCH_RCA_COPY_RCA_FAIL = 'SEARCH_RCA_COPY_RCA_FAIL'
export const SEARCH_RCA_DISPOSE = 'SEARCH_RCA_DISPOSE'

export const copyRcaRequested = () => ({
    type: SEARCH_RCA_COPY_RCA_REQUESTED,
})

export const copyRcaFail = (error: any) => ({
    type: SEARCH_RCA_COPY_RCA_FAIL,
    payload: error,
})

export const copyRcaDone = () => ({
    type: SEARCH_RCA_COPY_RCA_DONE,
})

export const copyRca =
    (
        id: number,
        quickFixIssueId: number,
        issueIdFromUrl: string | number = null
    ) =>
    async (dispatch: any, getState: () => RootState) => {
        try {
            const { assignSection, changeLog } = getState().issue
            dispatch(copyRcaRequested())
            const { data: newAssignSection } = await copyRcaApi(
                id,
                {
                    ...assignSection,
                    quickFixIssueId,
                },
                changeLog?.assign ?? {}
            )
            dispatch(saveAssignSectionDone(newAssignSection))
            const getResolutionSectionPromise = getResolutionSection(id)
            const getAttachmentsPromise = getAttachments(id)
            const { data: newResolutionSection } =
                await getResolutionSectionPromise
            const { data: attachments } = await getAttachmentsPromise
            dispatch(saveResolutionSectionDone(newResolutionSection))
            dispatch(loadAttachmentsDone(attachments))
            dispatch(updateIssueRowVersion(newAssignSection.rowVersion))
            dispatch(
                replace(
                    `${urlBuilder(ISSUE_VIEW, {
                        id: issueIdFromUrl || id,
                    })}#${ASSIGNMENT_SECTION_ID}`
                )
            )
            dispatch(showSnackbar('RCA link was copied'))
            dispatch(copyRcaDone())
            dispatch(clearSearch())
        } catch (err) {
            dispatch(copyRcaFail(err))
            dispatch(showSnackbar("RCA link wasn't copied!"), true)
        }
    }

export const setSearchText = (wildchart: string) => ({
    type: SEARCH_RCA_ADD_SEARCH_TEXT,
    payload: wildchart,
})

export const initSearchRcaState =
    (issueNumber: string, ruId: number) =>
    async (dispatch: any, getState: () => RootState) => {
        try {
            const issueId = getState()?.issue?.issueId
            dispatch(initSearchRcaParams(issueNumber, ruId))
            const query: SearchRcaQuery = {
                specificIssueNumber: '',
                ruId,
                page: 1,
                pageSize: NUMBER_OF_FETCHED_ITEMS,
            }
            dispatch(searchRcaRequested())
            const { data } = await searchRcaApi(issueId, query)
            dispatch(searchRcaDone(data))
        } catch (err) {
            dispatch(searchRcaFail(err))
        }
    }

export const setFiltersExpand = () => (dispatch: any, getState: any) => {
    const linkRca = getState().linkRca
    dispatch({
        type: SEARCH_RCA_ADD_SET_FILTERS_EXPAND,
        payload: !linkRca.expanded,
    })
}

export const selectBusinessUnit = (item: FilterBase) => ({
    type: SEARCH_RCA_SELECT_BUSINESS_UNIT,
    payload: item,
})

export const selectBusiness = (item: FilterBase) => ({
    type: SEARCH_RCA_SELECT_BUSINESS,
    payload: item,
})

export const selectProductGroup = (item: FilterBase) => ({
    type: SEARCH_RCA_SELECT_PRODUCT_GROUP,
    payload: item,
})

export const selectActivity = (item: FilterBase) => ({
    type: SEARCH_RCA_SELECT_ACTIVITY,
    payload: item,
})

export const selectCountry = (item: FilterBase) => ({
    type: SEARCH_RCA_SELECT_COUNTRY,
    payload: item,
})

export const selectIssueType = (item: FilterBase<number>) => ({
    type: SEARCH_RCA_SELECT_ISSUE_TYPE,
    payload: item,
})

export const initSearchRcaParams = (issueNumber: string, ruId: number) => ({
    type: SEARCH_RCA_INIT,
    payload: { issueNumber, ruId },
})

export const clearSearch = () => ({
    type: SEARCH_RCA_CLEAR,
})

export const searchRcaRequested = () => ({
    type: SEARCH_RCA_REQUESTED,
})
export const searchRcaDone = (issues: Array<Issue>) => ({
    type: SEARCH_RCA_DONE,
    payload: issues,
})
export const searchRcaFail = (error: any) => ({
    type: SEARCH_RCA_FAIL,
    payload: error,
})
export const clearRcaList = () => ({
    type: SEARCH_RCA_CLEAR_LIST,
})

export const searchRca =
    (query: SearchRcaQuery) =>
    async (dispatch: any, getState: () => RootState) => {
        try {
            const issueId = getState()?.issue?.issueId
            dispatch(clearRcaList())
            dispatch(searchRcaRequested())
            const { data } = await searchRcaApi(issueId, query)
            dispatch(searchRcaDone(data))
        } catch (err) {
            dispatch(searchRcaFail(err))
        }
    }

export const loadMoreRcaRequested = () => ({
    type: SEARCH_RCA_LOAD_MORE_RESULTS_REQUESTED,
})
export const loadMoreRcaFail = (error: any) => ({
    type: SEARCH_RCA_LOAD_MORE_RESULTS_FAIL,
    payload: error,
})
export const loadMoreRcaDone = (issues: Array<IssueRcaPreview>) => ({
    type: SEARCH_RCA_LOAD_MORE_RESULTS_DONE,
    payload: issues,
})

export const clearError = () => ({ type: SEARCH_RCA_CLEAR_ERROR })

export const loadMoreRca =
    (query: SearchRcaQuery) =>
    async (dispatch: any, getState: () => RootState) => {
        try {
            const issueId = getState()?.issue?.issueId
            dispatch(loadMoreRcaRequested())
            const { data } = await searchRcaApi(issueId, query)
            dispatch(loadMoreRcaDone(data))
        } catch (err) {
            dispatch(loadMoreRcaFail(err))
        }
    }

const filtersCounter = (state: LinkRcaStoreState): number => {
    let count = 0
    state.selectedBusiness && count++
    state.selectedBusinessLine && count++
    state.selectedProductGroup && count++
    state.selectedActivity && count++
    state.selectedCountry && count++
    state.selectedIssueType && count++
    return count
}

export interface LinkRcaStoreState {
    expanded: boolean
    selectedFiltersCount: number
    copyingRca: boolean
    error: any
    suggestions: IssueRcaPreview[]
    loading: boolean
    loadingDesc?: string
    fullLoadingDesc?: string
    initLinking: boolean
    selectedBusiness: FilterBase<string>
    selectedBusinessLine: FilterBase<string>
    selectedProductGroup: FilterBase<string>
    selectedActivity: FilterBase<string>
    selectedCountry: FilterBase<string>
    selectedIssueType: FilterBase<number>
    issueNumber?: string
    ruId?: number
    specificIssueNumber: string
}

const initState: LinkRcaStoreState = {
    expanded: true,
    selectedFiltersCount: 0,
    copyingRca: false,
    error: null,
    suggestions: null,
    loading: false,
    loadingDesc: null,
    fullLoadingDesc: null,
    initLinking: false,
    selectedBusiness: null,
    selectedBusinessLine: null,
    selectedProductGroup: null,
    selectedActivity: null,
    selectedCountry: null,
    selectedIssueType: null,
    issueNumber: null,
    ruId: null,
    specificIssueNumber: '',
}

export const linkRcaReducer = (
    state: LinkRcaStoreState = initState,
    action: AnyAction
): LinkRcaStoreState => {
    switch (action.type) {
        case SEARCH_RCA_ADD_SET_FILTERS_EXPAND:
            return {
                ...state,
                expanded: action.payload,
            }
        case SEARCH_RCA_ADD_SEARCH_TEXT:
            return {
                ...state,
                specificIssueNumber: action.payload,
            }
        case SEARCH_RCA_COPY_RCA_REQUESTED:
            return {
                ...state,
                copyingRca: true,
                error: null,
                fullLoadingDesc: 'Copy RCA…',
            }
        case SEARCH_RCA_CLEAR_ERROR:
            return {
                ...state,
                error: null,
            }
        case SEARCH_RCA_COPY_RCA_DONE:
            return {
                ...state,
                copyingRca: false,
                error: null,
                fullLoadingDesc: '',
            }
        case SEARCH_RCA_COPY_RCA_FAIL:
            return {
                ...state,
                copyingRca: false,
                error: action.payload,
                fullLoadingDesc: '',
            }
        case SEARCH_RCA_REQUESTED:
        case SEARCH_RCA_LOAD_MORE_RESULTS_REQUESTED:
            return {
                ...state,
                loading: true,
                error: null,
                loadingDesc: 'Search RCA…',
            }
        case SEARCH_RCA_SELECT_BUSINESS:
            if (action.payload === null) {
                state.selectedBusiness = null
                state.selectedBusinessLine = null
                state.selectedProductGroup = null
            }
            if (
                state.selectedBusiness &&
                state.selectedBusiness.code === action.payload.code
            ) {
                return { ...state }
            } else {
                state.selectedBusiness = action.payload
                state.selectedBusinessLine = null
                state.selectedProductGroup = null
            }
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_SELECT_BUSINESS_UNIT:
            if (action.payload === null) {
                state.selectedBusinessLine = null
                state.selectedProductGroup = null
            }
            if (
                state.selectedBusinessLine &&
                state.selectedBusinessLine.code === action.payload.code
            ) {
                return { ...state }
            } else {
                state.selectedBusinessLine = action.payload
                state.selectedProductGroup = null
            }
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_SELECT_PRODUCT_GROUP:
            state.selectedProductGroup = action?.payload
                ? { ...action.payload }
                : null
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_SELECT_ACTIVITY:
            state.selectedActivity = action?.payload
                ? { ...action.payload }
                : null
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_SELECT_COUNTRY:
            state.selectedCountry = action?.payload
                ? { ...action.payload }
                : null
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_SELECT_ISSUE_TYPE:
            state.selectedIssueType = action?.payload
                ? { ...action.payload }
                : null
            state.selectedFiltersCount = filtersCounter(state)
            return { ...state }
        case SEARCH_RCA_FAIL:
        case SEARCH_RCA_LOAD_MORE_RESULTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
                loadingDesc: '',
            }
        case SEARCH_RCA_DONE:
            return {
                ...state,
                loading: false,
                loadingDesc: '',
                suggestions: [...action.payload],
            }
        case SEARCH_RCA_LOAD_MORE_RESULTS_DONE:
            return {
                ...state,
                loading: false,
                loadingDesc: '',
                suggestions: state.suggestions
                    ? [...state.suggestions, ...action.payload]
                    : [...action.payload],
            }
        case SEARCH_RCA_CLEAR_LIST:
            return {
                ...state,
                loading: false,
                loadingDesc: '',
                expanded: true,
                suggestions: null,
            }
        case SEARCH_RCA_INIT:
            return {
                ...state,
                initLinking: true,
                issueNumber: action.payload.issueNumber,
                ruId: action.payload.ruId,
            }
        case SEARCH_RCA_CLEAR:
            return {
                ...state,
                expanded: true,
                selectedBusiness: null,
                selectedBusinessLine: null,
                selectedProductGroup: null,
                selectedActivity: null,
                selectedCountry: null,
                selectedIssueType: null,
                selectedFiltersCount: 0,
                specificIssueNumber: '',
            }
        case SEARCH_RCA_DISPOSE:
            return { ...initState }
        case AuthenticationActions.LogoutSuccess:
            return { ...initState }
        default:
            return state
    }
}
