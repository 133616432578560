import { Grid, GridProps, SvgIconProps, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { forwardRef, HtmlHTMLAttributes, ReactNode } from 'react'

export interface ValueViewNextProps extends HtmlHTMLAttributes<HTMLDivElement> {
    renderIcon?: (iconProps: Partial<SvgIconProps>) => ReactNode
    label?: string
    GridProps?: Omit<GridProps, 'item'>
    fullWidth?: boolean
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(0.5),
            color: theme.palette.grey[600],
        },
        label: {
            fontWeight: theme.typography.fontWeightBold as number,
            color: theme.palette.grey[600],
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'ValueViewNext' }
)

export const ValueViewNext = forwardRef(
    (props: ValueViewNextProps, ref: any) => {
        const {
            className,
            fullWidth,
            label,
            renderIcon,
            children,
            GridProps = {},
            ...other
        } = props
        const classes = useStyles()
        return (
            <Grid
                ref={ref}
                className={clsx(classes.root, className)}
                item
                xs={12}
                sm={fullWidth ? 12 : 6}
                {...GridProps}
                {...other}
            >
                {label && (
                    <div className={classes.header}>
                        {renderIcon &&
                            renderIcon({
                                className: classes.icon,
                                fontSize: 'small',
                            })}
                        <Typography className={classes.label} variant="caption">
                            {label}
                        </Typography>
                    </div>
                )}
                <div>{children}</div>
            </Grid>
        )
    }
)
