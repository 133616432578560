import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    saveClosingSectionDone,
    setIssueActionFail,
    ISSUE_SAVE_CLOSING_CALL,
    updateIssueRowVersion,
    IssueStoreState,
} from '..'

import { RootState } from 'store/types'
import { saveClosingSection } from 'api'
import { showSnackbar } from 'store/app'

export function* saveClosingSectionAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { closingSection, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        if (!closingSection) {
            return
        }
        const { data } = yield call(
            saveClosingSection,
            closingSection,
            changeLog?.closed ?? null,
            cancelSource.token
        )
        yield put(saveClosingSectionDone(data))
        yield put(showSnackbar('Issue closure section was saved'))
        yield put(updateIssueRowVersion(data.rowVersion))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_SAVE_CLOSING_CALL, error))
        yield put(showSnackbar("Issue closure section wasn't saved!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveClosingSectionSaga() {
    yield takeLatest([ISSUE_SAVE_CLOSING_CALL], saveClosingSectionAction)
}
