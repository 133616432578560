import { AccountCircleOutlined, Search } from '@mui/icons-material'
import { IconButton, Tooltip, useMediaQuery } from '@mui/material'
import { YammerIcon } from 'components'
import { appInsights } from 'configs/appInsights'
import { msalInstance } from 'configs/authProvider'
import { ccrpLogoutKey } from 'pages/Signin'
import { MouseEventHandler, useState } from 'react'
import { dataTestId } from 'utils'
import { MAIN_PHONE_MEDIA_QUERY } from './consts'
import { ProfileDetailsShell } from './ProfileDetailsShell'
import { YAMMER_LINK } from './utils'

export interface ContextMenuShellProps {
    logout: () => void
    search?: boolean
    onSearchOpen: () => void
}

const ContextMenuShell = (props: ContextMenuShellProps) => {
    const { logout, search = false, onSearchOpen } = props
    const [open, setOpen] = useState(false)
    const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = () => {
        appInsights.trackEvent({
            name: 'User Menu on Top Menu',
        })
        setOpen(true)
    }
    const isPhone = useMediaQuery(MAIN_PHONE_MEDIA_QUERY)
    const handleMenuClose = () => setOpen(false)
    const handleSearchOpen = () => onSearchOpen()
    const handleLogout = () => {
        localStorage.setItem(ccrpLogoutKey, '')
        if (logout) {
            logout()
        } else {
            msalInstance?.logoutRedirect()
        }
    }
    const handleOpenYammer = () => {
        appInsights.trackEvent({
            name: 'Yammer on Top Menu',
        })
        window.open(YAMMER_LINK, '_blank')
    }

    return <>
        <div>
            {search && (
                <IconButton
                    aria-haspopup="true"
                    color="inherit"
                    {...dataTestId('DASHBOARD_TOP_BAR_SEARCH_LOOP')}
                    onClick={handleSearchOpen}
                    size="large">
                    <Search />
                </IconButton>
            )}
            {!isPhone && (
                <Tooltip title="Viva Engage">
                    <IconButton onClick={handleOpenYammer} size="large">
                        <YammerIcon />
                    </IconButton>
                </Tooltip>
            )}
            <IconButton
                aria-haspopup="true"
                color="inherit"
                {...dataTestId('DASHBOARD_TOP_BAR_MORE_OPTIONS')}
                onClick={handleOpenMenu}
                size="large">
                <AccountCircleOutlined />
            </IconButton>
        </div>
        <ProfileDetailsShell
            open={open}
            onClose={handleMenuClose}
            logout={handleLogout}
        />
    </>;
}

export default ContextMenuShell
