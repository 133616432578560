import { AnyAction } from 'redux'
import { DashboardStoreState, initState } from './initState'
import * as ACTIONS from './actionTypes'

const reducersIndex: {
    [key: string]: (state, action: AnyAction) => DashboardStoreState
} = {
    [ACTIONS.DASHBOARD_OPEN_SEARCH]: (state, action) => ({
        ...state,
        openSearchBar: Boolean(action?.payload),
    }),
    [ACTIONS.DASHBOARD_LOAD_PROFILE_CALL]: (state) => ({
        ...state,
        loadingProfile: true,
    }),
    [ACTIONS.DASHBOARD_LOAD_PROFILE_DONE]: (state, action) => ({
        ...state,
        loadingProfile: false,
        profile: { ...action.payload },
    }),
    [ACTIONS.DASHBOARD_LOAD_PROFILE_FAIL]: (state) => ({
        ...state,
        loadingProfile: false,
    }),
    [ACTIONS.DASHBOARD_LOAD_AVATAR_DONE]: (state, action) => ({
        ...state,
        avatar: action.payload,
    }),
}

export const dashboardReducer = (
    state = initState,
    action: AnyAction
): DashboardStoreState => reducersIndex[action?.type]?.(state, action) ?? state
