import { makeStyles } from '@mui/styles'
import { useContext, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { ActionItem } from 'types/actionPlanTypes'
import { DashboardMobileNavigation } from '.'
import { ActionPlanDashboardColumn } from '..'
import { ActionPlanContext } from '../../context/ActionPlanContext'
import DashboardMobileColumn from './DashboardMobileColumn'
import { DashboardMobileProps } from './types'

const useStyles = makeStyles(
    () => ({
        columns: {
            maxHeight: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
            justifyContent: 'space-between',
        },
    }),
    { name: 'DashboardMobile' }
)

export const DashboardMobile = (props: DashboardMobileProps) => {
    const { openActionItem } = useContext(ActionPlanContext)
    const [selected, setSelected] = useState<boolean>(false)
    const [moved, setMoved] = useState<boolean>(false)
    const classes = useStyles()
    const { columnOrder, columns, items, navigationValue, setNavigationValue } =
        props

    const onClickLabel = (item: ActionItem) => {
        if (moved === false) {
            openActionItem(item.issueId, item.actionItemId)
        }
        setSelected(false)
        setMoved(false)
    }

    const onMouseMoveLabel = () => {
        if (selected) {
            setMoved(true)
        }
    }

    const onMouseDownLabel = () => {
        setSelected(true)
        setMoved(false)
    }

    return (
        <>
            <SwipeableViews
                enableMouseEvents
                index={navigationValue}
                onChangeIndex={(newValue) => {
                    setNavigationValue(newValue)
                }}
                className={classes.columns}
            >
                {columnOrder.map((columnId: string) => {
                    const column: ActionPlanDashboardColumn = columns[columnId]
                    const parseItems: ActionItem[] = column.itemsIds.map(
                        (itemId: number) => items[itemId]
                    )
                    return (
                        <DashboardMobileColumn
                            isDropDisabled={false}
                            onClickLabel={onClickLabel}
                            onMouseMoveLabel={onMouseMoveLabel}
                            onMouseDownLabel={onMouseDownLabel}
                            key={column.id}
                            column={column}
                            items={parseItems}
                        />
                    )
                })}
            </SwipeableViews>
            <DashboardMobileNavigation
                navigationValue={navigationValue}
                setNavigationValue={setNavigationValue}
                columnOrder={columnOrder}
                columns={columns}
            />
        </>
    )
}
