import { Typography } from '@mui/material'
import dayjs from 'dayjs'
import { HTMLAttributes, useMemo } from 'react'
import { Dates } from 'types/issueTypes'
import { getPrettyDate } from 'utils'

interface TileDateProps extends HTMLAttributes<HTMLDivElement> {
    dates: Dates
}

export const TileDate = (props: TileDateProps) => {
    const { dates, ...other } = props

    let creationDate: string
    let submitDate: string
    let assignedDate: string
    let resolvedDate: string
    let verifiedDate: string
    let completionDate: string

    const timeLeft = useMemo(() => {
        return (
            dayjs().to(dayjs(dates.creationDate), true) +
            `${
                dayjs().valueOf() < dayjs(dates.creationDate).valueOf()
                    ? ' left'
                    : ' ago'
            }`
        )
    }, [dates.creationDate])

    useMemo(() => {
        creationDate = getPrettyDate(dates.creationDate)
        submitDate = getPrettyDate(dates.submitDate)
        assignedDate = getPrettyDate(dates.assignedDate)
        resolvedDate = getPrettyDate(dates.resolvedDate)
        verifiedDate = getPrettyDate(dates.verifiedDate)
        completionDate = getPrettyDate(dates.completionDate)
    }, [dates])

    const label = useMemo(() => {
        return `Created: ${creationDate} (${timeLeft}) 
            ${submitDate && `| Submitted: ${submitDate} `} 
            ${assignedDate && `| Assigned: ${assignedDate} `} 
            ${resolvedDate && `| Resolved: ${resolvedDate} `} 
            ${verifiedDate && `| Resolution verified: ${verifiedDate} `} 
            ${completionDate && `| Completed: ${completionDate} `}`
    }, [dates])

    return (
        <div {...other}>
            <Typography variant="caption">{label} </Typography>
        </div>
    )
}
