import { Chip } from '@mui/material'
import { EmployeeChip } from 'components'
import { CSSProperties, HTMLAttributes, ReactNode, useState } from 'react'
import theme from 'styles/themeBase'
import { Employee } from 'types/issueTypes'
import { IssueTooltip } from '.'
import { LinearContent } from './LinearContent'

export interface AssignedUsersGroupProps
    extends HTMLAttributes<HTMLDivElement> {
    usersGroup: Employee[]
    label: string
    renderIcon: (props: CSSProperties) => ReactNode
}

export const AssignedUsersGroup = (props: AssignedUsersGroupProps) => {
    const { usersGroup, renderIcon, label, ...other } = props
    const [open, setOpen] = useState(false)
    return (
        <div {...other}>
            {usersGroup && (
                <LinearContent
                    label={label}
                    renderIcon={renderIcon}
                    additionalStyle={{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    {!open && (
                        <EmployeeChip
                            style={{ margin: 4 }}
                            employee={usersGroup[0]}
                            label={usersGroup[0]?.fullName}
                        />
                    )}
                    {usersGroup.length > 1 && !open && (
                        <IssueTooltip
                            text={usersGroup.slice(1).map((i) => i?.fullName)}
                            icon={
                                <Chip
                                    variant="outlined"
                                    style={{
                                        backgroundColor:
                                            theme.palette.grey[100],
                                        fontSize:
                                            theme.typography.caption.fontSize,
                                    }}
                                    label={`+${usersGroup.length - 1} more`}
                                    onClick={() => setOpen(true)}
                                />
                            }
                        ></IssueTooltip>
                    )}
                    {open && (
                        <>
                            {usersGroup.map((u) => (
                                <EmployeeChip
                                    style={{ margin: 4 }}
                                    employee={u}
                                    label={u?.fullName}
                                />
                            ))}
                        </>
                    )}
                </LinearContent>
            )}
        </div>
    )
}
