import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    ISSUE_VERIFY_CONTAINMENT_CALL,
    loadIssue,
    verifyContainmentDone,
} from '..'

import { VerifyContainmentCommand } from '../types'
import { verifyContainmentActionExtended } from 'api'
import { removeMyActionById } from 'store/myActions'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { setIssueActionFail } from '../actions'
import { RootState } from '../../types'

export function* verifyContainmentAction(action: VerifyContainmentCommand) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    const { changeLog } = (yield select(
        (s: RootState) => s.issue
    )) as IssueStoreState
    try {
        yield call(
            verifyContainmentActionExtended,
            query,
            changeLog?.resolve ?? null,
            cancelSource.token
        )
        yield put(verifyContainmentDone())
        yield put(
            removeMyActionById(
                query.issueId,
                IssueActionsTypes.To_Execute_Containment_Actions
            )
        )
        yield put(showSnackbar('Containment actions were confirmed', false))
        yield put(loadIssue(query.issueId))
    } catch (error) {
        yield put(showSnackbar("Containment actions weren't confirmed!", true))
        yield put(setIssueActionFail(ISSUE_VERIFY_CONTAINMENT_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* verifyContainmentSaga() {
    yield takeLatest([ISSUE_VERIFY_CONTAINMENT_CALL], verifyContainmentAction)
}
