import { ActionItemBase, ActionItemStatus } from 'types/actionPlanTypes'
import { FilterBase } from 'types/models'
import {
    ActionPlanItemsQuery,
    ActionPlanItemsStats,
    ActionPlanItemViewModel,
    SortByPropertyType,
} from '.'

export function getInitActionPlanItemsQuery(): ActionPlanItemsQuery {
    return {
        wildcard: '',
        descending: false,
        sortByProperty: 'actionName',
        actionCreatorFilter: true,
        actionOwnerFilter: true,
        coordinatiorFilter: true,
        statuses: [
            ActionItemStatus.ToDo,
            ActionItemStatus.InProgress,
            ActionItemStatus.InProgressOverdue,
        ],
    }
}

export function getOrderProperties(): FilterBase<string>[] {
    return [
        { code: 'actionCreator', name: 'Action creator' },
        { code: 'actionDeadline', name: 'Action deadline' },
        { code: 'actionName', name: 'Action name' },
        { code: 'actionOwner', name: 'Action owner' },
        { code: 'actionStatus', name: 'Action status' },
        { code: 'actionType', name: 'Action type' },
        { code: 'issueNumber', name: 'Issue number' },
        { code: 'resolutionOwner', name: 'Resolution owner' },
        { code: 'resolvingUnit', name: 'Resolving unit' },
    ]
}

export function getSearchProporties(): Array<keyof ActionPlanItemViewModel> {
    return [
        'actionCreatorFullName',
        'actionOwnerFullName',
        'resolutionOwnerFullName',
        'actionName',
        'issueNumber',
        'resolvingUnitName',
        'actionTypeName',
    ]
}

export function getInitActionPlanStats(): ActionPlanItemsStats {
    return {
        actionOwner: null,
        actionCreator: null,
        issueCoordinator: null,
        draft: null,
        inprogress: null,
        inprogressOverdue: null,
        closed: null,
        deleted: null,
    }
}

export function getActionPlanStats(
    items: ActionItemBase[],
    currentUserEmail: string = ''
): ActionPlanItemsStats {
    const results = getInitActionPlanStats()
    items.forEach((x) => {
        const {
            actionCreator,
            actionOwner,
            actionStatus,
            isDeleted,
            issueCoordinator,
        } = x
        results.actionCreator =
            actionCreator?.email === currentUserEmail
                ? results.actionCreator + 1
                : results.actionCreator
        results.actionOwner =
            actionOwner?.email === currentUserEmail
                ? results.actionOwner + 1
                : results.actionOwner
        results.issueCoordinator =
            issueCoordinator?.email === currentUserEmail
                ? results.issueCoordinator + 1
                : results.issueCoordinator
        switch (actionStatus) {
            case ActionItemStatus.ToDo:
                results.draft = !isDeleted ? results.draft + 1 : results.draft
                break
            case ActionItemStatus.InProgress:
                results.inprogress = results.inprogress + 1
                break
            case ActionItemStatus.InProgressOverdue:
                results.inprogressOverdue = results.inprogressOverdue + 1
                break
            case ActionItemStatus.Complated:
                results.closed = results.closed + 1
                break
        }
        if (isDeleted) results.deleted = results.deleted + 1
    })
    return results
}

export const generateRolesAndStatuses = (
    actionPlanItemsQuery: ActionPlanItemsQuery
) => {
    let statuses: string[] | string = []
    if (actionPlanItemsQuery.statuses.includes(ActionItemStatus.ToDo)) {
        statuses.push('Draft')
    }
    if (actionPlanItemsQuery.statuses.includes(ActionItemStatus.InProgress)) {
        statuses.push('In progress')
    }
    if (
        actionPlanItemsQuery.statuses.includes(
            ActionItemStatus.InProgressOverdue
        )
    ) {
        statuses.push('In progress overdue')
    }
    if (actionPlanItemsQuery.statuses.includes(ActionItemStatus.Complated)) {
        statuses.push('Closed')
    }
    statuses = statuses.join(', ')

    let roles: string[] | string = []
    if (actionPlanItemsQuery.actionCreatorFilter) {
        roles.push('Action creator')
    }
    if (actionPlanItemsQuery.actionOwnerFilter) {
        roles.push('Action owner')
    }
    roles = roles.join(', ')

    return [statuses, roles]
}

export const sortFilteredResults = (
    results: ActionPlanItemViewModel[],
    sortByProperty: SortByPropertyType | string,
    descending: boolean
) => {
    results.sort((a, b) => {
        let nameA = null
        let nameB = null

        if (sortByProperty === 'actionDeadline') {
            nameA = new Date(a[sortByProperty])
            nameB = new Date(b[sortByProperty])
        } else if (sortByProperty === 'actionCreator') {
            nameA = a.actionCreatorFullName
            nameB = b.actionCreatorFullName
        } else if (sortByProperty === 'actionOwner') {
            nameA = a.actionOwnerFullName
            nameB = b.actionOwnerFullName
        } else if (sortByProperty === 'actionType') {
            nameA = a.actionTypeName
            nameB = b.actionTypeName
        } else if (sortByProperty === 'resolvingUnit') {
            nameA = a.resolvingUnitName
            nameB = b.resolvingUnitName
        } else if (sortByProperty === 'resolutionOwner') {
            nameA = a.resolutionOwnerFullName
            nameB = b.resolutionOwnerFullName
        } else {
            nameA = a[sortByProperty]
            nameB = b[sortByProperty]
        }

        if (nameA < nameB) {
            if (descending) {
                return 1
            }
            return -1
        } else if (nameA > nameB) {
            if (descending) {
                return -1
            }
            return 1
        }
        return 0
    })
}
