import { Typography } from '@mui/material'
import { NO_RESULTS_PAGE } from '../../consts/selectorIds'
import RoundedButton from '../Base/RoundedButton'
import { NoResultsSvg } from '../Images'
import useStyles from './styles'

export interface NoResultsProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title?: string
    subTitle?: string
    buttonLabel?: string
    errorHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const NoResults = (props: NoResultsProps) => {
    const classes = useStyles(props)

    let { title, subTitle: subTitleProp, buttonLabel, errorHandler } = props
    title = title ?? 'No issues to display!'
    let subTitle =
        subTitleProp ?? 'Probably you have not any issues related to you.'
    buttonLabel = buttonLabel ?? 'Refresh'
    return (
        <div className={classes.content}>
            <NoResultsSvg />
            <section className={classes.info} {...NO_RESULTS_PAGE.INFO_SECTION}>
                <Typography variant="h4" {...NO_RESULTS_PAGE.HEADLINE}>
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    {...NO_RESULTS_PAGE.SUB_HEADLINE}
                >
                    {subTitle}
                </Typography>
            </section>
            {errorHandler && (
                <RoundedButton
                    color="secondary"
                    variant="contained"
                    onClick={errorHandler}
                    {...NO_RESULTS_PAGE.LOAD_ISSUES_BUTTON}
                >
                    {buttonLabel}
                </RoundedButton>
            )}
        </div>
    )
}

export default NoResults
