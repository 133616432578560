import BuildIcon from '@mui/icons-material/Build'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'
import DomainIcon from '@mui/icons-material/Domain'
import MoreIcon from '@mui/icons-material/More'
import SettingsIcon from '@mui/icons-material/Settings'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { IssueTypes } from 'types/enums'
import { FilterBase } from 'types/models'
import { dataTestId, EMPTY_VALUE_PLACEHOLDER } from 'utils'
import { LinearContent } from './LinearContent'

interface IssueInformationProps {
    resolvingUnit: string
    issueType: IssueTypes
    product: string
    affectedCustomer: string
    failureMode: string
}

export const IssueInformation = (props: IssueInformationProps) => {
    const { resolvingUnit, issueType, product, affectedCustomer, failureMode } =
        props
    const issueTypes = useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.issueType
    )
    const issueLabel = useMemo(() => {
        return (
            issueTypes.find((x) => x.code === issueType)?.name ??
            EMPTY_VALUE_PLACEHOLDER
        )
    }, [issueTypes, issueType])

    return (
        <div>
            {resolvingUnit && (
                <LinearContent
                    label={'Resolving Unit: '}
                    renderIcon={(props) => (
                        <DomainIcon fontSize="small" style={{ ...props }} />
                    )}
                    {...dataTestId(`DASHBOARD_ISSUE_TILE_RESOLVING_UNIT`)}
                >
                    {resolvingUnit}
                </LinearContent>
            )}
            <LinearContent
                label={'Issue type: '}
                renderIcon={(props) => <MoreIcon style={{ ...props }} />}
                {...dataTestId(`DASHBOARD_ISSUE_TILE_ISSUE_TYPE`)}
            >
                {issueLabel}
            </LinearContent>
            {affectedCustomer && (
                <LinearContent
                    label={'Customer: '}
                    renderIcon={(props) => (
                        <BusinessCenterIcon style={{ ...props }} />
                    )}
                    {...dataTestId(`DASHBOARD_ISSUE_TILE_CUSTOMER`)}
                >
                    {affectedCustomer}
                </LinearContent>
            )}
            {product && (
                <LinearContent
                    label={'Product: '}
                    renderIcon={(props) => (
                        <SettingsIcon style={{ ...props }} />
                    )}
                    {...dataTestId(`DASHBOARD_ISSUE_TILE_PRODUCT`)}
                >
                    {product}
                </LinearContent>
            )}
            {failureMode && (
                <LinearContent
                    label={'Failure mode: '}
                    renderIcon={(props) => <BuildIcon style={{ ...props }} />}
                    {...dataTestId(`DASHBOARD_ISSUE_TILE_FAILURE_MODE`)}
                >
                    {failureMode}
                </LinearContent>
            )}
        </div>
    )
}
