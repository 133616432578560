import { Typography } from '@mui/material'
import { AbbTypography, RoundedButton } from 'components'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SurveyTranslationContext, SurveyTranslationContextProps } from '..'
import { useStyles } from '../Survey.style'

export interface SuccessfullyUnsubscribedSurveyProps {
    onSubscribe: () => void
}

export const SuccessfullyUnsubscribedSurvey = ({
    onSubscribe,
}: SuccessfullyUnsubscribedSurveyProps) => {
    const classes = useStyles()
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    return (
        <>
            <Helmet>
                <title>
                    {t(
                        'GdprPrivacyNotice_Unsubscribe_Title',
                        'You have been successfully unsubscribed.'
                    )}
                </title>
            </Helmet>
            <AbbTypography variant="h2">
                {t(
                    'GdprPrivacyNotice_Unsubscribe_Title',
                    'You have been successfully unsubscribed.'
                )}
            </AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                {t(
                    'GdprPrivacyNotice_Unsubscribe_Text',
                    'If you did this in error, you may re-subscribe by clicking the button below.'
                )}
            </Typography>
            <RoundedButton
                onClick={onSubscribe}
                color="secondary"
                variant="contained"
            >
                {t('GdprPrivacyNotice_ResubscribeButton_Label', 'Subscribe')}
            </RoundedButton>
        </>
    )
}
