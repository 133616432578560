import { ShoppingCartOutlined } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import {
    BusinessUnitFilterBase,
    DivisionFilterBase,
    ProductGroupFilterBase,
} from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionLabel, getOptionSelected } from './utils'

export interface MultiProductGroupSelectProps
    extends Partial<BaseTextFieldProps> {
    division: DivisionFilterBase[]
    businessUnit: BusinessUnitFilterBase[]
    selectedProductsGroups: ProductGroupFilterBase[]
    onProductsGroupsChanged: (
        selectedProductsGroups: ProductGroupFilterBase[]
    ) => void
    displayAll?: boolean
    displayInactive?: boolean
}

export const MultiProductGroupSelect = (
    props: MultiProductGroupSelectProps
) => {
    const {
        division = [],
        businessUnit = [],
        selectedProductsGroups = [],
        onProductsGroupsChanged,
        error,
        helperText,
        disabled,
        displayAll = false,
        displayInactive = false,
        ...other
    } = props

    const allOptions = useSelector(
        (state: RootState) => state.resources.productGroups
    )
    const options = useMemo(() => {
        const filterByDivisions = Array.isArray(division) && 0 < division.length
        const filterByBusinessUnit =
            Array.isArray(businessUnit) && 0 < businessUnit.length
        return allOptions
            .filter((x) => (displayInactive ? true : x.isActive))
            .filter((x) =>
                filterByDivisions
                    ? division.some((d) => d.code === x.divisionCode)
                    : true
            )
            .filter((x) =>
                filterByBusinessUnit
                    ? businessUnit.some((d) => d.code === x.businessUnitCode)
                    : true
            )
    }, [allOptions, businessUnit, displayInactive, division])

    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | ProductGroupFilterBase>
    ) =>
        onProductsGroupsChanged &&
        onProductsGroupsChanged([...values] as Array<ProductGroupFilterBase>)

    useEffect(() => {
        const filterByDivisions = Array.isArray(division) && 0 < division.length
        const filterByBusinessUnit =
            Array.isArray(businessUnit) && 0 < businessUnit.length
        if (filterByDivisions || filterByBusinessUnit) {
            const newProductGroups = [...selectedProductsGroups]
            const productGroupsToRemove = []
            newProductGroups.forEach((x, i) => {
                if (
                    !(
                        businessUnit
                            .map((x) => x.code)
                            .includes(x.businessUnitCode) ||
                        division.map((x) => x.code).includes(x.divisionCode)
                    )
                ) {
                    productGroupsToRemove.push(i)
                }
            })
            if (0 < productGroupsToRemove.length) {
                productGroupsToRemove.forEach((i) => {
                    newProductGroups.splice(i, 1)
                })
                onProductsGroupsChanged(newProductGroups)
            }
        }
    }, [businessUnit, division])
    const isDisabled = !displayAll
        ? Array.isArray(businessUnit) && businessUnit?.length === 0
        : false
    return (
        <Autocomplete
            disabled={disabled || isDisabled}
            multiple={true}
            value={selectedProductsGroups}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={(option) =>
                `(${option.divisionCode}/${option.businessUnitCode}/${option.code}) ${option.name}`
            }
            isOptionEqualToValue={getOptionSelected}
            loadingText={' Loading Product Groups…'}
            disableClearable={disabled}
            noOptionsText={'No match Product Groups'}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          `(${f.divisionCode}/${f.businessUnitCode}/${f.code}) ${f.name}`
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    error={error}
                    helperText={helperText}
                    params={params}
                    label="Products Groups"
                    {...dataTestId('PRODUCTS_GROUP_COMMON')}
                    renderIcon={(props) => <ShoppingCartOutlined {...props} />}
                    {...other}
                />
            )}
        />
    )
}
