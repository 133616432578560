import { useMsal } from '@azure/msal-react'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { loginRequest } from 'configs/authProvider'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { AbbTheme } from 'styles/createAbbTheme'
import { BaseBar, RoundedButton, SigninSvg } from '../components'
import { SIGN_IN_PAGE } from '../consts/selectorIds'
import { gdprListKey } from './GdprGuard'
export const ccrpLogoutKey = 'ccrp-logout'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'column',
            width: 600,
            marginTop: theme.spacing(4),
            'box-sizing': 'border-box',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.spacing(4),
            backgroundColor: theme.palette.common.white,
            '@media (max-width: 600px)': {
                width: '100vw',
            },
        },
        info: {
            maxWidth: 320,
            textAlign: 'center',
        },
        headline: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'Signin' }
)

export interface SigninProps {
    login?: () => any
}

const Signin = (props: SigninProps) => {
    const { instance } = useMsal()
    const handleLogin = () => {
        instance.loginRedirect(loginRequest)
    }
    useEffect(() => {
        const keys = []
        for (let index = 0; index < localStorage.length; index++) {
            keys.push(localStorage.key(index))
        }
        if (keys.some((k) => k === ccrpLogoutKey)) {
            keys.forEach((k) => k !== gdprListKey && localStorage.removeItem(k))
        }
    }, [])
    const classes = useStyles(props)
    return (
        <>
            <BaseBar />
            <div className={classes.content}>
                <Helmet>
                    <title>Sign in to CCRP</title>
                </Helmet>
                <SigninSvg />
                <section className={classes.info}>
                    <Typography variant="h4" className={classes.headline}>
                        Welcome to CCRP
                    </Typography>
                </section>
                <RoundedButton
                    onClick={() => handleLogin()}
                    color="secondary"
                    variant="contained"
                    {...SIGN_IN_PAGE.LOG_IN_BUTTON}
                >
                    Log in
                </RoundedButton>
            </div>
        </>
    )
}

export default Signin
