import axios, { AxiosResponse, CancelToken } from 'axios'
import { ResolvingUnit } from 'types/issueTypes'
import { SearchRUQuery } from '../types/queries'

const controllerName = '/resolving-unit'

/**
 * @param {string} employeeEmail - Email of employee
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getRecentEmployeeResolvingUnits = (
    employeeEmail: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<ResolvingUnit>>> =>
    axios.get(controllerName + `/employee/${employeeEmail}/recent`, {
        cancelToken,
    })

/**
 * @param {body} query - Email of employee
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const searchResolvingUnits = (
    query: SearchRUQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<ResolvingUnit>>> =>
    axios.post(controllerName + `/search`, { ...query }, { cancelToken })
