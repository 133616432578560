import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { LoadingSvg, RoundedButton } from 'components'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            zIndex: theme.zIndex.modal,
            padding: theme.spacing(3),
            boxSizing: 'border-box',
            minWidth: 320,
            width: '100%',
            maxWidth: 480,
            minHeight: 256,
            top: theme.spacing(6),
            left: '50%',
            transform: 'translateX(-50%)',
            position: 'fixed',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        actions: {
            display: 'flex',
            width: '100%',
            height: 48,
            justifyContent: 'center',
            marginTop: theme.spacing(4),
        },
        extendAction: {
            marginLeft: theme.spacing(2),
        },
    }),
    {
        name: 'SessionTimeoutWarrning',
    }
)

export interface SessionTimeoutWarrningProps {
    onExtendSession: () => void
    onCloseSession: () => void
    expireTimeInMinutes?: number
}

export const SessionTimeoutWarrning = (props: SessionTimeoutWarrningProps) => {
    const { onExtendSession, onCloseSession, expireTimeInMinutes = 5 } = props
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <LoadingSvg />
            <Typography variant="h5">Session expire warning</Typography>
            <Typography variant="body2">
                {`Your session will expire in ${expireTimeInMinutes} minutes. Do you want to extend it?`}
            </Typography>
            <div className={classes.actions}>
                <RoundedButton onClick={onCloseSession} variant="outlined">
                    Cancel
                </RoundedButton>
                <RoundedButton
                    onClick={onExtendSession}
                    className={classes.extendAction}
                    color="secondary"
                    variant="contained"
                >
                    Ok
                </RoundedButton>
            </div>
        </div>
    )
}
