import {
    BusinessCenterOutlined,
    FlagOutlined,
    PermContactCalendarOutlined,
} from '@mui/icons-material'
import { List, Typography } from '@mui/material'
import {
    AdminCardHeader,
    CustomerContactsListItem,
    useCustomerContactsContext,
} from 'Admin'
import { AdminCustomer } from 'api'
import {
    Button,
    CardViewResults,
    CardViewResultsContainer,
    ChipLabel,
    ExpandableCardView,
    ValueViewNext,
} from 'components'
import { useMemo } from 'react'
import { dataTestId, EMPTY_VALUE_PLACEHOLDER } from 'utils'

export interface CustomerContactsManagePageCardProps {
    customer: AdminCustomer
}

export const CustomerContactsManagePageCard = (
    props: CustomerContactsManagePageCardProps
) => {
    const { customer } = props
    const { openCustomerContactDialog } = useCustomerContactsContext()

    const contacts = customer?.contacts ? customer.contacts : []

    const [activeContacts, inActiveContacts] = useMemo(
        () => [
            contacts.filter((item) => item.isActive),
            contacts.filter((item) => !item.isActive),
        ],
        [contacts]
    )

    const results: CardViewResults = useMemo(
        () => [
            {
                label: 'Address',
                renderIcon: (props) => <FlagOutlined {...props} />,
                text: customer?.address?.full ?? null,
                idTest: 'CUSTOMER_ADRES',
            },
            {
                label: 'Global HQ Guid',
                renderIcon: (props) => <BusinessCenterOutlined {...props} />,
                text: customer?.headquarterGuid,
            },
        ],
        [customer]
    )

    const renderContent = () => (
        <>
            <CardViewResultsContainer results={results} />

            <ValueViewNext
                renderIcon={(props) => (
                    <PermContactCalendarOutlined {...props} />
                )}
                label="Active contacts"
                fullWidth
                {...dataTestId(`ACTIVE_CONTACTS`)}
            >
                <List
                    style={{
                        paddingTop: 0,
                    }}
                >
                    {0 < activeContacts.length
                        ? activeContacts.map(
                              (customerContact) =>
                                  !customerContact.email.includes(
                                      'archive.contact'
                                  ) && (
                                      <CustomerContactsListItem
                                          customerContact={customerContact}
                                          customer={customer}
                                          key={customerContact.id}
                                      />
                                  )
                          )
                        : EMPTY_VALUE_PLACEHOLDER}
                </List>
            </ValueViewNext>
            {0 < inActiveContacts.length && (
                <ValueViewNext
                    renderIcon={(props) => (
                        <PermContactCalendarOutlined {...props} />
                    )}
                    label="Inactive contacts"
                    fullWidth
                    {...dataTestId(`INACTIVE_CONTACTS`)}
                >
                    <List
                        style={{
                            paddingTop: 0,
                        }}
                    >
                        {inActiveContacts.map((customerContact) => (
                            <CustomerContactsListItem
                                customerContact={customerContact}
                                customer={customer}
                                key={customerContact.id}
                            />
                        ))}
                    </List>
                </ValueViewNext>
            )}
            <Button
                onClick={() =>
                    openCustomerContactDialog('add', {
                        customer,
                    })
                }
                color="secondary"
                {...dataTestId(`ADD_NEW_CONTACT`)}
            >
                Add new contact
            </Button>
        </>
    )
    return (
        <ExpandableCardView
            renderHeader={() => (
                <AdminCardHeader
                    renderTags={() => (
                        <>
                            <ChipLabel
                                label={customer.guid}
                                variant="grey"
                                {...dataTestId(`BAL_ID_NUMBER`)}
                            />
                        </>
                    )}
                >
                    <Typography>{customer.name}</Typography>
                </AdminCardHeader>
            )}
            renderContent={renderContent}
            {...dataTestId(`ADMIN_CUSTOMER_CONTAINER_${customer.guid}`)}
        ></ExpandableCardView>
    )
}
