import { LinearProgress, Typography, useTheme } from '@mui/material'
import { ActionPlanDashboardColumn } from 'ActionPlan'
import { ActionPlanContext } from 'ActionPlan/context/ActionPlanContext'
import { Label, TabsView } from 'components'
import { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router'
import { IssueStoreState, loadActionPlan } from 'store/issue'
import { RootState } from 'store/types'
import { ActionItem } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'
import { ActionPlanSectionActions, ActionPlanSectionColumn } from './components'

export interface ActionPlanSectionProps {
    expanded: boolean
}

export const ActionPlanSection = (props: ActionPlanSectionProps) => {
    const { expanded } = props
    const theme = useTheme()
    const { dashboardState, updateActionItemsDashboard } =
        useContext(ActionPlanContext)
    const history = useHistory()
    const dispatch = useDispatch()
    const { actionItems, loadingActionPlan, issueId } = useSelector<
        RootState,
        IssueStoreState
    >((state) => state.issue)
    useEffect(() => {
        updateActionItemsDashboard(actionItems)
    }, [actionItems])
    useEffect(() => {
        if (expanded && actionItems.length === 0) {
            dispatch(loadActionPlan(issueId))
        }
    }, [expanded])
    const openActionPlanDashboard = () => {
        history.push(
            generatePath('/issue/:issueId/action-plan', {
                issueId,
            })
        )
    }
    const { columnOrder, columns, items } = dashboardState
    const { Draft, InProgress, Closed } = columns

    return (
        <>
            <Label>Action Plan</Label>
            {loadingActionPlan ? (
                <LinearProgress
                    style={{ margin: theme.spacing(2, 0) }}
                    color="secondary"
                    variant="indeterminate"
                />
            ) : (
                <>
                    {actionItems && actionItems.length > 0 ? (
                        <TabsView
                            animateHeight={true}
                            variant="fullWidth"
                            tabs={[
                                {
                                    label: `${Draft.title} (${Draft.itemsIds.length})`,
                                    ...dataTestId('ACTION_DRAFT_BUTTON'),
                                },
                                {
                                    label: `${InProgress.title} (${InProgress.itemsIds.length})`,
                                    ...dataTestId('ACTION_IN_PROGRESS_BUTTON'),
                                },
                                {
                                    label: `${Closed.title} (${Closed.itemsIds.length})`,
                                    ...dataTestId('ACTION_CLOSED_BUTTON'),
                                },
                            ]}
                        >
                            {columnOrder.map((columnId: string) => {
                                const column: ActionPlanDashboardColumn =
                                    columns[columnId]
                                const parseItems: ActionItem[] =
                                    column.itemsIds.map(
                                        (itemId: number) => items[itemId]
                                    )
                                return (
                                    <ActionPlanSectionColumn
                                        items={parseItems}
                                        key={columnId}
                                    />
                                )
                            })}
                        </TabsView>
                    ) : (
                        <Typography>
                            You have not created any action items
                        </Typography>
                    )}
                    <ActionPlanSectionActions
                        openActionPlanDashboard={openActionPlanDashboard}
                    />
                </>
            )}
        </>
    )
}
