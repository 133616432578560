import { ChipLabel, StatusChipLabelProps } from 'components'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { RemainingTimeColor } from './MyIssueItem'
import { useRemainingTimeColor } from './utils'

export interface MyIssueStatusChipLabelProps extends StatusChipLabelProps {
    date: RemainingTimeColor
    label?: string
    isProgressBar?: boolean
    progressBarLabel?: string
}

export const MyIssueStatusChipLabel = (props: MyIssueStatusChipLabelProps) => {
    const issueStatus = useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.complaintStatus
    )
    const {
        progressBarLabel,
        issueStatusId,
        date,
        label,
        isProgressBar = false,
        ...other
    } = props
    const issueStatusItem = issueStatus.find(
        (item: any) => String(item.code) === String(issueStatusId)
    )
    let [, color] = useRemainingTimeColor(date)

    if (!issueStatusItem) {
        return null
    }

    let issueStatusLabel = label ? label : issueStatusItem.name

    if (issueStatusId === 2 || issueStatusId === 8 || issueStatusId === 13)
        color = 'grey'

    return issueStatusId ? (
        <ChipLabel
            {...other}
            label={
                isProgressBar && progressBarLabel
                    ? progressBarLabel
                    : issueStatusLabel
            }
            variant={label ? 'blue' : color}
        />
    ) : null
}

export default MyIssueStatusChipLabel
