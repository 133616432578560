import { NavButton } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { TileActionsProps } from '..'

export const ToReplaceInactiveRUActionTileActions = (
    props: TileActionsProps
) => {
    const { myActionMeta } = props
    return (
        <>
            <NavButton
                color="secondary"
                linkProps={{ to: `/issue/${myActionMeta.issueId}` }}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .REPLACE_INACTIVE_RU}
                eventName={'Replace inactive resolution unit'}
            >
                REPLACE INACTIVE RESOLVING UNIT
            </NavButton>
        </>
    )
}
