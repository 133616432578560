import { Typography } from '@mui/material'
import RoundedButton from '../Base/RoundedButton'
import { NoInternetSvg } from '../Images'
import useStyles from './styles'

export interface OfflineProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title?: string
    subTitle?: string
    buttonLabel?: string
    errorHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const Offline = (props: OfflineProps) => {
    const classes = useStyles(props)

    let { title, subTitle, buttonLabel, errorHandler } = props
    title = title ? title : "You're offline!"
    subTitle = subTitle
        ? subTitle
        : 'There seems to be no internet connection. Please, check your settings or try again.'

    buttonLabel = buttonLabel ? buttonLabel : 'Retry'
    return (
        <div className={classes.content}>
            <NoInternetSvg />
            <section className={classes.info}>
                <Typography variant="h5">{title}</Typography>
                <Typography variant="body1" component="p">
                    {subTitle}
                </Typography>
            </section>
            {errorHandler && (
                <RoundedButton
                    color="secondary"
                    variant="contained"
                    onClick={errorHandler}
                >
                    {buttonLabel}
                </RoundedButton>
            )}
        </div>
    )
}

export default Offline
