import { ExpandMore } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AdminResolvingUnitsDialogQuery,
    AdminSearchPanelActions,
    BusinessAreaActivityEnum,
    EmployeeStatusEnum,
    EmployeeTypeEnum,
    getInitQueryForAdminResolvingUnits,
    ResolvingUnitsManagePageDialog,
    ResolvingUnitsManagePageFields,
    ResolvingUnitsManagePageResults,
    RUPersonsAssociationEnum,
} from 'Admin'
import { addAdminResolvingUnits, searchAdminResolvingUnits } from 'api/admin'
import {
    ActivitySelect,
    BusinessUnitSelect,
    CitySelect,
    Content,
    CountrySelect,
    DivisionSelect,
    EmployeeSelect,
    FormControlLabel,
    FormGridItem,
    GridContainer,
    PageTitle,
    ProductGroupSelect,
    RegionSelect,
    SearchPanel,
    Switch,
    TextField,
} from 'components'
import { ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import { loadProfile } from 'store/dashboard'
import { RootState } from 'store/types'
import { AdminEmployeeTypeEnum, AdminResolvingUnit } from 'types/adminTypes'
import { Employee } from 'types/issueTypes'
import { UserProfile } from 'types/profile'
import { AdminResolvingUnitSearchQuery } from 'types/queries'
import { dataTestId } from 'utils'

export const ADMIN_RESOLVING_UNITS_PAGE_SIZE = 20

export const ResolvingUnitManagePage = () => {
    const dispatch = useDispatch()
    const [noResults, setNoResults] = useState(false)
    const [page, setPage] = useState(1)
    const [openedDialog, setOpenedDialog] = useState<boolean>(false)
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [error, setError] = useState<unknown>(null)
    const [personsIds, setPersonsIds] = useState<number[]>([])
    const [searchResults, setSearchResults] = useState<AdminResolvingUnit[]>([])
    const [isSearching, setISearching] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [query, setQuery] = useState<ResolvingUnitsManagePageFields>(
        getInitQueryForAdminResolvingUnits()
    )
    const profile = useSelector<RootState, UserProfile>(
        (state) => state?.dashboard?.profile ?? null
    )
    const resetForm = () => {
        setQuery(getInitQueryForAdminResolvingUnits())
        setPersonsIds([])
        setSearchText('')
    }

    const handleSearch = async () => {
        try {
            setPersonsIds([])
            setISearching(true)
            setError(null)
            const parseQuery: Partial<AdminResolvingUnitSearchQuery> = {
                divisionCode: query.businessArea?.code,
                buCode: query.division?.code,
                pgCode: query.productGroup?.code,
                regionCode: query.region?.code,
                countryCode: query.country?.code,
                cityCode: query.city?.code,
                activityCode: query.activity?.code,
                employee: query.employee?.geid,
                onlyMine: query.ruPersonAssigned,
                withoutActivePeople: query.withoutActivePeople,
            }

            if (searchText !== '') {
                parseQuery.balId = searchText
            }

            if (
                BusinessAreaActivityEnum.Global === query.businessAreaActivity
            ) {
                parseQuery.isGlobal = true
            } else if (
                BusinessAreaActivityEnum.Local === query.businessAreaActivity
            ) {
                parseQuery.isGlobal = false
            }

            if (EmployeeStatusEnum.OnlyActiveRUs === query.employeeStatus) {
                parseQuery.isActive = true
            } else if (
                EmployeeStatusEnum.InactiveRUs === query.employeeStatus
            ) {
                parseQuery.isActive = false
            }

            if (EmployeeTypeEnum.All === query.employeeType) {
                parseQuery.ruPersonType = AdminEmployeeTypeEnum.All
            } else if (EmployeeTypeEnum.Coordinator === query.employeeType) {
                parseQuery.ruPersonType = AdminEmployeeTypeEnum.Coordinator
            } else if (EmployeeTypeEnum.RUProcessOwner === query.employeeType) {
                parseQuery.ruPersonType = AdminEmployeeTypeEnum.RUProcessOwner
            }

            if (
                RUPersonsAssociationEnum.Assigned === query.ruPersonsAssociation
            ) {
                parseQuery.ruPersonAssigned = true
            } else if (
                RUPersonsAssociationEnum.NotAssigned ===
                query.ruPersonsAssociation
            ) {
                parseQuery.ruPersonAssigned = false
            }
            setNoResults(false)
            const { data, status } = await searchAdminResolvingUnits(parseQuery)
            setPage(1)
            setSearchResults(data?.items ?? [])
            if (status === 204) setNoResults(true)
        } catch (error) {
            setError(error)
        } finally {
            setISearching(false)
        }
    }

    const updateResolvingUnitsLocal = (
        updatedResolvingUnits: AdminResolvingUnit[]
    ) => {
        dispatch(loadProfile())
        setSearchResults((prev) => {
            const parsedRU = [...prev]
            updatedResolvingUnits.forEach((aru) => {
                const index = parsedRU.findIndex((item) => item.id === aru.id)
                parsedRU[index] = { ...aru }
            })
            return parsedRU
        })
    }

    const handleChange =
        (fieldName: keyof ResolvingUnitsManagePageFields) =>
        (newValue: ReactNode) => {
            setQuery((prev: ResolvingUnitsManagePageFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }

    const getFieldPropsForAutoComplete = (
        fieldName: keyof ResolvingUnitsManagePageFields,
        label: string,
        onChangeCallback: (newValue: any) => void = null
    ) => {
        return {
            disabled: isSearching,
            onChange: (e, newValue) => {
                setQuery((prev: ResolvingUnitsManagePageFields) => ({
                    ...prev,
                    [fieldName]: newValue,
                }))
                onChangeCallback && onChangeCallback(newValue)
            },
            value: query[fieldName],
            renderInput: (params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                    }}
                    label={label}
                />
            ),
        }
    }

    const togglePerson = (id: number) => {
        setPersonsIds((prev) => {
            const isExist = prev.find((item) => item === id)
            if (isExist) {
                const isSame = id === isExist
                if (isSame) {
                    return prev.filter((item) => item !== id)
                }
                return prev
            }
            return [...prev, id]
        })
    }

    const addPerson = (id: number) => {
        const isExist = personsIds.find((item) => item === id)
        if (!isExist) {
            setPersonsIds((prev) => [...prev, id])
        }
        setOpenedDialog(true)
    }

    const handleAddPersonsToRUs = async (
        resolvingUnitIds: number[],
        query: AdminResolvingUnitsDialogQuery
    ) => {
        try {
            setLoading(true)
            setLoadingDesc('Adding persons to Resolving Unit...')
            setOpenedDialog(false)
            const promises = resolvingUnitIds.map((id) => {
                return addAdminResolvingUnits({
                    resolvingUnitId: id,
                    ruProcessOwnerEmails: query.processOwners.map(
                        (item) => item.email
                    ),
                    ruCoordinatorEmails: query.coordinators.map(
                        (item) => item.email
                    ),
                })
            })
            await Promise.all(promises)
            const updatePersons = (
                oldList: Employee[],
                newList: Employee[]
            ): Employee[] => {
                const results = oldList ? [...oldList] : []
                newList.forEach((e) => {
                    const duplicate = results.find(
                        (old) => old.email === e.email
                    )
                    if (duplicate) {
                        const index = results.findIndex(
                            (e) => e.email === duplicate.email
                        )
                        results[index] = duplicate
                    } else {
                        results.push(e)
                    }
                })
                return results
            }
            resolvingUnitIds.forEach((updatedRUId) => {
                const index = searchResults.findIndex(
                    (ru) => ru.id === updatedRUId
                )
                if (index < 0) {
                    return
                }
                const ru = { ...searchResults[index] }
                ru.processOwners = updatePersons(
                    ru.processOwners,
                    query.processOwners
                )
                ru.coordinators = updatePersons(
                    ru.coordinators,
                    query.coordinators
                )
                searchResults[index] = ru
            })
            setSearchResults([...searchResults])
            dispatch(showSnackbar('Resolving Unit Persons added'))
            dispatch(loadProfile())
        } catch (err) {
            dispatch(
                showSnackbar('Adding persons to Resolving Unit failed', true)
            )
        } finally {
            setLoading(false)
            setLoadingDesc('')
            setPersonsIds([])
        }
    }

    const isGlobal =
        query?.businessAreaActivity === BusinessAreaActivityEnum.Global
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    const filtersSection = useMemo(
        () => (
            <GridContainer>
                <FormGridItem>
                    <Autocomplete
                        options={Object.values(BusinessAreaActivityEnum)}
                        {...getFieldPropsForAutoComplete(
                            'businessAreaActivity',
                            'Business Area activity',
                            (newValue) => {
                                const value =
                                    newValue as BusinessAreaActivityEnum
                                if (value === BusinessAreaActivityEnum.Global) {
                                    setQuery((prev) => ({
                                        ...prev,
                                        region: null,
                                        country: null,
                                        city: null,
                                        activity: null,
                                    }))
                                }
                            }
                        )}
                    />
                    <DivisionSelect
                        disabled={isSearching}
                        selectedDivision={query.businessArea}
                        onDivisionChanged={handleChange('businessArea')}
                        {...dataTestId('BUSINESS_AREA_RU')}
                    />
                    <BusinessUnitSelect
                        disabled={isSearching}
                        division={query.businessArea}
                        selectedBusinessUnit={query.division}
                        onBusinessUnitChanged={handleChange('division')}
                        {...dataTestId('DIVISION_RU')}
                    />
                    <ProductGroupSelect
                        disabled={isSearching}
                        division={query.businessArea}
                        businessUnit={query.division}
                        selectedProductGroup={query.productGroup}
                        onProductGroupChanged={handleChange('productGroup')}
                        {...dataTestId('PG_RUPG_RU')}
                    />
                </FormGridItem>
                <FormGridItem>
                    <RegionSelect
                        disabled={isSearching || isGlobal}
                        selectedRegion={query.region}
                        onRegionChanged={handleChange('region')}
                    />
                    <CountrySelect
                        disabled={isSearching || isGlobal}
                        region={query.region}
                        selectedCountry={query.country}
                        onCountryChanged={handleChange('country')}
                    />
                    <CitySelect
                        disabled={isSearching || isGlobal}
                        region={query.region}
                        country={query.country}
                        selectedCity={query.city}
                        onCityChanged={handleChange('city')}
                        displayAll
                    />
                    <ActivitySelect
                        style={{ marginBottom: 0 }}
                        disabled={isSearching || isGlobal}
                        selectedActivity={query.activity}
                        onActivityChanged={handleChange('activity')}
                    />
                </FormGridItem>
                <Accordion square elevation={0}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h6">Advanced filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <GridContainer>
                            <FormGridItem>
                                <Autocomplete
                                    options={Object.values(EmployeeStatusEnum)}
                                    {...getFieldPropsForAutoComplete(
                                        'employeeStatus',
                                        'Resolving Unit Status'
                                    )}
                                />
                                <EmployeeSelect
                                    disabled={isSearching}
                                    label="Employee"
                                    employee={query.employee}
                                    onEmployeeChanged={handleChange('employee')}
                                />
                                <Autocomplete
                                    options={Object.values(EmployeeTypeEnum)}
                                    {...getFieldPropsForAutoComplete(
                                        'employeeType',
                                        'Employee role'
                                    )}
                                />
                            </FormGridItem>
                            <FormGridItem>
                                <Autocomplete
                                    options={Object.values(
                                        RUPersonsAssociationEnum
                                    )}
                                    {...getFieldPropsForAutoComplete(
                                        'ruPersonsAssociation',
                                        'RU Persons association'
                                    )}
                                />
                                <div
                                    style={{
                                        marginTop: theme.spacing(2),
                                        marginLeft: theme.spacing(1),
                                    }}
                                >
                                    <FormControlLabel
                                        style={{
                                            marginRight: theme.spacing(2),
                                        }}
                                        control={
                                            <Switch
                                                checked={query.ruPersonAssigned}
                                                onChange={() =>
                                                    setQuery((prev) => ({
                                                        ...prev,
                                                        ruPersonAssigned:
                                                            !prev.ruPersonAssigned,
                                                    }))
                                                }
                                            />
                                        }
                                        label="RUs where I am assigned"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    query.withoutActivePeople
                                                }
                                                onChange={() =>
                                                    setQuery((prev) => ({
                                                        ...prev,
                                                        withoutActivePeople:
                                                            !prev.withoutActivePeople,
                                                    }))
                                                }
                                            />
                                        }
                                        label="RUs without active people"
                                    />
                                </div>
                            </FormGridItem>
                        </GridContainer>
                    </AccordionDetails>
                </Accordion>
            </GridContainer>
        ),
        [isSearching, query]
    )

    return (
        <Content
            loading={loading}
            loadingDesc={loadingDesc}
            onRetry={() => setError(null)}
            error={error}
            variant="medium"
        >
            <PageTitle
                title="Resolving units"
                desc="This panel allows you to manage RU Process owners and Coordinators in Resolving units. Each Resolving unit can be managed by RU Process owners assigned to this specific unit and all process owners who are listed under 'Show process owners' button."
                to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B2B60C5BD-A4D3-4B22-9D57-565D428B6FC3%7D&file=CCRP%20Managing%20Resolving%20Units.pptx&action=edit&mobileredirect=true"
            />
            <SearchPanel
                initOpen={true}
                placeholder="Search by BAL ID. Enter at least 3 chars..."
                onEnter={() => !isSearching && handleSearch()}
                searchText={searchText}
                onChange={(text) => {
                    setSearchText(text)
                }}
                onClearText={() => setSearchText('')}
                disabled={isSearching}
            >
                {filtersSection}
                <AdminSearchPanelActions
                    isSearching={isSearching}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>

            <ResolvingUnitsManagePageResults
                noResults={noResults}
                profile={profile}
                isSearching={isSearching}
                searchResults={searchResults}
                togglePerson={togglePerson}
                addPerson={addPerson}
                personsIds={personsIds}
                updateResolvingUnitsLocal={updateResolvingUnitsLocal}
                page={page}
                onPageChange={handlePageChange}
            />
            <ResolvingUnitsManagePageDialog
                open={openedDialog}
                onAddPersons={handleAddPersonsToRUs}
                onClose={() => setOpenedDialog(false)}
                personsIds={personsIds}
                adminResolvingUnits={searchResults}
                updateResolvingUnitsLocal={updateResolvingUnitsLocal}
            />
        </Content>
    )
}
