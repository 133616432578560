import axios, { AxiosResponse, CancelToken } from 'axios'
import { GenericNode } from 'components/TreeView/types'

const controllerName = '/products'

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */

export const getProductByCID = (
    cid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<GenericNode>> =>
    axios.get(`${controllerName}/${cid}`, {
        cancelToken,
    })

export const getProductRootsByResolvingUnit = (
    resolvingUnitId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/roots/${resolvingUnitId}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} parentCid - product CID
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getProductChildren = (
    resolvingUnitId: number,
    parentCid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/children/${resolvingUnitId}/${parentCid}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} nameOrCid - product name or CID
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const searchProductByNameOrCid = (
    resolvingUnitId: number,
    nameOrCid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.post(
        `${controllerName}/search`,
        { resolvingUnitId, nameOrCid },
        { cancelToken }
    )
