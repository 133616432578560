import { Paper, PaperProps, useTheme } from '@mui/material'
import { TREE_NODE } from 'consts/selectorIds'
import { createContext, ReactNode } from 'react'
import { PgCategoryNode } from '.'
import { AdminNode, GenericNode, GenericNodeBase } from './types'

export interface TreeContextProps<T = GenericNode> {
    nodes: T[]
    selectedNodes?: T[]
    onSelectNode?: (node: T) => void
    onConfirmButton?: (node: PgCategoryNode) => ReactNode
    onListItemClicked?: (node: T) => void
    expandedNodes?: string[]
    onExpandNode?: (node: GenericNode, expanded: boolean) => void
    onDeleteNode?: (node: GenericNodeBase) => void
    onEditNode?: (node: GenericNodeBase) => void
    onAddNode?: (parentNode: GenericNodeBase) => void
    loadingNodes?: string[]
    onDoubleClickConfirm?: (node: PgCategoryNode) => void
}

export const TreeContext = createContext<TreeContextProps>({ nodes: [] })

export interface TreeRootsProps<T = GenericNode | AdminNode | PgCategoryNode>
    extends TreeContextProps<T> {
    renderNodes: (nodes: T[], level: number) => ReactNode
    PaperProps?: PaperProps
}

export const TreeRoot = (props: TreeRootsProps) => {
    const {
        nodes,
        selectedNodes,
        onSelectNode,
        onConfirmButton,
        onDoubleClickConfirm,
        expandedNodes,
        onExpandNode,
        loadingNodes,
        onEditNode,
        onDeleteNode,
        onAddNode,
        renderNodes,
        PaperProps = {},
    } = props
    const theme = useTheme()
    return (
        <TreeContext.Provider
            value={{
                nodes,
                selectedNodes: selectedNodes ? [...selectedNodes] : [],
                onSelectNode,
                onConfirmButton,
                onDoubleClickConfirm,
                expandedNodes,
                onExpandNode,
                loadingNodes,
                onEditNode,
                onAddNode,
                onDeleteNode,
            }}
        >
            <Paper
                style={{
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: theme.shape.borderRadius,
                }}
                elevation={0}
                {...PaperProps}
                {...TREE_NODE.ROOT}
            >
                {nodes && renderNodes(nodes, 0)}
            </Paper>
        </TreeContext.Provider>
    )
}
