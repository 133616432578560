import { all } from 'redux-saga/effects'
import { loadIssueSaga } from './loadIssue'
import { loadContextSaga } from './loadContext'
import { saveAsDraftSaga } from './saveAsDraft'
import { loadMatrixSchemeSaga } from './loadMatrixScheme'
import { initExpandState } from './initExpandState'
import { calculateMatrixSchemeSaga } from './calculateMatrixScheme'
import { generateReportSaga } from './generateReport'
import { loadAttachmentsSaga } from './loadAttachments'
import { returnToIssueOwnerSaga } from './returnToIssueOwner'
import { saveAssignSectionSaga } from './saveAssignSection'
import { removeRcaSaga } from './removeRca'
import { initNewDraftSaga } from './initNewDraft'
import { saveResolutionSectionSaga } from './saveResolutionSection'
import { resolveIssueSaga } from './resolveIssue'
import { saveClosingSectionSaga } from './saveClosingSection'
import { saveDraftSaga } from './saveDraft'
import { verifyResolutionSaga } from './verifyResolution'
import { submitDraftSaga } from './submitDraft'
import { saveEffectivenessSectionSaga } from './saveEffectivenessSection'
import { completeIssueSaga } from './completeIssue'
import { verifyContainmentSaga } from './verifyContainment'
import { deleteDraftSaga } from './deleteDraft'
import { rejectResolutionToROSaga } from './rejectResolutionToRO'
import { confirmEffectivenessSaga } from './confirmEffectiveness'
import { rejectResolutionSaga } from './rejectResolution'
import { reopenIssueSaga } from './reopenIssue'
import { assignIssueSaga } from './assignIssue'
import { revertToNotAssignedSaga } from './revertToNotAssigned'
import { loadActionPlanSaga } from './loadActionPlan'
import { generateOverviewInPowerPointSaga } from './generateOverviewInPowerPointSaga'
import { generateCcrpOverviewReportSaga } from './generateCcrpOverviewReportSaga'
import { confirmHISaga } from './confirmHI'
import { downgradeHISaga } from './downgradeHI'
import { sendIssueMessageSaga } from './sendIssueMessage'

function* root() {
    yield all([
        loadIssueSaga(),
        saveAsDraftSaga(),
        initExpandState(),
        loadMatrixSchemeSaga(),
        calculateMatrixSchemeSaga(),
        loadContextSaga(),
        generateReportSaga(),
        loadAttachmentsSaga(),
        returnToIssueOwnerSaga(),
        saveAssignSectionSaga(),
        removeRcaSaga(),
        initNewDraftSaga(),
        saveResolutionSectionSaga(),
        resolveIssueSaga(),
        saveClosingSectionSaga(),
        saveDraftSaga(),
        verifyResolutionSaga(),
        submitDraftSaga(),
        saveEffectivenessSectionSaga(),
        completeIssueSaga(),
        verifyContainmentSaga(),
        deleteDraftSaga(),
        rejectResolutionToROSaga(),
        confirmEffectivenessSaga(),
        rejectResolutionSaga(),
        reopenIssueSaga(),
        assignIssueSaga(),
        revertToNotAssignedSaga(),
        loadActionPlanSaga(),
        generateCcrpOverviewReportSaga(),
        generateOverviewInPowerPointSaga(),
        confirmHISaga(),
        downgradeHISaga(),
        sendIssueMessageSaga(),
    ])
}

export default root
