import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ClearIcon from '@mui/icons-material/Clear'
import { useTheme } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { PICKER_TEXT_FIELD } from 'consts/selectorIds'
import { ReactNode } from 'react'
import { dataTestId } from 'utils'
import TextField, { AbbTextFieldProps } from './TextField'

export interface PickerTextFieldProps extends AbbTextFieldProps {
    onSelect: () => void
    onClear?: () => void
    onClearValue?: () => void
    endIcon?: ReactNode
}

export const PickerTextField = (props: PickerTextFieldProps) => {
    const {
        onSelect,
        onClear,
        onClearValue,
        disabled,
        startAdornment: startAdornmentProp,
        value,
        endIcon,
        ...other
    } = props
    const theme = useTheme()
    const style = disabled
        ? {}
        : {
              color: theme.palette.common.black,
              cursor: disabled ? 'auto' : 'pointer',
          }
    let startAdornment = startAdornmentProp
    if (!disabled && onClear && value && !startAdornment) {
        startAdornment = (
            <IconButton
                size="small"
                onClick={(e) => {
                    onClear && onClear()
                    e.stopPropagation()
                }}
            >
                <ClearIcon fontSize="small" />
            </IconButton>
        )
    }
    return (
        <TextField
            disabled={disabled}
            value={value}
            onClick={() => {
                !disabled && onSelect && onSelect()
            }}
            {...other}
            InputProps={{
                // disabled: true,
                style: { ...style },
            }}
            inputProps={{
                style: {
                    pointerEvents: 'none',
                    cursor: disabled ? 'auto' : 'pointer',
                },
            }}
            startAdornment={startAdornment}
            endAdornment={
                <>
                    {!disabled && (
                        <>
                            {value && (
                                <IconButton
                                    size="small"
                                    {...dataTestId('REMOVE_BUTTON')}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        onClearValue && onClearValue()
                                    }}
                                >
                                    <ClearIcon />
                                </IconButton>
                            )}
                            <IconButton size="small">
                                {endIcon ?? (
                                    <ArrowForwardIcon
                                        {...PICKER_TEXT_FIELD.ARROW_BUTTON}
                                    />
                                )}
                            </IconButton>
                        </>
                    )}
                </>
            }
        />
    )
}

export default PickerTextField
