import { put, select, takeLatest } from 'redux-saga/effects'
import { ISSUE_SELECT_ROLES, selectCurrentMatrix } from '..'
import { RootState } from 'store/types'
import { IssueStoreState } from '../initState'
import { mergeMatrixForRoles } from '../utils'

export function* calculateMatrixSchemeAction() {
    const { context, accessMatrixes, selectedRolesCodes } = (yield select(
        (s: RootState) => s?.issue
    )) as IssueStoreState
    const roles = context?.myRoles
    if (!Array.isArray(roles) || roles.length === 0) {
        yield put(selectCurrentMatrix())
        return
    } else {
        const currentMatrix = mergeMatrixForRoles(
            selectedRolesCodes,
            accessMatrixes
        )
        yield put(
            selectCurrentMatrix(
                currentMatrix.currentActions,
                currentMatrix.dependedFields
            )
        )
    }
}

export function* calculateMatrixSchemeSaga() {
    yield takeLatest([ISSUE_SELECT_ROLES], calculateMatrixSchemeAction)
}
