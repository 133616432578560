import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            marginBottom: theme.spacing(2),
            overflow: 'hidden',
        },
    }),
    { name: 'TileContainer' }
)

export const TileContainer: FC<HtmlHTMLAttributes<HTMLElement>> = ({
    children,
    className,
    ...other
}) => {
    const classes = useStyles()
    return (
        <section className={clsx(className, classes.root)} {...other}>
            {children}
        </section>
    )
}
