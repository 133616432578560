import { AppBar, Toolbar } from '@mui/material'
import { Fragment } from 'react'
import { AppLogo } from '..'

export interface BaseBarProps {
    title?: string
}

export const BaseBar = (props: BaseBarProps) => {
    const { title } = props
    return (
        <Fragment>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <AppLogo title={title} />
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Fragment>
    )
}

export default BaseBar
