import { useState } from 'react'

import { Help } from '@mui/icons-material'
import {
    FormControlLabel,
    IconButton,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material'
import { IssueCreationTypes, IssueTypes, IssueValuesKeys } from 'types/enums'
import DialogBase from '../../components/Dialogs/DialogBase'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { useIssueTypeMap } from '../../hooks'
import { useFieldWithError } from '../matrix'

export interface IssueTypeSectionProps {
    disabled: boolean
    issueType: IssueTypes
    issueCreationType: IssueCreationTypes
    onIssueTypeChanged?: (issueType: IssueTypes) => void
}

const IssueTypeSection = (props: any) => {
    const {
        disabled,
        issueType,
        issueCreationType,
        onIssueTypeChanged,
        ...other
    } = props
    const [open, setOpen] = useState<boolean>(false)

    const issueTypesMap = useIssueTypeMap()
    const theme = useTheme()
    const issueTypeGroup = useFieldWithError(IssueValuesKeys.IssueType, {
        disabled,
    })
    return (
        <div {...other}>
            <Typography variant="h6">Issue type</Typography>
            <RadioGroup
                style={{ marginBottom: theme.spacing(2) }}
                {...ISSUE_VIEW_PAGE.ISSUE_TYPE.RADIO_GROUP}
                disabled={issueTypeGroup.disabled}
                value={issueType}
                onChange={(e) => {
                    onIssueTypeChanged &&
                        onIssueTypeChanged(Number(e.target.value) as IssueTypes)
                }}
            >
                <FormControlLabel
                    value={IssueTypes.External}
                    disabled={issueTypeGroup.disabled}
                    control={<Radio />}
                    label={issueTypesMap.get(IssueTypes.External)}
                />
                <FormControlLabel
                    value={IssueTypes.Internal}
                    disabled={issueTypeGroup.disabled}
                    control={<Radio />}
                    label={issueTypesMap.get(IssueTypes.Internal)}
                />
                <span>
                    <FormControlLabel
                        value={IssueTypes.Internal_NC}
                        disabled={
                            issueTypeGroup.disabled ||
                            issueCreationType !== IssueCreationTypes.Manual
                        }
                        control={<Radio />}
                        label={issueTypesMap.get(IssueTypes.Internal_NC)}
                    />
                    &nbsp;
                    <IconButton
                        onClick={() => setOpen(true)}
                        size="small"
                        style={{ color: theme.palette.grey[400] }}
                        {...ISSUE_VIEW_PAGE.ISSUE_TYPE.TOOLTIP_BUTTON}
                    >
                        <Help fontSize="small" />
                    </IconButton>
                </span>

                <DialogBase
                    open={open}
                    title="Unit internal NC"
                    closeLabel="Cancel"
                    onClose={() => setOpen(false)}
                >
                    <Typography
                        variant="body2"
                        style={{ margin: theme.spacing(1, 0) }}
                    >
                        Unit-internal NC CCRP always has the following options
                        enabled:
                        <ul>
                            <li>RCA is required</li>
                            <li>Containment Actions are required</li>
                            <li>Issue Impact is set to Normal</li>
                            <li>
                                Solution must be verified by the Coordinator
                            </li>
                        </ul>
                        Additionally, fields listed below are not available for
                        this issue type:
                        <ul>
                            <li>What are the customer’s expectations?</li>
                            <li>Customer name</li>
                            <li>Customer contact name</li>
                            <li>Quantity</li>
                            <li>Issue Summary</li>
                        </ul>
                        whereas there are two additional, mandatory fields in
                        the issue description section:
                        <ul>
                            <li>Who is affected by the problem?</li>
                            <li>How many defects/units/parts are affected?</li>
                        </ul>
                    </Typography>
                </DialogBase>
            </RadioGroup>
        </div>
    )
}

IssueTypeSection.defaultProps = {
    issueType: IssueTypes.External,
}

export default IssueTypeSection
