import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import { Attachment } from 'types/issueTypes'
import AttachmentBase, { AttachmentProps } from './Attachment'

import { LinearProgress } from '@mui/material'
import { FILE_MANAGER } from '../../consts/selectorIds'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
        },
        label: {
            display: 'inline-flex',
            height: 36,
            alignItems: 'center',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightBold as number,
            marginBottom: theme.spacing(0.5),
        },
        progress: {
            marginLeft: theme.spacing(2),
        },
        caption: {
            ...theme.typography.body2,
            color: theme.palette.grey[300],
        },
        error: {
            fontSize: '0.75rem',
            color: theme.palette.red.main,
        },
        errorAstrisk: {
            color: theme.palette.red.main,
        },
        refresh: {
            width: 20,
            height: 20,
            color: theme.palette.grey[600],
        },
    }),
    { name: 'AttachmentsView' }
)

export interface AttachmentsViewProps extends AttachmentProps {
    attachments: Array<Attachment>
    label: string
    disabled: boolean
    required?: boolean
    loading: boolean
}

export const AttachmentsView = (props: AttachmentsViewProps) => {
    const { attachments, label, loading, onFileRemove, disabled, required } =
        props

    const classes = useStyles()
    const displayLabel = (
        <span>
            {label ? label : 'Attachments'}
            {required && (
                <span
                    {...FILE_MANAGER.REQUIRED_ASTERISK}
                    className={classes.errorAstrisk}
                >
                    {' '}
                    *
                </span>
            )}
        </span>
    )

    let content = null

    switch (true) {
        case loading:
            content = (
                <LinearProgress variant="indeterminate" color="secondary" />
            )
            break
        case attachments && 0 < attachments.length:
            content = attachments.map((file) => (
                <AttachmentBase
                    key={file.id}
                    file={file}
                    onFileRemove={onFileRemove}
                    disabled={disabled}
                />
            ))
            break
        default:
            content = (
                <span className={classes.caption}>
                    {'No attachments to display…'}
                </span>
            )
            break
    }

    return (
        <div className={classes.root}>
            <div className={classes.label}>{displayLabel}</div>
            {content}
        </div>
    )
}

AttachmentsView.defautProps = {
    attachments: [],
    loading: false,
}

export default AttachmentsView
