import { makeStyles } from '@mui/styles'
import {
    CustomerContactsManagePageContextProvider,
    CustomerContactsTab,
    CustomersTab,
} from 'Admin'
import { Content, PageTitle, TabsView } from 'components'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            margin: theme.spacing(0, 1),
        },
        tabs: {
            marginBottom: theme.spacing(3),
        },
    }),
    {
        name: 'CustomerContactsManagePage',
    }
)

export const CustomerContactsManagePage = () => {
    const classes = useStyles()

    return (
        <Content variant="medium">
            <CustomerContactsManagePageContextProvider>
                <PageTitle
                    title="Customer contacts"
                    desc="This panel allows you to manage Customers contacts."
                    to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B8FE6B738-B9D8-4466-AD8D-EEBB8D033518%7D&file=CCRP%20Managing%20Customer%20Contacts.pptx&action=edit&mobileredirect=true"
                />
                <TabsView
                    classes={{
                        tabs: classes.tabs,
                    }}
                    tabs={[
                        {
                            label: `By Customers`,
                            idTest: 'ADMIN_BY_CUSTOMERS',
                        },
                        {
                            label: `By Customer contact`,
                            idTest: 'ADMIN_BYCUSTOMER_CONTACT',
                        },
                    ]}
                >
                    <CustomersTab />
                    <CustomerContactsTab />
                </TabsView>
            </CustomerContactsManagePageContextProvider>
        </Content>
    )
}
