import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { Employee } from 'types/issueTypes'
import { FilterBase } from 'types/models'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface MultiEmployeeRolesSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedItems: FilterBase<number>[]
    employee: Employee
    onSelectedItemsChanged: (items: FilterBase<number>[]) => void
}

export const MultiEmployeeRolesSelect = (
    props: MultiEmployeeRolesSelectProps
) => {
    const {
        selectedItems,
        onSelectedItemsChanged,
        error,
        employee,
        helperText,
        disabled,
        ...other
    } = props
    const options = useSelector(
        (state: RootState) => state.resources.allInOneReportEmployeeRoles
    )
    useEffect(() => {
        if (!employee) {
            onSelectedItemsChanged([])
        } else if (Array.isArray(selectedItems) && selectedItems.length === 0) {
            onSelectedItemsChanged([{ ...options[0] }])
        }
    }, [employee])
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | FilterBase<number>>
    ) => {
        const items = values as FilterBase<number>[]
        if (Array.isArray(items) && items.length === 0) {
            onSelectedItemsChanged([{ ...options[0] }])
            return
        } else if (items[items.length - 1]?.code === 0) {
            onSelectedItemsChanged([{ ...options[0] }])
            return
        } else {
            onSelectedItemsChanged([...items].filter((x) => x.code !== 0))
        }
    }

    return (
        <Autocomplete
            disabled={disabled || !employee}
            multiple={true}
            value={selectedItems}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            disableClearable={disabled}
            noOptionsText={'No match Divisions'}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    error={error}
                    helperText={helperText}
                    params={params}
                    label="Employee roles"
                    {...other}
                />
            )}
        />
    )
}
