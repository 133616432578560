import { SvgIcon, SvgIconProps } from '@mui/material'
import clsx from 'clsx'
import { useSvgIconStyles } from './SvgIcon.style'

export const CloseFullScreen = (props: SvgIconProps) => {
    const { className, fontSize, ...other } = props
    const classes = useSvgIconStyles()
    return (
        <SvgIcon className={clsx(className, classes[fontSize])} {...other}>
            <path d="M22,3.41l-5.29,5.29L20,12h-8V4l3.29,3.29L20.59,2L22,3.41z M3.41,22l5.29-5.29L12,20v-8H4l3.29,3.29L2,20.59L3.41,22z" />
        </SvgIcon>
    )
}
