import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    setIssueActionFail,
    ISSUE_CONFIRM_HI_CALL,
    IssueStoreState,
    ISSUE_CONFIRM_HI_FAIL,
} from '..'

import { RootState } from 'store/types'
import { confirmHIIssue } from 'api'
import { removeMyActionById } from 'store/myActions'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { confirmHIDone, loadIssue } from '../actions'
import { AnyAction } from 'redux'

export function* confirmHIAction(action: AnyAction) {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueId, rowVersion } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        yield call(
            confirmHIIssue,
            { issueId, rowVersion, motivation: action.payload },
            cancelSource.token
        )
        yield put(confirmHIDone())
        yield put(loadIssue(issueId))
        yield put(removeMyActionById(issueId, IssueActionsTypes.To_Verify_HI))
        yield put(showSnackbar('High impact was confirmed'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_CONFIRM_HI_FAIL, error))
        yield put(showSnackbar("High impact wasn't confirmed!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* confirmHISaga() {
    yield takeLatest([ISSUE_CONFIRM_HI_CALL], confirmHIAction)
}
