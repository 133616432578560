import { memo } from 'react'
import { ChipLabel, ChipLabelProps } from 'components'
import { ActionItemStatus } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'

export interface ActionPlanStatusChipProps
    extends Omit<ChipLabelProps, 'label' | 'variant'> {
    status: ActionItemStatus
}

export const ActionPlanStatusChip = memo((props: ActionPlanStatusChipProps) => {
    const { status, ...other } = props
    
    switch (status) {
        case ActionItemStatus.New:
            return <ChipLabel label="New" variant="grey" {...other}  {...dataTestId('ACTION_PLAN_STATUS')}/>
        case ActionItemStatus.ToDo:
            return <ChipLabel label="Draft" variant="grey" {...other} {...dataTestId('ACTION_PLAN_STATUS')}/>
        case ActionItemStatus.InProgress:
            return <ChipLabel label="In progress" variant="blue" {...other} {...dataTestId('ACTION_PLAN_STATUS')}/>
        case ActionItemStatus.InProgressOverdue:
            return (
                <ChipLabel
                    label="In progress overdue"
                    variant="red"
                    {...other}
                />
            )
        case ActionItemStatus.Complated:
            return <ChipLabel label="Closed" variant="green" {...other} {...dataTestId('ACTION_PLAN_STATUS')}/>
        default:
            return <ChipLabel label="No label" variant="red" {...other} {...dataTestId('ACTION_PLAN_STATUS')}/>
    }
})
