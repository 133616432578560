import { PeopleOutline } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AutoCompleteInput, Chip } from 'components'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, KeyboardEventHandler, useState } from 'react'
import { Employee } from 'types/issueTypes'
import { validateEmail } from 'utils'

export interface OtherEmailsSelectProps extends Partial<BaseTextFieldProps> {
    employees?: Array<Employee>
    onEmployeesChanged?: (employees: Array<Employee>) => void
}

const getInitEmployee = () => ({
    geid: null,
    phone: null,
    organizationInfo: null,
    countryCode: null,
    supervisor: null,
    fullName: null,
    firstName: null,
    lastName: null,
    email: null,
    isActive: true,
})

export const OtherEmailsSelect = (props: OtherEmailsSelectProps) => {
    const {
        disabled,
        employees = [],
        onEmployeesChanged,
        error: errorProps,
        helperText: helperTextProps,
        ...other
    } = props
    const [email, setEmail] = useState('')
    const [error, setError] = useState(false)
    const [helperText, setHelperText] = useState('')
    const getOptionLabel = (option: Employee): string => {
        return option?.email ?? ''
    }
    const getOptionSelected = (option: Employee, value: Employee): boolean => {
        return option?.email === value?.email
    }
    const handleOnChange = (
        e: ChangeEvent<unknown>,
        values: Array<string | Employee>
    ) => {
        setEmail('')
        onEmployeesChanged && onEmployeesChanged([...values] as Array<Employee>)
    }
    const handleTryAddEmail: KeyboardEventHandler<HTMLDivElement> = (e) => {
        if (e.key === 'Enter' || e.key === ' ') {
            const trimedEmail = email.trim()
            if (validateEmail(trimedEmail)) {
                const newEmail = getInitEmployee()
                newEmail.email = trimedEmail
                onEmployeesChanged &&
                    onEmployeesChanged([
                        ...employees,
                        newEmail,
                    ] as Array<Employee>)
                setEmail('')
            } else {
                if (0 < trimedEmail.length) {
                    setError(true)
                    setHelperText('Wrong email address format')
                }
            }
            return
        }
        setError(false)
        setHelperText('')
    }
    const handleBlur = () => {
        const trimedEmail = email.trim()
        if (validateEmail(trimedEmail)) {
            const newEmail = getInitEmployee()
            newEmail.email = trimedEmail
            onEmployeesChanged &&
                onEmployeesChanged([...employees, newEmail] as Array<Employee>)
        }
        setEmail('')
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={employees ?? []}
            open={false}
            options={[]}
            inputValue={email}
            onKeyDown={handleTryAddEmail}
            onInputChange={(e, value, reason) => {
                if (reason === 'input') {
                    setEmail(value)
                } else if (reason === 'clear') {
                    setEmail('')
                }
            }}
            onBlur={handleBlur}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            getOptionDisabled={(option) => !Boolean(option?.isActive)}
            noOptionsText={'Type correct email...'}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) => {
                          const label = state.getOptionLabel(f)
                          return label
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      })
            }}
            disableClearable={disabled}
            renderOption={(props, option) => {
                return <li {...props}>{option.email}</li>
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.email}
                            {...getTagProps({ index })}
                        />
                    )
                })
            }
            renderInput={(params) => {
                return (
                    <AutoCompleteInput
                        error={Boolean(error) || errorProps}
                        helperText={helperText || helperTextProps}
                        renderIcon={(props) => <PeopleOutline {...props} />}
                        params={params}
                        {...other}
                    />
                )
            }}
        />
    )
}
