import { Language } from '@mui/icons-material'
import {
    AutocompleteChangeReason,
    BaseTextFieldProps,
    useTheme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { getShortOptionLabel } from 'components'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/types'
import { CountryFilter, FilterBase } from '../../types/models'
import TextField from '../Base/TextField'

export interface CountrySelectProps extends Partial<BaseTextFieldProps> {
    region?: FilterBase
    selectedCountry: FilterBase
    onCountryChanged: (item: FilterBase) => void
}

export const CountrySelect = (props: CountrySelectProps) => {
    const {
        label = 'Country',
        disabled,
        selectedCountry,
        onCountryChanged,
        error: errorProps,
        helperText: helperTextProps,
        region,
        ...other
    } = props
    const countries = useSelector<RootState, CountryFilter[]>(
        (state) => state.resources.countries
    )

    const options = useMemo(() => {
        if (region?.code) {
            return countries.filter((c) => c?.regionCode === region?.code)
        }
        return countries
    }, [countries, region])
    const theme = useTheme()
    const handleSelectFilter = (item: FilterBase) => onCountryChanged(item)
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option?.code === value?.code
    }

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedCountry ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Countries'}
            loadingText={'Loading Countries…'}
            disableClearable={false}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          f.name
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as FilterBase
                switch (reason) {
                    case 'clear':
                        handleSelectFilter(null)
                        break
                    default:
                        handleSelectFilter({ ...selectedValue })
                }
            }}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    helperText={helperTextProps}
                    autoComplete="off"
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'off',
                        style: { paddingTop: 0 },
                        startAdornment: (
                            <Language
                                fontSize="small"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default CountrySelect
