export const MY_ACTIONS_CLEAR_ERROR = 'MY_ACTIONS_CLEAR_ERROR'
export const MY_ACTIONS_LOADING = 'MY_ACTIONS_LOADING'
export const MY_ACTIONS_LOAD_DONE = 'MY_ACTIONS_LOAD_DONE'
export const MY_ACTIONS_LOAD_ERROR = 'MY_ACTIONS_LOAD_ERROR'
export const MY_ACTION_REMOVE_BY_ID = 'MY_ACTION_REMOVE_BY_ID'
export const MY_ACTIONS_CLEAR = 'MY_ACTIONS_CLEAR'
export const MY_ACTION_UPDATE_BY_ID = 'MY_ACRION_UPDATE_BY_ID'
export const MY_ACTIONS_RETURN_TO_IO_CALL = 'MY_ACTIONS_RETURN_TO_IO_CALL'
export const MY_ACTIONS_RETURN_TO_IO_DONE = 'MY_ACTIONS_RETURN_TO_IO_DONE'
export const MY_ACTIONS_RETURN_TO_IO_FAIL = 'MY_ACTIONS_RETURN_TO_IO_FAIL'
export const MY_ACTIONS_CONFIRM_HI_CALL = 'MY_ACTIONS_CONFIRM_HI_CALL'
export const MY_ACTIONS_CONFIRM_HI_DONE = 'MY_ACTIONS_CONFIRM_HI_DONE'
export const MY_ACTIONS_CONFIRM_HI_FAIL = 'MY_ACTIONS_CONFIRM_HI_FAIL'
export const MY_ACTIONS_DOWNGRADE_HI_CALL = 'MY_ACTIONS_DOWNGRADE_HI_CALL'
export const MY_ACTIONS_DOWNGRADE_HI_DONE = 'MY_ACTIONS_DOWNGRADE_HI_DONE'
export const MY_ACTIONS_DOWNGRADE_HI_FAIL = 'MY_ACTIONS_DOWNGRADE_HI_FAIL'
export const MY_ACTIONS_VERIFY_CONTAINMENT_CALL =
    'MY_ACTIONS_VERIFY_CONTAINMENT_CALL'
export const MY_ACTIONS_VERIFY_CONTAINMENT_DONE =
    'MY_ACTIONS_VERIFY_CONTAINMENT_DONE'
export const MY_ACTIONS_VERIFY_CONTAINMENT_FAIL =
    'MY_ACTIONS_VERIFY_CONTAINMENT_FAIL'
export const MY_ACTIONS_REOPEN_ISSUE_CALL = 'MY_ACTIONS_REOPEN_ISSUE_CALL'
export const MY_ACTIONS_REOPEN_ISSUE_DONE = 'MY_ACTIONS_REOPEN_ISSUE_DONE'
export const MY_ACTIONS_REOPEN_ISSUE_FAIL = 'MY_ACTIONS_REOPEN_ISSUE_FAIL'
export const MY_ACTIONS_VERIFY_RESOLUTION_CALL =
    'MY_ACTIONS_VERIFY_RESOLUTION_CALL'
export const MY_ACTIONS_VERIFY_RESOLUTION_DONE =
    'MY_ACTIONS_VERIFY_RESOLUTION_DONE'
export const MY_ACTIONS_VERIFY_RESOLUTION_FAIL =
    'MY_ACTIONS_VERIFY_RESOLUTION_FAIL'
export const MY_ACTIONS_REJECT_RESOLUTION_CALL =
    'MY_ACTIONS_REJECT_RESOLUTION_CALL'
export const MY_ACTIONS_REJECT_RESOLUTION_DONE =
    'MY_ACTIONS_REJECT_RESOLUTION_DONE'
export const MY_ACTIONS_REJECT_RESOLUTION_FAIL =
    'MY_ACTIONS_REJECT_RESOLUTION_FAIL'

export const MY_ACTIONS_EXPORT_CALL = 'MY_ACTIONS_EXPORT_CALL'
export const MY_ACTIONS_EXPORT_DONE = 'MY_ACTIONS_EXPORT_DONE'
export const MY_ACTIONS_EXPORT_FAIL = 'MY_ACTIONS_EXPORT_FAIL'
