import { ButtonBase, FormHelperText } from '@mui/material'
import clsx from 'clsx'
import { FlexGrow } from '../components'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export type ScoreValues = null | 1 | 2 | 3 | 4 | 5

export interface ScoreProps {
    minimalValueLabel?: string
    maximumValueLabel?: string
    error?: boolean
    helperText?: string
    score?: ScoreValues
    onChange: (score: ScoreValues) => void
}
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(2, 0),
        },
        scores: {
            textAlign: 'center',
        },
        score: {
            boxSizing: 'border-box',
            ...theme.typography.body2,
            width: '20%',
            height: 40,
            margin: theme.spacing(1, 0),
            border: `1px solid ${theme.palette.divider}`,
            transition: ' background-color 0.4',
            '&:first-child': {
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
            },
            '&:last-child': {
                borderTopRightRadius: theme.shape.borderRadius,
                borderBottomRightRadius: theme.shape.borderRadius,
            },
            '&$selected': {
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                fontWeight: theme.typography.fontWeightBold as number,
                '&:hover': {
                    backgroundColor: theme.palette.secondary.dark,
                },
            },
            '&:hover': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        labels: {
            display: 'flex',
            flexDirection: 'row',
            color: theme.palette.grey[600],
            fontWeight: theme.typography.fontWeightBold as number,
        },
        helperText: {
            marginTop: theme.spacing(2),
        },
        selected: {},
    }),
    { name: 'Score' }
)

export const Score = (props: ScoreProps) => {
    const {
        helperText,
        error,
        minimalValueLabel,
        maximumValueLabel,
        score,
        onChange,
    } = props
    const rates: ScoreValues[] = [1, 2, 3, 4, 5]
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.scores}>
                {rates.map((r) => (
                    <ButtonBase
                        onClick={() => onChange(r)}
                        className={clsx(
                            classes.score,
                            score === r && classes.selected
                        )}
                        key={r}
                    >
                        {r}
                    </ButtonBase>
                ))}
            </div>
            {(minimalValueLabel || maximumValueLabel) && (
                <div className={classes.labels}>
                    {minimalValueLabel && (
                        <FormHelperText>{minimalValueLabel}</FormHelperText>
                    )}
                    <FlexGrow />
                    {maximumValueLabel && (
                        <FormHelperText>{maximumValueLabel}</FormHelperText>
                    )}
                </div>
            )}
            {helperText && (
                <FormHelperText className={classes.helperText} error={error}>
                    {helperText}
                </FormHelperText>
            )}
        </div>
    )
}
