import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FilterBase } from 'types/models'

export interface EverySelectProps extends Omit<FormControlProps, 'onChange'> {
    value: number
    onChange: (value: number) => void
    repeatTypePeriod?: FilterBase<number>
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({ root: { marginBottom: theme.spacing(2) } }),
    { name: 'EverySelect' }
)

const labelMap: Record<number, string> = {
    0: 'Every day',
    1: 'Every week',
    2: 'Every month',
    3: 'Every year',
}

export const EverySelect = (props: EverySelectProps) => {
    const { className, value, repeatTypePeriod, onChange, ...other } = props
    const classes = useStyles()
    const values = useMemo(
        () => Array.from({ length: 30 }, (_, i) => i + 1),
        []
    )
    return (
        <FormControl
            className={clsx(classes.root, className)}
            fullWidth
            {...other}
        >
            <InputLabel>{labelMap[repeatTypePeriod?.code ?? 0]}</InputLabel>
            <Select
                value={value}
                onChange={(e) => onChange(e.target.value as number)}
                variant="filled"
            >
                {values.map((x) => (
                    <MenuItem key={x} value={x}>
                        {x}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
