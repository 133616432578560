import React from 'react'
import { AdminCustomer } from 'api'
import {
    CreateCustomerContactCommand,
    CustomerContact,
    UpdateCustomerContactCommand,
} from 'types/issueTypes'
import { CustomerContactsManagePageDialogModeType } from 'Admin'

export const CustomerContactsManagePageContext = React.createContext<{
    customer: AdminCustomer
    customerContact: CustomerContact
    customerTabSearchResults: AdminCustomer[]
    customerContactTabSearchResults: AdminCustomer[]
    updateCustomerTabSearchResults: (customers: AdminCustomer[]) => void
    updateCustomerContactTabSearchResults: (customers: AdminCustomer[]) => void
    openCustomerContactDialog: (
        dialogMode: CustomerContactsManagePageDialogModeType,
        options: {
            customerContact?: CustomerContact
            customer: AdminCustomer
        }
    ) => void
    addCustomerContact: (query: CreateCustomerContactCommand) => void
    updateCustomerContact: (
        customerContactId: string,
        customer: UpdateCustomerContactCommand
    ) => void
}>({
    customer: null,
    customerContact: null,
    customerTabSearchResults: [],
    customerContactTabSearchResults: [],
    updateCustomerTabSearchResults: null,
    updateCustomerContactTabSearchResults: null,
    openCustomerContactDialog: null,
    addCustomerContact: null,
    updateCustomerContact: null,
})
