import { all } from 'redux-saga/effects'
import { refreshMyIssue } from './refreshMyIssue'
import { extendTargetResolutionDate } from './extendTargetResolutionDate'
import { exportMyIssuesToExcel } from './exportMyIssuesToExcel'

export function* root() {
    yield all([
        refreshMyIssue(),
        extendTargetResolutionDate(),
        exportMyIssuesToExcel(),
    ])
}

export default root
