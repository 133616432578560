import { default as Signin } from './svg/Signin.svg'

export interface SigninSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const SigninSvg = (props: SigninSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? Signin}
            width={width}
            height={height}
            alt={alt ?? 'Signin'}
            {...other}
        />
    )
}

export default SigninSvg
