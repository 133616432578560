import { Pagination } from '@mui/lab'
import { NoResults, SearchResultsHeader } from 'components'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { IssueGlobalSearchResultItem } from 'types/models'
import { GlobalSearchItem } from '.'

export interface GlobalSearchResultsProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    searching: boolean
    page: number
    pageSize: number
    searchText: string
    items: IssueGlobalSearchResultItem[]
    totalSize: number
    onPageChange: (page: number) => void
}

export const GlobalSearchResults = (props: GlobalSearchResultsProps) => {
    const {
        searching,
        searchText,
        page,
        pageSize,
        items,
        totalSize,
        onPageChange,
    } = props
    const totalPages = useMemo(
        () => (totalSize <= pageSize ? 1 : Math.floor(totalSize / pageSize)),
        [totalSize]
    )
    return 0 < totalSize ? (
        <>
            <SearchResultsHeader
                isSearching={searching}
                itemCount={totalSize}
            />
            {items?.map((x) => (
                <GlobalSearchItem
                    key={x.issueId}
                    item={x}
                    searchText={searchText}
                />
            )) ?? null}
            {1 < totalPages && (
                <Pagination
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center',
                    }}
                    count={totalPages}
                    hidePrevButton={page === 1}
                    hideNextButton={page === totalPages}
                    onChange={(_, page) => onPageChange(page)}
                    page={page}
                    size="large"
                    color="secondary"
                />
            )}{' '}
        </>
    ) : items ? (
        <NoResults
            title="No results!"
            subTitle="Change search criteria and try again."
        />
    ) : null
}
