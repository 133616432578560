import { makeStyles } from '@mui/styles'
import { AdminNode } from 'components'
import { HtmlHTMLAttributes } from 'react'
import { dataTestId } from 'utils'
import { AdminTreeNode } from './AdminTreeNode'

const useCcrpTreeNodes = makeStyles(
    () => ({
        root: {
            listStyleType: 'none',
            'padding-inline-start': 0,
            margin: 0,
        },
    }),
    { name: 'TreeNodes' }
)

export interface RootTreeNodesProps
    extends HtmlHTMLAttributes<HTMLUListElement> {
    nodes: AdminNode[]
    level: number
    disabled?: boolean
    canEdit?: boolean
    canEditAllNodes?: boolean
}

export const AdminTreeNodes = (props: RootTreeNodesProps) => {
    const {
        nodes,
        level,
        canEdit = false,
        disabled = false,
        canEditAllNodes = false,
        ...other
    } = props
    const classes = useCcrpTreeNodes(props)
    return (
        <ul className={classes.root} {...other}>
            {nodes &&
                nodes.map((node) => (
                    <AdminTreeNode
                        disabled={disabled}
                        key={node.id}
                        node={node}
                        canEdit={canEdit}
                        level={level ? level + 1 : 1}
                        canEditAllNodes={canEditAllNodes}
                        {...dataTestId(
                            `TREE_NODE_CONTAINER_${node.name.toUpperCase()}`
                        )}
                    />
                ))}
        </ul>
    )
}
