import { AnyAction } from 'redux'
import * as ACTIONS from './actionTypes'

export const loadAvatar = (): AnyAction => ({
    type: ACTIONS.DASHBOARD_LOAD_AVATAR_CALL,
})

export const loadProfile = (): AnyAction => ({
    type: ACTIONS.DASHBOARD_LOAD_PROFILE_CALL,
})

export const openSearch = (open: boolean): AnyAction => ({
    type: ACTIONS.DASHBOARD_OPEN_SEARCH,
    payload: open,
})

export const downloadAnalzyer = (isUnitInternal: boolean): AnyAction => ({
    type: ACTIONS.DASHBOARD_DOWNLOAD_ANALYZER,
    payload: isUnitInternal,
})
