import { Collapse } from '@mui/material'
import {
    FormGridItem,
    GridContainer,
    KeyboardDatePicker,
    MultiEmployeeSelect,
    TextField,
} from 'components'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { Employee } from 'types/issueTypes'
import { PersonBase } from 'types/models'
import { ReportNotificationSettings } from 'types/reportTypes'
import {
    EverySelect,
    RepeatBySelect,
    RepeatInSelect,
    RepeatTypeSelect,
} from '.'
import { OtherEmailsSelect } from './OtherEmailsSelect'

export interface NotificationSettingsProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    notificationSettings: ReportNotificationSettings
    onSettingsChange: (settings: ReportNotificationSettings) => void
}

export const NotificationSettings = (props: NotificationSettingsProps) => {
    const { notificationSettings, onSettingsChange, ...other } = props
    const {
        subject,
        recipients,
        repeatType,
        startDate,
        repeatEvery,
        repeatBy,
        repeatDays,
    } = notificationSettings
    const [employees, otherPersons] = useMemo(
        () => [
            recipients
                ?.filter((e) => e && Boolean(e?.geid))
                ?.map<PersonBase>(({ geid, email }) => ({ geid, email })) ?? [],
            recipients
                ?.filter((e) => e && !Boolean(e?.geid))
                ?.map<PersonBase>(({ geid, email }) => ({ geid, email })) ?? [],
        ],
        [recipients]
    )
    const handleEmployeesChange = (employees: Employee[]) => {
        const persons = employees.map(({ geid, email }) => ({ geid, email }))
        onSettingsChange({
            ...notificationSettings,
            recipients: [...persons, ...otherPersons],
        })
    }
    const handleOtherPersonChange = (otherPersons: Employee[]) => {
        const persons = otherPersons.map(({ geid, email }) => ({ geid, email }))
        onSettingsChange({
            ...notificationSettings,
            recipients: [...persons, ...employees],
        })
    }
    return (
        <div {...other}>
            <MultiEmployeeSelect
                label="To employees"
                employees={employees}
                onEmployeesChanged={handleEmployeesChange}
            />
            <OtherEmailsSelect
                label="To other"
                employees={otherPersons}
                onEmployeesChanged={handleOtherPersonChange}
            />
            <TextField
                label="Subject"
                value={subject}
                onChange={(e) =>
                    onSettingsChange({
                        ...notificationSettings,
                        subject: e.target.value,
                    })
                }
            />
            <GridContainer>
                <FormGridItem>
                    <RepeatTypeSelect
                        value={repeatType}
                        onChange={(repeatType) =>
                            onSettingsChange({
                                ...notificationSettings,
                                repeatType,
                            })
                        }
                    />
                </FormGridItem>
                <FormGridItem>
                    <EverySelect
                        value={repeatEvery}
                        onChange={(repeatEvery) =>
                            onSettingsChange({
                                ...notificationSettings,
                                repeatEvery,
                            })
                        }
                        repeatTypePeriod={repeatType}
                    />
                </FormGridItem>
            </GridContainer>
            <Collapse in={repeatType?.code === 2 || repeatType?.code === 1}>
                {repeatType?.code === 1 && (
                    <RepeatInSelect
                        value={repeatDays}
                        onChange={(repeatDays) =>
                            onSettingsChange({
                                ...notificationSettings,
                                repeatDays,
                            })
                        }
                    />
                )}
                {repeatType?.code === 2 && (
                    <RepeatBySelect
                        value={repeatBy}
                        onChange={(repeatBy) =>
                            onSettingsChange({
                                ...notificationSettings,
                                repeatBy,
                            })
                        }
                    />
                )}
            </Collapse>
            <KeyboardDatePicker
                label="Start day"
                minDate={dayjs().utc()}
                value={startDate ? dayjs(startDate).utc() : dayjs().utc()}
                onChange={(date) =>
                    onSettingsChange({
                        ...notificationSettings,
                        startDate: date.toISOString(),
                    })
                }
            />
        </div>
    )
}
