import { SupervisorAccountOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
    AdminEmployeeChipWithProtection,
    AdminEmployeesChipsSimple,
    ProcessOwnerInfo,
} from 'Admin/components'
import { AdminEmployeeLabel } from 'Admin/components/AdminEmployeesChips/AdminEmployeeLabel'
import { AdminTileContainer } from 'Admin/components/AdminTileContainer'
import { ProcessOwnerActions } from 'Admin/components/ProcessOwnerActions'
import CardInformation from 'Admin/ResolvingUnitsManagePage/components/NewResolvingUnitsPageCard/CardInformation'
import { ChipLabel } from 'components'
import { useProcessOwnerChecker } from 'hooks'
import { useMemo, useState } from 'react'
import { AdminHubInfo } from 'types/adminTypes'
import { IssueRoles } from 'types/enums'
import { Employee } from 'types/issueTypes'
import { UserProfile } from 'types/profile'
import { dataTestId } from 'utils'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        border: {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        users: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(1, 1.5),
        },
        newConcept: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        employee: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: theme.spacing(0, 1),
        },
        userConcept: {
            margin: theme.spacing(0.5, 0.5),
        },
    }),
    {
        name: 'HubsManagePageCard',
    }
)

export interface HubsManagePageCardProps {
    hub: AdminHubInfo
    profile: UserProfile
    onDeleteProcessOwner: (processOwner: Employee, hub: AdminHubInfo) => void
    selected: boolean
    onSelect: (hubId: number, select: boolean) => void
    onAddHubProcessOwner: (hubId: number) => void
}

export const HubsManagePageCard = (props: HubsManagePageCardProps) => {
    const classes = useStyles()
    const {
        hub,
        profile,
        onDeleteProcessOwner,
        onAddHubProcessOwner,
        selected,
        onSelect,
    } = props
    const {
        hubProcessOwners,
        name,
        id,
        code,
        isActive,
        productGroupCode,
        businessUnitCode,
        divisionCode,
        leadCountryCode,
    } = hub
    const [showProcessOwners, setShowProcessOwners] = useState(false)
    const [checkProcessOwner] = useProcessOwnerChecker()
    const [canDeleteLastHubProcessOwner, disabled] = useMemo<
        [boolean, boolean]
    >(() => {
        const canDeleteLastHubProcessOwner =
            profile.roles.some((r) => r.roleId === IssueRoles.Support_Desk) ||
            (hubProcessOwners?.length ?? 0) !== 1
        const disabled = !checkProcessOwner(
            {
                division: { code: divisionCode, name: '' },
                businessUnit: { code: businessUnitCode, name: '' },
                productGroup: { code: productGroupCode, name: '' },
                country: null,
                region: null,
                abacusCode: null,
                genericCode: null,
            },
            false
        )

        return [canDeleteLastHubProcessOwner, disabled]
    }, [profile, hubProcessOwners, divisionCode, businessUnitCode])
    const productGroupCodeAppendix = productGroupCode
        ? `(PG:${productGroupCode})`
        : ''
    return (
        <AdminTileContainer
            {...dataTestId(`HUBS_CONTAINER_${code}`)}
            checked={selected}
            disabled={disabled}
            headerLabel={`Hub code: ${code}`}
            onChangeChecked={() => onSelect(id, !selected)}
            renderTags={() => (
                <>
                    <ChipLabel
                        variant={isActive ? 'green' : 'red'}
                        label={isActive ? 'Active' : 'Inactive'}
                    />
                </>
            )}
            headerContent={
                <CardInformation
                    informationResult={[
                        {
                            label: 'Hub name',
                            text: `${name}, ${leadCountryCode} ${productGroupCodeAppendix}`,
                        },
                    ]}
                />
            }
            mainContent={
                Boolean(hubProcessOwners?.length) ? (
                    <>
                        <div className={classes.users}>
                            <AdminEmployeesChipsSimple
                                newConceptRender={(props) => (
                                    <div className={classes.newConcept}>
                                        {props}
                                    </div>
                                )}
                            >
                                <div className={classes.employee}>
                                    <AdminEmployeeLabel
                                        name="Hub process owners"
                                        icon={() => (
                                            <SupervisorAccountOutlined
                                                className={classes.icon}
                                            />
                                        )}
                                    />

                                    {hubProcessOwners?.map((employee) => (
                                        <AdminEmployeeChipWithProtection
                                            key={employee?.geid}
                                            disabled={disabled}
                                            isLast={
                                                !canDeleteLastHubProcessOwner
                                            }
                                            isLastMessage="Cannot delete the only Hub Process Owner"
                                            label={
                                                employee?.fullName ??
                                                employee?.email
                                            }
                                            employee={{ ...employee }}
                                            onDelete={() =>
                                                onDeleteProcessOwner(
                                                    employee,
                                                    hub
                                                )
                                            }
                                            {...dataTestId(`HUB_PROCESS_OWNER`)}
                                        />
                                    ))}
                                </div>
                            </AdminEmployeesChipsSimple>
                        </div>
                        <div className={classes.border} />
                    </>
                ) : null
            }
            bottomContent={
                <ProcessOwnerInfo
                    newConcept={true}
                    divisionCode={divisionCode}
                    businessUnitCode={businessUnitCode}
                    productGroupCode={productGroupCode}
                    countryCode={leadCountryCode}
                    showProcessOwners={showProcessOwners}
                />
            }
            actions={
                <ProcessOwnerActions
                    addPerson={() => onAddHubProcessOwner(id)}
                    canEdit={!disabled}
                    isActive={isActive}
                    setShowProcessOwners={(value) => {
                        setShowProcessOwners(value)
                    }}
                    showProcessOwners={showProcessOwners}
                    showTooltipText={
                        'HUB Process owners assigned to HUB as well as all proces owners listed under "Show process owners" button are allowed to manage this specific HUB.'
                    }
                />
            }
        />
    )
}
