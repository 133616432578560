import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { forwardRef, HtmlHTMLAttributes, ReactNode } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId } from 'utils'
import { Label } from '.'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(1),
        },
        value: {
            display: 'inline-flex',
            color: theme.palette.grey[700],
            textAlign: 'justify',
            lineHeight: 1.2,
        },
        singleLine: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    }),
    { name: 'ValueView' }
)

export interface ValueViewProps extends HtmlHTMLAttributes<HTMLDivElement> {
    icon?: ReactNode
    label: string | ReactNode
    value: string | ReactNode
    singleLine?: boolean
}

export const ValueView = forwardRef<HTMLDivElement, ValueViewProps>(
    (props, ref) => {
        const classes = useStyles()
        const {
            className,
            icon,
            label,
            value,
            singleLine = true,
            ...other
        } = props
        return (
            <div ref={ref} {...other} className={clsx(className, classes.root)}>
                <Label {...dataTestId('DATE_VIEW_LABEL')}>
                    {icon}
                    {label}
                </Label>
                {value && (
                    <div
                        className={clsx(
                            classes.value,
                            singleLine && classes.singleLine
                        )}
                        {...dataTestId('VALUE_VIEW_VALUE')}
                    >
                        {value ?? '...'}
                    </div>
                )}
            </div>
        )
    }
)

ValueView.defaultProps = {
    singleLine: true,
}

export default ValueView
