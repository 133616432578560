import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { confirmHIIssue } from 'api'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { confirmHIDone, confirmHIFail, removeMyActionById } from '../actions'
import { MY_ACTIONS_CONFIRM_HI_CALL } from '../actionTypes'
import { ConfirmHighImpactCommand } from '../types'

export function* confirmHighImpactSaga() {
    yield takeLatest(
        MY_ACTIONS_CONFIRM_HI_CALL,
        function* (action: ConfirmHighImpactCommand) {
            const cancelSource = axios.CancelToken.source()
            try {
                yield call(confirmHIIssue, action.payload, cancelSource.token)
                yield put(confirmHIDone())
                yield put(showSnackbar('High impact was confirmed'))
                yield put(
                    removeMyActionById(
                        action.payload.issueId,
                        IssueActionsTypes.To_Verify_HI
                    )
                )
            } catch (error) {
                yield put(confirmHIFail(error))
                yield put(showSnackbar("High impact wasn't confirmed!", true))
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
