import { ErrorOutline, ExpandMore as ExpandIcon } from '@mui/icons-material'
import {
    Checkbox,
    CircularProgress,
    Collapse,
    FormLabel,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material'
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    EmployeeSelect,
    FlexGrow,
    FormControlLabel,
    HelpTip,
    MultiEmployeeSelect,
    PickerTextField,
    Radio,
} from 'components'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import {
    useFieldMatrix,
    useFieldWithError,
    useHasActionInContext,
    useIssueAction,
} from 'Issue/matrix'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import {
    ActionsKeys,
    IssueActionsTypes,
    IssueTypes,
    IssueValuesKeys,
    QuickFixVariants,
} from 'types/enums'
import { AssignSection, Employee } from 'types/issueTypes'
import { FilterBase } from 'types/models'
import useIssueFormStyles from './useIssueFormStyles'
import { ASSIGNMENT_SECTION_ID } from './utils'

export interface AssignmentProps
    extends Partial<{
        expanded: boolean
        disabled: boolean
        issueType: IssueTypes
        onAssign: () => void
        onAssignSectionChanged: (key: keyof AssignSection, value: any) => void
        onQuickFixVariantChanged: (quickFixVariant: QuickFixVariants) => void
        onLinkIssueClick: () => void
        onRemoveLink: () => void
        onReturnToIssueOwner: () => void
        onSaveClick: () => void
        onExpand: (expanded: boolean) => void
        saving: boolean
        assignSection: AssignSection
        isRootCauseRequiredByCustomer: boolean
        isHighImpact: boolean
        [key: string]: any
    }> {}

const Assignment = (props: AssignmentProps) => {
    const classes = useIssueFormStyles()
    const theme = useTheme()
    const quickFixVariants = useSelector<RootState, Array<FilterBase<number>>>(
        (store) => store.resources.quickFixVariant
    )

    const {
        assignSection,
        issueType,
        saving,
        disabled,
        expanded,
        onExpand,
        onLinkIssueClick,
        onAssign,
        onSaveClick,
        onRemoveLink,
        onReturnToIssueOwner,
        onAssignSectionChanged,
        onQuickFixVariantChanged,
        isRootCauseRequiredByCustomer,
        isHighImpact,
    } = props
    const hasToReplaceInactiveRO = useHasActionInContext(
        IssueActionsTypes.To_Replace_Inactive_RO
    )
    const quickFixVariantRadioGroup = useFieldWithError(
        IssueValuesKeys.QuickFixVariant,
        { disabled }
    )
    const quickFixIssueField = useFieldWithError(
        IssueValuesKeys.QuickFixIssueId,
        { disabled }
    )
    const primaryResolutionOwnerField = useFieldWithError(
        IssueValuesKeys.PrimaryResolutionOwner,
        {
            disabled,
            defaultError: hasToReplaceInactiveRO,
        }
    )
    const primaryActiveResolutionOwnerField = useFieldWithError(
        IssueValuesKeys.PrimaryResolutionOwnerActive,
        {
            disabled,
            defaultError: hasToReplaceInactiveRO,
        }
    )
    const resolutionOwnerTeamField = useFieldWithError(
        IssueValuesKeys.ResolutionOwnerTeam,
        { disabled }
    )
    const solutionWillBeValidatedByCoordinatorCheckbox = useFieldMatrix(
        IssueValuesKeys.SolutionWillBeValidatedByCoordinator,
        { disabled }
    )
    const assignAction = useIssueAction(ActionsKeys.Assign)
    const saveAssignAction = useIssueAction(ActionsKeys.SaveAssignSection)
    if (assignSection === null) {
        return null
    }
    const rootCauseAnalysisRequired =
        isRootCauseRequiredByCustomer || isHighImpact
    const onTryAssign = () => {
        onAssign && onAssign()
    }
    const handleDeleteLinkedIssue = () => {
        if (!disabled) {
            onRemoveLink && onRemoveLink()
        }
    }
    const handleQuickFixVariantChange = (quickFixVariant: QuickFixVariants) => {
        onQuickFixVariantChanged && onQuickFixVariantChanged(quickFixVariant)
    }
    const handleAssignSectionChange = (key: keyof AssignSection, value: any) =>
        onAssignSectionChanged && onAssignSectionChanged(key, value)
    const handleSaveClick = () => {
        onSaveClick && onSaveClick()
    }
    const primaryResolutionOwner = assignSection?.primaryResolutionOwner
    return assignSection ? (
        <Accordion
            id={ASSIGNMENT_SECTION_ID}
            className={classes.section}
            expanded={expanded}
            onChange={(event, value) => onExpand && onExpand(value)}
        >
            <AccordionSummary
                {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT.EXPANSION_PANEL}
                expandIcon={<ExpandIcon />}
            >
                <Typography variant="h5">Issue assignment</Typography>
            </AccordionSummary>
            <AccordionDetails
                {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT.DETAILS_CONTAINER}
            >
                <EmployeeSelect
                    {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                        .RESOLUTION_OWNER_SELECT}
                    label="Resolution Owner"
                    fullWidth
                    {...primaryResolutionOwnerField}
                    {...(!primaryResolutionOwnerField.error &&
                        primaryActiveResolutionOwnerField.error &&
                        primaryActiveResolutionOwnerField)}
                    employee={primaryResolutionOwner}
                    onEmployeeChanged={(employee) => {
                        handleAssignSectionChange(
                            'primaryResolutionOwner',
                            employee?.email ?? ''
                        )
                        handleAssignSectionChange(
                            'primaryActiveResolutionOwner',
                            employee?.email ?? ''
                        )
                        handleAssignSectionChange(
                            'primaryResolutionOwner',
                            employee
                        )
                        handleAssignSectionChange(
                            'primaryActiveResolutionOwner',
                            employee
                        )
                    }}
                    startAdornment={
                        primaryResolutionOwner &&
                        !primaryResolutionOwner.isActive && (
                            <HelpTip
                                icon={
                                    <ErrorOutline
                                        fontSize="small"
                                        style={{
                                            color: theme.palette.secondary.main,
                                        }}
                                    />
                                }
                                size="small"
                                title="Resolution Owner isn't active!"
                            />
                        )
                    }
                />
                <MultiEmployeeSelect
                    {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                        .RESOLUTION_OWNER_TEAM_SELECT}
                    label="Resolution Owner Team"
                    fullWidth
                    employees={assignSection?.resolutionOwnerTeam ?? []}
                    {...resolutionOwnerTeamField}
                    onEmployeesChanged={(employees: Array<Employee>) => {
                        handleAssignSectionChange('resolutionOwnerTeam', [
                            ...employees,
                        ])
                    }}
                />
                <FormLabel>RCA</FormLabel>
                <RadioGroup
                    {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT.RCA_RADIO_GROUP}
                    value={assignSection.quickFixVariant.toString()}
                    onChange={(e, quickFixVariant) => {
                        handleQuickFixVariantChange(
                            Number(quickFixVariant) as QuickFixVariants
                        )
                    }}
                >
                    <FormControlLabel
                        value={quickFixVariants[
                            QuickFixVariants.Create_RCA
                        ].code.toString()}
                        disabled={quickFixVariantRadioGroup.disabled}
                        label={
                            quickFixVariants[QuickFixVariants.Create_RCA].name
                        }
                        control={<Radio />}
                    />
                    <FormControlLabel
                        value={quickFixVariants[
                            QuickFixVariants.Link_RCA
                        ].code.toString()}
                        label={quickFixVariants[QuickFixVariants.Link_RCA].name}
                        disabled={quickFixVariantRadioGroup.disabled}
                        control={<Radio />}
                    />
                    <Collapse
                        in={
                            assignSection.quickFixVariant ===
                            QuickFixVariants.Link_RCA
                        }
                    >
                        <PickerTextField
                            {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                                .LINKED_ISSUE_LABEL}
                            label="Linked issue"
                            fullWidth
                            value={
                                assignSection?.quickFixIssueNumber
                                    ? `Linked to ${assignSection.quickFixIssueNumber}`
                                    : ''
                            }
                            {...quickFixIssueField}
                            onClear={handleDeleteLinkedIssue}
                            onSelect={onLinkIssueClick}
                        />
                    </Collapse>
                    {issueType !== IssueTypes.Internal_NC && (
                        <FormControlLabel
                            value={quickFixVariants[
                                QuickFixVariants.No_RCA
                            ].code.toString()}
                            label={
                                <>
                                    {
                                        quickFixVariants[
                                            QuickFixVariants.No_RCA
                                        ].name
                                    }
                                    {rootCauseAnalysisRequired && (
                                        <HelpTip
                                            {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                                                .RCA_NOT_REQUIRED_HELP_BUTTON}
                                            title={
                                                'RCA not required field is disabled when issue is high impact or RCA is required by Customer.'
                                            }
                                        />
                                    )}
                                </>
                            }
                            className={classes.lastItem}
                            control={
                                <Radio
                                    {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                                        .RCA_REQUIRED_RADIO_BUTTON}
                                    disabled={
                                        quickFixVariantRadioGroup.disabled ||
                                        rootCauseAnalysisRequired
                                    }
                                />
                            }
                        />
                    )}
                </RadioGroup>

                <FormControlLabel
                    label={
                        'Before the solution can be sent to the Issue Owner/Customer it must be verified by one of the below'
                    }
                    {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                        .MUST_BE_VERIFIED_CHECKBOX}
                    control={
                        <Checkbox
                            checked={
                                assignSection.solutionWillBeValidatedByCoordinator ||
                                rootCauseAnalysisRequired
                            }
                            className={classes.sendCopyCheckbox}
                            disabled={
                                solutionWillBeValidatedByCoordinatorCheckbox.disabled ||
                                rootCauseAnalysisRequired
                            }
                            value="sendCopy"
                            onChange={(e, sendCopyTo) => {
                                handleAssignSectionChange(
                                    'solutionWillBeValidatedByCoordinator',
                                    Boolean(sendCopyTo)
                                )
                            }}
                        />
                    }
                />
                <Collapse
                    in={
                        assignSection.solutionWillBeValidatedByCoordinator ||
                        rootCauseAnalysisRequired
                    }
                >
                    <MultiEmployeeSelect
                        {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                            .SHOULD_BE_VERIFIED_BY_SELECT}
                        label="Solution should be verified by"
                        className={classes.sendCopySelection}
                        disabled
                        fullWidth
                        employees={
                            assignSection?.processOwnersVerificationTeam ?? []
                        }
                    />
                </Collapse>
            </AccordionDetails>
            {disabled ? null : (
                <AccordionActions style={{ marginTop: theme.spacing(3) }}>
                    {saving ? (
                        <CircularProgress
                            color="secondary"
                            size={24}
                            style={{ marginLeft: 8 }}
                        />
                    ) : saveAssignAction.disabled ? null : (
                        <Button
                            onClick={handleSaveClick}
                            {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT.ACTION_BUTTONS
                                .SAVE}
                        >
                            Save
                        </Button>
                    )}
                    <FlexGrow />
                    {assignAction.disabled ? null : (
                        <>
                            <Button
                                onClick={onReturnToIssueOwner}
                                {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                                    .ACTION_BUTTONS.RETURN_TO_ISSUE_OWNER}
                                eventName="Return to Issue Owner"
                            >
                                Return to Issue Owner
                            </Button>
                            <Button
                                color="secondary"
                                {...ISSUE_VIEW_PAGE.ISSUE_ASSIGNMENT
                                    .ACTION_BUTTONS.ASSIGN}
                                onClick={onTryAssign}
                                eventName="Assign"
                            >
                                Assign
                            </Button>
                        </>
                    )}
                </AccordionActions>
            )}
        </Accordion>
    ) : null
}

Assignment.defaultProps = {
    issueType: IssueTypes.External,
    saving: false,
    expanded: false,
    disabled: false,
    isRootCauseRequiredByCustomer: false,
    isHighImpact: false,
}

export default Assignment
