import AssignmentLate from '@mui/icons-material/AssignmentLate'
import Healing from '@mui/icons-material/Healing'
import Subject from '@mui/icons-material/Subject'
import { getAttachments } from 'api/attachments'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { AttachmentTypes, IssueActionsTypes } from 'types/enums'
import { Attachment } from 'types/issueTypes'
import Button from '../../components/Base/Button'
import ValueView from '../../components/Base/ValueView'
import DialogBase from '../../components/Dialogs/DialogBase'
import ReturnToResolutionOwnerDialog from '../../components/Dialogs/ReturnToResolutionOwnerDialog'
import AttachmentsView from '../../components/Files/AttachmentsView'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { rejectResolution, verifyResolution } from '../../store/myActions/index'
import { RejectResolutionQuery } from '../../types/actions'
import { MyAction } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import IssueInfoBaseRow from './IssueInfoBaseRow'
import { useTilesStyles } from './tilesStyles'

export interface ToVerifyResolutionState
    extends Readonly<{
        returnOpen: boolean
        verifyOpen: boolean
        expanded: boolean
        loading: boolean
        attachments: Array<Attachment>
        error?: string
    }> {}

export interface ToVerifyResolutionProps {
    actionIssue: MyAction
}

export const ToVerifyResolution = (props: ToVerifyResolutionProps) => {
    const { actionIssue, ...other } = props
    const { action } = actionIssue

    const dispatch = useDispatch()
    const classes = useTilesStyles(props)
    const [state, setState] = useState<ToVerifyResolutionState>({
        returnOpen: false,
        verifyOpen: false,
        expanded: false,
        loading: true,
        attachments: [],
    })
    const handleVerifyResolution = () => {
        setState((prev) => ({ ...prev, verifyOpen: false }))
        dispatch(verifyResolution(actionIssue.issueId))
    }
    const handleExpanded = () => {
        setState((prev) => ({
            ...prev,
            expanded: !prev.expanded,
        }))
        if (state.attachments && state.attachments.length === 0) {
            loadAttachments()
        }
    }
    const loadAttachments = async () => {
        try {
            setState((prev) => ({
                ...prev,
                loading: true,
                error: null,
            }))
            const { data } = await getAttachments(
                actionIssue.issueId,
                AttachmentTypes.Resolution_Rca
            )
            setState((prev) => ({
                ...prev,
                loading: false,
                attachments: [...data],
            }))
        } catch (err) {
            setState((prev) => ({
                ...prev,
                loading: false,
                attachments: [],
                error: 'Cannot load attachments',
            }))
        }
    }
    const handleRejectResolution = (formData: RejectResolutionQuery) => {
        setState((prev) => ({ ...prev, returnOpen: false }))
        dispatch(
            rejectResolution(formData, IssueActionsTypes.To_Verify_Resolution)
        )
    }

    let actions = (
        <>
            <Button
                onClick={() =>
                    setState((prev) => ({ ...prev, returnOpen: true }))
                }
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .RETURN_TO_RESOLUTION_OWNER}
                event="Return to Resolution Owner on dashboard"
            >
                Return to Resolution Owner
            </Button>
            <Button
                color="secondary"
                onClick={() =>
                    setState((prev) => ({ ...prev, verifyOpen: true }))
                }
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.VERIFY}
                event="Verify Resolution on dashboard"
            >
                Verify
            </Button>
        </>
    )
    return (
        <>
            <ExpandTile
                issueNumber={actionIssue.issueNumber}
                subject={actionIssue.subject}
                issueStatusId={actionIssue.issueStatusId}
                isOverdue={action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                showActionStatus={true}
                actionCode={action.actionCode}
                {...other}
                issueId={actionIssue.issueId}
                collapsedSize="100px"
                actions={actions}
                expanded={state.expanded}
                onExpand={handleExpanded}
            >
                <IssueInfoBaseRow
                    deadlineDate={actionIssue?.action?.deadlineDate}
                    issueType={actionIssue.issueType}
                />
                {actionIssue.whatWasTheRootCause && (
                    <ValueView
                        className={classes.singleRow}
                        icon={<AssignmentLate className={classes.labelIcon} />}
                        label="Root cause of the issue"
                        singleLine={false}
                        value={actionIssue.whatWasTheRootCause}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.ROOT_CAUSE}
                    />
                )}
                {actionIssue.howDidWeResolveThisIssue && (
                    <ValueView
                        className={classes.singleRow}
                        icon={<Subject className={classes.labelIcon} />}
                        label="How did we resolve this issue?"
                        singleLine={false}
                        value={actionIssue.howDidWeResolveThisIssue}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                            .HOW_DID_WE_RESOLVE}
                    />
                )}
                {actionIssue.whatContainmentActionsWereTaken && (
                    <ValueView
                        className={classes.singleRow}
                        icon={<Healing className={classes.labelIcon} />}
                        label="Containment actions"
                        singleLine={false}
                        value={actionIssue.whatContainmentActionsWereTaken}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                            .CONTAINMENT_ACTIONS}
                    />
                )}
                <div className={classes.singleRow}>
                    <AttachmentsView
                        loading={state.loading}
                        label="RCA Attachments"
                        error={Boolean(state?.error)}
                        helperText={state?.error}
                        attachments={state.attachments.filter(
                            (a) =>
                                a.attachmentType ===
                                AttachmentTypes.Resolution_Rca
                        )}
                        disabled
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                            .RCA_ATTACHMENTS}
                    />
                </div>
            </ExpandTile>
            <ReturnToResolutionOwnerDialog
                open={state.returnOpen}
                message="You are about to return this issue to Resolution Owner. You have to provide the reason why you're doing this."
                number={actionIssue.issueNumber}
                issueId={actionIssue.issueId}
                rowVersion={actionIssue.rowVersion}
                onReturnToRO={handleRejectResolution}
                onClose={() =>
                    setState((prev) => ({ ...prev, returnOpen: false }))
                }
            />
            <DialogBase
                title="Verify resolution"
                message={`
                    You are about to verify resolution for issue ${actionIssue.issueNumber}. Proceed?`}
                confirmLabel="Verify"
                closeLabel="Cancel"
                onConfirm={handleVerifyResolution}
                onClose={() =>
                    setState((prev) => ({ ...prev, verifyOpen: false }))
                }
                open={state.verifyOpen}
            />
        </>
    )
}

export default ToVerifyResolution
