import {
    StyledEngineProvider,
    Theme,
    ThemeProvider,
} from '@mui/material/styles'
import { createBrowserHistory, History } from 'history'
import MainApp from 'MainApp'
import { useEffect } from 'react'
import { Route, Router, Switch } from 'react-router'
import theme from 'styles/themeBase'
import { SurveyApp } from 'SurveyApp'
import {
    CUSTOMER_SUBSCRIPTIONS,
    CUSTOMER_SUBSCRIPTIONS_LANG,
    CUSTOMER_SURVEY,
    CUSTOMER_SURVEY_EFFORT_SCORE,
    CUSTOMER_SURVEY_EFFORT_SCORE_LANG,
    CUSTOMER_SURVEY_LANG,
    CUSTOMER_UNSUBSCRIBE,
} from './routes'

declare module '@mui/styles' {
    interface DefaultTheme extends Theme {}
}

export const history: History = createBrowserHistory()

export const App = () => {
    const redirect = () => {
        if (window.location.href.includes('localtest'))
            return window.location.href
        if (window.location.href.includes('-light'))
            return window.location.replace(
                window.location.href.split('-light').join('')
            )
    }

    useEffect(() => {
        redirect()
    })

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Router history={history}>
                    <Switch>
                        <Route
                            path={[
                                CUSTOMER_SURVEY,
                                CUSTOMER_SURVEY_LANG,
                                CUSTOMER_SURVEY_EFFORT_SCORE,
                                CUSTOMER_SURVEY_EFFORT_SCORE_LANG,
                                CUSTOMER_UNSUBSCRIBE,
                                CUSTOMER_SUBSCRIPTIONS_LANG,
                                CUSTOMER_SUBSCRIPTIONS,
                            ]}
                            component={SurveyApp}
                            exact
                        />
                        <Route component={MainApp} />
                    </Switch>
                </Router>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
