import { useLayoutEffect } from 'react'

export const ScrollService = () => {
    useLayoutEffect(() => {
        const hash = window.location.hash
        if (hash) {
            const sectionId = hash.replace('#', '')
            const element = document.getElementById(sectionId)
            if (element) {
                setTimeout(() => {
                    element.scrollIntoView({
                        behavior: 'smooth',
                        block: 'start',
                    })
                }, 1600)

                window.location.hash = ''
            }
        }
    }, [])
    return null
}
