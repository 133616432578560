import { OpenInNew } from '@mui/icons-material'
import { MenuItem } from '@mui/material'
import clsx from 'clsx'
import { NavMenuItemProps, useNavMenuItemStyles } from '.'

export const LinkMenuItem = (props: NavMenuItemProps) => {
    const { className, selected, to, children, eventName, ...other } = props
    const classes = useNavMenuItemStyles(props)
    return (
        <MenuItem
            selected={selected}
            classes={{
                gutters: classes.gutters,
            }}
            className={clsx(className, classes.root, classes.gutters)}
            {...other}
        >
            <a
                className={clsx(classes.link)}
                href={to}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children} <OpenInNew className={classes.icon} />
            </a>
        </MenuItem>
    )
}

export default LinkMenuItem
