import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Fragment } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    TimelineConnector,
    TimelineDot,
    TimelineOrientation,
    TimelineState,
} from '.'
export interface TimelineBarProps extends React.HTMLProps<HTMLDivElement> {
    steps: number
    currentStep: number
    radius?: number
    orientation?: TimelineOrientation
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: (props: TimelineBarProps) =>
                props.orientation === 'horizontal' ? 'row' : 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    { name: 'TimelineBar' }
)

export const TimelineBar = (props: TimelineBarProps) => {
    const {
        className: classNameProp,
        children,
        radius = 14,
        orientation = 'horizontal',
        steps,
        currentStep,
        ...other
    } = props
    const classes = useStyles(props)
    const stepsMap: Array<TimelineState> = new Array(steps)
        .fill(0)
        .map((_, i) => i + 1)
        .map((step) => {
            switch (true) {
                case step < currentStep:
                    return 'done'
                case step === currentStep:
                    return 'inprocess'
                default:
                    return 'waiting'
            }
        })
    return (
        <div className={clsx(classNameProp, classes.root)} {...other}>
            {stepsMap.map((s, index, arr) => {
                return (
                    <Fragment key={index}>
                        <TimelineDot radius={radius} state={s} />
                        {index < arr.length - 1 && (
                            <TimelineConnector
                                key={`connector-${index}`}
                                orientation={orientation}
                                state={arr[index + 1]}
                            />
                        )}
                    </Fragment>
                )
            })}
        </div>
    )
}

export default TimelineBar
