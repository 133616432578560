import Autocomplete from '@mui/material/Autocomplete'
import { AdminDialog, DialogModeType } from 'Admin'
import {
    BusinessUnitSelect,
    BusinessUnitSelectProps,
    Button,
    CountrySelect,
    CountrySelectProps,
    DivisionSelect,
    DivisionSelectProps,
    EmployeeSelect,
    EmployeeSelectProps,
    FormGridItem,
    GridContainer,
    ProductGroupSelect,
    ProductGroupSelectProps,
    RegionSelect,
    RegionSelectProps,
    TextField,
} from 'components'
import MessageItem from 'components/Base/MessageItem'
import { createElement, useEffect, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import { Employee } from 'types/issueTypes'
import { FilterBase } from 'types/models'
import { AdminProcessOwnerAddQuery } from 'types/queries'
import { dataTestId } from 'utils'
import {
    ProcessOwnersDialogFields,
    ProcessOwnersDialogForInitQuery,
    ProcessOwnersManagePageDialogProps,
} from './types'
import { getInitQueryForDialog, getOptionsAndFields } from './utils'

const { options, fields } = getOptionsAndFields()

export const ProcessOwnersManagePageDialog = (
    props: ProcessOwnersManagePageDialogProps
) => {
    const { dialogMode, onClose, onAddProcessOwners, organizationalInfo } =
        props
    const [regionalError, setRegionalError] = useState<'regionalError' | null>(
        null
    )
    const [query, setQuery] = useState<ProcessOwnersDialogForInitQuery>(
        getInitQueryForDialog()
    )
    const [option, setOption] = useState(options[0])

    useEffect(() => {
        if (dialogMode === 'all') {
            setQuery(getInitQueryForDialog())
            setOption(options.find((item) => item.mode === 'all'))
        } else if (dialogMode) {
            setQuery({
                ...getInitQueryForDialog(),
                businessArea: organizationalInfo.division,
                division: organizationalInfo.businessUnit,
                productGroup: organizationalInfo.productGroup,
            })
            setOption(options.find((item) => item.mode === dialogMode))
        }
    }, [dialogMode, organizationalInfo])

    useUpdateEffect(() => {
        if (dialogMode !== option.mode) setQuery(getInitQueryForDialog())
    }, [option])

    const addProcessOwner = async () => {
        const regionalDialogTypes: DialogModeType[] = [
            'businessAreaAndRegionOrCountry',
            'businessUnitAndRegionOrCountry',
            'productGroupAndRegionOrCountry',
        ]
        if (regionalDialogTypes.includes(option.mode)) {
            if (!query.country?.code && !query.region?.code) {
                setRegionalError('regionalError')
                return
            }
        }
        const parseQuery: AdminProcessOwnerAddQuery = {
            divisionCode: query.businessArea?.code,
            businessUnitCode: query.division?.code,
            productGroupCode: query.productGroup?.code,
            regionCode: query.region?.code,
            countryCode: query.country?.code,
            geid: query.employee?.geid,
        }

        onAddProcessOwners(parseQuery)
    }

    const renderActions = () => (
        <>
            <Button onClick={onClose} {...dataTestId('PO_CLOSE_BUTTON')}>
                Close
            </Button>
            <Button
                onClick={addProcessOwner}
                color="secondary"
                {...dataTestId('PO_ADD_BUTTON')}
                disabled={
                    !query.employee ||
                    !Boolean(
                        query?.businessArea ||
                            query?.division ||
                            query?.productGroup
                    )
                }
            >
                Add
            </Button>
        </>
    )

    const handleChange =
        (fieldName: keyof ProcessOwnersDialogFields) =>
        (newValue: FilterBase<string> & Employee) =>
            setQuery((prev) => {
                const newState = { ...prev }
                if (fieldName === 'country' || fieldName === 'region') {
                    setRegionalError(null)
                    newState[fieldName === 'country' ? 'region' : 'country'] =
                        null
                }

                newState[fieldName] = newValue

                return newState
            })

    const isVisible = (fieldName: keyof ProcessOwnersDialogFields) => {
        return option.fields.includes(fields[fieldName])
    }

    const renderGridItem = <T extends {}>(
        fieldName: keyof ProcessOwnersDialogFields,
        item: any,
        options: T = null,
        fullWidth: boolean = true
    ) => {
        return (
            isVisible(fieldName) && (
                <FormGridItem fullWidth={fullWidth}>
                    {createElement(item, {
                        ...options,
                    })}
                </FormGridItem>
            )
        )
    }

    const isAllMode = option.mode === 'all'
    return (
        <AdminDialog
            title="Add process owners"
            open={Boolean(dialogMode)}
            onClose={onClose}
            renderActions={renderActions}
        >
            <GridContainer>
                {regionalError && (
                    <FormGridItem fullWidth>
                        <MessageItem
                            key="error"
                            message="Select country or region to add Process Owner"
                            variant="error"
                            onClose={() => setRegionalError(null)}
                        />
                    </FormGridItem>
                )}
                <FormGridItem fullWidth>
                    <Autocomplete
                        {...dataTestId('ADD_PROCESS_OWNER_TYPE_INPUT')}
                        options={options}
                        getOptionLabel={(option: {
                            title: string
                            fields: string[]
                            mode: DialogModeType
                        }) => option?.title}
                        value={option}
                        onChange={(e, v: any) => setOption(v)}
                        disableClearable={true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    style: { paddingTop: 0 },
                                }}
                                label="Process owner type"
                            />
                        )}
                    />
                </FormGridItem>
                {renderGridItem<EmployeeSelectProps>(
                    'employee',
                    EmployeeSelect,
                    {
                        label: 'Employee',
                        employee: query.employee,
                        onEmployeeChanged: (employee) =>
                            setQuery((prev) => ({ ...prev, employee })),
                        ...dataTestId('ADD_EMPLOYEE_TEXT_INPUT'),
                    }
                )}
                {renderGridItem<DivisionSelectProps>(
                    'businessArea',
                    DivisionSelect,
                    {
                        selectedDivision: query.businessArea,
                        onDivisionChanged: handleChange('businessArea'),
                        ...dataTestId('ADD_BUSINESS_AREA_INPUT'),
                    }
                )}
                {isAllMode ? (
                    <>
                        {renderGridItem<BusinessUnitSelectProps>(
                            'division',
                            BusinessUnitSelect,
                            {
                                division: query.businessArea,
                                selectedBusinessUnit: query.division,
                                onBusinessUnitChanged: handleChange('division'),
                                ...dataTestId('ADD_DIVISION_INPUT'),
                            }
                        )}
                        {renderGridItem<ProductGroupSelectProps>(
                            'productGroup',
                            ProductGroupSelect,
                            {
                                division: query.businessArea,
                                businessUnit: query.division,
                                selectedProductGroup: query.productGroup,
                                onProductGroupChanged:
                                    handleChange('productGroup'),
                                ...dataTestId('ADD_PRODUCT_GROUP_INPUT'),
                            }
                        )}
                    </>
                ) : (
                    <>
                        {renderGridItem<BusinessUnitSelectProps>(
                            'division',
                            BusinessUnitSelect,
                            {
                                selectedBusinessUnit: query.division,
                                onBusinessUnitChanged: handleChange('division'),
                                displayAll: true,
                                ...dataTestId('ADD_DIVISION_INPUT'),
                            }
                        )}
                        {renderGridItem<ProductGroupSelectProps>(
                            'productGroup',
                            ProductGroupSelect,
                            {
                                selectedProductGroup: query.productGroup,
                                onProductGroupChanged:
                                    handleChange('productGroup'),
                                displayAll: true,
                                ...dataTestId('ADD_PRODUCT_GROUP_INPUT'),
                            }
                        )}
                    </>
                )}
                {renderGridItem<RegionSelectProps>('region', RegionSelect, {
                    selectedRegion: query.region,
                    onRegionChanged: handleChange('region'),
                    ...dataTestId('ADD_REGION_INPUT'),
                })}
                {renderGridItem<CountrySelectProps>('country', CountrySelect, {
                    selectedCountry: query.country,
                    onCountryChanged: handleChange('country'),
                    ...dataTestId('ADD_COUNTRY_INPUT'),
                })}
            </GridContainer>
        </AdminDialog>
    )
}
