import { LocalOffer } from '@mui/icons-material'
import Memory from '@mui/icons-material/Memory'
import Work from '@mui/icons-material/Work'
import { memo } from 'react'
import { dataTestId } from 'utils'
import { ExpandTile, useTilesStyles } from '.'
import { DataArea, ResolutionUnitValue, ValueView } from '../../components'
import NavButton from '../../components/Base/NavButton'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { useIssueTypeName } from '../../hooks'
import { MyAction } from '../../types/myDashboard'

export interface ToReplaceInactiveRUActionTileProps {
    actionIssue: MyAction
}

export const ToReplaceInactiveRUActionTile = (
    props: ToReplaceInactiveRUActionTileProps
) => {
    const { actionIssue, ...other } = props

    const classes = useTilesStyles(props)
    const resolvingUnit = actionIssue?.resolvingUnit
    const actions = (
        <NavButton
            color="secondary"
            linkProps={{ to: `/issue/${actionIssue.issueId}` }}
            {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.REPLACE_INACTIVE_RU}
            eventName={'REPLACE INACTIVE RESOLVING UNIT'}
        >
            REPLACE INACTIVE RESOLVING UNIT
        </NavButton>
    )
    const issueTypeName = useIssueTypeName(actionIssue?.issueType)
    return (
        <ExpandTile
            {...other}
            {...actionIssue}
            showActionStatus={true}
            actionCode={actionIssue.action.actionCode}
            issueId={actionIssue.issueId}
            issueNumber={actionIssue.issueNumber}
            collapsedSize="64px"
            isOverdue={actionIssue.action.isActionOverdue}
            isHighImpact={actionIssue.isHighImpact}
            actions={actions}
        >
            <ResolutionUnitValue
                resolvingUnit={resolvingUnit}
                {...dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT')}
            />
            <DataArea>
                {issueTypeName && (
                    <ValueView
                        className={classes.cell}
                        icon={<LocalOffer className={classes.labelIcon} />}
                        label="Issue type"
                        value={issueTypeName}
                        singleLine={true}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.ISSUE_TYPE}
                    />
                )}
                {actionIssue.productName && (
                    <ValueView
                        className={classes.cell}
                        icon={<Memory className={classes.labelIcon} />}
                        label="Product"
                        value={actionIssue.productName}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.PRODUCT}
                    />
                )}
                {actionIssue.customerName && (
                    <ValueView
                        className={classes.cell}
                        icon={<Work className={classes.labelIcon} />}
                        label="Customer"
                        value={actionIssue.customerName}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.CUSTOMER}
                    />
                )}
            </DataArea>
        </ExpandTile>
    )
}

export default memo(ToReplaceInactiveRUActionTile)
