import { AccordionActions } from '@mui/material'
import { ActionPlanContext } from 'ActionPlan/context/ActionPlanContext'
import { Button } from 'components'
import { useCanCreateActionPlanItem } from 'hooks'
import { useContext } from 'react'
import { useParams } from 'react-router'
import { dataTestId } from 'utils'
export interface ActionPlanSectionActionsProps {
    openActionPlanDashboard: () => void
}

export const ActionPlanSectionActions = (
    props: ActionPlanSectionActionsProps
) => {
    const { id: issueIdOrNumber } = useParams<{ id: string }>()
    const canAddTask = useCanCreateActionPlanItem()
    const { openActionItem } = useContext(ActionPlanContext)
    const { openActionPlanDashboard } = props

    return (
        <AccordionActions>
            {canAddTask && (
                <Button
                    onClick={() => openActionItem(issueIdOrNumber)}
                    color="primary"
                    eventName="Add new action item"
                    {...dataTestId('ACTION_PLAN_ADD_TASK')}
                >
                    Add task
                </Button>
            )}
            <Button
                onClick={openActionPlanDashboard}
                color="secondary"
                eventName="Open action dashboard"
                {...dataTestId('ACTION_PLAN_ACTION_DASHBOARD')}
            >
                Action Dashboard
            </Button>
        </AccordionActions>
    )
}
