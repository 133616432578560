import { put, select, takeEvery } from 'redux-saga/effects'
import {
    ISSUE_SAVE_AS_DONE,
    ExpandedIssueState,
    ISSUE_LOAD_DONE,
    ISSUE_LOAD_CONTEXT_DONE,
    initExpandMode,
} from '..'
import { IssueActionsTypes } from 'types/enums'
import { IssueAction, IssueContext } from 'types/issueTypes'
import { RootState } from 'store/types'

const checkExpanded = (
    actionTypes: Array<IssueActionsTypes>,
    actions: Array<IssueAction>
): boolean =>
    actions?.some((a) => actionTypes.some((t) => t === a.actionCode)) ?? false

export function* initExpandState() {
    yield takeEvery(
        [ISSUE_SAVE_AS_DONE, ISSUE_LOAD_DONE, ISSUE_LOAD_CONTEXT_DONE],
        function* () {
            const context: IssueContext = yield select(
                (s: RootState) => s?.issue?.context ?? null
            )
            if (context && context?.actions) {
                const actions = context.actions
                const newExpandedState: ExpandedIssueState = {
                    capture: checkExpanded(
                        [
                            IssueActionsTypes.To_Submit,
                            IssueActionsTypes.To_Submit_Returned_To_Draft,
                            IssueActionsTypes.To_Replace_Inactive_RU,
                            IssueActionsTypes.To_Assign_Updated_RU,
                            IssueActionsTypes.To_Replace_Inactive_IO,
                            IssueActionsTypes.To_Verify_HI,
                        ],
                        actions
                    ),
                    assign: checkExpanded(
                        [
                            IssueActionsTypes.To_Assign,
                            IssueActionsTypes.To_Replace_Inactive_RO,
                        ],
                        actions
                    ),
                    resolve: checkExpanded(
                        [
                            IssueActionsTypes.To_Resolve,
                            IssueActionsTypes.To_Verify_Resolution,
                            IssueActionsTypes.Resolution_Rejected,
                            IssueActionsTypes.To_Execute_Containment_Actions,
                        ],
                        actions
                    ),
                    closed: checkExpanded(
                        [
                            IssueActionsTypes.To_Complete,
                            IssueActionsTypes.To_Complete_After_Customer_Execution,
                        ],
                        actions
                    ),
                    effectiveness: checkExpanded(
                        [IssueActionsTypes.Effectiveness_Sustainability_Check],
                        actions
                    ),
                }
                yield put(initExpandMode(newExpandedState))
            } else {
                yield put(
                    initExpandMode({
                        capture: true,
                        assign: false,
                        resolve: false,
                        closed: false,
                        effectiveness: false,
                    })
                )
            }
        }
    )
}
