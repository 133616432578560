import {
    FormControl,
    FormControlLabel,
    FormControlProps,
    FormGroup,
    InputLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Checkbox } from 'components'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(2, 0),
        },
        item: {
            marginRight: theme.spacing(2),
        },
    }),
    { name: 'RepeatInSelect' }
)

export interface RepeatInSelectProps
    extends Omit<FormControlProps, 'onChange'> {
    value: number[]
    onChange: (value: number[]) => void
}

const values: Array<{ key: number; name: string }> = [
    { key: 0, name: 'Sa' },
    { key: 1, name: 'Mo' },
    { key: 2, name: 'Tu' },
    { key: 3, name: 'We' },
    { key: 4, name: 'Th' },
    { key: 5, name: 'Fr' },
    { key: 6, name: 'Sat' },
]

export const RepeatInSelect = (props: RepeatInSelectProps) => {
    const { value, onChange, ...other } = props
    const classes = useStyles()
    return (
        <FormControl fullWidth {...other}>
            <InputLabel>Repeat In</InputLabel>
            <FormGroup className={classes.content}>
                {values.map(({ key, name }) => (
                    <FormControlLabel
                        key={key}
                        className={classes.item}
                        control={
                            <Checkbox
                                checked={value.includes(key)}
                                onChange={(_, checked) => {
                                    if (checked) {
                                        onChange([...value, key].sort())
                                    } else {
                                        const newValue = [...value]
                                        const index = newValue.findIndex(
                                            (x) => x === key
                                        )
                                        newValue.splice(index, 1)
                                        onChange(newValue)
                                    }
                                }}
                                name={name}
                            />
                        }
                        label={name}
                    />
                ))}
            </FormGroup>
        </FormControl>
    )
}
