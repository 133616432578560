import { HtmlHTMLAttributes, useMemo } from 'react'
import { Customer } from 'types/issueTypes'
import { StartAdornmentInfo } from './StartAdornmentInfo'

export interface CustomerDetailsProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    customer?: Customer
}

const EMPTY_PLACEHOLDER = 'n/a'

export const CustomerDetails = (props: CustomerDetailsProps) => {
    const { customer, ...other } = props
    const title = useMemo(() => {
        return (
            <div>
                <div>Identifier: {customer?.guid ?? EMPTY_PLACEHOLDER}</div>
                <div>Full name: {customer?.name ?? EMPTY_PLACEHOLDER}</div>
                <div>
                    Short name: {customer?.shortName ?? EMPTY_PLACEHOLDER}
                </div>
                <div>
                    Address: {customer?.address?.full ?? EMPTY_PLACEHOLDER}
                </div>
                <div>
                    Zip Code: {customer?.address?.zipCode ?? EMPTY_PLACEHOLDER}
                </div>
                <div>
                    LBPS:{' '}
                    {customer?.leadingBusinessPartnerSegment ??
                        EMPTY_PLACEHOLDER}
                </div>
                <div>
                    Industry Usage:{' '}
                    {customer?.industryUsage ?? EMPTY_PLACEHOLDER}
                </div>
                <div>
                    Channel Class: {customer?.channelClass ?? EMPTY_PLACEHOLDER}
                </div>
                <div>
                    Account manager:{' '}
                    {customer?.accountManagerEmail ?? EMPTY_PLACEHOLDER}
                </div>
            </div>
        )
    }, [customer])
    return customer ? <StartAdornmentInfo details={title} {...other} /> : null
}
