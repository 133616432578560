import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(10, 0),
        },
    }),
    {
        name: 'CircularProgressLoader',
    }
)

export const CircularProgressLoader = (
    props: Omit<HtmlHTMLAttributes<HTMLDivElement>, 'children'>
) => {
    const { className, ...other } = props
    const classes = useStyles()
    return (
        <div className={clsx(className, classes.root)} {...other}>
            <CircularProgress color="secondary" size={36} />
        </div>
    )
}
