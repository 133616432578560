import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useIssueFormStyles = makeStyles(
    (theme: AbbTheme) => ({
        header: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        chechboxGroup: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        headline: {
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightMedium as number,
            fontSize: 20,
            '@media (max-width: 480px)': {
                fontSize: 16,
            },
        },
        subHeadline: {
            display: 'flex',
            alignItems: 'center',
            minHeight: 48,
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightMedium as number,
            fontSize: 16,
            '@media (max-width: 480px)': {
                fontSize: 14,
            },
        },
        topBadge: {
            boxShadow: `inset 0 ${theme.spacing(0.5)} 0 0 ${
                theme.palette.secondary.main
            }`,
        },
        radioTextField: {
            marginTop: theme.spacing(1),
        },
        sendCopyCheckbox: {
            marginTop: theme.spacing(1),
        },
        sendCopySelection: {
            marginTop: theme.spacing(2),
        },
        linkIssue: {
            color: 'green',
            '&$disabled': {
                color: theme.palette.red.main,
            },
        },
        disabled: {},
        lastItem: {
            paddingBottom: theme.spacing(2),
        },
        rcaChip: {
            margin: theme.spacing(1),
        },
        expandSubHeadline: {
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightMedium as number,
            fontSize: 16,
            '@media (max-width: 480px)': {
                fontSize: 14,
            },
        },
        section: {
            marginBottom: `${theme.spacing(4)} !important`,
        },
        card: {
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2),
        },
        horiRadioGroup: {
            marginBottom: theme.spacing(2),
        },
        radioGroup: {
            marginBottom: theme.spacing(2),
        },
        horiRadioLast: {
            marginLeft: theme.spacing(2),
        },
        lastCheckBox: {
            marginBottom: theme.spacing(2),
        },
        columnWrapper: {
            display: 'flex',
            flexDirection: 'column',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(2),
            '@media (max-width: 480px)': {
                flexDirection: 'column',
            },
            '&:first-child': {
                marginTop: theme.spacing(0.5),
            },
        },
        col: {
            display: 'inline-flex',
            width: 'calc(50% - 16px)',
        },
        solutionCheckbox: {
            marginBottom: theme.spacing(2),
        },
        expectedExecutionDate: {
            marginBottom: theme.spacing(2),
        },
        radioLast: {
            marginBottom: theme.spacing(1),
        },
        rcaCategory: {
            marginTop: theme.spacing(2),
        },
        content: {
            marginTop: theme.spacing(2),
            '&:first-child': {
                marginTop: 0,
            },
            '&:last-child': {
                marginBottom: theme.spacing(2),
            },
        },
        subjectWrapper: {
            width: '100%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginTop: theme.spacing(1),
        },
        overdue: {
            marginRight: theme.spacing(0.5),
        },
        reaportsAndTools: {
            display: 'flex',
            width: '100%',
            '@media (max-width: 480px)': {
                flexDirection: 'column',
            },
        },
        border: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
    }),
    { name: 'IssueForm' }
)

export default useIssueFormStyles
