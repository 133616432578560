import { Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { AbbTypography } from '../../components'
import { useStyles } from '../Survey.style'

export const SurveyServerError = () => {
    const classes = useStyles()
    return (
        <>
            <Helmet>
                <title></title>
            </Helmet>
            <AbbTypography variant="h2">Error</AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                This website is temporarily unavailable, please try again later.
            </Typography>
        </>
    )
}
