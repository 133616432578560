import { IssueValuesKeys, AttachmentTypes } from 'types/enums'
import {
    Issue,
    CaptureSection,
    HighImpact,
    ResolutionSection,
    EffectivenessSection,
    AssignSection,
    ClosingSection,
} from 'types/issueTypes'

const issueGetterMap: Partial<Record<IssueValuesKeys, (issue: Issue) => any>> =
    {
        [IssueValuesKeys.IssueOwner]: (issue: Issue) =>
            issue?.captureSection?.issueOwner,
        [IssueValuesKeys.IssueOwnerTeamEmails]: (issue: Issue) =>
            issue?.captureSection?.issueOwnerTeam,
        [IssueValuesKeys.IssueOwnerSupervisorEmail]: (issue: Issue) =>
            issue?.captureSection?.issueOwnerSupervisorEmail,
        [IssueValuesKeys.IssueType]: (issue: Issue) =>
            issue?.captureSection?.issueType,
        [IssueValuesKeys.Customer]: (issue: Issue) =>
            issue?.captureSection?.customer,
        [IssueValuesKeys.CustomerContact]: (issue: Issue) =>
            issue?.captureSection?.customerContact,
        [IssueValuesKeys.Subject]: (issue: Issue) =>
            issue?.captureSection?.subject,
        [IssueValuesKeys.Summary]: (issue: Issue) =>
            issue?.captureSection?.summary,
        [IssueValuesKeys.WhatIsHappening]: (issue: Issue) =>
            issue?.captureSection?.whatIsHappening,
        [IssueValuesKeys.WhyIsThisAProblem]: (issue: Issue) =>
            issue?.captureSection?.whyIsThisAProblem,
        [IssueValuesKeys.HowDoWeKnowWeHaveAProblem]: (issue: Issue) =>
            issue?.captureSection?.howDoWeKnowWeHaveAProblem,
        [IssueValuesKeys.WhereDoesDidProblemHappen]: (issue: Issue) =>
            issue?.captureSection?.whereDoesDidProblemHappen,
        [IssueValuesKeys.WhenDoesDidTheProblemHappen]: (issue: Issue) =>
            issue?.captureSection?.whenDoesDidTheProblemHappen,
        [IssueValuesKeys.WhatAreCustomerExpectations]: (issue: Issue) =>
            issue?.captureSection?.whatAreCustomerExpectations,
        [IssueValuesKeys.WhoIsAffectedByTheProblem]: (issue: Issue) =>
            issue?.captureSection?.whoIsAffectedByTheProblem,
        [IssueValuesKeys.HowManyDefectsFound]: (issue: Issue) =>
            issue?.captureSection?.howManyDefectsFound,
        [IssueValuesKeys.IsContainmentActionRequired]: (issue: Issue) =>
            issue?.captureSection?.isContainmentActionRequired,
        [IssueValuesKeys.ContainmentActionDeadlineDate]: (issue: Issue) =>
            issue?.captureSection?.containmentActionDeadlineDate,
        [IssueValuesKeys.ContainmentActionDescription]: (issue: Issue) =>
            issue?.captureSection?.containmentActionDescription,
        [IssueValuesKeys.IsHighImpact]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isHighImpact,
        [IssueValuesKeys.IsHealthAndSafetyImpact]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isHealthAndSafetyImpact,
        [IssueValuesKeys.IsABBOrCustomerReputationAtStake]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isABBOrCustomerReputationAtStake,
        [IssueValuesKeys.IsNegativeBusinessImpactToCustomer]: (issue: Issue) =>
            issue?.captureSection?.highImpact
                ?.isNegativeBusinessImpactToCustomer,
        [IssueValuesKeys.IsNegativeBusinessImpactToABB]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isNegativeBusinessImpactToABB,
        [IssueValuesKeys.IsNegativeImpactOnABBBrandImage]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isNegativeImpactOnABBBrandImage,
        [IssueValuesKeys.IsPotentialToLossCustomer]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.isPotentialToLossCustomer,
        [IssueValuesKeys.IsPotentialSystemicIssuesOrProductRecall]: (
            issue: Issue
        ) =>
            issue?.captureSection?.highImpact
                ?.isPotentialSystemicIssuesOrProductRecall,
        [IssueValuesKeys.IsResolvingUnitOrganizationalInfoDivisionCode]: (
            issue: Issue
        ) =>
            issue?.captureSection?.resolvingUnit?.organizationalInfo?.division
                ?.code,
        [IssueValuesKeys.IsResolvingUnitOrganizationalInfoBusinessUnitCode]: (
            issue: Issue
        ) =>
            issue?.captureSection?.resolvingUnit?.organizationalInfo
                ?.businessUnit?.code,
        [IssueValuesKeys.ImpactReason]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.impactReason,
        [IssueValuesKeys.PotentialBusinessImpact]: (issue: Issue) =>
            issue?.captureSection?.highImpact?.potentialBusinessImpact,
        [IssueValuesKeys.IsRootCauseRequiredByCustomer]: (issue: Issue) =>
            issue?.captureSection?.isRootCauseRequiredByCustomer,
        [IssueValuesKeys?.TargetResolutionAndVerificationDate]: (
            issue: Issue
        ) => issue?.captureSection?.targetResolutionAndVerificationDate,
        [IssueValuesKeys?.TargetResolutionDateChangeReasonId]: (issue: Issue) =>
            issue?.captureSection?.targetResolutionDateChangeReasonId,
        [IssueValuesKeys?.TargetResolutionDateChangeReason]: (issue: Issue) =>
            issue?.captureSection?.targetResolutionDateChangeReason,
        [IssueValuesKeys?.ResolvingUnitId]: (issue: Issue) =>
            issue?.captureSection?.resolvingUnitId,
        [IssueValuesKeys.ProposedResolutionOwner]: (issue: Issue) =>
            issue?.captureSection?.proposedResolutionOwner,
        [IssueValuesKeys.CoordinatorsEmails]: (issue: Issue) =>
            issue?.captureSection?.resolvingUnit?.coordinatorsEmails,
        [IssueValuesKeys.ProductCID]: (issue: Issue) =>
            issue?.captureSection?.productCID,
        [IssueValuesKeys.ProductLineLocationId]: (issue: Issue) =>
            issue?.captureSection?.productLineLocationId,
        [IssueValuesKeys.FailureModeId]: (issue: Issue) =>
            issue?.captureSection?.failureModeId,
        [IssueValuesKeys?.FailureModeValidation]: (issue: Issue) =>
            issue?.captureSection?.failureModeValidation,
        [IssueValuesKeys.SerialNo]: (issue: Issue) =>
            issue?.captureSection?.serialNo,
        [IssueValuesKeys.PurchaseOrderNo]: (issue: Issue) =>
            issue?.captureSection?.purchaseOrderNo,
        [IssueValuesKeys.AbbProject]: (issue: Issue) =>
            issue?.captureSection?.abbProject,
        [IssueValuesKeys.ProjectName]: (issue: Issue) =>
            issue?.captureSection?.projectName,
        [IssueValuesKeys.Quantity]: (issue: Issue) =>
            issue?.captureSection?.quantity,
        [IssueValuesKeys.CustomerReference]: (issue: Issue) =>
            issue?.captureSection?.customerReference,
        [IssueValuesKeys.SupplierGuid]: (issue: Issue) =>
            issue?.captureSection?.supplier?.guid,
        [IssueValuesKeys.CommissioningDate]: (issue: Issue) =>
            issue?.captureSection?.commissioningDate,
        [IssueValuesKeys.ManufacturingDate]: (issue: Issue) =>
            issue?.captureSection?.manufacturingDate,
        [IssueValuesKeys.WarrantyReference]: (issue: Issue) =>
            issue?.captureSection?.warranty?.reference,
        [IssueValuesKeys.WarrantyStartDate]: (issue: Issue) =>
            issue?.captureSection?.warranty?.startDate,
        [IssueValuesKeys.WarrantyEndDate]: (issue: Issue) =>
            issue?.captureSection?.warranty?.endDate,
        [IssueValuesKeys.IsCopySentToParties]: (issue: Issue) =>
            issue?.captureSection?.isCopySentToParties,
        [IssueValuesKeys.PartiesReference]: (issue: Issue) =>
            issue?.captureSection?.partiesReference,
        [IssueValuesKeys.PartiesReferenceEmails]: (issue: Issue) =>
            issue?.captureSection?.partiesReferenceEmails,
        [IssueValuesKeys.PrimaryResolutionOwner]: (issue: Issue) =>
            issue?.assignSection?.primaryResolutionOwner,
        [IssueValuesKeys.ResolutionOwnerTeam]: (issue: Issue) =>
            issue?.assignSection?.resolutionOwnerTeam,
        [IssueValuesKeys.QuickFixVariant]: (issue: Issue) =>
            issue?.assignSection?.quickFixVariant,
        [IssueValuesKeys.WhatContainmentActionsWereTaken]: (issue: Issue) =>
            issue?.resolutionSection?.whatContainmentActionsWereTaken,
        [IssueValuesKeys.WhatWasTheRootCause]: (issue: Issue) =>
            issue?.resolutionSection?.whatWasTheRootCause,
        [IssueValuesKeys.HowDidWeResolveThisIssue]: (issue: Issue) =>
            issue?.resolutionSection?.howDidWeResolveThisIssue,
        [IssueValuesKeys.ResolutionDescription]: (issue: Issue) =>
            issue?.resolutionSection?.resolutionDescription,
        [IssueValuesKeys.RcaAttachments]: (issue: Issue) =>
            issue?.attachments.filter(
                (a) => a.attachmentType === AttachmentTypes.Resolution_Rca
            ),
        [IssueValuesKeys.ResolutionAttachments]: (issue: Issue) =>
            issue?.attachments.filter(
                (a) => a.attachmentType === AttachmentTypes.Resolution
            ),
        [IssueValuesKeys.PgRcaCategory]: (issue: Issue) =>
            issue?.resolutionSection?.pgRcaCategory,
        [IssueValuesKeys.EffectivenessTypeId]: (issue: Issue) =>
            issue?.effectivenessSection?.effectivenessTypeId,
        [IssueValuesKeys.ReasonWhyNotEffectiveOrNotRequired]: (issue: Issue) =>
            issue?.effectivenessSection?.reasonWhyNotEffectiveOrNotRequired,
        [IssueValuesKeys.EffectivenessDeadlineDate]: (issue: Issue) =>
            issue?.effectivenessSection?.effectivenessDeadlineDate,
        [IssueValuesKeys.CaptureAttachments]: (issue: Issue) =>
            issue?.attachments.filter(
                (a) => a.attachmentType === AttachmentTypes.Information
            ),
        [IssueValuesKeys.SolutionWillBeValidatedByCoordinator]: (
            issue: Issue
        ) => issue?.assignSection?.solutionWillBeValidatedByCoordinator,
        [IssueValuesKeys.IsSendCustomerSurvey]: (issue: Issue) =>
            issue?.closingSection?.isSendCustomerSurvey,
        [IssueValuesKeys.NextEscalationDate]: (issue: Issue) =>
            issue?.effectivenessSection?.nextEscalationDate,
        [IssueValuesKeys.BehalfBuCode]: (issue: Issue) =>
            issue?.captureSection?.behalfBuCode,
        [IssueValuesKeys.BehalfCountryCode]: (issue: Issue) =>
            issue?.captureSection?.behalfCountryCode,
        [IssueValuesKeys.Classifications]: (issue: Issue) =>
            issue?.captureSection?.resolvingUnit?.classifications,
    }

export const getIssueGetter = (issueValueKey: IssueValuesKeys) =>
    issueGetterMap[issueValueKey]

export const getCaptureSectionKey = (
    propName: keyof CaptureSection
): IssueValuesKeys => {
    const map: Partial<Record<keyof CaptureSection, IssueValuesKeys>> = {
        issueOwnerSupervisorEmail: IssueValuesKeys.IssueOwnerSupervisorEmail,
        issueOwner: IssueValuesKeys.IssueOwner,
        issueOwnerTeam: IssueValuesKeys.IssueOwnerTeamEmails,
        isCopySentToParties: IssueValuesKeys.IsCopySentToParties,
        partiesReferenceEmails: IssueValuesKeys.PartiesReferenceEmails,
        partiesReference: IssueValuesKeys.PartiesReference,
        subject: IssueValuesKeys.Subject,
        summary: IssueValuesKeys.Summary,
        whatIsHappening: IssueValuesKeys.WhatIsHappening,
        whyIsThisAProblem: IssueValuesKeys.WhyIsThisAProblem,
        howDoWeKnowWeHaveAProblem: IssueValuesKeys.HowDoWeKnowWeHaveAProblem,
        whereDoesDidProblemHappen: IssueValuesKeys.WhereDoesDidProblemHappen,
        whenDoesDidTheProblemHappen:
            IssueValuesKeys.WhenDoesDidTheProblemHappen,
        whatAreCustomerExpectations:
            IssueValuesKeys.WhatAreCustomerExpectations,
        whoIsAffectedByTheProblem: IssueValuesKeys.WhoIsAffectedByTheProblem,
        howManyDefectsFound: IssueValuesKeys.HowManyDefectsFound,
        issueType: IssueValuesKeys.IssueType,
        customer: IssueValuesKeys.Customer,
        customerContact: IssueValuesKeys.CustomerContact,
        isRootCauseRequiredByCustomer:
            IssueValuesKeys.IsRootCauseRequiredByCustomer,
        targetResolutionAndVerificationDate:
            IssueValuesKeys.TargetResolutionAndVerificationDate,
        targetResolutionDateChangeReasonId:
            IssueValuesKeys.TargetResolutionDateChangeReasonId,
        targetResolutionDateChangeReason:
            IssueValuesKeys.TargetResolutionDateChangeReason,
        resolvingUnitId: IssueValuesKeys.ResolvingUnitId,
        proposedResolutionOwner: IssueValuesKeys.ProposedResolutionOwner,
        productCID: IssueValuesKeys.ProductCID,
        failureModeId: IssueValuesKeys.FailureModeId,
        failureModeValidation: IssueValuesKeys.FailureModeValidation,
        serialNo: IssueValuesKeys.SerialNo,
        purchaseOrderNo: IssueValuesKeys.PurchaseOrderNo,
        abbProject: IssueValuesKeys.AbbProject,
        projectName: IssueValuesKeys.ProjectName,
        quantity: IssueValuesKeys.Quantity,
        customerReference: IssueValuesKeys.CustomerReference,
        supplier: IssueValuesKeys.SupplierGuid, // missing
        commissioningDate: IssueValuesKeys.CommissioningDate,
        manufacturingDate: IssueValuesKeys.ManufacturingDate,
        failureModeDescription: IssueValuesKeys.FailureModeDescription,
        containmentActionDescription:
            IssueValuesKeys.ContainmentActionDescription,
        isContainmentActionRequired:
            IssueValuesKeys.IsContainmentActionRequired,
        containmentActionDeadlineDate:
            IssueValuesKeys.ContainmentActionDeadlineDate,
        productLineLocationId: IssueValuesKeys.ProductLineLocationId,
        behalfBuCode: IssueValuesKeys.BehalfBuCode,
        behalfCountryCode: IssueValuesKeys.BehalfCountryCode,
    }

    return map[propName] ?? null
}

export const getHighImpactKey = (
    propName: keyof HighImpact
): IssueValuesKeys => {
    const map: Partial<Record<keyof HighImpact, IssueValuesKeys>> = {
        impactReason: IssueValuesKeys.ImpactReason,
        isHighImpact: IssueValuesKeys.IsHighImpact,
        isABBOrCustomerReputationAtStake:
            IssueValuesKeys.IsABBOrCustomerReputationAtStake,
        isHealthAndSafetyImpact: IssueValuesKeys.IsHealthAndSafetyImpact,
        isNegativeBusinessImpactToABB:
            IssueValuesKeys.IsNegativeBusinessImpactToABB,
        isNegativeBusinessImpactToCustomer:
            IssueValuesKeys.IsNegativeBusinessImpactToCustomer,
        isPotentialSystemicIssuesOrProductRecall:
            IssueValuesKeys.IsPotentialSystemicIssuesOrProductRecall,
        isPotentialToLossCustomer: IssueValuesKeys.IsPotentialToLossCustomer,
        potentialBusinessImpact: IssueValuesKeys.PotentialBusinessImpact,
        isNegativeImpactOnABBBrandImage:
            IssueValuesKeys.IsNegativeImpactOnABBBrandImage,
    }
    return map[propName] ?? null
}

export const getHighImpactCategoriesKeys = (): Array<IssueValuesKeys> => [
    IssueValuesKeys.IsABBOrCustomerReputationAtStake,
    IssueValuesKeys.IsHealthAndSafetyImpact,
    IssueValuesKeys.IsNegativeBusinessImpactToABB,
    IssueValuesKeys.IsNegativeBusinessImpactToCustomer,
    IssueValuesKeys.IsPotentialSystemicIssuesOrProductRecall,
    IssueValuesKeys.IsPotentialToLossCustomer,
    IssueValuesKeys.IsNegativeImpactOnABBBrandImage,
]

export const getResolutionSectionKey = (
    propName: keyof ResolutionSection
): IssueValuesKeys => {
    const map: Partial<Record<keyof ResolutionSection, IssueValuesKeys>> = {
        whatWasTheRootCause: IssueValuesKeys.WhatWasTheRootCause,
        howDidWeResolveThisIssue: IssueValuesKeys.HowDidWeResolveThisIssue,
        whatContainmentActionsWereTaken:
            IssueValuesKeys.WhatContainmentActionsWereTaken,
        pgRcaCategory: IssueValuesKeys.PgRcaCategory,
    }
    return map[propName] ?? null
}

export const getEffectivenessSectionKey = (
    propName: keyof EffectivenessSection
): IssueValuesKeys => {
    const map: Partial<Record<keyof EffectivenessSection, IssueValuesKeys>> = {
        effectivenessTypeId: IssueValuesKeys.EffectivenessTypeId,
        reasonWhyNotEffectiveOrNotRequired:
            IssueValuesKeys.ReasonWhyNotEffectiveOrNotRequired,
        nextEscalationDate: IssueValuesKeys.NextEscalationDate,
    }
    return map[propName] ?? null
}

export const getAssignSectionKey = (
    propName: keyof AssignSection
): IssueValuesKeys => {
    const map: Partial<Record<keyof AssignSection, IssueValuesKeys>> = {
        primaryResolutionOwner: IssueValuesKeys.PrimaryResolutionOwner,
        primaryActiveResolutionOwner:
            IssueValuesKeys.PrimaryResolutionOwnerActive,
        resolutionOwnerTeam: IssueValuesKeys.ResolutionOwnerTeam,
        quickFixVariant: IssueValuesKeys.QuickFixVariant,
        quickFixIssueId: IssueValuesKeys.QuickFixIssueId,
        solutionWillBeValidatedByCoordinator:
            IssueValuesKeys.SolutionWillBeValidatedByCoordinator,
    }

    return map[propName] ?? null
}

export const getClosingSectionKey = (
    propName: keyof ClosingSection
): IssueValuesKeys => {
    const map: Partial<Record<keyof ClosingSection, IssueValuesKeys>> = {
        isWaitingForExecution: IssueValuesKeys.IsWaitingForExecution,
        expectedExecutionDate: IssueValuesKeys.ExpectedExecutionDate,
        customerComments: IssueValuesKeys.CustomerComments,
    }

    return map[propName] ?? null
}
