import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { msalInstance } from 'configs/authProvider'
import dayjs from 'dayjs'
import { debounce } from 'lodash'
import { MY_ISSUES_FILTERS_STORAGE_KEY } from 'pages/MyIssue'
import { FC, useCallback, useEffect, useState } from 'react'
import { AuthenticationActions } from 'react-aad-msal'
import { useDispatch } from 'react-redux'
import { useInterval } from 'react-use'
import { gdprListKey } from '../GdprGuard'
import {
    ACTION_PLAN_DASHBOARD_EXAND_STORAGE_KEY,
    ACTION_PLAN_DASHBOARD_FILTERS_STORAGE_KEY,
} from '../MyActionPlanItems'
import { MY_ACTIONS_FILTERS_STORAGE_KEY } from '../MyActionsPage'
import { SessionTimeoutWarrning } from './SessionTimeoutWarrning'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            '&$timeout': {
                filter: 'grayscale(1) blur(8px)',
                pointerEvents: 'none',
                userSelect: 'none',
            },
        },
        timeout: {},
    }),
    { name: 'SessionTimeoutGuard' }
)

let lastUserInteraction = ''
const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart']

const warningTimeoutMinutes = Number(
    process.env.REACT_APP_SESSION_TIMEOUT_WARNING
)
const autoLogoutTimeoutMinutes = Number(process.env.REACT_APP_SESSION_TIMEOUT)

const temporaryKeys = [
    gdprListKey,
    ACTION_PLAN_DASHBOARD_FILTERS_STORAGE_KEY,
    ACTION_PLAN_DASHBOARD_EXAND_STORAGE_KEY,
    MY_ISSUES_FILTERS_STORAGE_KEY,
    MY_ACTIONS_FILTERS_STORAGE_KEY,
]

export const SessionTimeoutGuard: FC<unknown> = ({ children }) => {
    const classes = useStyles()
    const [timeout, setTimeout] = useState<string>(null)
    const dispatch = useDispatch()
    const handleLogout = () => {
        dispatch({ type: AuthenticationActions.LogoutSuccess })
        sessionStorage.clear()
        for (let index = 0; index < localStorage.length; index++) {
            const key = localStorage.key(index)
            if (!temporaryKeys.includes(key)) {
                localStorage.removeItem(key)
            }
        }
        msalInstance?.logoutRedirect()
    }
    const handleLastInteraction = useCallback(
        debounce(() => (lastUserInteraction = new Date().toISOString()), 500),
        []
    )
    useInterval(
        () => {
            if (!timeout) {
                setTimeout(
                    dayjs().isSameOrAfter(
                        dayjs(lastUserInteraction).add(
                            warningTimeoutMinutes,
                            'minutes'
                        ),
                        'seconds'
                    )
                        ? new Date().toISOString()
                        : null
                )
            }
        },
        !timeout ? 5000 : null
    )
    useInterval(
        () => {
            if (
                dayjs().isSameOrAfter(
                    dayjs(timeout).add(autoLogoutTimeoutMinutes, 'minutes'),
                    'seconds'
                )
            ) {
                handleLogout()
            }
        },
        timeout ? 5000 : null
    )
    useEffect(() => {
        lastUserInteraction = new Date().toISOString()
        events.forEach((event) =>
            document.addEventListener(event, handleLastInteraction, true)
        )
        return events.forEach((event) =>
            document.removeEventListener(event, handleLastInteraction)
        )
    }, [])

    return (
        <div>
            {timeout && (
                <SessionTimeoutWarrning
                    onExtendSession={() => {
                        lastUserInteraction = new Date().toISOString()
                        setTimeout(null)
                    }}
                    onCloseSession={handleLogout}
                />
            )}
            <div className={clsx(classes.content, timeout && classes.timeout)}>
                {children}
            </div>
        </div>
    )
}
