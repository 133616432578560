import { applyMiddleware, compose, createStore, combineReducers } from 'redux'
import { History } from 'history'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { appReducer } from './app/index'
import { dashboardReducer } from './dashboard/index'
import { issueReducer } from './issue/index'
import { linkRcaReducer } from './linkRca/index'
import { resourcesReducer } from './resources/index'
import { myActionsReducer } from './myActions/index'
import { myIssuesReducer } from './myIssues/index'
import { RootState } from './types'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './rootSaga'
import thunk from 'redux-thunk'
import { msalInstance } from 'configs/authProvider'

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: typeof compose
    }
}

const sagaMiddleware = createSagaMiddleware()

export default function createAppStore(history: History) {
    const enhancers = []
    const reduxDevTools: boolean = Boolean(
        Number(process.env.REACT_APP_REDUX_DEV_TOOLS)
    )
    const middlewares = [
        sagaMiddleware,
        routerMiddleware(history),
        thunk.withExtraArgument({ history, msalInstance }),
    ]

    if (reduxDevTools) {
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
            enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__())
    }

    const rootReducer = combineReducers<RootState>({
        app: appReducer,
        router: connectRouter(history),
        dashboard: dashboardReducer,
        myActions: myActionsReducer,
        myIssues: myIssuesReducer,
        issue: issueReducer,
        linkRca: linkRcaReducer,
        resources: resourcesReducer,
    })

    const store = createStore(
        rootReducer,
        {},
        compose(applyMiddleware(...middlewares), ...enhancers)
    )

    sagaMiddleware.run(rootSaga)

    return store
}
