import { Chip } from '@mui/material'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IssueRoles } from 'types/enums'
import { CcrpRole, CcrpUserScope } from 'types/profile'
import { GlobalResourcesState } from '../store/resources'
import { RootState } from '../store/types'
import { FilterBase } from '../types/models'
import { dataTestId, uuid } from '../utils'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        tags: {
            margin: theme.spacing(0.5, -1, 0 - 1),
        },
        tag: {
            maxWidth: 250,
            ...theme.typography.caption,
            margin: theme.spacing(0.5),
            fontWeight: theme.typography.fontWeightMedium as number,
        },
    }),
    { name: 'RoleView' }
)

export interface RoleViewProps
    extends Omit<HtmlHTMLAttributes<HTMLLIElement>, 'children' | 'role'> {
    roleDetails: CcrpRole
}

export const RoleView = (props: RoleViewProps) => {
    const { roleDetails, ...other } = props
    const classes = useStyles(props)
    const { roleId } = roleDetails
    const { roleCode } = useSelector<RootState, GlobalResourcesState>(
        (state) => state?.resources
    )
    const roleCodeExt: FilterBase<number>[] = [
        ...roleCode,
        { code: IssueRoles.Ru_Process_Owner, name: 'RU Process Owner' },
    ]
    const roleName =
        roleCodeExt.find((r) => Number(r.code) === roleDetails.roleId)?.name ??
        roleDetails.roleName
    const getScopeName = useMemo<(scope: CcrpUserScope) => string>(() => {
        const getRegionalAppendix = (scope: CcrpUserScope) => {
            const code = scope?.countryCode ?? scope?.regionCode ?? null
            return code ? ` - ${code}` : ''
        }
        switch (roleId) {
            case IssueRoles.Ru_Process_Owner:
                return (scope: CcrpUserScope) => scope.balId
            case IssueRoles.Div_Process_Owner:
                return (scope: CcrpUserScope) =>
                    `${scope?.divisionCode}${getRegionalAppendix(scope)}`
            case IssueRoles.Bu_Process_Owner:
                return (scope: CcrpUserScope) =>
                    `${scope?.businessUnitCode}${getRegionalAppendix(scope)}`
            case IssueRoles.PG_Process_Owner:
                return (scope: CcrpUserScope) =>
                    `${scope?.productGroupCode}${getRegionalAppendix(scope)}`
            case IssueRoles.Hub_Process_Owner:
                return (scope: CcrpUserScope) => scope.hubCode
            default:
                return () => null
        }
    }, [roleId])
    const scopes = useMemo(() => {
        if (roleDetails.roleId === IssueRoles.Hub_Process_Owner) {
            const scopes = roleDetails.scopes.reduce((a, x) => {
                a[x.hubCode] = x.hubCode
                return a
            }, {})
            return Object.keys(scopes).map<CcrpUserScope>((x) => ({
                hubCode: x,
            }))
        }
        return roleDetails.scopes
    }, [roleDetails])
    return (
        <li {...other}>
            {roleName}
            <div className={classes.tags}>
                {scopes.map((s) => {
                    const name = getScopeName(s)
                    return name ? (
                        <Chip
                            className={classes.tag}
                            key={uuid()}
                            label={name}
                            {...dataTestId(`ROLE_${name}`)}
                        />
                    ) : null
                })}
            </div>
        </li>
    )
}
