import { Button, CircularProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    AccordionActions,
    ActivitySelect,
    BusinessUnitSelect,
    Content,
    CountrySelect,
    DivisionSelect,
    IssueTypeSelect,
    NoResults,
    ProductGroupSelect,
    RoundedButton,
    SearchPanel,
} from 'components'
import { LINK_ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import LinkIssueItem from 'Issue/LinkIssueItem'
import { get } from 'lodash'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { IssueStoreState } from 'store/issue'
import {
    clearError,
    clearSearch,
    copyRca,
    initSearchRcaState,
    LinkRcaStoreState,
    loadMoreRca,
    NUMBER_OF_FETCHED_ITEMS,
    searchRca,
    selectActivity,
    selectBusiness,
    selectBusinessUnit,
    selectCountry,
    selectIssueType,
    selectProductGroup,
    setSearchText,
} from 'store/linkRca'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueRcaPreview } from 'types/issueTypes'
import { SearchRcaQuery } from 'types/queries'
import { PreviewLinkRcaParams } from 'types/urlParams'
import { ASSIGNMENT_SECTION_ID } from './Sections/utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        container: {
            width: 768,
            marginLeft: 'auto',
            marginRight: 'auto',
            '@media (max-width: 768px)': {
                width: '100vw',
            },
        },
        header: {
            position: 'relative',
            backgroundColor: theme.palette.common.white,
            marignBottom: theme.spacing(2),
        },
        field: {
            marginBottom: theme.spacing(2),
        },
        filters: {
            padding: theme.spacing(2),
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        actions: {
            padding: theme.spacing(2),
        },
        searchResults: {
            marginTop: theme.spacing(2.5),
        },
        SearchLoader: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            minHeight: 100,
        },
        loadMore: {
            left: '50%',
            transform: 'translateX(-50%)',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(4),
        },
    }),
    { name: 'LinkIssueView' }
)

export const LinkIssueView = (props: any) => {
    const classes = useStyles(props)
    const history = useHistory()

    const linkRca = useSelector<RootState, LinkRcaStoreState>(
        (state) => state?.linkRca
    )
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state?.issue
    )
    const { id: issueIdFromUrl } = useParams<PreviewLinkRcaParams>()
    const dispatch = useDispatch()
    const specificIssueNumber = linkRca.specificIssueNumber
    const { issueId } = issue
    const suggestionsCount = linkRca.suggestions
        ? linkRca.suggestions.length
        : 0
    const canLoadMoreItems =
        NUMBER_OF_FETCHED_ITEMS <= suggestionsCount
            ? suggestionsCount % NUMBER_OF_FETCHED_ITEMS === 0
            : false

    const goToIssue = () => {
        if (!issueId) {
            history.replace(`/issue/${issueIdFromUrl}#${ASSIGNMENT_SECTION_ID}`)
        }
    }

    const number = issue?.issueNumber
    const resolvingUnitId = get(issue, 'captureSection.resolvingUnit.id', null)
    useEffect(() => {
        if (!issue || !resolvingUnitId) {
            history.replace(`/issue/${issueIdFromUrl}`)
        } else {
            !linkRca.initLinking &&
                dispatch(initSearchRcaState(number, resolvingUnitId))
        }
    }, [])

    const createFormData = (page: number, pageSize: number): SearchRcaQuery => {
        const {
            selectedBusiness,
            selectedBusinessLine,
            selectedProductGroup,
            selectedActivity,
            selectedCountry,
            selectedIssueType,
        } = linkRca
        let query: SearchRcaQuery = {
            page,
            ruId: resolvingUnitId,
            pageSize,
            specificIssueNumber: specificIssueNumber,
        }
        query.businessCode = selectedBusiness ? selectedBusiness.code : null
        query.businessLineCode = selectedBusinessLine
            ? selectedBusinessLine.code
            : null
        query.productGroupCode = selectedProductGroup
            ? selectedProductGroup.code
            : null
        query.activityCode = selectedActivity ? selectedActivity.code : null
        query.countryCode = selectedCountry ? selectedCountry.code : null
        query.issueType = selectedIssueType
            ? Number(selectedIssueType.code)
            : null
        return query
    }

    const handleSearch = () => {
        const { loading, issueNumber } = linkRca
        if (loading) {
            return
        }
        if (!issueNumber) {
            goToIssue()
        }
        dispatch(searchRca(createFormData(1, NUMBER_OF_FETCHED_ITEMS)))
    }
    const handleLoadMoreIssue = () => {
        const { loading, suggestions, issueNumber } = linkRca
        if (loading) {
            return
        }
        if (!issueNumber) {
            goToIssue()
        }
        const page =
            NUMBER_OF_FETCHED_ITEMS <= suggestions.length
                ? Math.ceil(suggestions.length / NUMBER_OF_FETCHED_ITEMS) + 1
                : 1
        dispatch(loadMoreRca(createFormData(page, NUMBER_OF_FETCHED_ITEMS)))
    }
    const handleCopyRca = (item: IssueRcaPreview) =>
        dispatch(copyRca(issueId, item.issueId, issueIdFromUrl))

    return (
        <Content
            loadingDesc={linkRca.fullLoadingDesc}
            loading={linkRca.copyingRca}
            error={linkRca.error}
            onRetry={() => {
                dispatch(clearError())
                dispatch(initSearchRcaState(number, resolvingUnitId))
            }}
        >
            <Helmet>
                <title>Search RCA</title>
            </Helmet>
            <div className={classes.container}>
                {linkRca.error ? null : (
                    <>
                        <div
                            className={classes.header}
                            {...LINK_ISSUE_VIEW_PAGE.SEARCH_BAR_CONTAINER}
                        >
                            <SearchPanel
                                placeholder="Search by Issue Number…"
                                onEnter={handleSearch}
                                searchText={specificIssueNumber}
                                disabled={linkRca.loading}
                                onChange={(specificIssueNumber) =>
                                    dispatch(setSearchText(specificIssueNumber))
                                }
                                onClearText={() => dispatch(setSearchText(''))}
                                selectedFiltersCount={
                                    linkRca.selectedFiltersCount
                                }
                            >
                                <form autoComplete="off">
                                    <DivisionSelect
                                        disabled={linkRca.loading}
                                        selectedDivision={
                                            linkRca.selectedBusiness
                                        }
                                        onDivisionChanged={(item) =>
                                            dispatch(selectBusiness(item))
                                        }
                                    />
                                    <BusinessUnitSelect
                                        disabled={linkRca.loading}
                                        division={linkRca.selectedBusiness}
                                        selectedBusinessUnit={
                                            linkRca.selectedBusinessLine
                                        }
                                        onBusinessUnitChanged={(item) =>
                                            dispatch(selectBusinessUnit(item))
                                        }
                                    />
                                    <ProductGroupSelect
                                        disabled={linkRca.loading}
                                        division={linkRca.selectedBusiness}
                                        businessUnit={
                                            linkRca.selectedBusinessLine
                                        }
                                        selectedProductGroup={
                                            linkRca.selectedProductGroup
                                        }
                                        onProductGroupChanged={(item) =>
                                            dispatch(selectProductGroup(item))
                                        }
                                    />
                                    <ActivitySelect
                                        selectedActivity={
                                            linkRca.selectedActivity
                                        }
                                        onActivityChanged={(item) =>
                                            dispatch(selectActivity(item))
                                        }
                                    />
                                    <CountrySelect
                                        selectedCountry={
                                            linkRca.selectedCountry
                                        }
                                        onCountryChanged={(item) =>
                                            dispatch(selectCountry(item))
                                        }
                                    />
                                    <IssueTypeSelect
                                        selectedIssueType={
                                            linkRca.selectedIssueType
                                        }
                                        onIssueTypeChanged={(item) =>
                                            dispatch(selectIssueType(item))
                                        }
                                    />
                                </form>

                                <AccordionActions className={classes.actions}>
                                    <Button
                                        {...LINK_ISSUE_VIEW_PAGE.ACTION_BUTTONS
                                            .CLEAR}
                                        onClick={() => dispatch(clearSearch())}
                                    >
                                        Clear
                                    </Button>
                                    <Button
                                        {...LINK_ISSUE_VIEW_PAGE.ACTION_BUTTONS
                                            .SEARCH}
                                        onClick={handleSearch}
                                        color="secondary"
                                    >
                                        Search
                                    </Button>
                                </AccordionActions>
                            </SearchPanel>
                        </div>
                        {linkRca.suggestions &&
                            (0 < linkRca.suggestions.length ? (
                                <div className={classes.searchResults}>
                                    {linkRca.suggestions &&
                                        linkRca.suggestions.map((item) => (
                                            <LinkIssueItem
                                                destinationId={issueIdFromUrl}
                                                issueStatusId={
                                                    item.issueStatusId
                                                }
                                                item={item}
                                                key={item.issueId}
                                                onCopyRca={() =>
                                                    handleCopyRca(item)
                                                }
                                            />
                                        ))}
                                </div>
                            ) : (
                                <NoResults
                                    {...LINK_ISSUE_VIEW_PAGE.NO_ISSUES_FOUND_LABEL}
                                    title="No issues found"
                                    subTitle="Change search criteria and try again."
                                />
                            ))}
                        {linkRca.loading && (
                            <div className={classes.SearchLoader}>
                                <CircularProgress color="secondary" size={28} />
                                <span style={{ marginLeft: 16 }}>
                                    {linkRca.loadingDesc}
                                </span>
                            </div>
                        )}
                        {!linkRca.loading && canLoadMoreItems && (
                            <RoundedButton
                                {...LINK_ISSUE_VIEW_PAGE.LOAD_MORE_BUTTON}
                                variant="contained"
                                className={classes.loadMore}
                                onClick={handleLoadMoreIssue}
                            >
                                Load more…
                            </RoundedButton>
                        )}
                    </>
                )}
            </div>
        </Content>
    )
}

export default LinkIssueView
