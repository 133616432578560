export const getTemplate = (content: string): string => `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <title>A Simple Responsive HTML Email</title>
        <style type="text/css">
            body {
                margin: 0;
                padding: 16px;
                min-width: 100% !important;
                box-sizing: border-box;
                font-family: abbvoice, sans-serif;
            }
            .logo {
                width: 66px;
                height: 25px;
            }
            .content {
                width: 100%;
                text-align: right;
            }
            h1 {
                font-size: 2rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 2.5rem;
            }
            h1::before {
                color: #ff000f;
                content: '—';
                display: block;
                font-size: inherit;
            }
            h2 {
                font-size: 1.75rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 2.25rem;
            }
            h2::before {
                color: #ff000f;
                content: '—';
                display: block;
                font-size: inherit;
            }
            h3 {
                font-size: 1.5rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 2rem;
            }
            h3::before {
                color: #ff000f;
                content: '—';
                display: block;
                font-size: inherit;
            }
            h4 {
                font-size: 1.25rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 1.75rem;
            }
            h4::before {
                color: #ff000f;
                content: '—';
                display: block;
                font-size: inherit;
            }
            h5 {
                font-size: 2rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 2.5rem;
            }

            h6 {
                font-size: 2rem;
                font-family: abbvoice, sans-serif;
                font-weight: 500;
                line-height: 2.5rem;
            }
        </style>
    </head>
    <body>
        <table width="100%" cellpadding="0" cellspacing="0">
            <tr>
                <td>
                    <table class="content" cellpadding="0" cellspacing="0">
                        <tr>
                            <td>
                                <img
                                    class="logo"
                                    src="/assets/abb-logo.png"
                                    alt="ABB Logo"
                                />
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>${content}</td>
            </tr>
        </table>
    </body>
</html>

`
