import { DASHBOARD_LOAD_ISSUES_CALL } from '../actionTypes'
import { takeLatest, put, call } from 'redux-saga/effects'
import { getMyDashboard, getMyDashboard2 } from 'api'
import {
    loadMyActionsDone,
    loadMyActionsError,
    MY_ACTIONS_LOADING,
} from 'store/myActions'
import {
    loadMyIssuesDone,
    loadMyIssuesError,
    MY_ISSUES_LOAD_CALL,
} from 'store/myIssues'
import {
    MyAction,
    MyAction2,
    MyDashboard,
    MyDashboard2,
    MyIssue,
    MyIssue2,
} from 'types/myDashboard'
import { AxiosResponse } from 'axios'
import { NEW_DASHBOARD } from 'features'

export function* loadMyDashboard() {
    yield takeLatest(DASHBOARD_LOAD_ISSUES_CALL, function* () {
        try {
            yield put({ type: MY_ACTIONS_LOADING })
            yield put({ type: MY_ISSUES_LOAD_CALL })
            let myActionsItems: (MyAction | MyAction2)[] = []
            let myIssuesItems: (MyIssue | MyIssue2)[] = []
            if (NEW_DASHBOARD) {
                const { data: myDashboard } = (yield call(
                    getMyDashboard2
                )) as AxiosResponse<MyDashboard2>
                const { issueData, myActions, myIssues } = myDashboard
                myActionsItems = myActions.map((meta) => ({
                    ...meta,
                    ...issueData[meta.issueId],
                }))
                myIssuesItems = myIssues.map((meta) => ({
                    ...meta,
                    ...issueData[meta.issueId],
                }))
            } else {
                const { data } = (yield call(
                    getMyDashboard
                )) as AxiosResponse<MyDashboard>
                myActionsItems = data.myActions
                myIssuesItems = data.myIssues
            }
            yield put(loadMyActionsDone(myActionsItems))
            yield put(loadMyIssuesDone(myIssuesItems))
        } catch (error) {
            yield put(loadMyActionsError(error))
            yield put(loadMyIssuesError(error))
        }
    })
}
