import { Button } from '@mui/material'
import { ConfirmContainmentActionDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { verifyContainment } from 'store/myActions'
import { VerifyContainmentActionQuery } from 'types/actions'
import { TileActionsProps } from '..'

export const ToVerifyContainmentActions = (props: TileActionsProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { myActionMeta } = props
    const handleVerifyContainmentAction = (
        formData: VerifyContainmentActionQuery
    ) => {
        setOpen(false)
        dispatch(verifyContainment(formData))
    }
    return (
        <>
            <Button
                color="secondary"
                onClick={() => setOpen(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CONFIRM_CONTAINMENT_ACTIONS}
                eventName="Confirm Containment Actions on dashboard"
            >
                Confirm Containment Actions
            </Button>
            <ConfirmContainmentActionDialog
                open={open}
                issueId={myActionMeta.issueId}
                rowVersion={myActionMeta.rowVersion}
                onClose={() => setOpen(false)}
                onConfirm={(formData) =>
                    handleVerifyContainmentAction(formData)
                }
            />
        </>
    )
}
