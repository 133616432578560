import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(2),
        },
    }),
    { name: 'TileSection' }
)

export const TileSection: FC<HtmlHTMLAttributes<HTMLDivElement>> = ({
    children,
    className,
    ...other
}) => {
    const classes = useStyles()
    return (
        <div className={clsx(className, classes.root)} {...other}>
            {children}
        </div>
    )
}
