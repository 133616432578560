import { BaseBar, RoundedButton, TextField } from '../components'

import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import Axios, { AxiosRequestConfig } from 'axios'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { OVERRIDE_TOKEN_KEY } from '../configs/authProvider'
import { SIGN_IN_PAGE } from '../consts/selectorIds'
import { showSnackbar } from '../store/app'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'column',
            width: 600,
            marginTop: theme.spacing(4),
            'box-sizing': 'border-box',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: theme.spacing(4),
            backgroundColor: theme.palette.common.white,
            '@media (max-width: 600px)': {
                width: '100vw',
            },
        },
        info: {
            maxWidth: 320,
            textAlign: 'center',
            marginBottom: theme.spacing(2),
        },
        subHeadline: {
            marginBottom: theme.spacing(4),
        },
    }),
    { name: 'SigninMock' }
)

interface SigninMockState {
    error?: string
    email: string
    emailError?: string
    password: string
    passwordError?: string
    loading: boolean
}

const axios = Axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
})

export const OVERRIDE_GIVEN_NAME = 'override-given-name'
export const OVERRIDE_FAMILY_NAME = 'override-family-name'

const SigninMock = (props: any) => {
    const dispatch = useDispatch()
    const [state, setState] = useState<SigninMockState>({
        error: null,
        email: '',
        emailError: null,
        password: '',
        passwordError: null,
        loading: false,
    })
    const classes = useStyles(props)
    const handleLogin = async () => {
        let isValid = true
        if (state.email === '') {
            isValid = false
            setState((prev) => ({ ...prev, emailError: 'Email is required' }))
        } else {
            setState((prev) => ({ ...prev, emailError: '' }))
        }
        if (state.password === '') {
            isValid = false
            setState((prev) => ({
                ...prev,
                passwordError: 'Password is required',
            }))
        } else {
            setState((prev) => ({ ...prev, passwordError: '' }))
        }
        if (isValid) {
            try {
                const config: AxiosRequestConfig = { headers: {} }
                config.headers['tester-secret'] = state.password
                const { data: token } = await axios.post(
                    '/tokenGenerator',
                    {
                        email: state.email,
                        givenName:
                            localStorage.getItem(OVERRIDE_GIVEN_NAME) ||
                            'Janusz',
                        familyName:
                            localStorage.getItem(OVERRIDE_FAMILY_NAME) ||
                            'Tester',
                    },
                    config
                )
                localStorage.setItem(OVERRIDE_TOKEN_KEY, token)
                window.location.reload()
            } catch (err) {
                dispatch(showSnackbar('Cannot login!', true))
            }
        }
    }
    return (
        <>
            <BaseBar />
            <div className={classes.content}>
                <Helmet>
                    <title>Sign in to CCRP</title>
                </Helmet>
                <section className={classes.info}>
                    <Typography variant="h4">Welcome to CCRP</Typography>
                    <Typography component="p" className={classes.subHeadline}>
                        The tool you already know got a new look! Easy way to
                        perform your everyday work
                    </Typography>
                    <TextField
                        id="email"
                        label="Email"
                        required
                        value={state.email}
                        error={Boolean(state.emailError)}
                        helperText={state.emailError}
                        onChange={(e: any) =>
                            setState((prev) => ({
                                ...prev,
                                email: e.target.value,
                                emailError: '',
                            }))
                        }
                    />
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        required
                        value={state.password}
                        error={Boolean(state.passwordError)}
                        helperText={state.passwordError}
                        onChange={(e: any) => {
                            setState((prev) => ({
                                ...prev,
                                password: e.target.value,
                                passwordError: '',
                            }))
                        }}
                    />
                </section>
                <RoundedButton
                    onClick={handleLogin}
                    color="secondary"
                    variant="contained"
                    {...SIGN_IN_PAGE.LOG_IN_BUTTON}
                >
                    Log in
                </RoundedButton>
            </div>
        </>
    )
}

export default SigninMock
