import ErrorIcon from '@mui/icons-material/Error'
import { Tooltip, Typography } from '@mui/material'
import React, { HtmlHTMLAttributes, ReactElement } from 'react'
import theme from 'styles/themeBase'

interface IssueTooltipProps extends HtmlHTMLAttributes<HTMLDivElement> {
    text: string[]
    icon?: ReactElement
}

export const IssueTooltip = (props: IssueTooltipProps) => {
    const [open, setOpen] = React.useState(false)
    const {
        icon = (
            <ErrorIcon
                style={{ color: theme.palette.grey[600], fontSize: 18 }}
            />
        ),
        text,
        ...other
    } = props

    const handleOpen = () => {
        text ? setOpen(true) : setOpen(false)
    }
    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Tooltip
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            arrow
            title={
                <Typography variant="caption">
                    {text &&
                        text.map((i) => (
                            <p key={i}>
                                {i}
                                <br />
                            </p>
                        ))}
                </Typography>
            }
            {...other}
        >
            {icon}
        </Tooltip>
    )
}
