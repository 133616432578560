import { HtmlHTMLAttributes, useMemo } from 'react'
import clsx from 'clsx'
import { Employee } from 'types/issueTypes'
import { arrayToStringLine, createArrayWithoutNulls } from 'utils/helpers'
import { FieldItem } from '..'
import { getEmployeeOrganizationInfoName } from 'utils'

export interface EmployeeItemProps extends HtmlHTMLAttributes<HTMLDivElement> {
    details: Employee
}

export const formatEmployee = (employee: Employee): string =>
    clsx(employee?.email, employee?.fullName && `(${employee.fullName})`)

export const EmployeeItem = (props: EmployeeItemProps) => {
    const { className: classNameProp, details, ...other } = props
    const tags = useMemo(() => {
        const tags = createArrayWithoutNulls(
            details?.countryCode,
            details?.organizationInfo
                ? getEmployeeOrganizationInfoName(details?.organizationInfo)
                : '',
            details?.phone
        )
        return arrayToStringLine(tags)
    }, [details])
    return (
        <FieldItem name={formatEmployee(details)} caption={tags} {...other} />
    )
}

export default EmployeeItem
