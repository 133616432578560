import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
} from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { Dialog } from '.'
import { HIGH_IMPACT_CONFIRM_DIALOG } from '../../consts/selectorIds'
import TextField from '../Base/TextField'

export interface HighImpactActionDialoProps extends DialogProps {
    title: string
    message: string
    confirmLabel: string
    closeLabel: string
    onConfirm: (motivation: string) => void
}

export const HighImpactActionDialog = (props: HighImpactActionDialoProps) => {
    const {
        open,
        title,
        message,
        confirmLabel,
        closeLabel,
        onConfirm,
        onClose,
        ...other
    } = props

    const [motivation, setMotivation] = useState('')

    return (
        <Dialog open={open} {...other}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent {...HIGH_IMPACT_CONFIRM_DIALOG.CONTENT_CONTAINER}>
                <DialogContentText>{message}</DialogContentText>
                <TextField
                    autoFocus
                    id="motivation"
                    label={'Please provide motivation for your decision'}
                    rows={2}
                    multiline
                    fullWidth
                    value={motivation}
                    onChange={(e: any) => setMotivation(e.target.value)}
                ></TextField>
            </DialogContent>
            <DialogActions
                {...HIGH_IMPACT_CONFIRM_DIALOG.ACTION_BUTTONS_CONTAINER}
            >
                <Button
                    {...HIGH_IMPACT_CONFIRM_DIALOG.ACTION_BUTTONS.CANCEL}
                    onClick={(e) => onClose(e, 'backdropClick')}
                >
                    {closeLabel}
                </Button>
                <Button
                    {...HIGH_IMPACT_CONFIRM_DIALOG.ACTION_BUTTONS
                        .CHANGE_TARGET_DATE}
                    color="secondary"
                    onClick={() => {
                        onConfirm(motivation)
                    }}
                >
                    {confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default HighImpactActionDialog
