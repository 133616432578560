import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormControlProps,
    InputLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChangeEvent } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { SpecialCasesState } from 'types/reportTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            marginTop: theme.spacing(3),
        },
        item: {
            marginRight: theme.spacing(2),
        },
    }),
    { name: 'SpecialCases' }
)

export interface SpecialCasesProps
    extends Omit<
        FormControlProps,
        'children' | 'fullWidth' | 'variant' | 'onChange'
    > {
    value: SpecialCasesState
    onChange: (value: SpecialCasesState) => void
}

export const SpecialCases = (props: SpecialCasesProps) => {
    const { value, onChange, ...other } = props
    const classes = useStyles()
    const handleCheckHI = (
        e: ChangeEvent<HTMLInputElement>,
        checked: boolean
    ) =>
        onChange({
            ...value,
            hasHighImpact: checked,
            rcaIsRequired: checked,
            verificationRequired: checked,
        })
    const createHandleCheck =
        (key: keyof SpecialCasesState) =>
        (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (value.hasHighImpact) {
                onChange({
                    ...value,
                    containmentActionsRequired: false,
                    hasHighImpact: false,
                    rcaIsRequired: false,
                    verificationRequired: false,
                })
            } else {
                onChange({
                    ...value,
                    [key]: checked,
                })
            }
        }
    return (
        <FormControl fullWidth variant="outlined" {...other}>
            <InputLabel>Special cases</InputLabel>
            <div className={classes.content}>
                <FormControlLabel
                    className={classes.item}
                    control={
                        <Checkbox
                            checked={Boolean(value.hasHighImpact)}
                            onChange={handleCheckHI}
                        />
                    }
                    label="High Impact"
                />
                <FormControlLabel
                    className={classes.item}
                    control={
                        <Checkbox
                            checked={Boolean(value.rcaIsRequired)}
                            onChange={createHandleCheck('rcaIsRequired')}
                            sx={{
                                '&.Mui-checked': {
                                    color: 'red',
                                },
                            }}
                        />
                    }
                    label="RCA Required"
                />
                <FormControlLabel
                    className={classes.item}
                    control={
                        <Checkbox
                            checked={Boolean(value.verificationRequired)}
                            onChange={createHandleCheck('verificationRequired')}
                            color="secondary"
                        />
                    }
                    label="Verification Required"
                />
                <FormControlLabel
                    className={classes.item}
                    control={
                        <Checkbox
                            checked={Boolean(value.containmentActionsRequired)}
                            onChange={(e, checked) =>
                                onChange({
                                    ...value,
                                    containmentActionsRequired: checked,
                                })
                            }
                        />
                    }
                    label="Containment Actions"
                />
            </div>
        </FormControl>
    )
}
