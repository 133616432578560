import { getPgByResolvingUnitAndId } from 'api'
import { StartAdornmentInfo } from 'Issue/components'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import theme from 'styles/themeBase'

interface PgRcaCategoryStartAdornmentProps {
    resolvingUnitId: number
    pgRcaCategoryId: number
}

const PgRcaCategoryStartAdornment = (
    props: PgRcaCategoryStartAdornmentProps
) => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const { resolvingUnitId, pgRcaCategoryId } = props

    const loadData = () => {
        if (title || loading) return
        setLoading(true)
        getPgByResolvingUnitAndId(resolvingUnitId, pgRcaCategoryId)
            .then((res) => {
                const { itemPath } = res.data
                const fullPath = [...itemPath]
                const parsedPath = fullPath.join(' / ')
                setTitle(parsedPath)
            })
            .catch((err) => {
                dispatch(showSnackbar('PG RCA Category failed to load', true))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const isVisible = resolvingUnitId && pgRcaCategoryId

    return isVisible ? (
        // <HelpTipWithLoading
        //     onClick={loadData}
        //     title={title}
        //     loading={loading}
        // />
        <StartAdornmentInfo
            style={{ marginRight: theme.spacing(1) }}
            details={title}
            onLoadData={loadData}
            loading={loading}
        />
    ) : null
}

export default PgRcaCategoryStartAdornment
