import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        return {
            content: {
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                flexDirection: 'column',
                maxWidth: 600,
                boxSizing: 'border-box',
                marginLeft: 'auto',
                marginRight: 'auto',
                padding: theme.spacing(4),
            },
            info: {
                maxWidth: 600,
                textAlign: 'center',
                padding: theme.spacing(0, 2),
                marginBottom: theme.spacing(3),
            },
        }
    },
    { name: 'ErrorPage' }
)

export default useStyles
