import { Route, Switch } from 'react-router'
import { AllInOneRaport, NCAllInOneRaport } from '.'
import * as ROUTES from './routes'

export const ReportsRoot = () => (
    <Switch>
        <Route
            path={ROUTES.ALL_IN_ONE_REPORT}
            render={() => <AllInOneRaport />}
            exact
        />
        <Route
            path={ROUTES.NC_ALL_IN_ONE_REPORT}
            render={() => <NCAllInOneRaport />}
            exact
        />
    </Switch>
)

export default ReportsRoot
