import { Build, Memory, Work } from '@mui/icons-material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { dataTestId } from 'utils'
import { ExpandTile, useTilesStyles } from '.'
import {
    Button,
    DataArea,
    NavButton,
    ResolutionUnitValue,
    ReturnToIssueOwnerDialog,
    ValueView,
} from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { returnToIO } from '../../store/myActions/index'
import { ReturnToIssueOwnerQuery } from '../../types/actions'
import { MyAction } from '../../types/myDashboard'
import IssueInfoBaseRow from './IssueInfoBaseRow'

export interface ToAssignProps {
    actionIssue: MyAction
}

export const ToAssign = (props: ToAssignProps) => {
    const [open, setOpen] = useState<boolean>(false)

    const classes = useTilesStyles(props)
    const dispatch = useDispatch()
    const { actionIssue, ...other } = props
    const resolvingUnit = actionIssue?.resolvingUnit
    const handleReturnToIssue = (formData: ReturnToIssueOwnerQuery) => {
        setOpen(false)
        dispatch(returnToIO(formData))
    }

    const actions = (
        <>
            <Button
                onClick={() => setOpen(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .RETURN_TO_ISSUE_OWNER}
                eventName="Return not assigne issue to Issue Owner"
            >
                Return to Issue Owner
            </Button>
            <NavButton
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.ASSIGN}
                color="secondary"
                eventName="Assign issue on dashboard"
                linkProps={{ to: `/issue/${actionIssue.issueId}` }}
            >
                Assign
            </NavButton>
        </>
    )
    return (
        <>
            <ExpandTile
                {...other}
                {...actionIssue}
                showActionStatus={true}
                actionCode={actionIssue.action.actionCode}
                issueId={actionIssue.issueId}
                issueNumber={actionIssue.issueNumber}
                collapsedSize="64px"
                isOverdue={actionIssue.action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                actions={actions}
            >
                <IssueInfoBaseRow
                    deadlineDate={actionIssue?.action?.deadlineDate}
                    issueType={actionIssue.issueType}
                />
                <ResolutionUnitValue
                    resolvingUnit={resolvingUnit}
                    {...dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT')}
                />
                <DataArea>
                    {actionIssue.productName && (
                        <ValueView
                            className={classes.cell}
                            icon={<Memory className={classes.labelIcon} />}
                            label="Product"
                            value={actionIssue.productName}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.PRODUCT}
                        />
                    )}
                    {actionIssue.customerName && (
                        <ValueView
                            className={classes.cell}
                            icon={<Work className={classes.labelIcon} />}
                            label="Customer"
                            value={actionIssue.customerName}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.CUSTOMER}
                        />
                    )}
                    <ValueView
                        className={classes.singleRow}
                        icon={<Build className={classes.labelIcon} />}
                        label="Failure mode"
                        value={actionIssue.failureModeName}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.FAILURE_MODE}
                    />
                </DataArea>
            </ExpandTile>
            <ReturnToIssueOwnerDialog
                open={open}
                issueId={actionIssue.issueId}
                number={actionIssue.issueNumber}
                rowVersion={actionIssue.rowVersion}
                onClose={() => setOpen(false)}
                onReturnIssue={(formData) => handleReturnToIssue(formData)}
            />
        </>
    )
}

export default ToAssign
