import MuiTab, { TabProps } from '@mui/material/Tab'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            color: theme.palette.common.black,
        },
        selected: {
            color: theme.palette.common.black,
            '&$textColorPrimary': {
                color: theme.palette.common.black,
            },
        },
        textColorPrimary: {
            color: theme.palette.common.black,
        },
    }),
    { name: 'Tab' }
)

export const Tab = (props: TabProps) => {
    const classes = useStyles()
    return <MuiTab {...props} classes={classes} />
}
Tab.muiName = 'Tab'

export default Tab
