import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { TimelineBar, TimelineConnector } from 'components'
import { ProcessFlowViewProps, useIssueProcessFlow } from '.'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        bar: {
            display: 'inline-flex',
            alignItems: 'center',
            position: 'relative',
            width: '100%',
        },
        connector: {
            display: 'inline-flex',
            alignItems: 'center',
            backgroundColor: theme.palette.grey[100],
            width: (props: StyleProps) =>
                `calc(${100 / (props.stepsCount * 2)}% - 14px) !important`,
        },
        desc: {
            display: 'flex',
            marginTop: theme.spacing(2),
            flexDirection: 'row',
        },
        item: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            boxSizing: 'border-box',
            margin: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
            width: (props: StyleProps) => 100 / props.stepsCount + '%',
            minHeight: 24,
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        role: {
            ...theme.typography.caption,
            color: theme.palette.grey[400],
        },
    }),
    { name: 'HorizontalProcessFlowView' }
)

export interface StyleProps {
    stepsCount: number
}

export const HorizontalProcessFlowView = (props: ProcessFlowViewProps) => {
    const {
        className: classNameProp,
        highImpact,
        issueStatus,
        issueType,
        solutionWillBeValidatedByCoordinator,
        isWaitingForExecution,
        dates,
        ...other
    } = props

    const [processFlow, currentStep] = useIssueProcessFlow(
        issueStatus,
        highImpact,
        solutionWillBeValidatedByCoordinator,
        issueType,
        isWaitingForExecution,
        dates
    )
    const classes = useStyles({
        stepsCount: processFlow.length,
    } as StyleProps)
    return (
        <div className={clsx(classes.root, classNameProp)} {...other}>
            <div className={classes.desc}>
                {processFlow.map((p) => (
                    <div key={p.order} className={classes.item}>
                        {p.statusName}
                    </div>
                ))}
            </div>
            <div className={classes.bar}>
                <TimelineConnector className={classes.connector} />
                <TimelineBar
                    orientation="horizontal"
                    className={classes.bar}
                    steps={processFlow.length}
                    currentStep={currentStep}
                    radius={12}
                />
                <TimelineConnector className={classes.connector} />
            </div>
            <div className={classes.desc}>
                {processFlow.map((p) => (
                    <div key={p.order} className={classes.item}>
                        <span className={classes.role}>{p.roleName}</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HorizontalProcessFlowView
