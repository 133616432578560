import {
    assignActionPlanItem,
    completeActionPlanItem,
    saveActionPlanItem,
} from 'api'
import { FC, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory } from 'react-router'
import { ISSUE_ACTION_PLAN_ITEM } from 'routes'
import { loadActionPlan } from 'store/issue'
import {
    ActionItem,
    ActionItemActions,
    ActionItemStatus,
    ActionPlanStatusEnum,
} from 'types/actionPlanTypes'
import { ApiError } from 'types/errors'
import {
    ActionPlanDashboardColumn,
    ActionPlanDashboardItems,
    DashboardInitState,
    getInitQueryForItemContainer,
} from '..'
import {
    ActionPlanContext,
    ActionPlanContextProps,
    DialogStateFields,
} from './ActionPlanContext'
import { ActionPlanDialog } from './ActionPlanDialog'

export const getInitDataForDashboard = (): DashboardInitState => ({
    items: {},
    columns: {
        [ActionPlanStatusEnum.Draft]: {
            id: ActionPlanStatusEnum.Draft,
            title: 'Draft',
            itemsIds: [],
        },
        [ActionPlanStatusEnum.InProgress]: {
            id: ActionPlanStatusEnum.InProgress,
            title: 'In progress',
            itemsIds: [],
        },
        [ActionPlanStatusEnum.Closed]: {
            id: ActionPlanStatusEnum.Closed,
            title: 'Closed',
            itemsIds: [],
        },
    },
    columnOrder: [
        ActionPlanStatusEnum.Draft,
        ActionPlanStatusEnum.InProgress,
        ActionPlanStatusEnum.Closed,
    ],
})

const ActionPlanContextProvider: FC<unknown> = ({ children }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const updateDashboardState = ({
        items,
        columns,
    }: Partial<DashboardInitState>) => {
        setState((prev) => ({
            ...prev,
            dashboardState: {
                ...prev.dashboardState,
                items: items ? items : prev.dashboardState.items,
                columns: columns ? columns : prev.dashboardState.columns,
            },
        }))
    }

    const updateCurrentActionItem = (actionItem: Partial<ActionItem>) => {
        setState((prev) => ({
            ...prev,
            currentActionItem: {
                ...prev.currentActionItem,
                ...actionItem,
                accessMatrix: [...(actionItem?.accessMatrix ?? [])],
            },
        }))
    }

    const setError = (error: unknown) => {
        setState((prev) => ({
            ...prev,
            error,
        }))
    }

    const setLoading = (loading: boolean, loadingDesc: string = null) => {
        setState((prev) => ({
            ...prev,
            loading,
            loadingDesc,
        }))
    }

    const catchErrorsAndLoading = async (
        cb: () => void,
        loadingDesc: string
    ) => {
        try {
            setState((prev) => ({
                ...prev,
                loading: true,
                loadingDesc,
            }))
            await cb()
        } catch (err) {
            setError(err)
        } finally {
            setLoading(false)
        }
    }

    const openDialog = (
        func: ActionItemActions,
        actionItem: ActionItem,
        fields?: DialogStateFields
    ) => {
        setState((prev) => ({
            ...prev,
            dialogState: {
                ...prev.dialogState,
                visible: true,
                fields,
                func,
                actionItem,
            },
        }))
    }

    const closeDialog = () => {
        setState((prev) => ({
            ...prev,
            dialogState: {
                ...prev.dialogState,
                visible: false,
            },
        }))
    }

    const updateDashboardPosition = (result: Partial<DropResult>) => {
        const { source, destination, draggableId } = result
        setState((prev) => {
            const { columns } = prev.dashboardState

            const column: ActionPlanDashboardColumn =
                columns[source.droppableId]
            const finish: ActionPlanDashboardColumn =
                columns[destination.droppableId]

            const startTaskIds = [...column.itemsIds]
            startTaskIds.splice(source.index, 1)
            const newStart = {
                ...column,
                itemsIds: startTaskIds,
            }
            const finishTaskIds = [...finish.itemsIds]
            finishTaskIds.splice(destination.index, 0, parseInt(draggableId))
            const newFinish = {
                ...finish,
                itemsIds: finishTaskIds,
            }

            return {
                ...prev,
                dashboardState: {
                    ...prev.dashboardState,
                    columns: {
                        ...columns,
                        [newStart.id]: newStart,
                        [newFinish.id]: newFinish,
                    },
                },
            }
        })
    }

    const updateDashboardPositionWithoutDropResult = (
        source: ActionPlanStatusEnum,
        destination: ActionPlanStatusEnum,
        actionItem: ActionItem
    ) => {
        updateDashboardPosition({
            source: {
                droppableId: source,
                index: 0,
            },
            destination: {
                droppableId: destination,
                index: 0,
            },
            draggableId: `${actionItem.actionItemId}`,
        })
    }

    const updateActionItemInDashboard = (updatedActionItem: ActionItem) => {
        setState((prev) => {
            const updatedItems: ActionPlanDashboardItems = {
                ...prev.dashboardState.items,
            }

            updatedItems[updatedActionItem.actionItemId] = {
                ...updatedActionItem,
            }

            return {
                ...prev,
                dashboardState: {
                    ...prev.dashboardState,
                    items: updatedItems,
                },
            }
        })
    }

    const updateDashboardItemAndChangePosition = (
        source: ActionPlanStatusEnum,
        destination: ActionPlanStatusEnum,
        dropResult: DropResult,
        updatedActionItem: ActionItem
    ) => {
        updateActionItemInDashboard(updatedActionItem)

        if (dropResult) {
            updateDashboardPosition(dropResult)
        }

        if (!dropResult) {
            updateDashboardPositionWithoutDropResult(
                source,
                destination,
                updatedActionItem
            )
        }
    }

    const removeActionItemFromDashboardState = (actionItem: ActionItem) => {
        const actionItemId = actionItem.actionItemId

        setState((prev) => {
            const updatedItems = { ...prev.dashboardState.items }
            delete updatedItems[actionItemId]

            const { itemsIds } = prev.dashboardState.columns.Draft
            const updatedIds = itemsIds.filter((id) => id !== actionItemId)

            return {
                ...prev,
                dashboardState: {
                    ...prev.dashboardState,
                    items: updatedItems,
                    columns: {
                        ...prev.dashboardState.columns,
                        Draft: {
                            ...prev.dashboardState.columns.Draft,
                            itemsIds: updatedIds,
                        },
                    },
                },
            }
        })
    }

    const updateActionItemsDashboard = (actionItems: ActionItem[]) => {
        const items = {}
        actionItems.forEach((item) => {
            items[item.actionItemId] = {
                ...item,
            }
        })

        const { columns } = getInitDataForDashboard()

        actionItems.forEach(({ actionStatus, actionItemId }) => {
            if (actionStatus === ActionItemStatus.ToDo) {
                columns.Draft.itemsIds.push(actionItemId)
            } else if (
                actionStatus === ActionItemStatus.InProgress ||
                actionStatus === ActionItemStatus.InProgressOverdue
            ) {
                columns.InProgress.itemsIds.push(actionItemId)
            } else if (actionStatus === 3) {
                columns.Closed.itemsIds.push(actionItemId)
            }
        })

        updateDashboardState({ items, columns })
    }

    const [state, setState] = useState<ActionPlanContextProps>({
        updateActionItemsDashboard,
        removeActionItemFromDashboardState,
        updateDashboardItemAndChangePosition,
        dashboardState: getInitDataForDashboard(),
        updateDashboardState,
        dashboardStatus: 0,
        currentActionItem: getInitQueryForItemContainer(),
        loading: null,
        loadingDesc: '',
        error: null,
        setError,
        dialogState: {
            visible: false,
            fields: {
                title: '',
                description: '',
                requiredReason: null,
                buttonName: '',
                source: null,
                destination: null,
                dropResult: null,
                'data-test-id': '',
            },
            func: null,
            actionItem: null,
        },
        catchErrorsAndLoading,
        closeDialog,
        updateCurrentActionItem,
        openActionItem: (
            issueIdOrNumber: number | string,
            actionId?: number
        ) => {
            if (!actionId) {
                updateCurrentActionItem(getInitQueryForItemContainer())
            }

            history.push(
                generatePath(ISSUE_ACTION_PLAN_ITEM, {
                    id: issueIdOrNumber,
                    actionPlanId: actionId ? actionId : 'new',
                })
            )
        },
        assignActionItem: async (
            actionItem: ActionItem,
            dropResult?: DropResult
        ) => {
            catchErrorsAndLoading(async () => {
                try {
                    const { data } = await assignActionPlanItem(actionItem)
                    const isNew = 0 <= window.location.pathname.search('new')
                    const { actionStatus, actionItemId } = data
                    if (actionStatus && isNew) {
                        history.replace(
                            generatePath(ISSUE_ACTION_PLAN_ITEM, {
                                id: data.issueId,
                                actionPlanId: actionItemId,
                            })
                        )
                        dispatch(loadActionPlan(actionItem?.issueId))
                    }
                    updateCurrentActionItem(data)
                    updateDashboardItemAndChangePosition(
                        ActionPlanStatusEnum.Draft,
                        ActionPlanStatusEnum.InProgress,
                        dropResult,
                        data
                    )
                } catch (error: any) {
                    const err = error as ApiError
                    if (err.error === 'Action names have to be unique') {
                        throw Object.assign({ error: err.error })
                    }
                    throw err
                }
            }, 'Assigning action item')
        },

        rejectActionItem: async (
            actionItem: ActionItem,
            dropResult?: DropResult
        ) => {
            openDialog(ActionItemActions.Reject, actionItem, {
                title: 'Reject action',
                description:
                    'You are about to reject this action. Message will be sent to Action Creator and action status will change to Draft. You need to provide a reason for doing so.',
                requiredReason: 'RejectReason',
                buttonName: 'Reject',
                source: ActionPlanStatusEnum.InProgress,
                destination: ActionPlanStatusEnum.Draft,
                dropResult,
                'data-test-id': 'REASON_REJECT',
            })
        },
        completeActionItem: async (
            actionItem: ActionItem,
            dropResult?: DropResult
        ) => {
            catchErrorsAndLoading(async () => {
                const res = await completeActionPlanItem(actionItem)
                const updatedActionItem = res.data
                updateCurrentActionItem(updatedActionItem)

                updateDashboardItemAndChangePosition(
                    ActionPlanStatusEnum.InProgress,
                    ActionPlanStatusEnum.Closed,
                    dropResult,
                    updatedActionItem
                )
                dispatch(loadActionPlan(actionItem?.issueId))
            }, 'Completing action item')
        },
        reopenActionItem: async (
            actionItem: ActionItem,
            dropResult?: DropResult
        ) => {
            openDialog(ActionItemActions.Reopen, actionItem, {
                title: 'Reopen action',
                description:
                    'You are about to reopen this action. Message will be sent to Action Owner and action status will change to In Progress. You need to provide a reason for doing so.',
                requiredReason: 'ReopenReason',
                buttonName: 'Reopen',
                source: ActionPlanStatusEnum.Closed,
                destination: ActionPlanStatusEnum.InProgress,
                dropResult,
                'data-test-id': 'REOPEN_REJECT',
            })
        },
        deleteActionItem: async (
            actionItem: ActionItem,
            dropResult?: DropResult
        ) => {
            openDialog(ActionItemActions.Delete, actionItem, {
                title: 'Delete action',
                description: 'Are you sure you want to delete this action?',
                requiredReason: null,
                buttonName: 'Delete',
                source: ActionPlanStatusEnum.Draft,
                destination: ActionPlanStatusEnum.InProgress,
                dropResult,
                'data-test-id': 'DELETE_REJECT',
            })
        },
        saveActionItem: async (actionItem: ActionItem) => {
            catchErrorsAndLoading(async () => {
                const res = await saveActionPlanItem(actionItem)
                const isNew = 0 <= window.location.pathname.search('new')
                const { actionStatus, actionItemId, issueId } = res.data

                if (actionStatus && isNew) {
                    history.replace(
                        generatePath('/issue/:issueId/action-plan/:actionId', {
                            issueId,
                            actionId: actionItemId,
                        })
                    )
                }

                updateCurrentActionItem(res.data)
                updateActionItemInDashboard(res.data)
                dispatch(loadActionPlan(actionItem?.issueId))
            }, 'Saving action item')
        },
    })

    return (
        <ActionPlanContext.Provider value={state}>
            <ActionPlanDialog />
            {children}
        </ActionPlanContext.Provider>
    )
}

export default ActionPlanContextProvider
