import axios, { AxiosResponse, CancelToken } from 'axios'

const controllerName = '/issue'

export const generate8DRaport = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(`${controllerName}/${issueId}/report/8D`, {
        cancelToken,
        responseType: 'blob',
    })

export const generateDMAICRaport = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(`${controllerName}/${issueId}/report/DMAIC`, {
        cancelToken,
        responseType: 'blob',
    })

export const generateRCATools = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(`${controllerName}/${issueId}/resolution/rca-tools`, {
        cancelToken,
        responseType: 'blob',
    })
