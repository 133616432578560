import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import { makeStyles } from '@mui/styles'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            '&$expanded': {
                margin: 0,
            },
            '&:before': {
                height: 0,
            },
        },
        expanded: {},
    }),
    { name: 'Accordion' }
)

export const Accordion = (props: AccordionProps) => {
    const classes = useStyles()
    return (
        <MuiAccordion
            {...props}
            square={true}
            elevation={0}
            classes={classes}
        />
    )
}
Accordion.muiName = 'Accordion'

export default Accordion
