import { DateRange } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { ReactNode, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { getPrettyDate, humanizeDateTime } from 'utils/formaters'
import ValueView from './ValueView'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            marginRight: theme.spacing(1),
            height: 16,
            width: 16,
        },
    }),
    { name: 'DateView' }
)

export interface DateViewProps {
    actionName: string
    date?: string
    className: string
    formatValue?: (label: string) => ReactNode
}

export const DateView = (props: DateViewProps) => {
    const classes = useStyles()
    const { date, formatValue, actionName, ...other } = props
    const dateFormated = useMemo(() => humanizeDateTime(date), [date])
    return (
        <ValueView
            label={actionName}
            icon={<DateRange className={classes.icon} />}
            value={
                formatValue ? formatValue(dateFormated) : getPrettyDate(date)
            }
            {...other}
        />
    )
}

DateView.muiName = 'DateView'

export default DateView
