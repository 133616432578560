import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface MultiIssueSourcesProps extends Partial<BaseTextFieldProps> {
    selectedItems: FilterBase<number>[]
    onSelectedItemsChanged?: (regions: FilterBase<number>[]) => void
}

export const MultiIssueSourceSelect = (props: MultiIssueSourcesProps) => {
    const options = useSelector(
        (state: RootState) => state?.resources?.issueSourceType ?? []
    )
    const {
        selectedItems = [],
        onSelectedItemsChanged,
        disabled,
        ...other
    } = props
    useEffect(() => {
        if (!Array.isArray(selectedItems) || selectedItems?.length === 0) {
            const { name, code } = options[0]
            onSelectedItemsChanged &&
                onSelectedItemsChanged([{ name, code: Number(code) }])
        }
    }, [])
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: (string | FilterBase<number>)[]
    ) => {
        if (!onSelectedItemsChanged) return
        const items = (values as FilterBase<number>[]) ?? []
        if (items?.length === 0) {
            const all = options.find((x) => x.code === 15)
            onSelectedItemsChanged([all])
            return
        }
        const allIndex = items.findIndex(({ code }) => code === 15)
        if (0 < allIndex) {
            onSelectedItemsChanged([items[allIndex]])
            return
        }

        onSelectedItemsChanged(items.filter((x) => x.code !== 15))
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedItems}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option?.name ?? ''}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Issue source"
                    {...dataTestId('ISSUE_SOURCE')}
                    {...other}
                />
            )}
        />
    )
}
