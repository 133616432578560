import axios, { AxiosResponse, CancelToken } from 'axios'
import {
    ActionItem,
    ActionItemAttachment,
    ActionItemAttachmentType,
    ActionItemBase,
    ActionType,
} from 'types/actionPlanTypes'
import { ActionPlanItemLogInterface } from '../ActionPlan/ActionPlanItem'

const controllerName = '/issue'

/**
 * @param {number} issueId - issue id
 * @param {CancelToken} cancelToken - Axios CancelToken
 */
export const getActionPlanForIssue = (
    issueIdOrNumber: number | string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<ActionItem>>> =>
    axios.get(`${controllerName}/${issueIdOrNumber}/action-plan`, {
        cancelToken,
    })

export const getActionPlanItemForIssue = (
    issueIdOrNumber: number | string,
    actionId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.get(`${controllerName}/${issueIdOrNumber}/action-plan/${actionId}`, {
        cancelToken,
    })

export const getActionPlanTypes = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionType[]>> =>
    axios.get(`${controllerName}/action-plan/types`, { cancelToken })

export const getActionPlanLogs = (
    actionItemId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionPlanItemLogInterface[]>> =>
    axios.get(`${controllerName}/action-plan/${actionItemId}/logs`, {
        cancelToken,
    })

export const assignActionPlanItem = (
    actionItem: ActionItem,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.post(
        `${controllerName}/action-plan/assign`,
        {
            ...actionItem,
        },
        {
            cancelToken,
        }
    )

export const rejectActionPlanItem = (
    actionItemId: number,
    rejectReason: string,
    rowVersion: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.post(
        `${controllerName}/action-plan/reject`,
        {
            actionItemId,
            rejectReason,
            rowVersion,
        },
        {
            cancelToken,
        }
    )

export const completeActionPlanItem = (
    actionItem: ActionItem,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.post(
        `${controllerName}/action-plan/complete`,
        {
            ...actionItem,
        },
        {
            cancelToken,
        }
    )

export const reopenActionPlanItem = (
    actionItemId: number,
    reopenReason: string,
    rowVersion: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.post(
        `${controllerName}/action-plan/${actionItemId}/reopen`,
        {
            actionItemId,
            reopenReason,
            rowVersion,
        },
        {
            cancelToken,
        }
    )

export const deleteActionPlanItem = (
    actionItemId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.delete(`${controllerName}/action-plan/${actionItemId}/delete`, {
        cancelToken,
    })

export const saveActionPlanItem = (
    actionItem: ActionItem,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItem>> =>
    axios.post(
        `${controllerName}/action-plan/save`,
        {
            ...actionItem,
        },
        {
            cancelToken,
        }
    )

export const uploadActionItemAttachments = (
    actionItemId: number,
    sectionId: ActionItemAttachmentType,
    file: File,
    progressCallback: (progressEvent: any) => void,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItemAttachment>> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('sectionId', `${sectionId}`)
    return axios.post(
        `${controllerName}/action-plan/${actionItemId}/attachments`,
        formData,
        {
            onUploadProgress: progressCallback,
            cancelToken,
        }
    )
}

export const getActionItemAttachment = (
    actionItemId: number,
    attachmentId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Blob>> =>
    axios.get(
        `${controllerName}/action-plan/${actionItemId}/attachments/${attachmentId}`,
        {
            cancelToken,
            responseType: 'blob',
        }
    )

export const deleteActionItemAttachment = (
    actionItemId: number,
    attachmentId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.delete(
        `${controllerName}/action-plan/${actionItemId}/attachments/${attachmentId}`,
        {
            cancelToken,
        }
    )

export const getActionPlanDashboard = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItemBase[]>> =>
    axios.get('/action-plan', { cancelToken })
