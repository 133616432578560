import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { useAutocomplete } from '@mui/lab'
import { IconButton } from '@mui/material'
import { useState } from 'react'
import { dataTestId } from 'utils'
import { MessageTemplateMeta } from '../../../types/models'
import { SendMessageInput } from './SendMessageInput'
import { useStyles } from './styles'

export interface MessageTemplateSelectProps {
    selectedTemplate: MessageTemplateMeta
    templates: MessageTemplateMeta[]
    onChange: (template: MessageTemplateMeta) => void
}

export const MessageTemplateSelect = (props: MessageTemplateSelectProps) => {
    const { selectedTemplate = null, templates = [], onChange } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    const {
        popupOpen,
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
    } = useAutocomplete({
        open,
        onOpen: () => setOpen(true),
        onClose: () => setOpen(false),
        value: selectedTemplate,
        onChange: (e, theme) => onChange(theme as MessageTemplateMeta),
        options: [...templates],
        getOptionLabel: (option) => {
            return option?.value ?? ''
        },
        // getOptionSelected: (option, value) => option.key === value.key,
    })
    return (
        <div className={classes.root} {...getRootProps()}>
            <SendMessageInput
                style={{ flexWrap: 'nowrap' }}
                {...dataTestId('CONTAINER_TEMPLATES')}
                startAdornment={
                    <IconButton
                        size="small"
                        className={classes.arrowIcon}
                        onClick={() => setOpen(!open)}
                        {...dataTestId('TEMPLATES_BUTTON')}
                    >
                        {popupOpen ? (
                            <ArrowDropUp fontSize="small" />
                        ) : (
                            <ArrowDropDown fontSize="small" />
                        )}
                    </IconButton>
                }
                placeholder="Templates"
                inputProps={{
                    ...getInputProps(),
                    style: { width: '100%', maxWidth: 'initial' },
                    ...dataTestId('MAIL_TEMPLATES'),
                }}
            />
            {groupedOptions.length > 0 ? (
                <ul className={classes.list} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li {...getOptionProps({ option, index })}>
                            {option.value}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    )
}
