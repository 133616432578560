import { Grid } from '@mui/material'
import { TextField } from 'components'
import { GridItemProps } from './types'

const GridItem = (props: GridItemProps) => {
    const { GridProps = {}, ...other } = props
    return (
        <Grid item xs={12} sm={6} {...GridProps}>
            <TextField {...other} />
        </Grid>
    )
}

export default GridItem
