import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    assignIssueDone,
    setIssueActionFail,
    loadIssue,
    IssueStoreState,
} from '..'

import { RootState } from 'store/types'
import { removeMyActionById } from 'store/myActions'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { assignIssue } from 'api'
import { ISSUE_ASSIGN_CALL } from '../actionsTypes'

export function* assignIssueAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { assignSection, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        yield call(
            assignIssue,
            assignSection,
            changeLog?.assign ?? null,
            cancelSource.token
        )
        yield put(assignIssueDone())
        yield put(
            removeMyActionById(
                assignSection.issueId,
                IssueActionsTypes.To_Assign
            )
        )
        yield put(showSnackbar('Issue was assigned'))
        yield put(loadIssue(assignSection.issueId))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_ASSIGN_CALL, error))
        yield put(showSnackbar('Cannot assign issue!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* assignIssueSaga() {
    yield takeLatest([ISSUE_ASSIGN_CALL], assignIssueAction)
}
