import { Collapse, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { forwardRef, Ref, useMemo, useState } from 'react'
import { GridContainer } from '../GridContainer'
import { CardViewHeader, CardViewResultsContainer } from './components'
import { CardViewProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2, 2, 1, 2),
            marginBottom: theme.spacing(3),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        collapse: {
            width: '100%',
        },
    }),
    { name: 'CardView' }
)

export const CardView = forwardRef(
    (props: CardViewProps, ref: Ref<HTMLDivElement>) => {
        const [expanded, setExpanded] = useState<boolean>(false)
        const {
            className,
            title,
            titleProps = {},
            subTitle,
            subTitleProps = {},
            results,
            collapsedResults,
            children,
            renderTag,
            onClickLabel,
            onMouseMoveLabel,
            onMouseDownLabel,
            renderActions,
            ...other
        } = props
        const classes = useStyles()

        const toggleExpanded = () => setExpanded((prev) => !prev)

        const resultsSection = useMemo(
            () => <CardViewResultsContainer results={results} />,
            [results]
        )

        const collapsedResultsSection = useMemo(
            () =>
                collapsedResults && (
                    <Collapse in={expanded} className={classes.collapse}>
                        <CardViewResultsContainer results={collapsedResults} />
                    </Collapse>
                ),
            [collapsedResults, expanded]
        )

        return (
            <div ref={ref} className={clsx(className, classes.root)} {...other}>
                <CardViewHeader
                    onClickLabel={onClickLabel}
                    onMouseMoveLabel={onMouseMoveLabel}
                    onMouseDownLabel={onMouseDownLabel}
                    title={title}
                    titleProps={titleProps}
                    subTitle={subTitle}
                    subTitleProps={subTitleProps}
                    renderTag={renderTag}
                />

                {resultsSection}

                {collapsedResultsSection}

                {children && <GridContainer>{children}</GridContainer>}

                {renderActions &&
                    renderActions({
                        expanded,
                        toggleExpanded,
                    })}
            </div>
        )
    }
)

export default CardView
