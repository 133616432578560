import { AuthenticationActions } from 'react-aad-msal'
import * as ACTIONS from './actionTypes'
import { AnyAction } from 'redux'
import { AppStoreNotification, initState } from './initState'
import { uuid } from 'utils'

export const appReducer = (state = initState, action: AnyAction) => {
    switch (action.type) {
        case ACTIONS.SET_USER_PROFILE:
            return { ...state, userProfile: { ...action.payload } }
        case ACTIONS.SHOW_SNACKBAR:
            const notification: AppStoreNotification = {
                key: uuid(),
                message: action.payload.message,
                error: action.payload.error,
                dismissed: false,
            }
            return {
                ...state,
                notifications: [...state.notifications, notification],
            }
        case ACTIONS.DISMISS_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            }
        case ACTIONS.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.payload.key
                ),
            }
        case AuthenticationActions.LogoutSuccess:
            return { ...initState }
        default:
            return state
    }
}
