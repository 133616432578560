import { getUserProfile } from 'api'
import { AxiosResponse } from 'axios'
import { appInsights } from 'configs/appInsights'
import { AnyAction } from 'redux'
import { call, put, takeLatest } from 'redux-saga/effects'
import { UserProfile } from 'types/profile'
import { DASHBOARD_LOAD_PROFILE_FAIL } from '..'
import { showSnackbar } from '../../app'
import {
    DASHBOARD_LOAD_PROFILE_CALL,
    DASHBOARD_LOAD_PROFILE_DONE,
} from '../actionTypes'

export function* loadProfile() {
    yield takeLatest(DASHBOARD_LOAD_PROFILE_CALL, function* () {
        try {
            const { data: profile } = (yield call(
                getUserProfile
            )) as AxiosResponse<UserProfile>
            appInsights.addTelemetryInitializer((envelop) => {
                envelop.data['divCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtDivision?.code ?? ''
                envelop.data['buCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code ?? ''
                envelop.data['pgCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtProductGroup?.code ?? ''
                envelop.data['countryCode'] = profile?.countryCode ?? ''
                envelop.tags['divCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtDivision?.code ?? ''
                envelop.tags['buCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code ?? ''
                envelop.tags['pgCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtProductGroup?.code ?? ''
                envelop.tags['countryCode'] = profile?.countryCode ?? ''
            })
            appInsights.setAuthenticatedUserContext(
                profile?.geid ?? '',
                profile?.geid ?? '',
                true
            )
            const action: AnyAction = {
                type: DASHBOARD_LOAD_PROFILE_DONE,
                payload: profile,
            }
            if (action?.payload) {
                yield put(action)
            }
        } catch (error) {
            yield put(showSnackbar('Cannot load user profile', true))
            yield put({ type: DASHBOARD_LOAD_PROFILE_FAIL })
        }
    })
}
