import { ISSUE_ROOT } from 'routes'
import { ReportResult } from 'types/reportTypes'
import { ExcelExportColumnConfig } from 'utils'

export const additionalColumnSettings: Record<
    string,
    Partial<ExcelExportColumnConfig<ReportResult>>
> = {
    '01': {
        getHyperLink: (item) =>
            `${window.location.origin}${ISSUE_ROOT}/${item['0_9']}`,
    },
    '03': {
        isDate: true,
        localDate: true,
    },
    '04': {
        isDate: true,
        localDate: true,
    },
    '05': {
        isDate: true,
        localDate: true,
    },
    '06': {
        isDate: true,
        localDate: true,
    },
    '07': {
        isDate: true,
        localDate: true,
    },
    '08': {
        isDate: true,
        localDate: true,
    },
    '09': {
        isDate: true,
        localDate: true,
    },
    '10': {
        isDate: true,
        localDate: true,
    },
    '11': {
        isDate: true,
        localDate: true,
    },
    '23': {
        width: 900,
    },
    '27': {
        isDate: true,
    },
    '36': {
        width: 900,
    },
    '47': {
        isDate: true,
        localDate: true,
    },
    '59': {
        width: 900,
    },
    '63': {
        isDate: true,
    },
    '64': {
        isDate: true,
    },
    '65': {
        isDate: true,
    },
    '66': {
        isDate: true,
    },
    '67': {
        isDate: true,
        localDate: true,
    },
    '78': {
        width: 900,
    },
    '79': {
        width: 900,
    },
    '91': {
        isDate: true,
        localDate: true,
    },
    '92': {
        isDate: true,
        localDate: true,
    },
    '98': {
        isDate: true,
    },
    '99': {
        isDate: true,
    },
}
