import { Checkbox, FormControlLabel, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import theme from 'styles/themeBase'
import { AdminCardHeaderProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: theme.spacing(2),
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            ...theme.typography.body2,
        },
        tags: {
            display: 'flex',
            gap: theme.spacing(1),
        },
        formControlLabel: {
            padding: 0,
            margin: 0,
        },
        checkbox: {
            padding: 0,
            alignSelf: 'flex-start',
        },
        label: {
            marginLeft: theme.spacing(2),
        },
        disabledLabel: {
            color: theme.palette.common.black,
        },
    }),
    { name: 'AdminCardHeader' }
)

export const AdminCardHeader = (props: AdminCardHeaderProps) => {
    const {
        disabled = false,
        children,
        className,
        renderTags,
        checked,
        onChangeChecked,
        newConcept = false,
        ...other
    } = props

    const classes = useStyles()

    return (
        <div className={clsx(classes.root, className)} {...other}>
            <div className={classes.content}>
                {!disabled && onChangeChecked ? (
                    <FormControlLabel
                        className={classes.formControlLabel}
                        label={
                            <Typography
                                variant="body2"
                                className={classes.label}
                                style={newConcept ? { fontWeight: 800 } : {}}
                            >
                                {children}
                            </Typography>
                        }
                        control={
                            <Checkbox
                                className={classes.checkbox}
                                checked={disabled ? false : checked}
                                onChange={onChangeChecked}
                                color="secondary"
                                size="small"
                            />
                        }
                    />
                ) : (
                    <Typography
                        variant="body2"
                        style={
                            newConcept
                                ? {
                                      marginLeft: theme.spacing(0.5),
                                      fontWeight: 800,
                                  }
                                : {}
                        }
                    >
                        {children}
                    </Typography>
                )}
            </div>
            <div
                className={classes.tags}
                style={newConcept ? { marginLeft: theme.spacing(1) } : {}}
            >
                {renderTags && renderTags()}
            </div>
        </div>
    )
}
