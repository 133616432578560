import { DialogProps } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useEffect, useState } from 'react'
import { ResolutionSection } from 'types/issueTypes'
import { CONFIRM_CONTAINMENT_ACTIONS_DIALOG } from '../../consts/selectorIds'
import { VerifyContainmentActionQuery } from '../../types/actions'
import TextField from '../Base/TextField'
import Dialog from './Dialog'

const MAX_LENGTH_WHAT_CONTAINMENT_ACTIONS_WERE_TAKEN = 1000

export interface ConfirmContainmentActionDialogProps extends DialogProps {
    issueId: number
    rowVersion: string
    number?: string
    whatContainmentActionsWereTaken?: string
    onClose: () => void
    onConfirm?: (data: VerifyContainmentActionQuery) => void
    [key: string]: any
}

export const ConfirmContainmentActionDialog = (
    props: ConfirmContainmentActionDialogProps
) => {
    const {
        open,
        issueId,
        rowVersion,
        number,
        whatContainmentActionsWereTaken:
            whatContainmentActionsWereTakenProp = '',
        onClose,
        onConfirm,
        ...other
    } = props
    const [
        whatContainmentActionsWereTaken,
        setWhatContainmentActionsWereTaken,
    ] = useState<string>(whatContainmentActionsWereTakenProp)
    useEffect(() => {
        setWhatContainmentActionsWereTaken(whatContainmentActionsWereTakenProp)
    }, [whatContainmentActionsWereTakenProp])

    const [error, setError] = useState<string>('')

    const onTypeReason = (event: any) => {
        setWhatContainmentActionsWereTaken(event.target.value)
        if (whatContainmentActionsWereTaken) {
            setError('')
        }
    }
    const onClickConfirm = () => {
        if (!whatContainmentActionsWereTaken) {
            setError('The field containment action taken is required')
        } else if (
            MAX_LENGTH_WHAT_CONTAINMENT_ACTIONS_WERE_TAKEN <
            whatContainmentActionsWereTaken.length
        ) {
            setError(
                `The field containment action taken must be a string with a maximum length of ${MAX_LENGTH_WHAT_CONTAINMENT_ACTIONS_WERE_TAKEN}.`
            )
        } else {
            setError('')
            setWhatContainmentActionsWereTaken('')
            onConfirm &&
                onConfirm({
                    issueId,
                    rowVersion,
                    whatContainmentActionsWereTaken,
                })
        }
    }
    return (
        <Dialog open={open} {...other}>
            <DialogTitle>Confirm CA execution</DialogTitle>
            <DialogContent style={{ minHeight: 200 }}>
                <DialogContentText>
                    You are about to confirm containment actions execution. You
                    have to provide the description of those actions.
                </DialogContentText>
                <TextField
                    {...CONFIRM_CONTAINMENT_ACTIONS_DIALOG.CONTAINMENT_ACTIONS_TAKEN_INPUT}
                    error={error !== ''}
                    helperText={error}
                    variant="filled"
                    autoFocus
                    label="Containment action taken"
                    rows={3}
                    multiline
                    value={whatContainmentActionsWereTaken}
                    onChange={onTypeReason}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    {...CONFIRM_CONTAINMENT_ACTIONS_DIALOG.ACTION_BUTTONS
                        .CANCEL}
                >
                    Cancel
                </Button>
                <Button
                    {...CONFIRM_CONTAINMENT_ACTIONS_DIALOG.ACTION_BUTTONS
                        .CONFIRM}
                    color="secondary"
                    onClick={onClickConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmContainmentActionDialog.defaultProps = {
    open: false,
    rowVersion: '',
}

export interface ConfirmContainmentActionDialogExtendedProps
    extends DialogProps {
    resolutionSection: ResolutionSection
    onConfirm?: (data: ResolutionSection) => void
    onClose: () => void
}

export const ConfirmContainmentActionDialogExtended = (
    props: ConfirmContainmentActionDialogExtendedProps
) => {
    const { onConfirm, resolutionSection, ...other } = props
    const handleConfirm = (data: VerifyContainmentActionQuery) => {
        onConfirm && onConfirm({ ...resolutionSection, ...data })
    }
    return (
        <ConfirmContainmentActionDialog
            onConfirm={handleConfirm}
            {...resolutionSection}
            {...other}
        />
    )
}

ConfirmContainmentActionDialogExtended.defaultProps = {
    open: false,
    rowVersion: '',
}
