import { FlagOutlined, StarRate } from '@mui/icons-material'
import { AccordionActions } from '@mui/material'
import { Button, CardView, ChipLabel } from 'components'
import { useMemo } from 'react'
import { arrayToStringLine, dataTestId } from 'utils'
import { ResolvingUnitItemProps } from './types'

export const ResolvingUnitItem = (props: ResolvingUnitItemProps) => {
    const { resolvingUnit, onSelect, ...other } = props
    const {
        balId,
        organizationalInfo,
        address,
        city,
        countryCode,
        activity,
        isGlobal,
    } = resolvingUnit
    const { division, businessUnit, productGroup } = organizationalInfo
    const [title, results] = useMemo(
        () => [
            arrayToStringLine([
                division.code,
                businessUnit.code,
                productGroup.code,
            ]),
            isGlobal
                ? []
                : [
                      {
                          label: 'Address',
                          renderIcon: (props) => <FlagOutlined {...props} />,
                          text: `${countryCode} ${city}, ${address}`,
                          ...dataTestId('RSOLVING_UNIT_PICKER_ITEM_ADRESS'),
                      },
                      {
                          label: 'Activity',
                          renderIcon: (props) => <StarRate {...props} />,
                          text: activity,
                          ...dataTestId('RESOLVING_UNIT_PICKER_ITEM_ACTIVITY'),
                      },
                  ],
        ],
        [resolvingUnit]
    )
    return (
        <CardView
            results={results}
            title={title}
            {...other}
            renderTag={() => <ChipLabel label={balId} variant="grey" />}
            renderActions={() => (
                <AccordionActions>
                    <Button
                        onClick={() => onSelect({ ...resolvingUnit })}
                        color="secondary"
                        {...dataTestId('RESOLVING_UNIT_PICKER_ITEM_SELECT')}
                    >
                        Select
                    </Button>
                </AccordionActions>
            )}
        />
    )
}

export default ResolvingUnitItem
