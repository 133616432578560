import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    deleteActionPlanItem,
    rejectActionPlanItem,
    reopenActionPlanItem,
} from 'api'
import { Button, Dialog, TextField } from 'components'
import CircularLoading from 'components/Base/Content/components/CircularLoading'
import ContentOverlay from 'components/Base/Content/components/ContentOverlay'
import { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { generatePath, useHistory } from 'react-router'
import { ISSUE_ACTION_PLAN_DASHBOARD } from 'routes'
import { loadActionPlan } from 'store/issue'
import { ActionItemActions } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'
import { ActionPlanContext } from './ActionPlanContext'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        loader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: theme.zIndex.modal,
        },
    }),
    {
        name: 'ActionPlanDialog',
    }
)

export const ActionPlanDialog = () => {
    const classes = useStyles()
    const history = useHistory()
    const {
        updateDashboardItemAndChangePosition,
        currentActionItem,
        dialogState,
        closeDialog,
        catchErrorsAndLoading,
        updateCurrentActionItem,
        error,
        loading,
        loadingDesc,
    } = useContext(ActionPlanContext)
    const { fields, visible, func, actionItem } = dialogState
    const dispatch = useDispatch()
    const [reason, setReason] = useState<string>('')
    const [reasonError, setReasonError] = useState<string>(null)

    const { source, destination, dropResult } = fields

    const onSubmit = async () => {
        if (ActionItemActions.Reopen === func) {
            catchErrorsAndLoading(async () => {
                const res = await reopenActionPlanItem(
                    actionItem.actionItemId,
                    reason,
                    actionItem.rowVersion
                )
                const updatedActionItem = res.data
                updateCurrentActionItem(updatedActionItem)
                updateDashboardItemAndChangePosition(
                    source,
                    destination,
                    dropResult,
                    updatedActionItem
                )
                closeDialog()
                dispatch(loadActionPlan(actionItem?.issueId))
            }, 'Reopening action item')
        } else if (ActionItemActions.Reject === func) {
            catchErrorsAndLoading(async () => {
                const res = await rejectActionPlanItem(
                    actionItem.actionItemId,
                    reason,
                    actionItem.rowVersion
                )
                const updatedActionItem = res.data
                updateCurrentActionItem(updatedActionItem)
                updateDashboardItemAndChangePosition(
                    source,
                    destination,
                    dropResult,
                    updatedActionItem
                )
                closeDialog()
            }, 'Rejecting action item')
        } else if (ActionItemActions.Delete === func) {
            catchErrorsAndLoading(async () => {
                await deleteActionPlanItem(actionItem.actionItemId)
                dispatch(loadActionPlan(actionItem.issueId))
                if (currentActionItem?.issueId) {
                    history.push(
                        generatePath(ISSUE_ACTION_PLAN_DASHBOARD, {
                            id: currentActionItem.issueId,
                        })
                    )
                }

                closeDialog()
            }, 'Deleting action item')
        }
    }

    useEffect(() => {
        if (error?.errors) {
            const filteredError = error.errors.find(
                (item) => item.field === fields.requiredReason
            )
            if (filteredError && filteredError.message) {
                setReasonError(filteredError.message)
            }
        }
    }, [error])

    const resetForm = () => {
        setReason('')
        setReasonError(null)
    }

    useEffect(() => {
        resetForm()
    }, [visible])

    const updateTextField = (e) => {
        setReason(e.target.value)
        setReasonError(null)
    }

    return (
        <Dialog
            open={visible}
            onClose={closeDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {loading && (
                <>
                    <ContentOverlay />
                    <CircularLoading
                        loading={loading}
                        loadingDesc={loadingDesc}
                        className={classes.loader}
                    />
                </>
            )}

            <DialogTitle id="alert-dialog-title">{fields.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {fields.description}
                </DialogContentText>
                {fields.requiredReason && (
                    <TextField
                        label="Reason"
                        value={reason}
                        onChange={updateTextField}
                        error={Boolean(reasonError)}
                        helperText={reasonError}
                        required
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeDialog}
                    color="primary"
                    {...dataTestId('CANCEL_REJECT')}
                >
                    Cancel
                </Button>
                <Button
                    onClick={onSubmit}
                    color="secondary"
                    autoFocus
                    {...dataTestId(fields['data-test-id'])}
                >
                    {fields.buttonName}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
