import { Typography } from '@mui/material'
import { useStyles } from '../Survey.style'

export const SurveyFooter = () => {
    const classes = useStyles()
    const currentYear = new Date().getFullYear()
    return (
        <div className={classes.footer}>
            <Typography variant="caption">
                {`©1995–${currentYear} ABB`}
            </Typography>
        </div>
    )
}
