import { Chip } from '@mui/material'
import { RcaReportTypes } from 'types/enums'
import { getRcaReportTypeName } from 'utils'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export interface ReportsViewProps {
    generateRaport?: (reportType: RcaReportTypes) => void
    [key: string]: any
}
export const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: theme.spacing(1, 0),
        },
        label: {
            display: 'inline-flex',
            height: 36,
            alignItems: 'center',
            color: theme.palette.grey[600],
            fontSize: '0.875rem',
            fontWeight: theme.typography.fontWeightBold as number,
            marginBottom: theme.spacing(0.5),
        },
        chip: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'ReportsView' }
)

const ReportsView = (props: ReportsViewProps) => {
    const { generateRaport, ...other } = props
    const classes = useStyles(props)

    return generateRaport ? (
        <div className={classes.root} {...other}>
            <span className={classes.label}>Generate reports</span>
            <div>
                <Chip
                    className={classes.chip}
                    label={getRcaReportTypeName(RcaReportTypes.Report8D)}
                    onClick={() =>
                        generateRaport &&
                        generateRaport(RcaReportTypes.Report8D)
                    }
                    variant="outlined"
                />
                <Chip
                    className={classes.chip}
                    label={getRcaReportTypeName(RcaReportTypes.DMAIC)}
                    onClick={() =>
                        generateRaport && generateRaport(RcaReportTypes.DMAIC)
                    }
                    variant="outlined"
                />
            </div>
        </div>
    ) : null
}

export default ReportsView
