import { Grid } from '@mui/material'
import { CardViewResultItem, CardViewResultsContainerProps } from 'components'

export const CardViewResultsContainer = (
    props: CardViewResultsContainerProps
) => {
    const { className, results, ...other } = props
    return (
        <Grid
            className={className}
            container
            xs={12}
            spacing={1}
            item
            {...other}
        >
            {results.map((result) => (
                <CardViewResultItem result={result} key={result.label} />
            ))}
        </Grid>
    )
}
