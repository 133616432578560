import { Close } from '@mui/icons-material'
import {
    AppBar,
    Dialog,
    IconButton,
    Theme,
    Toolbar,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ActionsView, Content, FullScreenDialogTransition } from 'components'
import { AdminDialogMobileProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        paper: {
            backgroundColor: theme.palette.grey[100],
        },
        section: {
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2, 2, 0, 2),
            marginTop: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            '&$disabledContentPadddings': {
                padding: theme.spacing(0),
            },
        },
        disabledContentPadddings: {},
    }),
    {
        name: 'AdminDialogMobile',
    }
)

export const AdminDialogMobile = (props: AdminDialogMobileProps) => {
    const {
        open,
        onClose,
        title,
        pageTitle,
        subTitle,
        renderActions,
        children,
        disabledContentPadddings,
        ...other
    } = props
    const classes = useStyles()

    return (
        <Dialog
            fullScreen
            open={Boolean(open)}
            TransitionComponent={FullScreenDialogTransition}
            disableAutoFocus={true}
            disableRestoreFocus={true}
            classes={{ paper: classes.paper }}
            {...other}
            maxWidth="sm"
        >
            <AppBar position="static" color="default" elevation={0}>
                <Toolbar />
            </AppBar>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton
                        onClick={onClose}
                        aria-label="close"
                        size="large"
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6">{title}</Typography>
                </Toolbar>
            </AppBar>
            <Content>
                {subTitle && (
                    <Typography variant="caption">Add contact to</Typography>
                )}
                {pageTitle && <Typography variant="h5">{pageTitle}</Typography>}
                <section
                    className={clsx(
                        classes.section,
                        disabledContentPadddings &&
                            classes.disabledContentPadddings
                    )}
                >
                    {children}
                    {renderActions && (
                        <ActionsView>{renderActions()}</ActionsView>
                    )}
                </section>
            </Content>
        </Dialog>
    )
}
