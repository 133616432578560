import { useSelector } from 'react-redux'
import ChipLabel, { ChipLabelProps } from './ChipLabel'
import { COMPLETED } from '../../consts/comaplaintStatus'
import { IssueStatuses } from 'types/enums'

export interface StatusChipLabelProps extends Omit<ChipLabelProps, 'label'> {
    issueStatusId: IssueStatuses
}

export const StatusChipLabel = (props: StatusChipLabelProps) => {
    const complaintStatus = useSelector<any, any>(
        (state) => state.resources.complaintStatus
    )
    const { issueStatusId, ...other } = props
    const complaintStatusItem = complaintStatus.find(
        (item: any) => String(item.code) === String(issueStatusId)
    )
    if (!complaintStatusItem) {
        return null
    }
    const complaintStatusLabel = complaintStatusItem.name
    const isCompleted = complaintStatusItem.code === COMPLETED
    return issueStatusId ? (
        <ChipLabel
            {...other}
            label={complaintStatusLabel}
            variant={isCompleted ? 'green' : 'blue'}
        />
    ) : null
}

export default StatusChipLabel
