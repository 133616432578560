import { useEffect, useState } from 'react'
import { Content, PageTitle } from 'components'
import {
    ActionPlanItemsQuery,
    ActionPlanList,
    getInitActionPlanItemsQuery,
    getInitActionPlanStats,
    SearchSection,
} from '../ActionPlan/Dashboard'

export const ACTION_PLAN_DASHBOARD_FILTERS_STORAGE_KEY =
    'ccrp-my-action-plan-dashboard-filters'

export const ACTION_PLAN_DASHBOARD_EXAND_STORAGE_KEY =
    'ccrp-my-action-plan-dashboard-expand'

export const MyActionPlanItems = () => {
    const [actionPlanItemsQuery, setActionPlanItemsQuery] =
        useState<ActionPlanItemsQuery>(getInitActionPlanItemsQuery())
    const [stats, setStats] = useState(getInitActionPlanStats())
    const [open, setOpen] = useState(true)
    const saveFilters = (filters: ActionPlanItemsQuery) => {
        const { wildcard, ...data } = filters
        window.localStorage.setItem(
            ACTION_PLAN_DASHBOARD_FILTERS_STORAGE_KEY,
            JSON.stringify(data)
        )
    }
    useEffect(() => {
        const data = JSON.parse(
            window.localStorage.getItem(
                ACTION_PLAN_DASHBOARD_FILTERS_STORAGE_KEY
            ) ?? null
        ) as Partial<ActionPlanItemsQuery>
        const open = Boolean(
            Number(
                window.localStorage.getItem(
                    ACTION_PLAN_DASHBOARD_EXAND_STORAGE_KEY
                ) ?? '1'
            )
        )
        setOpen(open)
        if (data) {
            setActionPlanItemsQuery((prev) => ({ ...prev, ...data }))
        }
    }, [])
    return (
        <Content label="Reload" title="My action plan items" variant="medium">
            <PageTitle
                title="My action plan items"
                desc="This dashboard presents all actions plan items with which you are associated."
                to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B56C84027-3B1D-46B4-BC26-71BEA984B2A8%7D&file=CCRP%20Light%20-%20Dashboard%20overview.pptx&action=edit&mobileredirect=true"
            />
            <SearchSection
                open={open}
                onOpen={(open) => {
                    setOpen(open)
                    window.localStorage.setItem(
                        ACTION_PLAN_DASHBOARD_EXAND_STORAGE_KEY,
                        Number(open).toString()
                    )
                }}
                actionPlanItemsQuery={actionPlanItemsQuery}
                onFiltersChanged={(filters) => {
                    setActionPlanItemsQuery(filters)
                    saveFilters(filters)
                }}
                stats={stats}
            />
            <ActionPlanList
                actionPlanItemsQuery={actionPlanItemsQuery}
                onStatsChanged={setStats}
            />
        </Content>
    )
}

export default MyActionPlanItems
