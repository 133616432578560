import { Domain } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { BusinessUnitFilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionLabel, getOptionSelected } from './utils'

export interface MultiBusinessUnitSelectProps
    extends Partial<BaseTextFieldProps> {
    divisions: BusinessUnitFilterBase[]
    selectedBusinessUnits: BusinessUnitFilterBase[]
    onBusinessUnitChanged: (selectedDivisions: BusinessUnitFilterBase[]) => void
    displayAll?: boolean
    displayInactive?: boolean
}

export const MultiBusinessUnitSelect = (
    props: MultiBusinessUnitSelectProps
) => {
    const {
        divisions = [],
        selectedBusinessUnits = [],
        onBusinessUnitChanged,
        error,
        helperText,
        disabled,
        displayAll = false,
        displayInactive = false,
        ...other
    } = props
    const allOptions = useSelector(
        (state: RootState) => state.resources.businessUnits
    )
    const options = useMemo(() => {
        const filterByDivisions =
            Array.isArray(divisions) && 0 < divisions?.length
        return allOptions
            .filter((x) => (displayInactive ? true : x.isActive))
            .filter((b) =>
                filterByDivisions
                    ? divisions.some((d) => d.code === b.divisionCode)
                    : true
            )
    }, [allOptions, divisions, displayInactive])
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | BusinessUnitFilterBase>
    ) =>
        onBusinessUnitChanged &&
        onBusinessUnitChanged([...values] as Array<BusinessUnitFilterBase>)
    useEffect(() => {
        const filterByDivisions =
            Array.isArray(divisions) && 0 < divisions?.length
        if (filterByDivisions) {
            const newBusinessUnits = [...selectedBusinessUnits]
            const businessUnitsToRemove = []
            newBusinessUnits.forEach((x, i) => {
                if (!divisions.map((x) => x.code).includes(x.divisionCode)) {
                    businessUnitsToRemove.push(i)
                }
            })
            if (0 < businessUnitsToRemove?.length) {
                businessUnitsToRemove.forEach((i) => {
                    newBusinessUnits.splice(i, 1)
                })
                onBusinessUnitChanged(newBusinessUnits)
            }
        }
    }, [divisions])

    const isDisabled = !displayAll
        ? Array.isArray(divisions) && divisions?.length === 0
        : false

    return (
        <Autocomplete
            disabled={disabled || isDisabled}
            multiple={true}
            value={selectedBusinessUnits}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            loading={options?.length === 0}
            loadingText={'Loading Divisions… '}
            disableClearable={disabled}
            noOptionsText={'No match Divisions'}
            renderOption={(props, option) => (
                <li {...props}>{getOptionLabel(option)}</li>
            )}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          getOptionLabel(f)
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    error={error}
                    helperText={helperText}
                    params={params}
                    label="Divisions"
                    {...dataTestId('DIVISIONS_COMMON')}
                    renderIcon={(props) => <Domain {...props} />}
                    {...other}
                />
            )}
        />
    )
}
