import { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import ActionPlanItem from '../ActionPlan/ActionPlanItem/ActionPlanItem'
import ActionPlanItemLogs from '../ActionPlan/ActionPlanItem/ActionPlanItemLogs'
import { ActionPlanItemLogInterface } from '../ActionPlan/ActionPlanItem/types'
import { ActionPlanContext } from '../ActionPlan/context/ActionPlanContext'
import { getActionPlanItemForIssue, getActionPlanLogs } from 'api'
import { Content, PageAppBar } from '../components'
import { useActionItemForNew, useMergeErrors } from '../hooks'
import { ActionPlanItemParams } from '../types/urlParams'

export const IssueActionPlanItem = () => {
    const didMountRef = useRef(false)
    const { currentActionItem, updateCurrentActionItem, error, setError } =
        useContext(ActionPlanContext)
    const [loading, setLoading] = useState<boolean>(false)
    const [loadingLogs, setLoadingLogs] = useState<boolean>(false)
    const [localError, setLocalError] = useState(null)
    const { actionPlanId: actionPlanIdParam } =
        useParams<{ actionPlanId: string }>()
    const actionPlanId = Number(actionPlanIdParam)
    const [logs, setLogs] = useState<ActionPlanItemLogInterface[]>([])
    const { id: issueIdOrNumberParam } = useParams<ActionPlanItemParams>()
    const isNewItem = actionPlanIdParam && actionPlanIdParam === 'new'
    const fetchActionPlanItem = () => {
        catchErrorsAndLoadingLocal(async () => {
            const res = await getActionPlanItemForIssue(
                Number.isInteger(Number(issueIdOrNumberParam))
                    ? Number(issueIdOrNumberParam)
                    : 0,
                actionPlanId
            )
            const actionItem = { ...res.data }
            if (actionItem.isDeleted) {
                actionItem.accessMatrix = null
            }
            updateCurrentActionItem(actionItem)
        })
    }
    useEffect(() => {
        if (didMountRef.current) {
            if (!isNewItem) {
                setLoadingLogs(true)
                getActionPlanLogs(actionPlanId)
                    .then((res) => {
                        setLogs(res.data)
                    })
                    .catch((err) => {
                        setLocalError(err)
                    })
                    .finally(() => {
                        setLoadingLogs(false)
                    })
            }
        } else didMountRef.current = true
    }, [currentActionItem])
    useEffect(() => {
        setError(null)
        if (!isNewItem) {
            fetchActionPlanItem()
        }
    }, [])
    const catchErrorsAndLoadingLocal = async (cb: () => void) => {
        setLoading(true)
        try {
            await cb()
        } catch (err) {
            setLocalError(err)
        } finally {
            setLoading(false)
        }
    }
    const actionItemForNew = useActionItemForNew(
        issueIdOrNumberParam,
        catchErrorsAndLoadingLocal
    )
    useEffect(() => {
        if (isNewItem && actionItemForNew) {
            updateCurrentActionItem(actionItemForNew)
        }
    }, [actionItemForNew])
    const { isLoading, loadingDescription } = useMergeErrors()
    return (
        <>
            <PageAppBar title="Action plan item" />
            <Content
                loading={isLoading || loading}
                loadingDesc={loadingDescription}
                error={error || localError}
                onRetry={() => {
                    setError(null)
                }}
            >
                {!loading && (
                    <>
                        <ActionPlanItem />
                        {!isNewItem && (
                            <ActionPlanItemLogs
                                logs={logs}
                                loading={loadingLogs}
                            />
                        )}
                    </>
                )}
            </Content>
        </>
    )
}
