import { Typography } from '@mui/material'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { AbbTypography } from '../../components'
import { useStyles } from '../Survey.style'
import {
    SurveyTranslationContext,
    SurveyTranslationContextProps,
} from '../SurveyTranslationProvider'

export const ConfirmationNotFound = () => {
    const classes = useStyles()
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    return (
        <>
            <Helmet>
                <title>
                    {t(
                        'Survey_NotExistHeader',
                        'You have been successfully unsubscribed.'
                    )}
                </title>
            </Helmet>
            <AbbTypography variant="h2">
                {t(
                    'Survey_NotExistHeader',
                    'You have been successfully unsubscribed.'
                )}
            </AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                {t(
                    'Survey_NotExistsMessage',
                    'If you did this in error, you may re-subscribe by clicking the button below.'
                )}
            </Typography>
        </>
    )
}
