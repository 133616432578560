import { MenuItem, MenuItemProps } from '@mui/material'
import clsx from 'clsx'
import { useNavMenuItemStyles } from '.'

export interface ButtonMenuItemProps extends Omit<MenuItemProps, 'button'> {}

export const ButtonMenuItem = (props: ButtonMenuItemProps) => {
    const { className, selected, children, ...other } = props
    const classes = useNavMenuItemStyles(props)
    return (
        <MenuItem
            selected={selected}
            classes={{
                gutters: classes.gutters,
            }}
            className={clsx(className, classes.root, classes.gutters)}
            {...other}
        >
            <div className={clsx(classes.link, selected && classes.selected)}>
                {children}
            </div>
        </MenuItem>
    )
}

export default ButtonMenuItem
