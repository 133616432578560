import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const height = 20

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            height: height,
            borderRadius: 4,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            lineHeight: height + 'px',
            fontSize: 12,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        blue: {
            color: theme.palette.blue.main,
            backgroundColor: theme.palette.blue.light,
        },
        green: {
            color: theme.palette.green.main,
            backgroundColor: theme.palette.green.light,
        },
        yellow: {
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.yellow.light,
        },
        red: {
            color: theme.palette.red.main,
            backgroundColor: theme.palette.red.light,
        },
        grey: {
            color: theme.palette.grey[600],
            backgroundColor: theme.palette.grey[200],
        },
    }),
    { name: 'ChipLabel' }
)

export type ChipLabelVariants = 'blue' | 'green' | 'grey' | 'red' | 'yellow'

export interface ChipLabelProps
    extends React.HtmlHTMLAttributes<HTMLSpanElement> {
    label: string | number
    variant?: ChipLabelVariants
}

export const ChipLabel = (props: ChipLabelProps) => {
    const classes = useStyles()
    const { className, label, variant = 'blue', ...other } = props
    let variantClass = null
    switch (variant) {
        case 'red':
            variantClass = classes.red
            break
        case 'green':
            variantClass = classes.green
            break
        case 'yellow':
            variantClass = classes.yellow
            break
        case 'grey':
            variantClass = classes.grey
            break
        default:
            variantClass = classes.blue
            break
    }
    let classNames = clsx(className, classes.root, variantClass)
    return (
        <span className={classNames} {...other}>
            {label}
        </span>
    )
}

export default ChipLabel
