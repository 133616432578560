import { ExpandMore } from '@mui/icons-material'
import { Collapse, Divider, MenuList, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FlexGrow } from '..'
import ButtonMenuItem from './ButtonMenuItem'

export interface AccordionGroupProps {
    label: string
    initExpand?: boolean
    divider?: boolean
    level?: number
}

const useStyles = makeStyles((theme: AbbTheme) => ({
    icon: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expanded: {
        '&$expanded': {
            transform: 'rotate(180deg)',
        },
    },
}))

export const AccordionGroup: FC<AccordionGroupProps> = (props) => {
    const {
        label,
        initExpand = false,
        divider = true,
        level = 0,
        children,
        ...other
    } = props
    const classes = useStyles(props)
    const theme = useTheme()
    const [open, setOpen] = useState(false)
    useEffect(() => {
        setOpen(initExpand)
    }, [initExpand])
    const handleExpand = () => setOpen((prev) => !prev)
    return (
        <>
            {divider && <Divider style={{ margin: 0 }} />}
            <ButtonMenuItem
                onClick={handleExpand}
                style={{ paddingLeft: theme.spacing(level * 1) }}
            >
                {label} <FlexGrow />
                <ExpandMore
                    className={clsx(classes.icon, open && classes.expanded)}
                    {...other}
                />
            </ButtonMenuItem>
            <Collapse
                in={open}
                style={{ paddingLeft: theme.spacing(level * 1) }}
            >
                <MenuList disablePadding={true}>{children}</MenuList>
            </Collapse>
        </>
    )
}

export default AccordionGroup
