import { ProductLineLocationProps } from 'Issue/Pickers'
import { Address, ClassificationIssueForm } from './adminTypes'
import {
    AttachmentTypes,
    EffectivenessTypes,
    FailureModeValidation,
    FollowUpByMethod,
    IssueActionsTypes,
    IssueCreationTypes,
    IssueRoles,
    IssueStatuses,
    IssueTypes,
    QuickFixVariants,
    TargetResolvingDateChangeReasons as TargetResolutionDateChangeReasons,
} from './enums'
import { AttachmentBase, FilterBase } from './models'

export interface Dates {
    targetResolutionAndVerificationDate: string
    creationDate: string
    submitDate: string
    assignedDate: string
    resolvedDate: string
    verifiedDate: string
    solutionSubmittedDate: string
    completionDate: string
    effectivenessCheckDate: string
}

export interface Kpis {
    aot: boolean
    rot: boolean
    cot: boolean
    caot: boolean
}

export interface Attachment extends AttachmentBase {
    issueId: number
    attachmentType: AttachmentTypes
}

export interface EmployeeOrganizationInfo {
    divisionCode: string
    businessUnitCode: string
    productGroupCode: string
    genericCode: string
}

export interface Employee
    extends Partial<{
        geid: string
        phone: string
        organizationInfo: OrganizationalInfo
        countryCode: string
        supervisor?: Employee
        fullName: string
        firstName: string
        lastName: string
        email: string
        isActive: boolean
        hrgtOrganizationInfo: HrgtOrganizationInfo
    }> {}

export interface Customer {
    address: Address
    guid: string
    name: string
    countryCode: string
    city: string
    stateDistrict: string
    zipCode: string
    headquarterGuid: string
    isActive: boolean
    accountManagerEmail: string
    channelClass: string
    industryUsage: string
    leadingBusinessPartnerSegment: string
    shortName: string
}

export type CustomerContactSource = 'SFDC' | 'CCRP'

export interface CustomerContact {
    id: string
    firstName: string
    lastName: string
    email: string
    phone: string
    source: CustomerContactSource
    isActive: boolean
    customerGuid?: string
}

export interface CustomerContactQuery {
    id?: string
    firstName: string
    lastName: string
    email: string
    phone: string
    isActive: boolean
}

export interface CustomerContactErrors {
    firstName: string | null
    lastName: string | null
    email: string | null
    phone: string | null
    isActive: boolean | null
}

export interface CreateCustomerContactCommand {
    customerGuid: string
    firstName: string
    lastName: string
    email: string
    phone: string
}

export interface UpdateCustomerContactCommand {
    firstName: string
    lastName: string
    email: string
    phone: string
    isActive: boolean
}

export interface Coordinator {
    geid: string
    email: string
}

export interface ContainmentAction
    extends Partial<{
        isContainmentActionRequired: boolean
        containmentActionDescription: string
        containmentActionDeadlineDate: string
        containmentActionConfirmationDate: string
    }> {}

export interface HighImpactCategory
    extends Partial<{
        isHealthAndSafetyImpact: boolean
        isABBOrCustomerReputationAtStake: boolean
        isNegativeBusinessImpactToCustomer: boolean
        isNegativeBusinessImpactToABB: boolean
        isNegativeImpactOnABBBrandImage: boolean
        isPotentialToLossCustomer: boolean
        isPotentialSystemicIssuesOrProductRecall: boolean
    }> {}

export interface HighImpact
    extends Partial<{
            isHighImpact: boolean
            impactReason: string
            potentialBusinessImpact: number
            validationDeadlineDate: string
            validationActionDate: string
        }>,
        HighImpactCategory {}

export interface HrgtOrganizationInfo {
    hrgtAbacusCode: string
    genericCode: string
    hrgtDivision: FilterBase
    hrgtBusinessUnit: FilterBase
    hrgtProductGroup: FilterBase
}

export interface OrganizationalInfo {
    abacusCode: string
    businessUnit: FilterBase
    division: FilterBase
    genericCode: string
    productGroup: FilterBase
    region?: string
    country?: string
    hrgtOrganizationInfo?: HrgtOrganizationInfo
}
export interface ResolvingUnit {
    id: number
    balId: string
    isGlobal: boolean
    abacusCode: string
    activity: string
    addressId: string
    countryCode: string
    locationCity?: string
    city: string
    address: string
    zipCode: string
    isActive: boolean
    coordinatorsEmails: Array<string>
    organizationalInfo: OrganizationalInfo
    classifications: ClassificationIssueForm[]
}

export interface Product {
    cid: string
    name: string
    businessCode: string
    businessLineCode: string
    productGroupCode: string
    parentCID: string
}

export interface FailureMode
    extends Partial<{
        parentId: number
        validation: 0
        id: number
        name: string
        hasChildren: boolean
        isActive: boolean
        isSelectable: boolean
        isDescriptionRequired: boolean
        nodes: Array<FailureMode>
    }> {}

export interface Supplier {
    guid: string
    name: string
}

export interface Warranty
    extends Partial<{
        reference: string
        startDate: string
        endDate: string
    }> {}

export interface CaptureSection
    extends ContainmentAction,
        Partial<{
            issueId: number
            rowVersion: string
            issueStatusId: IssueStatuses
            issueOwnerSupervisorEmail: string
            issueOwner: Employee
            issueOwnerTeam: Array<Employee>
            isCopySentToParties: boolean
            npsAdditionalInfo: RedCardInfoEntry
            sfdcAdditionalInfo: SalesforceIsssueInfo
            partiesReferenceEmails: Array<string>
            partiesReference: Array<Employee>
            subject: string
            summary: string
            whatIsHappening: string
            whyIsThisAProblem: string
            howDoWeKnowWeHaveAProblem: string
            whereDoesDidProblemHappen: string
            whenDoesDidTheProblemHappen: string
            whatAreCustomerExpectations: string
            whoIsAffectedByTheProblem: string
            howManyDefectsFound: number
            issueType: IssueTypes
            issueCreationType: IssueCreationTypes
            customer: Customer
            customerContact: CustomerContact
            highImpact: HighImpact
            isRootCauseRequiredByCustomer: boolean
            targetResolutionAndVerificationDate: string
            targetResolutionDateChangeReasonId?: TargetResolutionDateChangeReasons
            targetResolutionDateChangeReason?: string
            resolvingUnitId: number
            resolvingUnit: ResolvingUnit
            proposedResolutionOwner: Employee
            productCID: string
            productLineLocation: ProductLineLocationProps
            productLineLocationId: number
            product: Product
            failureModeId: number
            failureModeValidation: FailureModeValidation
            failureModeDescription: string
            isFailureModeDescriptionRequired: boolean
            failureModeName: string
            failureModeOriginSource: number
            serialNo: string
            purchaseOrderNo: string
            abbProject: string
            projectName: string
            quantity: number
            customerReference: string
            supplier: Supplier
            commissioningDate: string
            manufacturingDate: string
            warranty: Warranty
            behalfBuCode: string
            behalfCountryCode: string
        }> {}

export interface AssignSection {
    issueId: number
    rowVersion: string
    issueNumber: string
    primaryResolutionOwner?: Employee
    primaryActiveResolutionOwner?: Employee
    resolutionOwnerTeam?: Array<Employee>
    solutionWillBeValidatedByCoordinator: boolean
    quickFixVariant: QuickFixVariants
    quickFixIssueId: number
    quickFixIssueNumber: string
    processOwnersVerificationTeam: Array<Employee>
}

export interface PGRcaCategory {
    id: number
    parentId: string
    name: string
    productGroupCode: number
}

export interface ResolutionSection
    extends Partial<{
        rcaAttachments?: Array<Attachment>
    }> {
    rowVersion: string
    issueId: number
    whatContainmentActionsWereTaken: string
    whatWasTheRootCause: string
    howDidWeResolveThisIssue: string
    resolutionDescription: string
    pgRcaCategory: PGRcaCategory
    isAllActionItemClose: boolean
}

export interface ClosureConfirmation {
    effortScore: number
    satisfactionScore: number
    comments: string
}

export interface ClosureConfirmationStatus {
    surveySentTime?: string
    surveyResponseTime?: string
    isSurveySent: boolean
    isDeliveryFailure: boolean
    isValid: boolean
}

export interface ClosingSection {
    issueId: number
    rowVersion: string
    customerComments: string
    closureConfirmation?: ClosureConfirmation
    isWaitingForExecution?: boolean
    expectedExecutionDate?: string
    actualExecutionDate?: string
    expectedExecutionDateValid?: boolean
    isCurrentUserSubscribedForSurveys: boolean
    closureConfirmationStatus?: ClosureConfirmationStatus
    isSendCustomerSurvey: boolean
    reasonNotToSendCustomerSurvey?: string
}
export interface EffectivenessSection
    extends Partial<{
        issueId: 0
        rowVersion: string
        effectivenessTypeId: EffectivenessTypes
        reasonWhyNotEffectiveOrNotRequired: string
        effectivenessDeadlineDate: string
        effectivenessConfirmationDate: string
        nextEscalationDate: string
        escalationState: number
        createDate: string
    }> {}

export interface ReturnTo {
    isVisible: boolean
    text: string
    url: string
}

export interface IssueAction {
    actionCode: IssueActionsTypes
    name: string
    deadlineDate: string
    isActionOverdue: boolean
    roles: Array<IssueRoles>
}

export interface IssueContext
    extends Partial<{
        issueId: number
        issueNumber: string
        issueStatusId: IssueStatuses
        rowVersion: string
    }> {
    actions?: Array<IssueAction>
    myRoles?: Array<IssueRoles>
}

export enum AttachmentNewIssueStatus {
    Disabled,
    Add,
}

export interface Issue
    extends Partial<{
        issueId: number
        rowVersion: string
        issueNumber: string
        issueStatusId?: IssueStatuses
        context?: IssueContext
        dates?: Dates
        kpis?: Kpis
        isDeleted?: boolean
        captureSection?: CaptureSection
        assignSection?: AssignSection
        resolutionSection?: ResolutionSection
        closingSection?: ClosingSection
        effectivenessSection?: EffectivenessSection
        attachments: Array<Attachment>
        issueLoadDone: boolean
        attachmentsNewIssue?: AttachmentNewIssueStatus
    }> {}

export interface FieldMatrix {
    field: string
    dependsOn: {
        field: string
        value: any
        anyValue: boolean
    }
}

export interface AccessMatrixAction {
    action: string
    dependsOn: Array<FieldMatrix>
}

export interface IssueRcaPreview {
    issueId: number
    issueNumber: string
    failureMode: string
    issueStatusId: IssueStatuses
    customer: string
    description: string
}

export interface RedCardInfoEntry {
    id: number
    createDate: string
    details: RedCardDetails
}

export interface RedCardDetails {
    category: string
    comment: string
    translation: string
    autoTranslation: string
    tsSurveyContext: string
    productServiceGroups: Array<ProductServiceGroups>
    productClass: Array<ProductClass>
    followUps: Array<string>
    followUpDetails: Array<FollowUpDetails>
}

export interface ProductServiceGroups {
    name: string
    buCode: string
    products: Array<string>
}

export interface ProductClass {
    cid: string
    name: string
}

export interface FollowUpDetails {
    confirmedBy: NpsUser
    followUpBy: FollowUpByMethod
    aggreedAction: string
    updates: Array<FollowUpInfoUpdate>
}

export interface NpsUser {
    name: string
    email: string
}

export interface FollowUpInfoUpdate {
    from: NpsUser
    text: string
}

export interface SalesforceIsssueInfo {
    id: number
    subject: string
    originatingCountry: string
    division: string
    businessUnit: string
    productGroup: string
    product: string
    serialNumber: string
    abbOrderNumber: string
    salesOrderNumber: string
    purchaseOrderNumber: string
    serviceAgreement: string
    sapNumber: string
    projectNumber: string
    caseNumber: string
    customerReference: string
    warrantyEndDate: string
    warrantyStartDate: string
    projectName: string
    origin: string
    urlToCase: string
    sfdcCaseId: string
    attachments: Array<SalesforceInfoAttachment>
}

export interface SalesforceInfoAttachment {
    id: number
    url: string
    displayName: string
}
