import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const ELEMENT_HEIGHT = 32

const useStyles = makeStyles<Theme>(
    (theme) => ({
        Container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: theme.spacing(8),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            height: 0,
            overflow: 'hidden',
            borderRadius: ELEMENT_HEIGHT / 2,
            transition: 'height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },
        Loading: {
            height: ELEMENT_HEIGHT,
        },
        Label: {
            ...theme.typography.body2,
            marginLeft: theme.spacing(2),
        },
    }),
    { name: 'BottomLoader' }
)

export interface BottomLoaderProps {
    className: string
    label?: string
    loading: boolean
}

export const BottomLoader = (props: BottomLoaderProps) => {
    const { className, label, loading } = props
    const classes = useStyles(props)
    const containerClasses = loading
        ? clsx(className, classes.Container, classes.Loading)
        : classes.Container
    return (
        <div className={containerClasses}>
            <CircularProgress color="secondary" size={ELEMENT_HEIGHT / 2} />
            <span className={classes.Label}>{label}</span>
        </div>
    )
}

BottomLoader.defaultProps = {
    loading: false,
}

export default BottomLoader
