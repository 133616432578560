import { SvgIcon, SvgIconProps } from '@mui/material'
import clsx from 'clsx'
import { useSvgIconStyles } from './SvgIcon.style'

export const OpenInFull = (props: SvgIconProps) => {
    const { className, fontSize, ...other } = props
    const classes = useSvgIconStyles()
    return (
        <SvgIcon className={clsx(className, classes[fontSize])} {...other}>
            <polygon points="21,11 21,3 13,3 16.29,6.29 6.29,16.29 3,13 3,21 11,21 7.71,17.71 17.71,7.71" />
        </SvgIcon>
    )
}
