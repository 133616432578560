import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useNavMenuItemStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            position: 'relative',
            minHeight: 40,
        },
        link: {
            ...theme.typography.body2,
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: theme.spacing(0, 2),
            width: '100%',
            height: 40,
            textDecoration: 'none',
            color: theme.palette.grey[600],
            fontWeight: theme.typography.fontWeightMedium as number,
            '&$selected': {
                borderRight: `3px solid ${theme.palette.secondary.main}`,
                backgroundColor: theme.palette.red.light,
            },
        },
        gutters: {
            padding: 0,
        },
        icon: {
            width: 16,
            height: 16,
            marginLeft: theme.spacing(1),
        },
        selected: {},
    }),
    { name: 'NavMenuItem' }
)
