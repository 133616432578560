import { Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: 'auto',
            maxWidth: 360,
        },
    }),
    { name: 'SingleLine' }
)

export interface SingleLineProps extends HtmlHTMLAttributes<HTMLSpanElement> {
    text: string
}

export const SingleLine = (props: SingleLineProps) => {
    const classes = useStyles()
    const { children, className, text, ...other } = props
    return (
        <Tooltip title={text}>
            <div className={clsx(className, classes.root)} {...other}>
                {text}
            </div>
        </Tooltip>
    )
}
