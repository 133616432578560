import MuiFormControlLabel, {
    FormControlLabelProps as MuiFormControlProps,
} from '@mui/material/FormControlLabel'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        error: {
            color: theme.palette.secondary.main,
        },
    }),
    { name: 'FormControlLabel' }
)

export interface FormControlLabelProps
    extends MuiFormControlProps,
        Partial<{
            error: boolean
            helperText: string
        }> {}

export const FormControlLabel = (props: FormControlLabelProps) => {
    const { className, error, ...other } = props
    const classes = useStyles(props)
    return (
        <MuiFormControlLabel
            className={clsx(className, !error || classes.error)}
            {...other}
        />
    )
}

FormControlLabel.muiName = 'FormControlLabel'

FormControlLabel.defaultProps = {
    error: false,
}

export default FormControlLabel
