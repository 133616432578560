import CircularLoading from './CircularLoading'
import ContentFixed from './ContentFixed'
import ContentOverlay from './ContentOverlay'
import { ContentLoadingProps } from './types'

export const ContentLoading = (props: ContentLoadingProps) => {
    const { loading, loadingDesc } = props

    return loading ? (
        <>
            <ContentFixed>
                <CircularLoading loading={loading} loadingDesc={loadingDesc} />
            </ContentFixed>
            <ContentOverlay />
        </>
    ) : null
}

export default ContentLoading
