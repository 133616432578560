import { Close } from '@mui/icons-material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { VariantType } from 'notistack'
import { forwardRef, ReactNode } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId } from 'utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        const successColor = theme.palette.green.main
        const errorColor = theme.palette.red.main
        const warningColor = theme.palette.yellow.main
        const infoColor = theme.palette.blue.main

        const contentFactory = (color) => {
            return {
                color: theme.palette.grey[600],
                border: `1px solid ${theme.palette[color].main}`,
                backgroundColor: theme.palette[color].light,
            }
        }

        return {
            root: {
                backgroundColor: theme.palette.background.default,
                borderRadius: theme.shape.borderRadius,
            },
            container: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: theme.shape.borderRadius,
                padding: theme.spacing(1),
                minWidth: 300,
                zIndex: theme.zIndex.snackbar,
            },
            content: {
                display: 'flex',
                alignItems: 'center',
            },
            success: {
                ...contentFactory('green'),
            },
            error: {
                ...contentFactory('red'),
            },
            warning: {
                ...contentFactory('yellow'),
            },
            info: {
                ...contentFactory('blue'),
            },
            icon: {
                marginRight: theme.spacing(1),
            },
            successIcon: {
                color: successColor,
            },
            errorIcon: {
                color: errorColor,
            },
            warningIcon: {
                color: warningColor,
            },
            infoIcon: {
                color: infoColor,
            },
        }
    },
    { name: 'MessageItem' }
)

export interface MessageItemProps {
    key: string
    message: ReactNode
    onClose: (key: string) => void
    variant: VariantType
}

const MessageItem = forwardRef((props: MessageItemProps, ref: any) => {
    const { variant, key, message, onClose, ...other } = props
    const classes = useStyles()

    const isSuccess = variant === 'success' && (
        <CheckCircleIcon className={clsx(classes.icon, classes.successIcon)} />
    )
    const isError = variant === 'error' && (
        <ErrorIcon className={clsx(classes.icon, classes.errorIcon)} />
    )
    const isWarning = variant === 'warning' && (
        <WarningIcon className={clsx(classes.icon, classes.warningIcon)} />
    )
    const isInfo = variant === 'info' && (
        <InfoIcon className={clsx(classes.icon, classes.infoIcon)} />
    )

    const contentButton = (
        <>
            {isSuccess}
            {isError}
            {isWarning}
            {isInfo}
        </>
    )

    return (
        <div className={classes.root} ref={ref} {...other}>
            <div className={clsx(classes.container, classes[variant])}>
                <div className={classes.content}>
                    {contentButton}
                    <Typography variant="body2">{message}</Typography>
                </div>
                <IconButton
                    {...dataTestId('SNACKBAR_CLOSE')}
                    size="small"
                    onClick={() => onClose(key)}
                >
                    <Close />
                </IconButton>
            </div>
        </div>
    )
})

export default MessageItem
