import { useTheme } from '@mui/material'
import { getFailureModeByResolvingUnitAndFailureModeId } from 'api'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { StartAdornmentInfo } from '../../../components'

interface FailureModeStartAdornmentProps {
    resolvingUnitId: number
    failureModeId: number
}

const FailureModeStartAdornment = (props: FailureModeStartAdornmentProps) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [loading, setLoading] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')
    const { resolvingUnitId, failureModeId } = props

    const loadData = () => {
        if (title || loading) {
            return
        }
        setLoading(true)
        getFailureModeByResolvingUnitAndFailureModeId(
            resolvingUnitId,
            failureModeId
        )
            .then((res) => {
                const { itemPath } = res.data
                const fullPath = [...itemPath]
                const parsedPath = fullPath.join(' / ')
                setTitle(parsedPath)
            })
            .catch((err) => {
                dispatch(showSnackbar('Failure mode failed to load', true))
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const isVisible = resolvingUnitId && failureModeId

    return isVisible ? (
        <StartAdornmentInfo
            style={{ marginRight: theme.spacing(1) }}
            details={title}
            onLoadData={loadData}
            loading={loading}
        />
    ) : null
}

export default FailureModeStartAdornment
