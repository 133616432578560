import axios, { AxiosResponse, CancelToken } from 'axios'
import { MyAction, MyIssue, MyIssue2 } from '../types/myDashboard'
import { MyDashboardQuery } from '../types/queries'

const controllerName = '/my'

/**
 * @param {number} page - start index of collection
 * @param {number} pageSize - number of elements return during single request
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getIssuesNeedingActions = (
    page: number,
    pageSize: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<MyAction>>> =>
    axios.get(controllerName + '/actions', {
        cancelToken,
        params: { page, pageSize },
    })

/**
 * @param {object} query - filtring parameters
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const getFiltredIssuesNeedingActions = (
    query: MyDashboardQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<MyAction>>> =>
    axios.post(controllerName + '/actions', { ...query }, { cancelToken })

/**
 * @param {number} page - start index of collection
 * @param {number} pageSize - number of elements return during single request
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getMyAllIssues = (
    page: number,
    pageSize: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<MyIssue>>> =>
    axios.get(controllerName + '/issues', {
        params: { page, pageSize },
        cancelToken,
    })

/**
 * @param {object} query - filtring parameters
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const getFiltredMyAllIssues = (
    query: MyDashboardQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<MyIssue>>> =>
    axios.post(
        controllerName + '/issues',
        {
            ...query,
        },
        { cancelToken }
    )

/**
 * @param {number} issueId - Issue Id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getMyIssue = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<MyIssue>> =>
    axios.get(`${controllerName}/issues/${issueId}`, {
        cancelToken,
    })

/**
 * @param {number} issueId - Issue Id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getMyIssue2 = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<MyIssue2>> =>
    axios.get(`${controllerName}/issues2/${issueId}`, {
        cancelToken,
    })
