import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import {
    Route,
    RouteComponentProps,
    RouteProps,
    StaticContext,
    useHistory,
} from 'react-router'
import { DASHBOARD } from 'routes'
import { RootState } from 'store/types'
import { CcrpRole } from 'types/profile'
import { BaseBar } from '.'
import { RoundedButton } from './Base'
import { AccessDeniedSvg } from './Images'

export interface ProtectedRouteProps extends Omit<RouteProps, 'component'> {
    checkPermissions: (roles: CcrpRole[]) => boolean
}
export interface AccessDeniedViewProps {
    withButton?: boolean
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        button: {
            marginTop: theme.spacing(3),
        },
    }),
    { name: 'AccessDeniedView' }
)

export const AccessDeniedView = (props: AccessDeniedViewProps) => {
    const { withButton = true } = props
    const classes = useStyles()
    const history = useHistory()
    const handleError = () => history.replace(DASHBOARD)
    return (
        <div className={classes.root}>
            <BaseBar />
            <AccessDeniedSvg />
            <Typography variant="h6">
                You haven't got access to this page
            </Typography>
            {withButton && (
                <RoundedButton
                    className={classes.button}
                    variant="contained"
                    onClick={handleError}
                >
                    Go to dashboard
                </RoundedButton>
            )}
        </div>
    )
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
    const { checkPermissions, render: renderProp, ...other } = props
    const roles = useSelector<RootState, CcrpRole[]>(
        (state) => state.dashboard.profile.roles
    )
    const permission = checkPermissions(roles)
    const render = (
        props: RouteComponentProps<
            {
                [x: string]: string
            },
            StaticContext,
            unknown
        >
    ): ReactNode => {
        return permission ? renderProp(props) : <AccessDeniedView />
    }
    return <Route render={render} {...other} />
}
