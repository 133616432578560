import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CircularProgressLoader } from 'components/Base/CircularProgressLoader'
import ActionPlanItemLog from './ActionPlanItemLog'
import { ActionPlanItemLogsProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginTop: theme.spacing(4),
        },
        logs: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    { name: 'ActionPlanItemLogs' }
)

export const ActionPlanItemLogs = (props: ActionPlanItemLogsProps) => {
    const { logs, loading } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Typography variant="subtitle1">
                Action plan items logs ({logs.length})
            </Typography>
            {loading ? (
                <CircularProgressLoader />
            ) : (
                <div className={classes.logs}>
                    {logs.map((log, index) => (
                        <ActionPlanItemLog log={log} key={index} />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ActionPlanItemLogs
