import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button'
import { appInsights } from '../../configs/appInsights'

export interface ButtonProps extends MuiButtonProps {
    eventName?: string
}

export const Button = (props: ButtonProps) => {
    const { eventName, onClick, ...other } = props
    const handleOnClick = (event: any) => {
        eventName &&
            appInsights.trackEvent({
                name: eventName,
            })
        onClick && onClick(event)
    }
    return <MuiButton onClick={handleOnClick} {...other} />
}

export default Button
