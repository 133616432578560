import { withAITracking } from '@microsoft/applicationinsights-react-js'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory, History } from 'history'
import { Component, lazy, Suspense } from 'react'
import { Provider } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import PageLoader from './components/Base/PageLoader'
import OfflinePage from './components/Errors/OfflinePage'
import { appInsights, reactPlugin } from './configs/appInsights'
import axiosConfig from './configs/axios'
import { ISSUE_ROOT, ISSUE_VIEW } from './Issue/routes'
import AppResourcesLoader from './pages/AppResourcesLoader'
import Error404 from './pages/Error404'
import Error5xx from './pages/Error5xx'
import GdprGuard from './pages/GdprGuard'
import Signin from './pages/Signin'
import SigninMock from './pages/SigninMock'
import TokenGenerator from './pages/TokenGenerator'
import * as Routes from './routes'
import createAppStore from './store/appStores'

//ts typing fix
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { SnackbarProvider } from 'notistack'
import { GlobalNotificationProvider } from './DeveloperTools'
import { FAKE_AZURE_AD, TOKEN_GENERATOR } from './features'
import { LegacyBrowserAlert } from './LegacyBrowserAlert'
import { AnalyzerDownloaderPage } from './pages/AnalyzerDownloaderPage'
import { SessionTimeoutGuard } from './pages/SessionTimeoutGuard/SessionTimeoutGuard'
import { SfdcRedirectPage } from './pages/SfdcRedirectPage'
import SnackbarNotifier from './pages/SnackbarNotifier'

const history: History = createBrowserHistory()

const store = createAppStore(history)

axiosConfig(history)
const Dashboard = lazy(() => import('./Dashboard/Root'))

const IssueRootLazy = lazy(() => import('./pages/IssueRoot'))
const Test = lazy(() => import('./Test/Test'))

class MainApp extends Component {
    static getDerivedStateFromError(error: any) {
        const exception = new Error('Application unhandle exception')
        appInsights.trackException({
            exception: exception,
            severityLevel: SeverityLevel.Critical,
            measurements: error,
        })
        return { hasError: true }
    }

    render() {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Provider store={store}>
                    <SnackbarProvider maxSnack={4} autoHideDuration={4000}>
                        <ConnectedRouter history={history}>
                            <AuthenticatedTemplate>
                                <GlobalNotificationProvider>
                                    <AppResourcesLoader>
                                        <SessionTimeoutGuard>
                                            <GdprGuard>
                                                <LegacyBrowserAlert>
                                                    <Switch>
                                                        <Route
                                                            path={[
                                                                Routes.DASHBOARD,
                                                                Routes.MY_ACTIONS,
                                                                Routes.MY_ISSUES,
                                                                Routes.MY_ACTION_PLAN_ITEMS,
                                                                Routes.RELEASE_NOTES,
                                                                Routes.LEGACY_RELEASE_NOTES,
                                                                Routes.ACCESS_MATRIX_REQUIRED_FIELDS_EDITOR,
                                                                Routes.ACCESS_MATRIX_ENABLED_FIELDS_EDITOR,
                                                                Routes.EMAIL_THEME_EDITOR,
                                                                Routes.USER_IMPERSONATE,
                                                                Routes.ADMIN_RESOLVING_UNITS,
                                                                Routes.ADMIN_CUSTOMER_CONTACTS,
                                                                Routes.ADMIN_MANAGE_HUBS,
                                                                Routes.ADMIN_PROCESS_OWNERS,
                                                                Routes.ADMIN_PG_RCA_CATEGORIES,
                                                                Routes.ADMIN_FAILURE_MODES,
                                                                Routes.ALL_IN_ONE_REPORT,
                                                                Routes.NC_ALL_IN_ONE_REPORT,
                                                                Routes.NOTIFICATION_SETTINGS,
                                                                Routes.MANAGE_SUPPORT_GROUP,
                                                                Routes.GLOBAL_SEARCH,
                                                                Routes.ADMIN_CLASSIFICATIONS,
                                                            ]}
                                                            render={() => (
                                                                <Suspense
                                                                    fallback={
                                                                        <PageLoader />
                                                                    }
                                                                >
                                                                    <Dashboard />
                                                                </Suspense>
                                                            )}
                                                            exact
                                                        />
                                                        <Route
                                                            path={ISSUE_ROOT}
                                                            render={() => (
                                                                <Suspense
                                                                    fallback={
                                                                        <PageLoader />
                                                                    }
                                                                >
                                                                    <IssueRootLazy />
                                                                </Suspense>
                                                            )}
                                                        />
                                                        <Route
                                                            path="/offline"
                                                            component={
                                                                OfflinePage
                                                            }
                                                        />
                                                        <Route
                                                            path={
                                                                Routes.ERROR_500
                                                            }
                                                            component={Error5xx}
                                                        />
                                                        {TOKEN_GENERATOR && (
                                                            <Route
                                                                path={
                                                                    Routes.TOKEN_GENERATOR
                                                                }
                                                                component={
                                                                    TokenGenerator
                                                                }
                                                            />
                                                        )}
                                                        <Route
                                                            path="/en/app/"
                                                            component={
                                                                SfdcRedirectPage
                                                            }
                                                        />
                                                        <Redirect
                                                            from="/:lang/complaint/:id"
                                                            to={ISSUE_VIEW}
                                                        />
                                                        <Route
                                                            path={
                                                                Routes.ANALYZER_RAPORT
                                                            }
                                                            render={() => (
                                                                <AnalyzerDownloaderPage />
                                                            )}
                                                        />
                                                        <Route
                                                            path={
                                                                Routes.ANALYZER_NC_REPORT
                                                            }
                                                            render={() => (
                                                                <AnalyzerDownloaderPage
                                                                    isUnitInternal={
                                                                        true
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                        <Route
                                                            component={Error404}
                                                            path={
                                                                Routes.NOT_FOUND_404
                                                            }
                                                        />
                                                        <Route
                                                            component={Error404}
                                                        />
                                                    </Switch>
                                                </LegacyBrowserAlert>
                                            </GdprGuard>
                                        </SessionTimeoutGuard>
                                    </AppResourcesLoader>
                                </GlobalNotificationProvider>
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                {window.location.pathname === Routes.TEST ? (
                                    <Route
                                        path={Routes.TEST}
                                        render={() => (
                                            <Suspense
                                                fallback={
                                                    <PageLoader
                                                        overlay={false}
                                                    />
                                                }
                                            >
                                                <Test />
                                            </Suspense>
                                        )}
                                        exact
                                    />
                                ) : FAKE_AZURE_AD ? (
                                    <SigninMock />
                                ) : (
                                    <Signin />
                                )}
                            </UnauthenticatedTemplate>
                        </ConnectedRouter>
                        <SnackbarNotifier />
                    </SnackbarProvider>
                </Provider>
            </LocalizationProvider>
        )
    }
}
export default withAITracking(reactPlugin, MainApp)
