import { Close, Search } from '@mui/icons-material'
import {
    CircularProgress,
    IconButton,
    InputBase,
    InputBaseProps,
    Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

import { SEARCH_BAR_BASE_ELEMENT } from '../../consts/selectorIds'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'inline-flex',
            width: '100%',
            height: 56,
            position: 'relative',
            alignItems: 'center',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            '&$dense': {
                height: 42,
            },
        },
        dense: {},
        leftIcon: {
            margin: theme.spacing(1.5),
            '&$dense': {
                margin: theme.spacing(1),
            },
        },
        close: {
            margin: theme.spacing(0, 1.5),
            '&$dense': {
                margin: theme.spacing(1),
            },
        },
        input: { flexGrow: 1, margin: theme.spacing(0, 1) },
    }),
    { name: 'SearchBarBase' }
)

export interface SearchBarBaseProps
    extends React.HTMLAttributes<HTMLDivElement> {
    disabled?: boolean
    loading?: boolean
    InputBaseProps?: InputBaseProps
    margin?: 'dense' | 'normal'
    onClose?: () => void
}

export const SearchBarBase: React.FC<SearchBarBaseProps> = (props) => {
    const {
        children,
        className,
        disabled = false,
        loading = false,
        onClose,
        margin = 'normal',
        InputBaseProps = {},
        ...other
    } = props
    const classes = useStyles(props)
    return (
        <div
            className={clsx(
                className,
                classes.root,
                margin === 'dense' && classes.dense
            )}
            {...other}
        >
            {loading ? (
                <CircularProgress
                    className={clsx(
                        classes.leftIcon,
                        margin === 'dense' && classes.dense
                    )}
                    size={16}
                    color="secondary"
                />
            ) : (
                <Search
                    className={clsx(
                        classes.leftIcon,
                        margin === 'dense' && classes.dense
                    )}
                    fontSize="small"
                    color="primary"
                />
            )}
            <InputBase
                disabled={disabled}
                className={classes.input}
                {...InputBaseProps}
                {...SEARCH_BAR_BASE_ELEMENT.SEARCH_TEXT_INPUT}
            />
            {onClose && (
                <IconButton
                    className={clsx(
                        classes.close,
                        margin === 'dense' && classes.dense
                    )}
                    disabled={disabled}
                    size="small"
                    onClick={() => onClose && onClose()}
                    {...SEARCH_BAR_BASE_ELEMENT.CLOSE_BUTTON}
                >
                    <Close fontSize="small" color="primary" />
                </IconButton>
            )}
            {children}
        </div>
    )
}

export default SearchBarBase
