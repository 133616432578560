import { makeStyles } from '@mui/styles'
import {
    AccountTypeSelect,
    FormGridItem,
    GridContainer,
    MultiCustomerHqsSelect,
    MultiCustomerSelect,
} from 'components'
import { CustomerFiltersState } from 'types/reportTypes'
import { FilterSection } from '.'
import { FiltersSectionBaseProps } from '../types'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        noFieldSpacing: { marginBottom: 0 },
        searchButton: {
            marginRight: theme.spacing(1),
        },
        divider: {
            display: 'block',
            width: 1,
            height: 24,
            borderLeft: `1px solid ${theme.palette.divider}`,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
        input: {
            marginLeft: theme.spacing(2),
            width: 'auto',
            flexGrow: 1,
        },
        chips: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }),
    { name: 'CustomerFilters' }
)

export interface CustomerFiltersProps extends FiltersSectionBaseProps {
    value: CustomerFiltersState
    onChange: (value: CustomerFiltersState) => void
}

export const CustomerFilters = (props: CustomerFiltersProps) => {
    const { value, onChange, ...other } = props
    const { customers, accountType } = value
    const createChangeHandler =
        (key: keyof CustomerFiltersState) => (newValue: any) => {
            value[key] = newValue
            onChange({ ...value })
        }
    const classes = useStyles()
    const createPlaceholder = (
        key: keyof CustomerFiltersState,
        placeholder: string = 'Click or type to select'
    ) => (Boolean((value[key] as Array<any>)?.length) ? '' : placeholder)
    return (
        <FilterSection {...other}>
            <GridContainer>
                <FormGridItem fullWidth>
                    <AccountTypeSelect
                        className={classes.noFieldSpacing}
                        selectedAccountType={accountType}
                        onAccountTypeChanged={createChangeHandler(
                            'accountType'
                        )}
                    />
                </FormGridItem>
                <FormGridItem fullWidth>
                    <MultiCustomerHqsSelect
                        placeholder={createPlaceholder('customerHeadquarters')}
                        selectedItems={value.customerHeadquarters ?? []}
                        onSelectedItemsChanged={(values) => {
                            onChange({ ...value, customerHeadquarters: values })
                        }}
                    />
                </FormGridItem>
                <FormGridItem fullWidth>
                    <MultiCustomerSelect
                        label="Customer"
                        placeholder={createPlaceholder('customers')}
                        selectedCustomers={customers ?? []}
                        onCustomersChanged={createChangeHandler('customers')}
                    />
                </FormGridItem>
            </GridContainer>
        </FilterSection>
    )
}
