import { ErrorOutline } from '@mui/icons-material'
import InfoIcon from '@mui/icons-material/Info'
import PersonIcon from '@mui/icons-material/Person'
import { Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { HelpTip, PickerTextField, TextField } from 'components'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import { ProductLineLocationProps } from 'Issue/Pickers'
import { memo, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    selectFailureMode,
    selectProduct,
    selectProductLineLocation,
    selectResolvingUnit,
} from 'store/issue'
import { getAdditionalInformation } from 'store/issue/initState'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    IssueActionsTypes,
    IssueCreationTypes,
    IssueRoles,
    IssueStatuses,
    IssueValuesKeys,
} from 'types/enums'
import { CaptureSection, ResolvingUnit } from 'types/issueTypes'
import { AccessMatrixSetting } from 'types/matrixTypes'
import {
    getProductLineLocationName,
    getResolutionUnitName,
} from 'utils/formaters'
import {
    useFieldError,
    useFieldWithError,
    useHasActionInContext,
} from '../../matrix'
import FailureModeStartAdornment from './components/FailureModeStartAdornment'
import ProductStartAdornment from './components/ProductStartAdornment'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
    }),
    {
        name: 'AdditionalProductInformation',
    }
)
export interface NcProductAndSubjectInformationProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        Partial<{
            disabled: boolean
            resolvingUnit: ResolvingUnit
            productCID: string
            productName: string
            productLine: ProductLineLocationProps
            failureModeDescription: string
            failureModeDescriptionRequired?: boolean
            failureModeName: string
            captureSection: CaptureSection
            lightMode: boolean
            onCaptureSectionChanged: (
                key: keyof CaptureSection,
                value: any
            ) => void
            onEditResolvingUnit: () => void
            onEditAbbProduct: () => void
            onEditFailureMode: () => void
            onEditProductLine: () => void
            onFailureModeDescriptionChanged: (desc: string) => void
        }> {}

const ProductAndSubjectInformation = (
    props: NcProductAndSubjectInformationProps
) => {
    const {
        disabled,
        resolvingUnit,
        productCID,
        productName,
        productLine,
        failureModeName,
        failureModeDescriptionRequired,
        failureModeDescription: failureModeDescriptionProp,
        captureSection,
        lightMode = false,
        onEditAbbProduct,
        onEditFailureMode,
        onEditResolvingUnit,
        onEditProductLine,
        onCaptureSectionChanged: onAdditionalInformationChanged,
        onFailureModeDescriptionChanged,
        ...other
    } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const [additionalInformation, setAdditionalInformation] = useState({
        ...getAdditionalInformation(),
    })
    const [failureModeDescription, setFailureModeDescription] = useState('')
    const canEditNpsFailureMode = useSelector<RootState, boolean[]>((state) => {
        const { issue } = state
        const { captureSection } = issue
        if (captureSection?.issueCreationType !== IssueCreationTypes.NPS) {
            return [true, true]
        }

        const failureModeEmpty = captureSection?.failureModeOriginSource !== 2

        const failureModeEmptyDesc = true

        return [failureModeEmpty, failureModeEmptyDesc].map(
            (i) =>
                i &&
                [
                    IssueRoles.Support_Desk,
                    IssueRoles.Issue_Owner,
                    IssueRoles.Issue_Owner_Supervisor,
                    IssueRoles.Issue_Owner_Team,
                ].some((r) => state.issue?.selectedRolesCodes?.includes(r)) &&
                captureSection.issueStatusId === IssueStatuses.Draft
        )
    })

    useEffect(() => {
        setFailureModeDescription(failureModeDescriptionProp ?? '')
        captureSection
            ? setAdditionalInformation({ ...captureSection })
            : setAdditionalInformation({ ...getAdditionalInformation() })
    }, [captureSection, failureModeDescriptionProp])
    const classes = useStyles(props)

    const handleCaptureSectionChanged = (key: keyof CaptureSection, e: any) => {
        onAdditionalInformationChanged &&
            onAdditionalInformationChanged(key, e.target.value)
    }
    const hasToReplaceInactiveRU = useHasActionInContext(
        IssueActionsTypes.To_Replace_Inactive_RU
    )
    const hasToAssignUpdatedRU = useHasActionInContext(
        IssueActionsTypes.To_Assign_Updated_RU
    )
    const inActiveRU =
        resolvingUnit &&
        (hasToReplaceInactiveRU ||
            hasToAssignUpdatedRU ||
            !Boolean(resolvingUnit?.isActive))
    const coordinatorsEmails = [
        ...new Set(resolvingUnit?.coordinatorsEmails ?? []),
    ].join(' • ')

    const resolvingUnitHelperText = useMemo(() => {
        if (!Boolean(resolvingUnit)) return
        else if (resolvingUnit?.isActive) return coordinatorsEmails
        else return `Resolving unit isn't active`
    }, [resolvingUnit?.isActive, coordinatorsEmails])

    const resolvingUnitIdMatrix = useFieldWithError(
        IssueValuesKeys.ResolvingUnitId,
        {
            defaultHelperText: resolvingUnitHelperText,
            disabled,
            defaultError: inActiveRU,
            matrixModifier: (currentMatrix, issueState) => {
                if (
                    issueState.issueStatusId === IssueStatuses.In_Process &&
                    issueState?.selectedRolesCodes?.some(
                        (role) =>
                            role === IssueRoles.Issue_Coordinator &&
                            issueState?.captureSection?.highImpact?.isHighImpact
                    )
                ) {
                    return { ...currentMatrix, isDisabled: true }
                }
                return null
            },
        }
    )
    const matrixRUModifier = (
        matrix: AccessMatrixSetting
    ): AccessMatrixSetting => {
        if (!Boolean(resolvingUnit)) {
            return {
                ...matrix,
                isRequired: true,
                isDisabled: true,
            }
        }
        return matrix
    }
    const productCIDMatrix = useFieldWithError(IssueValuesKeys.ProductCID, {
        disabled,
        defaultHelperText: !resolvingUnit && 'Select Resolving unit first',
        matrixModifier: matrixRUModifier,
    })
    const productLineMatrix = useFieldWithError(
        IssueValuesKeys.ProductLineLocationId,
        {
            disabled,
            defaultHelperText: !resolvingUnit && 'Select Resolving unit first',
            matrixModifier(currentMatrix, issueState) {
                if (
                    issueState.issueStatusId === IssueStatuses.In_Process &&
                    issueState?.selectedRolesCodes?.some(
                        (role) =>
                            role === IssueRoles.Issue_Coordinator &&
                            issueState?.captureSection?.highImpact?.isHighImpact
                    )
                ) {
                    return {
                        ...currentMatrix,
                        isDisabled: true,
                    }
                }

                return {
                    ...matrixRUModifier(currentMatrix),
                    isRequired: false,
                }
            },
        }
    )

    const failureModeIdMatrix = useFieldWithError(
        IssueValuesKeys.FailureModeId,
        {
            disabled: disabled || !canEditNpsFailureMode[0],
            defaultHelperText: !resolvingUnit && 'Select Resolving unit first',
            matrixModifier: matrixRUModifier,
        }
    )
    const failureModeDescriptionMatrix = useFieldError(
        IssueValuesKeys.FailureModeDescription
    )
    const serialNoMatrix = useFieldWithError(IssueValuesKeys.SerialNo, {
        disabled,
    })
    const purchaseOrderNoMatrix = useFieldWithError(
        IssueValuesKeys.PurchaseOrderNo,
        { disabled }
    )
    const abbProjectMatrix = useFieldWithError(IssueValuesKeys.AbbProject, {
        disabled,
    })
    const projectNameMatrix = useFieldWithError(IssueValuesKeys.ProjectName, {
        disabled,
    })

    return (
        <div {...other}>
            <Typography variant="h6">
                Resolving unit, Product and subject information
            </Typography>
            <PickerTextField
                {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.RESOLVING_UNIT_INPUT}
                className={classes.field}
                label="Resolving unit"
                value={getResolutionUnitName(resolvingUnit) ?? ''}
                {...resolvingUnitIdMatrix}
                helperTextIcon={
                    resolvingUnit &&
                    resolvingUnit.coordinatorsEmails &&
                    0 < resolvingUnit.coordinatorsEmails.length && (
                        <PersonIcon fontSize="inherit" />
                    )
                }
                multiline
                onSelect={() => {
                    onEditResolvingUnit && onEditResolvingUnit()
                }}
                startAdornment={
                    inActiveRU && (
                        <HelpTip
                            {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT
                                .RESOLVING_UNIT_HELP_TIP_ERROR}
                            icon={
                                <ErrorOutline
                                    fontSize="small"
                                    style={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                            }
                            size="small"
                            style={{ marginRight: theme.spacing(1) }}
                            title="Resolving unit isn't active"
                        />
                    )
                }
                onClearValue={() => {
                    dispatch(selectResolvingUnit(null))
                }}
            />
            {!lightMode && (
                <PickerTextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT
                        .PRODUCT_LINE_LOCATION_INPUT}
                    className={classes.field}
                    label="Product Line Location"
                    value={getProductLineLocationName(productLine) ?? ''}
                    onSelect={() => onEditProductLine && onEditProductLine()}
                    {...productLineMatrix}
                    multiline
                    helperTextIcon={
                        !resolvingUnit && <InfoIcon fontSize="inherit" />
                    }
                    onClearValue={() =>
                        dispatch(selectProductLineLocation(null))
                    }
                />
            )}
            <PickerTextField
                {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.ABB_PRODUCT_INPUT}
                className={classes.field}
                label="ABB Product/Service"
                value={productName ?? ''}
                onSelect={() => onEditAbbProduct && onEditAbbProduct()}
                {...productCIDMatrix}
                multiline
                helperTextIcon={
                    !resolvingUnit && <InfoIcon fontSize="inherit" />
                }
                startAdornment={
                    <ProductStartAdornment
                        productCID={captureSection?.productCID}
                    />
                }
                onClearValue={() => dispatch(selectProduct(null))}
            />
            <PickerTextField
                {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.FAILURE_MODE_INPUT}
                className={classes.field}
                label="Failure mode"
                value={failureModeName ?? ''}
                onSelect={() => onEditFailureMode && onEditFailureMode()}
                {...failureModeIdMatrix}
                multiline
                helperTextIcon={
                    !resolvingUnit && <InfoIcon fontSize="inherit" />
                }
                startAdornment={
                    <FailureModeStartAdornment
                        resolvingUnitId={resolvingUnit?.id}
                        failureModeId={captureSection?.failureModeId}
                    />
                }
                onClearValue={() => dispatch(selectFailureMode(null))}
            />
            {Boolean(failureModeDescriptionRequired) && (
                <TextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT
                        .FAILURE_MODE_DESCRIPTION_INPUT}
                    className={classes.field}
                    label="Failure mode description"
                    value={failureModeDescription ?? ''}
                    onChange={(e: any) => {
                        setFailureModeDescription(e.target.value)
                    }}
                    onBlur={(
                        e: React.FocusEvent<
                            HTMLInputElement | HTMLTextAreaElement
                        >
                    ) =>
                        onFailureModeDescriptionChanged &&
                        onFailureModeDescriptionChanged(e.target.value)
                    }
                    disabled={!canEditNpsFailureMode[1]}
                    {...failureModeDescriptionMatrix}
                    required={true}
                />
            )}
            {(!lightMode || serialNoMatrix.required) && (
                <TextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.SERIAL_INPUT}
                    className={classes.field}
                    label="Serial number"
                    value={additionalInformation?.serialNo ?? ''}
                    onChange={(e: any) => {
                        setAdditionalInformation((prev) => ({
                            ...prev,
                            serialNo: e.target.value,
                        }))
                    }}
                    onBlur={(e: any) =>
                        handleCaptureSectionChanged('serialNo', e)
                    }
                    {...serialNoMatrix}
                />
            )}
            {(!lightMode || purchaseOrderNoMatrix.required) && (
                <TextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.PURCHES_ORDER_INPUT}
                    className={classes.field}
                    label="Purchase Order"
                    value={additionalInformation?.purchaseOrderNo ?? ''}
                    onChange={(e: any) => {
                        setAdditionalInformation((prev) => ({
                            ...prev,
                            purchaseOrderNo: e.target.value,
                        }))
                    }}
                    onBlur={(e: any) =>
                        handleCaptureSectionChanged('purchaseOrderNo', e)
                    }
                    {...purchaseOrderNoMatrix}
                />
            )}
            {(!lightMode || abbProjectMatrix.required) && (
                <TextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.ABB_PROJECT_INPUT}
                    className={classes.field}
                    label="ABB project number"
                    value={additionalInformation?.abbProject ?? ''}
                    onChange={(e: any) => {
                        setAdditionalInformation((prev) => ({
                            ...prev,
                            abbProject: e.target.value,
                        }))
                    }}
                    onBlur={(e) => handleCaptureSectionChanged('abbProject', e)}
                    {...abbProjectMatrix}
                />
            )}
            {(!lightMode || projectNameMatrix.required) && (
                <TextField
                    {...ISSUE_VIEW_PAGE.PRODUCT_AND_SUBJECT.PROJECT_NAME_INPUT}
                    className={classes.field}
                    label="Project name"
                    value={additionalInformation?.projectName ?? ''}
                    onChange={(e: any) => {
                        setAdditionalInformation((prev) => ({
                            ...prev,
                            projectName: e.target.value,
                        }))
                    }}
                    onBlur={(e: any) =>
                        handleCaptureSectionChanged('projectName', e)
                    }
                    {...projectNameMatrix}
                />
            )}
        </div>
    )
}

ProductAndSubjectInformation.defaultProps = {
    disabled: false,
}

export default memo(ProductAndSubjectInformation)
