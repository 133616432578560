import { DialogProps } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { dataTestId } from 'utils'
import { RevertToNotAssignedQuery } from '../../types/actions'
import TextField from '../Base/TextField'
import Dialog from './Dialog'

const MAX_LENGTH_REASON = 1000

export interface RevertToNotAssignedDialogProps extends DialogProps {
    issueId: number
    rowVersion: string
    issueNumber: string
    onRevertToNotAssigned: (date: RevertToNotAssignedQuery) => void
}

export const RevertToNotAssignedDialog = (
    props: RevertToNotAssignedDialogProps
) => {
    const [reason, setReason] = useState<string>('')
    const [error, setError] = useState<string>('')

    const {
        open,
        issueId,
        issueNumber,
        onClose,
        rowVersion,
        onRevertToNotAssigned,
        ...other
    } = props
    const onTypeReason = (event: any) => {
        setReason(event.target.value)
        if (reason) {
            setError('')
        }
    }
    const onClickReturn = () => {
        if (!reason) {
            setError('Reason is required')
        } else if (MAX_LENGTH_REASON < reason.length) {
            setError(
                `The field Return reason must be a string with a maximum length of ${MAX_LENGTH_REASON}.`
            )
        } else {
            setError('')
            setReason('')
            onRevertToNotAssigned &&
                onRevertToNotAssigned({
                    issueId,
                    reason,
                    rowVersion,
                })
        }
    }
    return (
        <Dialog open={open} {...other}>
            <DialogTitle>Revert to Not Assigned</DialogTitle>
            <DialogContent style={{ minHeight: 164 }}>
                <DialogContentText>
                    {`You are about to revert issue ${issueNumber} to Not Assigned.
                    Message will be sent to Issue Coordinators and status of the
                    issue will change to "Not Assigned". You need to provide a
                    reason for doing so.`}
                </DialogContentText>
                <TextField
                    error={error !== ''}
                    helperText={error}
                    autoFocus
                    label="Return reason"
                    rows={3}
                    multiline
                    value={reason}
                    onChange={onTypeReason}
                    fullWidth
                    {...dataTestId('REVERT_TO_NOT_ASSIGNED_RETURN_REASON')}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => onClose(e, 'backdropClick')}
                    {...dataTestId('REVERT_TO_NOT_ASSIGNED_CLOSE')}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={onClickReturn}
                    {...dataTestId('REVERT_TO_NOT_ASSIGNED_REVERT')}
                >
                    Revert issue
                </Button>
            </DialogActions>
        </Dialog>
    );
}

RevertToNotAssignedDialog.defaultProps = {
    open: false,
    rowVersion: null,
}

export default RevertToNotAssignedDialog
