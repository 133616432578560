import { Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { KeyboardDatePicker } from 'components'
import dayjs, { Dayjs } from 'dayjs'
import { memo, useEffect, useState } from 'react'
import { getAdditionalInformation } from 'store/issue/initState'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueTypes, IssueValuesKeys } from 'types/enums'
import { CaptureSection, Warranty } from 'types/issueTypes'
import PickerTextField from '../../components/Base/PickerTextField'
import TextField from '../../components/Base/TextField'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { MAX_INTEGER } from '../../consts/values'
import { useFieldWithError } from '../matrix'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
    }),
    {
        name: 'AdditionalProductInformation',
    }
)

export interface AdditionalProductInformationProps
    extends React.HTMLAttributes<HTMLDivElement>,
        Partial<{
            disabled: boolean
            lightMode: boolean
            onEditSupplier: () => void
            onClearSupplier: () => void
            onCaptureSectionChanged: (
                propName: keyof CaptureSection,
                value: any
            ) => void
            onWarrantyChanged: (propName: keyof Warranty, value: any) => void
            captureSection?: CaptureSection
        }> {}

const AdditionalProductInformationBase = (
    props: AdditionalProductInformationProps
) => {
    const {
        disabled,
        lightMode,
        onEditSupplier,
        onClearSupplier,
        captureSection,
        onCaptureSectionChanged,
        onWarrantyChanged,
        ...other
    } = props
    const classes = useStyles(props)
    const [additionalInformation, setAdditionalInformation] =
        useState<CaptureSection>({
            ...getAdditionalInformation(),
        })
    useEffect(() => {
        captureSection
            ? setAdditionalInformation({
                  ...captureSection,
                  commissioningDate: captureSection?.commissioningDate,
                  manufacturingDate: captureSection?.manufacturingDate,
                  warranty: {
                      ...captureSection.warranty,
                      startDate: captureSection.warranty?.startDate,
                      endDate: captureSection.warranty?.endDate,
                  },
              })
            : setAdditionalInformation({ ...getAdditionalInformation() })
    }, [captureSection])
    const warranty = additionalInformation.warranty || {}
    const quantityError = MAX_INTEGER <= additionalInformation.quantity
    const issueType = captureSection?.issueType || IssueTypes.External
    const quantityField = useFieldWithError(IssueValuesKeys.Quantity, {
        defaultHelperText:
            quantityError &&
            `The field Quantity must be between 0 and ${MAX_INTEGER}.`,
        defaultError: quantityError,
        disabled,
    })
    const customerReferenceIdField = useFieldWithError(
        IssueValuesKeys.CustomerReference,
        { disabled }
    )
    const supplierField = useFieldWithError(IssueValuesKeys.SupplierGuid, {
        disabled,
    })
    const commissioningDateField = useFieldWithError(
        IssueValuesKeys.CommissioningDate,
        { disabled }
    )
    const manufacturingDateField = useFieldWithError(
        IssueValuesKeys.ManufacturingDate,
        { disabled }
    )
    const warrantyReferenceField = useFieldWithError(
        IssueValuesKeys.WarrantyReference,
        { disabled }
    )
    const handleCaptureSectionChanged = (
        key: keyof CaptureSection,
        value: any
    ) => {
        onCaptureSectionChanged && onCaptureSectionChanged(key, value)
    }
    const handleCaptureSectionDateChanged = (
        key: keyof CaptureSection,
        date: Dayjs
    ) => {
        onCaptureSectionChanged &&
            onCaptureSectionChanged(key, date.endOf('day'))
    }
    const [state, setState] = useState(true)
    return (
        <Collapse
            in={
                !lightMode ||
                (quantityField.required && issueType !== IssueTypes.Internal_NC)
            }
        >
            <div {...other}>
                <Typography variant="h6">
                    Additional product information
                </Typography>
                <Collapse in={issueType !== IssueTypes.Internal_NC}>
                    {(!lightMode || quantityField.required) && (
                        <TextField
                            disabled={disabled}
                            {...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                .QUANTITY_INPUT}
                            className={classes.field}
                            label="Quantity impacted"
                            type="number"
                            value={additionalInformation?.quantity ?? ''}
                            onKeyPress={(e) =>
                                !Number.isInteger(
                                    Number(
                                        String.fromCharCode(
                                            e.keyCode || e.which
                                        )
                                    )
                                ) && e.preventDefault()
                            }
                            onChange={(e) => {
                                if (!state) return
                                let value = Number(e.target.value)
                                value = value < 0 ? 0 : value
                                setAdditionalInformation((prev) => ({
                                    ...prev,
                                    quantity: Number(
                                        value === 0 ? null : value
                                    ),
                                }))
                            }}
                            onBlur={() =>
                                handleCaptureSectionChanged(
                                    'quantity',
                                    additionalInformation.quantity
                                )
                            }
                            onWheel={() => setState(false)}
                            onMouseLeave={() => setState(true)}
                            {...quantityField}
                        />
                    )}
                </Collapse>
                {!lightMode && (
                    <>
                        <TextField
                            disabled={disabled}
                            {...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                .CUSTOMER_REFERENCE_INPUT}
                            className={classes.field}
                            label="Customer reference"
                            value={
                                additionalInformation?.customerReference ?? ''
                            }
                            onChange={(e: any) => {
                                setAdditionalInformation((prev) => ({
                                    ...prev,
                                    customerReference: e.target.value,
                                }))
                            }}
                            onBlur={(e) =>
                                handleCaptureSectionChanged(
                                    'customerReference',
                                    e.target.value
                                )
                            }
                            {...customerReferenceIdField}
                        />
                        <PickerTextField
                            disabled={disabled}
                            {...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                .SUPPLIER_INPUT}
                            className={classes.field}
                            label="Supplier"
                            value={additionalInformation?.supplier?.name ?? ''}
                            onSelect={() => onEditSupplier && onEditSupplier()}
                            onClearValue={() =>
                                onClearSupplier && onClearSupplier()
                            }
                            {...supplierField}
                        />
                        <KeyboardDatePicker
                            disabled={disabled}
                            label="Commissioning date"
                            className={classes.field}
                            value={
                                captureSection?.commissioningDate
                                    ? dayjs(captureSection?.commissioningDate)
                                          .utc()
                                          .endOf('day')
                                    : null
                            }
                            onChange={(date) => {
                                handleCaptureSectionDateChanged(
                                    'commissioningDate',
                                    date
                                )
                            }}
                            {...commissioningDateField}
                            slotProps={{
                                textField: {
                                    ...commissioningDateField,
                                    ...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                        .COMMISIONING_DATE_PICKER,
                                },
                            }}
                        />
                        <KeyboardDatePicker
                            disabled={disabled}
                            label="Delivery date" //Old "Manufacturing Date"
                            className={classes.field}
                            value={
                                captureSection?.manufacturingDate
                                    ? dayjs(captureSection?.manufacturingDate)
                                          .utc()
                                          .endOf('day')
                                    : null
                            }
                            onChange={(date) =>
                                handleCaptureSectionDateChanged(
                                    'manufacturingDate',
                                    date
                                )
                            }
                            {...manufacturingDateField}
                            slotProps={{
                                textField: {
                                    ...manufacturingDateField,
                                    ...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                        .MANUFACTURING_DATE_PICKER,
                                },
                            }}
                        />
                        <TextField
                            disabled={disabled}
                            {...ISSUE_VIEW_PAGE.ADDITIONAL_INFORMATION
                                .WARRANTY_REFERENCE_INPUT}
                            className={classes.field}
                            label="Warranty reference"
                            value={warranty?.reference ?? ''}
                            onChange={(e: any) => {
                                setAdditionalInformation((prev) => ({
                                    ...prev,
                                    warranty: {
                                        ...prev.warranty,
                                        reference: e.target.value,
                                    },
                                }))
                            }}
                            onBlur={(e) =>
                                onWarrantyChanged &&
                                onWarrantyChanged('reference', e.target.value)
                            }
                            multiline
                            {...warrantyReferenceField}
                        />
                    </>
                )}
            </div>
        </Collapse>
    )
}

AdditionalProductInformationBase.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default memo<AdditionalProductInformationProps>(
    AdditionalProductInformationBase
)
