import axios from 'axios'
import { call, cancelled, takeLatest, put } from 'redux-saga/effects'
import {
    loadIssue,
    reopenIssueDone,
    setIssueActionFail,
    ISSUE_REOPEN_CALL,
} from '..'

import { ReopenIssueCommand } from '../types'
import { reopenIssue } from 'api'
import { showSnackbar } from 'store/app'
import { IssueActionsTypes } from 'types/enums'
import { removeMyActionById } from 'store/myActions'

export function* reopenIssueAction(action: ReopenIssueCommand) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    try {
        yield call(reopenIssue, query, cancelSource.token)
        yield put(reopenIssueDone())
        yield put(
            removeMyActionById(
                query.issueId,
                IssueActionsTypes.Effectiveness_Sustainability_Check
            )
        )
        yield put(showSnackbar('Issue was reopened'))
        yield put(loadIssue(query.issueId))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_REOPEN_CALL, error))
        yield put(showSnackbar("Issue wasn't reopened!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* reopenIssueSaga() {
    yield takeLatest([ISSUE_REOPEN_CALL], reopenIssueAction)
}
