import { makeStyles } from '@mui/styles'
import { AbbLogo, AbbTypography } from 'components'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            position: 'relative',
            width: '100vw',
            height: '100vh',
            backgroundImage: 'url(/assets/abb-background-medium.jpg)',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        },
        logo: {
            position: 'absolute',
            right: theme.spacing(6),

            top: theme.spacing(6),
        },
        content: {
            position: 'absolute',
            top: '30%',
            left: '10%',
            width: '80%',
            maxWidth: 968,
            '& a': {
                color: 'inherit !important',
            },
        },
    }),
    { name: 'TechnicalBreakOverlay' }
)

export interface TechnicalBreakOverlayProps {
    title: string
    description: string
}

export const TechnicalBreakOverlay = (props: TechnicalBreakOverlayProps) => {
    const { title, description } = props
    const classes = useStyles()
    return (
        <div className={classes.root} style={{}}>
            <AbbLogo className={classes.logo} variant="big" />
            <div className={classes.content}>
                <AbbTypography variant="h1" style={{ fontWeight: 600 }}>
                    {title}
                </AbbTypography>
                <AbbTypography
                    abbCursor={false}
                    variant="h4"
                    style={{ fontWeight: 400 }}
                    dangerouslySetInnerHTML={{ __html: description }}
                />
            </div>
        </div>
    )
}
