import { useTheme } from '@mui/material'
import { memo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { IssueActionsTypes } from 'types/enums'
import { Button, ExtendTargetResolutionDateDialog } from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { extendTargetResolutionDate } from '../../store/myIssues/index'
import { ExtendTargetResolutionDateQuery } from '../../types/actions'
import { MyIssue } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import IssueInfoBaseRow from './IssueInfoBaseRow'

export interface MyIssueTileProps
    extends Partial<{
        myIssue: MyIssue
        actionCode: IssueActionsTypes
        isActionOverdue: boolean
        isHighImpact: boolean
        needAttention: boolean
        showActionStatus: boolean
        onIssueChange?: (newIssue: MyIssue) => void
    }> {}

export const MyIssueTile = (props: MyIssueTileProps) => {
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()
    const theme = useTheme()
    const { myIssue, actionCode, showActionStatus } = props

    if (!myIssue) {
        return null
    }

    const {
        issueId: id,
        issueNumber: number,
        issueStatusId,
        subject,
        rowVersion,
        targetResolutionAndVerificationDate,
        canExtendTargetResolutionAndVerificationDate: canExtend,
        issueType,
    } = myIssue
    let actions = canExtend ? (
        <Button
            color="secondary"
            onClick={() => setOpen(true)}
            eventName="Extend target resolution date on dashboard"
            {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.EXTEND_TARGET_DATE}
        >
            Extend target resolution date
        </Button>
    ) : null
    const handleTargetResolutionAndVerificationDate = (
        formData: ExtendTargetResolutionDateQuery
    ) => {
        setOpen(false)
        dispatch(extendTargetResolutionDate(formData))
    }
    return (
        <>
            <ExpandTile
                issueId={id}
                issueNumber={number}
                subject={subject}
                issueStatusId={issueStatusId}
                actionCode={actionCode}
                showActionStatus={showActionStatus}
                isHighImpact={myIssue.isHighImpact}
                expanded={true}
                actions={actions}
            >
                <IssueInfoBaseRow
                    style={{ marginBottom: theme.spacing(1) }}
                    deadlineDateLabel="Target Resolution and Verification date"
                    deadlineDate={targetResolutionAndVerificationDate}
                    issueType={issueType}
                />
            </ExpandTile>
            <ExtendTargetResolutionDateDialog
                issueId={id}
                rowVersion={rowVersion}
                open={open}
                onExtendTargetDate={handleTargetResolutionAndVerificationDate}
                onClose={() => setOpen(false)}
                currentDate={targetResolutionAndVerificationDate}
            />
        </>
    )
}

export default memo<MyIssueTileProps>(MyIssueTile, (prevProps, nextProps) => {
    return prevProps?.myIssue?.rowVersion === nextProps?.myIssue?.rowVersion
})
