import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import AppLogo from '../Base/AppLogo'
import Offline from './Offline'

const appBarHeight: number = 64

const useStyles = makeStyles((theme: AbbTheme) => ({
    appBar: {
        display: 'block',
        width: '100%',
        position: 'fixed',
        top: 0,
        backgroundColor: theme.palette.common.white,
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: theme.shadows[4],
        overflow: 'hidden',
    },
    toolbar: {
        display: 'flex',
        height: appBarHeight,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        alignItems: 'center',
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
        width: 600,
        boxSizing: 'border-box',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: theme.spacing(4),
        '@media (max-width: 600px)': {
            width: '100vw',
            padding: theme.spacing(2),
        },
    },
}))

export interface OfflinePageProps {
    errorHandler?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const OfflinePage = (props: OfflinePageProps) => {
    const classes = useStyles(props)
    let { errorHandler } = props
    errorHandler = errorHandler ? errorHandler : () => window.location.reload()
    return (
        <>
            <header className={classes.appBar}>
                <nav className={classes.toolbar}>
                    <AppLogo />
                </nav>
            </header>
            <div className={classes.content}>
                <Offline errorHandler={errorHandler} />
            </div>
        </>
    )
}

export default OfflinePage
