import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    confirmIssueEffectivenessDone,
    setIssueActionFail,
    ISSUE_CONFRIM_EFFECTIVENESS_CALL,
    IssueStoreState,
    // loadMatrixScheme,
    updateIssueRowVersion,
} from '..'

import { RootState } from 'store/types'
import { postEffectivenessSection } from 'api'
import { removeMyActionById } from 'store/myActions'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { loadIssue } from '../actions'

export function* confirmEffectivenessAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueId, changeLog, effectivenessSection } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const { data: newEffectivenessSection } = yield call(
            postEffectivenessSection,
            effectivenessSection,
            changeLog?.effectiveness ?? null,
            cancelSource.token
        )
        yield put(confirmIssueEffectivenessDone(newEffectivenessSection))
        yield put(updateIssueRowVersion(newEffectivenessSection.rowVersion))
        yield put(loadIssue(issueId))
        yield put(
            removeMyActionById(
                issueId,
                IssueActionsTypes.Effectiveness_Sustainability_Check
            )
        )
        yield put(
            showSnackbar('Effectiveness and sustainability was confirmed')
        )
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_CONFRIM_EFFECTIVENESS_CALL, error))
        yield put(
            showSnackbar(
                "Effectiveness and sustainability wasn't confirmed!",
                true
            )
        )
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* confirmEffectivenessSaga() {
    yield takeLatest(
        [ISSUE_CONFRIM_EFFECTIVENESS_CALL],
        confirmEffectivenessAction
    )
}
