import { Memory, Work } from '@mui/icons-material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { dataTestId } from 'utils'
import { ExpandTile, useTilesStyles } from '.'
import {
    Button,
    DataArea,
    ResolutionUnitValue,
    ReturnToResolutionOwnerDialog,
    ValueView,
} from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { rejectResolution } from '../../store/myActions'
import { ReturnToRO } from '../../types/actions'
import { MyAction } from '../../types/myDashboard'
import IssueInfoBaseRow from './IssueInfoBaseRow'

export interface ToCompleteIssueProps {
    actionIssue: MyAction
}

export const ToCompleteIssue = (props: ToCompleteIssueProps) => {
    const classes = useTilesStyles(props)
    const dispatch = useDispatch()
    const history = useHistory()
    const [state, setState] = useState({
        returnOpen: false,
        surveyConfirmOpen: false,
        completeOpen: false,
        formData: null,
    })
    const { returnOpen } = state
    const { actionIssue, ...other } = props
    const { action, resolvingUnit } = actionIssue
    const actions = (
        <>
            <Button
                onClick={() =>
                    setState((prev) => ({ ...prev, returnOpen: true }))
                }
                eventName="Return to resolution owner on dashboard"
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.RETURN_ISSUE}
            >
                Return issue
            </Button>
            <Button
                color="secondary"
                onClick={() => history.push(`/issue/${actionIssue.issueId}`)}
                eventName="Complete issue on dashboard"
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.COMPLETE_ISSUE}
            >
                Complete issue
            </Button>
        </>
    )

    const handleReturnToRO = (formData: ReturnToRO) => {
        setState((prev) => ({ ...prev, returnOpen: false }))
        dispatch(rejectResolution(formData))
    }
    return (
        <>
            <ExpandTile
                issueNumber={actionIssue.issueNumber}
                subject={actionIssue.subject}
                issueStatusId={actionIssue.issueStatusId}
                isOverdue={action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                showActionStatus={true}
                actionCode={action.actionCode}
                {...other}
                issueId={actionIssue.issueId}
                collapsedSize="110px"
                actions={actions}
            >
                <IssueInfoBaseRow
                    deadlineDate={actionIssue?.action?.deadlineDate}
                    issueType={actionIssue.issueType}
                />
                <ResolutionUnitValue
                    resolvingUnit={resolvingUnit}
                    {...dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT')}
                />
                <DataArea>
                    {actionIssue.productName && (
                        <ValueView
                            className={classes.cell}
                            icon={<Memory className={classes.labelIcon} />}
                            label="Product"
                            value={actionIssue.productName}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.PRODUCT}
                        />
                    )}
                    {actionIssue.customerName && (
                        <ValueView
                            className={classes.cell}
                            icon={<Work className={classes.labelIcon} />}
                            singleLine={false}
                            label="Customer Name"
                            value={actionIssue.customerName}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.CUSTOMER}
                        />
                    )}
                </DataArea>
            </ExpandTile>
            <ReturnToResolutionOwnerDialog
                open={returnOpen}
                message={`You are about to reject resolution for issue ${actionIssue.issueNumber}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                minHeight={220}
                rowVersion={actionIssue.rowVersion}
                number={actionIssue.issueNumber}
                issueId={actionIssue.issueId}
                onReturnToRO={handleReturnToRO}
                onClose={() =>
                    setState((prev) => ({ ...prev, returnOpen: false }))
                }
            />
        </>
    )
}

export default ToCompleteIssue
