import { OpenInNew } from '@mui/icons-material'
import { ButtonBase, ButtonBaseProps, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { MouseEventHandler, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { AbbTheme } from 'styles/createAbbTheme'
import { RenderPropHandler } from 'types/handlers'
import { FlexGrow } from '..'

export interface TileHeaderProps extends Omit<ButtonBaseProps, 'onClick'> {
    disabled?: boolean
    to?: string
    openInNewWindow?: boolean
    renderPrimary: RenderPropHandler
    icon?: ReactNode
    renderSecondary?: RenderPropHandler
    renderAppendix?: RenderPropHandler
    onClick?: MouseEventHandler<HTMLButtonElement>
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            minHeight: 56,
        },
        content: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflow: 'hidden',
            marginRight: theme.spacing(2),
            flexGrow: 1,
        },
        appendixContainer: {},
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            height: 'auto',
            minWidth: 56,
            minHeight: 56,
        },
        primary: {
            display: 'flex',
            flexDirection: 'row',
            whiteSpace: 'nowrap',
        },
    }),
    { name: 'TILEHEADER' }
)

export const TileHeaderBase = (props: TileHeaderProps) => {
    const {
        className,
        style,
        disabled,
        to,
        openInNewWindow = false,
        renderPrimary,
        icon = <OpenInNew fontSize="small" />,
        renderSecondary,
        renderAppendix,
        onClick,
        ...other
    } = props
    const classes = useStyles()
    const history = useHistory()
    const handleHeaderClick: MouseEventHandler<HTMLButtonElement> = (e) => {
        if (openInNewWindow) {
            window.open(to, '_blank')
        } else {
            history.push(to)
        }
        onClick && onClick(e)
    }
    return (
        <ButtonBase
            className={clsx(className, classes.root)}
            onClick={handleHeaderClick}
            disabled={disabled}
            {...other}
        >
            <div className={classes.iconContainer}>{icon}</div>
            <div className={classes.content}>
                <Typography
                    className={classes.primary}
                    variant="body1"
                    component="div"
                >
                    {renderPrimary()}
                </Typography>
                {renderSecondary && (
                    <Typography variant="caption">
                        {renderSecondary()}
                    </Typography>
                )}
            </div>
            <FlexGrow />
            {renderAppendix && (
                <div className={classes.appendixContainer}>
                    {renderAppendix()}
                </div>
            )}
        </ButtonBase>
    )
}
