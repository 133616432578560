import { Collapse, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { changeHubsUsage, getHubsStructure } from 'api'
import { ExpandButton, FlexGrow } from 'components'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { AdminHubStructureBU, AdminHubStructureItem } from 'types/adminTypes'
import { UserProfile } from 'types/profile'
import { dataTestId } from 'utils'
import { AdminConfirmDialog } from '../..'
import { HubStructureGroup } from './HubStructureGroup'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
            marginBottom: theme.spacing(3),
            paddingRight: theme.spacing(2),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: 56,
        },
        title: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'HubStructureTree' }
)

export const HubStructureTree = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const profile = useSelector<RootState, UserProfile>(
        (store) => store?.dashboard?.profile
    )
    const [openHub, setHubOpen] = useState<
        [AdminHubStructureBU, boolean] | null
    >(null)
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState<number[]>([])
    const [items, setItems] = useState<AdminHubStructureItem[]>([])
    const [rootExpanded, setRootExpanded] = useState(false)
    const handleRootExpand = async () => {
        if (!rootExpanded && items.length === 0) {
            try {
                setLoading(true)
                const { data } = await getHubsStructure()
                setRootExpanded(!rootExpanded)
                setItems(data)
            } catch (error) {
                dispatch(showSnackbar('Cannot load hubs structure', true))
            } finally {
                setLoading(false)
            }
        } else {
            setRootExpanded(!rootExpanded)
        }
    }
    const handleOpenDialog = (hub: AdminHubStructureBU, checked: boolean) =>
        setHubOpen([hub, checked])

    const handleHubChange = async () => {
        const hubId = openHub[0].id
        try {
            const checked = openHub[1]
            setHubOpen(null)
            setUpdating((prev) => [...prev, hubId])
            const newItems = [...items]
            const groupIndex = newItems.findIndex((g) =>
                g.businessUnits.some((b) => b.id === hubId)
            )
            if (0 <= groupIndex) {
                newItems[groupIndex] = { ...newItems[groupIndex] }
                const buIndex = newItems[groupIndex].businessUnits.findIndex(
                    (b) => b.id === hubId
                )
                if (0 <= buIndex) {
                    await changeHubsUsage(
                        newItems[groupIndex].businessUnits[buIndex]?.code,
                        checked
                    )
                    setTimeout(() => {
                        newItems[groupIndex].businessUnits[buIndex] = {
                            ...newItems[groupIndex].businessUnits[buIndex],
                            isPgHubs: checked,
                        }
                        setItems(newItems)
                    }, 300)
                }
            }
        } catch (error) {
            dispatch(showSnackbar('Cannot change hub usage', true))
        } finally {
            setUpdating((prev) => {
                prev.splice(
                    prev.findIndex((x) => x === hubId),
                    1
                )
                return [...prev]
            })
        }
    }
    const confirmDialogText = useMemo(() => {
        const hub = Array.isArray(openHub) ? openHub[0] : null
        if (hub) {
            return hub.isPgHubs
                ? `IMPORTANT NOTICE: You are about to change the DIV ${hub.code} HUB structure from PG HUB to DIV HUB. If the new HUBs are empty, please add an active HUB Process Owner who will be responsible for high impact validation.`
                : `IMPORTANT NOTICE: You are about to change the DIV ${hub.code} HUB structure from DIV HUB to PG HUB. If the new HUBs are empty, please add an active HUB Process Owner who will be responsible for high impact validation.`
        }
    }, [openHub])
    return (
        <>
            <div className={classes.root}>
                <div className={classes.header}>
                    <ExpandButton
                        loading={loading}
                        expanded={rootExpanded}
                        onClick={handleRootExpand}
                        {...dataTestId(`HUB_STRUCTURE_EXPAND`)}
                    />
                    <Typography className={classes.title} variant="h6">
                        HUB Structure
                    </Typography>
                    <FlexGrow />
                    <Typography className={classes.title} variant="caption">
                        DIV HUB/PG HUB
                    </Typography>
                </div>
                <Collapse in={rootExpanded}>
                    {items.map((x) => (
                        <HubStructureGroup
                            profile={profile}
                            updating={updating}
                            key={x.divisionCode}
                            divCode={x.divisionCode}
                            divName={x.divisionName}
                            hubs={x.businessUnits}
                            onHubChange={handleOpenDialog}
                        />
                    ))}
                </Collapse>
            </div>
            <AdminConfirmDialog
                open={Array.isArray(openHub)}
                onAccept={handleHubChange}
                onClose={() => setHubOpen(null)}
                disabledMobile={true}
                title="Hub Structure"
            >
                <Typography>{confirmDialogText}</Typography>
            </AdminConfirmDialog>
        </>
    )
}
