import { Divider } from '@mui/material'
import {
    StatusChipLabel,
    TileContainer,
    TileHeaderBase,
    TileSection,
} from 'components'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { generatePath } from 'react-router'
import { IssueGlobalSearchResultItem } from 'types/models'
import { SingleLine } from '../../components/Tile/SingleLine'
import { ISSUE_VIEW } from '../../routes'
import { HighlightsItem } from './HighlightsItem'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export interface GlobalSearchItemProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    item: IssueGlobalSearchResultItem
    searchText: string
}
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        statusChip: {
            margin: theme.spacing(0, 1),
        },
    }),
    { name: 'GlobalSearchItem' }
)

export const GlobalSearchItem = (props: GlobalSearchItemProps) => {
    const { className, item, searchText, ...other } = props
    const classes = useStyles()
    const creationDate = useMemo(() => {
        const date = dayjs(item?.creationDate ?? null)
        if (date.isValid()) {
            return `Creation date: ${date.format('YYYY-MM-DD')}`
        }
        return ''
    }, [item])
    return (
        <TileContainer {...other}>
            <TileHeaderBase
                renderPrimary={() => (
                    <>
                        <b>{item.issueNumber}</b>
                        <StatusChipLabel
                            className={classes.statusChip}
                            issueStatusId={item.issueStatusId}
                        />
                        <SingleLine text={item.issueSubject} />
                    </>
                )}
                renderSecondary={() => creationDate}
                openInNewWindow={true}
                to={generatePath(ISSUE_VIEW, { id: item.issueId })}
            />
            <Divider />
            <TileSection>
                {Object.keys(item?.highlights ?? {})?.map((k) => (
                    <HighlightsItem
                        key={k}
                        name={k}
                        values={item.highlights[k]}
                    />
                )) ?? null}
            </TileSection>
        </TileContainer>
    )
}
