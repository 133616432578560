import { default as AllDone } from './svg/AllDone.svg'

export interface AllDoneSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const AllDoneSvg = (props: AllDoneSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? AllDone}
            width={width}
            height={height}
            alt={alt ?? 'All tasks done'}
            {...other}
        />
    )
}

export default AllDoneSvg
