import { TrendingFlat } from '@mui/icons-material'
import { IconButton, Select } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FilterBase } from 'types/models'

interface SortPanelProps {
    sortKeys: FilterBase<string>[]
    selectedSortKey: string
    onSortKeyChange: (key: string) => void
    selectedDescending: boolean
    onDescendingChange: (descending: boolean) => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            flexGrow: 1,
            minWidth: 175,
            minHeight: 42,
            // border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        sortIcon: {
            color: 'rgba(0, 0, 0, 0.54)',
            transition: 'transform .1s',
            '&$asscending': {
                transform: 'rotateZ(90deg)',
            },
            '&$descending': {
                transform: 'rotateZ(-90deg)',
            },
        },
        asscending: {},
        descending: {},
    }),
    {
        name: 'SortPanel',
    }
)

export const SortPanel = (props: SortPanelProps) => {
    const {
        sortKeys,
        selectedSortKey,
        selectedDescending,
        onSortKeyChange,
        onDescendingChange,
    } = props
    const classes = useStyles()

    const [sortType, setSortType] = useState(selectedDescending)

    const [value, setValue] = useState(selectedSortKey)

    return (
        <div className={clsx(classes.root)}>
            <Select
                // disableUnderline={true}
                value={value}
                onChange={(e) => {
                    if (typeof e.target.value === 'string') {
                        setValue(e.target.value)
                        onSortKeyChange(e.target.value)
                        onDescendingChange(sortType)
                    }
                }}
                sx={{ height: 'inherit', flexGrow: 1, margin: 0 }}
            >
                {sortKeys.map((k) => (
                    <MenuItem key={k.code} value={k.code}>
                        {k.name}
                    </MenuItem>
                ))}
            </Select>
            <IconButton
                // size="small"
                onClick={() => {
                    setSortType(!sortType)
                    onSortKeyChange(value)
                    onDescendingChange(!sortType)
                }}
            >
                <TrendingFlat
                    className={clsx(
                        classes.sortIcon,
                        sortType ? classes.asscending : classes.descending
                    )}
                />
            </IconButton>
        </div>
    )
}

export default SortPanel
