import { ListItemText } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { BaseTextFieldProps } from '@mui/material/TextField'
import { searchCustomersHeadquarters } from 'api'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, useState } from 'react'
import { useDebounce } from 'react-use'
import { Customer } from 'types/issueTypes'
import { dataTestId } from 'utils'
import { useMultiCustomerSelectStyles } from '.'
import { Chip, ChipLabel } from '../Chips'
import { AutoCompleteInput } from './components'
import { useFieldState } from './utils'

export interface MultiCustomerHqsSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedItems?: Customer[]
    onSelectedItemsChanged?: (employees: Customer[]) => void
}

export const MultiCustomerHqsSelect = (props: MultiCustomerHqsSelectProps) => {
    const {
        disabled,
        selectedItems = [],
        onSelectedItemsChanged,
        error: errorProps,
        helperText: helperTextProps,
        ...other
    } = props
    const classes = useMultiCustomerSelectStyles()
    const [{ loading, options, error, helperText }, setState] =
        useFieldState<Customer>()
    const [searchText, setSearchText] = useState('')
    useDebounce(
        async () => {
            try {
                if (loading || !searchText || searchText.length < 3) {
                    return
                }
                setState({ loading: true, error: null, helperText: null })
                const { data: customersHq } = await searchCustomersHeadquarters(
                    searchText
                )

                if (customersHq?.length === 1) {
                    onSelectedItemsChanged([...selectedItems, ...customersHq])
                    setSearchText('')
                    setState({ loading: false, options: [] })
                } else {
                    setState({ loading: false, options: [...customersHq] })
                }
            } catch (error) {
                setState({
                    loading: false,
                    error,
                    helperText: 'Cannot load employees list',
                })
            }
        },
        1000,
        [searchText]
    )
    const getOptionLabel = (option: Customer): string => {
        return option?.name ?? ''
    }
    const getOptionSelected = (option: Customer, value: Customer): boolean => {
        return option?.guid === value?.guid
    }
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | Customer>
    ) => {
        setSearchText('')
        onSelectedItemsChanged &&
            onSelectedItemsChanged([...values] as Customer[])
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedItems ?? []}
            options={[...options]}
            inputValue={searchText}
            onInputChange={(e, value, reason) => {
                if (reason === 'input') {
                    setSearchText(value)
                } else if (reason === 'clear') {
                    setSearchText('')
                }
            }}
            onBlur={() => setSearchText('')}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No Customer HQ. Enter at least 3 characters…'}
            loading={loading && options.length === 0}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) => {
                          const label = state.getOptionLabel(f)
                          return label
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      })
            }}
            loadingText={'Loading Customer HQ…'}
            disableClearable={Boolean(loading || disabled)}
            renderOption={(props, option) => (
                <li {...props}>
                    <ListItemText
                        primary={
                            <>
                                {option.name}
                                <ChipLabel
                                    className={classes.chipLabel}
                                    label={option.guid}
                                    variant="grey"
                                />
                            </>
                        }
                        secondary={option?.address?.full ?? ''}
                    />
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip label={option.name} {...getTagProps({ index })} />
                    )
                })
            }
            renderInput={(params) => {
                return (
                    <AutoCompleteInput
                        label="Customer HQ"
                        error={Boolean(error) || errorProps}
                        helperText={helperText || helperTextProps}
                        params={params}
                        loading={loading}
                        {...dataTestId('CUSTOMER_HQ')}
                        {...other}
                    />
                )
            }}
        />
    )
}

export default MultiCustomerHqsSelect
