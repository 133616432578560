import { Apartment } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AutoCompleteInput } from 'components'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { getOptionLabel } from './utils'

export interface DivisionSelectProps extends Partial<BaseTextFieldProps> {
    selectedDivision: FilterBase<string>
    onDivisionChanged: (item: FilterBase<string>) => void
    displayInactive?: boolean
}

export const DivisionSelect = (props: DivisionSelectProps) => {
    const {
        label = 'Business Area',
        disabled,
        selectedDivision,
        onDivisionChanged,
        error: errorProps,
        helperText: helperTextProps,
        displayInactive,
        ...other
    } = props

    const allOptions = useSelector(
        (state: RootState) => state.resources.divisions
    )
    const options = useMemo(
        () => allOptions.filter((x) => (displayInactive ? true : x.isActive)),
        [allOptions]
    )
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option.code === value.code
    }

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedDivision ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Business Area'}
            loadingText={'Loading Business Area…'}
            disableClearable={false}
            onChange={(e, newValue: any) => {
                onDivisionChanged(newValue)
            }}
            renderOption={(props, option) => (
                <li {...props}>{getOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label={label}
                    renderIcon={(props) => <Apartment {...props} />}
                    {...other}
                />
            )}
        />
    )
}

export default DivisionSelect
