import { MenuItem, MenuItemProps } from '@mui/material'
import clsx from 'clsx'
import { appInsights } from 'configs/appInsights'
import { NavLink } from 'react-router-dom'
import { useNavMenuItemStyles } from '.'

export interface NavMenuItemProps extends Omit<MenuItemProps, 'button'> {
    to: string
    level?: number
    eventName?: string
}

export const NavMenuItem = (props: NavMenuItemProps) => {
    const {
        className,
        level = 0,
        selected,
        to,
        children,
        eventName,
        ...other
    } = props
    const classes = useNavMenuItemStyles(props)

    const handleOnClick = () => {
        eventName &&
            appInsights.trackEvent({
                name: eventName,
            })
    }
    return (
        <MenuItem
            selected={selected}
            classes={{
                gutters: classes.gutters,
            }}
            className={clsx(className, classes.root, classes.gutters)}
            {...other}
        >
            <NavLink
                className={clsx(classes.link, selected && classes.selected)}
                style={{ marginLeft: 8 * level }}
                activeClassName={classes.selected}
                isActive={(match) => {
                    if (!match) {
                        return false
                    }
                    return (
                        match.isExact &&
                        match.url?.toLocaleLowerCase() ===
                            to?.toLocaleLowerCase()
                    )
                }}
                to={to}
                onClick={handleOnClick}
            >
                {children}
            </NavLink>
        </MenuItem>
    )
}

export default NavMenuItem
