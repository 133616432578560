import { CircularProgress, Theme } from '@mui/material'

import Error from '@mui/icons-material/Info'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select'
import { makeStyles } from '@mui/styles'

const useStyle = makeStyles<Theme>(
    (theme) => ({
        'ItemLoader-root': {
            display: 'flex',
            justifyContent: 'flex-start',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            height: theme.spacing(2),
        },
        'ItemLoader-progress': {
            marginRight: theme.spacing(2),
        },
        NoItems: {
            padding: theme.spacing(2),
        },
    }),
    { name: 'Select' }
)

export type SelectProps = MuiSelectProps & {
    showNone?: boolean
    loadingLabel?: string
    labelPropName?: string
    valuePropName?: string
    isLoading?: boolean
    label?: string | React.ReactNode
    helperText?: string
    statusIds?: any
    items: Array<any>
    selectedItem?: any
    [ket: string]: any
}

const Select = (props: SelectProps) => {
    const classes = useStyle()

    const {
        labelPropName,
        valuePropName,
        loadingLabel,
        isLoading,
        label,
        selectedItem,
        showNone,
        required,
        className,
        onOpen: onOpenProp,
        onChange: onChangeProp,
        items,
        fullWidth,
        helperText,
        error,
        ...other
    } = props

    const renderList = () => {
        if (items && 0 < items.length) {
            return items.map((item) => (
                <MenuItem key={item[valuePropName]} value={item[valuePropName]}>
                    {item[labelPropName]}
                </MenuItem>
            ))
        } else {
            if (selectedItem) {
                return (
                    <MenuItem value={selectedItem[valuePropName]}>
                        {selectedItem[labelPropName]}
                    </MenuItem>
                )
            } else {
                return (
                    <div className={classes.NoItems}>No items to display…</div>
                )
            }
        }
    }

    const renderLoader = () => (
        <div className={classes['ItemLoader-root']}>
            <CircularProgress
                className={classes['ItemLoader-progress']}
                size={24}
                color="secondary"
            />
            {loadingLabel}
        </div>
    )
    const inputProps: any = {
        value: selectedItem ? selectedItem[valuePropName] : '',
        onChange: onChangeProp,
    }
    return (
        <FormControl
            variant="outlined"
            fullWidth={fullWidth}
            className={className}
        >
            <InputLabel required={required}>{label}</InputLabel>
            <MuiSelect
                variant="outlined"
                onOpen={onOpenProp}
                inputProps={inputProps}
                {...other}
            >
                {showNone && items && 0 < items.length && (
                    <MenuItem value="">None</MenuItem>
                )}
                {isLoading ? renderLoader() : renderList()}
            </MuiSelect>
            {helperText && (
                <FormHelperText error={error}>
                    {error && (
                        <span style={{ marginRight: 6 }}>
                            <Error fontSize="inherit" />
                        </span>
                    )}
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    )
}

Select.defaultProps = {
    labelPropName: 'name',
    valuePropName: 'code',
    value: '',
    showNone: true,
    isLoading: false,
    loadingLabel: '',
    helperText: '',
}

export default Select
