import ExpandTile from './ExpandTile'
import { dataTestId, urlBuilder } from 'utils'
import * as ROUTES from '../../routes'
import { NavButton } from '../../components'
import { MyAction } from '../../types/myDashboard'
import IssueInfoBaseRow from './IssueInfoBaseRow'
import { memo } from 'react'

export interface ToSubmitProps {
    actionIssue: MyAction
}

export const ToSubmit = (props: ToSubmitProps) => {
    const { actionIssue, ...other } = props
    const {
        issueId,
        issueNumber,
        subject,
        issueStatusId,
        isHighImpact,
        action,
    } = actionIssue
    return (
        <ExpandTile
            issueId={issueId}
            issueNumber={issueNumber}
            subject={subject}
            issueStatusId={issueStatusId}
            isOverdue={action.isActionOverdue}
            isHighImpact={isHighImpact}
            actionCode={action.actionCode}
            showActionStatus={true}
            expanded={true}
            actions={
                <NavButton
                    color="secondary"
                    linkProps={{
                        to: urlBuilder(ROUTES.ISSUE_VIEW, { id: issueId }),
                    }}
                    {...dataTestId('DASHBOARD_ISSUE_TILE_TO_SUBMIT')}
                >
                    To Submit
                </NavButton>
            }
            {...other}
        >
            <IssueInfoBaseRow
                deadlineDate={action.deadlineDate}
                issueType={actionIssue.issueType}
            />
        </ExpandTile>
    )
}

export default memo(ToSubmit)
