import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes, ReactNode } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        label: {
            marginTop: theme.spacing(2),
        },
    }),
    { name: 'ContentLoading' }
)

export interface CircularLoadingProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    loading: boolean
    loadingDesc: string | ReactNode
}

const CircularLoading: FC<CircularLoadingProps> = (props) => {
    const { loading, loadingDesc, className, ...other } = props
    const classes = useStyles()

    return loading ? (
        <div className={clsx(classes.root, className)} {...other}>
            <CircularProgress color="secondary" size={32} />
            <div className={classes.label}>{loadingDesc}</div>
        </div>
    ) : null
}

export default CircularLoading
