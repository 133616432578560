import { DesktopDatePicker, DatePickerProps } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs, { Dayjs } from 'dayjs'
import { useState } from 'react'

const DATE_FORMAT = 'YYYY-MM-DD'

export interface AbbKeyboardDatePickerProps extends DatePickerProps<Dayjs> {
    helperTextIcon?: React.ReactNode
    requiredField?: boolean
    isReadOnly?: boolean
}

export const KeyboardDatePicker = (props: AbbKeyboardDatePickerProps) => {
    const {
        helperTextIcon,
        value,
        minDate,
        onChange,
        slotProps,
        isReadOnly = true,
        disablePast,
        ...other
    } = props
    const [isOpen, setIsOpen] = useState(false)

    const openDate = () => {
        setIsOpen(true)
        if (
            value?.isAfter(minDate) &&
            !(disablePast && value?.isBefore(dayjs()))
        ) {
            return
        }
        let dateToSet = minDate ?? value

        if (!dateToSet || (disablePast && dateToSet?.isBefore(dayjs()))) {
            dateToSet = dayjs()
        }
        onChange(dateToSet, {
            validationError: null,
        })
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                autoFocus={false}
                disablePast={disablePast}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{
                    '& .MuiSvgIcon-root': {
                        width: 22,
                        height: 22,
                    },
                    '& .MuiOutlinedInput-root': {
                        borderColor: 'rgba(0,0,0,0.34) !important',
                    },
                }}
                format={DATE_FORMAT}
                value={value ?? dayjs(DATE_FORMAT)}
                view={'day'}
                onOpen={openDate}
                minDate={minDate}
                views={['year', 'month', 'day']}
                onChange={onChange}
                slotProps={
                    isReadOnly
                        ? {
                              ...slotProps,
                              field: {
                                  ...slotProps?.field,
                                  readOnly: true,
                              },
                              textField: {
                                  ...slotProps?.textField,
                                  onClick: () => openDate(),
                              },
                          }
                        : {
                              ...slotProps,
                          }
                }
                {...other}
            />
        </LocalizationProvider>
    )
}

export default KeyboardDatePicker
