import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
    ISSUE_SEND_MESSAGE_CALL,
    SendIssueMessageCommand,
    sendIssueMessageDone,
    setIssueActionFail,
} from '..'
import { sendMessageTemplate } from '../../../api'
import { showSnackbar } from '../../app'

export function* sendIssueMessage(action: SendIssueMessageCommand) {
    const cancelSource = axios.CancelToken.source()
    try {
        const query = action.payload
        yield call(sendMessageTemplate, query, cancelSource.token)
        yield put(sendIssueMessageDone())
        yield put(showSnackbar('Message was sent!'))
    } catch (error) {
        yield put(showSnackbar('Message not send!', true))
        yield put(setIssueActionFail(ISSUE_SEND_MESSAGE_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* sendIssueMessageSaga() {
    yield takeLatest([ISSUE_SEND_MESSAGE_CALL], sendIssueMessage)
}
