import { InputBase, InputBaseProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            minHeight: 42,
            padding: theme.spacing(0, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
        },
        input: {
            width: 'auto',
            maxWidth: 320,
        },
    }),
    {
        name: 'SendMessageInput',
    }
)

export interface SendMessageInputProps
    extends Omit<InputBaseProps, 'fullWidth'> {}

export const SendMessageInput = (props: SendMessageInputProps) => {
    const { className, inputProps = {}, ...other } = props
    const classes = useStyles()
    return (
        <InputBase
            className={clsx(className, classes.root)}
            inputProps={{
                ...inputProps,
                className: clsx(classes.input, inputProps?.className),
            }}
            {...other}
            fullWidth
        />
    )
}
