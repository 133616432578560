import useAutocomplete from '@mui/material/useAutocomplete'
import { makeStyles } from '@mui/styles'
import { searchIssues as searchIssuesApi } from 'api'
import Axios from 'axios'
import clsx from 'clsx'
import { SearchBarBase } from 'components'
import { appInsights } from 'configs/appInsights'
import { ISSUE_VIEW } from 'Issue/routes'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { showSnackbar } from 'store/app'
import { openSearch } from 'store/dashboard'
import { GlobalResourcesState } from 'store/resources'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    IssueSearchResultItem,
    IssueSearchResultItemViewModel,
    IssueSearchResultViewModel,
} from 'types/models'
import { dataTestId } from 'utils'
import { arrayToStringLine, createArrayWithoutNulls } from 'utils/helpers'
import { urlBuilder } from 'utils/urlBuilder'
import { MAIN_MOBILE_MEDIA_QUERY } from './consts'

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        const mobileMedia = `@media ${MAIN_MOBILE_MEDIA_QUERY}`
        return {
            wrapper: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 320,
                height: '100%',
                borderRadius: theme.shape.borderRadius,
                boxSizing: 'border-box',
                transition: 'width ease 0.4s',
                pointerEvents: 'none',
                padding: theme.spacing(0.5, 1),
                marginRight: theme.spacing(1),
                '&$focus': {
                    width: 480,
                    [mobileMedia]: {
                        width: 'calc(100% - 16px)',
                    },
                },
                [mobileMedia]: {
                    position: 'absolute',
                    right: 0,
                    zIndex: theme.zIndex.tooltip,
                },
            },
            root: {
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
                backgroundColor: theme.palette.grey[100],
                pointerEvents: 'all',
                '&$focus': {
                    backgroundColor: theme.palette.common.white,
                    boxShadow: theme.shadows[3],
                },
            },
            list: {
                borderTop: `1px solid ${theme.palette.divider}`,
                width: '100%',
                margin: 0,
                padding: 0,
                zIndex: 1,
                top: 42,
                left: 0,
                position: 'absolute',
                listStyle: 'none',
                backgroundColor: theme.palette.background.paper,
                overflow: 'auto',
                maxHeight: '60vh',
                boxShadow: theme.shadows[3],
                '& li': {
                    display: 'flex',
                    flexDirection: 'column',
                    padding: theme.spacing(1),
                },
                '& li[data-focus="true"]': {
                    backgroundColor: theme.palette.grey[100],
                    cursor: 'pointer',
                },
                '& li:active': {
                    backgroundColor: theme.palette.grey[100],
                },
            },
            itemText: {
                ...theme.typography.body2,
                color: theme.palette.grey[400],
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': 2 /* number of lines to show */,
                '-webkit-box-orient': 'vertical',
            },
            itemTitle: {
                fontWeight: theme.typography.fontWeightBold as number,
                color: theme.palette.grey[600],
            },
            loadMore: {
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 42,
            },
            focus: {},
        }
    },
    { name: 'IssueSearchBar' }
)

export interface IssueSearchBarProps {
    mobile?: boolean
    onClose: () => void
}

export interface IssueSearchBarState {
    loading: boolean
    loadingMore: boolean
    partialIssueNo: string
    pages: Array<IssueSearchResultViewModel>
    items: Array<IssueSearchResultItemViewModel>
    error: any
}

const SEARCH_ISSUES_PAGE_SIZE = 40

let timeout: any = 0

const throttle = (cb: () => void) => {
    if (timeout) clearTimeout(timeout)
    timeout = setTimeout(() => {
        cb()
    }, 300)
}

const IssueSearchBar = (props: IssueSearchBarProps) => {
    const { mobile, onClose } = props
    const classes = useStyles(props)
    const [focus, setFocus] = useState(false)
    const dispatch = useDispatch()
    const [cancelTokenSource, setCancelTokenSource] = useState(
        Axios.CancelToken.source()
    )

    const [{ loading, loadingMore, partialIssueNo, pages, items }, setState] =
        useState<IssueSearchBarState>({
            loading: false,
            loadingMore: false,
            partialIssueNo: '',
            error: null,
            pages: [],
            items: [],
        })
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        popupOpen,
    } = useAutocomplete<IssueSearchResultItemViewModel>({
        clearOnBlur: true,
        options: items,
        onInputChange: (e, value, reason) => {
            if (reason !== 'reset') {
                if (Number(value?.length) < 4) {
                    updateState({ pages: [], items: [] })
                } else {
                    updateState({
                        loading: true,
                    })
                    throttle(() => {
                        searchIssues(value)
                    })
                }
            }
        },
        onHighlightChange: (e, option) => {
            const groupedOptionsLength = groupedOptions.length
            if (option && 0 < groupedOptionsLength) {
                const index = groupedOptions.findIndex(
                    (x) => option.issueNumber === x.issueNumber
                )
                Math.ceil(groupedOptionsLength * 0.9) <= index &&
                    loadMoreIssues()
            }
        },
        getOptionLabel: (option) => option?.issueNumber ?? '',
        onChange: (e, option) =>
            history.push(
                urlBuilder(ISSUE_VIEW, {
                    id: (option as IssueSearchResultItemViewModel).issueId,
                })
            ),
        // getOptionSelected: (option, value) =>
        //     option?.issueNumber?.toUpperCase() ===
        //     value?.issueNumber?.toUpperCase(),
    })
    const history = useHistory()
    useEffect(() => {
        return () => cancelTokenSource?.cancel()
    }, [])
    const handleCloseSearch = () => {
        onClose && onClose()
        dispatch(openSearch(false))
    }
    const updateState = (partialState: Partial<IssueSearchBarState>) => {
        setState((prev) => ({ ...prev, ...partialState }))
    }
    const mapItemTitle = (item: IssueSearchResultItem): string => {
        const issueTypeName = issueType.find(
            (x) => Number(x.code) === item.issueType
        )?.name
        const statusName = complaintStatus.find(
            (x) => Number(x.code) === item.issueStatusId
        )?.name
        return arrayToStringLine(
            createArrayWithoutNulls(item.issueNumber, issueTypeName, statusName)
        )
    }
    const searchIssues = async (partialIssueNo: string) => {
        try {
            cancelTokenSource.cancel(partialIssueNo)
            const newCancelTokenSource = Axios.CancelToken.source()
            updateState({ error: null, partialIssueNo })
            setCancelTokenSource(newCancelTokenSource)
            const { data } = await searchIssuesApi(
                partialIssueNo,
                1,
                SEARCH_ISSUES_PAGE_SIZE,
                newCancelTokenSource.token
            )
            const page: IssueSearchResultViewModel = {
                ...data,
                items: data.items.map((i) => ({
                    ...i,
                    title: mapItemTitle(i),
                })) as Array<IssueSearchResultItemViewModel>,
            }
            if (data.items.length === 0) {
                dispatch(showSnackbar('Issues not found', true))
            }
            const newPages = [page]
            updateState({
                loading: false,
                pages: newPages,
                items: newPages.flatMap((p) => p.items),
            })
        } catch (error) {
            if (cancelTokenSource.token?.reason.message !== partialIssueNo) {
                updateState({
                    loading: false,
                    error,
                    pages: [],
                    items: [],
                })
                dispatch(showSnackbar('Issues searching failed', true))
            }
        }
    }

    const { issueType, complaintStatus } = useSelector<
        RootState,
        GlobalResourcesState
    >((state) => state?.resources)
    const loadMoreIssues = async () => {
        try {
            if (loadingMore || pages[0]?.total <= items?.length) {
                return
            }
            cancelTokenSource.cancel()
            const newCancelTokenSource = Axios.CancelToken.source()
            updateState({ loadingMore: true })
            setCancelTokenSource(newCancelTokenSource)
            const pageNumber = pages.length + 1
            const { data } = await searchIssuesApi(
                partialIssueNo,
                pageNumber,
                SEARCH_ISSUES_PAGE_SIZE,
                newCancelTokenSource.token
            )
            const page: IssueSearchResultViewModel = {
                ...data,
                items: data.items.map((i) => ({
                    ...i,
                    title: mapItemTitle(i),
                })) as Array<IssueSearchResultItemViewModel>,
            }
            const newPages = [...pages, page]
            updateState({
                loadingMore: false,
                pages: newPages,
                items: newPages.flatMap((p) => p.items),
            })
        } catch (error) {
            updateState({
                loadingMore: false,
            })
            dispatch(showSnackbar('Cannot load next page', true))
        }
    }
    return (
        <div
            className={clsx(classes.wrapper, focus && classes.focus)}
            {...dataTestId('DASHBOARD_TOP_BAR_SEARCH_CONTAINER')}
        >
            <SearchBarBase
                className={clsx(classes.root, focus && classes.focus)}
                margin="dense"
                onClose={focus && mobile ? handleCloseSearch : null}
                loading={loading || loadingMore}
                InputBaseProps={{
                    placeholder: 'Search issue by number…',
                    autoFocus: mobile,
                    onFocus: () => setFocus(true),
                    onBlur: () => {
                        onClose && onClose()
                        setFocus(false)
                    },
                    inputProps: {
                        onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                                searchIssues(e.currentTarget.value)
                            }
                        },
                        ...getInputProps(),
                    },
                }}
                {...getRootProps()}
            >
                {popupOpen && groupedOptions.length > 0 ? (
                    <ul
                        className={classes.list}
                        {...getListboxProps()}
                        {...dataTestId('DASHBOARD_TOP_BAR_SEARCH_RESULTS_LIST')}
                    >
                        {groupedOptions.map((option, index) => {
                            return (
                                <li
                                    {...dataTestId(
                                        `DASHBOARD_TOP_BAR_SEARCH_RESULT_ITEM_${option.issueNumber}`
                                    )}
                                    {...getOptionProps({ option, index })}
                                >
                                    <div
                                        className={clsx(
                                            classes.itemText,
                                            classes.itemTitle
                                        )}
                                        {...dataTestId(
                                            'DASHBOARD_TOP_BAR_SEARCH_RESULT_TITLE'
                                        )}
                                        onClick={() =>
                                            appInsights.trackEvent({
                                                name: 'Search issue on Top Menu',
                                            })
                                        }
                                    >
                                        {option.title}
                                    </div>
                                    <div
                                        className={classes.itemText}
                                        {...dataTestId(
                                            'DASHBOARD_TOP_BAR_SEARCH_RESULT_SUBJECT'
                                        )}
                                    >
                                        {option.subject}
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                ) : null}
            </SearchBarBase>
        </div>
    )
}

export default IssueSearchBar
