import EditIcon from '@mui/icons-material/Edit'
import { IconButton, ListItem, ListItemProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        item: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1, 0),
        },
        icon: {
            height: theme.spacing(3),
        },
    }),
    { name: 'ListItemWithEditing' }
)

export interface ListItemWithEditingProps extends ListItemProps {
    onEdit?: () => void
}

export const ListItemWithEditing = (props: ListItemWithEditingProps) => {
    const { onEdit, className, children } = props
    const classes = useStyles()

    return (
        <ListItem className={clsx(classes.item, className)}>
            <div>{children}</div>
            {onEdit && (
                <IconButton onClick={onEdit} size="large">
                    <EditIcon
                        className={classes.icon}
                        {...dataTestId(`EDIT_CUSTOMER_BUTTON`)}
                    />
                </IconButton>
            )}
        </ListItem>
    )
}
