import { default as NoResults } from './svg/NoResults.svg'

export interface NoResultsSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const NoResultsSvg = (props: NoResultsSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? NoResults}
            width={width}
            height={height}
            alt={alt ?? 'No results…'}
            {...other}
        />
    )
}

export default NoResultsSvg
