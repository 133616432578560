import axios, { AxiosResponse, CancelToken } from 'axios'
import { AccessMatrixQuery } from '../types/queries'
import { AccessMatrixItem } from 'types/matrixTypes'
import { IssueRoles } from 'types/enums'
import { ActionItemAccessMatrix, ActionItemStatus } from 'types/actionPlanTypes'

const controllerName = '/issue'

/**
 * @param {object} query - issue status and roles
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const getAccessMatrixScheme = (
    query: AccessMatrixQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<AccessMatrixItem>>> =>
    axios.post(`${controllerName}/access-matrix`, { ...query }, { cancelToken })

export const getAccessMatrixForActionPlan = (
    roleId: IssueRoles[],
    actionItemStatusId: ActionItemStatus,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ActionItemAccessMatrix>> =>
    axios.post(
        `${controllerName}/access-matrix/action-plan`,
        {
            roleId,
            actionItemStatusId,
            actionItemId: null,
        },
        { cancelToken }
    )
