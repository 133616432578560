import ErrorIcon from '@mui/icons-material/Info'
import { FilledTextFieldProps, TextField as MuiTextField } from '@mui/material'

export interface AbbTextFieldProps
    extends Omit<FilledTextFieldProps, 'variant'> {
    endAdornment?: React.ReactNode
    startAdornment?: React.ReactNode
    helperTextIcon?: React.ReactNode
}

export const TextField = (props: AbbTextFieldProps) => {
    const {
        endAdornment,
        startAdornment,
        type,
        InputProps,
        value: valueProp,
        error,
        helperText: helperTextProp,
        helperTextIcon,
        ...other
    } = props
    const fieldType = type ? type : 'string'
    const value = valueProp ? valueProp : ''
    const inputProps = {
        type: fieldType,
        startAdornment: startAdornment,
        endAdornment: endAdornment,
        ...InputProps,
    }
    const helperText = (
        <>
            {error && helperTextProp ? (
                <span style={{ marginRight: 6 }}>
                    <ErrorIcon fontSize="inherit" />
                </span>
            ) : (
                <span style={{ marginRight: 6 }}>{helperTextIcon}</span>
            )}
            {helperTextProp}
        </>
    )
    return (
        <MuiTextField
            variant="filled"
            autoFocus={false}
            error={error}
            helperText={helperText}
            InputProps={{
                disableUnderline: true,
                ...inputProps,
            }}
            value={value}
            {...other}
        />
    )
}

TextField.muiName = 'TextField'

export default TextField
