import { Business } from '@mui/icons-material'
import { Typography } from '@mui/material'
import {
    AdminDialog,
    AdminResolvingUnitsDialogQuery,
    ResolvingUnitsManagePageDialogProps,
} from 'Admin'
import { Button, MultiEmployeeSelect, ValueViewNext } from 'components'
import { ReactNode, useEffect, useState } from 'react'
import { AdminResolvingUnit } from 'types/adminTypes'
import { Employee } from 'types/issueTypes'
import { arrayToStringLine, dataTestId } from 'utils'

const getInitQuery = (): AdminResolvingUnitsDialogQuery => ({
    coordinators: [],
    processOwners: [],
})

export const ResolvingUnitsManagePageDialog = (
    props: ResolvingUnitsManagePageDialogProps
) => {
    const { personsIds, adminResolvingUnits, open, onClose, onAddPersons } =
        props
    const [query, setQuery] = useState(getInitQuery())
    const [filtered, setFiltered] = useState<AdminResolvingUnit[]>([])

    useEffect(() => {
        const updatedFiltered = adminResolvingUnits.filter(({ id }) =>
            personsIds.includes(id)
        )
        const filteredCoordinators: Employee[] = []
        const filteredProcessOwners: Employee[] = []
        updatedFiltered.forEach((aru) => {
            aru.processOwners &&
                aru.processOwners.forEach((processOwner) => {
                    filteredProcessOwners.push({
                        ...processOwner,
                    })
                })
            aru.coordinators &&
                aru.coordinators.forEach((coordinator) => {
                    filteredCoordinators.push({
                        ...coordinator,
                    })
                })
        })
        setFiltered(updatedFiltered)
        setQuery({
            processOwners: [],
            coordinators: [],
        })
    }, [personsIds, adminResolvingUnits, open])

    const saveResolvingUnits = () =>
        onAddPersons(
            filtered.map((ru) => ru.id),
            query
        )

    const renderActions = () => (
        <>
            <Button onClick={onClose} {...dataTestId('RU_CLOSE_BUTTON')}>
                Close
            </Button>
            <Button
                {...dataTestId('RU_SAVE_BUTTON')}
                color="secondary"
                onClick={saveResolvingUnits}
                disabled={
                    0 === query.coordinators.length &&
                    0 === query.processOwners.length
                }
            >
                Add
            </Button>
        </>
    )

    const handleChange =
        (fieldName: keyof AdminResolvingUnitsDialogQuery) =>
        (newValue: ReactNode) => {
            setQuery((prev: AdminResolvingUnitsDialogQuery) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }

    return (
        <AdminDialog
            title="Add Resolving Unit person(s)"
            open={open}
            onClose={onClose}
            renderActions={renderActions}
        >
            <ValueViewNext
                renderIcon={(props) => <Business {...props} />}
                label="Selected Resolving units"
            >
                {filtered.map((aru) => (
                    <Typography variant="body2" key={aru.id}>
                        {arrayToStringLine([
                            aru?.balId,
                            aru?.divisionCode,
                            aru?.businessUnitCode,
                            aru?.productGroupCode,
                        ])}
                    </Typography>
                ))}
            </ValueViewNext>
            <MultiEmployeeSelect
                label="RU Process owners"
                employees={query.processOwners}
                onEmployeesChanged={handleChange('processOwners')}
                {...dataTestId('RU_PROCESS_OWNERS_INPUT')}
            />
            <MultiEmployeeSelect
                label="Coordinators"
                employees={query.coordinators}
                onEmployeesChanged={handleChange('coordinators')}
                {...dataTestId('COORDINATOR_INPUT')}
            />
        </AdminDialog>
    )
}
