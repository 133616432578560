import * as ACTIONS from './actionTypes'
import { MyIssue, MyIssue2 } from 'types/myDashboard'
import { ExtendTargetResolutionDateQuery } from 'types/actions'
import {
    ExtendTargetResolutionDateCommand,
    LoadMyIssuesCommand,
    RefreshMyIssueCommand,
} from './types'
import { AnyAction } from 'redux'
import { MyIssuesQuery } from 'types/queries'

export const clearError = () => ({
    type: ACTIONS.MY_ISSUES_CLEAR_ERROR,
})

export const loadMyIssues = (init: boolean = false): LoadMyIssuesCommand => ({
    type: ACTIONS.MY_ISSUES_LOAD_CALL,
    payload: init,
})

export const loadMyIssuesError = (error: any) => ({
    type: ACTIONS.MY_ISSUES_LOAD_ERROR,
    payload: error,
})

export const loadMyIssuesDone = (myIssues: Array<MyIssue | MyIssue2>) => ({
    type: ACTIONS.MY_ISSUES_LOAD_DONE,
    payload: myIssues,
})

export const removeMyIssues = () => ({
    type: ACTIONS.MY_ISSUES_REMOVE_ALL,
})

export const removeMyIssuesById = (id: number) => ({
    type: ACTIONS.MY_ISSUES_REMOVE_BY_ID,
    payload: id,
})

export const refreshMyIssueById = (
    myIssueId: number
): RefreshMyIssueCommand => ({
    type: ACTIONS.MY_ISSUES_REFRESH_BY_ID_CALL,
    payload: myIssueId,
})
export const rrefreshMyIssueByIdError = (error: any) => ({
    type: ACTIONS.MY_ISSUES_REFRESH_BY_ID_ERROR,
    payload: error,
})
export const refreshMyIssueByIdDone = (issue: MyIssue | MyIssue2) => ({
    type: ACTIONS.MY_ISSUES_REFRESH_BY_ID_DONE,
    payload: issue,
})

export const extendTargetResolutionDate = (
    query: ExtendTargetResolutionDateQuery
): ExtendTargetResolutionDateCommand => ({
    type: ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL,
    payload: query,
})
export const extendTargetResolutionDateFail = (error: any) => ({
    type: ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_FAIL,
    payload: error,
})
export const extendTargetResolutionDateDone = (
    query: ExtendTargetResolutionDateQuery
) => ({
    type: ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_DONE,
    payload: query,
})

export const exportMyIssues = (query: MyIssuesQuery = {}): AnyAction => ({
    type: ACTIONS.MY_ISSUES_EXPORT_CALL,
    payload: { query },
})

export const exportMyIssuesDone = (): AnyAction => ({
    type: ACTIONS.MY_ISSUES_EXPORT_DONE,
})

export const exportMyIssuesFail = (error: any): AnyAction => ({
    type: ACTIONS.MY_ISSUES_EXPORT_FAIL,
    payload: error,
})
