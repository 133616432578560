import { FlagOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
    AdminSearchPanelActions,
    CustomerContactsManagePageFields,
    CustomerContactsManagePageResults,
    useCustomerContactsContext,
} from 'Admin'
import { searchAdminCustomers } from 'api'
import {
    CitySelect,
    CountrySelect,
    FormGridItem,
    GridContainer,
    SearchPanel,
    TextField,
} from 'components'
import { ChangeEvent, ReactNode, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            margin: theme.spacing(0, 1),
        },
        tabs: {
            marginBottom: theme.spacing(3),
        },
        field: {
            marginBottom: 0,
        },
    }),
    {
        name: 'CustomerContactsManagePage',
    }
)

const getInitQuery = (): CustomerContactsManagePageFields => ({
    country: null,
    city: null,
    address: '',
    zipCode: '',
    state: '',
    bussinesPartnerGUID: '',
})

export interface CustomersTabProps {}

export const CustomersTab = (props: CustomersTabProps) => {
    const { customerTabSearchResults, updateCustomerTabSearchResults } =
        useCustomerContactsContext()
    const classes = useStyles()
    const [noResults, setNoResults] = useState(false)
    const [isSearching, setISearching] = useState<boolean>(false)
    const [page, setPage] = useState(1)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState<string>('')
    const [query, setQuery] = useState<CustomerContactsManagePageFields>(
        getInitQuery()
    )
    const resetForm = () => {
        setSearchText('')
        setQuery(getInitQuery())
    }
    const handleSearch = async () => {
        try {
            const someFilters = Object.keys(query).some((x) =>
                Boolean(query[x])
            )
            if (!someFilters && searchText === '') {
                dispatch(showSnackbar('Add search criterias', true))
                return
            }
            if (!someFilters && searchText.length < 3) {
                dispatch(showSnackbar('Too short search phrease', true))
                return
            }
            setISearching(true)
            setNoResults(false)
            const res = await searchAdminCustomers({
                cityCode: query.city?.code ? query.city.code : null,
                countryCode: query.country?.code ? query.country.code : null,
                guid: query.bussinesPartnerGUID
                    ? query.bussinesPartnerGUID
                    : null,
                name: searchText,
                state: query.state ? query.state : null,
                street: query.address ? query.address : null,
                zipCode: query.zipCode ? query.zipCode : null,
            })

            res?.data?.length === 0 && setNoResults(true)
            updateCustomerTabSearchResults(res.data)
            setPage(1)
            if (res.status === 204) {
                setNoResults(true)
            }
        } catch (error) {
            dispatch(showSnackbar('Cannot load customers', true))
        } finally {
            setISearching(false)
        }
    }

    const handleChange =
        (fieldName: keyof CustomerContactsManagePageFields) =>
        (newValue: ReactNode) => {
            setQuery((prev: CustomerContactsManagePageFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }

    const handleChangeText =
        (fieldName: keyof CustomerContactsManagePageFields) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setQuery((prev) => ({
                ...prev,
                [fieldName]: e.target.value,
            }))
        }

    const getTextFieldProps = (
        fieldName: keyof CustomerContactsManagePageFields
    ) => {
        return {
            value: query[fieldName] ?? '',
            onChange: handleChangeText(fieldName),
            disabled: isSearching,
        }
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    return (
        <div>
            <SearchPanel
                initOpen={true}
                placeholder="Customer name. Enter at least 3 chars..."
                onEnter={() => !isSearching && handleSearch()}
                searchText={searchText}
                onChange={(text) => {
                    setSearchText(text)
                }}
                onClearText={() => setSearchText('')}
                disabled={isSearching}
            >
                <GridContainer>
                    <FormGridItem>
                        <CountrySelect
                            className={classes.field}
                            disabled={isSearching}
                            selectedCountry={query.country}
                            onCountryChanged={handleChange('country')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <CitySelect
                            className={classes.field}
                            disabled={isSearching}
                            country={query.country}
                            selectedCity={query.city}
                            onCityChanged={handleChange('city')}
                            displayAll
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Address"
                            value={query.address}
                            onChange={handleChangeText('address')}
                            disabled={isSearching}
                            startAdornment={
                                <FlagOutlined
                                    fontSize="small"
                                    className={classes.icon}
                                />
                            }
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Zip code"
                            {...getTextFieldProps('zipCode')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="State"
                            {...getTextFieldProps('state')}
                        />
                    </FormGridItem>
                    <FormGridItem>
                        <TextField
                            className={classes.field}
                            label="Bussines Partner GUID"
                            {...getTextFieldProps('bussinesPartnerGUID')}
                        />
                    </FormGridItem>
                </GridContainer>
                <AdminSearchPanelActions
                    spacing={true}
                    isSearching={isSearching}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>

            <CustomerContactsManagePageResults
                noResults={noResults}
                isSearching={isSearching}
                searchResults={customerTabSearchResults}
                page={page}
                onPageChange={handlePageChange}
            />
        </div>
    )
}
