import { ArrowBack, Menu, MoreVert } from '@mui/icons-material'
import {
    AppBar,
    Grow,
    IconButton,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { FlexGrow } from 'components'
import { appInsights } from 'configs/appInsights'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { saveAsDraft, setLightMode, submitDraft } from 'store/issue'
import { RootState } from 'store/types'

import { MainMenu } from 'Dashboard'
import { MAIN_MOBILE_MEDIA_QUERY } from 'Dashboard/consts'
import { LEFT_MENU_DISPLAY } from 'features'
import { dataTestId } from 'utils'
import { useIssueAppBarStyle } from './IssueAppBar.style'

export const NewIssueBar = (props: any) => {
    const [openMenu, setOpenMenu] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const lightMode = useSelector<RootState, boolean>(
        (state) => state.issue?.lightMode ?? false
    )
    const classes = useIssueAppBarStyle(props)
    const history = useHistory()
    const dispatch = useDispatch()
    const handleLightMode = () => {
        appInsights.trackEvent(
            { name: 'Set Light Mode' },
            { lightMode: !lightMode }
        )
        dispatch(setLightMode(!lightMode))
    }

    const isMobile = useMediaQuery(MAIN_MOBILE_MEDIA_QUERY)
    const handleCloseMenu = () => setOpenMenu(false)
    const handleOpenMenu = () => setOpenMenu(true)
    const menu = useMemo(
        () => <MainMenu onClose={handleCloseMenu} mobile={isMobile} />,
        [isMobile]
    )
    return <>
        <AppBar position="fixed" color="default">
            <Toolbar>
                <IconButton
                    {...dataTestId('NEW_ISSUE_TOP_BAR_BACK')}
                    edge="start"
                    color="inherit"
                    onClick={() => history.replace('/')}
                    size="large">
                    <ArrowBack />
                </IconButton>
                {isMobile && LEFT_MENU_DISPLAY && (
                    <>
                        <IconButton size="large">
                            <Menu onClick={handleOpenMenu} />
                        </IconButton>
                        <SwipeableDrawer
                            anchor="left"
                            open={openMenu}
                            onClose={handleCloseMenu}
                            onOpen={handleOpenMenu}
                        >
                            {menu}
                        </SwipeableDrawer>
                    </>
                )}
                <Typography className={classes.label} variant="subtitle1">
                    Create new issue
                </Typography>
                <FlexGrow />
                <IconButton
                    {...dataTestId('NEW_ISSUE_TOP_BAR_MORE_OPTIONS')}
                    aria-haspopup="true"
                    color="inherit"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    size="large">
                    <MoreVert />
                </IconButton>
                <Popper
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    transition
                    disablePortal
                    style={{ zIndex: 2000 }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom'
                                        ? 'center top'
                                        : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener
                                    onClickAway={() => setAnchorEl(null)}
                                >
                                    <MenuList>
                                        <MenuItem
                                            {...dataTestId(
                                                'NEW_ISSUE_TOP_BAR_CHANGE_MODE'
                                            )}
                                            className={classes.menuItem}
                                            onClick={handleLightMode}
                                            selected={false}
                                        >
                                            {lightMode
                                                ? 'Extended Mode'
                                                : 'Light Mode'}
                                        </MenuItem>
                                        <MenuItem
                                            {...dataTestId(
                                                'NEW_ISSUE_TOP_BAR_SAVE_AS_DRAFT'
                                            )}
                                            className={classes.menuItem}
                                            onClick={() =>
                                                dispatch(saveAsDraft())
                                            }
                                            selected={false}
                                        >
                                            Save as draft
                                        </MenuItem>
                                        <MenuItem
                                            {...dataTestId(
                                                'NEW_ISSUE_TOP_BAR_SUBMIT_ISSUE'
                                            )}
                                            className={classes.menuItem}
                                            onClick={() =>
                                                dispatch(submitDraft(true))
                                            }
                                            selected={false}
                                        >
                                            Submit issue
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Toolbar>
        </AppBar>
        <Toolbar />
    </>;
}

export default NewIssueBar
