import { alpha, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { SELECTABLE_CHIP } from 'consts'
import { HtmlHTMLAttributes, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FilterTooltip } from 'types/models'
import { customize } from 'utils'

const alphaRatio = 0.5

const useStyles = makeStyles<AbbTheme>(
    (theme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
            margin: theme.spacing(0.5),
            padding: theme.spacing(0, 1.5),
            height: 32,
            cursor: 'pointer',
            whiteSpace: 'nowrap',
            borderRadius: 16,
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightMedium as number,
            '&$blue': {
                color: theme.palette.blue.main,
                backgroundColor: theme.palette.blue.light,
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: alpha(
                        theme.palette.blue.light,
                        alphaRatio
                    ),
                },
                '&$selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.blue.main,
                },
            },
            '&$green': {
                color: theme.palette.green.main,
                backgroundColor: theme.palette.green.light,
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: alpha(
                        theme.palette.green.light,
                        alphaRatio
                    ),
                },
                '&$selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.green.main,
                },
            },
            '&$grey': {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.grey[100],
                '&:hover': {
                    color: theme.palette.grey[600],
                    backgroundColor: theme.palette.grey[200],
                    '&$selected': {
                        color: theme.palette.common.white,
                        backgroundColor: theme.palette.grey[400],
                    },
                },
                '&$selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.grey[400],
                },
            },
            '&$red': {
                color: theme.palette.red.main,
                backgroundColor: theme.palette.red.light,
                '&:hover': {
                    color: theme.palette.common.white,
                    backgroundColor: alpha(theme.palette.red.light, alphaRatio),
                },
                '&$selected': {
                    color: theme.palette.common.white,
                    backgroundColor: theme.palette.secondary.main,
                },
            },
            '&$yellow': {
                color: theme.palette.grey[600],
                backgroundColor: theme.palette.yellow.light,
                '&:hover': {
                    backgroundColor: alpha(
                        theme.palette.yellow.light,
                        alphaRatio
                    ),
                },
                '&$selected': {
                    backgroundColor: theme.palette.yellow.main,
                },
            },
            '&$disabled': {
                cursor: 'initial',
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[300],
                pointerEvents: 'none',
            },
            '&$countTag': {
                padding: theme.spacing(0, 1.5, 0, 0.5),
            },
        },
        count: {
            height: 24,
            minWidth: 24,
            marginRight: theme.spacing(1),
            padding: theme.spacing(0, 0.25),
            borderRadius: 12,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.palette.common.white,
            color: theme.palette.common.black,
            border: `1px solid ${theme.palette.divider}`,
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        red: {},
        blue: {},
        yellow: {},
        green: {},
        grey: {},
        selected: {},
        disabled: {},
        countTag: {},
    }),
    {
        name: 'CountabledChip',
    }
)

export type CountabledChipVariants =
    | 'red'
    | 'blue'
    | 'yellow'
    | 'green'
    | 'grey'

export interface CountabledChipProps
    extends HtmlHTMLAttributes<HTMLSpanElement> {
    label: string
    count?: number
    selected?: boolean
    variant?: CountabledChipVariants
    disabled?: boolean
    tooltip?: FilterTooltip
    onSelectChanged?: (state: boolean) => void
}

export const CountabledChip = (props: CountabledChipProps) => {
    const {
        className,
        label,
        count,
        selected = false,
        onSelectChanged,
        variant = 'grey',
        disabled,
        tooltip,
        ...other
    } = props
    const classes = useStyles()
    const handleClick = () => onSelectChanged && onSelectChanged(!selected)
    const [open, setOpen] = useState(false)
    return (
        <Tooltip
            arrow
            title={tooltip?.text}
            open={open}
            onOpen={() =>
                tooltip?.filter === label ? setOpen(true) : setOpen(false)
            }
            onClose={() => setOpen(false)}
        >
            <span
                {...customize(SELECTABLE_CHIP.CHIP_BUTTON, label)}
                className={clsx(
                    className,
                    classes.root,
                    classes[variant],
                    selected && classes.selected,
                    disabled && classes.disabled,
                    Number.isInteger(count) && classes.countTag
                )}
                onClick={handleClick}
                {...other}
            >
                {Number.isInteger(count) && (
                    <span className={classes.count}>{count}</span>
                )}
                {label}
            </span>
        </Tooltip>
    )
}
