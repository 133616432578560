import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Resolution from './Resolution/Resolution'
import {
    IssueStoreState,
    setExpandMode,
    updateResolutionSection,
    saveResolutionSection,
    resolveIssue,
    generateReport,
    verifyResolution,
    verifyContainment,
    ISSUE_RESOLVE_CALL,
    setIssueActionFail,
} from 'store/issue'
import { RootState } from 'store/types'
import useIssueFormStyles from './useIssueFormStyles'
import { ResolutionSection } from 'types/issueTypes'
import { useHistory } from 'react-router'
import { ISSUE_VIEW_PG_CATEGORY } from '../routes'
import { urlBuilder } from 'utils'
import DialogBase from '../../components/Dialogs/DialogBase'

import { ConfirmContainmentActionDialogExtended } from '../../components/Dialogs/ConfirmContainmentActionDialog'
import { AttachmentTypes, IssueTypes, IssueValuesKeys } from 'types/enums'
import { showSnackbar } from 'store/app'

const ResolutionContainer = (
    props: React.HtmlHTMLAttributes<HTMLAnchorElement>
) => {
    const classes = useIssueFormStyles()
    const [verifyOpen, setVerifyOpen] = useState<boolean>(false)
    const [resolveOpen, setResolveOpen] = useState<boolean>(false)
    const [confirmContainmentOpen, setConfirmContainmentOpen] =
        useState<boolean>(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const {
        captureSection,
        resolutionSection,
        expanded,
        rowVersion,
        issueId: id,
        issueNumber,
        issueStatusId,
        attachments,
    } = useSelector<RootState, IssueStoreState>((state) => state?.issue)
    const containmentActionRequired = Boolean(
        captureSection?.isContainmentActionRequired
    )
    const handleSectionChange = (key: keyof ResolutionSection, value: string) =>
        dispatch(updateResolutionSection(key, value))
    const handleSectionSave = () => {
        dispatch(saveResolutionSection())
    }
    const handleResolve = () => {
        if (
            captureSection?.resolvingUnit?.organizationalInfo?.productGroup
                ?.code &&
            !resolutionSection?.pgRcaCategory?.id
        ) {
            dispatch(showSnackbar('Cannot resolve issue!', true))
            dispatch(
                setIssueActionFail(ISSUE_RESOLVE_CALL, {
                    status: 412,
                    errors: [
                        {
                            field: IssueValuesKeys.PgRcaCategory,
                            message: "Field 'PG RCA category' is required",
                        },
                    ],
                })
            )
            setResolveOpen(false)
            return
        }
        dispatch(resolveIssue())
        setResolveOpen(false)
    }
    const handleEditPGCategory = () => {
        history.push(urlBuilder(ISSUE_VIEW_PG_CATEGORY, { id }))
    }
    const handleGenerateReport = (reportType) => {
        dispatch(generateReport(id, reportType))
    }
    const handleVerifyResolution = () => {
        dispatch(verifyResolution())
        setVerifyOpen(false)
    }
    const handleVerifyContainmentAction = (query: ResolutionSection) => {
        dispatch(verifyContainment(query))
        setConfirmContainmentOpen(false)
    }
    return (
        <>
            <Resolution
                issueType={captureSection?.issueType || IssueTypes.External}
                className={classes.section}
                number={issueNumber}
                expanded={Boolean(expanded?.resolve)}
                onExpand={(expanded) =>
                    dispatch(setExpandMode('resolve', expanded))
                }
                issueStatusId={issueStatusId}
                resolvingUnitId={captureSection?.resolvingUnitId}
                containmentActionRequired={containmentActionRequired}
                resolutionSection={resolutionSection ?? null}
                onSectionChnage={handleSectionChange}
                onSectionSave={handleSectionSave}
                onResolved={() => setResolveOpen(true)}
                onVerified={() => setVerifyOpen(true)}
                onVerifyContainmentAction={() =>
                    setConfirmContainmentOpen(true)
                }
                onEditPGCategory={handleEditPGCategory}
                generateRaport={handleGenerateReport}
                attachments={
                    attachments?.filter(
                        (a) =>
                            a.attachmentType ===
                            AttachmentTypes.Issue_Resolution
                    ) ?? []
                }
                rcaAttachments={
                    attachments?.filter(
                        (a) =>
                            a.attachmentType === AttachmentTypes.Resolution_Rca
                    ) ?? []
                }
            />
            <DialogBase
                title="Verify resolution"
                message={`You are about to verify resolution for issue ${issueNumber}. Proceed?`}
                confirmLabel="Verify"
                closeLabel="Cancel"
                onConfirm={handleVerifyResolution}
                onClose={() => setVerifyOpen(false)}
                open={verifyOpen}
            />
            <DialogBase
                title="Resolve issue"
                message={`You are about to resolve issue ${issueNumber}. Proceed?`}
                confirmLabel="Resolve"
                closeLabel="Cancel"
                onConfirm={handleResolve}
                onClose={() => setResolveOpen(false)}
                open={resolveOpen}
            />
            <ConfirmContainmentActionDialogExtended
                open={confirmContainmentOpen}
                rowVersion={rowVersion}
                resolutionSection={resolutionSection}
                onClose={() => setConfirmContainmentOpen(false)}
                onConfirm={handleVerifyContainmentAction}
            />
        </>
    )
}

export default ResolutionContainer
