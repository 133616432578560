import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    BusinessUnitSelect,
    CountrySelect,
    FormGridItem,
    GridContainer,
} from 'components'
import { ISSUE_VIEW_PAGE } from 'consts'
import { useFieldWithError } from 'Issue/matrix'
import { HTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueValuesKeys } from 'types/enums'
import { CaptureSection } from 'types/issueTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
    }),
    { name: 'OnBehalfOf' }
)

export interface OnBehalfOfProps extends HTMLAttributes<HTMLDivElement> {
    onCaptureSectionChanged: (
        propName: keyof CaptureSection,
        value: string
    ) => void
    captureSection: CaptureSection
    disabled: boolean
}

const OnBehalfOf = (props: OnBehalfOfProps) => {
    const classes = useStyles()
    const { onCaptureSectionChanged, captureSection, disabled, ...other } =
        props

    const [state, setState] = useState<CaptureSection>({
        behalfBuCode: '',
        behalfCountryCode: '',
    })
    useEffect(() => {
        setState({ ...captureSection })
    }, [captureSection])

    const divisionOption = useSelector(
        (state: RootState) => state.resources.businessUnits
    )
    const countryOption = useSelector(
        (state: RootState) => state.resources.countries
    )

    const selectedDivision = useMemo(
        () => divisionOption.find((d) => d.code === state.behalfBuCode),
        [state.behalfBuCode]
    )
    const selectedCode = useMemo(
        () => countryOption.find((c) => c.code === state.behalfCountryCode),
        [state.behalfCountryCode]
    )

    const behalfBuCodeField = useFieldWithError(IssueValuesKeys.BehalfBuCode, {
        disabled,
    })
    const behalfCountryCodeField = useFieldWithError(
        IssueValuesKeys.BehalfBuCode,
        {
            disabled,
        }
    )

    return (
        <div {...other}>
            <Typography variant="h6">On behalf of</Typography>
            <GridContainer className={classes.field}>
                <FormGridItem>
                    <BusinessUnitSelect
                        selectedBusinessUnit={selectedDivision ?? null}
                        onBusinessUnitChanged={(businessUnit) =>
                            onCaptureSectionChanged(
                                'behalfBuCode',
                                businessUnit?.code
                            )
                        }
                        {...behalfBuCodeField}
                        {...ISSUE_VIEW_PAGE.ON_BEHALF_OF.BU}
                    />
                </FormGridItem>
                <FormGridItem>
                    <CountrySelect
                        selectedCountry={selectedCode ?? null}
                        onCountryChanged={(country) =>
                            onCaptureSectionChanged(
                                'behalfCountryCode',
                                country?.code
                            )
                        }
                        {...behalfCountryCodeField}
                        {...ISSUE_VIEW_PAGE.ON_BEHALF_OF.COUNTRY}
                    />
                </FormGridItem>
            </GridContainer>
        </div>
    )
}

export default OnBehalfOf
