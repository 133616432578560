import { makeStyles } from '@mui/styles'
import {
    Content,
    ExtendTargetResolutionDateDialog,
    PageTitle,
    TabsView,
} from 'components'
import { appInsights } from 'configs/appInsights'
import { sortElements } from 'Issues/components/utils'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearError,
    exportMyIssues,
    extendTargetResolutionDate,
    refreshMyIssueByIdDone,
} from 'store/myIssues'
import { GlobalResourcesState } from 'store/resources'
import { RootState } from 'store/types'
import { ExtendTargetResolutionDateQuery } from 'types/actions'
import { IssueRoles, IssueStatuses } from 'types/enums'
import { DashboardFilter, FilterBase, TabsFilter } from 'types/models'
import { MyAction2, MyIssue, MyIssue2 } from 'types/myDashboard'
import { MyIssuesQuery } from 'types/queries'
import { NEW_DASHBOARD } from '../../features'
import TabsMyIssues from './TabsMyIssues'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        tabs: {
            marginBottom: theme.spacing(3),
        },
    }),
    {
        name: 'MyIssuesPage',
    }
)

export const MY_ISSUES_FILTERS_STORAGE_KEY = 'ccrp-my-issues-filters'

const getSearchProperties = (): Array<keyof MyIssue> => [
    'issueNumber',
    'subject',
]

export const MyIssuesPage = () => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const { myIssues } = useSelector<RootState, RootState>((state) => state)
    const { loading, loadingDesc, updating, error, items, exportingIssues } =
        myIssues
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(true)
    const [editDate, setEditDate] = useState(false)
    const [issue, setIssue] = useState<MyIssue2 | MyAction2>(null)
    const [wildcard, setWildcard] = useState('')
    const [descending, setDescending] = useState(null)
    const [sortKey, setSortKey] = useState(null)
    const [activeTab, setActiveTab] =
        useState<keyof TabsFilter>('myIssuesFilters')
    const resources = useSelector<RootState, GlobalResourcesState>(
        (state) => state.resources
    )
    const [issueTypesFilters, setIssueTypesFilters] = useState<TabsFilter>({
        myIssuesFilters: [],
        issuesInvolvedFilters: [],
    })
    const [issueStatusesFilters, setIssueStatusesFilters] =
        useState<TabsFilter>({
            myIssuesFilters: [],
            issuesInvolvedFilters: [],
        })
    const [issueRolesFilters, setIssueRolesFilters] = useState<TabsFilter>({
        myIssuesFilters: [],
        issuesInvolvedFilters: [],
    })

    const setDefaultFilters = () => {
        const select = (filter: FilterBase<number>): DashboardFilter => ({
            ...filter,
            selected: true,
        })
        setIssueTypesFilters({
            issuesInvolvedFilters: resources.issueType.map(select),
            myIssuesFilters: resources.issueType.map(select),
        })
        setIssueStatusesFilters(() => {
            const filters = () =>
                resources.complaintStatus
                    .filter((s) => Number(s.code) > 1)
                    .map(select)
                    .map((s) => {
                        if (s.code === IssueStatuses.Completed) {
                            s.selected = false
                        }
                        return s
                    })

            return {
                issuesInvolvedFilters: filters(),
                myIssuesFilters: filters(),
            }
        })
        setIssueRolesFilters(() => {
            const filters = () =>
                resources.roleCode
                    .filter(
                        (r) =>
                            ![
                                IssueRoles.Global_CCRP_Process_Owner,
                                IssueRoles.Action_Creator,
                                IssueRoles.Support_Desk,
                                IssueRoles.Developer,
                            ].includes(r.code)
                    )
                    .map(select)
            return {
                issuesInvolvedFilters: filters(),
                myIssuesFilters: filters(),
            }
        })
    }
    useEffect(() => {
        const data = JSON.parse(
            window.localStorage.getItem(MY_ISSUES_FILTERS_STORAGE_KEY) ?? null
        ) as {
            open: boolean
            issueTypesFilters: TabsFilter
            issueStatusesFilters: TabsFilter
            issueRolesFilters: TabsFilter
        }

        const isValidFilters = (value: TabsFilter) => {
            return (
                !value ||
                value?.issuesInvolvedFilters.length === 0 ||
                value?.myIssuesFilters.length === 0
            )
        }

        if (data) {
            setOpen(data.open)
            if (
                isValidFilters(data?.issueStatusesFilters) ||
                isValidFilters(data?.issueRolesFilters) ||
                isValidFilters(data?.issueTypesFilters)
            ) {
                setDefaultFilters()
                return
            }
            setIssueStatusesFilters(data?.issueStatusesFilters)
            setIssueRolesFilters(data?.issueRolesFilters)
            setIssueTypesFilters(data?.issueTypesFilters)
        } else {
            setDefaultFilters()
        }
    }, [])
    useEffect(() => {
        window.localStorage.setItem(
            MY_ISSUES_FILTERS_STORAGE_KEY,
            JSON.stringify({
                open,
                issueTypesFilters,
                issueStatusesFilters,
                issueRolesFilters,
            })
        )
    }, [
        open,
        issueTypesFilters,
        issueStatusesFilters,
        issueRolesFilters,
        activeTab,
    ])

    const tabsItems = useMemo(() => {
        if (NEW_DASHBOARD)
            return (items as MyIssue2[]).filter((i) => {
                return activeTab === 'myIssuesFilters'
                    ? !i.isUserPartiesReferencesTeam || i?.roles.length > 0
                    : activeTab === 'issuesInvolvedFilters' &&
                          i.isUserPartiesReferencesTeam
            })
        else return items
    }, [activeTab, items])
    const filtredItems = useMemo(() => {
        const someIssueTypes = issueTypesFilters[activeTab].some(
            (x) => x.selected
        )

        const someIssueStatuses = issueStatusesFilters[activeTab].some(
            (x) => x.selected
        )
        const someIssueRoles = issueRolesFilters[activeTab].some(
            (x) => x.selected
        )

        let results = Boolean(wildcard)
            ? tabsItems.filter((x) => {
                  return Boolean(
                      getSearchProperties().find((key) =>
                          String(x[key])
                              .toLocaleUpperCase()
                              .includes(wildcard.toLocaleUpperCase(), 0)
                      )
                  )
              })
            : tabsItems

        if (!someIssueTypes && !someIssueStatuses && !someIssueRoles) {
            return []
        }
        if (someIssueTypes) {
            if (NEW_DASHBOARD) {
                const myIssuesResults = [...results] as MyIssue2[]
                results = myIssuesResults.filter((a) =>
                    issueTypesFilters[activeTab].some(
                        (t) =>
                            t.selected &&
                            Number(t.code) === Number(a.issueTypeId)
                    )
                )
            } else
                results = results.filter((a) => {
                    return issueTypesFilters?.myIssuesFilters.some(
                        (t) =>
                            t.selected && Number(t.code) === Number(a.issueType)
                    )
                })
        }
        if (someIssueStatuses) {
            results = results.filter((a) => {
                return issueStatusesFilters[activeTab].some(
                    (t) =>
                        t.selected && Number(t.code) === Number(a.issueStatusId)
                )
            })
        }
        if (someIssueRoles && activeTab === 'myIssuesFilters') {
            if (NEW_DASHBOARD) {
                const myIssuesResults = [...results] as MyIssue2[]
                results = myIssuesResults.filter((a) => {
                    return issueRolesFilters[activeTab].some(
                        (t) =>
                            t.selected &&
                            a.roleCodes?.some((r) => +r === t.code)
                    )
                })
            } else
                results = results.filter((a) => {
                    return issueRolesFilters[activeTab].some(
                        (t) =>
                            t.selected &&
                            a.roles?.some((r) => r.code === t.code)
                    )
                })
        }
        return results
    }, [
        items,
        issueStatusesFilters,
        issueTypesFilters,
        issueRolesFilters,
        wildcard,
        activeTab,
        tabsItems,
    ])
    const issueTypesCount = useMemo<Record<number, number>>(
        () =>
            tabsItems.reduce((prev, a) => {
                const count = prev[a.issueType] ?? 0
                prev[a.issueType] = count + 1
                return prev
            }, {}),
        [tabsItems]
    )
    const issueStatusesCount = useMemo(
        () =>
            tabsItems.reduce((prev, a) => {
                const count = prev[a.issueStatusId] ?? 0
                prev[a.issueStatusId] = count + 1
                return prev
            }, {}),
        [tabsItems]
    )
    const issueRolesCount = useMemo(
        () =>
            tabsItems.reduce((prev, item) => {
                item?.roles?.forEach((a) => {
                    const count = prev[a.code] ?? 0
                    prev[a.code] = count + 1
                })
                return prev
            }, {}),
        [tabsItems]
    )
    const handleClearFilters = () => {
        const clearFilters = (
            filters: DashboardFilter[]
        ): DashboardFilter[] => {
            filters.forEach((x) => (x.selected = false))
            return [...filters]
        }
        const tab = activeTab
        setIssueTypesFilters((prev) => ({
            ...prev,
            tab: clearFilters(issueTypesFilters[tab]),
        }))
        setIssueStatusesFilters((prev) => ({
            ...prev,
            tab: clearFilters(issueStatusesFilters[tab]),
        }))
        setIssueRolesFilters((prev) => ({
            ...prev,
            tab: clearFilters(issueRolesFilters[tab]),
        }))
    }

    const handleExportIssuesToExcel = () => {
        const query: MyIssuesQuery = {
            issueTypes: issueTypesFilters[activeTab]
                .filter((x) => x.selected)
                .map((x) => x.code),
            roleCodes:
                activeTab === 'myIssuesFilters'
                    ? issueRolesFilters[activeTab]
                          .filter((x) => x.selected)
                          .map((x) => x.code)
                    : [101],
            statusCodes: issueStatusesFilters[activeTab]
                .filter((x) => x.selected)
                .map((x) => x.code),
        }
        dispatch(exportMyIssues(query))
        appInsights.trackEvent({
            name: 'Export my issues to excel',
        })
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0 })
    }

    const handleTargetResolutionAndVerificationDate = (
        formData: ExtendTargetResolutionDateQuery
    ) => {
        setEditDate(false)
        dispatch(extendTargetResolutionDate(formData))
    }

    const sortedFiltredItems = useMemo(() => {
        if (NEW_DASHBOARD) {
            const newFiltered = [...filtredItems] as MyIssue2[]

            sortElements(newFiltered as MyIssue2[], sortKey, descending)
            return newFiltered
        } else return filtredItems
    }, [sortKey, filtredItems, descending])

    const handleIssueUpdate = (newIssue) => {
        if (newIssue) {
            dispatch(refreshMyIssueByIdDone(newIssue))
        }
    }

    return (
        <Content
            coverBackground={true}
            loading={updating}
            loadingDesc={loadingDesc}
            error={error}
            title="My Issues"
            onRetry={() => {
                dispatch(clearError())
            }}
            variant="medium"
        >
            <PageTitle
                title="My issues"
                desc="This dashboard presents all issues with which you are associated."
                to="	
                https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B56C84027-3B1D-46B4-BC26-71BEA984B2A8%7D&file=CCRP%20Light%20-%20Dashboard%20overview.pptx&action=edit&mobileredirect=true"
            />
            <TabsView
                classes={{
                    tabs: classes.tabs,
                }}
                tabs={[
                    {
                        label: `My issues`,
                        idTest: 'ADMIN_BY_CUSTOMERS',
                        name: 'myIssuesFilters',
                    },
                    {
                        label: `Issues I am involved in`,
                        idTest: 'ADMIN_BYCUSTOMER_CONTACT',
                        name: 'issuesInvolvedFilters',
                    },
                ]}
                handleActive={setActiveTab}
            >
                <TabsMyIssues
                    exportingIssues={exportingIssues}
                    filtredItems={filtredItems}
                    handleClearFilters={handleClearFilters}
                    handleExportIssuesToExcel={handleExportIssuesToExcel}
                    handleIssueUpdate={handleIssueUpdate}
                    handlePageChange={handlePageChange}
                    loading={loading}
                    open={open}
                    page={page}
                    setDefaultFilters={setDefaultFilters}
                    setDescending={setDescending}
                    setEditDate={setEditDate}
                    setIssue={setIssue}
                    setOpen={setOpen}
                    setPage={setPage}
                    setSortKey={setSortKey}
                    setWildcard={setWildcard}
                    sortedFiltredItems={sortedFiltredItems}
                    updating={updating}
                    wildcard={wildcard}
                    issueStatusesCount={issueStatusesCount}
                    issueStatusesFilters={issueStatusesFilters.myIssuesFilters}
                    issueTypesCount={issueTypesCount}
                    setIssueStatusesFilters={(value) =>
                        setIssueStatusesFilters((prev) => ({
                            ...prev,
                            myIssuesFilters: value,
                        }))
                    }
                    setIssueTypesFilters={(value) =>
                        setIssueTypesFilters((prev) => ({
                            ...prev,
                            myIssuesFilters: value,
                        }))
                    }
                    issueTypesFilters={issueTypesFilters.myIssuesFilters}
                    issueRolesCount={issueRolesCount}
                    issueRolesFilters={issueRolesFilters.myIssuesFilters}
                    setIssueRolesFilters={(value) =>
                        setIssueRolesFilters((prev) => ({
                            ...prev,
                            myIssuesFilters: value,
                        }))
                    }
                />
                <TabsMyIssues
                    exportingIssues={exportingIssues}
                    filtredItems={filtredItems}
                    handleClearFilters={handleClearFilters}
                    handleExportIssuesToExcel={handleExportIssuesToExcel}
                    handleIssueUpdate={handleIssueUpdate}
                    handlePageChange={handlePageChange}
                    loading={loading}
                    open={open}
                    page={page}
                    setDefaultFilters={setDefaultFilters}
                    setDescending={setDescending}
                    setEditDate={setEditDate}
                    setIssue={setIssue}
                    setOpen={setOpen}
                    setPage={setPage}
                    setSortKey={setSortKey}
                    setWildcard={setWildcard}
                    sortedFiltredItems={sortedFiltredItems}
                    updating={updating}
                    wildcard={wildcard}
                    issueStatusesCount={issueStatusesCount}
                    issueStatusesFilters={
                        issueStatusesFilters.issuesInvolvedFilters
                    }
                    issueTypesCount={issueTypesCount}
                    setIssueStatusesFilters={(value) =>
                        setIssueStatusesFilters((prev) => ({
                            ...prev,
                            issuesInvolvedFilters: value,
                        }))
                    }
                    setIssueTypesFilters={(value) =>
                        setIssueTypesFilters((prev) => ({
                            ...prev,
                            issuesInvolvedFilters: value,
                        }))
                    }
                    issueTypesFilters={issueTypesFilters.issuesInvolvedFilters}
                />
            </TabsView>

            <ExtendTargetResolutionDateDialog
                issueId={issue?.issueId}
                rowVersion={issue?.rowVersion}
                currentDate={issue?.dates?.targetResolutionAndVerificationDate}
                onExtendTargetDate={handleTargetResolutionAndVerificationDate}
                open={editDate}
                onClose={() => setEditDate(false)}
            />
        </Content>
    )
}

export default MyIssuesPage
