import { makeStyles } from '@mui/styles'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            height: 32,
            width: 32,
        },
    }),
    { name: 'YammerIcon' }
)

export const YammerIcon = () => {
    const classes = useStyles()
    return (
        <img
            className={classes.root}
            src="/assets/Yammer_Viva_Engage_icon.png"
            alt="Viva Engage"
        />
    )
}
