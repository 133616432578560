export enum NotificationType {
    Success = 0,
    Error = 1,
    Information = 2,
    Warning = 3,
}

export type AppStoreNotificationErrorType = boolean | NotificationType
export interface AppStoreNotification {
    message: string
    key: string
    error: AppStoreNotificationErrorType
    dismissed: boolean
}

export type AppStoreNotifications = Array<AppStoreNotification>

export interface AppStoreState {
    notifications: AppStoreNotifications
    token: string
    unauthorized: boolean
    unauthorizedPath: string
    hasError: boolean
    error?: any
}

export const initState: AppStoreState = {
    notifications: [],
    token: '',
    unauthorized: false,
    unauthorizedPath: '',
    hasError: false,
    error: null,
}
