import {
    FormatAlignCenter,
    FormatAlignLeft,
    FormatAlignRight,
    FormatBold,
    FormatColorFill,
    FormatColorText,
    FormatIndentDecrease,
    FormatIndentIncrease,
    FormatItalic,
    FormatListBulleted,
    FormatListNumbered,
    FormatSize,
    FormatStrikethrough,
    FormatUnderlined,
    LocalOffer,
    Redo,
    Undo,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { EditorState } from 'draft-js'
import { HTMLAttributes, MouseEvent } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FillTextColorInlineStyles, TextColorInlineStyles } from '../types'
import { ColorPallete } from './ColorPallete'
import { FormatButton } from './FormatButton'
import { HorizontalDivider } from './HorizontalDivider'
import { OptionsButton } from './OptionsButton'
import { TagsPallete } from './TagsPallete'
import { TypographyPallete } from './TypographyPallete'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            minHeight: 32,
            flexWrap: 'wrap',
            borderBottom: `1px solid ${theme.palette.divider}`,
            alignItems: 'center',
        },
        labelText: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'FormatRibbon' }
)

export interface FormatRibbonProps extends HTMLAttributes<HTMLDivElement> {
    editorState: EditorState
    onUndo?: () => void
    onRedo?: () => void
    onBold?: () => void
    onUnderline?: () => void
    onItalic?: () => void
    onStrikethrough?: () => void
    onBulletList?: () => void
    onNumberList?: () => void
    onIncreaseListIndent?: (e: MouseEvent<HTMLButtonElement>) => void
    onDecreaseListIndent?: (e: MouseEvent<HTMLButtonElement>) => void
    onLeftText?: () => void
    onCenterText?: () => void
    onRightText?: () => void
    onTypographySelect?: (blockType: string) => void
    onTextColorSelect?: (colorTextInlineStyle: TextColorInlineStyles) => void
    onFillTextColorSelect?: (
        colorTextInlineStyle: FillTextColorInlineStyles
    ) => void
    onTagAdd: (tag: string) => void
}

export const FormatRibbon = (props: FormatRibbonProps) => {
    const {
        className,
        editorState,
        onRedo,
        onUndo,
        onBold,
        onUnderline,
        onItalic,
        onStrikethrough,
        onLeftText,
        onCenterText,
        onRightText,
        onBulletList,
        onNumberList,
        onIncreaseListIndent,
        onDecreaseListIndent,
        onTypographySelect,
        onTextColorSelect,
        onFillTextColorSelect,
        onTagAdd,
        ...other
    } = props
    const classes = useStyles()
    const selection = editorState.getSelection()
    const currentStyle = props.editorState.getCurrentInlineStyle()
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()
    const invoke = (func: Function) => (e) => func && func(e)
    return (
        <div className={clsx(className, classes.root)} {...other}>
            <FormatButton
                onClick={invoke(onUndo)}
                renderIcon={() => <Undo fontSize="small" />}
            />
            <FormatButton
                onClick={invoke(onRedo)}
                renderIcon={() => <Redo fontSize="small" />}
            />
            <HorizontalDivider />
            <OptionsButton
                renderOptions={() => (
                    <TypographyPallete
                        editorState={editorState}
                        onTypographySelect={invoke(onTypographySelect)}
                    />
                )}
            >
                <FormatSize fontSize="small" />
            </OptionsButton>
            <HorizontalDivider />
            <FormatButton
                onClick={invoke(onBold)}
                renderIcon={() => <FormatBold fontSize="small" />}
                active={currentStyle.has('BOLD')}
            />
            <FormatButton
                onClick={invoke(onUnderline)}
                renderIcon={() => <FormatUnderlined fontSize="small" />}
                active={currentStyle.has('UNDERLINE')}
            />
            <FormatButton
                onClick={invoke(onItalic)}
                renderIcon={() => <FormatItalic fontSize="small" />}
                active={currentStyle.has('ITALIC')}
            />
            <FormatButton
                onClick={invoke(onStrikethrough)}
                renderIcon={() => <FormatStrikethrough fontSize="small" />}
                active={currentStyle.has('STRIKETHROUGH')}
            />
            <HorizontalDivider />
            <FormatButton
                onClick={invoke(onLeftText)}
                renderIcon={() => <FormatAlignLeft fontSize="small" />}
            />
            <FormatButton
                onClick={invoke(onCenterText)}
                renderIcon={() => <FormatAlignCenter fontSize="small" />}
            />
            <FormatButton
                onClick={invoke(onRightText)}
                renderIcon={() => <FormatAlignRight fontSize="small" />}
            />
            <HorizontalDivider />
            <OptionsButton
                renderOptions={(close) => (
                    <ColorPallete
                        editorState={editorState}
                        onColorSelect={onTextColorSelect}
                        onClose={close}
                    />
                )}
            >
                <FormatColorText fontSize="small" />
            </OptionsButton>

            <OptionsButton
                renderOptions={(close) => (
                    <ColorPallete
                        editorState={editorState}
                        onColorSelect={(color) =>
                            onFillTextColorSelect(
                                (color + '_FILL') as FillTextColorInlineStyles
                            )
                        }
                        onClose={close}
                    />
                )}
            >
                <FormatColorFill fontSize="small" />
            </OptionsButton>
            <HorizontalDivider />
            <FormatButton
                onClick={invoke(onBulletList)}
                renderIcon={() => <FormatListBulleted fontSize="small" />}
                active={blockType === 'unordered-list-item'}
            />
            <FormatButton
                onClick={invoke(onNumberList)}
                renderIcon={() => <FormatListNumbered fontSize="small" />}
                active={blockType === 'ordered-list-item'}
            />
            <FormatButton
                onClick={invoke(onIncreaseListIndent)}
                renderIcon={() => <FormatIndentIncrease fontSize="small" />}
            />
            <FormatButton
                onClick={invoke(onDecreaseListIndent)}
                renderIcon={() => <FormatIndentDecrease fontSize="small" />}
            />
            <HorizontalDivider />
            <OptionsButton
                style={{ minWidth: 120 }}
                renderOptions={(close) => (
                    <TagsPallete onTagAdd={onTagAdd} onClose={close} />
                )}
            >
                <LocalOffer fontSize="small" />
                <span className={classes.labelText}>Tags</span>
            </OptionsButton>
        </div>
    )
}
