import { CustomerContactQuery } from 'types/issueTypes'

export * from './CustomerContactDialog'
export * from './ErrorMessage'

export const getInitQuery: () => CustomerContactQuery = () => ({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    isActive: false,
})

export const getInitErrorsForCustomerContactDialog: () => CustomerContactQuery =
    () => ({
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
        isActive: null,
    })
