import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            height: theme.mixins.toolbar.minHeight,
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    }),
    {
        name: 'ActionsView',
    }
)

export interface ActionsViewProps extends HtmlHTMLAttributes<HTMLDivElement> {}

export const ActionsView = (props: ActionsViewProps) => {
    const { className, children, ...other } = props
    const classes = useStyles()
    return (
        <div className={clsx(classes.root, className)} {...other}>
            {children}
        </div>
    )
}
