import { dataTestId } from 'utils'

export const SIGN_IN_PAGE = {
    LOG_IN_BUTTON: dataTestId('SIGN_IN_LOG_IN'),
    LOG_OUT_BUTTON: dataTestId('SIGN_IN_LOG_OUT'),
    USER: {
        NAME: dataTestId('SIGN_IN_USER_FULL_NAME'),
        EMAIL: dataTestId('SIGN_IN_USER_EMAIL'),
    },
}

export const GDPR_PAGE = {
    ACKNOWLEDGE_BUTTON: dataTestId('GDPR_I_ACKNOWLEDGE'),
    LEAVE_BUTTON: dataTestId('GDPR_LEAVE_THE_SITE'),
}

export const ISSUE_TILE_HEADER_ELEMENT = {
    SUBJECT: dataTestId('ISSUE_TILE_HEADER_SUBJECT'),
    LABELS: dataTestId('ISSUE_TILE_HEADER_LABELS'),
    NUMBER: dataTestId('ISSUE_TILE_HEADER_NUMBER'),
}

export const DASHBOARD_PAGE = {
    ISSUE_TILE: {
        EXPAND_BUTTON: dataTestId('DASHBOARD_ISSUE_TILE_EXPAND'),
        ACTION_BUTTONS: {
            RETURN_TO_RESOLUTION_OWNER: dataTestId(
                'DASHBOARD_ISSUE_TILE_RETURN_TO_RO'
            ),
            VERIFY: dataTestId('DASHBOARD_ISSUE_TILE_VERIFY'),
            DOWNGRADE: dataTestId('DASHBOARD_ISSUE_TILE_DOWNGRADE'),
            CONFIRM_HIGH_IMPACT: dataTestId(
                'DASHBOARD_ISSUE_TILE_CONFIRM_HIGH_IMPACT'
            ),
            EXTEND_TARGET_DATE: dataTestId(
                'DASHBOARD_ISSUE_TILE_EXTEND_TARGET_DATE'
            ),
            RETURN_ISSUE: dataTestId('DASHBOARD_ISSUE_TILE_RETURN_ISSUE'),
            COMPLETE_ISSUE: dataTestId('DASHBOARD_ISSUE_TILE_COMPLETE_ISSUE'),
            REOPEN_ISSUE: dataTestId('DASHBOARD_ISSUE_TILE_REOPEN_ISSUE'),
            CHECK_EFFECTIVENESS: dataTestId(
                'DASHBOARD_ISSUE_TILE_CHECK_EFFECTIVENESS'
            ),
            RETURN_TO_ISSUE_OWNER: dataTestId(
                'DASHBOARD_ISSUE_TILE_RETURN_TO_IO'
            ),
            ASSIGN: dataTestId('DASHBOARD_ISSUE_TILE_ASSIGN'),
            CONFIRM_CONTAINMENT_ACTIONS: dataTestId(
                'DASHBOARD_ISSUE_TILE_CONFIRM_CONTAINMENT_ACTIONS'
            ),
            RESOLVE: dataTestId('DASHBOARD_ISSUE_TILE_RESOLVE'),
            REPLACE_INACTIVE_RU: dataTestId(
                'DASHBOARD_ISSUE_TILE_REPLACE_INACTIVE_RU'
            ),
            REPLACE_INACTIVE_RO: dataTestId(
                'DASHBOARD_ISSUE_TILE_REPLACE_INACTIVE_RO'
            ),
            REPLACE_INACTIVE_IO: dataTestId(
                'DASHBOARD_ISSUE_TILE_REPLACE_INACTIVE_IO'
            ),
        },
        COMPONENTS: {
            TARGET_DATE: dataTestId('DASHBOARD_ISSUE_TILE_TARGET_DATE'),
            ACTION_DEADLINE: dataTestId('DASHBOARD_ISSUE_TILE_ACTION_DEADLINE'),
            TARGET_OR_ACTION_DATE: dataTestId(
                'DASHBOARD_ISSUE_TILE_TARGET_OR_ACTION_DATE'
            ),
            RCA_CATEGORY: dataTestId('DASHBOARD_ISSUE_TILE_RCA_CATEGORY'),
            RCA_ATTACHMENTS: dataTestId('DASHBOARD_ISSUE_TILE_RCA_ATTACHMENTS'),
            ROOT_CAUSE: dataTestId('DASHBOARD_ISSUE_TILE_ROOT_CAUSE'),
            HOW_DID_WE_RESOLVE: dataTestId(
                'DASHBOARD_ISSUE_TILE_HOW_DID_WE_RESOLVE'
            ),
            CONTAINMENT_ACTIONS: dataTestId(
                'DASHBOARD_ISSUE_TILE_CONTAINMENT_ACTIONS'
            ),
            POTENTIAL_BUSINESS_IMPACT: dataTestId(
                'DASHBOARD_ISSUE_TILE_POTENTIAL_BUSINESS_IMPACT'
            ),
            HIGH_IMPACT_JUSTIFICATION: dataTestId(
                'DASHBOARD_ISSUE_TILE_HIGH_IMPACT_JUSTIFICATION'
            ),
            POTENTIAL_RISKS: dataTestId('DASHBOARD_ISSUE_TILE_POTENTIAL_RISKS'),
            RESOLUTION_DESCRIPTION: dataTestId(
                'DASHBOARD_ISSUE_TILE_RESOLUTION_DESCRIPTION'
            ),
            PRODUCT: dataTestId('DASHBOARD_ISSUE_TILE_PRODUCT'),
            RESOLVING_UNIT: dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT'),
            CUSTOMER: dataTestId('DASHBOARD_ISSUE_TILE_CUSTOMER'),
            CUSTOMER_TYPE: dataTestId('DASHBOARD_ISSUE_TILE_CUSTOMER_TYPE'),
            FAILURE_MODE: dataTestId('DASHBOARD_ISSUE_TILE_FAILURE_MODE'),
            ISSUE_TYPE: dataTestId('DASHBOARD_ISSUE_TILE_ISSUE_TYPE'),
            REQUIRED_CONTAINMENT_ACTIONS: dataTestId(
                'DASHBOARD_ISSUE_TILE_REQUIRED_CONTAINMENT_ACTIONS'
            ),
        },
    },
    TOP_BAR: {
        ACTION_BUTTONS: {
            REFRESH: dataTestId('DASHBOARD_TOP_BAR_REFRESH'),
            MORE_OPTIONS: dataTestId('DASHBOARD_TOP_BAR_MORE_OPTIONS'),
            EXPORT_TO_EXCEL: dataTestId('DASHBOARD_TOP_BAR_EXPORT_TO_EXCEL'),
        },
        MENU_BUTTONS: {
            CONTACT_SUPPORT: dataTestId('DASHBOARD_TOP_BAR_CONTACT_SUPPORT'),
            RELEASE_NOTES: dataTestId('DASHBOARD_TOP_BAR_RELEASE_NOTES'),
            LOG_OUT: dataTestId('DASHBOARD_TOP_BAR_LOG_OUT'),
        },
        USER: {
            NAME: dataTestId('DASHBOARD_TOP_BAR_USER_FULL_NAME'),
            EMAIL: dataTestId('DASHBOARD_TOP_BAR_USER_EMAIL'),
        },
    },
    FILTERS: {
        CONTAINER: dataTestId('DASHBOARD_FILTERS_CONTAINER'),
        EXPAND_BUTTON: dataTestId('DASHBOARD_FILTERS_EXPAND'),
        BADGE: dataTestId('DASHBOARD_FILTERS_BADGE'),
        ACTION_BUTTON: dataTestId('DASHBOARD_FILTERS_ACTION'),
        GROUP: dataTestId('DASHBOARD_FILTERS_GROUP'),
        CHIP: dataTestId('DASHBOARD_FILTER_CHIP'),
    },
    NEW_ISSUE_BUTTON: dataTestId('DASHBOARD_NEW_ISSUE'),
    EXPORT_TO_EXCEL: dataTestId('DASHBOARD_EXPORT_TO_EXCEL'),
}

export const NO_RESULTS_PAGE = {
    INFO_SECTION: dataTestId('NO_RESULTS_INFO_SECTION'),
    HEADLINE: dataTestId('NO_RESULTS_HEADLINE'),
    SUB_HEADLINE: dataTestId('NO_RESULTS_SUB_HEADLINE'),
    LOAD_ISSUES_BUTTON: dataTestId('NO_RESULTS_LOAD_ISSUES'),
}

export const CANNOT_LOAD_PAGE = {
    INFO_SECTION: dataTestId('CANNOT_LOAD_INFO_SECTION'),
    HEADLINE: dataTestId('CANNOT_LOAD_HEADLINE'),
    SUB_HEADLINE: dataTestId('CANNOT_LOAD_SUB_HEADLINE'),
    TRY_AGAIN_BUTTON: dataTestId('CANNOT_LOAD_TRY_AGAIN'),
}

export const CONTENT_ERROR_PAGE = {
    OVERLAY: dataTestId('CONTENT_ERROR_OVERLAY'),
    CONTAINER: dataTestId('CONTENT_ERROR_CONTAINER'),
    HEADLINE: dataTestId('CONTENT_ERROR_HEADLINE'),
    SUB_HEADLINE: dataTestId('CONTENT_ERROR_SUB_HEADLINE'),
    REFERENCE: dataTestId('CONTENT_ERROR_REFERENCE'),
    RETRY_BUTTON: dataTestId('CONTENT_ERROR_RETRY'),
    GO_TO_DASHBOARD_BUTTON: dataTestId('CONTENT_ERROR_GO_TO_DASHBOARD'),
}

export const ISSUE_VIEW_PAGE = {
    SUMMARY: {
        ISSUE_NUMBER: dataTestId('ISSUE_VIEW_SUMMARY_ISSUE_NUMBER'),
        OVERDUE_HI_LABEL: dataTestId('ISSUE_VIEW_SUMMARY_OVERDUE_HI'),
        STATUS_LABEL: dataTestId('ISSUE_VIEW_SUMMARY_STATUS'),
        SUBJECT: dataTestId('ISSUE_VIEW_SUMMARY_SUBJECT'),
        RESOLUTION_DATE: dataTestId('ISSUE_VIEW_SUMMARY_RESOLUTION_DATE'),
        YOUR_ROLES: dataTestId('ISSUE_VIEW_SUMMARY_YOUR_ROLES'),
        ISSUE_WARNINGS: {
            LIST: dataTestId('ISSUE_VIEW_SUMMARY_WARNINGS_LIST'),
            ITEM: dataTestId('ISSUE_VIEW_SUMMARY_WARNING_ITEM'),
        },
        ACTION_LOGS: dataTestId('ISSUE_VIEW_SUMMARY_ACTION_LOGS'),
        ACTION_BUTTONS: {
            EXTEND_RESOLUTION_DATE: dataTestId(
                'ISSUE_VIEW_SUMMARY_EXTEND_RESOLUTION_DATE'
            ),
            CHANGE_ROLE: dataTestId('ISSUE_VIEW_SUMMARY_CHANGE_ROLE'),
        },
    },
    ISSUE_CAPTURE: {
        EXPANSION_PANEL: dataTestId('ISSUE_VIEW_ISSUE_CAPTURE_EXPANSION'),
        DETAILS_CONTAINER: dataTestId(
            'ISSUE_VIEW_ISSUE_CAPTURE_DETAILS_CONTAINER'
        ),
        ACTION_BUTTONS: {
            SAVE: dataTestId('ISSUE_VIEW_ISSUE_CAPTURE_SAVE'),
            SUBMIT_ISSUE: dataTestId('ISSUE_VIEW_ISSUE_CAPTURE_SUBMIT_ISSUE'),
        },
        LIGHT_MODE_SWITCH: dataTestId('ISSUE_VIEW_ISSUE_CAPTURE_LIGHT_MODE'),
    },
    ISSUE_TYPE: {
        RADIO_GROUP: dataTestId('ISSUE_VIEW_ISSUE_TYPE'),
        TOOLTIP_BUTTON: dataTestId('ISUE_VIEW_ISSUE_TYPE_TOOLTIP'),
    },
    DESCRIPTION: {
        ISSUE_SUBJECT: dataTestId('ISSUE_VIEW_DESCRIPTION_SUBJECT'),
        WHAT: dataTestId('ISSUE_VIEW_DESCRIPTION_WHAT'),
        WHY: dataTestId('ISSUE_VIEW_DESCRIPTION_WHY'),
        HOW: dataTestId('ISSUE_VIEW_DESCRIPTION_HOW'),
        WHERE: dataTestId('ISSUE_VIEW_DESCRIPTION_WHERE'),
        WHEN: dataTestId('ISSUE_VIEW_DESCRIPTION_WHEN'),
        EXPECTATIONS: dataTestId('ISSUE_VIEW_DESCRIPTION_EXPECTATIONS'),
        WHO: dataTestId('ISSUE_VIEW_DESCRIPTION_WHO'),
        HOW_MANY_AFFECTED: dataTestId(
            'ISSUE_VIEW_DESCRIPTION_HOW_MANY_AFFECTED'
        ),
    },
    ISSUE_IMPACT: {
        TYPE_RADIO_GROUP: dataTestId('ISSUE_VIEW_ISSUE_IMPACT_TYPE'),
        POTENTIAL_RISKS_CHECKBOX_GROUP: dataTestId(
            'ISSUE_VIEW_ISSUE_IMPACT_POTENTIAL_RISKS'
        ),
        HI_JUSTIFICATION_INPUT: dataTestId(
            'ISSUE_VIEW_ISSUE_IMPACT_HI_JUSTIFICATION'
        ),
        POTENTIAL_BUSINESS_IMPACT_INPUT: dataTestId(
            'ISSUE_VIEW_ISSUE_IMPACT_POTENTIAL_BUSINESS_IMPACT'
        ),
        RISK_CHECKBOXES: {
            PEOPLE: dataTestId('ISSUE_VIEW_ISSUE_IMPACT_PEOPLE'),
            ENVIRONMENTAL: dataTestId('ISSUE_VIEW_ISSUE_IMPACT_ENVIRONMENTAL'),
            IMAPCT_ON_CUSTOMER: dataTestId(
                'ISSUE_VIEW_ISSUE_IMPACT_IMAPCT_ON_CUSTOMER'
            ),
            IMAPCT_ON_ABB: dataTestId('ISSUE_VIEW_ISSUE_IMPACT_IMAPCT_ON_ABB'),
            IMPACT_ON_ABB_BRAND: dataTestId(
                'ISSUE_VIEW_ISSUE_IMPACT_IMAPCT_ON_ABB_BRAND'
            ),
            CLIENT_LOSS: dataTestId('ISSUE_VIEW_ISSUE_IMPACT_CLIENT_LOSS'),
            SYSTEMIC_ISSUES: dataTestId(
                'ISSUE_VIEW_ISSUE_IMPACT_SYSTEMIC_ISSUES'
            ),
        },
    },
    TARGET_DATE_AND_CONTAINMENT_ACTIONS: {
        TARGET_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_TARGET_DATE'
        ),
        TARGET_DATE_CHANGE_REASON_SELECT: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_TARGET_DATE_CHANGE_REASON'
        ),
        TARGET_DATE_CHANGE_REASON_DESCRIPTION_TEXTFIELD: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_TARGET_DATE_CHANGE_REASON_DESCRIPTION'
        ),
        RCA_REQUIRED_CHECKBOX: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_RCA_REQUIRED'
        ),
        CONTAINMENT_ACTIONS_CHECKBOX: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_CONTAINMENT_ACTIONS'
        ),
        REQUIRED_CONTAINMENT_ACTIONS_INPUT: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_REQUIRED_CONTAINMENT_ACTIONS'
        ),
        CONTAINMENT_ACTIONS_DEADLINE_PICKER: dataTestId(
            'ISSUE_VIEW_CONTAINMENT_ACTIONS_CONTAINMENT_ACTIONS_DEADLINE'
        ),
    },
    CUSTOMER: {
        ISSUE_TYPE_RADIO_GROUP: dataTestId('ISSUE_VIEW_CUSTOMER_ISSUE_TYPE'),
        NAME_INPUT: dataTestId('ISSUE_VIEW_CUSTOMER_NAME'),
        CONTACT_NAME_INPUT: dataTestId('ISSUE_VIEW_CUSTOMER_CONTACT_NAME'),
    },
    PRODUCT_AND_SUBJECT: {
        RESOLVING_UNIT_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_RESOLVING_UNIT'
        ),
        RESOLVING_UNIT_HELP_TIP_ERROR: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_RESOLVING_UNIT_HELP_TIP_ERROR'
        ),
        ABB_PRODUCT_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_ABB_PRODUCT'
        ),
        PRODUCT_LINE_LOCATION_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_PRODUCT_LINE_LOCATION'
        ),
        FAILURE_MODE_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_FAILURE_MODE'
        ),
        FAILURE_MODE_DESCRIPTION_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_FAILURE_MODE_DESCRIPTION'
        ),
        SERIAL_INPUT: dataTestId('ISSUE_VIEW_PRODUCT_AND_SUBJECT_SERIAL'),
        PURCHES_ORDER_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_PURCHES_ORDER'
        ),
        ABB_PROJECT_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_ABB_PROJECT'
        ),
        PROJECT_NAME_INPUT: dataTestId(
            'ISSUE_VIEW_PRODUCT_AND_SUBJECT_PROJECT_NAME'
        ),
    },
    ADDITIONAL_INFORMATION: {
        QUANTITY_INPUT: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_QUANTITY'
        ),
        CUSTOMER_REFERENCE_INPUT: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_CUSTOMER_REFERENCE'
        ),
        SUPPLIER_INPUT: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_SUPPLIER'
        ),
        COMMISIONING_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_COMMISIONING_DATE'
        ),
        MANUFACTURING_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_MANUFACTURING_DATE'
        ),
        WARRANTY_REFERENCE_INPUT: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_WARRANTY_REFERENCE'
        ),
        WARRANTY_START_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_WARRANTY_START_DATE'
        ),
        WARRANTY_END_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_ADDITIONAL_INFORMATION_WARRANTY_END_DATE'
        ),
    },
    ATTACHMENTS: {
        FILE_MANAGER: dataTestId('ISSUE_VIEW_ATTACHMENTS_FILE_MANAGER'),
    },
    IO_AND_RU: {
        ISSUE_OWNER_SELECT: dataTestId('ISSUE_VIEW_IO_AND_RU_ISSUE_OWNER'),
        SUBSTITUTES_SELECT: dataTestId('ISSUE_VIEW_IO_AND_RU_SUBSTITUTES'),
        PROPOSED_RO_SELECT: dataTestId('ISSUE_VIEW_IO_AND_RU_PROPOSED_RO'),
        SEND_COPY_TO_CHECKBOX: dataTestId(
            'ISSUE_VIEW_IO_AND_RU_SHOULD_COPY_BE_SEND'
        ),
        SEND_COPY_TO_SELECT: dataTestId('ISSUE_VIEW_IO_AND_RU_SEND_COPY_TO'),
    },
    ISSUE_OWNER_AND_TEAM: {
        EXPANSION_PANEL: dataTestId(
            'ISSUE_VIEW_ISSUE_OWNER_AND_TEAM_EXPANSION'
        ),
        ISSUE_OWNER_SELECT: dataTestId(
            'ISSUE_VIEW_ISSUE_OWNER_AND_TEAM_ISSUE_OWNER'
        ),
        ISSUE_SUBSTITUTE_SELECT: dataTestId(
            'ISSUE_VIEW_ISSUE_OWNER_AND_TEAM_ISSUE_SUBSTITUTE'
        ),
    },
    ON_BEHALF_OF: {
        BU: dataTestId('BEHALF_DIV'),
        COUNTRY: dataTestId('BEHALF_COUNTRY'),
    },
    ISSUE_ASSIGNMENT: {
        EXPANSION_PANEL: dataTestId('ISSUE_VIEW_ISSUE_ASSIGNMENT_EXPANSION'),
        DETAILS_CONTAINER: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_DETAILS_CONTAINER'
        ),
        RESOLUTION_OWNER_SELECT: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_RESOLUTION_OWNER'
        ),
        RESOLUTION_OWNER_TEAM_SELECT: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_RESOLUTION_OWNER_TEAM'
        ),
        RCA_RADIO_GROUP: dataTestId('ISSUE_VIEW_ISSUE_ASSIGNMENT_RCA'),
        LINKED_ISSUE_LABEL: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_LINKED_ISSUE'
        ),
        MUST_BE_VERIFIED_CHECKBOX: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_MUST_BE_VERIFIED'
        ),
        SHOULD_BE_VERIFIED_BY_SELECT: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_SHOULD_BE_VERIFIED_BY'
        ),
        RCA_REQUIRED_RADIO_BUTTON: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_RCA_REQUIRED'
        ),
        RCA_NOT_REQUIRED_HELP_BUTTON: dataTestId(
            'ISSUE_VIEW_ISSUE_ASSIGNMENT_RCA_NOT_REQUIRED_HELP'
        ),
        ACTION_BUTTONS: {
            RETURN_TO_ISSUE_OWNER: dataTestId(
                'ISSUE_VIEW_ISSUE_ASSIGNMENT_ACTION_RETURN_TO_IO'
            ),
            ASSIGN: dataTestId('ISSUE_VIEW_ISSUE_ASSIGNMENT_ACTION_ASSIGN'),
            SAVE: dataTestId('ISSUE_VIEW_ISSUE_ASSIGNMENT_ACTION_SAVE'),
        },
    },
    ISSUE_RESOLUTION: {
        EXPANSION_PANEL: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_EXPANSION'),
        WARNINGS_ACTION_ITEM: dataTestId('WARNINGS_ACTION_ITEM'),
        DETAILS_CONTAINER: dataTestId(
            'ISSUE_VIEW_ISSUE_RESOLUTION_DETAILS_CONTAINER'
        ),
        ROOT_CAUSE_INPUT: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_ROOT_CAUSE'),
        HOW_INPUT: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_HOW'),
        CONTAINMENT_ACTIONS_INPUT: dataTestId(
            'ISSUE_VIEW_ISSUE_RESOLUTION_CONTAINMENT_ACTIONS'
        ),
        RESOLUTION_DESCRIPTION_INPUT: dataTestId(
            'ISSUE_VIEW_ISSUE_RESOLUTION_DESCRIPTION'
        ),
        RCA_ATTACHMENTS: dataTestId(
            'ISSUE_VIEW_ISSUE_RESOLUTION_RCA_ATTACHMENTS'
        ),
        ATTACHMENTS: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_ATTACHMENTS'),
        PG_RCA_CATEGORY: dataTestId(
            'ISSUE_VIEW_ISSUE_RESOLUTION_PG_RCA_CATEGORY'
        ),
        ACTION_BUTTONS: {
            RESOLVE: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_RESOLVE'),
            RETURN_TO_RO: dataTestId(
                'ISSUE_VIEW_ISSUE_RESOLUTION_RETURN_TO_RO'
            ),
            CONFIRM_CA: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_CONFIRM_CA'),
            VERIFIED: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_VERIFIED'),
            SAVE: dataTestId('ISSUE_VIEW_ISSUE_RESOLUTION_SAVE'),
        },
    },
    ISSUE_CLOSED: {
        EXPANSION_PANEL: dataTestId('ISSUE_VIEW_ISSUE_CLOSED_EXPANSION'),
        SURVEY_CHECKBOX: dataTestId('SURVEY_CHECKBOX'),
        SURVEY_WHY_NOT_INFORMED_FIELD: dataTestId(
            'SURVEY_WHY_NOT_INFORMED_FIELD'
        ),
        DETAILS_CONTAINER: dataTestId(
            'ISSUE_VIEW_ISSUE_CLOSED_DETAILS_CONTAINER'
        ),
        WAITING_FOR_CUSTOMER_EXECUTION_CHECKBOX: dataTestId(
            'ISSUE_VIEW_ISSUE_CLOSED_WAITING_FOR_CUSTOMER'
        ),
        EXPECTED_EXECUTION_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_ISSUE_CLOSED_EXPECTED_EXECUTION_DATE'
        ),
        EXPECTED_EXECUTION_DATE_CONTAINER: dataTestId(
            'ISSUE_VIEW_ISSUE_EXPECTED_EXECUTION_DATE_CONTAINER'
        ),
        CUSTOMER_COMMENTS_INPUT: dataTestId(
            'ISSUE_VIEW_ISSUE_CLOSED_CUSTOMER_COMMENTS'
        ),
        FEEDBACK_INPUT: dataTestId('ISSUE_VIEW_ISSUE_CLOSED_FEEDBACK'),
        ATTACHMENTS_LIST: dataTestId('ISSUE_VIEW_ISSUE_CLOSED_ATTACHMENTS'),
        EFFORT_SCORE: dataTestId('ISSUE_VIEW_ISSUE_EFFORT_SCORE'),
        SATISFACTION_SCORE: dataTestId('ISSUE_VIEW_ISSUE_SATISFACTION_SCORE'),
        ACTION_BUTTONS: {
            RETURN_TO_RESOLUTION_OWNER: dataTestId(
                'ISSUE_VIEW_ISSUE_CLOSED_RETURN_TO_RO'
            ),
            COMPLETE: dataTestId('ISSUE_VIEW_ISSUE_CLOSED_COMPLETE'),
            SAVE: dataTestId('ISSUE_VIEW_ISSUE_CLOSED_SAVE'),
        },
    },
    EFFECTIVENESS: {
        EXPANSION_PANEL: dataTestId('ISSUE_VIEW_EFFECTIVENESS_EXPANSION'),
        DETAILS_CONTAINER: dataTestId(
            'ISSUE_VIEW_EFFECTIVENESS_DETAILS_CONTAINER'
        ),
        CORRECTIVE_ACTION_RADIO_GROUP: dataTestId(
            'ISSUE_VIEW_EFFECTIVENESS_CORRECTIVE_ACTION'
        ),
        DEADLINE_DATE_PICKER: dataTestId(
            'ISSUE_VIEW_EFFECTIVENESS_DEADLINE_DATE'
        ),
        WHY_INPUT: dataTestId('ISSUE_VIEW_EFFECTIVENESS_WHY'),
        ATTACHMENTS_LIST: dataTestId('ISSUE_VIEW_EFFECTIVENESS_ATTACHMENTS'),
        ACTION_BUTTONS: {
            SAVE: dataTestId('ISSUE_VIEW_EFFECTIVENESS_SAVE'),
            REOPEN: dataTestId('ISSUE_VIEW_EFFECTIVENESS_REOPEN'),
            CONFIRMED: dataTestId('ISSUE_VIEW_EFFECTIVENESS_CONFIRMED'),
        },
        EFFECTIVENESS_REMINDER_DATE: dataTestId('EFFECTIVENESS_REMINDER_DATE'),
    },
    ACTION_BUTTONS: {
        SAVE: dataTestId('ISSUE_VIEW_SAVE'),
        SUBMIT_ISSUE: dataTestId('ISSUE_VIEW_SUBMIT_ISSUE'),
    },
}

export const LINK_ISSUE_VIEW_PAGE = {
    SEARCH_BAR_CONTAINER: dataTestId('LINK_ISSUE_VIEW_SEARCH_BAR_CONTAINER'),
    BUSSINESS_SELECT: dataTestId('LINK_ISSUE_VIEW_BUSSINESS'),
    BUSSINESS_UNIT_SELECT: dataTestId('LINK_ISSUE_VIEW_BUSSINESS_UNIT'),
    PRODUCT_GROUP_SELECT: dataTestId('LINK_ISSUE_VIEW_PRODUCT_GROUP'),
    ACTIVITY_SELECT: dataTestId('LINK_ISSUE_VIEW_ACTIVITY'),
    COUNTRY_SELECT: dataTestId('LINK_ISSUE_VIEW_COUNTRY'),
    ISSUE_TYPE_SELECT: dataTestId('LINK_ISSUE_VIEW_ISSUE_TYPE'),
    ACTION_BUTTONS: {
        SEARCH: dataTestId('LINK_ISSUE_VIEW_SEARCH'),
        CLEAR: dataTestId('LINK_ISSUE_VIEW_CLEAR'),
    },
    NO_ISSUES_FOUND_LABEL: dataTestId('LINK_ISSUE_VIEW_NO_ISSUES_FOUND'),
    LOAD_MORE_BUTTON: dataTestId('LINK_ISSUE_VIEW_LOAD_MORE'),
}

export const LINK_ISSUE_ITEM_ELEMENT = {
    CONTAINER: dataTestId('LINK_ISSUE_ITEM_CONTAINER'),
    FAILURE_MODE_LABEL: dataTestId('LINK_ISSUE_ITEM_FAILURE_MODE'),
    CUSTOMER_LABEL: dataTestId('LINK_ISSUE_ITEM_CUSTOMER'),
    ACTION_BUTTONS: {
        COPY_RCA: dataTestId('LINK_ISSUE_ITEM_COPY_RCA'),
    },
}

export const NEW_ISSUE_PAGE = {
    ACTION_BUTTONS: {
        SAVE_AS_DRAFT: dataTestId('NEW_ISSUE_SAVE_AS_DRAFT'),
        SUBMIT_ISSUE: dataTestId('NEW_ISSUE_SUBMIT_ISSUE'),
    },
}

export const ACTION_LOGS = {
    TOP_BAR: {
        BACK_BUTTON: dataTestId('ACTION_LOGS_TOP_BAR_BACK'),
        ISSUE_NO_LABEL: dataTestId('ACTION_LOGS_TOP_BAR_ISSUE_NO'),
        ACTION_LOGS_LABEL: dataTestId('ACTION_LOGS_TOP_BAR_ACTION_LOGS'),
        REFRESH_BUTTON: dataTestId('ACTION_LOGS_TOP_BAR_REFRESH'),
    },
    SEARCH_BAR_CONTAINER: dataTestId('ACTION_LOGS_SEARCH_BAR_CONTAINER'),
    PAGINATION_CONTAINER: dataTestId('ACTION_LOGS_PAGINATION_CONTAINER'),
    FILTER_CHIPS: {
        CONTAINER: dataTestId('ACTION_LOGS_CHIPS_CONTAINER'),
        SYSTEM_ACTION: dataTestId('ACTION_LOGS_CHIPS_SYSTEM_ACTION'),
        USER_NOTIFICATION: dataTestId('ACTION_LOGS_CHIPS_USER_NOTIFICATION'),
        SYSTEM_NOTIFICATION: dataTestId(
            'ACTION_LOGS_CHIPS_SYSTEM_NOTIFICATION'
        ),
        MANUAL_ENTRY: dataTestId('ACTION_LOGS_CHIPS_MANUAL_ENTRY'),
        ACTION_ITEM: dataTestId('ACTION_LOGS_CHIPS_ACTION_ITEM'),
    },
    ITEMS_LIST_CONTAINER: dataTestId('ACTION_LOGS_ITEMS_LIST_CONTAINER'),
    ITEM: {
        CONTAINER: dataTestId('ACTION_LOGS_ITEM_CONTAINER'),
        HEADER: dataTestId('ACTION_LOGS_ITEM_HEADER'),
        TITLE: dataTestId('ACTION_LOGS_ITEM_TITLE'),
        DATE: dataTestId('ACTION_LOGS_ITEM_DATE'),
        USER_EMAIL: dataTestId('ACTION_LOGS_ITEM_USER_EMAIL'),
        TO_EMAIL: dataTestId('ACTION_LOGS_ITEM_TO_EMAIL'),
        CC_EMAIL: dataTestId('ACTION_LOGS_ITEM_CC_EMAIL'),
        TYPE_LABEL: dataTestId('ACTION_LOGS_ITEM_TYPE'),
        EXPAND: dataTestId('ACTION_LOGS_ITEM_EXPAND'),
        SUBJECT: dataTestId('ACTION_LOGS_ITEM_SUBJECT'),
        BODY: dataTestId('ACTION_LOGS_ITEM_BODY'),
        ATTACHMENTS: {
            CONTAINER: dataTestId('ACTION_LOGS_ITEM_ATTACHMENTS_CONTAINER'),
            LIST: dataTestId('ACTION_LOGS_ITEM_ATTACHMENTS_LIST'),
            ITEM: dataTestId('ACTION_LOGS_ITEM_ATTACHMENTS_ITEM'),
        },
    },
}

export const RESOLVING_UNIT_PICKER = {
    CONTENT: dataTestId('RESOLVING_UNIT_PICKER_CONTENT'),
    IS_GLOBAL_RU_CHECKBOX: dataTestId('RESOLVING_UNIT_IS_GLOBAL_RU'),
    BUSINESS_SELECT: dataTestId('RESOLVING_UNIT_BUSINESS'),
    BUSINESS_LINE_SELECT: dataTestId('RESOLVING_UNIT_BUSINESS_LINE'),
    PRODUCT_GROUP_SELECT: dataTestId('RESOLVING_UNIT_PRODUCT_GROUP'),
    ACTIVITY_SELECT: dataTestId('RESOLVING_UNIT_ACTIVITY'),
    COUNTRY_SELECT: dataTestId('RESOLVING_UNIT_COUNTRY'),
    CITY_SELECT: dataTestId('RESOLVING_UNIT_CITY'),
    ACTION_BUTTONS: {
        SEARCH: dataTestId('RESOLVING_UNIT_SEARCH'),
        CLEAR: dataTestId('RESOLVING_UNIT_CLEAR'),
    },
    RESULTS_LIST: {
        CONTAINER: dataTestId('RESOLVING_UNIT_RESULTS_LIST'),
        TRY_AGAIN_BUTTON: dataTestId('RESOLVING_UNIT_RESULTS_LIST_TRY_AGAIN'),
    },
}

export const CUSTOMER_PICKER = {
    CONTENT: dataTestId('CUSTOMER_PICKER_CONTENT'),
    COUNTRY_SELECT: dataTestId('CUSTOMER_PICKER_COUNTRY'),
    CITY_SELECT: dataTestId('CUSTOMER_PICKER_CITY'),
    ADDRESS_INPUT: dataTestId('CUSTOMER_PICKER_ADDRESS'),
    GLOBAL_HQ_INPUT: dataTestId('CUSTOMER_PICKER_GLOBAL_HQ'),
    BUSINES_PARTNER_INPUT: dataTestId('CUSTOMER_PICKER_BUSINES_PARTNER'),
    ACTION_BUTTONS: {
        SEARCH: dataTestId('CUSTOMER_PICKER_SEARCH'),
        CLEAR: dataTestId('CUSTOMER_PICKER_CLEAR'),
    },
    RESULTS_LIST: {
        CONTAINER: dataTestId('CUSTOMER_PICKER_RESULTS_LIST'),
        ITEM: {
            ADDRESS: dataTestId('CUSTOMER_PICKER_ITEM_ADDRESS'),
            GLOBAL_HQ: dataTestId('CUSTOMER_PICKER_ITEM_GLOBAL_HQ'),
        },
    },
}

export const CUSTOMER_CONTACT_PICKER = {
    CONTENT: dataTestId('CUSTOMER_CONTACT_PICKER_CONTENT'),
    RESULTS_LIST: {
        CONTAINER: dataTestId('CUSTOMER_CONTACT_PICKER_RESULTS_LIST'),
    },
}

export const ABB_PRODUCT_PICKER = {
    CONTENT: dataTestId('ABB_PRODUCT_PICKER_CONTENT'),
    RESULTS_TREE_CONTAINER: dataTestId('ABB_PRODUCT_PICKER_RESULTS_TREE'),
}
export const PRODUCT_LINE_LOCATION_PICKER = {
    CONTENT: dataTestId('PRODUCT_LINE_LOCATION_CONTENT'),
    RESULTS_LIST_CONTAINER: dataTestId('PRODUCT_LINE_LOCATION_RESULTS_LIST'),
}

export const FAILURE_MODE_PICKER = {
    CONTENT: dataTestId('FAILURE_MODE_PICKER_CONTENT'),
    RESULTS_TREE_CONTAINER: dataTestId('FAILURE_MODE_PICKER_RESULTS_TREE'),
}

export const PICKER_TOP_BAR = {
    CONTAINER: dataTestId('PICKER_TOP_BAR'),
    TITLE: dataTestId('PICKER_TOP_BAR_TITLE'),
    ACTION_BUTTONS: {
        BACK: dataTestId('PICKER_TOP_BAR_BACK'),
        CONFIRM: dataTestId('PICKER_TOP_BAR_CONFIRM'),
    },
}

export const SEARCH_PANEL_ELEMENT = {
    SEARCH_TEXT_INPUT: dataTestId('SEARCH_PANEL_SEARCH_TEXT'),
    CLEAR_BUTTON: dataTestId('SEARCH_PANEL_SEARCH_CLEAR'),
    FILTERS_LIST_EXPAND_BUTTON: dataTestId(
        'SEARCH_PANEL_SEARCH_FILTERS_LIST_EXPAND'
    ),
}

export const SEARCH_BAR_BASE_ELEMENT = {
    SEARCH_TEXT_INPUT: dataTestId('SEARCH_BAR_SEARCH_TEXT'),
    CLOSE_BUTTON: dataTestId('SEARCH_BAR_SEARCH_CLOSE'),
}

export const SEARCH_RESULTS_ITEM = {
    CONTAINER: dataTestId('SEARCH_RESULTS_ITEM_CONTAINER'),
    SUBJECT: dataTestId('SEARCH_RESULTS_ITEM_SUBJECT'),
    TAGS: dataTestId('SEARCH_RESULTS_ITEM_TAGS'),
    TAG: dataTestId('SEARCH_RESULTS_ITEM_TAG'),
}

export const RELASE_NOTES_PAGE = {
    NOTES_CONTAINER: dataTestId('RELASE_NOTES_PAGE_NOTES_CONTAINER'),
}

export const CONFIRM_CONTAINMENT_ACTIONS_DIALOG = {
    CONTAINMENT_ACTIONS_TAKEN_INPUT: dataTestId(
        'CONFIRM_CONTAINMENT_ACTIONS_ACTIONS_TAKEN'
    ),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('CONFIRM_CONTAINMENT_ACTIONS_CANCEL'),
        CONFIRM: dataTestId('CONFIRM_CONTAINMENT_ACTIONS_CONFIRM'),
    },
}

export const EXTEND_TARGET_DATE_DIALOG = {
    NEW_TARGET_DATE_PICKER: dataTestId('EXTEND_TARGET_DATE_NEW_TARGET_DATE'),
    DATE_CHANGE_REASON_SELECT: dataTestId('EXTEND_TARGET_DATE_CHANGE_REASON'),
    REASON_DESCRIPTION: dataTestId('EXTEND_TARGET_DATE_REASON_DESCRIPTION'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('EXTEND_TARGET_DATE_CANCEL'),
        CHANGE_TARGET_DATE: dataTestId('EXTEND_TARGET_DATE_CHANGE_TARGET_DATE'),
    },
}

export const HIGH_IMPACT_ACTION_DIALOG = {
    NEW_TARGET_DATE_PICKER: dataTestId('HIGH_IMPACT_ACTION_NEW_TARGET_DATE'),
    DATE_CHANGE_REASON_SELECT: dataTestId(
        'HIGH_IMPACT_ACTION_DATE_CHANGE_REASON'
    ),
    MOTIVATION_INPUT: dataTestId('HIGH_IMPACT_ACTION_MOTIVATION'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('HIGH_IMPACT_ACTION_CANCEL'),
        CHANGE_TARGET_DATE: dataTestId('HIGH_IMPACT_ACTION_CHANGE_TARGET_DATE'),
    },
}

export const HIGH_IMPACT_CONFIRM_DIALOG = {
    CONTENT_CONTAINER: dataTestId('HIGH_IMPACT_CONFIRM_CONTENT_CONTAINER'),
    ACTION_BUTTONS_CONTAINER: dataTestId(
        'HIGH_IMPACT_CONFIRM_ACTIONS_CONTAINER'
    ),
    MOTIVATION_INPUT: dataTestId('HIGH_IMPACT_CONFIRM_MOTIVATION'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('HIGH_IMPACT_CONFIRM_CANCEL'),
        CHANGE_TARGET_DATE: dataTestId('HIGH_IMPACT_CONFIRM_HI'),
    },
}

export const RETURN_TO_RESOLUTION_OWNER_DIALOG = {
    TITLE: dataTestId('RETURN_TO_RESOLUTION_OWNER_TITLE'),
    CONTENT_TEXT: dataTestId('RETURN_TO_RESOLUTION_OWNER_CONTENT'),
    RETURN_REASON_INPUT: dataTestId('RETURN_TO_RESOLUTION_OWNER_RETURN_REASON'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('RETURN_TO_RESOLUTION_OWNER_CANCEL'),
        CHANGE_TARGET_DATE: dataTestId(
            'RETURN_TO_RESOLUTION_OWNER_CHANGE_TARGET_DATE'
        ),
        RETURN_ISSUE: dataTestId('RETURN_TO_RESOLUTION_OWNER_RETURN'),
    },
}

export const RETURN_TO_ISSUE_OWNER_DIALOG = {
    TITLE: dataTestId('RETURN_TO_ISSUE_OWNER_TITLE'),
    CONTENT_TEXT: dataTestId('RETURN_TO_ISSUE_OWNER_CONTENT'),
    RETURN_REASON_INPUT: dataTestId('RETURN_TO_ISSUE_OWNER_RETURN_REASON'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('RETURN_TO_ISSUE_OWNER_CANCEL'),
        RETURN_ISSUE: dataTestId('RETURN_TO_ISSUE_OWNER_RETURN'),
    },
}

export const ROLE_PICKER_DIALOG = {
    LIST_ITEM: dataTestId('ROLE_PICKER_DIALOG_ITEM'),
    ACTION_BUTTONS: {
        CANCEL: dataTestId('ROLE_PICKER_DIALOG_CANCEL'),
        SELECT_ROLE: dataTestId('ROLE_PICKER_DIALOG_SELECT_ROLE'),
    },
}

export const DIALOG = {
    CONTAINER: dataTestId('DIALOG_CONTAINER'),
    TITLE_TEXT: dataTestId('DIALOG_TITLE'),
    CONTENT_CONTAINER: dataTestId('DIALOG_CONTENT'),
    CONTENT_TEXT: dataTestId('DIALOG_MESSAGE'),
    DIALOG_ACTIONS_CONTAINER: dataTestId('DIALOG_ACTIONS'),
    DIALOG_CLOSE_BUTTON: dataTestId('DIALOG_CLOSE'),
    DIALOG_CONFIRM_BUTTON: dataTestId('DIALOG_CONFIRM'),
}

export const TEXT_FIELD = {
    INPUT: dataTestId('TEXT_FIELD_INPUT'),
}

export const FILE_MANAGER = {
    REFRESH_ATTACHMENTS_BUTTON: dataTestId('FILE_MANAGER_REFRESH_ATTACHMENTS'),
    UPLOAD_FILES_BUTTON: dataTestId('FILE_MANAGER_UPLOAD_FILES'),
    UPLOAD_FILES_INPUT: dataTestId('FILE_MANAGER_UPLOAD_FILES_INPUT'),
    UPLOADED_FILE_BUTTON: dataTestId('FILE_MANAGER_UPLOAD_UPLOADED_FILE'),
    REQUIRED_ASTERISK: dataTestId('FILE_MANAGER_REQUIRED'),
    UPLOADED_FILE_DELETE_BUTTON: dataTestId('FILE_MANAGER_UPLOAD_DELETE_FILE'),
}

export const SELECTABLE_CHIP = {
    CHIP_BUTTON: dataTestId('SELECTABLE_CHIP_BUTTON'),
}

export const TREE_NODE = {
    ROOT: dataTestId('TREE_ROOT'),
    CONTAINER: dataTestId('TREE_NODE_CONTAINER'),
    EXPAND_MORE_BUTTON: dataTestId('TREE_NODE_EXPAND_MORE'),
    CHILDREN_LIST: dataTestId('TREE_NODE_CHILDREN'),
}

export const HELP_TIP = {
    BUTTON: dataTestId('HELP_TIP_BUTTON'),
    POPUP: dataTestId('HELP_TIP_POPUP'),
    POPUP_MESSAGE: dataTestId('HELP_TIP_POPUP_MESSAGE'),
}

export const PICKER_TEXT_FIELD = {
    ARROW_BUTTON: dataTestId('PICKER_TEXT_FIELD_ARROW'),
}

export const CODE_SELECT = {
    SELECTED_ITEM_INPUT: dataTestId('CODE_SELECT_SELECTED_ITEM'),
}

export const EMPLOYEE_SELECT = {
    AUTOCOMPLETE_LIST: dataTestId('EMPLOYEE_SELECT_AUTOCOMPLETE'),
    AUTOCOMPLETE_ITEM: dataTestId('EMPLOYEE_SELECT_AUTOCOMPLETE_ITEM'),
    INPUT_FIELD: dataTestId('EMPLOYEE_SELECT_INPUT'),
    CHIP: {
        CONTAINER: dataTestId('EMPLOYEE_SELECT_CHIP_CONTAINER'),
    },
}
