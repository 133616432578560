import ChipLabel, { ChipLabelProps } from '../Chips/ChipLabel'

import { useMediaQuery } from '@mui/material'
import clsx from 'clsx'

export interface OverdueChipLabelProps extends ChipLabelProps {
    isHighImpact?: boolean
    isOverdue?: boolean
}

export const OverdueChipLabel = (props: OverdueChipLabelProps) => {
    const { isHighImpact, isOverdue, ...other } = props
    const isSmallDivice = useMediaQuery('(max-width:480px)')
    const smallScreen = isOverdue ? isSmallDivice : false

    const text = isHighImpact ? (smallScreen ? 'HI' : 'High Impact') : ''
    let attentionLabel = clsx(isHighImpact && text, isOverdue && 'Overdue')
    return <ChipLabel label={attentionLabel} variant="red" {...other} />
}

export default OverdueChipLabel
