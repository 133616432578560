import { MyIssue, MyIssue2 } from 'types/myDashboard'

export interface MyIssuesStoreState {
    loading: boolean
    loadingMore: boolean
    updating: boolean
    loadingDesc?: string
    error?: any
    items: Array<MyIssue | MyIssue2>
    exportingIssues: boolean
}

export const initState: MyIssuesStoreState = {
    loading: true,
    loadingMore: false,
    updating: false,
    loadingDesc: 'Loading your issues…',
    error: null,
    items: [],
    exportingIssues: false,
}
