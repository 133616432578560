import { AdminSearchPanelActions, ProcessOwnerInfo } from 'Admin/components'
import { TreeViewManageDialogVariant } from 'Admin/components/TreeViewManagePage/TreeViewManageDialog'
import {
    addAdminClassifications,
    deleteAdminClassifications,
    editAdminClassifications,
    getAdminClassifications,
    getAdminClassificationsProcessOwners,
} from 'api'
import { makeStyles } from '@mui/styles'
import {
    BusinessUnitSelect,
    CircularProgressLoader,
    Content,
    DivisionSelect,
    FormGridItem,
    GridContainer,
    NoResults,
    PageTitle,
    SearchPanel,
    SearchResultsHeader,
} from 'components'
import { ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { AdminClassification, ClassificationItem } from 'types/adminTypes'
import { Employee } from 'types/issueTypes'
import { BusinessUnitFilterBase, DivisionFilterBase } from 'types/models'
import { UserProfile } from 'types/profile'
import { dataTestId, permissionDivBuProcessOwner } from 'utils'
import { ClassificationsList, ClassificationsManageDialog } from './components'
import { Button } from '@mui/material'
import { Add } from '@mui/icons-material'

export interface QueryFields {
    business: BusinessUnitFilterBase
    division: DivisionFilterBase
}

const useStyles = makeStyles(
    (theme) => ({
        processOwners: {
            margin: theme.spacing(4, 0, 1),
        },
        searchTitle: {
            marginTop: theme.spacing(4),
        },
        tree: {
            marginTop: theme.spacing(1),
        },
        field: {
            marginBottom: 0,
        },
        addRootIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'ClassificationsManagePage' }
)

const getInitQuery = (): QueryFields => ({
    business: null,
    division: null,
})

export const ClassificationsManagePage = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const profile = useSelector<RootState, UserProfile>(
        (store) => store?.dashboard?.profile
    )
    const [processOwners, setProcessOwners] = useState<Employee[]>(null)
    const [error, setError] = useState<unknown>(null)
    const [searching, setSearching] = useState(false)
    const [editing, setEditing] = useState<TreeViewManageDialogVariant | null>(
        null
    )
    const [open, setOpen] = useState(true)
    const [query, setQuery] = useState<QueryFields>(() => getInitQuery())
    const [items, setItems] = useState<AdminClassification>()
    const [dialogVariant, setDialogVariant] =
        useState<TreeViewManageDialogVariant | null>(null)
    const [title, setTitle] = useState<string | null>()
    const [selectedClassification, setSelectedClassification] =
        useState<ClassificationItem | null>()
    const isPermissionDivBuProcessOwner = permissionDivBuProcessOwner(
        profile?.roles,
        query?.business?.code,
        query?.business?.divisionCode ?? query?.division?.code
    )

    const handleChange =
        (fieldName: keyof QueryFields) => (newValue: ReactNode) => {
            setProcessOwners(null)
            setItems(null)
            setQuery((prev: QueryFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }
    const resetForm = () => setQuery(getInitQuery())

    const loadingDesc = useMemo(() => {
        switch (editing) {
            case 'add':
                return 'Adding new classification...'
            case 'edit':
                return 'Updating classification...'
            case 'deactive':
                return 'Deactving classification...'
            default:
                return ''
        }
    }, [editing])

    const handleSearch = async () => {
        if (!query?.business?.code) {
            return
        }
        try {
            setSearching(true)
            const { data } = await getAdminClassifications(query.business.code)
            setItems(data)
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot load classifications', true))
        } finally {
            setSearching(false)
        }
    }

    const handleAddClassification = async () => {
        if (!query?.business?.code) {
            return
        }
        try {
            setEditing('add')
            const { data } = await addAdminClassifications(
                query.business.code,
                selectedClassification.categoryName,
                selectedClassification.isActive
            )

            setItems((prev) => ({ ...prev, items: [data, ...prev.items] }))
        } catch (error) {
            dispatch(showSnackbar('Cannot add classification', true))
        } finally {
            setEditing(null)
        }
    }

    const handleDeleteClassification = async () => {
        if (!selectedClassification.id) {
            return
        }
        try {
            setEditing('deactive')
            await deleteAdminClassifications(selectedClassification.id)
            setItems((prev) => ({
                ...prev,
                items: [
                    ...prev.items.map((item) => {
                        if (item.id === selectedClassification.id) {
                            return {
                                ...selectedClassification,
                                isActive: false,
                            }
                        }
                        return item
                    }),
                ],
            }))
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot deactive classification', true))
        } finally {
            setEditing(null)
        }
    }

    const handleEditClassification = async () => {
        if (!selectedClassification.id) {
            return
        }
        try {
            setEditing('edit')
            const { categoryName, isActive, id } = selectedClassification
            await editAdminClassifications(categoryName, isActive, id)
            setItems((prev) => ({
                ...prev,
                items: [
                    ...prev.items.map((item) => {
                        if (item.id === selectedClassification.id)
                            return selectedClassification
                        return item
                    }),
                ],
            }))
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot edit classification', true))
        } finally {
            setEditing(null)
        }
    }

    const handleCloseModal = () => {
        setDialogVariant(null)
    }

    const classificationHandler =
        (variant: TreeViewManageDialogVariant) =>
        (item: ClassificationItem) => {
            setSelectedClassification(item)

            if (variant === 'edit') {
                setTitle(`Edit classification ${item.categoryName}`)
                setDialogVariant('edit')
            }
            if (variant === 'deactive') {
                setTitle(`Deactivate classification ${item.categoryName}`)
                setDialogVariant('deactive')
            }
        }

    return (
        <Content
            loading={Boolean(editing)}
            loadingDesc={loadingDesc}
            error={error}
            onRetry={() => setError(null)}
        >
            <PageTitle
                title={'Classifications'}
                desc={
                    'This panel allows you to manage classifications for Divisions.'
                }
                to={
                    'https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B660CF3A2-0862-4C6B-9C4F-9821619FF106%7D&file=CCRP%20Managing%20Classifications.pptx&action=edit&mobileredirect=true'
                }
            />
            <SearchPanel
                initOpen={true}
                open={open}
                onOpen={(open) => {
                    setOpen(open)
                }}
                placeholder={'Search classifications'}
                disabledTextSearch={true}
            >
                <GridContainer>
                    <FormGridItem fullWidth>
                        <DivisionSelect
                            className={classes.field}
                            disabled={searching}
                            selectedDivision={query.division}
                            onDivisionChanged={handleChange('division')}
                            {...dataTestId('CLASSIFICATIONS_DIV')}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <BusinessUnitSelect
                            className={classes.field}
                            disabled={searching}
                            division={query.division}
                            selectedBusinessUnit={query.business}
                            onBusinessUnitChanged={handleChange('business')}
                            required
                            {...dataTestId('CLASSIFICATIONS_BA')}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <ProcessOwnerInfo
                            cache={open}
                            processOwners={processOwners}
                            onProcessOwnersLoad={setProcessOwners}
                            divisionCode={query?.division?.code}
                            businessUnitCode={query?.business?.code}
                            customMessage={
                                query?.business?.code
                                    ? null
                                    : 'Select Division to load Process Owners...'
                            }
                            requestToGetProcessOwners={() =>
                                getAdminClassificationsProcessOwners(
                                    query?.business?.divisionCode ??
                                        query?.division?.code,
                                    query?.business?.code
                                )
                            }
                        />
                    </FormGridItem>
                </GridContainer>
                <AdminSearchPanelActions
                    spacing={true}
                    isSearching={searching}
                    disabledSearch={!Boolean(query?.business)}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>
            {searching ? (
                <CircularProgressLoader />
            ) : (
                items && (
                    <>
                        <SearchResultsHeader
                            title={
                                items.items.length < 1 ? '' : 'Search results'
                            }
                            isSearching={searching}
                            renderAction={() =>
                                isPermissionDivBuProcessOwner &&
                                query?.business?.code ? (
                                    <Button
                                        onClick={() => {
                                            setTitle(
                                                `Add classification to ${query.business.code}`
                                            )
                                            setDialogVariant('add')
                                            setSelectedClassification({
                                                bu: items?.items[0]?.bu,
                                                categoryName: '',
                                                id: null,
                                                isActive: true,
                                            })
                                        }}
                                    >
                                        <Add
                                            className={classes.addRootIcon}
                                            fontSize="small"
                                        />
                                        Add classification
                                    </Button>
                                ) : null
                            }
                        />
                        {items.items.length > 0 && (
                            <ClassificationsList
                                classifications={items}
                                onEditClassification={classificationHandler(
                                    'edit'
                                )}
                                onDeleteClassification={classificationHandler(
                                    'deactive'
                                )}
                                canEdit={isPermissionDivBuProcessOwner}
                            />
                        )}
                    </>
                )
            )}
            {items?.items.length === 0 && !searching && (
                <NoResults
                    title="No results!"
                    subTitle="Change search criteria and try again or add new classification."
                />
            )}
            <ClassificationsManageDialog
                onCloseModal={handleCloseModal}
                onAddClassification={handleAddClassification}
                onDeleteClassification={handleDeleteClassification}
                onEditClassification={handleEditClassification}
                title={title}
                variant={dialogVariant}
                classificationItem={selectedClassification}
                onSetSelectedClassification={(item) =>
                    setSelectedClassification(item)
                }
            />
        </Content>
    )
}
