import MuiAccordionActions, {
    AccordionActionsProps,
} from '@mui/material/AccordionActions'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
    }),
    { name: 'AccordionActions' }
)

export const AccordionActions = (props: AccordionActionsProps) => {
    const classes = useStyles()
    return <MuiAccordionActions {...props} classes={classes} />
}
AccordionActions.muiName = 'AccordionActions'

export default AccordionActions
