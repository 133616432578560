import { getResources } from 'api/filters'
import { memo, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getUserProfile } from '../api'
import {
    AccessDeniedView,
    BaseBar,
    CannotLoad,
    ContentLoader,
} from '../components'
import { DASHBOARD_LOAD_PROFILE_DONE } from '../store/dashboard'
import { setGlobalResources } from '../store/resources'
import { IssueStatuses } from '../types/enums'
import { CcrpResources, FilterBase } from '../types/models'

export const RESOURCES_STORE_KEY = 'ccrp-app-resources'

enum LoadingResourcesState {
    INIT,
    LOADING,
    ERROR,
    ERROR_ACCESS,
    DONE,
}

export interface CcrpResourcesStore {
    version: string
    created: string
    resources: CcrpResources
}

const makeIssueStatusesOrder = (
    issueStatuses: FilterBase<number>[]
): FilterBase<number>[] => {
    const map = issueStatuses.reduce<
        Partial<Record<IssueStatuses, FilterBase<number>>>
    >((prev, curr) => {
        prev[curr.code as IssueStatuses] = curr
        return prev
    }, {})

    return [
        IssueStatuses.Draft,
        IssueStatuses.Not_Assigned,
        IssueStatuses.In_Process,
        IssueStatuses.In_Process_Overdue,
        IssueStatuses.In_Process_Verification,
        IssueStatuses.In_Process_Verification_Overdue,
        IssueStatuses.Resolved,
        IssueStatuses.Completed_Execution_Pending,
        IssueStatuses.Completed,
    ].map((x) => map[x])
}

const AppResourcesLoader = (props: any) => {
    const { children } = props
    const [state, setState] = useState<LoadingResourcesState>(
        LoadingResourcesState.INIT
    )
    const dispatch = useDispatch()
    const loadRresources = async () => {
        try {
            setState(LoadingResourcesState.LOADING)
            const [{ data: resources }, { data: profile }] = await Promise.all([
                getResources(),
                getUserProfile(),
            ])
            resources.complaintStatus = makeIssueStatusesOrder(
                resources?.complaintStatus ?? []
            )
            dispatch(setGlobalResources(resources))
            dispatch({
                type: DASHBOARD_LOAD_PROFILE_DONE,
                payload: { ...profile },
            })
            dispatch(setGlobalResources(resources))
            setState(LoadingResourcesState.DONE)
        } catch (error) {
            const err = error as { status: number; error: string }
            if (err.status === 403) {
                return setState(LoadingResourcesState.ERROR_ACCESS)
            }
            setState(LoadingResourcesState.ERROR)
        }
    }
    useEffect(() => {
        loadRresources()
    }, [])
    switch (state) {
        case LoadingResourcesState.DONE:
            return children ?? null
        case LoadingResourcesState.INIT:
        case LoadingResourcesState.LOADING:
            return (
                <>
                    <BaseBar />
                    <ContentLoader loading label={'Starting application…'} />
                </>
            )
        case LoadingResourcesState.ERROR:
            return (
                <>
                    <BaseBar />
                    <CannotLoad
                        title={'Cannot load application resources'}
                        subTitle={'Try again in a moment.'}
                        buttonLabel={'Try again'}
                        errorHandler={loadRresources}
                    />
                </>
            )
        case LoadingResourcesState.ERROR_ACCESS:
            return (
                <>
                    <BaseBar />
                    <AccessDeniedView withButton={false} />
                </>
            )
        default:
            return null
    }
}

export default memo(AppResourcesLoader)
