import { DialogProps } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { RETURN_TO_ISSUE_OWNER_DIALOG } from '../../consts/selectorIds'
import { ReturnToIssueOwnerQuery } from '../../types/actions'
import TextField from '../Base/TextField'
import Dialog from './Dialog'

const MAX_LENGTH_REASON = 1000

export interface ReturnToIssueOwnerDialogProps extends DialogProps {
    issueId: number
    rowVersion: string
    number: string
    onReturnIssue: (date: ReturnToIssueOwnerQuery) => void
}

export const ReturnToIssueOwnerDialog = (
    props: ReturnToIssueOwnerDialogProps
) => {
    const [reason, setReason] = useState<string>('')
    const [error, setError] = useState<string>('')

    const {
        open,
        issueId,
        number,
        onClose,
        rowVersion,
        onReturnIssue,
        ...other
    } = props
    const onTypeReason = (event: any) => {
        setReason(event.target.value)
        if (reason) {
            setError('')
        }
    }
    const onClickReturn = () => {
        if (!reason) {
            setError('Reason is required')
        } else if (MAX_LENGTH_REASON < reason.length) {
            setError(
                `The field Return reason must be a string with a maximum length of ${MAX_LENGTH_REASON}.`
            )
        } else {
            setError('')
            setReason('')
            onReturnIssue &&
                onReturnIssue({
                    issueId,
                    reason,
                    rowVersion,
                })
        }
    }
    return (
        <Dialog open={open} {...other}>
            <DialogTitle {...RETURN_TO_ISSUE_OWNER_DIALOG.TITLE}>
                {number}
            </DialogTitle>
            <DialogContent style={{ minHeight: 164 }}>
                <DialogContentText
                    {...RETURN_TO_ISSUE_OWNER_DIALOG.CONTENT_TEXT}
                >
                    You are about to return this issue to Issue's Owner. You
                    have to explain the reason why you're doing this.
                </DialogContentText>
                <TextField
                    {...RETURN_TO_ISSUE_OWNER_DIALOG.RETURN_REASON_INPUT}
                    variant="filled"
                    error={error !== ''}
                    helperText={error}
                    autoFocus
                    label="Return reason"
                    rows={3}
                    multiline
                    value={reason}
                    onChange={onTypeReason}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(e) => onClose(e, 'backdropClick')}
                    {...RETURN_TO_ISSUE_OWNER_DIALOG.ACTION_BUTTONS.CANCEL}
                >
                    Cancel
                </Button>
                <Button
                    {...RETURN_TO_ISSUE_OWNER_DIALOG.ACTION_BUTTONS
                        .RETURN_ISSUE}
                    color="secondary"
                    onClick={onClickReturn}
                >
                    Return issue
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReturnToIssueOwnerDialog.defaultProps = {
    open: false,
    rowVersion: null,
}

export default ReturnToIssueOwnerDialog
