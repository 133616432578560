import Check from '@mui/icons-material/Check'
import { getHighImpactSection } from 'api'
import clsx from 'clsx'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { HighImpact } from 'types/issueTypes'
import { DataArea, ValueView } from '../../components'
import Button from '../../components/Base/Button'
import HighImpactActionDialog from '../../components/Dialogs/HighImpactActionDialog'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { showSnackbar } from '../../store/app'
import { confirmHI, downgradeHI } from '../../store/myActions/index'
import { MyAction } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import IssueInfoBaseRow from './IssueInfoBaseRow'
import { useTilesStyles } from './tilesStyles'

export interface ToVerifyHighImpactProps {
    actionIssue: MyAction
}

export interface ToVerifyHighImpactState {
    loading: boolean
    highImpact: HighImpact
}

export const ToVerifyHighImpact = (props: ToVerifyHighImpactProps) => {
    const [{ loading, highImpact }, setState] =
        useState<ToVerifyHighImpactState>({
            loading: false,
            highImpact: null,
        })
    const classes = useTilesStyles()

    const dispatch = useDispatch()
    const [openDowngrade, setOpenDowngrade] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const { actionIssue, ...other } = props
    const { action } = actionIssue
    const risks = actionIssue ? getPotentailRisks(highImpact) : []
    const handleConfirmHighImpact = (motivation: string) => {
        setOpenConfirm(false)
        dispatch(
            confirmHI({
                rowVersion: actionIssue.rowVersion,
                issueId: actionIssue.issueId,
                motivation: motivation,
            })
        )
    }
    const handleDowngradeHighImpact = (motivation: string) => {
        setOpenDowngrade(false)
        dispatch(
            downgradeHI({
                rowVersion: actionIssue.rowVersion,
                issueId: actionIssue.issueId,
                motivation: motivation,
            })
        )
    }
    const handleExpand = async (expanded: boolean) => {
        if (!expanded || loading || highImpact) {
            return
        }
        try {
            setState((prev) => ({ ...prev, loading: true }))
            const { data: highImpact } = await getHighImpactSection(
                actionIssue.issueId
            )
            setState({
                loading: false,
                highImpact,
            })
        } catch (error) {
            dispatch(showSnackbar('Cannot load high impact section', true))
        }
    }
    const actions = (
        <>
            <Button
                onClick={() => setOpenDowngrade(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.DOWNGRADE}
                eventName="Downgrade HI issue on dashboard"
            >
                Downgrade
            </Button>
            <Button
                color="secondary"
                onClick={() => setOpenConfirm(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CONFIRM_HIGH_IMPACT}
                eventName="Confirm HI issue on dashboard"
            >
                Confirm high impact
            </Button>
        </>
    )
    return (
        <>
            <ExpandTile
                onExpand={handleExpand}
                issueNumber={actionIssue.issueNumber}
                subject={actionIssue.subject}
                issueStatusId={actionIssue.issueStatusId}
                isOverdue={action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                showActionStatus={true}
                actionCode={action.actionCode}
                {...other}
                issueId={actionIssue.issueId}
                collapsedSize="80px"
                actions={actions}
            >
                <IssueInfoBaseRow
                    deadlineDate={actionIssue?.action?.deadlineDate}
                    issueType={actionIssue.issueType}
                />
                {!loading && highImpact && (
                    <>
                        <ValueView
                            className={classes.singleRow}
                            singleLine={false}
                            label="High impact justification"
                            value={highImpact?.impactReason}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                                .HIGH_IMPACT_JUSTIFICATION}
                        />
                        <ValueView
                            className={classes.singleRow}
                            label="Potential business impact"
                            value={`${Number(
                                highImpact?.potentialBusinessImpact
                            )}$`}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                                .POTENTIAL_BUSINESS_IMPACT}
                        />

                        <ValueView
                            className={classes.singleRow}
                            label="Potential risks"
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                                .POTENTIAL_RISKS}
                        />
                        <DataArea>
                            {risks.map((risk, index) => (
                                <div
                                    key={index}
                                    className={clsx(classes.cell, classes.risk)}
                                >
                                    <Check className={classes.labelIcon} />
                                    {risk}
                                </div>
                            ))}
                        </DataArea>
                    </>
                )}
            </ExpandTile>
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to validate High Impact proposal for issue ${actionIssue.issueNumber}. Proceed?`}
                confirmLabel="Confirm high impact"
                closeLabel="Cancel"
                onConfirm={handleConfirmHighImpact}
                onClose={() => setOpenConfirm(false)}
                open={openConfirm}
            />
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to downgrade the CCRP issue ${actionIssue.issueNumber} to normal impact. Do you really wish to proceed?`}
                confirmLabel="Downgrade"
                closeLabel="Cancel"
                onConfirm={handleDowngradeHighImpact}
                onClose={() => setOpenDowngrade(false)}
                open={openDowngrade}
            />
        </>
    )
}

function getPotentailRisks(highImpact: HighImpact) {
    let risks = []
    if (!highImpact) {
        return risks
    }
    highImpact.isHealthAndSafetyImpact && risks.push('People health and safty')
    highImpact.isABBOrCustomerReputationAtStake &&
        risks.push('Environmental/sustainability reputation at stake')
    highImpact.isNegativeBusinessImpactToCustomer &&
        risks.push('Negative business impact on a Customer')
    highImpact.isNegativeBusinessImpactToABB &&
        risks.push('Negative business impact on ABB')
    highImpact.isNegativeImpactOnABBBrandImage &&
        risks.push('Negative impact on ABB brand image')
    highImpact.isPotentialSystemicIssuesOrProductRecall &&
        risks.push('Repeatable systemic issues or product recall')
    highImpact.isPotentialToLossCustomer &&
        risks.push(
            'Loss of large or new customer with significant growth opportunity'
        )
    return risks
}

export default ToVerifyHighImpact
