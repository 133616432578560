import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { TimelineState } from './index'

export interface TimelineDotProps extends React.HTMLProps<HTMLLIElement> {
    radius: number
    state?: TimelineState
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            boxSizing: 'border-box',
            display: 'block',
            width: (props: TimelineDotProps) => props.radius * 2,
            height: (props: TimelineDotProps) => props.radius * 2,
            borderRadius: (props: TimelineDotProps) => props.radius,
            backgroundColor: theme.palette.grey[200],
            '&$waiting': {
                border: `4px solid ${theme.palette.grey[300]}`,
                backgroundColor: 'initial',
            },
            '&$inprocess': {
                border: `4px solid ${theme.palette.blue.main}`,
                backgroundColor: 'initial',
            },
            '&$done': {
                backgroundColor: theme.palette.blue.main,
            },
        },
        ['waiting' as TimelineState]: {},
        ['inprocess' as TimelineState]: {},
        ['done' as TimelineState]: {},
    }),
    { name: 'TimelineDot' }
)

export const TimelineDot = (props: TimelineDotProps) => {
    const {
        children,
        radius,
        state,
        className: classNameProp,
        ...other
    } = props
    const classes = useStyles(props)
    return (
        <span
            className={clsx(classNameProp, classes.root, classes[state])}
            {...other}
        >
            {children}
        </span>
    )
}

export default TimelineDot
