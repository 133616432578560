import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from '@mui/styles'
import { getActionPlanTypes } from 'api'
import { ReactNode, useState } from 'react'
import { ActionType } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'
import theme from '../../styles/themeBase'
import { TextField } from '../Base'

export interface ActionPlanTypeSelectProps {
    value: ActionType
    onTypeChanged: (newValue: ReactNode) => void
    disabled: boolean
    error: boolean
    helperText: string
    required: boolean
}

const useStyles = makeStyles(
    () => ({
        groupLabel: {
            fontSize: theme.spacing(1.5),
        },
    }),
    { name: 'ActionPlanTypeSelect' }
)

export const ActionPlanTypeSelect = (props: ActionPlanTypeSelectProps) => {
    const classes = useStyles()
    const [options, setOptions] = useState([])
    const { error: errorProps, helperText: helperTextProps, required } = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)
    const { value, onTypeChanged, disabled } = props

    const loadTypes = async () => {
        try {
            setLoading(true)
            const res = await getActionPlanTypes()
            const { data } = res
            const parsedData = []
            const groups = data.filter((item) => !item.selectable)
            const selectedAndSortedData = data
                .filter((item) => item.selectable)
                .sort((x, y) => x.parentId - y.parentId)
            selectedAndSortedData.forEach((item) => {
                const groupName = groups.find(
                    (i) => i.id === item.parentId
                ).name
                parsedData.push({ ...item, groupName })
            })
            setOptions(parsedData)
            setLoading(false)
        } catch (err) {
            setError(err)
            setLoading(false)
        }
    }

    return (
        <Autocomplete
            classes={{
                groupLabel: classes.groupLabel,
            }}
            onOpen={() => {
                if (!options || options?.length === 0) {
                    loadTypes()
                }
            }}
            groupBy={(option) => `${option.groupName}`}
            loading={loading}
            loadingText="Loading types"
            disabled={disabled}
            options={options}
            value={value}
            onChange={(e, newValue) => onTypeChanged(newValue)}
            getOptionLabel={(option) => option.name}
            id="clear-on-escape"
            clearOnEscape
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                    }}
                    error={Boolean(error) || errorProps}
                    helperText={
                        (Boolean(error) && 'Cannot load types list') ||
                        helperTextProps
                    }
                    label="Action type"
                    required={required}
                    {...dataTestId('ACTION_PLAN_ACTION_TYPE')}
                />
            )}
        />
    )
}

export default ActionPlanTypeSelect
