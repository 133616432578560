import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { BaseBar, Link, RoundedButton } from '../components'
import { GDPR_PAGE } from '../consts/selectorIds'
import { DashboardStoreState } from '../store/dashboard'

export const gdprListKey: string = 'ccrp-gdpr'

interface UserGdpr {
    userEmail: string
    isGdprAccepted: boolean
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        contentArea: {
            display: 'flex',
            justifyContent: 'center',
            '@media (max-width: 380px)': {
                display: 'block',
                justifyContent: 'none',
            },
        },
        content: {
            maxWidth: 800,
            overflow: 'hidden',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            padding: theme.spacing(3),
            backgroundColor: theme.palette.common.white,
            '@media (max-width: 380px)': {
                width: 'auto',
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
        },
        section: {
            marginBottom: theme.spacing(3),
        },
        tableWrapper: {
            position: 'relative',
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
            transition: `box-shadow ${theme.transitions.easing.easeIn} ${theme.transitions.duration.standard}ms`,
            '@media (max-width: 580px)': {
                overflowX: 'auto',
            },
        },
        paragraph: {
            ...theme.typography.body1,
        },
        list: {
            listStyle: 'outside',
            marginTop: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(2),
            '& >li': {
                marginBottom: theme.spacing(0.5),
                lineHeight: 1.2,
            },
        },
        actionArea: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderTop: '0.4px solid rgba(0, 0, 0,.125)',
            marginTop: theme.spacing(4),
            paddingTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            '@media (max-width: 580px)': {
                flexDirection: 'column',
                paddingBottom: theme.spacing(4),
            },
        },
        actionButton: {
            width: 320,
            marginBottom: theme.spacing(2),
            '@media (max-width: 380px)': {
                width: '100%',
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
            },
        },
    }),
    { name: 'GdprGuard' }
)

const addOrUpdateUserGdpr = (userEmail: string, isGdprAccepted = false) => {
    let gdprList = JSON.parse(localStorage.getItem(gdprListKey))
    if (gdprList) {
        let gdpr = gdprList.find(
            (item: UserGdpr) => item.userEmail === userEmail
        )
        if (gdpr) {
            gdpr.isGdprAccepted = isGdprAccepted
        } else {
            gdprList.push({
                userEmail: userEmail,
                isGdprAccepted: isGdprAccepted,
            })
        }
    } else {
        gdprList = []
        gdprList.push({
            userEmail: userEmail,
            isGdprAccepted: isGdprAccepted,
        })
    }
    localStorage.setItem(gdprListKey, JSON.stringify(gdprList))
}

const findUserGdpr = (userEmail: string): UserGdpr => {
    let gdprList: UserGdpr[] = JSON.parse(localStorage.getItem(gdprListKey))
    if (Array.isArray(gdprList)) {
        return gdprList.find((item: UserGdpr) => item.userEmail === userEmail)
    }
    return null
}

export const GdprGuard: FC<any> = (props) => {
    const { children } = props
    const classes = useStyles()
    const [gdprAccepted, setGdprAccepted] = useState(true)
    const { profile } = useSelector<RootState, DashboardStoreState>(
        (state) => state?.dashboard
    )

    useEffect(() => {
        const user = findUserGdpr(profile.email)
        setGdprAccepted(Boolean(user))
    }, [profile?.email])
    const handleAccept = () => {
        addOrUpdateUserGdpr(profile.email, true)
        setGdprAccepted(true)
    }
    const handleNotAccept = () => {
        addOrUpdateUserGdpr(profile.email, false)
        window.location.assign('https://www.abb.com')
    }
    const tableWrapperClasses = [classes.tableWrapper, 'scrollStyle']
    return gdprAccepted ? (
        children
    ) : (
        <>
            <BaseBar />
            <div className={classes.contentArea}>
                <Helmet>
                    <title>CCRP privacy and conditions</title>
                </Helmet>
                <article className={classes.content}>
                    <section className={classes.section}>
                        <Typography variant="h1">
                            Privacy and conditions
                        </Typography>
                        <p className={classes.paragraph}>
                            ABB is fully committed to protecting your privacy
                            and respecting your rights to control how your
                            personal data is used. This notice explains why and
                            how ABB collects and uses your personal data and the
                            rights you have in relation to that data.
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">Your Personal Data</Typography>
                        <p className={classes.paragraph}>
                            ABB is responsible for your personal data. ABB
                            refers to ABB Asea Brown Boveri Ltd and its
                            subsidiary companies. Information on all of ABB’s
                            subsidiaries can be found in the{' '}
                            <Link
                                url="https://new.abb.com/privacy-policy/subsidiaries"
                                label="list of ABB subsidiaries"
                            />
                            . For the purposes of applicable data protection
                            law, the controller of your data will be the ABB
                            affiliate which is providing services or
                            communicating to you. This notice applies to all
                            such companies. Controllers located outside of the
                            European Union (EU), have appointed a representative
                            for EU data privacy matters. For more information,
                            please contact us at{' '}
                            <Link
                                url="https://new.abb.com/privacy"
                                label="www.abb.com/privacy"
                            />
                            .
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">What we need</Typography>
                        <p className={classes.paragraph}>
                            We are collecting and using personal data about you,
                            including:
                        </p>
                        <ul className={classes.list}>
                            <li>Your first and last name</li>
                            <li>Your email address</li>
                            <li>Your GUID</li>
                            <li>Your phone number</li>
                            <li>
                                Your organizational assignment including
                                location details
                            </li>
                            <li>Your IP address</li>
                            <li>Any preferences you provide us with</li>
                            <li>
                                Tracking and analytics data about your use of
                                the CCRP light tool
                            </li>
                        </ul>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">Why we need it</Typography>
                        <p className={classes.paragraph}>
                            We use the personal data that we collect for the
                            following purposes:
                        </p>
                        <ul className={classes.list}>
                            <li>
                                to manage and administer your access to and use
                                of the CCRP light tool
                            </li>
                            <li>
                                to provide any automatic notifications regarding
                                issue resolution progress
                            </li>
                            <li>to contact you to provide product support</li>
                            <li>
                                to fulfill your requests for information or
                                answer your inquiries about the tool
                            </li>
                            <li>
                                to develop and improve our services through
                                assessment and analysis of the information
                                collected when you visit the CCRP light tool.
                            </li>
                        </ul>
                        <p className={classes.paragraph}>
                            We only collect the personal data from you that we
                            need for these purposes. We process your personal
                            data for the purposes described in this notice based
                            on the legitimate interests of ABB. You may obtain a
                            copy of our assessment of why we may process your
                            personal data for these interests by visiting{' '}
                            <Link
                                url="https://new.abb.com/privacy"
                                label="www.abb.com/privacy"
                            />{' '}
                            We may also anonymise your personal data, so it no
                            longer identifies you and use it for various
                            purposes, including data analysis, improving our
                            services and testing certain of our IT systems.
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">Why we need it</Typography>
                        <p className={classes.paragraph}>
                            We only share your personal data as described in
                            this notice. We always put safeguards in place to
                            protect your personal data where we share it with a
                            party outside the EU. If this is the case, the
                            safeguard is described below. We share your personal
                            data with the following types of third parties for
                            reasons described below as part of the processing
                            activities:
                        </p>
                        <div className={tableWrapperClasses.join(' ')}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>
                                            Recipient name or category (in
                                            non-EU country)
                                        </th>
                                        <th>Recipient location</th>
                                        <th>Purpose</th>
                                        <th>Safeguards in place to protect</th>
                                    </tr>
                                    <tr>
                                        <td>IT Service Providers</td>
                                        <td>Within the EU</td>
                                        <td>
                                            Provision of hosting, development
                                            and maintenance services
                                        </td>
                                        <td>Within the EU</td>
                                    </tr>
                                    <tr>
                                        <td>ABB Affiliates</td>
                                        <td>Inside and outside the EU</td>
                                        <td>
                                            To enable the right ABB company to
                                            respond to your requests and provide
                                            information about their products and
                                            services
                                        </td>
                                        <td>
                                            Data transfer agreement based on EU
                                            Model Clauses
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className={classes.paragraph}>
                            You may obtain a copy of the safeguards which we use
                            to protect your personal data by visiting{' '}
                            <Link
                                url="https://new.abb.com/privacy"
                                label="www.abb.com/privacy"
                            />
                            .
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">
                            How long we keep it
                        </Typography>
                        <p className={classes.paragraph}>
                            We will keep your personal data for as long as
                            necessary for the purposes set out above, or until
                            you delete your account. After this time, we will
                            securely delete your personal data. If necessary to
                            meet legal or regulatory requirements and/or resolve
                            disputes, we may keep some of your personal data
                            after this time.
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">
                            What are your rights?
                        </Typography>
                        <p className={classes.paragraph}>
                            You have the right to correct any error in your
                            personal data or update it. Depending on the
                            jurisdiction in which you are located and in which
                            your personal data is processed, you may also have
                            the right to:
                        </p>
                        <ul className={classes.list}>
                            <li>
                                receive a copy of your personal data that we
                                hold,
                            </li>
                            <li>
                                delete your personal data that we no longer have
                                a lawful ground to use,
                            </li>
                            <li>
                                port your personal data to a new supplier (if
                                applicable),
                            </li>
                            <li>
                                object to the processing of your personal data
                                based on the legitimate interests ground
                                (provided our reasons for the processing don’t
                                outweigh any prejudice to your data privacy
                                rights), or
                            </li>
                            <li>
                                restrict how we use your personal data whilst a
                                complaint is being investigated,
                            </li>
                        </ul>
                        <p className={classes.paragraph}>
                            you may make such request at{' '}
                            <Link
                                url="https://new.abb.com/privacy"
                                label="www.abb.com/privacy"
                            />
                            . In certain circumstances, we may need to restrict
                            the above rights to safeguard the public interest
                            (e.g. the prevention or detection of crime) and our
                            interests (e.g. the maintenance of legal privilege).
                            If you have any questions about how we use your
                            personal data or wish to make a complaint about how
                            we have handled your personal data, you may contact
                            our Group Data Protection Officer at{' '}
                            <Link
                                url="mailto:privacy@abb.com"
                                label="privacy@abb.com"
                            />
                            , or submit your complaint at www.abb.com/privacy.
                            If you are not satisfied with our response or
                            believe we are processing your personal data against
                            the law, you may also contact the Data Privacy
                            Authority in your country of residence or work or
                            where you believe an infringement of data privacy
                            laws may have taken place.
                        </p>
                    </section>
                    <section className={classes.section}>
                        <Typography variant="h2">Cookies</Typography>
                        <p className={classes.paragraph}>
                            The CCRP light website uses "first party" cookies
                            (cookies set by the ABB website) for storing
                            preferences and for storing data needed throughout
                            your visit on the site (for example, to store your
                            log in and access rights) and to monitor performance
                            of the site. The table below describes the web
                            tracking cookies that may be used by the CCRP light
                            website.
                        </p>
                        <div className={tableWrapperClasses.join(' ')}>
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Provider/Name of Cookie</th>
                                        <th>Type of Cookie</th>
                                        <th>Purpose</th>
                                    </tr>
                                    <tr>
                                        <td>jwt_Abbcom</td>
                                        <td>First Party</td>
                                        <td>
                                            Storing data needed throughout your
                                            visit on the site
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>ARRAffinity</td>
                                        <td>First Party</td>
                                        <td>
                                            Used for stay with a certain
                                            instance of web app or web site in
                                            Azure.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>First Party</td>
                                        <td>Used for user identification</td>
                                    </tr>
                                    <tr>
                                        <td>idsrv.session</td>
                                        <td>First Party</td>
                                        <td>
                                            Uniquely identifying user’s session
                                            on server side
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            .AspNetCore.Identity.Application
                                        </td>
                                        <td>First Party</td>
                                        <td>Storing identity server token</td>
                                    </tr>
                                    <tr>
                                        <td>.AspNet.Consent</td>
                                        <td>First Party</td>
                                        <td>Consent for using cookies</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </section>
                    <div className={classes.actionArea}>
                        <RoundedButton
                            className={classes.actionButton}
                            onClick={handleNotAccept}
                            size="small"
                            {...GDPR_PAGE.LEAVE_BUTTON}
                        >
                            I do not agree. Leave the site
                        </RoundedButton>
                        <RoundedButton
                            className={classes.actionButton}
                            onClick={handleAccept}
                            color="secondary"
                            variant="contained"
                            {...GDPR_PAGE.ACKNOWLEDGE_BUTTON}
                        >
                            I acknowledge
                        </RoundedButton>
                    </div>
                </article>
            </div>
        </>
    )
}

export default GdprGuard
