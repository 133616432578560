import { makeStyles } from '@mui/styles'
import { memo, useEffect, useState } from 'react'

import InfoIcon from '@mui/icons-material/Info'
import {
    AccordionActions,
    Button,
    FormControlLabel,
    Link,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material'
import Collapse from '@mui/material/Collapse'
import clsx from 'clsx'
import { HighImpactActionDialog } from 'components'
import TextField from 'components/Base/TextField'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import { useDispatch } from 'react-redux'
import { confirmHI, downgradeHI } from 'store/issue/actions'
import { AbbTheme } from 'styles/createAbbTheme'
import { ActionsKeys, IssueValuesKeys } from 'types/enums'
import { Employee, HighImpact } from 'types/issueTypes'
import { dataTestId } from 'utils'
import { useFieldMatrix, useFieldWithError, useIssueAction } from '../matrix'
import ImpactCategories from './ImpactCategories'
import { CAPTURE_HIGH_IMPACT_ID } from './utils'

const potentialBusinessImpactPropName = 'potentialBusinessImpact'
const impactReasonPropName = 'impactReason'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        classification: {
            marginTop: theme.spacing(2),
        },
        label: {
            display: 'block',
            transform: 'translate(0, 1.5px) scale(0.75) !important',
            transformOrigin: '0px 0px',
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        field: {
            marginTop: theme.spacing(1),
        },
        rpnMessage: {
            fontWeight: 500,
            color: theme.palette.grey[300],
        },
        rpnIcon: {
            height: theme.typography.fontSize / 1.3,
            width: theme.typography.fontSize / 1.3,
            color: theme.palette.grey[300],
            marginRight: theme.spacing(0.6),
        },
    }),
    { name: 'ImpactClassification' }
)

const MAX_POTENTIAL_BUSINESS_IMPACT = 2000000000

export interface ImpactClassificationProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        Partial<{
            lightMode: boolean
            disabled: boolean
            canConfirmHI?: boolean
            highImpact: HighImpact
            issueNumber?: string
            onImpactClassificationChanged?: (
                key: keyof HighImpact,
                value: any
            ) => void
            issueOwner: Employee
        }> {}

const ImpactClassification = (props: ImpactClassificationProps) => {
    const {
        canConfirmHI = false,
        disabled,
        lightMode,
        issueNumber = '',
        className: classNameProp,
        highImpact,
        onImpactClassificationChanged,
        issueOwner,
        ...other
    } = props
    const [state, setState] = useState(true)
    const [open, setOpen] = useState<'confirm' | 'downgrade' | null>(null)
    const canConfirmHi = useIssueAction(ActionsKeys.ConfirmHighImpact)
    const canDowngradeHi = useIssueAction(ActionsKeys.DowngradeHighImpact)
    const dispatch = useDispatch()
    const [potentialBusinessImpact, setPotentialBusinessImpact] = useState(0)
    const [impactReason, setImpactReason] = useState('')
    const isHighImpactField = useFieldMatrix(IssueValuesKeys.IsHighImpact)
    const [showRpnMessage, setShowRpnMessage] = useState<string | null>(null)
    useEffect(() => {
        if (
            !issueOwner ||
            !['ELDS', 'IAPI'].includes(
                issueOwner?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code
            )
        )
            return setShowRpnMessage(null)
        if (issueOwner?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code === 'ELDS')
            setShowRpnMessage(
                'https://abb.sharepoint.com/:x:/r/sites/NPS/CCRP/Shared%20Documents/External%20Documents/RPN/ELDS%20Quality%20Ranking%20Number%20(QRN)%20Calculation.xlsx?d=w4134f842ed754f79bcc96b7d7f4aa518&csf=1&web=1&e=E1kjuq'
            )
        if (issueOwner?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code === 'IAPI')
            setShowRpnMessage(
                'https://abb.sharepoint.com/:x:/r/sites/PAPI_IMS_Portal/_layouts/15/Doc.aspx?sourcedoc=%7BCF60C770-4DD7-4EAD-927C-5458964E093E%7D&file=PAPI%20Top%205%20QI%20-%20RPN%20_v1.xlsx&action=default&mobileredirect=true'
            )
    }, [issueOwner])

    useEffect(() => {
        setPotentialBusinessImpact(Number(highImpact?.potentialBusinessImpact))
        setImpactReason(highImpact?.impactReason ?? '')
    }, [highImpact])

    const potentialBusinessImpactError =
        MAX_POTENTIAL_BUSINESS_IMPACT <= potentialBusinessImpact
    const potentialBusinessImpactField = useFieldWithError(
        IssueValuesKeys.PotentialBusinessImpact,
        {
            defaultHelperText:
                potentialBusinessImpactError &&
                `The field Potential business impact must be between 1 and ${MAX_POTENTIAL_BUSINESS_IMPACT}.`,
            defaultError: potentialBusinessImpactError,
            disabled,
        }
    )
    const impactReasonField = useFieldWithError(IssueValuesKeys.ImpactReason, {
        disabled,
    })
    const classes = useStyles(props)

    const reasonProp = highImpact?.impactReason ?? ''
    useEffect(() => {
        setImpactReason(reasonProp)
    }, [reasonProp])
    const handleConfirmHighImpact = (motivation: string) => {
        setOpen(null)
        dispatch(confirmHI(motivation))
    }
    const handleDowngradeHighImpact = (motivation: string) => {
        setOpen(null)
        dispatch(downgradeHI(motivation))
    }
    if (!highImpact) {
        return null
    }
    const {
        isHighImpact,
        isHealthAndSafetyImpact,
        isABBOrCustomerReputationAtStake,
        isNegativeBusinessImpactToCustomer,
        isNegativeBusinessImpactToABB,
        isNegativeImpactOnABBBrandImage,
        isPotentialToLossCustomer,
        isPotentialSystemicIssuesOrProductRecall,
    } = highImpact
    return (
        <Collapse in={!lightMode}>
            <div
                id={CAPTURE_HIGH_IMPACT_ID}
                className={clsx(classNameProp)}
                {...other}
            >
                <Typography variant="h6">Issue impact</Typography>
                <RadioGroup
                    {...ISSUE_VIEW_PAGE.ISSUE_IMPACT.TYPE_RADIO_GROUP}
                    value={highImpact.isHighImpact}
                    disabled={isHighImpactField.disabled}
                    onChange={() =>
                        onImpactClassificationChanged &&
                        onImpactClassificationChanged(
                            'isHighImpact',
                            !highImpact.isHighImpact
                        )
                    }
                >
                    {showRpnMessage && (
                        <div>
                            <InfoIcon className={classes.rpnIcon} />
                            <Typography
                                variant={'caption'}
                                className={classes.rpnMessage}
                                {...dataTestId('LINK_TO_RPN_TABLE')}
                            >
                                Make sure the correct issue impact is selected
                                by checking the Risk Priority Name table
                                available{' '}
                                <Link
                                    href={showRpnMessage}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    underline="always"
                                >
                                    here
                                </Link>
                                .
                            </Typography>
                        </div>
                    )}
                    <FormControlLabel
                        value={false}
                        disabled={isHighImpactField.disabled || disabled}
                        control={<Radio />}
                        label="Normal impact"
                    />
                    <FormControlLabel
                        value={true}
                        disabled={isHighImpactField.disabled || disabled}
                        control={<Radio />}
                        label="High impact"
                    />
                </RadioGroup>
                <ImpactCategories
                    isHighImpact={isHighImpact}
                    isHealthAndSafetyImpact={isHealthAndSafetyImpact}
                    isABBOrCustomerReputationAtStake={
                        isABBOrCustomerReputationAtStake
                    }
                    isNegativeBusinessImpactToCustomer={
                        isNegativeBusinessImpactToCustomer
                    }
                    isNegativeBusinessImpactToABB={
                        isNegativeBusinessImpactToABB
                    }
                    isNegativeImpactOnABBBrandImage={
                        isNegativeImpactOnABBBrandImage
                    }
                    isPotentialToLossCustomer={isPotentialToLossCustomer}
                    isPotentialSystemicIssuesOrProductRecall={
                        isPotentialSystemicIssuesOrProductRecall
                    }
                    disabled={!isHighImpact}
                    onImpactClassificationCategoryChanged={(key, value) =>
                        onImpactClassificationChanged &&
                        onImpactClassificationChanged(key, value)
                    }
                >
                    <TextField
                        {...ISSUE_VIEW_PAGE.ISSUE_IMPACT.HI_JUSTIFICATION_INPUT}
                        className={classes.field}
                        value={impactReason}
                        label="High impact justification"
                        onChange={(e) => setImpactReason(e.target.value)}
                        onBlur={(e) =>
                            onImpactClassificationChanged &&
                            onImpactClassificationChanged(
                                impactReasonPropName,
                                e.target.value
                            )
                        }
                        {...impactReasonField}
                        multiline
                    />
                    <TextField
                        {...ISSUE_VIEW_PAGE.ISSUE_IMPACT
                            .POTENTIAL_BUSINESS_IMPACT_INPUT}
                        className={classes.field}
                        type="number"
                        value={potentialBusinessImpact}
                        label="Potential business impact (kUSD)"
                        onKeyPress={(e) =>
                            !Number.isInteger(
                                Number(
                                    String.fromCharCode(e.keyCode || e.which)
                                )
                            ) && e.preventDefault()
                        }
                        onChange={(e) => {
                            if (!state) return
                            let value = Number(e.target.value)
                            value = value < 0 ? 0 : value
                            setPotentialBusinessImpact(value)
                        }}
                        onBlur={() =>
                            onImpactClassificationChanged &&
                            onImpactClassificationChanged(
                                potentialBusinessImpactPropName,
                                potentialBusinessImpact
                            )
                        }
                        onWheel={() => setState(false)}
                        onMouseLeave={() => setState(true)}
                        {...potentialBusinessImpactField}
                    />
                </ImpactCategories>
            </div>
            {canConfirmHI && (
                <AccordionActions>
                    {canDowngradeHi && (
                        <Button
                            onClick={() => setOpen('downgrade')}
                            {...dataTestId('ISSUE_FORM_DOWNGRADE_HI')}
                        >
                            Downgrade
                        </Button>
                    )}
                    {canConfirmHi && (
                        <Button
                            onClick={() => setOpen('confirm')}
                            color="secondary"
                            {...dataTestId('ISSUE_FORM_CONFIRM_HI')}
                        >
                            Confirm high impact
                        </Button>
                    )}
                </AccordionActions>
            )}
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to validate High Impact proposal for issue ${issueNumber}. Proceed?`}
                confirmLabel="Confirm high impact"
                closeLabel="Cancel"
                onConfirm={(motivation) => handleConfirmHighImpact(motivation)}
                onClose={() => setOpen(null)}
                open={open === 'confirm'}
            />
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to downgrade the CCRP issue ${issueNumber} to normal impact. Do you really wish to proceed?`}
                confirmLabel="Downgrade"
                closeLabel="Cancel"
                onConfirm={(motivation) =>
                    handleDowngradeHighImpact(motivation)
                }
                onClose={() => setOpen(null)}
                open={open === 'downgrade'}
            />
        </Collapse>
    )
}

ImpactClassification.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default memo(ImpactClassification)
