import axios from 'axios'
import { call, cancelled, takeLatest, put } from 'redux-saga/effects'
import {
    ISSUE_REJECT_RESOLUTION_TO_RO_CALL,
    loadIssue,
    RejectResolutionToROCommand,
    rejectResolutionToRODone,
    setIssueActionFail,
} from '..'

import { removeMyActionById } from 'store/myActions'
import { showSnackbar } from 'store/app'
import { IssueActionsTypes } from 'types/enums'
import { rejectResolutionToRO } from 'api'

export function* rejectResolutionToROAction(
    action: RejectResolutionToROCommand
) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    try {
        yield call(rejectResolutionToRO, query, cancelSource.token)
        yield put(rejectResolutionToRODone())
        yield put(
            removeMyActionById(query.issueId, IssueActionsTypes.To_Complete)
        )
        yield put(showSnackbar('Issue was rejected to RO'))
        yield put(loadIssue(query.issueId))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_REJECT_RESOLUTION_TO_RO_CALL, error))
        yield put(showSnackbar('Cannot reject issue!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* rejectResolutionToROSaga() {
    yield takeLatest(
        [ISSUE_REJECT_RESOLUTION_TO_RO_CALL],
        rejectResolutionToROAction
    )
}
