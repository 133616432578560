import { Domain } from '@mui/icons-material'
import { useTilesStyles } from 'Issues/Tiles'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { ResolvingUnit } from 'types/issueTypes'
import { getResolutionUnitName } from 'utils'
import { ValueView } from '..'

export interface ResolutionUnitValueProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    resolvingUnit: ResolvingUnit
}

export const ResolutionUnitValue = (props: ResolutionUnitValueProps) => {
    const { resolvingUnit, ...other } = props
    const classes = useTilesStyles(props)
    const resolutionUnit = useMemo(
        () => getResolutionUnitName(resolvingUnit),
        [resolvingUnit]
    )
    return resolutionUnit ? (
        <ValueView
            singleLine={false}
            icon={<Domain className={classes.labelIcon} />}
            label="Resolving unit"
            value={resolutionUnit}
            {...other}
        />
    ) : null
}

export default ResolutionUnitValue
