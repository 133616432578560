import { useMemo } from 'react'
import { getPartOfTable } from 'utils'
import { CustomerContactsManagePageCard } from 'Admin'
import { AdminCustomer } from 'api'
import { SearchResultsWithPagination } from 'components'

export const ADMIN_CUSTOMERS_PAGE_SIZE = 20

export interface CustomerContactsManagePageResultsProps {
    noResults?: boolean
    isSearching: boolean
    searchResults: AdminCustomer[]
    page: number
    onPageChange: (page: number) => void
}

export const CustomerContactsManagePageResults = (
    props: CustomerContactsManagePageResultsProps
) => {
    const {
        noResults = false,
        isSearching,
        searchResults,
        page,
        onPageChange,
    } = props

    const cards = useMemo(() => {
        let results: AdminCustomer[] = []

        if (searchResults.length > 0) {
            results = getPartOfTable<AdminCustomer>(
                searchResults,
                ADMIN_CUSTOMERS_PAGE_SIZE,
                page
            )
        }

        return results.map((adminCustomer) => (
            <CustomerContactsManagePageCard
                customer={adminCustomer}
                key={adminCustomer.guid}
            />
        ))
    }, [page, searchResults])

    return (
        <SearchResultsWithPagination
            isSearching={isSearching}
            page={page}
            pageSize={ADMIN_CUSTOMERS_PAGE_SIZE}
            itemCount={searchResults.length}
            onPageChange={onPageChange}
            errorTitle="No results!"
            errorSubTitle="Change search criteria and try again."
            errorVisible={noResults}
        >
            {cards}
        </SearchResultsWithPagination>
    )
}
