import {
    Business,
    DateRangeRounded,
    LocalOfferRounded,
    OpenInNewOutlined,
    PersonRounded,
} from '@mui/icons-material'
import { Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    CardViewHeader,
    CardViewResults,
    CardViewResultsContainer,
    ExpandableCardView,
} from 'components'
import { HtmlHTMLAttributes, MouseEventHandler, useMemo } from 'react'
import { generatePath } from 'react-router'
import { ISSUE_VIEW } from 'routes'
import { dataTestId, EMPTY_VALUE_PLACEHOLDER, humanizeDateTime } from 'utils'
import { ActionPlanItemViewModel } from '.'
import { useActionPlanStatusChips } from '../../hooks'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            padding: theme.spacing(2, 2, 0, 2),
            marginBottom: 0,
        },
        content: { paddingBottom: 0 },
        issueLink: {
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        openIcon: {
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'ActionPlanItemDashboard' }
)

export interface ActionPlanItemDashboardProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    actionPlanItem: ActionPlanItemViewModel
    openActionItem: (actionPlanItem: ActionPlanItemViewModel) => void
}

export const ActionPlanItemDashboard = (
    props: ActionPlanItemDashboardProps
) => {
    const { className, actionPlanItem, openActionItem, ...other } = props
    const classes = useStyles()
    const TabsWithStatus = useActionPlanStatusChips(actionPlanItem)
    const content = useMemo<CardViewResults>(() => {
        const {
            actionCreator,
            actionOwnerFullName,
            actionOwner,
            actionCreatorFullName,
            actionType,
            actionDeadline,
            resolutionOwner,
            resolvingUnitName,
            resolutionOwnerFullName,
            actionDescription,
            activitiesDescription,
        } = actionPlanItem
        const actionTypeName = actionType.name
        const results: CardViewResults = [
            {
                label: 'Action deadline',
                ...dataTestId('ACTION_DASHBOARD_DEADLINE'),
                renderIcon: (props) => <DateRangeRounded {...props} />,
                text: humanizeDateTime(actionDeadline),
                GridProps: {
                    sm: 4,
                },
            },
            {
                label: 'Action type',
                ...dataTestId('ACTION_DASHBOARD_TYPE'),
                renderIcon: (props) => <LocalOfferRounded {...props} />,
                text: actionTypeName,
                GridProps: {
                    sm: 8,
                },
            },
            {
                label: 'Resolving unit',
                ...dataTestId('ACTION_DASHBOARD_RESOLVING_UNIT'),
                renderIcon: (props) => <Business {...props} />,
                text: resolvingUnitName,
                fullWidth: true,
            },
            {
                label: 'Action owner',
                ...dataTestId('ACTION_DASHBOARD_ACTION_OWNER'),
                renderIcon: (props) => <PersonRounded {...props} />,
                text: actionOwnerFullName,
                tooltip: actionOwner?.email,
                GridProps: {
                    sm: 4,
                },
            },
            {
                label: 'Action creator',
                ...dataTestId('ACTION_DASHBOARD_ACTION_CREATOR'),
                renderIcon: (props) => <PersonRounded {...props} />,
                text: actionCreatorFullName,
                tooltip: actionCreator.email,
                GridProps: {
                    sm: 4,
                },
            },
            {
                label: 'Resolution owner',
                ...dataTestId('ACTION_DASHBOARD_RESOLUTION_OWNER'),
                renderIcon: (props) => <PersonRounded {...props} />,
                text: resolutionOwnerFullName,
                tooltip: resolutionOwner?.email,
                GridProps: {
                    sm: 4,
                },
            },
        ]
        if (actionDescription) {
            results.push({
                label: 'Action description',
                ...dataTestId('ACTION_DASHBOARD_ACTION_DESCRIPTION'),
                text: actionDescription,
                fullWidth: true,
            })
        }
        if (activitiesDescription) {
            results.push({
                label: 'Activitiy description',
                ...dataTestId('ACTION_DASHBOARD_ACTIVITY_DESCRIPTION'),
                text: activitiesDescription,
                fullWidth: true,
            })
        }
        return results
    }, [])
    const handleOpenIssue: MouseEventHandler<HTMLSpanElement> = (e) => {
        e.stopPropagation()
        window.open(
            `${window.location.origin}${generatePath(ISSUE_VIEW, {
                id: actionPlanItem.issueId,
            })}`
        )
    }
    return (
        <ExpandableCardView
            {...dataTestId(
                `ACTION_DASHBOARD_CONTAINER_${actionPlanItem?.actionName}`
            )}
            className={clsx(className, classes.root)}
            renderHeader={() => (
                <CardViewHeader
                    className={classes.header}
                    title={
                        actionPlanItem?.actionName ?? EMPTY_VALUE_PLACEHOLDER
                    }
                    subTitle={
                        <Tooltip
                            title={`Open issue ${actionPlanItem.issueNumber} in the new window`}
                            {...dataTestId('ACTION_PLAN_HEADER_NUMBER')}
                        >
                            <span
                                className={classes.issueLink}
                                onClick={handleOpenIssue}
                            >
                                {actionPlanItem.issueNumber}
                                <OpenInNewOutlined
                                    className={classes.openIcon}
                                    fontSize="inherit"
                                />
                            </span>
                        </Tooltip>
                    }
                    onClickLabel={() => openActionItem(actionPlanItem)}
                    renderTag={() => TabsWithStatus}
                    titleProps={dataTestId('ACTION_DASHBOARD_NAME')}
                />
            )}
            renderContent={() => (
                <CardViewResultsContainer
                    className={classes.content}
                    results={content}
                />
            )}
            {...other}
        />
    )
}
