import AttachmentIcon from '@mui/icons-material/Attachment'
import { IconButton, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes, ReactNode } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: theme.spacing(1),
        },
        content: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: theme.spacing(1),
        },
        label: {
            fontWeight: theme.typography.fontWeightMedium as number,
            textTransform: 'none',
        },
        actions: {},
    }),
    { name: 'AttachmentBase' }
)

export interface AttachmentBaseProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    disabled?: boolean
    fileName: string
    renderActions?: (disabled: boolean) => ReactNode
}

export const AttachmentBase = (props: AttachmentBaseProps) => {
    const {
        className,
        fileName,
        disabled = false,
        renderActions,
        ...other
    } = props
    const classes = useStyles()
    return (
        <div className={clsx(className, classes.root)} {...other}>
            <div className={classes.content}>
                <IconButton disabled={disabled} size="small">
                    <AttachmentIcon />
                </IconButton>
                <Typography className={classes.label} variant="button">
                    {fileName}
                </Typography>
            </div>
            {renderActions && (
                <div className={classes.actions}>{renderActions(disabled)}</div>
            )}
        </div>
    )
}

export default AttachmentBase
