import { InfoOutlined } from '@mui/icons-material'
import { Collapse, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { GlobalNotificationVariant } from './types'

export interface GlobalNotificationProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    visible?: boolean
    message?: string
    variant?: GlobalNotificationVariant
    open?: boolean
}

export const useMessageNotificationStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            minHeight: 32,
            padding: theme.spacing(0.5, 2),
            '&$blue': {
                backgroundColor: theme.palette.blue.main,
                color: theme.palette.common.white,
            },
            '&$yellow': {
                backgroundColor: theme.palette.yellow.main,
                color: theme.palette.grey[600],
            },
            '&$gray': {
                backgroundColor: theme.palette.grey[200],
                color: theme.palette.grey[600],
            },
            '& a': {
                color: 'inherit !important',
            },
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        yellow: {},
        blue: {},
        gray: {},
    }),
    { name: 'GlobalNotification' }
)

export const GlobalNotification = (props: GlobalNotificationProps) => {
    const { className, message, visible, variant = 'gray', ...other } = props
    const classes = useMessageNotificationStyles()

    return (
        <Collapse in={Boolean(message) && visible}>
            <div
                className={clsx(className, classes.root, classes[variant])}
                {...other}
            >
                <InfoOutlined fontSize="small" className={classes.icon} />
                <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: message ?? '' }}
                />
            </div>
        </Collapse>
    )
}
