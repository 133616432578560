import { SendMessageInput, SendMessageInputProps } from './SendMessageInput'

export const SubjectTextField = (props: SendMessageInputProps) => {
    return (
        <SendMessageInput
            placeholder="Subject"
            inputProps={{ style: { width: '100%', maxWidth: 'initial' } }}
            {...props}
        />
    )
}
