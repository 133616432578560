import Button, { ButtonProps } from './Button'
import { Link, LinkProps, useHistory } from 'react-router-dom'
import { useIsInternetExplorer } from '../../hooks'

export interface NavButtonProps extends ButtonProps {
    linkProps: LinkProps
}

export const NavButton = (props: NavButtonProps) => {
    const { children, linkProps, ...other } = props
    const { style, ...otherLinkProps } = linkProps
    const isIE = useIsInternetExplorer()
    const history = useHistory()
    const handleOpenUrl = () => {
        if (isIE) {
            if (linkProps.replace) {
                history.replace(linkProps.to as string)
            } else {
                history.push(linkProps.to as string)
            }
        }
    }
    return (
        <Button onClick={handleOpenUrl} {...other}>
            <Link
                style={{
                    ...style,
                    fontSize: 'inherit',
                    textDecoration: 'inherit',
                    color: 'inherit',
                    fontWeight: 'inherit',
                }}
                {...otherLinkProps}
            >
                {children}
            </Link>
        </Button>
    )
}

export default NavButton
