import { ActionPlanContext } from 'ActionPlan/context/ActionPlanContext'
import { useContext } from 'react'
import { IssueStatuses } from 'types/enums'

export const CAPTURE_SECTION_ID = 'capture'
export const CAPTURE_HIGH_IMPACT_ID = 'high-impact'
export const ASSIGNMENT_SECTION_ID = 'assignment'
export const RESOLUTION_SECTION_ID = 'resolution'
export const CLOSING_SECTION_ID = 'closure'
export const EFFECTIVENESS_SECTION_ID = 'effectiveness'

export const checkCharactersLimit = (
    currentLength: number,
    maxLength: number
): boolean => {
    return currentLength > maxLength
}

export const useResolvedActionActive = (
    issueStatusId: IssueStatuses,
    resolvedAction: boolean
): boolean => {
    const { dashboardState } = useContext(ActionPlanContext)

    if (
        [IssueStatuses.In_Process, IssueStatuses.In_Process_Overdue].includes(
            issueStatusId
        )
    ) {
        const actionItemsDraft = Boolean(
            dashboardState.columns.Draft.itemsIds.length
        )
        const actionItemsInProgress = Boolean(
            dashboardState.columns.InProgress.itemsIds.length
        )

        if (!resolvedAction && (actionItemsDraft || actionItemsInProgress))
            return false
    }

    if (resolvedAction) return false

    return true
}
