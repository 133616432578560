import { AccordionActions, PropTypes, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Button, ExpandButton } from 'components'
import { HtmlHTMLAttributes, useContext, useMemo } from 'react'
import { useParams } from 'react-router'
import { ActionItem, ActionItemActions } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'
import { ActionPlanContext } from '../context/ActionPlanContext'

export interface ActionPlanActionsProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    item: ActionItem
    expanded?: boolean
    disabledSave?: boolean
    toggleExpanded?: () => void
}

export interface ActionPlanActionsConfig {
    name: string
    color: PropTypes.Color
    callback: any
    'data-test-id'?: string
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            border: 'none',
            padding: theme.spacing(1, 0),
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(-1),
        },
    }),
    {
        name: 'ActionPlanActions',
    }
)

const ActionPlanActions = (props: ActionPlanActionsProps) => {
    const classes = useStyles()
    const {
        expanded,
        disabledSave = false,
        toggleExpanded,
        item,
        ...other
    } = props
    const { actionPlanId: actionPlanIdParam } = useParams<{
        actionPlanId: string
    }>()

    const {
        saveActionItem,
        assignActionItem,
        deleteActionItem,
        rejectActionItem,
        completeActionItem,
        reopenActionItem,
    } = useContext(ActionPlanContext)

    const actionsConfig: Record<ActionItemActions, ActionPlanActionsConfig> = {
        [ActionItemActions.Save]: {
            name: 'Save',
            color: 'primary',
            callback: saveActionItem,
            ...dataTestId('ACTION_PLAN_SAVE'),
        },
        [ActionItemActions.Assign]: {
            name: 'Assign',
            color: 'secondary',
            callback: assignActionItem,
            ...dataTestId('ACTION_PLAN_ASSIGN'),
        },
        [ActionItemActions.Delete]: {
            name: 'Delete',
            color: 'primary',
            callback: deleteActionItem,
            ...dataTestId('ACTION_PLAN_DELETE'),
        },
        [ActionItemActions.Reject]: {
            name: 'Reject',
            color: 'primary',
            callback: rejectActionItem,
            ...dataTestId('ACTION_PLAN_REJECT'),
        },
        [ActionItemActions.Close]: {
            name: 'Close',
            color: 'secondary',
            callback: completeActionItem,
            ...dataTestId('ACTION_PLAN_CLOSE'),
        },
        [ActionItemActions.Reopen]: {
            name: 'Reopen',
            color: 'secondary',
            callback: reopenActionItem,
            ...dataTestId('ACTION_PLAN_REOPEN'),
        },
    }

    const [canSave, actions] = useMemo<[boolean, string[]]>(() => {
        const results: [boolean, string[]] = [false, []]
        const accessMatrix = item?.accessMatrix

        if (accessMatrix && accessMatrix.length > 0) {
            let actions: Partial<Record<ActionItemActions, unknown>> = {}
            accessMatrix.forEach((x) => (actions = { ...actions, ...x.matrix }))
            const actionsKeys = Object.keys(actions)
            const isIncludesSave = actionsKeys.includes(ActionItemActions.Save)
            results[0] = isIncludesSave || actionPlanIdParam === 'new'

            if (!actionPlanIdParam) {
                results[0] = false
            }
            const parseActions = actionsKeys.filter(
                (item) => item !== ActionItemActions.Save
            )
            parseActions.sort((a, b) => {
                if (
                    actionsConfig[a].color === 'primary' &&
                    actionsConfig[b].color === 'secondary'
                ) {
                    return -1
                }
                if (
                    actionsConfig[b].color === 'primary' &&
                    actionsConfig[a].color === 'secondary'
                ) {
                    return 1
                }
                return 0
            })
            results[1] = parseActions
        }
        return results
    }, [item])

    const saveConfig = actionsConfig[ActionItemActions.Save]

    const parsedActions = actions.map((actionNumber) => {
        const config: ActionPlanActionsConfig = actionsConfig[actionNumber]
        return (
            <Button
                key={config.name}
                color={config.color}
                onClick={() => config.callback(item)}
                // data-test-id={config['data-test-id']}
                {...dataTestId(config['data-test-id'])}
            >
                {config.name}
            </Button>
        )
    })

    return actions.length > 0 || canSave ? (
        <AccordionActions className={classes.root} {...other}>
            <div>
                {toggleExpanded && (
                    <ExpandButton
                        size="small"
                        expanded={expanded}
                        onClick={toggleExpanded}
                        IconProps={{
                            fontSize: 'small',
                        }}
                    />
                )}
                {!disabledSave && canSave && (
                    <Button
                        size="small"
                        onClick={() => saveConfig.callback(item)}
                        key={saveConfig.name}
                        color={saveConfig.color}
                        {...dataTestId('ACTION_PLAN_SAVE_ITEM')}
                    >
                        {saveConfig.name}
                    </Button>
                )}
            </div>

            <div>{parsedActions}</div>
        </AccordionActions>
    ) : null
}

export default ActionPlanActions
