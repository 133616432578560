import axios, { AxiosResponse } from 'axios'
import { MyDashboard, MyDashboard2 } from '../types/myDashboard'

const controllerName = '/my/dashboard'

export const getMyDashboard = (
    page: number = 1,
    pageSize: number = 1000
): Promise<AxiosResponse<MyDashboard>> =>
    axios.post(controllerName, { page, pageSize })

export const getMyDashboard2 = (
    page: number = 1,
    pageSize: number = 1000
): Promise<AxiosResponse<MyDashboard2>> =>
    axios.post(controllerName + 2, { page, pageSize })
