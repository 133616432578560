import { Tab, Tabs, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChangeEvent, useMemo } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { dataTestId } from 'utils'
import CustomerContactList from './List'
import { CustomerContactTabsProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        tabs: {
            backgroundColor: theme.palette.grey[100],
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'CustomerContactTabs' }
)

const CustomerContactTabs = (props: CustomerContactTabsProps) => {
    const {
        activeMode,
        setActiveMode,
        results,
        handleConfirmContactName,
        setInitQuery,
        setOpen,
        ...other
    } = props

    const [activeContacts, inActiveContacts] = useMemo(
        () => [
            results.filter((item) => item.isActive),
            results.filter((item) => !item.isActive),
        ],
        [results]
    )

    const handleActiveMode = (event: ChangeEvent<{}>, index: number) => {
        setActiveMode(index)
    }

    const handleChangeIndex = (index: number) => {
        setActiveMode(index)
    }

    const classes = useStyles()

    const getFieldProps = () => {
        return {
            onSelect: handleConfirmContactName,
            onEdit: (item) => {
                setInitQuery(item)
                setOpen('edit')
            },
        }
    }

    return (
        <>
            <Tabs
                className={classes.tabs}
                value={activeMode}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleActiveMode}
                aria-label="disabled tabs example"
                {...other}
            >
                <Tab
                    label={`Active (${activeContacts.length ?? 0})`}
                    {...dataTestId('CUSTOMER_CONTACT_NAME_ACTIVE_CONTACTS')}
                />
                <Tab
                    label={`Inactive (${inActiveContacts.length ?? 0})`}
                    {...dataTestId('CUSTOMER_CONTACT_NAME_INACTIVE_CONTACTS')}
                />
            </Tabs>
            <SwipeableViews
                key={JSON.stringify(results)}
                enableMouseEvents={false}
                index={activeMode}
                onChangeIndex={handleChangeIndex}
                animateHeight={true}
            >
                <CustomerContactList
                    results={activeContacts}
                    {...getFieldProps()}
                    errorMessage="Customer doesn't have any active contacts"
                />
                <CustomerContactList
                    results={inActiveContacts}
                    {...getFieldProps()}
                    errorMessage="Customer doesn't have any inactive contacts"
                    subTitle=""
                />
            </SwipeableViews>
        </>
    )
}

export default CustomerContactTabs
