import { Close, ErrorOutline } from '@mui/icons-material'
import { CircularProgress, IconButton, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { dataTestId } from 'utils'
import ExpandButton from './ExpandButton'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'inline-flex',
            width: '100%',
            height: 56,
            alignItems: 'center',
            boxSizing: 'border-box',
            padding: theme.spacing(0, 2),
        },
        flexGrow: {
            flexGrow: 1,
        },
        error: {
            ...theme.typography.body2,
            flexGrow: 1,
            marginLeft: theme.spacing(1),
        },
    }),
    { name: 'TileFooter' }
)

export interface TileFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    expanded?: boolean
    loading?: boolean
    error?: string
    onClearError?: () => void
    onExpand?: () => void
}

export const TileFooter: React.FC<TileFooterProps> = (props) => {
    const {
        className,
        expanded = false,
        onExpand,
        loading = false,
        error = null,
        onClearError,
        children,
        ...other
    } = props

    const classes = useStyles()
    return (
        <div className={clsx(className, classes.root)} {...other}>
            {(() => {
                switch (true) {
                    case loading:
                        return <CircularProgress size={20} color="secondary" />
                    case Boolean(error):
                        return (
                            <>
                                <ErrorOutline
                                    fontSize="small"
                                    color="secondary"
                                />
                                <span className={classes.error}>{error}</span>
                                {onClearError && (
                                    <IconButton
                                        size="small"
                                        onClick={onClearError}
                                    >
                                        <Close fontSize="small" />
                                    </IconButton>
                                )}
                            </>
                        )
                    default:
                        return (
                            <>
                                {onExpand && (
                                    <ExpandButton
                                        expanded={expanded}
                                        onClick={() => onExpand()}
                                        size="medium"
                                        edge="start"
                                        IconProps={{ fontSize: 'small' }}
                                        {...dataTestId(`EXPAND_CUTOMERS_ITEM`)}
                                    />
                                )}

                                <span className={classes.error}>{error}</span>
                                {children}
                            </>
                        )
                }
            })()}
        </div>
    )
}

export default TileFooter
