import MuiDialog, { DialogProps } from '@mui/material/Dialog'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        dialogPaper: {
            width: '100%',
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5),
            borderRadius: theme.shape.borderRadius,
        },
    }),
    { name: 'Dialog' }
)

export const Dialog = (props: DialogProps) => {
    const classes = useStyles()
    return (
        <MuiDialog
            {...props}
            PaperProps={{
                square: true,
                className: classes.dialogPaper,
            }}
        />
    )
}
Dialog.muiName = 'Dialog'

export default Dialog
