import { Tab, Tabs, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ActionPlanStatusType } from '../../../types/actionPlanTypes'
import { DashboardMobileNavigationProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            borderTop: `1px solid ${theme.palette.divider}`,
            boxShadow: theme.shadows[2],
            width: '100%',
            position: 'fixed',
            bottom: 0,
            display: 'flex',
        },
        content: {
            margin: '0 auto',
        },
        indicator: {
            top: 0,
        },
    }),
    { name: 'DashboardMobileNavigation' }
)

export const DashboardMobileNavigation = (
    props: DashboardMobileNavigationProps
) => {
    const { columnOrder, columns, navigationValue, setNavigationValue } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Tabs
                className={classes.content}
                classes={{ indicator: classes.indicator }}
                value={navigationValue}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={(event, newValue) => {
                    setNavigationValue(newValue)
                }}
            >
                {columnOrder.map((statusName: ActionPlanStatusType) => {
                    const count: number[] = columns[statusName].itemsIds
                    const label = `${statusName} (${count.length})`
                    return <Tab label={label} key={label} />
                })}
            </Tabs>
        </div>
    )
}

export default DashboardMobileNavigation
