import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    loadIssue,
    saveAssignSectionDone,
    setIssueActionFail,
    ISSUE_SAVE_ASSIGN_CALL,
    updateIssueRowVersion,
} from '..'

import { RootState } from 'store/types'
import { showSnackbar } from 'store/app'
import { IssueActionsTypes } from 'types/enums'
import { saveAssignSection } from 'api'

export function* saveAssignSectionAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { context, assignSection, issueId, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const { data: newAssignSection } = yield call(
            saveAssignSection,
            assignSection,
            changeLog?.assign ?? null,
            cancelSource.token
        )

        yield put(saveAssignSectionDone(newAssignSection))
        yield put(updateIssueRowVersion(newAssignSection.rowVersion))
        yield put(showSnackbar('Issue assignment section was saved'))
        const actions = context?.actions
        if (
            actions &&
            actions.some(
                (a) => a.actionCode === IssueActionsTypes.To_Replace_Inactive_RO
            )
        ) {
            yield put(loadIssue(issueId))
        }
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_SAVE_ASSIGN_CALL, error))
        yield put(showSnackbar("Issue assignment section wasn't saved!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveAssignSectionSaga() {
    yield takeLatest([ISSUE_SAVE_ASSIGN_CALL], saveAssignSectionAction)
}
