import axios, { AxiosResponse } from 'axios'
import { call, cancelled, put, takeLatest, select } from 'redux-saga/effects'
import { getActionPlanForIssue } from 'api'
import { ActionItem } from 'types/actionPlanTypes'
import { showSnackbar } from 'store/app'
import { loadActionPlanDone, loadActionPlanFail } from '../actions'
import { ISSUE_ACTION_PLAN_LOAD_CALL } from '../actionsTypes'
import { LoadIssueActionPlanCommand } from '../types'
import { RootState } from 'store/types'

export function* loadActionPlan(action: LoadIssueActionPlanCommand) {
    const cancelSource = axios.CancelToken.source()
    let issueIdOrNumber = action?.payload?.issueIdOrNumber
    if (!issueIdOrNumber) {
        issueIdOrNumber = (yield select(
            (s: RootState) => s.issue.issueId
        )) as number
    }
    try {
        const { data: actionItems } = (yield call(
            getActionPlanForIssue,
            issueIdOrNumber,
            cancelSource.token
        )) as AxiosResponse<ActionItem[]>
        yield put(loadActionPlanDone(actionItems))
    } catch (error) {
        yield put(showSnackbar('Cannot load action plan', true))
        yield put(loadActionPlanFail())
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* loadActionPlanSaga() {
    yield takeLatest([ISSUE_ACTION_PLAN_LOAD_CALL], loadActionPlan)
}
