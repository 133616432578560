import { Switch as MuiSwitch, SwitchProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { forwardRef } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            width: 42,
            height: 26,
            padding: 0,
            margin: theme.spacing(1),
        },
        switchBase: {
            padding: 1,
            backgroundColor: theme.palette.grey[400],
            '&$checked': {
                transform: 'translateX(16px)',
                color: theme.palette.common.white,
                backgroundColor: theme.palette.divider,
                '& + $track': {
                    backgroundColor: theme.palette.secondary,
                    opacity: 1,
                    border: 'none',
                },
            },
            '&$focusVisible $thumb': {
                color: theme.palette.secondary,
                border: '6px solid #fff',
            },
        },
        thumb: {
            width: 24,
            height: 24,
            boxShadow: theme.shadows[2],
        },
        track: {
            borderRadius: 26 / 2,
            border: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: theme.palette.grey[100],
            opacity: 1,
            padding: 1,
            boxSizing: 'border-box',
            transition: theme.transitions.create([
                'background-color',
                'border',
            ]),
        },
        colorSecondary: {
            '&$disabled + $track': {
                backgroundColor: theme.palette.grey[300],
            },
        },
        checked: {},
        disabled: {},
        focusVisible: {},
    }),
    { name: 'Switch' }
)

export const Switch = forwardRef<HTMLButtonElement, SwitchProps>(
    (props, ref) => {
        const classes = useStyles(props)
        return (
            <MuiSwitch
                ref={ref}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked,
                    disabled: classes.disabled,
                    colorSecondary: classes.colorSecondary,
                }}
                {...props}
            />
        )
    }
)

export default Switch
