import { Chip, ChipProps, CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'

export interface MailAttachment {
    guid: string
    name: string
    uploaded?: boolean
    file: File
    onUploaded?: () => void
}

export interface MessageAttachmentProps
    extends Omit<ChipProps, 'size' | 'variant'> {
    mailAttachment: MailAttachment
}

export const MessageAttachment = (props: MessageAttachmentProps) => {
    const { mailAttachment, ...other } = props
    const [uploading /*setUploading*/] = useState(false)
    useEffect(() => {
        if (mailAttachment?.file) {
        }
    }, [mailAttachment?.file])
    return (
        <Chip
            label={mailAttachment.name}
            variant="outlined"
            disabled={uploading}
            deleteIcon={
                uploading ? (
                    <CircularProgress size={16} color="secondary" />
                ) : null
            }
            size="small"
            {...other}
        />
    )
}
