import { DependedFields } from '.'
import { ActionsKeys, IssueRoles } from 'types/enums'
import { AccessMatrixItem, AccessMatrixSetting } from 'types/matrixTypes'

export const mergeMatrixForRoles = (
    selectedRolesCodes: Array<IssueRoles>,
    accessMatrixes: Array<AccessMatrixItem>
): {
    currentActions: Array<ActionsKeys>
    dependedFields: DependedFields
} => {
    if (!accessMatrixes && accessMatrixes.length === 0) {
        return { currentActions: [], dependedFields: {} }
    }
    const currentMatrixes = accessMatrixes.filter((x) =>
        selectedRolesCodes?.some((c) => c === x.roleId)
    )
    const currentActions = new Set<ActionsKeys>()
    let dependedFields: DependedFields = {}
    currentMatrixes.forEach((m) => {
        Object.getOwnPropertyNames(m.matrix).forEach((a) =>
            currentActions.add(a as ActionsKeys)
        )
        for (const key in m.matrix) {
            if (m.matrix.hasOwnProperty(key)) {
                const item = m.matrix[key]
                item.forEach((i: AccessMatrixSetting) => {
                    let currentField = dependedFields[
                        i.key
                    ] as AccessMatrixSetting
                    if (currentField) {
                        if (
                            !currentField.dependentOn?.length &&
                            i.dependentOn?.length
                        ) {
                            currentField.dependentOn = i.dependentOn
                        }
                        if (i.isRequired) {
                            currentField.isRequired = true
                        }
                        if (!i.isDisabled) {
                            currentField.isDisabled = false
                        }
                    } else {
                        dependedFields[i.key] = {
                            ...i,
                            dependentOn: i?.dependentOn
                                ? [...i.dependentOn]
                                : null,
                        }
                    }
                })
            }
        }
    })
    return { currentActions: [...currentActions], dependedFields }
}
