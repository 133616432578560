import { MenuItem, MenuList, Typography } from '@mui/material'
import { Variant } from '@mui/material/styles/createTypography'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { EditorState } from 'draft-js'
import { AbbTheme } from 'styles/createAbbTheme'

export interface TypographyPalleteProps {}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            maxHeight: 300,
        },
        cursor: {
            '&::before': {
                display: 'block',
                content: '"—"',
                fontSize: 'inherit',
                color: theme.palette.secondary.main,
            },
        },
    }),
    {
        name: 'TypographyPallete',
    }
)

const getTypographyPalleteConfig = () =>
    [
        {
            name: 'Headline 1',
            variant: 'h1',
            blockType: 'header-one',
            addCursor: true,
        },
        {
            name: 'Headline 2',
            variant: 'h2',
            blockType: 'header-two',
            addCursor: true,
        },
        {
            name: 'Headline 3',
            variant: 'h3',
            blockType: 'header-three',
            addCursor: true,
        },
        {
            name: 'Headline 4',
            variant: 'h4',
            blockType: 'header-four',
            addCursor: true,
        },
        { name: 'Headline 5', variant: 'h5', blockType: 'header-five' },
        { name: 'Headline 6', variant: 'h6', blockType: 'header-six' },
        { name: 'Content', variant: 'body2', blockType: 'unstyled' },
        { name: 'Caption', variant: 'caption', blockType: 'atomic' },
    ] as {
        name: string
        variant: Variant
        blockType: string
        addCursor?: boolean
    }[]

export interface TypographyPalleteProps {
    onTypographySelect: (blockType: string) => void
    editorState: EditorState
}

export const TypographyPallete = (props: TypographyPalleteProps) => {
    const { onTypographySelect, editorState } = props
    const selection = editorState.getSelection()
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType()
    const classes = useStyles()
    return (
        <MenuList className={classes.root}>
            {getTypographyPalleteConfig().map((x) => (
                <MenuItem
                    key={x.name}
                    onClick={() => onTypographySelect(x.blockType)}
                    onMouseDown={(e) => e.preventDefault()}
                    selected={blockType === x.blockType}
                >
                    <Typography
                        className={clsx(x.addCursor && classes.cursor)}
                        variant={x.variant}
                    >
                        {x.name}
                    </Typography>
                </MenuItem>
            ))}
        </MenuList>
    )
}
