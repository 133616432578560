import { AccordionActionsProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AccordionActions, Button } from 'components'
import { FC } from 'react'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            '&$spacing': {
                marginTop: theme.spacing(2),
            },
        },
        spacing: {},
    }),
    { name: 'AdminSearchPanelActions' }
)

export interface AdminSearchPanelActionsProps extends AccordionActionsProps {
    spacing?: boolean
    isSearching: boolean
    disabledClear?: boolean
    disabledSearch?: boolean
    resetForm: () => void
    handleSearch: () => void
}

export const AdminSearchPanelActions: FC<AdminSearchPanelActionsProps> = (
    props
) => {
    const {
        className,
        spacing,
        isSearching,
        disabledClear = false,
        disabledSearch = false,
        resetForm,
        handleSearch,
        children,
        ...other
    } = props
    const classes = useStyles()
    return (
        <AccordionActions
            className={clsx(
                className,
                classes.root,
                spacing && classes.spacing
            )}
            {...other}
        >
            <Button
                onClick={resetForm}
                disabled={disabledClear || isSearching}
                {...dataTestId('ADMIN_PANEL_CLEAR')}
            >
                Clear
            </Button>
            <Button
                onClick={handleSearch}
                color="secondary"
                disabled={disabledSearch || isSearching}
                {...dataTestId('ADMIN_PANEL_SEARCH')}
            >
                Search
            </Button>
            {children}
        </AccordionActions>
    )
}
