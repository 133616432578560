import { Public } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { getShortOptionLabel } from 'components'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { AutoCompleteInput } from './components'

export interface RegionSelectProps extends Partial<BaseTextFieldProps> {
    selectedRegion: FilterBase
    onRegionChanged: (item: FilterBase) => void
}

export const RegionSelect = (props: RegionSelectProps) => {
    const options = useSelector((state: RootState) => state.resources.regions)
    const {
        label = 'Region',
        disabled,
        selectedRegion,
        onRegionChanged,
        ...other
    } = props

    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option.code === value.code
    }

    const handleOnChange = (e: ChangeEvent<any>, value: string | FilterBase) =>
        onRegionChanged && onRegionChanged(value as FilterBase)

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedRegion ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Regions'}
            disableClearable={false}
            onChange={handleOnChange}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label={label}
                    renderIcon={(props) => <Public {...props} />}
                    {...other}
                />
            )}
        />
    )
}

export default RegionSelect
