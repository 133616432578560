import { AppStoreNotificationErrorType } from '.'
import * as ACTIONS from './actionTypes'

enum NotificationType {
    Success = 0,
    Error = 1,
    Information = 2,
    Warning = 3,
}

export const showSnackbar = (
    message: string,
    type: AppStoreNotificationErrorType = false
) => {
    const typeMapped = Number(type) as NotificationType

    return {
        type: ACTIONS.SHOW_SNACKBAR,
        payload: {
            message: message,
            error: typeMapped,
        },
    }
}

export const dismissSnackbar = (key: string) => ({
    type: ACTIONS.DISMISS_SNACKBAR,
    payload: {
        key,
    },
})

export const removeSnackbar = (key: string) => ({
    type: ACTIONS.REMOVE_SNACKBAR,
    payload: {
        key,
    },
})

export const removeSnackbars = (key: string) => ({
    type: ACTIONS.REMOVE_SNACKBARS,
    payload: {
        key,
    },
})
