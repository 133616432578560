export const ISSUE_ROOT = '/issue'

export const ISSUE_NEW = ISSUE_ROOT + '/new'
export const ISSUE_NEW_CUSTOMER_NAME = ISSUE_NEW + '/customer-name'
export const ISSUE_NEW_CUSTOMER_CONTACT_NAME =
    ISSUE_NEW + '/customer-contact-name'
export const ISSUE_NEW_RESOLVING_UNIT = ISSUE_NEW + '/resolving-unit'
export const ISSUE_NEW_ABB_PRODUCT = ISSUE_NEW + '/abb-product'
export const ISSUE_NEW_FAILURE_MODE = ISSUE_NEW + '/failure-mode'
export const ISSUE_NEW_PRODUCT_LINE = ISSUE_NEW + '/product-line'
export const ISSUE_NEW_SUPPLIER = ISSUE_NEW + '/supplier'

export const ISSUE_VIEW = ISSUE_ROOT + '/:id'
export const ISSUE_VIEW_CUSTOMER_NAME = ISSUE_VIEW + '/customer-name'
export const ISSUE_VIEW_CUSTOMER_CONTACT_NAME =
    ISSUE_VIEW + '/customer-contact-name'
export const ISSUE_VIEW_RESOLVING_UNIT = ISSUE_VIEW + '/resolving-unit'
export const ISSUE_VIEW_ABB_PRODUCT = ISSUE_VIEW + '/abb-product'
export const ISSUE_VIEW_FAILURE_MODE = ISSUE_VIEW + '/failure-mode'
export const ISSUE_VIEW_PRODUCT_LINE = ISSUE_VIEW + '/product-line'
export const ISSUE_VIEW_SUPPLIER = ISSUE_VIEW + '/supplier'
export const ISSUE_VIEW_PG_CATEGORY = ISSUE_VIEW + '/pg-category'
export const ISSUE_VIEW_ACTION_LOGS = ISSUE_VIEW + '/action-logs'

export const ISSUE_LINK_ISSUE = ISSUE_VIEW + '/link'
export const ISSUE_LINK_ISSUE_PREVIEW = ISSUE_LINK_ISSUE + '/:previewId'

export const ISSUE_ACTION_PLAN_DASHBOARD = ISSUE_VIEW + '/action-plan'
export const ISSUE_ACTION_PLAN_ITEM = ISSUE_VIEW + '/action-plan/:actionPlanId'
export const ISSUE_ACTION_PLAN_ITEM_NEW = ISSUE_VIEW + '/action-plan/new'
