import { AlternateEmail, Phone } from '@mui/icons-material'
import { Button, CardView, ChipLabel } from 'components'

import { makeStyles } from '@mui/styles'
import { useMemo } from 'react'
import { dataTestId } from 'utils'
import { CustomerContactItemProps } from './types'

const useStyles = makeStyles(
    () => ({
        actions: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
        },
    }),
    {
        name: 'CustomerContactItem',
    }
)

const CustomerContactItem = (props: CustomerContactItemProps) => {
    const classes = useStyles()
    const { item, onSelect, onEdit } = props
    const { firstName, lastName, email, phone, source } = item
    const title = `${firstName} ${lastName}`

    const results = useMemo(
        () => [
            {
                label: 'Email Address',
                renderIcon: (props) => <AlternateEmail {...props} />,
                text: email,
                ...dataTestId('CUSTOMER_CONTACT_PICKER_ITEM_EMAIL'),
            },
            {
                label: 'Telephone number',
                renderIcon: (props) => <Phone {...props} />,
                text: phone,
                ...dataTestId('CUSTOMER_CONTACT_PICKER_ITEM_PHONE'),
            },
        ],
        [email, phone]
    )

    const renderActions = () => (
        <div className={classes.actions}>
            {source !== 'SFDC' && (
                <Button
                    onClick={() => onEdit({ ...item })}
                    color="primary"
                    {...dataTestId('CUSTOMER_CONTACT_PICKER_EDIT')}
                >
                    Edit
                </Button>
            )}
            <div style={{ flexGrow: 1 }}></div>
            <Button
                onClick={() => onSelect({ ...item })}
                color="secondary"
                {...dataTestId('CUSTOMER_CONTACT_PICKER_SELECT')}
            >
                Select
            </Button>
        </div>
    )

    return (
        <CardView
            results={results}
            title={title}
            renderTag={() => (
                <ChipLabel
                    label={source}
                    variant={source === 'SFDC' ? 'blue' : 'grey'}
                />
            )}
            renderActions={renderActions}
        ></CardView>
    )
}

export default CustomerContactItem
