import ExpandIcon from '@mui/icons-material/ExpandMore'
import FiberManualRecord from '@mui/icons-material/FiberManualRecord'
import Rating from '@mui/lab/Rating'
import { useTheme } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import DialogContentText from '@mui/material/DialogContentText'
import Typography from '@mui/material/Typography'
import { withStyles } from '@mui/styles'
import dayjs from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addAttachment, deleteAttachment } from 'store/issue/actions'
import {
    completeIssue,
    rejectResolutionToRO,
    saveClosingSection,
} from 'store/issue/index'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    ActionsKeys,
    IssueActionsTypes,
    IssueStatuses,
    IssueTypes,
    IssueValuesKeys,
} from 'types/enums'
import {
    Attachment,
    CaptureSection,
    ClosingSection,
    IssueContext,
} from 'types/issueTypes'
import { ChipLabel, KeyboardDatePicker } from '../../components'
import Accordion from '../../components/Base/Accordion'
import AccordionActions from '../../components/Base/AccordionActions'
import AccordionDetails from '../../components/Base/AccordionDetails'
import AccordionSummary from '../../components/Base/AccordionSummary'
import Button from '../../components/Base/Button'
import FlexGrow from '../../components/Base/FlexGrow'
import FormControlLabel from '../../components/Base/FormControlLabel'
import TextField from '../../components/Base/TextField'
import ValueView from '../../components/Base/ValueView'
import DialogBase from '../../components/Dialogs/DialogBase'
import ReturnToResolutionOwnerDialog from '../../components/Dialogs/ReturnToResolutionOwnerDialog'
import FileManager from '../../components/Files/FileManager'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { RejectResolutionQuery } from '../../types/actions'
import { useFieldMatrix, useFieldWithError, useIssueAction } from '../matrix'
import ClosureConfirmationStatusContainer from './ClosingSection/ClosureConfirmationStatusContainer'
import useIssueFormStyles from './useIssueFormStyles'
import { CLOSING_SECTION_ID } from './utils'

const MAX_RATE = 5

interface ClosingState {
    customerComments: string
    expectedExecutionDate?: string
}

const initialState: ClosingState = {
    customerComments: '',
    expectedExecutionDate: null,
}

export interface ClosingProps
    extends Partial<{
        expanded: boolean
        issueNumber: string
        issueType: IssueTypes
        issueStatusId: IssueStatuses
        onExpand: (expanded: boolean) => void
        actionRequied: boolean
        closingSection: ClosingSection
        captureSection: CaptureSection
        saving?: boolean
        context: IssueContext
        attachments: Array<Attachment>
        closingChange?: (key: keyof ClosingSection, value: any) => void
        [key: string]: any
    }> {}

const Closing = (props: ClosingProps) => {
    const classes = useIssueFormStyles()
    const dispatch = useDispatch()
    const {
        expanded,
        issueNumber,
        issueType,
        issueStatusId,
        onExpand,
        closingSection,
        captureSection,
        saving,
        context,
        disabled,
        attachments = [],
        closingChange,
    } = props
    const theme = useTheme()
    const [
        errorReasonNotToSendCustomerSurvey,
        setErrorReasonNotToSendCustomerSurvey,
    ] = useState<string | null>(null)
    const saveClosingSectionAction = useIssueAction(
        ActionsKeys.SaveCompletionSection
    )
    const returnToResolutionOwnerAction = useIssueAction(
        ActionsKeys.ReturnToResolutionOwner
    )
    const completedAction = useIssueAction(ActionsKeys.Completed)
    const completedExecutionPendingAction = useIssueAction(
        ActionsKeys.CompletedExecutionPending
    )
    const isWaitingForExecutionCheckbox = useFieldMatrix(
        IssueValuesKeys.IsWaitingForExecution,
        {
            disabled,
        }
    )
    const customerCommentsField = useFieldWithError(
        IssueValuesKeys.CustomerComments,
        { disabled }
    )

    const reasonNotToSendCustomerSurveyField = useFieldWithError(
        IssueValuesKeys.ReasonNotToSendCustomerSurvey,
        { disabled }
    )
    const expectedExecutionDateField = useFieldWithError(
        IssueValuesKeys.ExpectedExecutionDate,
        { disabled }
    )
    const closedAttachmentsField = useFieldWithError(
        IssueValuesKeys.ClosedAttachments,
        { disabled }
    )
    const [state, setState] = useState<ClosingState>({ ...initialState })
    const [returnToRoOpen, setReturnToRo] = useState<boolean>(false)
    const [surveyDialogOpen, setSurveyDialogOpen] = useState<boolean>(false)

    const displaySubscriptionStatus =
        issueType === IssueTypes.External &&
        [
            IssueStatuses.Completed_Execution_Pending,
            IssueStatuses.Resolved,
        ].includes(issueStatusId)

    const displayClosureConfirmationStatus =
        issueType === IssueTypes.External &&
        issueStatusId === IssueStatuses.Completed

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            customerComments: closingSection?.customerComments,
            expectedExecutionDate:
                closingSection?.expectedExecutionDate ||
                dayjs().utc().toISOString(),
        }))
    }, [closingSection])

    const handleReturnToRo = (query: RejectResolutionQuery) => {
        setReturnToRo(false)
        dispatch(rejectResolutionToRO(query))
    }
    const handleCompleteIssue = () => {
        if (!closingSection.isWaitingForExecution) {
            // Update before complete
            closingSection.expectedExecutionDate = null
        }
        dispatch(completeIssue())
    }

    if (!closingSection) {
        return null
    }
    const closureConfirmation = closingSection?.closureConfirmation ?? false
    const isWaitingForExecution =
        closingSection?.isWaitingForExecution &&
        Array.isArray(context?.actions) &&
        !context.actions.some(
            (a) =>
                a.actionCode ===
                IssueActionsTypes.To_Complete_After_Customer_Execution
        )
    const completeLabel = isWaitingForExecution
        ? 'Completed (Execution Pending)'
        : 'Complete'
    const handleClosingChange = (key: keyof ClosingSection, value: any) =>
        closingChange && closingChange(key, value)

    const handleIsWaitingForExecutionChnage = () => {
        handleClosingChange(
            'isWaitingForExecution',
            !closingSection?.isWaitingForExecution
        )
    }
    const handleIsSendCustomerSurveyChange = () => {
        handleClosingChange(
            'isSendCustomerSurvey',
            closingSection?.isSendCustomerSurvey
                ? !closingSection?.isSendCustomerSurvey
                : true
        )
    }
    const handleReasonNotSendCustomerSurveyChange = (value: string) => {
        handleClosingChange('reasonNotToSendCustomerSurvey', value)
    }

    const minDate = dayjs().utc().endOf('day')

    const handleConfirmComplete = () => {
        if (isWaitingForExecution) {
            handleClosingChange('isSendCustomerSurvey', true)
            handleCompleteIssue()
            return
        }
        if (
            !closingSection?.reasonNotToSendCustomerSurvey &&
            !closingSection?.isSendCustomerSurvey
        ) {
            return setErrorReasonNotToSendCustomerSurvey(
                "Field 'Why do you not inform customer?' is required"
            )
        }
        setErrorReasonNotToSendCustomerSurvey(null)
        handleCompleteIssue()
        setSurveyDialogOpen(false)
    }

    const handleSetSurveyDialogOpen = (open: boolean) => {
        setSurveyDialogOpen(open)
        setErrorReasonNotToSendCustomerSurvey(null)
        handleClosingChange('isSendCustomerSurvey', true)
    }

    return closingSection ? (
        <Accordion
            id={CLOSING_SECTION_ID}
            className={classes.section}
            expanded={expanded}
            onChange={(e, expanded) => onExpand && onExpand(expanded)}
        >
            <AccordionSummary
                {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.EXPANSION_PANEL}
                expandIcon={<ExpandIcon />}
            >
                <Typography variant="h5">Issue closure</Typography>
            </AccordionSummary>
            <AccordionDetails
                {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.DETAILS_CONTAINER}
            >
                {issueType !== IssueTypes.Internal_NC && (
                    <FormControlLabel
                        style={{ marginBottom: theme.spacing(2) }}
                        label="The solution is waiting for execution by the Customer"
                        {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                            .WAITING_FOR_CUSTOMER_EXECUTION_CHECKBOX}
                        control={
                            <Checkbox
                                disabled={
                                    isWaitingForExecutionCheckbox.disabled ||
                                    context?.actions?.some(
                                        (a) =>
                                            a.actionCode ===
                                            IssueActionsTypes.To_Complete_After_Customer_Execution
                                    )
                                }
                                checked={closingSection.isWaitingForExecution}
                                onChange={(e, checked) => {
                                    handleIsWaitingForExecutionChnage()
                                    !checked &&
                                        handleClosingChange(
                                            'expectedExecutionDate',
                                            null
                                        )
                                }}
                            />
                        }
                        className={classes.solutionCheckbox}
                    />
                )}
                <Collapse
                    in={closingSection.isWaitingForExecution ?? true}
                    {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                        .EXPECTED_EXECUTION_DATE_CONTAINER}
                >
                    <KeyboardDatePicker
                        className={classes.expectedExecutionDate}
                        disablePast={true}
                        minDate={minDate}
                        label="Expected Execution Date"
                        {...expectedExecutionDateField}
                        value={
                            closingSection.expectedExecutionDate
                                ? dayjs(
                                      closingSection.expectedExecutionDate
                                  ).utc()
                                : null
                        }
                        onChange={(date) => {
                            handleClosingChange(
                                'expectedExecutionDate',
                                date.endOf('day')
                            )
                        }}
                        slotProps={{
                            textField: {
                                ...expectedExecutionDateField,
                                ...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                    .EXPECTED_EXECUTION_DATE_PICKER,
                            },
                        }}
                    />
                </Collapse>
                <TextField
                    {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.CUSTOMER_COMMENTS_INPUT}
                    {...customerCommentsField}
                    label="Closure comments"
                    value={state.customerComments}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                        setState((prev) => ({
                            ...prev,
                            customerComments: e.target.value,
                        }))
                    }}
                    onBlur={() =>
                        closingChange &&
                        closingChange(
                            'customerComments',
                            state.customerComments
                        )
                    }
                    fullWidth
                    multiline
                    rows={3}
                />
                {(displaySubscriptionStatus ||
                    closureConfirmation ||
                    displayClosureConfirmationStatus) && (
                    <>
                        <div className={classes.subHeadline}>
                            Customer Feedback
                        </div>

                        {displayClosureConfirmationStatus && (
                            <ClosureConfirmationStatusContainer
                                closingSection={closingSection}
                            />
                        )}
                        {displaySubscriptionStatus && (
                            <div className={classes.row}>
                                <ValueView
                                    label="Customer Contact subscription status"
                                    className={classes.col}
                                    value={
                                        closingSection.isCurrentUserSubscribedForSurveys ? (
                                            <ChipLabel
                                                label="Active"
                                                variant="green"
                                            />
                                        ) : (
                                            <ChipLabel
                                                label="Unsubscribed"
                                                variant="red"
                                            />
                                        )
                                    }
                                />
                            </div>
                        )}

                        {closureConfirmation && (
                            <>
                                <div className={classes.row}>
                                    <ValueView
                                        {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                            .EFFORT_SCORE}
                                        label="Effort score"
                                        className={classes.col}
                                        value={
                                            <StyledRating
                                                value={
                                                    closureConfirmation.effortScore
                                                }
                                                max={MAX_RATE}
                                                icon={<FiberManualRecord />}
                                                readOnly
                                            />
                                        }
                                    />
                                    <ValueView
                                        {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                            .SATISFACTION_SCORE}
                                        label="Satisfaction score"
                                        className={classes.col}
                                        value={
                                            <StyledRating
                                                value={
                                                    closureConfirmation.satisfactionScore
                                                }
                                                max={MAX_RATE}
                                                icon={<FiberManualRecord />}
                                                readOnly
                                            />
                                        }
                                    />
                                </div>
                                <TextField
                                    {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                        .FEEDBACK_INPUT}
                                    disabled
                                    label="Feedback"
                                    value={closureConfirmation.comments}
                                    fullWidth
                                    multiline
                                />
                            </>
                        )}
                    </>
                )}

                <FileManager
                    label="Attachments (max file size is 100 MB)"
                    issueId={closingSection.issueId}
                    attachmentType={7}
                    attachments={attachments}
                    {...closedAttachmentsField}
                    onFileUploaded={(attachment) =>
                        dispatch(addAttachment(attachment))
                    }
                    onFileRemove={(attachment) =>
                        dispatch(deleteAttachment(attachment))
                    }
                />
            </AccordionDetails>
            {disabled ? null : (
                <AccordionActions style={{ marginTop: theme.spacing(3) }}>
                    <ReturnToResolutionOwnerDialog
                        open={returnToRoOpen}
                        message={`You are about to reject resolution for issue ${issueNumber}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                        minHeight={220}
                        rowVersion={closingSection.rowVersion}
                        number={issueNumber}
                        issueId={closingSection.issueId}
                        onReturnToRO={handleReturnToRo}
                        onClose={() => setReturnToRo(false)}
                    />
                    <DialogBase
                        title="Confirm action"
                        message={`You are closing this issue having informed the customer of the solution. Then the customer will receive a confirmation and a request for feedback. Please ensure that their data is filled in correctly.`}
                        confirmLabel="Confirm"
                        closeLabel="Cancel"
                        onConfirm={handleConfirmComplete}
                        onClose={() => {
                            setSurveyDialogOpen(false)
                            handleClosingChange('isSendCustomerSurvey', true)
                            handleReasonNotSendCustomerSurveyChange(null)
                            setErrorReasonNotToSendCustomerSurvey(null)
                        }}
                        open={surveyDialogOpen}
                        disabledConfirm={
                            !closingSection?.isSendCustomerSurvey &&
                            Boolean(
                                !closingSection?.reasonNotToSendCustomerSurvey
                            )
                        }
                    >
                        <DialogContentText
                            style={{
                                marginTop: theme.spacing(-1),
                            }}
                        >
                            <FormControlLabel
                                style={{
                                    marginBottom: theme.spacing(-1),
                                }}
                                label="Send to customer confirmation and request feedback"
                                {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                    .SURVEY_CHECKBOX}
                                control={
                                    <Checkbox
                                        checked={
                                            closingSection?.isSendCustomerSurvey
                                        }
                                        onChange={() =>
                                            handleIsSendCustomerSurveyChange()
                                        }
                                    />
                                }
                                className={classes.solutionCheckbox}
                            />

                            <Collapse
                                in={!closingSection?.isSendCustomerSurvey}
                            >
                                <TextField
                                    {...ISSUE_VIEW_PAGE.ISSUE_CLOSED
                                        .SURVEY_WHY_NOT_INFORMED_FIELD}
                                    {...reasonNotToSendCustomerSurveyField}
                                    error={Boolean(
                                        errorReasonNotToSendCustomerSurvey
                                    )}
                                    helperText={
                                        errorReasonNotToSendCustomerSurvey
                                    }
                                    label="Why do you not inform customer?"
                                    value={
                                        closingSection?.reasonNotToSendCustomerSurvey
                                    }
                                    onChange={(
                                        e: ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                        handleReasonNotSendCustomerSurveyChange(
                                            e.currentTarget.value
                                        )
                                    }}
                                    fullWidth
                                    multiline
                                    rows={3}
                                    style={{
                                        marginBottom: 0,
                                        marginTop: theme.spacing(3),
                                    }}
                                />
                            </Collapse>
                        </DialogContentText>
                        <Typography
                            variant="body2"
                            style={{
                                color: theme.palette.primary.main,
                            }}
                        >
                            Please, confirm that are you sure to perform this
                            action.
                        </Typography>
                    </DialogBase>
                    {saving ? (
                        <CircularProgress
                            color="secondary"
                            size={24}
                            style={{ marginLeft: 8 }}
                        />
                    ) : saveClosingSectionAction.disabled ? null : (
                        <Button
                            onClick={() => {
                                if (!closingSection.isWaitingForExecution)
                                    closingChange &&
                                        closingChange(
                                            'expectedExecutionDate',
                                            null
                                        )
                                dispatch(saveClosingSection())
                            }}
                            {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.ACTION_BUTTONS
                                .SAVE}
                        >
                            Save
                        </Button>
                    )}
                    <FlexGrow />
                    {returnToResolutionOwnerAction.disabled ? null : (
                        <Button
                            onClick={() => setReturnToRo(true)}
                            eventName="Return to Resolution Owner"
                            {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.ACTION_BUTTONS
                                .RETURN_TO_RESOLUTION_OWNER}
                        >
                            Return to Resolution Owner
                        </Button>
                    )}
                    {(!completedAction.disabled ||
                        !completedExecutionPendingAction.disabled) &&
                    completeLabel ? (
                        <Button
                            {...ISSUE_VIEW_PAGE.ISSUE_CLOSED.ACTION_BUTTONS
                                .COMPLETE}
                            color="secondary"
                            eventName={completeLabel}
                            onClick={() =>
                                captureSection.issueType ===
                                    IssueTypes.External &&
                                !isWaitingForExecution
                                    ? handleSetSurveyDialogOpen(true)
                                    : handleConfirmComplete()
                            }
                        >
                            {completeLabel}
                        </Button>
                    ) : null}
                </AccordionActions>
            )}
        </Accordion>
    ) : null
}

const StyledRating = withStyles((theme: AbbTheme) => ({
    root: {
        width: '100%',
    },
    iconFilled: {
        color: theme.palette.grey[600],
    },
}))(Rating)

Closing.defaultProps = {
    expanded: false,
    actionRequied: false,
    disabled: false,
}

export default Closing
