import axios, { AxiosResponse } from 'axios'
import {
    call,
    cancelled,
    takeLatest,
    put,
    select,
    all,
} from 'redux-saga/effects'
import {
    ISSUE_REMOVE_RCA_CALL,
    loadAttachmentsDone,
    removeRcaDone,
    setIssueActionFail,
    saveResolutionSectionDone,
    updateIssueRowVersion,
    IssueStoreState,
} from '..'

import { Attachment, ResolutionSection } from 'types/issueTypes'
import { RootState } from 'store/types'
import { showSnackbar } from 'store/app'
import { copyRca, getAttachments, getResolutionSection } from 'api'

export function* removeRcaAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { assignSection, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const issueId = assignSection.issueId
        assignSection.quickFixIssueId = null
        assignSection.quickFixIssueNumber = null
        const { data: newAssignSection } = yield call(
            copyRca,
            issueId,
            {
                ...assignSection,
            },
            changeLog?.assign ?? null,
            cancelSource.token
        )
        const res: {
            newResolutionSection: AxiosResponse<ResolutionSection>
            attachments: AxiosResponse<Array<Attachment>>
        } = yield all({
            newResolutionSection: yield call(getResolutionSection, issueId),
            attachments: yield call(getAttachments, issueId),
        })
        yield put(saveResolutionSectionDone(res.newResolutionSection.data))
        yield put(loadAttachmentsDone(res.attachments.data))
        yield put(updateIssueRowVersion(newAssignSection.rowVersion))
        yield put(removeRcaDone(newAssignSection))
        yield put(showSnackbar('Linked RCA was removed'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_REMOVE_RCA_CALL, error))
        yield put(showSnackbar('Linked RCA fail', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* removeRcaSaga() {
    yield takeLatest([ISSUE_REMOVE_RCA_CALL], removeRcaAction)
}
