import axios, { AxiosResponse, CancelToken } from 'axios'
import { SearchRcaQuery } from '../types/queries'
import { IssueRcaPreview, AssignSection } from 'types/issueTypes'
import { IssueChangeLog } from '../store/issue'

const controllerName = '/issue'

/**
 * @param {number} issueId - id of issue
 * @param query - object with url params
 * @param {number} query.ruId - resolving unit id
 * @param {string} query.issueNo - resolving unit number
 * @param {string} query.specificIssueNumber - issue number
 * @param {string} query.businessCode - business code
 * @param {string} query.businessLineCode - division code
 * @param {string} query.productGroupCode - product group code
 * @param {string} query.countryCode - country code
 * @param {string} query.cityCode - city code
 * @param {string} query.activityCode - activity code
 * @param {number} query.page - start index of collection
 * @param {number} query.pageSize - number of elements return during single request
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const searchRca = (
    issueId: number,
    query: SearchRcaQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<IssueRcaPreview>>> =>
    axios.post(
        controllerName + `/${issueId}/assignment/rca/search`,
        { ...query },
        { cancelToken }
    )

/**
 * @param {number} issueId - id issue where RCA is copied
 * @param {number} assignSection - assign section with RCA linked issue
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of PUT request
 */
export const copyRca = (
    issueId: number,
    assignSection: AssignSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AssignSection>> =>
    axios.put(
        controllerName + `/${issueId}/assignment/rca`,
        { ...assignSection, changeLog },
        {
            cancelToken,
        }
    )
