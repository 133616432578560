import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

export const useDataAreaStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',

            '@media (max-width: 480px)': {
                flexDirection: 'column',
            },
        },
        singleRow: {
            marginTop: theme.spacing(1.5),
            width: '100%',
        },
        cell: {
            display: 'flex',
            width: `calc(50% - ${theme.spacing(1.5)})`,
            marginTop: theme.spacing(1.5),
            '@media (max-width: 600px)': {
                padding: 0,
                width: '100%',
            },
        },
    }),
    { name: 'DataArea' }
)

export const DataArea: FC<HtmlHTMLAttributes<HTMLDivElement>> = (props) => {
    const { className: classNameProp, children, ...other } = props
    const classes = useDataAreaStyles(props)
    return (
        <div className={clsx(classNameProp, classes.root)} {...other}>
            {children}
        </div>
    )
}

export default DataArea
