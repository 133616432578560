import { Collapse, FormControlLabel } from '@mui/material'
import { AdminDialogDesktop, AdminDialogDesktopProps } from 'Admin'
import { Button, Switch, TextField } from 'components'
import { ChangeEventHandler, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { Employee } from 'types/issueTypes'
import { AllInOneReportSettings } from 'types/reportTypes'
import { dataTestId } from 'utils'
import { CcrpResources } from '../../types/models'
import {
    getDefaultNotificationSettings,
    getDefaultSetting,
} from '../AllInOne/utils'
import { NotificationSettings } from './NotificationSettings'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        switches: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
    }),
    { name: 'ReportSettingContent' }
)

export interface ReportSettingsDialogProps
    extends Omit<AdminDialogDesktopProps, 'renderActions'> {
    settings?: AllInOneReportSettings
    onSave?: (settings: AllInOneReportSettings) => void
    settingsNames: string[]
    raportId: string
}

export const ReportSettingsDialog = (props: ReportSettingsDialogProps) => {
    const {
        settings: settingsProp,
        settingsNames: settingsNamesProps,
        raportId,
        onSave,
        onClose,
        ...other
    } = props
    const [settingsNames, setSettingsNames] = useState<string[]>([])
    const resources = useSelector<RootState, CcrpResources>(
        (state) => state.resources
    )
    const { email, geid } = useSelector((state: RootState) => {
        const tmp = state.dashboard?.profile
        const profile = { ...tmp }
        return profile as Employee
    })
    const [nameError, setNameError] = useState('')
    const [settings, setSettings] = useState<AllInOneReportSettings>(
        getDefaultSetting(raportId)
    )
    useEffect(() => {
        if (settingsProp) {
            setSettings({ ...settingsProp })
            setSettingsNames(
                [...settingsNamesProps].filter((x) => x !== settingsProp.name)
            )
        } else {
            setSettings(getDefaultSetting(raportId))
            setSettingsNames([...settingsNamesProps])
        }
        setNameError(null)
    }, [settingsProp, props.open])
    const classes = useStyles()
    const handleSave = () => {
        if (!settings.name) {
            setNameError('The settings name is required')
            return
        }
        onSave && onSave({ ...settings })
        onClose()
    }
    const isActive = Boolean(settings?.notificationSettings?.isActive)
    const handleSettingNameChange: ChangeEventHandler<
        HTMLTextAreaElement | HTMLInputElement
    > = (e) => {
        const name = e.target.value
        setSettings((prev) => ({ ...prev, name }))
        if (settingsNames.includes(name)) {
            setNameError('Setting name must be unique.')
        } else {
            setNameError(null)
        }
    }
    return (
        <AdminDialogDesktop
            onClose={onClose}
            renderActions={() => (
                <>
                    <Button onClick={onClose}>Close</Button>
                    <Button
                        onClick={handleSave}
                        color="secondary"
                        disabled={Boolean(nameError)}
                        {...dataTestId('ALLINONE_SETTINGS_SAVE')}
                    >
                        Save
                    </Button>
                </>
            )}
            {...other}
        >
            <TextField
                label="Setting name"
                value={settings?.name ?? ''}
                error={Boolean(nameError)}
                helperText={nameError}
                disabled={Number.isInteger(settings.id)}
                onChange={handleSettingNameChange}
                required
                {...dataTestId('ALLINONE_SETTINGS_NAME_FILED')}
            />
            <div className={classes.switches}>
                <FormControlLabel
                    control={
                        <>
                            <Switch
                                checked={settings?.isDefault ?? false}
                                onChange={(e, checked) =>
                                    setSettings((prev) => ({
                                        ...prev,
                                        isDefault: checked,
                                    }))
                                }
                            />
                        </>
                    }
                    label="Is default report settings"
                    {...dataTestId('ALLINONE_SETTINGS_DEFAULT')}
                />
                <FormControlLabel
                    control={
                        <>
                            <Switch
                                checked={isActive}
                                onChange={(e, checked) => {
                                    setSettings((prev) => ({
                                        ...prev,
                                        notificationSettings: {
                                            ...(prev?.notificationSettings ??
                                                getDefaultNotificationSettings(
                                                    {
                                                        geid,
                                                        email,
                                                    },
                                                    resources
                                                )),
                                            isActive: checked,
                                        },
                                    }))
                                }}
                            />
                        </>
                    }
                    label="Send notifications"
                    {...dataTestId('ALLINONE_SETTINGS_SEND_NOTIFICATION')}
                />
            </div>
            <Collapse in={isActive}>
                {settings.notificationSettings && (
                    <NotificationSettings
                        notificationSettings={settings?.notificationSettings}
                        onSettingsChange={(notificationSettings) =>
                            setSettings((prev) => ({
                                ...prev,
                                notificationSettings,
                            }))
                        }
                    />
                )}
            </Collapse>
        </AdminDialogDesktop>
    )
}
