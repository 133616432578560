import { MenuItem, MenuList } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import { getSendEmailsTags } from '../utils'

export interface TagsPalleteProps {
    onTagAdd: (tag: string) => void
    onClose?: () => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            maxHeight: 300,
        },
    }),
    {
        name: 'TagsPallete',
    }
)

export const TagsPallete = (props: TagsPalleteProps) => {
    const { onTagAdd, onClose } = props
    const classes = useStyles()
    return (
        <MenuList className={classes.root}>
            {getSendEmailsTags().map((t) => (
                <MenuItem
                    key={t.tag}
                    onClick={() => {
                        onTagAdd(t.tag)
                        onClose && onClose()
                    }}
                >
                    {t.description}
                </MenuItem>
            ))}
        </MenuList>
    )
}
