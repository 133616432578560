import { Grid, GridProps } from '@mui/material'

export interface FormGridItemProps extends Omit<GridProps, 'item'> {
    fullWidth?: boolean
}

export const FormGridItem = (props: FormGridItemProps) => {
    const { children, fullWidth = false, xs, sm, ...other } = props
    return (
        <Grid xs={xs ?? 12} sm={sm ?? fullWidth ? 12 : 6} {...other} item>
            {children}
        </Grid>
    )
}
