import Collapse from '@mui/material/Collapse'
import { makeStyles } from '@mui/styles'
import { memo, useEffect, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueActionsTypes, IssueStatuses } from 'types/enums'
import { dataTestId } from 'utils'
import { ExpandButton, FlexGrow } from '../../components'
import TileHeader from './TileHeader'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.common.white,
            marginTop: theme.spacing(3),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            '@media (max-width: 768px)': {
                marginTop: theme.spacing(2),
            },
        },
        content: {
            position: 'relative',
            margin: theme.spacing(1, 0),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        bottomShadow: {
            position: 'absolute',
            display: 'block',
            height: theme.spacing(4),
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(rgba(255,255,255,0), white)',
        },
        footer: {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            height: 56 - +theme.spacing(2),
            padding: theme.spacing(1),
        },
    }),
    { name: 'ExpandTile' }
)

export interface ExpandTileProps
    extends Partial<{
        issueId: number
        expanded: boolean
        isOverdue?: boolean
        isHighImpact?: boolean
        issueNumber: string
        subject: string
        issueStatusId: IssueStatuses
        actionCode: IssueActionsTypes
        actions?: React.ReactNode
        showActionStatus: boolean
        collapsedSize?: string | number
        onExpand?: (expanded: boolean) => void
        children?: React.ReactNode
    }> {}

export const ExpandTile: React.FC<ExpandTileProps> = (props) => {
    const {
        issueId: id,
        children,
        issueNumber,
        subject,
        issueStatusId,
        actionCode,
        isOverdue,
        isHighImpact,
        collapsedSize,
        showActionStatus,
        onExpand,
        expanded: expandedProp,
        actions,
    } = props
    const [expanded, setExpanded] = useState(false)
    useEffect(() => setExpanded(expandedProp), [expandedProp])
    const classes = useStyles()
    return (
        <div
            className={classes.root}
            {...dataTestId(`DASHBOARD_ISSUE_TILE_CONTAINER_${issueNumber}`)}
        >
            <TileHeader
                id={id}
                isOverdue={isOverdue}
                isHighImpact={isHighImpact}
                number={issueNumber}
                subject={subject}
                issueStatusId={issueStatusId}
                showActionStatus={showActionStatus}
                actionCode={actionCode}
            />
            <div className={classes.content}>
                <Collapse in={expanded} collapsedSize={collapsedSize}>
                    {children}
                </Collapse>
                {!expanded && <div className={classes.bottomShadow} />}
            </div>
            {actions && (
                <div className={classes.footer}>
                    {collapsedSize && (
                        <ExpandButton
                            expanded={expanded}
                            onClick={() => {
                                setExpanded(!expanded)
                                onExpand && onExpand(!expanded)
                            }}
                            size="medium"
                            IconProps={{ fontSize: 'small' }}
                            {...dataTestId('DASHBOARD_ISSUE_TILE_EXPAND')}
                        />
                    )}
                    <FlexGrow />
                    {actions}
                </div>
            )}
        </div>
    )
}

ExpandTile.defaultProps = {
    expanded: false,
    isOverdue: false,
    isHighImpact: false,
    showActionStatus: false,
}

export default memo(ExpandTile)
