import { useMediaQuery, useTheme } from '@mui/material'
import {
    HorizontalProcessFlowView,
    ProcessFlowViewProps,
    VerticalProcessFlowView,
} from '.'

export const ProcessFlowView = (props: ProcessFlowViewProps) => {
    const {
        issueType,
        issueStatus,
        highImpact,
        solutionWillBeValidatedByCoordinator,
        isWaitingForExecution,
        dates,
        ...other
    } = props
    const theme = useTheme()
    const mobile = useMediaQuery('(max-width: 600px)')
    return mobile ? (
        <VerticalProcessFlowView
            style={{ marginTop: theme.spacing(2) }}
            issueStatus={issueStatus}
            highImpact={highImpact}
            issueType={issueType}
            solutionWillBeValidatedByCoordinator={
                solutionWillBeValidatedByCoordinator
            }
            isWaitingForExecution={isWaitingForExecution}
            dates={dates}
            {...other}
        />
    ) : (
        <HorizontalProcessFlowView
            issueStatus={issueStatus}
            highImpact={highImpact}
            issueType={issueType}
            solutionWillBeValidatedByCoordinator={
                solutionWillBeValidatedByCoordinator
            }
            isWaitingForExecution={isWaitingForExecution}
            dates={dates}
            {...other}
        />
    )
}
