import dayjs from 'dayjs'

export function createArrayWithoutNulls<T>(...args: Array<T>): Array<T> {
    return args.filter((a) => Boolean(a))
}

export function arrayToStringLine(strings: Array<string>): string {
    return strings.filter((s) => Boolean(s)).join(' • ')
}

export const getArray = <T, K extends keyof T>(obj: T, key: K): T[K] => {
    const arr = obj[key]
    return Array.isArray(arr) && arr.length > 0 ? arr : null
}

export const uuid = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
            var r = (Math.random() * 16) | 0,
                v = c === 'x' ? r : r && 0x3 | 0x8
            return v.toString(16)
        }
    );
}

export const getPartOfTable = <T>(arr: T[], pageSize: number, page: number) => {
    const startAt = pageSize * page - pageSize
    const endAt = pageSize * page
    const parsedArr = [...arr]
    return parsedArr.slice(startAt, endAt)
}

export const validateEmail = (email: string): boolean => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export const deepCopy = <T>(obj: T): T => {
    const json = JSON.stringify(obj)
    return JSON.parse(json) as T
}

export function readFileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
        var fr = new FileReader()
        fr.onload = () => {
            const results = fr.result as string
            resolve(results.replace(/^data:(.*,)?/, ''))
        }
        fr.onerror = reject
        fr.readAsDataURL(file)
    });
}

export const base64Number = (base64: string): number | null => {
    try {
        const results = Number(atob(base64))
        return Number.isNaN(results) ? null : results
    } catch (error) {
        return null
    }
}

export const getUtcAppendix = (isUtc: boolean) => {
    const offset = dayjs().utcOffset()
    const hours = offset / 60
    switch (true) {
        case isUtc || hours === 0:
            return '(UTC)'
        case 0 < offset:
            return `(UTC+${hours})`
        case offset < 0:
            return `(UTC-${hours})`
        default:
            return ''
    }
}
