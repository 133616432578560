import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { SelectProps } from '@mui/material'
import { RootState } from 'store/types'
import { TargetResolvingDateChangeReasons } from 'types/enums'
import { FilterBase } from '../../types/models'
import Select from '../Base/Select'

export type TargetResolvingDataChangeReasonSelectProps = SelectProps & {
    selectedReason?: TargetResolvingDateChangeReasons
    onReasonChange: (reasonId: TargetResolvingDateChangeReasons) => void
    helperText?: string
}

export const TargetResolvingDataChangeReasonSelect = (
    props: TargetResolvingDataChangeReasonSelectProps
) => {
    const {
        selectedReason: selectedReasonProp,
        onReasonChange,
        ...other
    } = props
    const reasons = useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.targetResolvingDateChangeReason
    )

    const [selectedReason, setSelectedReason] =
        useState<FilterBase<number>>(null)
    useEffect(() => {
        const value =
            reasons.find((x: any) => x.code === selectedReasonProp) ||
            reasons[0]
        setSelectedReason(value)
    }, [reasons, selectedReasonProp])
    return reasons ? (
        <Select
            variant="filled"
            labelPropName="name"
            valuePropName="code"
            showNone={false}
            label="Target resolution and verification change reason"
            displayEmpty={false}
            onChange={(e) => {
                var reason = reasons.find((x: any) => x.code === e.target.value)
                setSelectedReason(reason)
                onReasonChange &&
                    onReasonChange(
                        Number(reason.code) as TargetResolvingDateChangeReasons
                    )
            }}
            selectedItem={selectedReason}
            items={[...reasons]}
            fullWidth
            {...other}
        />
    ) : null
}

export default TargetResolvingDataChangeReasonSelect
