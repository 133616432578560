import {
    FormControl,
    FormControlProps,
    InputLabel,
    MenuItem,
    Select,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { FilterBase } from 'types/models'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({ root: { marginBottom: theme.spacing(2) } }),
    { name: 'RepeatTypeSelect' }
)

export interface RepeatTypeSelectProps
    extends Omit<FormControlProps, 'onChange'> {
    value: FilterBase<number>
    onChange: (value: FilterBase<number>) => void
}

export const RepeatTypeSelect = (props: RepeatTypeSelectProps) => {
    const { className, value, onChange, ...other } = props
    const reportNotificationRepeatType = useSelector<
        RootState,
        FilterBase<number>[]
    >((state) => state?.resources?.reportNotificationRepeatType)
    const classes = useStyles()
    return (
        <FormControl
            className={clsx(classes.root, className)}
            fullWidth
            {...other}
        >
            <InputLabel>Repeat</InputLabel>
            <Select
                value={value?.code ?? null}
                onChange={(e) => {
                    onChange(
                        reportNotificationRepeatType.find(
                            (r) => r.code === e.target.value
                        )
                    )
                }}
                variant="filled"
            >
                {reportNotificationRepeatType.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}
