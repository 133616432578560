import { ArrowBack, Menu, MoreVert, Refresh } from '@mui/icons-material'
import {
    AppBar,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    SwipeableDrawer,
    Toolbar,
    Typography,
    useMediaQuery,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { DialogBase } from 'components'
import { MainMenu } from 'Dashboard'
import { MAIN_MOBILE_MEDIA_QUERY } from 'Dashboard/consts'
import { LEFT_MENU_DISPLAY } from 'features'
import { Fragment, MouseEventHandler, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
    clearIssue,
    deleteDraft,
    IssueStoreState,
    loadIssue,
    setLightMode,
} from 'store/issue/index'
import { RootState } from 'store/types'
import theme from 'styles/themeBase'
import { IssueRoles } from 'types/enums'
import { dataTestId, urlBuilder } from 'utils'
import FlexGrow from '../../components/Base/FlexGrow'
import { appInsights } from '../../configs/appInsights'
import { ISSUE_VIEW_ACTION_LOGS } from '../routes'
import { useIssueAppBarStyle } from './IssueAppBar.style'

export const IssueViewBar = () => {
    const classes = useIssueAppBarStyle()
    const [anchorEl, setAnchorEl] = useState(null)
    const history = useHistory()
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const [open, setOpen] = useState(false)
    const [deleteReason, setDeleteReason] = useState('')
    const dispatch = useDispatch()

    const onDeleteHandler = () => {
        setAnchorEl(null)
        dispatch(deleteDraft())
    }

    const menuItems = useMemo(() => {
        const menuList: Array<React.ReactNode> = []
        if (!issue) {
            return menuList
        }
        const { issueStatusId, context, lightMode } = issue
        const openActionLogs = () => {
            appInsights.trackEvent({ name: 'Open actions logs' })
            history.push(
                urlBuilder(ISSUE_VIEW_ACTION_LOGS, { id: issue.issueId })
            )
        }
        const isIssueDeleted = Boolean(issue?.isDeleted)
        const canDeleteDraft =
            0 <=
            context?.myRoles.findIndex(
                (r) =>
                    r === IssueRoles.Issue_Owner || IssueRoles.Issue_Owner_Team
            )
        const handleLightMode = () => {
            appInsights.trackEvent(
                { name: 'Set Light Mode' },
                { lightMode: !lightMode }
            )
            dispatch(setLightMode(!lightMode))
        }
        menuList.push(
            <MenuItem
                key="issue-action-logs"
                className={classes.menuItem}
                onClick={openActionLogs}
                selected={false}
            >
                Action logs
            </MenuItem>
        )
        menuList.push(
            <MenuItem
                {...dataTestId('ISSUE_VIEW_TOP_BAR_CHANGE_MODE')}
                key="issue-mode"
                className={classes.menuItem}
                onClick={handleLightMode}
                selected={false}
            >
                {lightMode ? 'Extended Mode' : 'Light Mode'}
            </MenuItem>
        )
        if (issueStatusId === 2 && canDeleteDraft && !isIssueDeleted) {
            menuList.push(
                <div key="delete-draft">
                    <MenuItem
                        {...dataTestId('ISSUE_VIEW_TOP_BAR_DELETE_DRAFT')}
                        className={classes.menuItem}
                        onClick={() => {
                            setOpen(true)
                        }}
                    >
                        Delete draft
                    </MenuItem>
                    <DialogBase
                        open={open}
                        title={number}
                        message="You are about to delete this draft. Please confirm."
                        closeLabel="Cancel"
                        confirmLabel="Delete"
                        onClose={() => {
                            setAnchorEl(null)
                            setOpen(false)
                            setDeleteReason('')
                        }}
                        onConfirm={onDeleteHandler}
                    ></DialogBase>
                </div>
            )
        }
        return menuList
    }, [issue, classes.menuItem, history, dispatch, open, deleteReason])
    const goToDashboard = () => {
        history.replace('/')
        dispatch(clearIssue())
    }
    const refreshIssue = () => dispatch(loadIssue(issue.issueId))
    let number =
        issue && issue.issueNumber ? issue.issueNumber : 'Loading issue…'
    number = issue?.error ? 'Error' : number
    const handleOpenMenu: MouseEventHandler<HTMLButtonElement> = (e) =>
        setAnchorEl(e.currentTarget)

    const [openMenu, setOpenMenu] = useState(false)
    const isMobile = useMediaQuery(MAIN_MOBILE_MEDIA_QUERY)
    const handleCloseMenuBurger = () => setOpenMenu(false)
    const handleOpenMenuBurger = () => setOpenMenu(true)
    const menu = useMemo(
        () => <MainMenu onClose={handleCloseMenuBurger} mobile={isMobile} />,
        [isMobile]
    )
    return (
        <Fragment>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton
                        {...dataTestId('ISSUE_VIEW_TOP_BAR_BACK')}
                        aria-label="Back"
                        className={classes.back}
                        onClick={goToDashboard}
                        size="large"
                    >
                        <ArrowBack />
                    </IconButton>
                    {isMobile && LEFT_MENU_DISPLAY && (
                        <>
                            <IconButton
                                size="large"
                                onClick={handleOpenMenuBurger}
                            >
                                <Menu />
                            </IconButton>
                            <SwipeableDrawer
                                anchor="left"
                                open={openMenu}
                                onClose={handleCloseMenuBurger}
                                onOpen={handleOpenMenuBurger}
                            >
                                {menu}
                            </SwipeableDrawer>
                        </>
                    )}
                    <Typography
                        variant="h5"
                        {...dataTestId('ISSUE_VIEW_TOP_BAR_ISSUE_NUMBER')}
                        style={{ marginLeft: theme.spacing(1) }}
                    >
                        {number}
                    </Typography>
                    <FlexGrow />
                    <IconButton
                        {...dataTestId('ISSUE_VIEW_TOP_BAR_REFRESH')}
                        aria-label="Refresh"
                        onClick={refreshIssue}
                        disabled={issue.loading}
                        size="large"
                    >
                        <Refresh />
                    </IconButton>
                    {menuItems && 0 < menuItems.length && (
                        <IconButton
                            {...dataTestId('ISSUE_VIEW_TOP_BAR_MORE_OPTIONS')}
                            onClick={handleOpenMenu}
                            disabled={issue.loading}
                            size="large"
                        >
                            <MoreVert />
                        </IconButton>
                    )}
                    <Popper
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        transition
                        disablePortal
                        style={{ zIndex: 2000 }}
                    >
                        {({ TransitionProps, placement }) => (
                            <Grow
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener
                                        onClickAway={() => setAnchorEl(null)}
                                    >
                                        <MenuList>{menuItems}</MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Fragment>
    )
}

export default IssueViewBar
