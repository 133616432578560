import dayjs from 'dayjs'
import { ActionItem } from 'types/actionPlanTypes'
import { ActionPlanItemInputFields } from './types'

export const getInitQuery: () => ActionPlanItemInputFields = () => ({
    actionName: '',
    actionDescription: '',
    activitiesDescription: '',
    actionCreator: null,
    actionOwner: null,
    actionDeadline: dayjs().toISOString(),
    actionType: null,
    descriptionAttachments: [],
    activitiesAttachments: [],
    interestedParties: [],
})

export const getInitQueryForItemContainer = (): ActionItem => ({
    accessMatrix: [],
    actionStatus: null,
    actionItemId: 0,
    issueId: null,
    issueNumber: '',
    isRejected: false,
    isReopened: false,
    isDeleted: false,
    actionName: '',
    actionDescription: '',
    activitiesDescription: '',
    resolvingUnit: {
        id: null,
        balId: null,
        isGlobal: null,
        organizationalInfo: {
            abacusCode: '',
            businessUnit: null,
            division: null,
            genericCode: '',
            productGroup: null,
        },
        abacusCode: '',
        activity: '',
        addressId: '',
        countryCode: '',
        locationCity: '',
        city: '',
        address: '',
        zipCode: '',
        isActive: null,
        coordinatorsEmails: [],
        classifications: [],
    },
    resolutionOwner: {
        geid: '',
        phone: '',
        organizationInfo: {
            abacusCode: '',
            businessUnit: null,
            division: null,
            genericCode: '',
            productGroup: null,
        },
        countryCode: '',
        supervisor: null,
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        isActive: null,
    },
    issueCoordinator: {
        geid: '',
        phone: '',
        organizationInfo: {
            abacusCode: '',
            businessUnit: null,
            division: null,
            genericCode: '',
            productGroup: null,
        },
        countryCode: '',
        supervisor: null,
        fullName: '',
        firstName: '',
        lastName: '',
        email: '',
        isActive: null,
    },
    actionCreator: null,
    actionOwner: null,
    actionDeadline: null,
    creationDate: null,
    completionDate: null,
    actionType: null,
    rowVersion: null,
    interestedParties: [],
    actionItemAttachments: [],
})
