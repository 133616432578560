import {
    IssueStoreState,
    ISSUE_LOAD_DONE,
    ISSUE_LOAD_MATRIX_CALL,
    selectCurrentRoles,
    setAccessMatrix,
} from '..'
import { getAccessMatrixScheme } from 'api'
import { IssueRoles, IssueStatuses } from 'types/enums'
import { showSnackbar } from 'store/app'
import { ISSUE_LOAD_CONTEXT_DONE } from '../actionsTypes'

import { RootState } from 'store/types'
import axios, { AxiosResponse } from 'axios'
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects'
import { Action } from 'redux'
import { setIssueActionFail, setLoadingState } from '../actions'
import { AccessMatrixItem } from 'types/matrixTypes'

function* loadMatrixSchemeAction(action: Action) {
    const cancelSource = axios.CancelToken.source()
    try {
        let issueId: number = null
        let issueStatusId: IssueStatuses = null
        let myRoles: Array<IssueRoles> = []
        const issueState = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        myRoles = issueState?.context?.myRoles || [IssueRoles.Issue_Owner]
        issueId = issueState.issueId
        issueStatusId = issueState?.issueStatusId || IssueStatuses.Draft
        const isDeleted = Boolean(issueState?.isDeleted)
        if (isDeleted) {
            yield put(setAccessMatrix([]))
            return
        }
        yield put(setLoadingState(true, 'Loading issue permissions…'))
        const { data: matrixes } = (yield call(
            getAccessMatrixScheme,
            {
                issueStatusId,
                issueId,
                roleId: [...myRoles],
            },
            cancelSource.token
        )) as AxiosResponse<Array<AccessMatrixItem>>
        const matrixesOptimized: Array<AccessMatrixItem> = matrixes.map((m) => {
            Reflect.ownKeys(m.matrix).forEach((a) => {
                m.matrix[a as string] = m.matrix[a as string].filter(
                    (s) => !s.isDisabled || s?.dependentOn
                )
            })
            return m
        })
        yield put(setAccessMatrix(matrixesOptimized))
        if (Array.isArray(myRoles)) {
            yield put(
                selectCurrentRoles([
                    ...myRoles.filter((x) => x !== IssueRoles.Support_Desk),
                ])
            )
        }
    } catch (err) {
        yield put(setIssueActionFail(ISSUE_LOAD_MATRIX_CALL, null))
        yield put(showSnackbar('Cannot load issue access scheme!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* loadMatrixSchemeSaga() {
    yield takeLatest(
        [ISSUE_LOAD_DONE, ISSUE_LOAD_CONTEXT_DONE, ISSUE_LOAD_MATRIX_CALL],
        loadMatrixSchemeAction
    )
}
