import { BaseBar, Content } from 'components'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useParams } from 'react-router-dom'
import AbbTypography from 'components/Base/AbbTypography'
import {
    ScoreValues,
    SurveyTranslationContextProps,
    SurveyTranslationContext,
} from 'Survey'
import { getSurveyStatus, sendSurveyStatus, subscriptionSurvey } from 'api'
import { CustomerSurveyStatus } from 'types/surveyTypes'
import { SurveyContent } from './components/SurveyContent'
import { useStyles } from './Survey.style'
import {
    ConfirmationNotFound,
    PreviouslyUnsubscribedSurvey,
    SurveyFooter,
    SurveyServerError,
} from './components'
import { AxiosError } from 'axios'

export interface SurveyAppParams {
    effortScore?: string
    satisfactionScore?: string
}

const validateScore = (value: string): ScoreValues => {
    if (value) {
        const score = Number(value)
        if (5 < score) return 5
        if (score < 1) return 1
        return score as ScoreValues
    }
    return null
}

export const Survey = () => {
    const classes = useStyles()
    const {
        loading: loadingTranslations,
        t,
        error: errorTranslations,
        loadTranslations,
    } = useContext<SurveyTranslationContextProps>(SurveyTranslationContext)
    const location = useLocation()
    const { surveyKey, effortScore: effortScoreUrl } =
        useParams<{ surveyKey: string; effortScore: string }>()
    const [loading, setLoading] = useState(false)
    const [surveyState, setSurveyState] = useState<CustomerSurveyStatus>(null)
    const [error, setError] = useState<AxiosError>(null)
    const loadSurvey = async () => {
        try {
            setLoading(true)
            setError(null)
            const { data } = await getSurveyStatus(surveyKey)
            const searchParams = new URLSearchParams(location.search)
            const effortScore = searchParams.get('effort_score')
            const satisfactionScore =
                searchParams.get('satisfaction_score') ?? effortScoreUrl
            let questions = data.questions
            if (Array.isArray(questions)) {
                if (effortScore) {
                    const index = questions.findIndex(
                        (q) => q.type === 'ValueFromOneToFiveEffort'
                    )
                    questions[index].result = validateScore(effortScore)
                }
                if (satisfactionScore) {
                    const index = questions.findIndex(
                        (q) => q.type === 'ValueFromOneToFiveSatisfaction'
                    )
                    questions[index].result = validateScore(satisfactionScore)
                }

                questions = questions?.map((x) => {
                    const results = { ...x }
                    if (x.type === 'Textarea') {
                        results.result = ''
                    }
                    return results
                })
                data.questions = questions
            }
            setSurveyState({ ...data })
        } catch (error: any) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        loadSurvey()
    }, [location])
    const handleSubmit = async (survey: CustomerSurveyStatus) => {
        try {
            setLoading(true)
            await sendSurveyStatus(surveyKey, survey.questions)
            await loadSurvey()
            setSurveyState({ ...survey, firstConfirm: true })
        } catch (error: any) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    const handleSubscribe = async () => {
        try {
            setLoading(true)
            setError(null)
            await subscriptionSurvey(surveyKey)
            await loadSurvey()
        } catch (error: any) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    const content = useMemo(() => {
        const status = error ? error?.response?.status ?? 0 : null

        switch (true) {
            case 500 <= status || status === 0:
                return <SurveyServerError />
            case 400 <= status && status < 500:
                return <ConfirmationNotFound />
            case !Boolean(surveyState?.isSubscribed):
                return (
                    <PreviouslyUnsubscribedSurvey
                        onSubscribe={handleSubscribe}
                    />
                )

            case !surveyState?.isActive: {
                return (
                    <>
                        <AbbTypography variant="h2">
                            {t(
                                'Survey_Status_AlreadyTaken',
                                'You have already taken this closure confirmation. Thank You!'
                            )}
                        </AbbTypography>
                        <div className={classes.space} />
                    </>
                )
            }
            case surveyState.firstConfirm: {
                return (
                    <>
                        <AbbTypography variant="h2">
                            {t(
                                'Survey_Status_Submitted',
                                'Thank you for providing your feedback.'
                            )}
                        </AbbTypography>
                        <div className={classes.space} />
                    </>
                )
            }
            case surveyState?.isActive:
                return (
                    <SurveyContent
                        survey={{ ...surveyState, firstConfirm: false }}
                        onSubmit={handleSubmit}
                        onSurveyChange={(survey) =>
                            setSurveyState((prev) => ({ ...prev, ...survey }))
                        }
                    />
                )
            default:
                return null
        }
    }, [surveyState, error])
    return (
        <>
            <Helmet>
                <title>{t('Survey_PageTitle', 'Customer survey')}</title>
            </Helmet>
            <BaseBar title="" />

            <Content
                error={error || errorTranslations}
                onRetry={() =>
                    errorTranslations ? loadTranslations() : loadSurvey()
                }
                loading={loading || loadingTranslations}
            >
                {!loading && (
                    <div className={classes.root}>
                        {content}
                        <SurveyFooter />
                    </div>
                )}
            </Content>
        </>
    )
}
