import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles((theme: AbbTheme) => ({
    root: {
        display: 'flex',
        ...theme.typography.body2,
        width: '100%',
        padding: theme.spacing(1, 0),
        textDecoration: 'none',
        fontWeight: theme.typography.fontWeightMedium as number,
        color: theme.palette.common.black,
        '&:hover': {
            color: theme.palette.blue.main,
        },
    },
}))

export interface HyperLinkProps extends HtmlHTMLAttributes<HTMLAnchorElement> {
    href: string
    target: string
    rel: string
}

export const HyperLink: FC<HyperLinkProps> = (props) => {
    const { className, children, ...other } = props
    const classes = useStyles(props)
    return (
        <a className={clsx(className, classes.root)} {...other}>
            {children}
        </a>
    )
}

export default HyperLink
