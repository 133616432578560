import { IconButton, IconButtonProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ReactNode } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            width: 36,
            height: 36,
            borderRadius: theme.shape.borderRadius,
            '&$active': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        active: {},
    }),
    { name: 'FormatButton' }
)

export interface FormatButtonProps
    extends Omit<IconButtonProps, 'size' | 'children'> {
    active?: boolean
    renderIcon: () => ReactNode
}

export const FormatButton = (props: FormatButtonProps) => {
    const { className, active, renderIcon, ...other } = props
    const classes = useStyles()
    return (
        <IconButton
            className={clsx(className, classes.root, active && classes.active)}
            onMouseDown={(e) => e.preventDefault()}
            {...other}
            size="large"
        >
            {renderIcon()}
        </IconButton>
    )
}
