import { useState } from 'react'

import AttachmentIcon from '@mui/icons-material/Attachment'
import DeleteIcon from '@mui/icons-material/Delete'
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import { makeStyles } from '@mui/styles'
import { deleteFile, downloadAttachment } from 'api/attachments'
import { useDispatch } from 'react-redux'
import { AbbTheme } from 'styles/createAbbTheme'
import { Attachment } from 'types/issueTypes'
import { FILE_MANAGER } from '../../consts/selectorIds'
import { showSnackbar } from '../../store/app/index'
import { customize } from '../../utils'
import DialogBase from '../Dialogs/DialogBase'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            position: 'relative',
            justifyContent: 'space-between',
            width: '100%',
            height: 36,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '&:last-child': {
                marginBottom: 0,
            },
        },
        icon: {
            width: 24,
            height: 24,
            color: theme.palette.grey[700],
        },
        fileName: {
            marginLeft: theme.spacing(2),
            textAlign: 'left',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: 14,
            color: theme.palette.grey[700],
            fontWeight: theme.typography.fontWeightMedium as number,
            fontFamily: 'abbvoice, sans-serif',
        },
        circularProgress: {
            marginRight: theme.spacing(2.5),
        },
        button: {
            marginRight: theme.spacing(1.5),
            padding: theme.spacing(0.75),
        },
    }),
    { name: 'Attachment' }
)

export interface AttachmentProps extends ButtonBaseProps {
    file?: Attachment
    onFileRemove?: (file: Attachment) => void
}

export const CcrpAttachment = (props: AttachmentProps) => {
    const classes = useStyles()

    const dispatch = useDispatch()
    const { disabled, file, onFileRemove, ...other } = props

    const [deleting, setDeleting] = useState(false)
    const [downloading, setDownloading] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)

    const removeFile = () => {
        setDialogOpen(false)
        setDeleting(true)
        deleteFile(file.issueId, file.id)
            .then(() => {
                onFileRemove && onFileRemove(file)
            })
            .catch(() => {
                setDeleting(false)
                dispatch(showSnackbar('File cannot be deleted', true))
            })
    }

    const saveFile = () => {
        setDownloading(true)
        downloadAttachment(file.issueId, file.id)
            .then((resp) => {
                let a = document.createElement('a')
                document.body.appendChild(a)
                a.style.display = 'none'
                let blob = new Blob([resp.data], { type: 'octet/stream' }),
                    url = window.URL.createObjectURL(blob)
                a.href = url
                a.download = file.fileName
                a.click()
                window.URL.revokeObjectURL(url)
                setDownloading(false)
            })
            .catch((_error) => {
                dispatch(
                    showSnackbar('Failed to download the attachment!', true)
                )
            })
    }

    return file ? (
        <div className={classes.root}>
            <DialogBase
                open={dialogOpen}
                title={'Delete?'}
                message={'Are you sure you want to delete this attachment?'}
                closeLabel={'Cancel'}
                confirmLabel={'Delete'}
                onClose={() => setDialogOpen(false)}
                onConfirm={removeFile}
            ></DialogBase>
            <ButtonBase
                {...customize(FILE_MANAGER.UPLOADED_FILE_BUTTON, file.fileName)}
                {...other}
                disabled={downloading}
                onClick={saveFile}
            >
                <AttachmentIcon className={classes.icon} />
                <div className={classes.fileName}>{file.fileName}</div>
            </ButtonBase>
            {deleting ? (
                <CircularProgress
                    className={classes.circularProgress}
                    color="secondary"
                    size={20}
                ></CircularProgress>
            ) : !disabled ? (
                <IconButton
                    {...customize(
                        FILE_MANAGER.UPLOADED_FILE_DELETE_BUTTON,
                        file.fileName
                    )}
                    onClick={() => setDialogOpen(true)}
                    className={classes.button}
                    size="large"
                >
                    <DeleteIcon className={classes.icon}></DeleteIcon>
                </IconButton>
            ) : null}
        </div>
    ) : null
}

export default CcrpAttachment
