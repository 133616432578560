import { ShoppingCartOutlined } from '@mui/icons-material'
import {
    AutocompleteChangeReason,
    BaseTextFieldProps,
    useTheme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from 'components'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import { RootState } from 'store/types'
import {
    BusinessUnitFilterBase,
    DivisionFilterBase,
    FilterBase,
    ProductGroupFilterBase,
} from 'types/models'
import { getOptionLabel } from './utils'

export interface ProductGroupSelectProps extends Partial<BaseTextFieldProps> {
    division?: DivisionFilterBase
    businessUnit?: BusinessUnitFilterBase
    selectedProductGroup: ProductGroupFilterBase
    onProductGroupChanged: (item: ProductGroupFilterBase) => void
    displayAll?: boolean
    displayInactive?: boolean
}

export const ProductGroupSelect = (props: ProductGroupSelectProps) => {
    const {
        label = 'Product group',
        disabled,
        selectedProductGroup,
        division,
        businessUnit,
        onProductGroupChanged,
        error,
        helperText,
        displayInactive = false,
        ...other
    } = props
    const theme = useTheme()
    const allOptions = useSelector(
        (state: RootState) => state.resources.productGroups
    )
    const options = useMemo(() => {
        const filterByDivision = division && Object.keys(division)?.length !== 0
        const filterByBusinessUnit =
            businessUnit && Object.keys(businessUnit)?.length !== 0
        return allOptions
            .filter((x) => (displayInactive ? true : x.isActive))
            .filter((b) =>
                filterByDivision ? division.code === b.divisionCode : true
            )
            .filter((b) =>
                filterByBusinessUnit
                    ? businessUnit.code === b.businessUnitCode
                    : true
            )
    }, [allOptions, businessUnit, division, displayInactive])

    const handleSelectFilter = (item: FilterBase<string>) =>
        onProductGroupChanged(item)
    useUpdateEffect(() => {
        if (
            businessUnit?.code !== selectedProductGroup?.businessUnitCode ||
            division?.code !== selectedProductGroup?.divisionCode
        ) {
            handleSelectFilter(null)
        }
    }, [businessUnit, division])
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option?.code === value?.code
    }

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedProductGroup ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Product Groups'}
            loadingText={'Loading Product Groups…'}
            disableClearable={false}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) => {
                          const label = state.getOptionLabel(f)
                          return label
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      })
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as FilterBase
                switch (reason) {
                    case 'clear':
                        handleSelectFilter(null)
                        break
                    default:
                        handleSelectFilter({ ...selectedValue })
                }
            }}
            renderOption={(props, option) => (
                <li {...props}>{getOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={label}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                        startAdornment: (
                            <ShoppingCartOutlined
                                fontSize="small"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default ProductGroupSelect
