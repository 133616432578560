import { useState } from 'react'
import {
    CustomerContactsManagePageResults,
    useCustomerContactsContext,
} from 'Admin'
import { SearchPanel } from 'components'
import { searchAdminCustomers } from 'api'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'

export interface CustomerContactsTabProps {}

export const CustomerContactsTab = (props: CustomerContactsTabProps) => {
    const {
        customerContactTabSearchResults,
        updateCustomerContactTabSearchResults,
    } = useCustomerContactsContext()
    const [noResults, setNoResults] = useState(false)
    const [page, setPage] = useState(1)
    const [isSearching, setIsSearching] = useState(false)
    const dispatch = useDispatch()
    const [searchText, setSearchText] = useState('')

    const handleSearch = async () => {
        try {
            if (searchText === '') {
                dispatch(showSnackbar('Type searching phrase', true))
                return
            }
            if (searchText.length < 3) {
                dispatch(showSnackbar('Too short search phrease', true))
                return
            }
            setIsSearching(true)
            setNoResults(false)
            const res = await searchAdminCustomers({
                contactSearchPhrase: searchText,
            })
            updateCustomerContactTabSearchResults(res.data)
            setPage(1)
            if (res.status === 204) {
                setNoResults(true)
            }
        } catch (err) {
            dispatch(showSnackbar('Cannot load customers', true))
        } finally {
            setIsSearching(false)
        }
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    return (
        <div>
            <SearchPanel
                initOpen={true}
                placeholder="Customer contact. Enter at least 3 chars..."
                onEnter={() => !isSearching && handleSearch()}
                searchText={searchText}
                onChange={(text) => {
                    setSearchText(text)
                }}
                onClearText={() => setSearchText('')}
                disabled={isSearching}
            ></SearchPanel>

            <CustomerContactsManagePageResults
                noResults={noResults}
                isSearching={isSearching}
                searchResults={customerContactTabSearchResults}
                page={page}
                onPageChange={handlePageChange}
            />
        </div>
    )
}
