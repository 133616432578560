import dayjs from 'dayjs'
import {
    AllInOneReportDataFields,
    AllInOneReportSettings,
    CustomerFiltersState,
    IssueFiltersState,
    IssueOwnerFiltersState,
    ReportNotificationSettings,
    ResolverFiltersState,
    SpecialCasesState,
} from 'types/reportTypes'
import { AllInOneColumn, CcrpResources, PersonBase } from 'types/models'
import { uuid } from 'utils'

export const getInitCustomerState = (
    resources: CcrpResources
): CustomerFiltersState => ({
    accountType:
        resources.customerAccountType.find((r) => r.code === 0) ?? null,
    customerHeadquarters: [],
    customers: [],
})

export const getInitSpecialCases = (): SpecialCasesState => ({
    hasHighImpact: false,
    containmentActionsRequired: false,
    rcaIsRequired: false,
    verificationRequired: false,
})

export const getInitResolverState = (): ResolverFiltersState => ({
    businessUnits: [],
    divisions: [],
    productGroups: [],
    isGlobal: null,
    regions: [],
    countries: [],
    cities: [],
    activities: [],
    resolvingUnitDescriptiveNames: [],
})

export const getInitIssueOwnerState = (): IssueOwnerFiltersState => ({
    regions: [],
    countries: [],
    cities: [],
    managementLevel1: [],
    managementLevel2: [],
    managementLevel3: [],
})

export const getInitIssueState = (
    resources: CcrpResources
): IssueFiltersState => {
    const dateType = resources.reportDateType.find((x) => x.code === 0) ?? null
    return {
        employee: null,
        employeeRole: null,
        issueSources: [resources.issueSourceType.find((r) => r.code === 15)],
        issueStatuses: [],
        issueType: null,
        dateType,
        fromDate: dayjs().subtract(30, 'day').utc().toISOString(),
        toDate: dayjs().utc().toISOString(),
        reportDatePeriodType: resources.reportDatePeriodType.find(
            (r) => r.code === 0
        ),
    }
}

export const getInitState = (
    resources: CcrpResources
): AllInOneReportDataFields => ({
    resolver: getInitResolverState(),
    issueOwner: getInitIssueOwnerState(),
    issue: getInitIssueState(resources),
    specialCases: getInitSpecialCases(),
    columns: getInitColumns(resources),
    customer: getInitCustomerState(resources),
})

export const getInitColumns = (resources: CcrpResources): AllInOneColumn[] => {
    const defaultValue = resources?.allInOneReportColumns.find(
        (x) => x.code === '00'
    )
    return defaultValue ? [defaultValue] : []
}

export const getDefaultSetting = (
    reportId: string,
    isUnitInternal: boolean = false
): AllInOneReportSettings => ({
    date: dayjs().utc().toISOString(),
    id: uuid(),
    reportId,
    isDefault: false,
    isUnitInternal,
    name: '',
    notificationSettings: null,
})

export const getDefaultNotificationSettings = (
    recipient: PersonBase,
    resources: CcrpResources
): ReportNotificationSettings => ({
    isActive: false,
    nextSendDate: null,
    repeatEvery: 1,
    repeatBy: 'w',
    repeatDays: [],
    repeatType: resources.reportDateType.find((x) => x.code === 0) ?? null,
    startDate: dayjs().utc().toISOString(),
    subject: '',
    recipients: [recipient],
})
