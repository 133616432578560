import { Button } from '@mui/material'
import { FC } from 'react'
import { AdminDialog, AdminDialogProps } from '.'

export interface AdminConfirmDialogProps
    extends Omit<AdminDialogProps, 'renderActions'> {
    onAccept: () => void
    acceptLabel?: string
}

export const AdminConfirmDialog: FC<AdminConfirmDialogProps> = (props) => {
    const {
        title,
        open,
        acceptLabel = 'Ok',
        onAccept,
        onClose,
        children,
        ...other
    } = props
    return (
        <AdminDialog
            title={title}
            open={open}
            onClose={onClose}
            renderActions={() => (
                <>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={onAccept} color="secondary">
                        {acceptLabel}
                    </Button>
                </>
            )}
            {...other}
        >
            {children}
        </AdminDialog>
    )
}
