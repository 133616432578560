import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface MultiIssueStatusesSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedItems: FilterBase<number>[]
    onSelectedItemsChanged?: (regions: FilterBase<number>[]) => void
}

export const MultiIssueStatusesSelect = (
    props: MultiIssueStatusesSelectProps
) => {
    const options = useSelector(
        (state: RootState) => state.resources.complaintStatus
    )
    const { selectedItems, onSelectedItemsChanged, disabled, ...other } = props
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: (string | FilterBase<number>)[]
    ) => {
        const items = values as FilterBase<number>[]
        onSelectedItemsChanged && onSelectedItemsChanged([...items])
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedItems}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Issue status"
                    {...dataTestId('ISSUE_STATUS')}
                    {...other}
                />
            )}
        />
    )
}
