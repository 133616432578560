import { Drawer, SwipeableDrawer, Toolbar, useMediaQuery } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { PageLoader, ProtectedRoute } from 'components'
import { msalInstance, OVERRIDE_TOKEN_KEY } from 'configs/authProvider'
import { GlobalNotificationContainer } from 'DeveloperTools/UserGlobalNotification'
import { Fragment, lazy, Suspense, useEffect, useMemo, useState } from 'react'
import { AuthenticationActions } from 'react-aad-msal'
import { useDispatch } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import * as Routes from 'routes'
import { DASHBOARD_LOAD_ISSUES_CALL } from 'store/dashboard'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueRoles } from 'types/enums'
import { MainAppBar, MainMenu } from '.'
import { GlobalSearchPage } from '../pages/GlobalSearch'
import { MAIN_DRAWER_WIDTH, MAIN_MOBILE_MEDIA_QUERY } from './consts'

const ReleaseNotes = lazy(() => import('pages/Release-notes/ReleaseNotes'))
const LegacyReleaseNotes = lazy(
    () => import('pages/Release-notes/LegacyReleaseNotes')
)
const MyActions = lazy(() => import('pages/MyActionsPage'))
const MyIssues = lazy(() => import('pages/MyIssue/MyIssuesPage'))
const MyActionPlanItems = lazy(() => import('pages/MyActionPlanItems'))
const Admin = lazy(() => import('pages/AdminRootPage'))
const EmailThemeEditor = lazy(
    () => import('../DeveloperTools/EmailThemeEditor/EmailThemeEditor')
)
const ReportsRoot = lazy(() => import('Reports/ReportsRoot'))

const UserImpersonate = lazy(() => import('pages/OverrideUser'))
const ManageSupportGroup = lazy(
    () => import('../DeveloperTools/ManageSupportGroups/ManageSupportGroupPage')
)

const GlobalNotificationSettings = lazy(
    () =>
        import(
            'DeveloperTools/UserGlobalNotification/GlobalNotificationSettings'
        )
)

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        const mobileMedia = `@media ${MAIN_MOBILE_MEDIA_QUERY}`
        return {
            main: {
                position: 'relative',
                marginLeft: MAIN_DRAWER_WIDTH,
                marginRight: theme.spacing(1.5),
                paddingLeft: theme.spacing(2),
                paddinRight: theme.spacing(2),
                [mobileMedia]: {
                    marginLeft: 0,
                    paddingLeft: theme.spacing(1),
                    paddinRight: theme.spacing(1),
                },
            },
            banner: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                minHeight: 42,
                width: '100%',
                backgroundColor: theme.palette.yellow.main,
            },
            drawer: {
                zIndex: 0,
                width: MAIN_DRAWER_WIDTH,
                backgroundColor: theme.palette.common.white,
            },
            divider: {
                margin: theme.spacing(1),
            },
            mobile: {},
        }
    },
    { name: 'Root' }
)

export interface RootProps {
    logout?: () => void
}

export const Root = (props: RootProps) => {
    const dispatch = useDispatch()

    const handleLogout = () => {
        msalInstance?.logoutRedirect()
        dispatch({ type: AuthenticationActions.LogoutSuccess })
        sessionStorage.clear()
        localStorage.removeItem(OVERRIDE_TOKEN_KEY)
    }

    const classes = useStyles()
    const [open, setOpen] = useState(false)
    useEffect(() => {
        dispatch({ type: DASHBOARD_LOAD_ISSUES_CALL })
    }, [])
    const isMobile = useMediaQuery(MAIN_MOBILE_MEDIA_QUERY)
    const handleCloseMenu = () => setOpen(false)
    const handleOpenMenu = () => setOpen(true)
    const menu = useMemo(
        () => <MainMenu onClose={handleCloseMenu} mobile={isMobile} />,
        [isMobile]
    )

    return (
        <Fragment>
            <MainAppBar
                mobile={isMobile}
                logout={handleLogout}
                openMenu={handleOpenMenu}
            />
            {isMobile ? (
                <SwipeableDrawer
                    anchor="left"
                    open={open}
                    onClose={handleCloseMenu}
                    onOpen={handleOpenMenu}
                >
                    {menu}
                </SwipeableDrawer>
            ) : (
                <Drawer
                    PaperProps={{ className: classes.drawer }}
                    variant="permanent"
                >
                    <Toolbar />
                    {menu}
                </Drawer>
            )}
            <Toolbar />
            <GlobalNotificationContainer
                className={clsx(classes.main, isMobile && classes.mobile)}
            />

            <div className={clsx(classes.main, isMobile && classes.mobile)}>
                <Switch>
                    <Route
                        path={[Routes.MY_ACTIONS, Routes.DASHBOARD]}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <MyActions />
                            </Suspense>
                        )}
                        exact
                    />
                    <Route
                        path={Routes.MY_ISSUES}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <MyIssues />
                            </Suspense>
                        )}
                        exact
                    />
                    <Route
                        path={Routes.MY_ACTION_PLAN_ITEMS}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <MyActionPlanItems />
                            </Suspense>
                        )}
                        exact
                    />
                    <ProtectedRoute
                        path={Routes.EMAIL_THEME_EDITOR}
                        checkPermissions={(roles) =>
                            roles?.some(
                                (r) => r.roleId === IssueRoles.Developer
                            ) ?? false
                        }
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <EmailThemeEditor />
                            </Suspense>
                        )}
                        exact
                    />
                    <ProtectedRoute
                        path={Routes.MANAGE_SUPPORT_GROUP}
                        checkPermissions={(roles) =>
                            roles?.some(
                                (r) => r.roleId === IssueRoles.Developer
                            ) ?? false
                        }
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <ManageSupportGroup />
                            </Suspense>
                        )}
                        exact
                    />
                    <Route
                        path={Routes.ADMIN_ROOT}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <Admin />
                            </Suspense>
                        )}
                    />
                    <ProtectedRoute
                        path={Routes.USER_IMPERSONATE}
                        checkPermissions={(roles) =>
                            roles?.some(
                                (r) => r.roleId === IssueRoles.Developer
                            ) ?? false
                        }
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <UserImpersonate />
                            </Suspense>
                        )}
                    />
                    <ProtectedRoute
                        path={Routes.NOTIFICATION_SETTINGS}
                        checkPermissions={(roles) =>
                            roles?.some(
                                (r) => r.roleId === IssueRoles.Developer
                            ) ?? false
                        }
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <GlobalNotificationSettings />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={Routes.GLOBAL_SEARCH}
                        render={() => <GlobalSearchPage />}
                    />
                    <Route
                        path={Routes.RELEASE_NOTES}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <ReleaseNotes />
                            </Suspense>
                        )}
                        exact
                    />
                    <Route
                        path={Routes.LEGACY_RELEASE_NOTES}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <LegacyReleaseNotes />
                            </Suspense>
                        )}
                        exact
                    />
                    <Route
                        path={Routes.RAPORTS_ROOT}
                        render={() => (
                            <Suspense fallback={<PageLoader overlay={false} />}>
                                <ReportsRoot />
                            </Suspense>
                        )}
                    />
                </Switch>
            </div>
        </Fragment>
    )
}

export default Root
