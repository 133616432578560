import { useSelector, useDispatch } from 'react-redux'
import Summary, { SummaryRole } from './Summary'
import AccordionActions from '../../components/Base/AccordionActions'
import {
    selectCurrentRoles,
    IssueStoreState,
    generateSummaryReport,
    generateOverviewInPowerPoint,
} from 'store/issue/index'
import { RootState } from 'store/types'
import { CcrpResources } from '../../types/models'
import { FilterBase } from '../../types/models'
import { IssueRoles, IssueStatuses } from 'types/enums'
import { useHistory } from 'react-router'
import { urlBuilder } from 'utils'
import { ISSUE_VIEW_ACTION_LOGS } from '../routes'
import { appInsights } from '../../configs/appInsights'

const SummaryContainer = () => {
    const dispatch = useDispatch()
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const history = useHistory()
    const {
        issueStatusId,
        captureSection,
        assignSection,
        closingSection,
        isDeleted,
        context,
        dates,
        issueNumber: number,
        selectedRolesCodes,
        warrnings,
    } = issue
    const myRoles = context?.myRoles
    const { roleCode } = useSelector<RootState, CcrpResources>(
        (state) => state.resources
    )
    const roles: SummaryRole[] = (context?.myRoles ?? [])
        .map((code: IssueRoles) => ({
            roleCode: code,
            name:
                roleCode.find((r: FilterBase<number>) => r.code === code)
                    ?.name ?? '',
            selected: selectedRolesCodes?.some((x) => x === code),
        }))
        .filter((x) => Boolean(x?.name))
    if (!issue.captureSection && !issue.dates) {
        return null
    }

    const { subject, highImpact, issueType, sfdcAdditionalInfo } =
        captureSection
    const isHighImpact = highImpact ? Boolean(highImpact.isHighImpact) : false
    let isOverdue = false
    if (
        context &&
        context.actions &&
        0 < context.actions.length &&
        issueStatusId !== IssueStatuses.Completed &&
        issueStatusId !== IssueStatuses.Draft &&
        issueStatusId !== IssueStatuses.New
    ) {
        isOverdue = context.actions.find((item) => item.isActionOverdue)
            ? true
            : false
    }
    const handleRoleSelect = (roleId: IssueRoles) => {
        if (
            selectedRolesCodes &&
            selectedRolesCodes.some((x) => x === roleId)
        ) {
            dispatch(
                selectCurrentRoles([
                    ...selectedRolesCodes.filter((x) => x !== roleId),
                ])
            )
            return
        }
        dispatch(selectCurrentRoles([...selectedRolesCodes, roleId]))
    }

    const canChangeRoles =
        issueStatusId !== IssueStatuses.Draft && myRoles && 1 < myRoles.length
    const handleOpenActionsLogs = () => {
        appInsights.trackEvent({ name: 'Open actions logs' })
        history.push(
            urlBuilder(ISSUE_VIEW_ACTION_LOGS, {
                id: issue.issueId,
            })
        )
    }
    const handleGenerateSummaryRaport = () => dispatch(generateSummaryReport())
    const handleGenerateOverviewInPowerPoint = () =>
        dispatch(generateOverviewInPowerPoint())
    return (
        <>
            <Summary
                number={number}
                issueStatus={issueStatusId}
                subject={subject}
                isOverdue={isOverdue}
                isHighImpact={isHighImpact}
                roles={roles}
                issueType={issueType}
                dates={dates}
                isDeleted={isDeleted}
                sfdcUrl={sfdcAdditionalInfo?.urlToCase}
                sfdcCaseNumber={sfdcAdditionalInfo?.caseNumber}
                solutionWillBeValidatedByCoordinator={
                    assignSection?.solutionWillBeValidatedByCoordinator ?? false
                }
                isWaitingForExecution={
                    closingSection?.isWaitingForExecution ?? false
                }
                selectRoleCode={handleRoleSelect}
                warrnings={warrnings}
                context={context}
                actions={
                    canChangeRoles ? (
                        <AccordionActions></AccordionActions>
                    ) : null
                }
                openActionLogs={handleOpenActionsLogs}
                onGenerateSummaryRaport={handleGenerateSummaryRaport}
                onGenerateOverviewInPowerPoint={
                    handleGenerateOverviewInPowerPoint
                }
            />
        </>
    )
}

export default SummaryContainer
