import { Typography } from '@mui/material'
import {
    AdminNode,
    Button,
    FormControlLabel,
    GenericNodeBase,
    Switch,
    TextField,
    TextFieldLimit,
} from 'components'
import {
    ChangeEventHandler,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react'
import { dataTestId } from 'utils'
import { AdminDialog, AdminDialogProps } from '..'
import { FilterBase } from '../../../types/models'

export type TreeViewManageDialogVariant = 'deactive' | 'edit' | 'add'

export interface TreeViewManageDialogProps
    extends Omit<AdminDialogProps, 'title' | 'renderActions'> {
    variant: TreeViewManageDialogVariant | null
    node: GenericNodeBase
    onAddNode?: (parentNode: GenericNodeBase, newNode: GenericNodeBase) => void
    onEditNode?: (node: GenericNodeBase) => void
    onDeleteNode?: (node: GenericNodeBase) => void
    nodeNameLabel: string
    canEditAllNodes: boolean
    productGroup: FilterBase
}

export const TreeViewManageDialog = (props: TreeViewManageDialogProps) => {
    const {
        open,
        variant,
        node,
        onClose,
        onAddNode,
        onDeleteNode,
        onEditNode,
        nodeNameLabel,
        canEditAllNodes,
        productGroup,
        ...other
    } = props
    const [nodeName, setNodeName] = useState('')
    const [isActive, setIsActive] = useState(true)
    useEffect(() => {
        if (!node && !variant) {
            setNodeName('')
            setIsActive(true)
        } else {
            if (variant === 'edit') {
                setNodeName(node.name)
                setIsActive(node?.isActive ?? false)
            } else if (variant === 'add') {
                setNodeName('')
                setIsActive(true)
            }
        }
    }, [node, variant])
    const handleNodeNameChange: ChangeEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => setNodeName(e.target.value)
    const handleNodeAdd = useCallback(() => {
        if (onAddNode) {
            onAddNode(node, {
                parentId: node?.id ?? null,
                name: nodeName,
                isActive: true,
                id: -1,
            })
            onClose()
        }
    }, [node, onAddNode, onClose, nodeName])
    const title = useMemo(() => {
        switch (variant) {
            case 'deactive':
                return `Deactivate node '${node.name}'`
            case 'edit':
                return `Edit node '${node.name}'`
            case 'add':
                return node
                    ? `Add child to '${node.name}'`
                    : `Add node to ${productGroup?.name} (${productGroup.code})`
            default:
                return ''
        }
    }, [variant, node])
    const renderActions = useCallback(() => {
        switch (variant) {
            case 'deactive':
                return (
                    <>
                        <Button
                            onClick={onClose}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                onDeleteNode && onDeleteNode({ ...node })
                                onClose()
                            }}
                            color="secondary"
                            {...dataTestId(`DEACTIVATE_BUTTON `)}
                        >
                            Deactivate
                        </Button>
                    </>
                )
            case 'edit':
                return (
                    <>
                        <Button
                            onClick={onClose}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                const results = node
                                node.name = nodeName
                                node.isActive = isActive
                                onEditNode && onEditNode(results)
                                onClose()
                            }}
                            color="secondary"
                            {...dataTestId(`EDIT_BUTTON `)}
                            disabled={nodeName?.length > 100}
                        >
                            Edit
                        </Button>
                    </>
                )
            case 'add':
                return (
                    <>
                        <Button
                            onClick={onClose}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            color="secondary"
                            onClick={handleNodeAdd}
                            {...dataTestId(`ADD_BUTTON `)}
                            disabled={nodeName?.length > 100}
                        >
                            Add
                        </Button>
                    </>
                )
            default:
                return null
        }
    }, [variant, nodeName, isActive, node])
    return (
        <AdminDialog
            open={open}
            title={title}
            onClose={onClose}
            renderActions={renderActions}
            disabledMobile={true}
            {...other}
        >
            <div style={{ minHeight: 148 }}>
                {variant === 'deactive' && (
                    <Typography variant="body1">
                        Do you want to deactivate this node?
                    </Typography>
                )}
                {(variant === 'edit' || variant === 'add') && (
                    <TextFieldLimit
                        disabled={false}
                        currentLength={nodeName?.length}
                        maxLength={100}
                    >
                        <TextField
                            autoFocus
                            label={nodeNameLabel}
                            value={nodeName}
                            placeholder="Node name"
                            onChange={handleNodeNameChange}
                            {...dataTestId(`PG_RCA_CATEGORY_NAME_TEXT_FIELD `)}
                            error={nodeName?.length > 100}
                        />
                    </TextFieldLimit>
                )}
                {variant === 'edit' &&
                    ((node as AdminNode).canEdit || canEditAllNodes) && (
                        <FormControlLabel
                            control={
                                <>
                                    <Switch
                                        checked={isActive}
                                        onChange={(e, checked) =>
                                            setIsActive(checked)
                                        }
                                        {...dataTestId(`IS_ACTIVE `)}
                                    />
                                </>
                            }
                            label="Is active"
                        />
                    )}
            </div>
        </AdminDialog>
    )
}
