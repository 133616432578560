import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ExpandableCardView } from 'components'
import { getPrettyDateTime } from 'utils'
import { ActionPlanItemLogProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        header: {
            padding: theme.spacing(2, 2, 1, 2),
        },
    }),
    { name: 'ActionPlanItemLog' }
)

export const ActionPlanItemLog = (props: ActionPlanItemLogProps) => {
    const { log } = props
    const { createDate, creatorFullName, subject, body } = log
    const classes = useStyles()

    const renderContent = () => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: body,
                }}
            ></div>
        )
    }

    return (
        <ExpandableCardView
            renderHeader={() => (
                <div className={classes.header}>
                    <Typography variant="body2">
                        {getPrettyDateTime(createDate)}
                    </Typography>
                    <Typography variant="body1">{creatorFullName}</Typography>
                    <Typography variant="body1">{subject}</Typography>
                </div>
            )}
            renderContent={renderContent}
            collapsedSize={32}
        />
    )
}

export default ActionPlanItemLog
