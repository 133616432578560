import { FormGroup, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Checkbox, FormControlLabel, GridContainer } from 'components'
import { useFieldWithError } from 'Issue/matrix'
import { HTMLAttributes, useEffect, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueValuesKeys } from 'types/enums'
import { CaptureSection, ResolvingUnit } from 'types/issueTypes'
import { dataTestId } from 'utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
        },
        control: {
            marginRight: theme.spacing(2.5),
        },
    }),
    { name: 'Classifications' }
)

export interface OnBehalfOfProps extends HTMLAttributes<HTMLDivElement> {
    onCaptureSectionChanged: (
        propName: keyof CaptureSection,
        value: ResolvingUnit
    ) => void
    captureSection: CaptureSection
    disabled: boolean
}

const Classifications = (props: OnBehalfOfProps) => {
    const classes = useStyles()
    const { onCaptureSectionChanged, captureSection, disabled, ...other } =
        props
    const [state, setState] = useState<ResolvingUnit>()

    useEffect(() => {
        setState({ ...captureSection?.resolvingUnit })
    }, [captureSection?.resolvingUnit])

    const classificationsField = useFieldWithError(
        IssueValuesKeys.Classifications,
        {
            disabled,
        }
    )

    return (
        <div {...other}>
            <Typography variant="h6">Classifications</Typography>
            <GridContainer className={classes.field}>
                <FormGroup row={true}>
                    {state?.classifications?.map((i) => (
                        <FormControlLabel
                            key={i.categoryName}
                            className={classes.control}
                            label={i.categoryName}
                            control={<Checkbox />}
                            checked={i.isSelected}
                            onChange={(_e, checked) => {
                                onCaptureSectionChanged('resolvingUnit', {
                                    ...state,
                                    classifications: state?.classifications.map(
                                        (c) => {
                                            if (
                                                c.categoryName ===
                                                i.categoryName
                                            )
                                                return {
                                                    ...c,
                                                    isSelected: checked,
                                                }
                                            return c
                                        }
                                    ),
                                })
                            }}
                            {...classificationsField}
                            {...dataTestId(
                                `CLASSIFICATION_CHECKBOX_${i.categoryName}`
                            )}
                        />
                    ))}
                </FormGroup>
            </GridContainer>
        </div>
    )
}

export default Classifications
