import { default as Loading } from './svg/Loading.svg'

export interface LoadingSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const LoadingSvg = (props: LoadingSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? Loading}
            width={width}
            height={height}
            alt={alt ?? 'Loading…'}
            {...other}
        />
    )
}

export default LoadingSvg
