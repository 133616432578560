import { Memory } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { ProductServiceGroups } from 'types/issueTypes'
import { FieldChip } from '../../../components'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(1, 0),
        },
        name: {
            display: 'inline-flex',
            justifyContent: 'center',
            fontWeight: theme.typography.fontWeightBold as number,
            marginBottom: theme.spacing(1),
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    {
        name: 'ProductServiceGroupn',
    }
)

export interface ProductServiceGroupProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    details: ProductServiceGroups
}

export const ProductServiceGroup = (props: ProductServiceGroupProps) => {
    const { details, className, ...other } = props
    const classes = useStyles(props)
    return (
        <div className={clsx(className, classes.root)} {...other}>
            <Typography className={classes.name} variant="body2">
                <Memory className={classes.icon} fontSize="small" />{' '}
                {details.name}
            </Typography>
            <div>
                {details?.products?.map((p, i) => (
                    <FieldChip key={i} label={p} />
                ))}
            </div>
        </div>
    )
}

export default ProductServiceGroup
