import axios from 'axios'
import { call, cancelled, takeLatest, put } from 'redux-saga/effects'
import {
    getIssueOwnerDone,
    getIssueOwnerRequested,
    ISSUE_NEW_INIT,
    loadMatrixScheme,
    setIssueActionFail,
} from '..'
import { IssueRoles, IssueStatuses } from 'types/enums'
import { msalInstance } from 'configs/authProvider'
import { getEmployee } from 'api/employees'

export function* initNewDraft() {
    const cancelSource = axios.CancelToken.source()
    try {
        yield put(getIssueOwnerRequested())
        const { data: issueOwner } = yield call(
            getEmployee,
            msalInstance?.getActiveAccount()?.username,
            cancelSource.token
        )
        yield put(getIssueOwnerDone(issueOwner))
        yield put(
            loadMatrixScheme(0, [IssueRoles.Issue_Owner], IssueStatuses.Draft)
        )
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_NEW_INIT, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* initNewDraftSaga() {
    yield takeLatest([ISSUE_NEW_INIT], initNewDraft)
}
