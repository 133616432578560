import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useState } from 'react'
import { FilterBase } from '../../types/models'

export interface FieldState<T> {
    loading: boolean
    error?: AxiosError | any
    options: Array<T>
    helperText?: string
}

export const getInitState = <T>(
    initState: Partial<FieldState<T>> = {}
): FieldState<T> => ({
    loading: false,
    options: [],
    error: null,
    helperText: null,
    ...initState,
})

export const useFieldState = <T>(
    initState?: Partial<FieldState<T>>
): [FieldState<T>, (stateChunk: Partial<FieldState<T>>) => void] => {
    const [state, setState] = useState<FieldState<T>>(getInitState(initState))
    const updateState = (stateChunk: Partial<FieldState<T>>) =>
        setState((prev) => ({ ...prev, ...stateChunk }))
    return [state, updateState]
}

export const getOptionLabel = <T>(option: FilterBase<T>): string => {
    return option ? clsx(option?.code && `(${option?.code})`, option?.name) : ''
}

export const getShortOptionLabel = <T>(option: FilterBase<T>): string => {
    return option?.name ?? ''
}

export const getOptionSelected = <T>(
    option: FilterBase<T>,
    value: FilterBase<T>
): boolean => option?.code === value?.code
