import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { PageLoader } from '../components'
import { ROOT } from '../routes'
import { downloadAnalzyer } from '../store/dashboard'

export interface AnalyzerDownloaderPageProps {
    isUnitInternal?: boolean
}

export const AnalyzerDownloaderPage = (props: AnalyzerDownloaderPageProps) => {
    const { isUnitInternal = false } = props
    const history = useHistory()
    const dispatch = useDispatch()
    dispatch(downloadAnalzyer(isUnitInternal))
    history.replace(ROOT)
    return <PageLoader />
}
