import {
    generateHeaderForProcessOwnerCard,
    ProcessOwnersManagePageExcelExport,
    ProcessOwnersManagePageResultsProps,
} from 'Admin'
import { SearchResultsWithPagination } from 'components'
import { useProfile } from 'hooks'
import { useMemo } from 'react'
import { AdminProcessOwner } from 'types/adminTypes'
import { IssueRoles } from 'types/enums'
import { dataTestId, getPartOfTable } from 'utils'
import { ProcessOwnersManagePageCard2 } from './ProcessOwnersManagePageCard2'

export const ADMIN_PROCESS_OWNERS_PAGE_SIZE = 20

export const ProcessOwnersManagePageResults = (
    props: ProcessOwnersManagePageResultsProps
) => {
    const {
        noResults = false,
        isSearching,
        searchResults,
        openDialog,
        deleteProcessOwner,

        page = 1,
        onPageChange,
    } = props
    const profile = useProfile()
    const isSupportDesk = profile.roles.some(
        (r) => r.roleId === IssueRoles.Support_Desk
    )
    const cards = useMemo(() => {
        let results: AdminProcessOwner[] = []

        if (0 < searchResults.length) {
            results = getPartOfTable<AdminProcessOwner>(
                searchResults,
                ADMIN_PROCESS_OWNERS_PAGE_SIZE,
                page
            )
        }

        return results.map((adminProcessOwner) => (
            <ProcessOwnersManagePageCard2
                isSupportDesk={isSupportDesk}
                adminProcessOwner={adminProcessOwner}
                openDialog={openDialog}
                deleteProcessOwner={deleteProcessOwner}
                key={adminProcessOwner?.reactId}
                {...dataTestId(
                    `PO_CONTAINER_(${generateHeaderForProcessOwnerCard(
                        adminProcessOwner.organizationalInfo
                    )})`
                )}
            />
        ))
    }, [page, searchResults, isSearching])

    return (
        <SearchResultsWithPagination
            isSearching={isSearching}
            page={page}
            pageSize={ADMIN_PROCESS_OWNERS_PAGE_SIZE}
            itemCount={searchResults.length}
            onPageChange={onPageChange}
            renderAction={() => (
                <ProcessOwnersManagePageExcelExport
                    adminProcessOwners={searchResults}
                />
            )}
            errorTitle="No results!"
            errorSubTitle="Change search criteria and try again."
            errorVisible={noResults}
        >
            {cards}
        </SearchResultsWithPagination>
    )
}
