import { InputLabel, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    deleteActionItemAttachment,
    getActionItemAttachment,
    uploadActionItemAttachments,
} from 'api'
import { useContext, useMemo, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    ActionItemAttachment,
    ActionItemAttachmentType,
} from 'types/actionPlanTypes'
import { Button, RoundedButton } from '..'
import { ActionPlanContext } from '../../ActionPlan/context/ActionPlanContext'
import AttachmentNext from './AttachmentNext'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            color: (props: AttachmentsNextProps) =>
                props.disabled ? theme.palette.action.disabled : 'inherit',
        },
        content: {
            backgroundColor: theme.palette.grey[100],
            border: `2px dashed ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        dragAndDrop: {},
        addButton: {
            marginTop: theme.spacing(2),
            height: '32px',
        },
        noFiles: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(4),
        },
        files: {},
        filesActions: {
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
    { name: 'AttachmentsNext' }
)
export interface AttachmentsNextProps {
    attachments: ActionItemAttachment[]
    attachmentType: ActionItemAttachmentType
    addAttachment: (attachment: ActionItemAttachment) => void
    removeAttachment: (attachment: ActionItemAttachment) => void
    disabled?: boolean
    label?: string
}

export const AttachmentsNext = (props: AttachmentsNextProps) => {
    const { catchErrorsAndLoading } = useContext(ActionPlanContext)
    const {
        label,
        disabled,
        attachments,
        attachmentType,
        addAttachment,
        removeAttachment,
    } = props

    const { actionPlanId } = useParams<{ actionPlanId: string }>()
    const actionPlanIdNumber = Number(actionPlanId)

    const [error, setError] = useState<unknown>(null)
    const classes = useStyles(props)

    const { getRootProps, getInputProps, open } = useDropzone({
        disabled,
        noClick: true,
        noKeyboard: true,
        onDrop: () => {
            setError(null)
        },
        onDropAccepted: (newFiles) => {
            const progressCallback = (e) => {}

            newFiles.forEach((fileData: any) => {
                catchErrorsAndLoading(async () => {
                    const res = await uploadActionItemAttachments(
                        actionPlanIdNumber,
                        attachmentType,
                        fileData,
                        progressCallback
                    )

                    addAttachment(res.data)
                }, `Uploading`)
            })
        },
        onDropRejected: (rejectedFiles) => {
            setError(rejectedFiles[0]?.errors[0]?.message)
        },
        accept: [
            'application/pdf',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'image/png',
            'image/jpeg',
            'text/csv',
        ].join(', '),
        maxSize: 62914560,
    })

    const downloadAttachment = (attachment: ActionItemAttachment) => {
        catchErrorsAndLoading(async () => {
            const res = await getActionItemAttachment(
                actionPlanIdNumber,
                attachment.id
            )
            let a = document.createElement('a')
            document.body.appendChild(a)
            a.style.display = 'none'
            let url = window.URL.createObjectURL(res.data)
            a.href = url
            a.download = attachment.displayName
            a.click()
            window.URL.revokeObjectURL(url)
        }, `Downloading ${attachment.displayName}`)
    }

    // when backend ready
    const deleteAttachment = (attachment: ActionItemAttachment) => {
        catchErrorsAndLoading(async () => {
            await deleteActionItemAttachment(actionPlanIdNumber, attachment.id)
            removeAttachment(attachment)
        }, `Deleting ${attachment.displayName}`)
    }

    const attachmentsMap = useMemo(
        () =>
            attachments.map((attachment: ActionItemAttachment) => (
                <AttachmentNext
                    disabled={disabled}
                    key={attachment.id}
                    attachment={attachment}
                    deleteAttachment={deleteAttachment}
                    downloadAttachment={downloadAttachment}
                />
            )),
        [attachments]
    )

    return (
        <div className={classes.root}>
            <InputLabel disabled={disabled}>{label}</InputLabel>
            <section className={classes.content}>
                <div {...getRootProps({ className: classes.dragAndDrop })}>
                    <input {...getInputProps()} />
                    {attachments.length === 0 ? (
                        <div className={classes.noFiles}>
                            <Typography variant="body1">
                                Drop files here to upload
                            </Typography>
                            {!disabled && (
                                <RoundedButton
                                    variant="contained"
                                    color="secondary"
                                    className={classes.addButton}
                                    onClick={open}
                                    size="large"
                                >
                                    Add file
                                </RoundedButton>
                            )}
                        </div>
                    ) : (
                        <div className={classes.files}>
                            {attachmentsMap}
                            <div className={classes.filesActions}>
                                <Button
                                    color="secondary"
                                    onClick={open}
                                    disabled={disabled}
                                >
                                    Add files
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <Typography variant="body2">
                Accepted file types: *.docx, *.xlsx, *.xls, *.pdf, *.png,
                *.jpeg, *.csv. Max file size 100 mb.
            </Typography>
            {error && (
                <Typography variant="body2" color="secondary">
                    {error}
                </Typography>
            )}
        </div>
    )
}

export default AttachmentsNext
