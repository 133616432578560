import { PersonOutlined, SupervisorAccountOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
    AdminEmployeeChip,
    AdminEmployeesChipsSimple,
    ProcessOwnerInfo,
    ResolvingUnitsManagePageCardProps,
    RUAdminChip,
} from 'Admin'
import { AdminEmployeeLabel } from 'Admin/components/AdminEmployeesChips/AdminEmployeeLabel'
import { AdminTileContainer } from 'Admin/components/AdminTileContainer'
import { ProcessOwnerActions } from 'Admin/components/ProcessOwnerActions'
import { deleteEmailFromAdminResolvingUnit } from 'api/admin'
import { ChipLabel, InfoBar } from 'components'
import { usePermissions } from 'hooks'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AdminEmployeeTypeEnum, AdminResolvingUnit } from 'types/adminTypes'
import { IssueRoles } from 'types/enums'
import { AdminResolvingUnitDeleteQuery } from 'types/queries'
import { arrayToStringLine, dataTestId, EMPTY_VALUE_PLACEHOLDER } from 'utils'
import CardInformation from './NewResolvingUnitsPageCard/CardInformation'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        border: {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        users: {
            display: 'flex',
            flexWrap: 'wrap',
            margin: theme.spacing(1, 1.5),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: theme.spacing(0, 1),
        },
        newConcept: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        userConcept: {
            margin: theme.spacing(0.5, 0.5),
        },
        employee: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
    }),
    {
        name: 'ResolvingUnitsManagePageCard',
    }
)

export const ResolvingUnitsManagePageCard2 = (
    props: ResolvingUnitsManagePageCardProps
) => {
    const dispatch = useDispatch()
    const {
        adminResolvingUnit,
        togglePerson,
        personsIds,
        addPerson,
        updateResolvingUnitsLocal,
        profile,
    } = props
    const {
        id,
        balId,
        isActive,
        processOwners,
        coordinators,
        divisionCode,
        businessUnitCode,
        productGroupCode,
        address,
        isGlobal,
        isInTransitionPeriod,
    } = adminResolvingUnit
    const [showProcessOwners, setShowProcessOwners] = useState(false)
    const permissions = usePermissions(
        {
            division: { code: divisionCode, name: '' },
            businessUnit: { code: businessUnitCode, name: '' },
            productGroup: { code: productGroupCode, name: '' },
            country: address?.countryCode ?? null,
            region: address?.regionCode ?? null,
            abacusCode: null,
            genericCode: null,
        },
        isGlobal
    )
    const canEdit = useMemo(() => {
        const { balIds, isSupportDesk, isProcessOwner } = permissions
        return isSupportDesk || balIds.includes(balId) || isProcessOwner
    }, [permissions, balId])

    const classes = useStyles()

    const headerTitle = arrayToStringLine([
        divisionCode,
        businessUnitCode,
        productGroupCode,
    ])

    const checked = personsIds.includes(adminResolvingUnit.id)

    const results = useMemo(() => {
        const { address, locationActivity } = adminResolvingUnit
        return !isGlobal
            ? [
                  {
                      label: 'Address',
                      text: address?.full ?? EMPTY_VALUE_PLACEHOLDER,
                  },
                  {
                      label: 'Activity',
                      text: `${
                          locationActivity?.name
                              ? locationActivity.name
                              : EMPTY_VALUE_PLACEHOLDER
                      }`,
                  },
              ]
            : []
    }, [adminResolvingUnit])

    const deletePersonFromResolvingUnit = async (
        query: AdminResolvingUnitDeleteQuery
    ) => {
        const { personEmail, ruPersonType } = query
        try {
            await deleteEmailFromAdminResolvingUnit(query)
            const updatedRU: AdminResolvingUnit = {
                ...adminResolvingUnit,
            }
            if (ruPersonType === AdminEmployeeTypeEnum.Coordinator) {
                const index = adminResolvingUnit.coordinators.findIndex(
                    (item) => item.email === personEmail
                )
                0 <= index && updatedRU.coordinators.splice(index, 1)
            } else if (ruPersonType === AdminEmployeeTypeEnum.RUProcessOwner) {
                const index = adminResolvingUnit.processOwners.findIndex(
                    (item) => item.email === personEmail
                )
                0 <= index && updatedRU.processOwners.splice(index, 1)
            }
            dispatch(showSnackbar('Successfully deleted'))
            updateResolvingUnitsLocal([updatedRU])
        } catch (err) {
            dispatch(showSnackbar('Deletion failed', true))
        }
    }
    return (
        <>
            <AdminTileContainer
                {...dataTestId(`RU_CONTAINER_${balId}`)}
                headerLabel={headerTitle}
                checked={checked}
                disabled={!canEdit}
                headerContent={
                    <>
                        {isInTransitionPeriod && (
                            <InfoBar status="info">
                                Resolving unit is in transition period
                            </InfoBar>
                        )}
                        {!isActive && (
                            <InfoBar status="error">
                                Resolving unit isn't active
                            </InfoBar>
                        )}
                        <CardInformation informationResult={results} />
                    </>
                }
                onChangeChecked={() => togglePerson(adminResolvingUnit.id)}
                renderTags={() => (
                    <>
                        <ChipLabel
                            label={balId}
                            variant="grey"
                            {...dataTestId('BAL_ID')}
                        />
                        {!isActive && (
                            <ChipLabel label="Inactive RU" variant="red" />
                        )}
                    </>
                )}
                mainContent={
                    (processOwners?.length > 0 || coordinators?.length > 0) && (
                        <>
                            <div className={classes.users}>
                                <AdminEmployeesChipsSimple
                                    newConceptRender={(props) => (
                                        <div className={classes.newConcept}>
                                            {props}
                                        </div>
                                    )}
                                >
                                    {Boolean(processOwners?.length) ? (
                                        <div
                                            {...dataTestId(
                                                'RU_PROCESS_OWNERS_INFO'
                                            )}
                                            className={classes.employee}
                                        >
                                            <AdminEmployeeLabel
                                                name="RU Process Owners"
                                                icon={() => (
                                                    <SupervisorAccountOutlined
                                                        className={classes.icon}
                                                    />
                                                )}
                                            />

                                            {processOwners?.map((employee) => {
                                                const isLast =
                                                    profile.roles.some((r) =>
                                                        [
                                                            IssueRoles.Support_Desk,
                                                            IssueRoles.Developer,
                                                        ].includes(r.roleId)
                                                    )
                                                        ? false
                                                        : processOwners.length ===
                                                          1
                                                return (
                                                    <RUAdminChip
                                                        className={
                                                            classes.userConcept
                                                        }
                                                        disabled={!canEdit}
                                                        onDelete={() =>
                                                            deletePersonFromResolvingUnit(
                                                                {
                                                                    personEmail:
                                                                        employee?.email,
                                                                    resolvingUnitId:
                                                                        id,
                                                                    ruPersonType:
                                                                        AdminEmployeeTypeEnum.RUProcessOwner,
                                                                }
                                                            )
                                                        }
                                                        employee={employee}
                                                        isLast={isLast}
                                                        key={employee?.email}
                                                    />
                                                )
                                            })}
                                        </div>
                                    ) : null}
                                    {Boolean(coordinators?.length) ? (
                                        <div
                                            {...dataTestId('COORDINATORS_INFO')}
                                            className={classes.employee}
                                        >
                                            <AdminEmployeeLabel
                                                name="Coordinators"
                                                icon={() => (
                                                    <PersonOutlined
                                                        className={classes.icon}
                                                    />
                                                )}
                                            />

                                            {coordinators.map((employee) => (
                                                <AdminEmployeeChip
                                                    className={
                                                        classes.userConcept
                                                    }
                                                    disabled={!canEdit}
                                                    key={employee?.email}
                                                    employee={employee}
                                                    onDelete={() =>
                                                        deletePersonFromResolvingUnit(
                                                            {
                                                                personEmail:
                                                                    employee?.email,
                                                                resolvingUnitId:
                                                                    id,
                                                                ruPersonType:
                                                                    AdminEmployeeTypeEnum.Coordinator,
                                                            }
                                                        )
                                                    }
                                                />
                                            ))}
                                        </div>
                                    ) : null}
                                </AdminEmployeesChipsSimple>
                            </div>
                            <div className={classes.border} />
                        </>
                    )
                }
                bottomContent={
                    productGroupCode && (
                        <ProcessOwnerInfo
                            newConcept={true}
                            divisionCode={divisionCode}
                            businessUnitCode={businessUnitCode}
                            productGroupCode={productGroupCode}
                            countryCode={address?.countryCode}
                            regionCode={address?.regionCode}
                            showProcessOwners={showProcessOwners}
                        />
                    )
                }
                actions={
                    <ProcessOwnerActions
                        addPerson={() => addPerson(adminResolvingUnit.id)}
                        canEdit={canEdit}
                        isActive={isActive}
                        setShowProcessOwners={(value) =>
                            setShowProcessOwners(value)
                        }
                        showProcessOwners={showProcessOwners}
                        showTooltipText={`'RU Process owners assigned to the Resolving Unit as well as all process owners listed under "Show process owners" button are allowed to manage this specific unit.'`}
                    />
                }
            />
        </>
    )
}
