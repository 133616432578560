import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FlexGrow } from 'components'
import { ReactNode, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            minHeight: 32,
            margin: theme.spacing(2, 0),
        },
    }),
    { name: 'SearchResultsHeader' }
)

export interface SearchResultsHeaderProps {
    itemCount?: number | null
    isSearching: boolean
    title?: string
    renderAction?: () => ReactNode
}

export const SearchResultsHeader = (props: SearchResultsHeaderProps) => {
    const {
        itemCount = null,
        title = 'Search results',
        renderAction,
        isSearching,
    } = props
    const classes = useStyles()
    const fullTitle = useMemo(() => {
        if (isSearching) {
            return title
        }
        const countText = Number.isInteger(itemCount) ? `(${itemCount})` : ''
        return `${title} ${countText}`.trim()
    }, [title, itemCount, isSearching])
    return (
        <div className={classes.root}>
            <Typography variant="h6">{fullTitle}</Typography>
            <FlexGrow />
            {renderAction && renderAction()}
        </div>
    )
}
