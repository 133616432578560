import { MyActionsStoreState, initState } from './initState'
import * as ACTIONS from './actionTypes'
import { AnyAction } from 'redux'
import { AuthenticationActions } from 'react-aad-msal'

import { SET_RESOURCES } from '../resources'
import { FilterBase } from 'types/models'
import { NEW_DASHBOARD } from 'features'
import { MyAction, MyAction2 } from 'types/myDashboard'

const createActionDone = () => (state: MyActionsStoreState) => ({
    ...state,
    loading: false,
    loadingMore: false,
    updating: false,
    loadingDesc: null,
    error: null,
})

const createActionFail =
    () => (state: MyActionsStoreState, action: AnyAction) => ({
        ...state,
        loading: false,
        loadingMore: false,
        updating: false,
        loadingDesc: null,
        error: action.payload,
    })

const createActionCall = (label: string) => (state: MyActionsStoreState) => ({
    ...state,
    loading: false,
    updating: true,
    loadingDesc: label,
    error: null,
})

const createLoadingActionCall =
    (label: string) => (state: MyActionsStoreState) => ({
        ...state,
        loading: true,
        updating: false,
        loadingDesc: label,
        error: null,
    })

const reducersIndex: {
    [key: string]: (
        state: MyActionsStoreState,
        action: AnyAction
    ) => MyActionsStoreState
} = {
    [ACTIONS.MY_ACTIONS_LOADING]: createLoadingActionCall(
        'Loading your issues…'
    ),
    [ACTIONS.MY_ACTIONS_RETURN_TO_IO_CALL]: createActionCall(
        'Returning issue to IO…'
    ),
    [ACTIONS.MY_ACTIONS_CONFIRM_HI_CALL]: createActionCall(
        'Confirming high impact…'
    ),
    [ACTIONS.MY_ACTIONS_DOWNGRADE_HI_CALL]: createActionCall(
        'Downgrading issue impact…'
    ),
    [ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_CALL]: createActionCall(
        'Verifying containment action…'
    ),
    [ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_CALL]: createActionCall(
        'Verifying resolution…'
    ),
    [ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_CALL]: createActionCall(
        'Rejecting resolution…'
    ),
    [ACTIONS.MY_ACTIONS_REOPEN_ISSUE_CALL]:
        createActionCall('Reopening issue…'),
    [ACTIONS.MY_ACTIONS_RETURN_TO_IO_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_CONFIRM_HI_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_DOWNGRADE_HI_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_REOPEN_ISSUE_DONE]: createActionDone(),
    [ACTIONS.MY_ACTIONS_RETURN_TO_IO_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_LOAD_ERROR]: createActionFail(),
    [ACTIONS.MY_ACTIONS_CONFIRM_HI_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_DOWNGRADE_HI_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_VERIFY_CONTAINMENT_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_VERIFY_RESOLUTION_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_REJECT_RESOLUTION_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_REOPEN_ISSUE_FAIL]: createActionFail(),
    [ACTIONS.MY_ACTIONS_LOAD_DONE]: (state, action) => ({
        ...state,
        loadingMore: false,
        loading: false,
        updating: false,
        error: null as any,
        items: action.payload,
        loadingDesc: null as string,
    }),
    [ACTIONS.MY_ACTION_UPDATE_BY_ID]: (state, action) => {
        const index = state.items.findIndex(
            (item) => item.issueId === action.payload.id
        )
        if (0 <= index) {
            state.items[index] = { ...action.payload }
        }
        return { ...state }
    },
    [ACTIONS.MY_ACTION_REMOVE_BY_ID]: (state, action) => ({
        ...state,
        items: state.items.filter((x) => {
            if (NEW_DASHBOARD) {
                const item = x as MyAction2
                return !(
                    item.issueId === Number(action.payload.issueId) &&
                    item.action === action.payload.actionCode
                )
            } else {
                const item = x as MyAction
                return !(
                    item.issueId === Number(action.payload.issueId) &&
                    item.action.actionCode === action.payload.actionCode
                )
            }
        }),
    }),
    [SET_RESOURCES]: (state, action) => ({
        ...state,
        issueTypesFilters: action.payload.issueType.map((a: FilterBase) => ({
            ...a,
            selected: false,
            display: true,
        })),
        actionsFilters: action.payload.action.map((a: FilterBase) => ({
            ...a,
            selected: false,
            display: true,
        })),
        selectedFiltersCount: 0,
    }),
    [ACTIONS.MY_ACTIONS_CLEAR_ERROR]: (state) => ({
        ...state,
        error: null,
        updating: false,
        loading: false,
        loadingDesc: null,
    }),
    [ACTIONS.MY_ACTIONS_CLEAR]: (state) => ({
        ...state,
        items: [],
        error: null,
        updating: false,
        loading: false,
        loadingDesc: null,
    }),
    [AuthenticationActions.LogoutSuccess]: () => ({ ...initState }),
    [ACTIONS.MY_ACTIONS_EXPORT_CALL]: (state) => ({
        ...state,
        exportingActions: true,
    }),
    [ACTIONS.MY_ACTIONS_EXPORT_DONE]: (state) => ({
        ...state,
        exportingActions: false,
    }),
    [ACTIONS.MY_ACTIONS_EXPORT_FAIL]: (state) => ({
        ...state,
        exportingActions: false,
    }),
}

export const myActionsReducer = (
    state: MyActionsStoreState = initState,
    action: AnyAction
): MyActionsStoreState => reducersIndex[action?.type]?.(state, action) || state
