import { Button, Collapse, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getGlobalNotification, saveGlobalNotification } from 'api'
import {
    BusinessUnitSelect,
    Content,
    FormControlLabel,
    PageSection,
    PageTitle,
    Switch,
    TextField,
} from 'components'
import dayjs, { Dayjs } from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { BusinessUnitFilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { getDefaultGlobalAppNotification, GlobalNotificationContext } from '.'
import {
    DateTimePicker,
    MessageColorPicker,
    TextAreaWithLinks,
} from './components'
import { GlobalAppNotification } from './types'

const title = 'Global user notification'

const useStyles = makeStyles(
    (theme) => ({
        switch: { width: '100%' },
        field: {
            marginTop: theme.spacing(1),
        },
    }),
    {
        name: 'GlobalNotificationSettings',
    }
)

export const GlobalNotificationSettings = () => {
    const theme = useTheme()
    const [loading, setLoading] = useState(false)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [error, setError] = useState(null)
    const [state, setState] = useState(getDefaultGlobalAppNotification())
    const { setNotification } = useContext(GlobalNotificationContext)
    const businessUnits = useSelector<RootState, BusinessUnitFilterBase[]>(
        (store) => store?.resources.businessUnits
    )
    const classes = useStyles()
    const {
        technicalBreak,
        technicalBreakTitle,
        technicalBreakDescription,
        message,
        messageVisible,
        messageTo,
        messageFrom,
        messageVariant,
        businessUnit,
    } = state
    const loadNotifications = async () => {
        try {
            setLoading(true)
            setLoadingDesc('Loading settings...')
            const { data } = await getGlobalNotification()
            setState(data)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    useEffect(() => {
        loadNotifications()
    }, [])
    const createSwitchHandler =
        (key: keyof GlobalAppNotification) => (e: any, checked: boolean) =>
            setState((prev) => ({ ...prev, [key]: checked }))
    const handleSwitchTemporaryNotification = (e: any, checked: boolean) => {
        if (checked) {
            setState((prev) => ({
                ...prev,
                messageFrom: dayjs().utc().add(5, 'days').toISOString(),
                messageTo: dayjs().utc().add(6, 'days').toISOString(),
            }))
        } else {
            setState((prev) => ({
                ...prev,
                messageFrom: null,
                messageTo: null,
            }))
        }
    }
    const temporaryNotification = Boolean(messageFrom && messageTo)
    const handleSave = async () => {
        try {
            setLoading(true)
            setLoadingDesc('Save settings...')
            await saveGlobalNotification(state)
            setNotification({ ...state })
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handleReset = () => {
        setNotification(getDefaultGlobalAppNotification())
    }
    const createDateHandler =
        (key: keyof GlobalAppNotification) => (date: Dayjs) =>
            setState((prev) => ({ ...prev, [key]: date?.utc()?.toISOString() }))
    return (
        <Content
            loading={loading}
            loadingDesc={loadingDesc}
            title={title}
            error={error}
            onRetry={() => setError(null)}
        >
            <PageTitle title={title} />
            <PageSection
                renderActions={() => (
                    <>
                        <Button onClick={handleReset}>Reset</Button>
                        <Button onClick={handleSave} color="secondary">
                            Save
                        </Button>
                    </>
                )}
            >
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            checked={technicalBreak}
                            onChange={createSwitchHandler('technicalBreak')}
                        />
                    }
                    label="Technical break (Maintenance)"
                />
                <Collapse in={technicalBreak}>
                    <TextField
                        label="Technical break title"
                        value={technicalBreakTitle ?? ''}
                        onChange={(e) =>
                            setState((prev) => ({
                                ...prev,
                                technicalBreakTitle: e.target.value,
                            }))
                        }
                        required
                    />
                    <TextAreaWithLinks
                        label="Technical break description"
                        initValue={technicalBreakDescription ?? ''}
                        onValueChange={(technicalBreakDescription) =>
                            setState((prev) => ({
                                ...prev,
                                technicalBreakDescription,
                            }))
                        }
                    />
                </Collapse>
                <FormControlLabel
                    className={classes.switch}
                    control={
                        <Switch
                            checked={messageVisible}
                            onChange={createSwitchHandler('messageVisible')}
                        />
                    }
                    label="Show global application notification"
                />
                <Collapse in={messageVisible}>
                    <TextAreaWithLinks
                        label="Message"
                        initValue={message ?? ''}
                        onValueChange={(message) =>
                            setState((prev) => ({
                                ...prev,
                                message,
                            }))
                        }
                        required
                    />
                    <MessageColorPicker
                        style={{ marginBottom: theme.spacing(2) }}
                        selectedColor={messageVariant}
                        onColorChange={(messageVariant) =>
                            setState((prev) => ({ ...prev, messageVariant }))
                        }
                    />
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                checked={temporaryNotification}
                                onChange={handleSwitchTemporaryNotification}
                            />
                        }
                        label="Temporary notification"
                    />

                    <Collapse in={temporaryNotification}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <DateTimePicker
                                style={{ marginRight: theme.spacing(1) }}
                                label="From"
                                value={dayjs(messageFrom).utc()}
                                onChange={createDateHandler('messageFrom')}
                                disablePast
                            />
                            <DateTimePicker
                                style={{ marginLeft: theme.spacing(1) }}
                                label="To"
                                value={dayjs(messageTo).utc()}
                                onChange={createDateHandler('messageTo')}
                                disablePast
                            />
                        </div>
                    </Collapse>
                    <BusinessUnitSelect
                        className={classes.field}
                        selectedBusinessUnit={businessUnits?.find(
                            (bu) => bu?.code === businessUnit
                        )}
                        onBusinessUnitChanged={(item) =>
                            setState((prev) => ({
                                ...prev,
                                businessUnit: item?.code,
                            }))
                        }
                        {...dataTestId('NOTIFICATION_BU')}
                    />
                </Collapse>
            </PageSection>
        </Content>
    )
}

export default GlobalNotificationSettings
