import MuiRadio, { RadioProps } from '@mui/material/Radio'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            color: theme.palette.grey[400],
        },
        checked: {
            color: `${theme.palette.secondary.main} !important`,
        },
        disabled: {
            color: `${theme.palette.grey[200]} !important`,
        },
    }),
    { name: 'Radio' }
)

export const Radio = (props: RadioProps) => {
    const classes = useStyles()
    return <MuiRadio {...props} classes={classes} />
}
Radio.muiName = 'Radio'

export default Radio
