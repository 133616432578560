import {
    Checkbox,
    Collapse,
    CollapseProps,
    FormGroup,
    FormHelperText,
    FormLabel,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { memo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueValuesKeys } from 'types/enums'
import { HighImpactCategory } from 'types/issueTypes'
import FormControlLabel from '../../components/Base/FormControlLabel'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { useFieldWithError } from '../matrix/hooks'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        classification: {
            marginTop: theme.spacing(2),
        },
        label: {
            display: 'block',
            transform: 'translate(0, 1.5px) scale(0.75) !important',
            transformOrigin: '0px 0px',
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        error: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'ImpactCategories' }
)

export interface ImpactCategoriesProps
    extends CollapseProps,
        HighImpactCategory {
    isHighImpact: boolean
    disabled: boolean
    onImpactClassificationCategoryChanged: (
        key: keyof HighImpactCategory,
        value: boolean
    ) => void
    error: boolean
    helperText?: string
}

const ImpactCategories = (props: ImpactCategoriesProps) => {
    const {
        disabled,
        isHealthAndSafetyImpact,
        isABBOrCustomerReputationAtStake,
        isNegativeBusinessImpactToCustomer,
        isNegativeBusinessImpactToABB,
        isNegativeImpactOnABBBrandImage,
        isPotentialToLossCustomer,
        isPotentialSystemicIssuesOrProductRecall,
        onImpactClassificationCategoryChanged,
        isHighImpact,
        error,
        helperText,
        children,
        ...other
    } = props
    const isHealthAndSafetyImpactField = useFieldWithError(
        IssueValuesKeys.IsHealthAndSafetyImpact,
        { disabled }
    )
    delete isHealthAndSafetyImpactField.helperText
    const isABBOrCustomerReputationAtStakeField = useFieldWithError(
        IssueValuesKeys.IsABBOrCustomerReputationAtStake,
        { disabled }
    )
    delete isABBOrCustomerReputationAtStakeField.helperText
    const isNegativeBusinessImpactToCustomerField = useFieldWithError(
        IssueValuesKeys.IsNegativeBusinessImpactToCustomer,
        { disabled }
    )
    delete isNegativeBusinessImpactToCustomerField.helperText
    const isNegativeBusinessImpactToABBField = useFieldWithError(
        IssueValuesKeys.IsNegativeBusinessImpactToABB,
        { disabled }
    )
    delete isNegativeBusinessImpactToABBField.helperText
    const isNegativeImpactOnABBBrandImageField = useFieldWithError(
        IssueValuesKeys.IsNegativeImpactOnABBBrandImage,
        { disabled }
    )
    delete isNegativeImpactOnABBBrandImageField.helperText
    const isPotentialToLossCustomerField = useFieldWithError(
        IssueValuesKeys.IsPotentialToLossCustomer,
        { disabled }
    )
    delete isPotentialToLossCustomerField.helperText
    const isPotentialSystemicIssuesOrProductRecallField = useFieldWithError(
        IssueValuesKeys.IsPotentialSystemicIssuesOrProductRecall,
        { disabled }
    )
    delete isPotentialSystemicIssuesOrProductRecallField.helperText
    const classes = useStyles(props)

    const handleImpactClassificationCategoryChanged = (
        key: keyof HighImpactCategory,
        value: boolean
    ) => {
        onImpactClassificationCategoryChanged &&
            onImpactClassificationCategoryChanged(key, value)
    }

    return (
        <Collapse in={isHighImpact} {...other}>
            <div className={classes.classification}>
                <FormLabel className={classes.label} required>
                    Potential risks:
                </FormLabel>
                <FormGroup
                    {...ISSUE_VIEW_PAGE.ISSUE_IMPACT
                        .POTENTIAL_RISKS_CHECKBOX_GROUP}
                >
                    <FormControlLabel
                        {...isHealthAndSafetyImpactField}
                        checked={isHealthAndSafetyImpact ?? false}
                        control={<Checkbox />}
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isHealthAndSafetyImpact',
                                Boolean(e.target.checked)
                            )
                        }
                        label="People health and safety"
                    />
                    <FormControlLabel
                        {...isABBOrCustomerReputationAtStakeField}
                        checked={isABBOrCustomerReputationAtStake ?? false}
                        control={<Checkbox />}
                        label="Environmental/sustainability reputation at stake"
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isABBOrCustomerReputationAtStake',
                                Boolean(e.target.checked)
                            )
                        }
                    />
                    <FormControlLabel
                        {...isNegativeBusinessImpactToCustomerField}
                        checked={isNegativeBusinessImpactToCustomer ?? false}
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isNegativeBusinessImpactToCustomer',
                                Boolean(e.target.checked)
                            )
                        }
                        control={<Checkbox />}
                        label="Negative business impact to Customer"
                    />
                    <FormControlLabel
                        {...isNegativeBusinessImpactToABBField}
                        checked={isNegativeBusinessImpactToABB ?? false}
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isNegativeBusinessImpactToABB',
                                Boolean(e.target.checked)
                            )
                        }
                        control={<Checkbox />}
                        label="Negative business impact to ABB"
                    />
                    <FormControlLabel
                        {...isNegativeImpactOnABBBrandImageField}
                        checked={isNegativeImpactOnABBBrandImage ?? false}
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isNegativeImpactOnABBBrandImage',
                                Boolean(e.target.checked)
                            )
                        }
                        control={<Checkbox />}
                        label="Negative impact on ABB brand image"
                    />
                    <FormControlLabel
                        {...isPotentialToLossCustomerField}
                        checked={isPotentialToLossCustomer ?? false}
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isPotentialToLossCustomer',
                                Boolean(e.target.checked)
                            )
                        }
                        control={<Checkbox />}
                        label="Loss of large or new customer with significant growth opportunity"
                    />
                    <FormControlLabel
                        {...isPotentialSystemicIssuesOrProductRecallField}
                        checked={
                            isPotentialSystemicIssuesOrProductRecall ?? false
                        }
                        onChange={(e: any) =>
                            handleImpactClassificationCategoryChanged(
                                'isPotentialSystemicIssuesOrProductRecall',
                                Boolean(e.target.checked)
                            )
                        }
                        control={<Checkbox />}
                        label="Repeatable systemic issues or product recall"
                    />
                    <FormHelperText className={classes.error} error={error}>
                        {helperText}
                    </FormHelperText>
                </FormGroup>
                {children}
            </div>
        </Collapse>
    )
}

ImpactCategories.defaultProps = {
    isHighImpact: false,
    disabled: true,
    error: false,
    helperText: '',
    isHealthAndSafetyImpact: false,
    isABBOrCustomerReputationAtStake: false,
    isNegativeBusinessImpactToCustomer: false,
    isNegativeBusinessImpactToABB: false,
    isNegativeImpactOnABBBrandImage: false,
    isPotentialToLossCustomer: false,
    isPotentialSystemicIssuesOrProductRecall: false,
}

export default memo(ImpactCategories)
