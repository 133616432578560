import LaunchIcon from '@mui/icons-material/Launch'
import { Grid, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HTMLAttributes, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueStatuses, QuickFixVariants } from 'types/enums'
import { MyIssue2 } from 'types/myDashboard'
import { dataTestId } from 'utils'
import { IssueTooltip } from '.'
import { RemainingTimeColor } from './MyIssueItem'
import MyIssueStatusChipLabel from './MyIssueStatusChipLabel'

interface TileHeaderProperty extends HTMLAttributes<HTMLDivElement> {
    issueNo: string
    issueStatus: IssueStatuses
    issueIsHighImpact: boolean
    issueSubject: string
    issueTargetDate: RemainingTimeColor
    canExtendTargetResolutionDate?: boolean
    issueVarification: boolean
    issueRcaVariant: QuickFixVariants
    onExtendTargetResolutionDate?: (date: MyIssue2) => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            ...theme.typography.caption,
        },
        subject: {
            maxWidth: 620,
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            [theme.breakpoints.down(1200)]: {
                maxWidth: 350,
            },
            [theme.breakpoints.down(600)]: {
                whiteSpace: 'normal',
            },
            textOverflow: 'ellipsis',
            fontSize: 16,
            lineHeight: 1.2,
        },
    }),
    { name: 'TileHeader' }
)

export const TileHeader = (props: TileHeaderProperty) => {
    const {
        issueNo,
        issueStatus,
        issueSubject,
        issueIsHighImpact,
        issueTargetDate,
        issueVarification,
        issueRcaVariant,
    } = props
    const classes = useStyles()

    const state = useMemo(() => {
        let array = []
        if (issueIsHighImpact)
            array.push(
                'High impact',
                'RCA is reqiured',
                'Verification required'
            )
        if (!issueIsHighImpact && issueVarification)
            array.push('Verification required')
        if (
            !issueIsHighImpact &&
            issueRcaVariant !== QuickFixVariants.No_RCA &&
            ![IssueStatuses.Draft, IssueStatuses.Empty].includes(issueStatus)
        )
            array.push('RCA is reqiured')
        return array
    }, [issueIsHighImpact, issueVarification, issueRcaVariant])

    return (
        <Grid container className={clsx(classes.root)} spacing={1}>
            <LaunchIcon style={{ fontSize: 16, padding: '0 4px' }} />

            <Grid item>
                <Typography
                    variant="subtitle2"
                    {...dataTestId(`ISSUE_TILE_HEADER_NUMBER`)}
                >
                    {issueNo}
                </Typography>
            </Grid>
            <Grid item>
                <MyIssueStatusChipLabel
                    date={issueTargetDate}
                    issueStatusId={issueStatus}
                    {...dataTestId(`DASHBOARD_ISSUE_TILE_ISSUE_STATUS`)}
                ></MyIssueStatusChipLabel>
            </Grid>
            {state.length ? (
                <Grid item>
                    <IssueTooltip
                        text={state}
                        {...dataTestId(`ISSUE_TILE_HIGHIMPACT`)}
                    />
                </Grid>
            ) : null}
            <Grid item>
                <IssueTooltip
                    text={[issueSubject]}
                    icon={
                        <p className={clsx(classes.subject)}>{issueSubject}</p>
                    }
                    {...dataTestId(`ISSUE_TILE_SUBJECT`)}
                />
            </Grid>
        </Grid>
    )
}
