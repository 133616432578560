import { PlaceOutlined } from '@mui/icons-material'
import {
    AutocompleteChangeReason,
    BaseTextFieldProps,
    useTheme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import { RootState } from 'store/types'
import { CityFilterBase, CountryFilter, FilterBase } from 'types/models'
import TextField from '../Base/TextField'
import { getShortOptionLabel } from './utils'

export interface CitySelectProps extends Partial<BaseTextFieldProps> {
    region?: FilterBase
    country?: CountryFilter
    selectedCity: CityFilterBase
    onCityChanged: (item: CityFilterBase) => void
    displayAll?: boolean
}

export const CitySelect = (props: CitySelectProps) => {
    const {
        label = 'City',
        disabled: disabledProp,
        selectedCity,
        region,
        country,
        onCityChanged,
        error,
        helperText,
        displayAll,
        ...other
    } = props
    const { cities: allOptions, countries } = useSelector(
        (state: RootState) => state.resources
    )
    const options = useMemo(() => {
        const filterByRegion = region && Object.keys(region)?.length !== 0
        const filterByCountry = country && Object.keys(country)?.length !== 0
        const regionMap = countries.reduce<Record<string, string>>(
            (prev, x) => {
                prev[x.code] = x.regionCode
                return prev
            },
            {}
        )
        return allOptions
            .filter((b) =>
                filterByRegion ? region.code === regionMap[b.countryCode] : true
            )
            .filter((b) =>
                filterByCountry ? country.code === b.countryCode : true
            )
    }, [allOptions, country, region])
    useUpdateEffect(() => {
        if (country && selectedCity?.countryCode !== country?.code) {
            onCityChanged(null)
        }
    }, [country])
    const theme = useTheme()
    const handleSelectFilter = (item: FilterBase) => onCityChanged(item)
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option?.code === value?.code
    }
    return (
        <Autocomplete
            disabled={disabledProp}
            value={selectedCity ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Cities'}
            disableClearable={false}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          f.name
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as FilterBase
                switch (reason) {
                    case 'clear':
                        handleSelectFilter(null)
                        break
                    default:
                        handleSelectFilter({ ...selectedValue })
                }
            }}
            renderOption={(props, option, state) => (
                <li {...props} key={state.index}>
                    {getShortOptionLabel(option)}
                </li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={label}
                    helperText={helperText}
                    autoComplete="off"
                    InputProps={{
                        ...params.InputProps,
                        autoComplete: 'off',
                        style: { paddingTop: 0 },
                        startAdornment: (
                            <PlaceOutlined
                                fontSize="small"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default CitySelect
