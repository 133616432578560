import { useAutocomplete } from '@mui/lab'
import {
    AutocompleteInputChangeReason,
    Chip,
    CircularProgress,
    Tooltip,
} from '@mui/material'
import { searchEmployees as searchEmployeesApi } from 'api/employees'
import { useFieldState } from 'components'
import debounce from 'lodash/debounce'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { IssueStoreState } from 'store/issue'
import { RootState } from 'store/types'
import { MailRecipient } from 'types/sendMessageTypes'
import { validateEmail } from '../../../utils'
import { SendMessageInput } from './SendMessageInput'
import { useStyles } from './styles'
import { getRecipiantsTags } from './utils'

export interface RecipientSelectProps {
    placeholder: string
    recipiants: MailRecipient[]
    onRecipiantsChange: (recipiants: MailRecipient[]) => void
}

export const RecipientSelect = (props: RecipientSelectProps) => {
    const { placeholder, recipiants, onRecipiantsChange, ...other } = props
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const [{ loading, options }, setState] = useFieldState<MailRecipient>({
        loading: false,
        helperText: '',
        options: [...getRecipiantsTags(issue)],
        error: null,
    })
    const [inputValue, setInputValue] = useState('')
    const classes = useStyles()
    const searchEmployees = useCallback(
        debounce(async (wildChard: string) => {
            try {
                if (loading || (!wildChard && wildChard.length < 4)) {
                    return
                }
                setState({ loading: true, error: null, helperText: null })
                const { data: employees } = await searchEmployeesApi(wildChard)
                setState({
                    loading: false,
                    options: [
                        ...employees.map<MailRecipient>((e) => ({
                            displayLabel: e.email,
                            identification: {
                                geid: e.geid,
                                email: e.email,
                            },
                            isValid: e.isActive,
                            tooltipInfo: [e.fullName] ?? [],
                        })),
                        ...getRecipiantsTags(issue),
                    ],
                })
            } catch (error) {
                setState({
                    loading: false,
                    error,
                    options: [],
                    helperText: 'Cannot load employees list',
                })
            }
        }, 800),
        []
    )
    const addAbbRecipiant = (email: string) => {
        const emailTrimed = email.trim()
        if (
            emailTrimed.endsWith('abb.com') &&
            validateEmail(emailTrimed) &&
            !value.some(
                (x) => (x as MailRecipient).displayLabel === emailTrimed
            )
        ) {
            onRecipiantsChange([
                ...((value as MailRecipient[]) ?? []),
                {
                    displayLabel: emailTrimed,
                    identification: {
                        geid: null,
                        email: emailTrimed,
                    },
                    isValid: true,
                    tooltipInfo: [emailTrimed],
                },
            ])
            setInputValue('')
        } else {
            setInputValue(emailTrimed)
        }
    }
    const onInputChange = (
        e: any,
        newValue: string,
        reason: AutocompleteInputChangeReason
    ) => {
        if (reason === 'input') {
            searchEmployees(newValue)
            if (newValue.endsWith(' ')) {
                addAbbRecipiant(newValue)
            } else {
                setInputValue(newValue)
            }
        } else if (reason === 'reset') {
            addAbbRecipiant(inputValue)
        } else if (reason === 'clear') {
            setInputValue('')
        }
    }
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        getTagProps,
        value,
        groupedOptions,
    } = useAutocomplete({
        value: recipiants,
        inputValue,
        onChange: (e, tags) => {
            onRecipiantsChange(tags as MailRecipient[])
            setInputValue('')
        },
        getOptionLabel: (option) => option.displayLabel,
        // getOptionSelected: (option: MailRecipient, value: MailRecipient) =>
        //     option?.displayLabel === value?.displayLabel,
        onInputChange,
        options,
        multiple: true,
    })
    const getKey = (recipient: MailRecipient) => recipient.displayLabel
    return (
        <div className={classes.root} {...getRootProps()} {...other}>
            <SendMessageInput
                startAdornment={value.map((t, index) => {
                    const tag = t as MailRecipient
                    return (
                        <Tooltip
                            key={getKey(tag)}
                            title={tag.tooltipInfo?.join(', ')}
                        >
                            <Chip
                                className={classes.chip}
                                classes={{ label: classes.chipLabel }}
                                label={tag.displayLabel}
                                size="small"
                                variant="outlined"
                                {...getTagProps({ index })}
                            />
                        </Tooltip>
                    )
                })}
                endAdornment={
                    loading && <CircularProgress size={16} color="secondary" />
                }
                placeholder={placeholder}
                inputProps={{ ...getInputProps() }}
                error
            />
            {groupedOptions.length > 0 ? (
                <ul className={classes.list} {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li {...getOptionProps({ option, index })}>
                            {option.displayLabel}
                        </li>
                    ))}
                </ul>
            ) : null}
        </div>
    )
}
