import { Collapse, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ExpandButton } from 'components'
import { useState } from 'react'
import { AdminHubStructureBU } from 'types/adminTypes'
import { UserProfile } from 'types/profile'
import { dataTestId } from 'utils'
import { HubStructureItem } from './HubStructureItem'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            marginLeft: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
        },
        title: {
            marginLeft: theme.spacing(1),
        },
        content: {
            padding: theme.spacing(0, 0, 2, 2),
        },
    }),
    { name: 'HubStructureGroup' }
)

export interface HubStructureGroupProps {
    profile: UserProfile
    updating: number[]
    divName: string
    divCode: string
    hubs: AdminHubStructureBU[]
    onHubChange: (hub: AdminHubStructureBU, isActive: boolean) => void
}

export const HubStructureGroup = (props: HubStructureGroupProps) => {
    const { divName, divCode, hubs, onHubChange, updating, profile } = props
    const classes = useStyles()
    const [rootExpanded, setRootExpanded] = useState(false)
    const handleRootExpand = () => {
        setRootExpanded(!rootExpanded)
    }
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <ExpandButton
                    expanded={rootExpanded}
                    onClick={handleRootExpand}
                    {...dataTestId(`EXPAND_${divName.toUpperCase()}`)}
                />
                <Typography className={classes.title} variant="body1">
                    {`${divCode} - ${divName}`}
                </Typography>
            </div>
            <Collapse in={rootExpanded}>
                <div className={classes.content}>
                    {hubs.map((h) => (
                        <HubStructureItem
                            updating={updating.includes(h.id)}
                            profile={profile}
                            key={h.id}
                            hub={h}
                            onHubChange={onHubChange}
                        />
                    ))}
                </div>
            </Collapse>
        </div>
    )
}
