import { IMsalContext } from '@azure/msal-react'
import ContentLoader from './ContentLoader'

export interface PageLoaderProps extends Partial<IMsalContext> {
    overlay?: boolean
}

export const PageLoader = (props: PageLoaderProps) => {
    const { overlay } = props
    return (
        <div>
            <ContentLoader
                loading={true}
                overlay={overlay}
                label="Loading page…"
            ></ContentLoader>
        </div>
    )
}

export default PageLoader
