import { MyAction2 } from 'types/myDashboard'
import { IssueActionsTypes } from 'types/enums'
import {
    EffectivnessCheckActions,
    ToAssignActions,
    ToCompleteIssueActions,
    ToReplaceInactiveIOActionTileActions,
    ToReplaceInactiveROActionTileActions,
    ToReplaceInactiveRUActionTileActions,
    ToResolveActions,
    ToSubmitActions,
    ToVerifyContainmentActions,
    ToVerifyHighImpactActions,
    ToVerifyResolutionActions,
} from './actions'

export interface TileActionsProps {
    myActionMeta?: MyAction2
    issueNumber?: string
}

export const TileActions = (props: TileActionsProps) => {
    const { myActionMeta } = props
    switch (myActionMeta?.action) {
        case IssueActionsTypes.To_Submit_Returned_To_Draft:
        case IssueActionsTypes.To_Submit:
            return <ToSubmitActions {...props} />
        case IssueActionsTypes.To_Assign:
            return <ToAssignActions {...props} />
        case IssueActionsTypes.Resolution_Rejected:
        case IssueActionsTypes.To_Resolve:
            return <ToResolveActions {...props} />
        case IssueActionsTypes.To_Verify_Resolution:
            return <ToVerifyResolutionActions {...props} />
        case IssueActionsTypes.To_Execute_Containment_Actions:
            return <ToVerifyContainmentActions {...props} />
        case IssueActionsTypes.To_Verify_HI:
            return <ToVerifyHighImpactActions {...props} />
        case IssueActionsTypes.To_Complete:
        case IssueActionsTypes.To_Complete_After_Customer_Execution:
            return <ToCompleteIssueActions {...props} />
        case IssueActionsTypes.Effectiveness_Sustainability_Check:
            return <EffectivnessCheckActions {...props} />
        case IssueActionsTypes.To_Assign_Updated_RU:
            return <ToReplaceInactiveRUActionTileActions {...props} />
        case IssueActionsTypes.To_Replace_Inactive_RO:
            return <ToReplaceInactiveROActionTileActions {...props} />
        case IssueActionsTypes.To_Replace_Inactive_IO:
            return <ToReplaceInactiveIOActionTileActions {...props} />
        case IssueActionsTypes.To_Replace_Inactive_RU:
            return <ToReplaceInactiveRUActionTileActions {...props} />
        default:
            return null
    }
}
