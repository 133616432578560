import { getSurveyTranslations } from 'api'
import { createContext, FC, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router'
import { SurveyTranslationKeys } from './types'

export interface SurveyTranslationContextProps {
    translations?: Record<SurveyTranslationKeys, string>
    t?: (key: SurveyTranslationKeys, defaultValue: string) => string
    loading: boolean
    error: any
    loadTranslations?: () => void
}

export const SurveyTranslationContext =
    createContext<SurveyTranslationContextProps>({
        loading: false,
        error: null,
    })

export const SurveyTranslationProvider: FC<unknown> = (props) => {
    const { children } = props
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [translations, setTranslations] =
        useState<Record<SurveyTranslationKeys, string>>(null)
    const { lang } = useParams<{ lang: string }>()
    const loadTranslations = async () => {
        try {
            setLoading(true)
            setError(null)
            const searchParams = new URLSearchParams(location.search)
            const culture = searchParams.get('culture') ?? lang ?? 'en'
            if (culture) {
                const { data } = await getSurveyTranslations(culture)
                const results = data.reduce((current, item) => {
                    current[item.name] = item.value
                    return current
                }, {})
                setTranslations(
                    results as Record<SurveyTranslationKeys, string>
                )
            }
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    const t = useCallback(
        (key: SurveyTranslationKeys, defaultValue: string = null): string => {
            const value =
                translations && translations[key]
                    ? translations[key]
                    : defaultValue
            return value ?? ''
        },
        [translations, loading]
    )
    useEffect(() => {
        loadTranslations()
    }, [location])
    return (
        <SurveyTranslationContext.Provider
            value={{ translations, t, loading, error, loadTranslations }}
        >
            {children}
        </SurveyTranslationContext.Provider>
    )
}
