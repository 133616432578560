import {
    ADMIN_RESOLVING_UNITS_PAGE_SIZE,
    ResolvingUnitsManagePageCard,
    ResolvingUnitsManagePageCard2,
    ResolvingUnitsManagePageExcelExport,
} from 'Admin'
import { SearchResultsWithPagination } from 'components'
import { ADMIN_PANEL_NEW_CONCEPT } from 'features'
import { createElement, useMemo } from 'react'
import { AdminResolvingUnit } from 'types/adminTypes'
import { UserProfile } from 'types/profile'
import { getPartOfTable } from 'utils'

export interface ResolvingUnitsManagePageResultsProps {
    noResults?: boolean
    profile: UserProfile
    isSearching: boolean
    searchResults: AdminResolvingUnit[]
    togglePerson: (id: number) => void
    addPerson: (id: number) => void
    personsIds: number[]
    updateResolvingUnitsLocal: (
        updatedResolvingUnits: AdminResolvingUnit[]
    ) => void
    page: number
    onPageChange: (page: number) => void
}

export const ResolvingUnitsManagePageResults = (
    props: ResolvingUnitsManagePageResultsProps
) => {
    const {
        noResults = false,
        profile,
        isSearching,
        searchResults,
        togglePerson,
        addPerson,
        personsIds,
        updateResolvingUnitsLocal,
        page,
        onPageChange,
    } = props

    const sortedSearchResult = useMemo(
        () =>
            searchResults.sort((a, b) =>
                a?.businessUnitCode?.toLocaleLowerCase() +
                    a?.productGroupCode?.toLocaleLowerCase() >
                b?.businessUnitCode?.toLocaleLowerCase() +
                    b?.productGroupCode?.toLocaleLowerCase()
                    ? 1
                    : -1
            ),
        [searchResults]
    )

    const cards = useMemo(() => {
        let results: AdminResolvingUnit[] = []

        if (searchResults?.length > 0) {
            results = getPartOfTable<AdminResolvingUnit>(
                sortedSearchResult,
                ADMIN_RESOLVING_UNITS_PAGE_SIZE,
                page
            )
        }
        const compType = ADMIN_PANEL_NEW_CONCEPT
            ? ResolvingUnitsManagePageCard2
            : ResolvingUnitsManagePageCard

        return results.map((adminResolvingUnit) =>
            createElement(compType, {
                profile,
                key: adminResolvingUnit.id,
                adminResolvingUnit: adminResolvingUnit,
                togglePerson: togglePerson,
                personsIds: personsIds,
                addPerson: addPerson,
                updateResolvingUnitsLocal: updateResolvingUnitsLocal,
            })
        )
    }, [page, sortedSearchResult, personsIds])

    return (
        <SearchResultsWithPagination
            isSearching={isSearching}
            page={page}
            pageSize={ADMIN_RESOLVING_UNITS_PAGE_SIZE}
            itemCount={searchResults?.length}
            onPageChange={onPageChange}
            renderAction={() => (
                <ResolvingUnitsManagePageExcelExport
                    adminResolvingUnits={searchResults}
                    isSearching={isSearching}
                />
            )}
            errorVisible={noResults}
            errorTitle="No results!"
            errorSubTitle="Change search criteria and try again."
        >
            {cards}
        </SearchResultsWithPagination>
    )
}
