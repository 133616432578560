import { Delete, DeleteForever } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { AdminEmployeeChip, RUAdminChipProps } from 'Admin'
import { useState } from 'react'
import { dataTestId } from 'utils'

export const RUAdminChip = (props: RUAdminChipProps) => {
    const { employee, isLast, disabled = false, onDelete, className } = props
    const [open, setOpen] = useState(false)
    return (
        <AdminEmployeeChip
            className={className}
            open={open}
            onMouseEnter={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            disabled={disabled}
            deleteIcon={
                isLast ? (
                    <Tooltip
                        title="Cannot delete the only RU Process Owner"
                        onOpen={() => {
                            setOpen(false)
                        }}
                        onClose={() => {
                            setOpen(true)
                        }}
                    >
                        <DeleteForever
                            {...dataTestId('DELETE_RU_PERSONS_BUTTON')}
                        />
                    </Tooltip>
                ) : (
                    <Delete {...dataTestId('DELETE_RU_PERSONS_BUTTON')} />
                )
            }
            onDelete={() => !disabled && !isLast && onDelete()}
            employee={employee}
            key={employee.email}
        />
    )
}
