import { Tabs, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ChangeEvent, HtmlHTMLAttributes, useEffect, useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import { TabsFilter } from 'types/models'
import { dataTestId } from 'utils'
import { Tab } from '.'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {},
        tabs: {
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
    }),
    { name: 'TabsView' }
)

export type TabsViewType = Array<
    Partial<{ label: string; idTest: string; name?: keyof TabsFilter }>
>

export interface TabsViewProps extends HtmlHTMLAttributes<HTMLDivElement> {
    tabs: TabsViewType
    variant?: 'standard' | 'scrollable' | 'fullWidth'
    enableMouseEvents?: boolean
    classes?: {
        root?: string
        tabs?: string
    }
    items?: any[]
    animateHeight?: boolean
    handleActive?: (index: keyof TabsFilter) => void
}

export const TabsView = (props: TabsViewProps) => {
    const {
        children,
        classes,
        tabs,
        variant,
        enableMouseEvents,
        className,
        items,
        animateHeight,
        handleActive,
        ...other
    } = props
    const [activeMode, setActiveMode] = useState(animateHeight ? 1 : 0)
    const mainClasses = useStyles()

    const handleActiveMode = (event: ChangeEvent<{}>, index: number) => {
        setActiveMode(index)
        handleActive && handleActive(tabs[index]?.name)
    }

    const handleChangeIndex = (index: number) => {
        setActiveMode(index)
    }

    useEffect(() => {
        if (animateHeight) {
            setActiveMode(0)
        }
    }, [])

    return (
        <div className={clsx(mainClasses.root, classes?.root)} {...other}>
            <Tabs
                variant={variant ? variant : 'standard'}
                className={clsx(mainClasses.tabs, classes?.tabs)}
                value={activeMode}
                indicatorColor="secondary"
                textColor="secondary"
                onChange={handleActiveMode}
            >
                {tabs.map(({ label, idTest, ...other }) => (
                    <Tab
                        label={label}
                        key={label}
                        {...dataTestId(idTest)}
                        {...other}
                    />
                ))}
            </Tabs>
            <SwipeableViews
                containerStyle={{
                    transition:
                        'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
                }}
                enableMouseEvents={
                    enableMouseEvents ? enableMouseEvents : false
                }
                index={activeMode}
                onChangeIndex={handleChangeIndex}
                animateHeight={animateHeight ? animateHeight : false}
            >
                {children}
            </SwipeableViews>
        </div>
    )
}
