import { makeStyles } from '@mui/styles'
import { Helmet } from 'react-helmet'
import { PageNotFound } from '../components'

const useStyles = makeStyles(
    () => ({
        content: {
            minWidth: 320,
            maxWidth: 420,
            margin: '0 auto',
        },
    }),
    { name: 'Error404' }
)

const Error404 = () => {
    const classes = useStyles()

    return (
        <section className={classes.content}>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <PageNotFound />
        </section>
    )
}

export default Error404
