import { ExpandMore } from '@mui/icons-material'
import {
    CircularProgress,
    IconButton,
    IconButtonProps,
    SvgIconProps,
    Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expanded: {
            '&$expanded': {
                transform: 'rotate(180deg)',
            },
        },
    }),
    { name: 'ExpandButton' }
)

export interface ExpandButtonProps extends IconButtonProps {
    expanded: boolean
    loading?: boolean
    IconProps?: SvgIconProps
}

export const ExpandButton = (props: ExpandButtonProps) => {
    const {
        className,
        expanded,
        disabled,
        loading = false,
        IconProps,
        ...other
    } = props
    const classes = useStyles(props)
    return (
        <IconButton
            disabled={disabled || loading}
            className={clsx(
                className,
                classes.root,
                expanded && classes.expanded
            )}
            {...other}
            size="large"
        >
            {loading ? (
                <CircularProgress size={24} color="secondary" />
            ) : (
                <ExpandMore {...IconProps} />
            )}
        </IconButton>
    )
}

export default ExpandButton
