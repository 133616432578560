import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { ChangeEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface AccountTypeSelectProps extends Partial<BaseTextFieldProps> {
    selectedAccountType: FilterBase<number>
    onAccountTypeChanged?: (regions: FilterBase<number>) => void
}

export const AccountTypeSelect = (props: AccountTypeSelectProps) => {
    const options = useSelector(
        (state: RootState) => state.resources.customerAccountType
    )
    const { selectedAccountType, onAccountTypeChanged, disabled, ...other } =
        props
    useEffect(() => {
        if (
            !Array.isArray(selectedAccountType) ||
            selectedAccountType?.length === 0
        ) {
            const { name, code } = options[0]
            onAccountTypeChanged &&
                onAccountTypeChanged({ name, code: Number(code) })
        }
    }, [])
    const handleOnChange = (
        e: ChangeEvent<any>,
        value: string | FilterBase<number>
    ) => {
        const item = value ? { ...(value as FilterBase<number>) } : null
        onAccountTypeChanged && onAccountTypeChanged(item)
    }
    return (
        <Autocomplete
            disabled={disabled}
            value={selectedAccountType}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Account type"
                    {...dataTestId('ACCOUNT_TYPE')}
                    {...other}
                />
            )}
        />
    )
}
