import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ReactNode } from 'react'
import theme from 'styles/themeBase'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
    }),
    {
        name: 'AdminEmployeeLabel',
    }
)

export interface AdminEmployeeLabelProps {
    name: string
    icon: () => ReactNode
}

export const AdminEmployeeLabel = (props: AdminEmployeeLabelProps) => {
    const classes = useStyles()
    const { name, icon } = props
    return (
        <div className={classes.root}>
            {icon && icon()}
            <Typography
                variant="caption"
                style={{
                    fontWeight: theme.typography.fontWeightMedium as number,
                    margin: theme.spacing(0, 1, 0, 0),
                }}
            >
                {name}
            </Typography>
        </div>
    )
}
