export const UNAUTHORIZED_REQUESTED = 'UNAUTHORIZED_REQUESTED'
export const SET_USER_PROFILE = 'APP_SET_USER_PROFILE'
export const GET_USER_TOKEN_REQUESTED = 'SET_USER_TOKEN_REQUESTED'
export const GET_USER_TOKEN_DONE = 'GET_USER_TOKEN_DONE'
export const GET_USER_TOKEN_FAIL = 'GET_USER_TOKEN_FAIL'
export const HANDLE_CLIENT_ERROR = 'HANDLE_CLIENT_ERROR'
export const REMOVE_CLIENT_ERROR = 'REMOVE_CLIENT_ERROR'

export const SHOW_SNACKBAR = 'APP_SHOW_SNACKBAR'
export const DISMISS_SNACKBAR = 'APP_DISMISS_SNACKBAR'
export const REMOVE_SNACKBAR = 'APP_REMOVE_SNACKBAR'
export const REMOVE_SNACKBARS = 'APP_REMOVE_SNACKBARS'
