const attributeName = 'data-test-id'
const isDevelopment = Boolean(
    Number(process.env.REACT_APP_ENABLE_TEST_ATTRIBUTES)
)

export function dataTestId(id: string): any {
    if (isDevelopment) {
        return {
            [attributeName]: id,
        }
    }
    return {}
}

export function customize(dataTestId: any, customData: any): any {
    return isDevelopment
        ? {
              [attributeName]: `${
                  dataTestId[attributeName as string]
              }_${customData}`,
          }
        : {}
}