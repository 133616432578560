import {
    Components,
    createTheme,
    Theme,
    ThemeOptions,
} from '@mui/material/styles'
import {
    Palette,
    PaletteColor,
    PaletteColorOptions,
    PaletteOptions,
} from '@mui/material/styles/createPalette'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import type { PickerComponents } from '@mui/x-date-pickers/themeAugmentation'

export interface Shades {
    gray06_t: String
    gray05_t: String
    red05_t: String
    red10_t: String
    red80_t: String
    red1_10: String
}
export interface AbbPalette extends Palette {
    blue?: PaletteColor
    green?: PaletteColor
    yellow?: PaletteColor
    red?: PaletteColor
    shades?: Shades
}
export interface AbbTheme extends Theme {
    palette: AbbPalette
}
export interface AbbPaletteOptions extends PaletteOptions {
    blue?: PaletteColorOptions
    green?: PaletteColorOptions
    yellow?: PaletteColorOptions
    red?: PaletteColorOptions
    shades?: Shades
}
export type AbbOverrides = Components<Omit<Theme, 'components'>> &
    PickerComponents & {
        MuiPickersToolbar?: any
        MuiPickersCalendarHeader?: any
    }

export type AbbTypography = TypographyOptions & {
    fontFamily: string
    fontSize: number
    fontWeightLight: number
    fontWeightRegular: number
    fontWeightMedium: number
    fontWeightBold: number
}

export interface AbbThemeOptions extends ThemeOptions {
    palette: AbbPaletteOptions
    typography: AbbTypography
    components: AbbOverrides
}

export default function createAbbTheme(options: AbbThemeOptions): AbbTheme {
    const themeBase = createTheme(options)

    return themeBase
}
