/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {number} productLineLocationId - product line location id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */

import axios, { CancelToken, AxiosResponse } from 'axios'
import { ProductLineLocationProps } from 'Issue/Pickers'

export const getProductLineLocation = (
    resolvingUnitId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ProductLineLocationProps[]>> =>
    axios.get(`/product-line-location/${resolvingUnitId}`, {
        cancelToken,
    })

export const searchProductLineLocation = (
    resolvingUnitId: number,
    productLineLocationId: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ProductLineLocationProps[]>> =>
    axios.post(
        `/product-line-location/search`,
        { resolvingUnitId, productLineLocationId },
        {
            cancelToken,
        }
    )
