import { useState } from 'react'

import Subject from '@mui/icons-material/Subject'
import { useDispatch } from 'react-redux'
import Button from '../../components/Base/Button'
import ValueView from '../../components/Base/ValueView'
import { ConfirmContainmentActionDialog } from '../../components/Dialogs/ConfirmContainmentActionDialog'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { verifyContainment } from '../../store/myActions/index'
import { VerifyContainmentActionQuery } from '../../types/actions'
import { MyAction } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import IssueInfoBaseRow from './IssueInfoBaseRow'
import { useTilesStyles } from './tilesStyles'

export interface ToVerifyContainmentActionProps {
    actionIssue: MyAction
}

export const ToVerifyContainmentAction = (
    props: ToVerifyContainmentActionProps
) => {
    const [open, setOpen] = useState<boolean>(false)
    const classes = useTilesStyles()

    const dispatch = useDispatch()
    const { actionIssue, ...other } = props
    const { action } = actionIssue
    let actions = (
        <>
            <Button
                color="secondary"
                onClick={() => setOpen(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CONFIRM_CONTAINMENT_ACTIONS}
                eventName="Confirm Containment Actions on dashboard"
            >
                Confirm Containment Actions
            </Button>
        </>
    )
    const handleVerifyContainmentAction = (
        formData: VerifyContainmentActionQuery
    ) => {
        setOpen(false)
        dispatch(verifyContainment(formData))
    }
    return (
        <>
            <ExpandTile
                issueNumber={actionIssue.issueNumber}
                subject={actionIssue.subject}
                issueStatusId={actionIssue.issueStatusId}
                isOverdue={action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                showActionStatus={true}
                actionCode={action.actionCode}
                {...other}
                issueId={actionIssue.issueId}
                collapsedSize="100px"
                actions={actions}
            >
                <IssueInfoBaseRow
                    deadlineDate={actionIssue?.action?.deadlineDate}
                    issueType={actionIssue.issueType}
                />
                <ValueView
                    className={classes.singleRow}
                    icon={<Subject className={classes.labelIcon} />}
                    singleLine={false}
                    label="Required containment actions"
                    value={actionIssue.containmentActionDescription}
                    {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                        .REQUIRED_CONTAINMENT_ACTIONS}
                />
            </ExpandTile>
            <ConfirmContainmentActionDialog
                open={open}
                issueId={actionIssue.issueId}
                rowVersion={actionIssue.rowVersion}
                onClose={() => setOpen(false)}
                onConfirm={(formData) =>
                    handleVerifyContainmentAction(formData)
                }
            />
        </>
    )
}

export default ToVerifyContainmentAction
