import { Typography } from '@mui/material'
import {
    ActionPlanTypeSelect,
    AttachmentsNext,
    CardView,
    EmployeeSelect,
    FormGridItem,
    KeyboardDatePicker,
    MultiEmployeeSelect,
    TextField,
} from 'components'
import dayjs, { Dayjs } from 'dayjs'
import {
    useMergeAccessMatrix,
    useResolvingUnitAndResolutionOwner,
    useValidationErrors,
} from 'hooks/actionPlan'
import { ChangeEvent, ReactNode, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
    ActionItemAttachment,
    ActionItemAttachmentType,
    ActionItemFields,
    ActionItemStatus,
} from 'types/actionPlanTypes'
import { ActionPlanItemParams } from 'types/urlParams'
import { dataTestId } from 'utils'
import ActionPlanActions from '../components/ActionPlanActions'
import { ActionPlanContext } from '../context/ActionPlanContext'
import { useActionPlanStatusChips } from '../hooks'
import { ActionPlanItemInputFields } from './types'
import { getInitQuery } from './utils'

export const ActionPlanItem = () => {
    const { actionPlanId: actionPlanIdParam } =
        useParams<ActionPlanItemParams>()
    const isNewItem = actionPlanIdParam && actionPlanIdParam === 'new'

    const { currentActionItem } = useContext(ActionPlanContext)
    const item = currentActionItem
    const isDraftStatus = item.actionStatus === ActionItemStatus.ToDo

    const { issueNumber } = item
    const [query, setQuery] = useState<ActionPlanItemInputFields>(
        getInitQuery()
    )

    const { errors, removeError } = useValidationErrors()
    const { enabledFields, requiredFields } = useMergeAccessMatrix(
        item.accessMatrix
    )
    useEffect(() => {
        const activitiesAttachments = item.actionItemAttachments.filter(
            (item) => item.type === ActionItemAttachmentType.Activities
        )
        const descriptionAttachments = item.actionItemAttachments.filter(
            (item) => item.type === ActionItemAttachmentType.Description
        )

        setQuery((prev) => ({
            ...prev,
            ...item,
            activitiesAttachments,
            descriptionAttachments,
        }))
    }, [item])

    const results = useResolvingUnitAndResolutionOwner(item)

    const TabsWithStatus = useActionPlanStatusChips(item)

    const checkIsDisabled = (id: ActionItemFields) => {
        return !enabledFields.includes(id)
    }

    const checkIsRequired = (id: ActionItemFields) => {
        return requiredFields.includes(id)
    }

    const handleChange =
        (fieldName: keyof ActionPlanItemInputFields) =>
        (newValue: ReactNode) => {
            setQuery((prev) => ({
                ...prev,
                [fieldName]: newValue,
            }))
            removeError(fieldName)
        }

    const handleChangeText =
        (fieldName: keyof ActionPlanItemInputFields) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setQuery((prev) => ({
                ...prev,
                [fieldName]: e.target.value,
            }))
            removeError(fieldName)
        }

    const handleChangeTime = (newDate: Dayjs) => {
        removeError('actionDeadline')
        setQuery((prev) => ({
            ...prev,
            actionDeadline: newDate.toISOString(),
        }))
    }

    const getFieldProps = (fieldName: keyof ActionPlanItemInputFields) => ({
        error: Boolean(errors[fieldName]),
        helperText: errors[fieldName],
    })

    const getTextFieldProps = (fieldName: keyof ActionPlanItemInputFields) => {
        return {
            ...getFieldProps(fieldName),
            value: query[fieldName],
            onChange: handleChangeText(fieldName),
        }
    }

    const getAccessMatrixProps = (fieldType: ActionItemFields) => ({
        disabled: checkIsDisabled(fieldType),
        required: checkIsRequired(fieldType),
    })

    const addAttachment = (attachment: ActionItemAttachment) => {
        const type = attachment.type
        if (type === ActionItemAttachmentType.Description) {
            setQuery((prev) => ({
                ...prev,
                descriptionAttachments: [
                    ...prev.descriptionAttachments,
                    attachment,
                ],
            }))
        } else if (type === ActionItemAttachmentType.Activities) {
            setQuery((prev) => ({
                ...prev,
                activitiesAttachments: [
                    ...prev.activitiesAttachments,
                    attachment,
                ],
            }))
        }
    }

    const removeAttachment = (attachment: ActionItemAttachment) => {
        const type = attachment.type
        if (type === ActionItemAttachmentType.Description) {
            setQuery((prev) => ({
                ...prev,
                descriptionAttachments: [
                    ...prev.descriptionAttachments.filter(
                        (item) => item.id !== attachment.id
                    ),
                ],
            }))
        } else if (type === ActionItemAttachmentType.Activities) {
            setQuery((prev) => ({
                ...prev,
                activitiesAttachments: [
                    ...prev.activitiesAttachments.filter(
                        (item) => item.id !== attachment.id
                    ),
                ],
            }))
        }
    }

    return (
        <CardView
            results={results}
            title={issueNumber}
            renderTag={() => TabsWithStatus}
            renderActions={() => (
                <ActionPlanActions
                    item={{
                        ...item,
                        ...query,
                    }}
                />
            )}
        >
            <FormGridItem>
                <EmployeeSelect
                    label="Action creator"
                    employee={query.actionCreator}
                    onEmployeeChanged={handleChange('actionCreator')}
                    {...getFieldProps('actionCreator')}
                    {...getAccessMatrixProps(ActionItemFields.ActionCreator)}
                    {...dataTestId('ACTION_PLAN_ACTION_CREATOR')}
                />
            </FormGridItem>
            <FormGridItem>
                <EmployeeSelect
                    label="Action owner"
                    employee={query.actionOwner}
                    onEmployeeChanged={handleChange('actionOwner')}
                    {...getFieldProps('actionOwner')}
                    {...getAccessMatrixProps(ActionItemFields.ActionOwnerEmail)}
                    {...dataTestId('ACTION_PLAN_ACTION_OWNER')}
                />
            </FormGridItem>
            <FormGridItem fullWidth>
                <MultiEmployeeSelect
                    label="Interested parties for receiving notifications"
                    employees={query.interestedParties}
                    onEmployeesChanged={handleChange('interestedParties')}
                    {...getFieldProps('interestedParties')}
                    {...getAccessMatrixProps(
                        ActionItemFields.InterestedParties
                    )}
                    {...dataTestId(
                        'ACTION_PLAN_INTERESTED_PARTIES_FOR_RECEIVING_NOTIFICATIONS'
                    )}
                />
            </FormGridItem>
            <FormGridItem>
                <ActionPlanTypeSelect
                    value={query.actionType}
                    onTypeChanged={handleChange('actionType')}
                    {...getFieldProps('actionType')}
                    {...getAccessMatrixProps(ActionItemFields.ActionTypeId)}
                />
            </FormGridItem>
            <FormGridItem>
                <KeyboardDatePicker
                    minDate={dayjs()}
                    label="Action deadline"
                    value={dayjs(query.actionDeadline)}
                    onChange={handleChangeTime}
                    slotProps={{
                        textField: {
                            ...getFieldProps('actionDeadline'),
                            ...getAccessMatrixProps(
                                ActionItemFields.ActionDeadline
                            ),
                            ...dataTestId('ACTION_PLAN_ACTION_DEADLINE'),
                        },
                    }}
                    {...getAccessMatrixProps(ActionItemFields.ActionDeadline)}
                />
            </FormGridItem>
            <FormGridItem fullWidth>
                <TextField
                    label="Action name"
                    {...getTextFieldProps('actionName')}
                    {...getAccessMatrixProps(ActionItemFields.ActionName)}
                    {...dataTestId('ACTION_PLAN_ACTION_NAME_FIELD')}
                />
            </FormGridItem>
            <FormGridItem fullWidth>
                <TextField
                    label="Action description"
                    multiline
                    {...getTextFieldProps('actionDescription')}
                    {...getAccessMatrixProps(
                        ActionItemFields.ActionDescription
                    )}
                    {...dataTestId('ACTION_PLAN_ACTION_ACTION_DESCRIPTION')}
                />
            </FormGridItem>
            <FormGridItem fullWidth>
                {!isNewItem ? (
                    <AttachmentsNext
                        attachmentType={ActionItemAttachmentType.Description}
                        attachments={query.descriptionAttachments}
                        addAttachment={addAttachment}
                        removeAttachment={removeAttachment}
                        label="Description Attachments"
                        disabled={checkIsDisabled(
                            ActionItemFields.DescriptionAttachments
                        )}
                    />
                ) : (
                    <Typography>
                        To add attachments save action item as draft
                    </Typography>
                )}
            </FormGridItem>

            {!isNewItem && (!isDraftStatus || query.activitiesDescription) && (
                <FormGridItem fullWidth>
                    <TextField
                        label="Activities description"
                        multiline
                        {...getTextFieldProps('activitiesDescription')}
                        {...getAccessMatrixProps(
                            ActionItemFields.ActivitiesDescription
                        )}
                        {...dataTestId('ACTION_PLAN_ACTIVITIES_DESCRIPTION')}
                    />
                </FormGridItem>
            )}

            {!isNewItem &&
                (!isDraftStatus || 0 < query.activitiesAttachments.length) && (
                    <FormGridItem fullWidth>
                        <AttachmentsNext
                            attachmentType={ActionItemAttachmentType.Activities}
                            attachments={query.activitiesAttachments}
                            addAttachment={addAttachment}
                            removeAttachment={removeAttachment}
                            label="Activities attachments"
                            disabled={checkIsDisabled(
                                ActionItemFields.ActivitiesAttachments
                            )}
                        />
                    </FormGridItem>
                )}
        </CardView>
    )
}

export default ActionPlanItem
