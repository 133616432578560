import { Chip } from '@mui/material'
import { HTMLAttributes, useMemo } from 'react'
import { IssueTypes, RcaReportTypes } from 'types/enums'
import { OrganizationalInfo } from 'types/issueTypes'
import { getRcaReportTypeName } from '../../utils/formaters'
import { useStyles } from './ReportsView'

const IA_LINK =
    'https://abb.sharepoint.com/teams/PAqualityandcontinuousimprovement/Shared%20Documents/Forms/AllItems.aspx?FolderCTID=0x01200070CE1487AC90D6469F1FFB308AFB4277&viewid=9e2a7334%2D9e6f%2D4335%2D9fe3%2D415fcb7bc07a&id=%2Fteams%2FPAqualityandcontinuousimprovement%2FShared%20Documents%2FGeneral%2FTemplates'

const GENERAL_LINK =
    'https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/RCA-Templates.aspx'

export interface TemplateViewProps extends HTMLAttributes<HTMLDivElement> {
    issueType: IssueTypes
    resolvingUnitOrganizational: OrganizationalInfo
}

const TemplateView = (props: TemplateViewProps) => {
    const { issueType, resolvingUnitOrganizational, ...other } = props
    const classes = useStyles(props)
    const divisionCode = resolvingUnitOrganizational?.division?.code

    const generatedLink = useMemo(() => {
        if (divisionCode?.includes('IA')) return IA_LINK
        return GENERAL_LINK
    }, [divisionCode])

    return (
        <div className={classes.root} {...other}>
            <span className={classes.label}>Template repository </span>
            <div>
                <Chip
                    className={classes.chip}
                    label={getRcaReportTypeName(RcaReportTypes.RcaTemplates)}
                    onClick={() => window.open(generatedLink, '_blank')}
                    variant="outlined"
                />
            </div>
        </div>
    )
}

export default TemplateView
