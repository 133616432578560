import { useSelector, useDispatch } from 'react-redux'
import Closing from './Closing'
import { IssueStoreState, setExpandMode, updateClosing } from 'store/issue'
import { RootState } from 'store/types'
import { AttachmentTypes } from 'types/enums'

const ClosingContainer = (props: any) => {
    const { className } = props
    const dispatch = useDispatch()
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const {
        context,
        issueNumber,
        closingSection,
        expanded,
        attachments,
        captureSection,
    } = issue
    return (
        <Closing
            issueType={captureSection?.issueType}
            issueStatusId={issue.issueStatusId}
            className={className}
            closingSection={closingSection}
            captureSection={captureSection}
            issueNumber={issueNumber}
            context={context}
            expanded={Boolean(expanded?.closed)}
            onExpand={(expanded) => dispatch(setExpandMode('closed', expanded))}
            closingChange={(key, value) => dispatch(updateClosing(key, value))}
            closingExecutionChange={(key, value) =>
                dispatch(updateClosing(key, value))
            }
            attachments={
                attachments?.filter(
                    (a) => a.attachmentType === AttachmentTypes.Acceptance
                ) ?? []
            }
        />
    )
}

export default ClosingContainer
