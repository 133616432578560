import ToAssign from './ToAssign'
import ToVerifyHighImpact from './ToVerifyHighImpact'
import ToVerifyResolution from './ToVerifyResolution'
import ToVerifyContainmentAction from './ToVerifyContainmentAction'
import ToCompleteIssue from './ToComplete'
import GenericActionTile from './GenericActionTile'
import ToResolve from './ToResolve'
import ToSubmit from './ToSubmit'
import ToReplaceInactiveROActionTile from './ToReplaceInactiveROActionTile'
import ToReplaceInactiveRUActionTile from './ToReplaceInactiveRUActionTile'
import ToReplaceInactiveIOActionTile from './ToReplaceInactiveIOActionTile'
import EffectivnessCheck from './EffectivnessCheck'
import { MyAction } from '../../types/myDashboard'
import { IssueActionsTypes } from 'types/enums'
import { memo } from 'react'

export interface ActionSelectorProps {
    actionIssue: MyAction
}

export const ActionSelector = (props: ActionSelectorProps) => {
    const issue = props.actionIssue
    if (issue && issue.action) {
        switch (issue.action.actionCode) {
            case IssueActionsTypes.To_Submit_Returned_To_Draft:
            case IssueActionsTypes.To_Submit:
                return <ToSubmit {...props} />
            case IssueActionsTypes.To_Assign:
                return <ToAssign {...props} />
            case IssueActionsTypes.Resolution_Rejected:
            case IssueActionsTypes.To_Resolve:
                return <ToResolve {...props} />
            case IssueActionsTypes.To_Verify_Resolution:
                return <ToVerifyResolution {...props} />
            case IssueActionsTypes.To_Execute_Containment_Actions:
                return <ToVerifyContainmentAction {...props} />
            case IssueActionsTypes.To_Verify_HI:
                return <ToVerifyHighImpact {...props} />
            case IssueActionsTypes.To_Complete:
            case IssueActionsTypes.To_Complete_After_Customer_Execution:
                return <ToCompleteIssue {...props} />
            case IssueActionsTypes.Effectiveness_Sustainability_Check:
                return <EffectivnessCheck {...props} />
            case IssueActionsTypes.To_Assign_Updated_RU:
                return <ToReplaceInactiveRUActionTile {...props} />
            case IssueActionsTypes.To_Replace_Inactive_RO:
                return <ToReplaceInactiveROActionTile {...props} />
            case IssueActionsTypes.To_Replace_Inactive_IO:
                return <ToReplaceInactiveIOActionTile {...props} />
            case IssueActionsTypes.To_Replace_Inactive_RU:
                return <ToReplaceInactiveRUActionTile {...props} />
            default:
                return <GenericActionTile {...props} />
        }
    }
}

export default memo(ActionSelector)
