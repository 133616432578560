import SwapVertIcon from '@mui/icons-material/SwapVert'
import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Button, ButtonProps } from 'components'
import { dataTestId } from 'utils'

export interface ExportButtonProps extends ButtonProps {
    loading?: boolean
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        swapIcon: {
            marginRight: theme.spacing(1),
        },
        loadingIcon: {
            marginRight: theme.spacing(1.5),
        },
    }),
    { name: 'ExportButton' }
)

export const ExportButton = (props: ExportButtonProps) => {
    const { loading, children, onClick, disabled, className, ...other } = props
    const classes = useStyles()
    return (
        <Button
            disabled={disabled || loading}
            className={clsx(className)}
            onClick={onClick}
            {...dataTestId('DASHBOARD_EXPORT_TO_EXCEL')}
            {...other}
        >
            {loading ? (
                <>
                    <CircularProgress
                        className={classes.loadingIcon}
                        color="secondary"
                        size={24}
                    />
                </>
            ) : (
                <SwapVertIcon className={classes.swapIcon} />
            )}
            {children}
        </Button>
    )
}

export default ExportButton
