import { HelpOutline } from '@mui/icons-material'
import { IconButton, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            alignItems: 'center',
        },
        iconButton: {
            marginLeft: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            color: theme.palette.text.primary,
        },
    }),
    { name: 'PageTitle' }
)

export interface PageTitleProps {
    title: string
    desc?: string
    to?: string
}

export const PageTitle = (props: PageTitleProps) => {
    const { title, desc, to } = props
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h4">{title}</Typography>
                {to && (
                    <Tooltip title="Help page">
                        <IconButton
                            size="small"
                            className={classes.iconButton}
                            href={to}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <HelpOutline className={classes.icon} />
                        </IconButton>
                    </Tooltip>
                )}
            </div>
            {desc && <Typography variant="body2">{desc}</Typography>}
        </div>
    )
}
