import { createContext } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { DashboardInitState, getInitQueryForItemContainer } from '..'
import {
    ActionItem,
    ActionItemActions,
    ActionPlanStatusEnum,
} from 'types/actionPlanTypes'
import { getInitDataForDashboard } from './ActionPlanContextProvider'

export enum DashboardStatusType {
    INITIAL,
    FAILED,
    SUCCESS,
}

export interface DialogStateFields {
    title: string
    description: string
    requiredReason: 'ReopenReason' | 'RejectReason'
    buttonName: string
    source: ActionPlanStatusEnum
    destination: ActionPlanStatusEnum
    dropResult: DropResult
    ['data-test-id']: string
}

export interface ActionPlanContextProps {
    updateActionItemsDashboard: (actionItems: ActionItem[]) => void
    removeActionItemFromDashboardState: (actionItem: ActionItem) => void
    updateDashboardItemAndChangePosition: (
        source: ActionPlanStatusEnum,
        destination: ActionPlanStatusEnum,
        dropResult: DropResult,
        updatedActionItem: ActionItem
    ) => void
    dashboardState: DashboardInitState
    updateDashboardState: (state: Partial<DashboardInitState>) => void
    dashboardStatus: number
    currentActionItem: ActionItem
    loading: boolean
    loadingDesc: string
    error: {
        error?: string
        errors?: Array<{
            field: string
            message: string
        }>
        status?: number
    }
    setError: (error: unknown) => void
    dialogState: {
        visible: boolean
        fields: DialogStateFields
        func: ActionItemActions
        actionItem: ActionItem
    }
    catchErrorsAndLoading: (cb: () => void, loadingDesc: string) => void
    updateCurrentActionItem: (actionItem: Partial<ActionItem>) => void
    closeDialog: () => void
    openActionItem: (
        issueIdOrNumber: number | string,
        actionId?: number
    ) => void
    assignActionItem: (actionItem: ActionItem, dropResult?: DropResult) => void
    rejectActionItem: (actionItem: ActionItem, dropResult?: DropResult) => void
    completeActionItem: (
        actionItem: ActionItem,
        dropResult?: DropResult
    ) => void
    reopenActionItem: (actionItem: ActionItem, dropResult?: DropResult) => void
    deleteActionItem: (actionItem: ActionItem, dropResult?: DropResult) => void
    saveActionItem: (actionItem: ActionItem) => void
}
export const ActionPlanContext = createContext<ActionPlanContextProps>({
    updateActionItemsDashboard: null,
    removeActionItemFromDashboardState: null,
    updateDashboardItemAndChangePosition: null,
    dashboardState: getInitDataForDashboard(),
    updateDashboardState: null,
    dashboardStatus: DashboardStatusType.INITIAL,
    currentActionItem: getInitQueryForItemContainer(),
    loading: false,
    loadingDesc: '',
    error: null,
    setError: null,
    dialogState: null,
    catchErrorsAndLoading: null,
    closeDialog: null,
    updateCurrentActionItem: null,
    openActionItem: (issueIdOrNumber: number | string, actionId?: number) => {},
    assignActionItem: (actionItem: ActionItem, dropResult?: DropResult) => {},
    rejectActionItem: (actionItem: ActionItem, dropResult?: DropResult) => {},
    completeActionItem: (actionItem: ActionItem, dropResult?: DropResult) => {},
    reopenActionItem: (actionItem: ActionItem, dropResult?: DropResult) => {},
    deleteActionItem: (actionItem: ActionItem, dropResult?: DropResult) => {},
    saveActionItem: (actionItem: ActionItem) => {},
})
