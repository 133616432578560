import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ExportButton } from 'components'
import { showSnackbar } from 'store/app'
import { AdminHubInfo } from 'types/adminTypes'
import { generateExcelFromTemplate1 } from 'utils'

export interface HubsManagePageExcelExportProps {
    hubs: AdminHubInfo[]
    isSearching: boolean
}

export const HubsManagePageExcelExport = (
    props: HubsManagePageExcelExportProps
) => {
    const { hubs, isSearching } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()

    const excelExport = async () => {
        setLoading(true)
        try {
            await generateExcelFromTemplate1<AdminHubInfo>(
                {
                    columns: [
                        {
                            name: 'Business Area',
                            getValue: ({ divisionCode }) => divisionCode,
                        },
                        {
                            name: 'DIV',
                            getValue: ({ businessUnitCode }) =>
                                businessUnitCode,
                        },
                        {
                            name: 'PG',
                            getValue: ({ productGroupCode }) =>
                                productGroupCode,
                        },
                        {
                            name: 'Lead Country',
                            getValue: ({ leadCountryName }) => leadCountryName,
                        },
                        {
                            name: 'Hub Code',
                            getValue: ({ code: hubCode }) => hubCode,
                        },
                        {
                            name: 'Hub Name',
                            getValue: ({ name: hubName }) => hubName,
                        },
                        {
                            name: 'Hub Process Owners',
                            getValue: ({ hubProcessOwners }) =>
                                hubProcessOwners
                                    ?.map((p) => p.email)
                                    ?.join(', ') ?? '',
                        },
                        {
                            name: 'Belonging Countries',
                            getValue: ({ belongingCountriesWithNames }) =>
                                belongingCountriesWithNames,
                        },
                        {
                            name: 'Is Active?',
                            getValue: ({ isActive }) => String(isActive),
                        },
                    ],
                },
                hubs,
                {
                    fileName: 'export-hubs',
                }
            )
        } catch {
            dispatch(showSnackbar('Export failed'))
        } finally {
            dispatch(showSnackbar('Export completed successfully'))
            setTimeout(() => setLoading(false), 1000)
        }
    }

    return (
        hubs.length > 0 && (
            <ExportButton
                loading={loading}
                disabled={isSearching}
                onClick={excelExport}
            >
                Export to Excel
            </ExportButton>
        )
    )
}
