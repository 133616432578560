import { DialogModeType } from 'Admin'
import { AdminProcessOwner, ProcessOwner } from 'types/adminTypes'
import { OrganizationalInfo } from 'types/issueTypes'
import {
    ProcessOwnerGroup,
    ProcessOwnersDialogFields,
    ProcessOwnersDialogForInitQuery,
} from './types'

export const getCommonTitlesForProcessOwners = (): Record<
    ProcessOwnerGroup,
    string
> => {
    return {
        businessArea: 'Business Area process owners',
        businessAreaAndRegionOrCountry:
            'Business Area and Region/Country Process owners',
        businessUnit: 'Division process owners',
        businessUnitAndRegionOrCountry:
            'Division and Region/Country Process owners',
        productGroup: 'Product group process owners',
        productGroupAndRegionOrCountry:
            'Product group and Region/Country Process owners',
    }
}

export const getInitProcessOwnerGroups = () => {
    const titles = getCommonTitlesForProcessOwners()

    const groups: Record<
        ProcessOwnerGroup,
        {
            title: string
            items: ProcessOwner[]
            dialogMode: DialogModeType
            organizationInfo?: OrganizationalInfo
        }
    > = {
        businessArea: {
            title: titles.businessArea,
            items: [],
            dialogMode: 'businessArea',
        },
        businessAreaAndRegionOrCountry: {
            title: titles.businessAreaAndRegionOrCountry,
            items: [],
            dialogMode: 'businessAreaAndRegionOrCountry',
        },
        businessUnit: {
            title: titles.businessUnit,
            items: [],
            dialogMode: 'businessUnit',
        },
        businessUnitAndRegionOrCountry: {
            title: titles.businessUnitAndRegionOrCountry,
            items: [],
            dialogMode: 'businessUnitAndRegionOrCountry',
        },
        productGroup: {
            title: titles.productGroup,
            items: [],
            dialogMode: 'productGroup',
        },
        productGroupAndRegionOrCountry: {
            title: titles.productGroupAndRegionOrCountry,
            items: [],
            dialogMode: 'productGroupAndRegionOrCountry',
        },
    }

    return groups
}

export const generateProcessOwnerGroups = (
    adminProcessOwner: AdminProcessOwner
) => {
    const { processOwners } = adminProcessOwner
    const groups = getInitProcessOwnerGroups()

    processOwners.forEach((processOwner) => {
        const { organizationInfo } = processOwner
        const isRegionOrCountry =
            organizationInfo.region || organizationInfo.country

        const addToGroup = (fieldName: ProcessOwnerGroup) => {
            groups[fieldName].items.push(processOwner)
            groups[fieldName].organizationInfo = {
                ...processOwner.organizationInfo,
            }
        }

        if (organizationInfo.productGroup?.code) {
            if (isRegionOrCountry) {
                addToGroup('productGroupAndRegionOrCountry')
            } else {
                addToGroup('productGroup')
            }
        } else if (organizationInfo.businessUnit?.code) {
            if (isRegionOrCountry) {
                addToGroup('businessUnitAndRegionOrCountry')
            } else {
                addToGroup('businessUnit')
            }
        } else if (organizationInfo.division?.code) {
            if (isRegionOrCountry) {
                addToGroup('businessAreaAndRegionOrCountry')
            } else {
                addToGroup('businessArea')
            }
        }
    })

    return groups
}

export const getInitQueryForDialog = (): ProcessOwnersDialogForInitQuery => ({
    employee: null,
    businessArea: null,
    division: null,
    productGroup: null,
    region: null,
    country: null,
})

export const getOptionsAndFields = () => {
    const fields: ProcessOwnersDialogFields = {}
    Object.keys(getInitQueryForDialog()).forEach((key) => {
        fields[key] = key
    })
    const titles = getCommonTitlesForProcessOwners()
    const options: { title: string; fields: string[]; mode: DialogModeType }[] =
        [
            {
                mode: 'all',
                title: 'All',
                fields: [
                    fields.employee,
                    fields.businessArea,
                    fields.division,
                    fields.productGroup,
                    fields.region,
                    fields.country,
                ],
            },
            {
                mode: 'businessAreaAndRegionOrCountry',
                title: titles.businessAreaAndRegionOrCountry,
                fields: [
                    fields.employee,
                    fields.businessArea,
                    fields.region,
                    fields.country,
                ],
            },
            {
                mode: 'businessArea',
                title: titles.businessArea,
                fields: [fields.employee, fields.businessArea],
            },
            {
                mode: 'businessUnitAndRegionOrCountry',
                title: titles.businessUnitAndRegionOrCountry,
                fields: [
                    fields.employee,
                    fields.division,
                    fields.region,
                    fields.country,
                ],
            },
            {
                mode: 'businessUnit',
                title: titles.businessUnit,
                fields: [fields.employee, fields.division],
            },
            {
                mode: 'productGroupAndRegionOrCountry',
                title: titles.productGroupAndRegionOrCountry,
                fields: [
                    fields.employee,
                    fields.productGroup,
                    fields.region,
                    fields.country,
                ],
            },
            {
                mode: 'productGroup',
                title: titles.productGroup,
                fields: [fields.employee, fields.productGroup],
            },
        ]

    return {
        options,
        fields,
    }
}

export const generateHeaderForProcessOwnerCard = (
    organizationalInfo: OrganizationalInfo
): string => {
    const { division, businessUnit, productGroup } = organizationalInfo
    const parsedBusinessUnit = businessUnit?.code
        ? ' | ' + businessUnit.code
        : ''
    const parsedProductGroup = productGroup?.code
        ? ' | ' + productGroup.code
        : ''

    return `${division?.code}${parsedBusinessUnit}${parsedProductGroup}`
}

export const getFilteredWithEmptyProcessOwners = (
    element: {
        title: string
        items: ProcessOwner[]
        dialogMode: DialogModeType
        organizationInfo?: OrganizationalInfo
    }[],
    adminProcessOwner: AdminProcessOwner
) => {
    const getTitleForProcessOwners = (): {
        unit: DialogModeType
        location: DialogModeType
    } => {
        if (adminProcessOwner.organizationalInfo?.productGroup.code) {
            return {
                unit: 'productGroup',
                location: 'productGroupAndRegionOrCountry',
            }
        }
        if (adminProcessOwner.organizationalInfo?.businessUnit.code) {
            return {
                unit: 'businessUnit',
                location: 'businessUnitAndRegionOrCountry',
            }
        }
        if (adminProcessOwner.organizationalInfo?.division.code) {
            return {
                unit: 'businessArea',
                location: 'businessAreaAndRegionOrCountry',
            }
        }
    }

    const dialogTypes = getTitleForProcessOwners()
    const titles = getCommonTitlesForProcessOwners()

    const addGroup = (value: DialogModeType) => ({
        title: titles[value],
        items: [],
        dialogMode: value,
        organizationInfo: adminProcessOwner.organizationalInfo,
    })

    if (element.length === 2) return element

    if (element.length === 0)
        return [addGroup(dialogTypes.unit), addGroup(dialogTypes.location)]

    return element[0].dialogMode === dialogTypes.unit
        ? [element[0], addGroup(dialogTypes.location)]
        : [addGroup(dialogTypes.unit), element[0]]
}
