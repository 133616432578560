import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { ProcessOwner } from 'types/adminTypes'
import { generateExcelFromTemplate1 } from 'utils'
import { ProcessOwnersManagePageExcelExportProps } from 'Admin'
import { ExportButton } from 'components'

export const ProcessOwnersManagePageExcelExport = (
    props: ProcessOwnersManagePageExcelExportProps
) => {
    const dispatch = useDispatch()
    const { adminProcessOwners } = props
    const [loading, setLoading] = useState(false)

    const excelExport = async () => {
        setLoading(true)

        try {
            const items: ProcessOwner[] = []
            adminProcessOwners.forEach((x) => {
                x.processOwners.forEach((item) => {
                    items.push(item)
                })
            })
            generateExcelFromTemplate1<ProcessOwner>(
                {
                    columns: [
                        {
                            name: 'BA',
                            getValue: ({ organizationInfo }) =>
                                organizationInfo.division?.code,
                        },
                        {
                            name: 'DIV',
                            getValue: ({ organizationInfo }) =>
                                organizationInfo.businessUnit?.code,
                        },
                        {
                            name: 'PG',
                            getValue: ({ organizationInfo }) =>
                                organizationInfo.productGroup?.code,
                        },
                        {
                            name: 'Region',
                            getValue: ({ organizationInfo }) =>
                                organizationInfo.region,
                        },
                        {
                            name: 'Country',
                            getValue: ({ organizationInfo }) =>
                                organizationInfo.country,
                        },
                        {
                            name: 'Scope',
                            getValue: ({ organizationInfo }) => {
                                const isLocation =
                                    organizationInfo.region ||
                                    organizationInfo.country

                                return isLocation ? 'Unit&Location' : 'Unit'
                            },
                        },
                        {
                            name: 'FirstName',
                            getValue: ({ firstName }) => firstName,
                        },
                        {
                            name: 'LastName',
                            getValue: ({ lastName }) => lastName,
                        },
                        {
                            name: 'Phone',
                            getValue: ({ phone }) => phone,
                        },
                        {
                            name: 'Email',
                            getValue: ({ email }) => email,
                        },
                        {
                            name: 'IsActive',
                            getValue: ({ isActive }) =>
                                isActive ? 'True' : 'False',
                        },
                    ],
                },
                items,
                {
                    fileName: 'export-process-owners',
                }
            )
        } catch {
            dispatch(showSnackbar('Export failed'))
        } finally {
            dispatch(showSnackbar('Export completed successfully'))
            setTimeout(() => setLoading(false), 1000)
        }
    }

    return (
        adminProcessOwners.length > 0 && (
            <ExportButton loading={loading} onClick={excelExport}>
                Export to Excel
            </ExportButton>
        )
    )
}
