import { Button } from '@mui/material'
import { NavButton, ReturnToIssueOwnerDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { returnToIO } from 'store/myActions'
import { ReturnToIssueOwnerQuery } from 'types/actions'
import { TileActionsProps } from '..'

export const ToAssignActions = (props: TileActionsProps) => {
    const { myActionMeta, issueNumber } = props
    const [open, setOpen] = useState<boolean>(false)
    const dispatch = useDispatch()
    const handleReturnToIssue = (formData: ReturnToIssueOwnerQuery) => {
        setOpen(false)
        dispatch(returnToIO(formData))
    }
    return (
        <>
            <Button
                onClick={() => setOpen(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .RETURN_TO_ISSUE_OWNER}
                eventName="Return not assigne issue to Issue Owner"
            >
                Return to Issue Owner
            </Button>
            <NavButton
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.ASSIGN}
                color="secondary"
                eventName="Assign issue on dashboard"
                linkProps={{ to: `/issue/${myActionMeta.issueId}` }}
            >
                Assign
            </NavButton>
            <ReturnToIssueOwnerDialog
                open={open}
                issueId={myActionMeta.issueId}
                number={issueNumber}
                rowVersion={myActionMeta.rowVersion}
                onClose={() => setOpen(false)}
                onReturnIssue={(formData) => handleReturnToIssue(formData)}
            />
        </>
    )
}
