import {
    getDraftState,
    getDefaultState,
    IssueStoreState,
    getHighImpact,
    IssueChangeLog,
} from './initState'
import * as ACTIONS from './actionsTypes'

import { AnyAction } from 'redux'
import {
    Issue,
    Attachment,
    CaptureSection,
    HighImpact,
    ResolutionSection,
    EffectivenessSection,
    IssueContext,
    AssignSection,
    ResolvingUnit,
    AttachmentNewIssueStatus,
} from 'types/issueTypes'
import {
    getCaptureSectionKey,
    getHighImpactKey,
    getHighImpactCategoriesKeys,
    getResolutionSectionKey,
    getEffectivenessSectionKey,
    getAssignSectionKey,
} from 'Issue/matrix'
import {
    IssueValuesKeys,
    QuickFixVariants,
    AttachmentTypes,
    IssueTypes,
    IssueActionsTypes,
    IssueCreationTypes,
} from 'types/enums'
import { AuthenticationActions } from 'react-aad-msal'
import { LoadIssueActionPlanDoneCommand } from './types'
import { IssueChangeLogState } from '.'
import { ProductLineLocationProps } from 'Issue/Pickers'
import { getProductLineLocationName } from 'utils'

const setLoading = (message: string = null): Partial<IssueStoreState> => ({
    loading: true,
    error: null,
    loadingDesc: message,
})

const setFail = (error: any): Partial<IssueStoreState> => ({
    loading: false,
    loadingDesc: null,
    error,
})

const setDone = (): Partial<IssueStoreState> => ({
    loading: false,
    error: null,
    loadingDesc: null,
    errorsList: [],
})

const removeCaptureSectionError = (
    state: IssueStoreState,
    key: keyof CaptureSection
) => {
    const issueKey = getCaptureSectionKey(key)
    if (state?.captureSection && state.errorsList) {
        state.errorsList = state.errorsList.filter(
            (err) => err.field !== issueKey
        )
    }
}

const removeAssignSectionError = (
    state: IssueStoreState,
    key: keyof AssignSection
) => {
    const issueKey = getAssignSectionKey(key)
    if (state?.captureSection && state.errorsList) {
        state.errorsList = state.errorsList.filter(
            (err) => err.field !== issueKey
        )
    }
}

const removeResolutionSectionError = (
    state: IssueStoreState,
    key: keyof ResolutionSection
) => {
    const issueKey = getResolutionSectionKey(key)
    if (state?.captureSection && state.errorsList) {
        state.errorsList = state.errorsList.filter(
            (err) => err.field !== issueKey
        )
    }
}

const removeEffectivenessSectionError = (
    state: IssueStoreState,
    key: keyof EffectivenessSection
) => {
    const issueKey = getEffectivenessSectionKey(key)
    if (state?.captureSection && state.errorsList) {
        state.errorsList = state.errorsList.filter(
            (err) => err.field !== issueKey
        )
    }
}

const createLoadingAction =
    (loadingMessage: string = '') =>
    (state: IssueStoreState) => ({
        ...state,
        ...setLoading(loadingMessage),
    })

const createDoneAction =
    (changeLogKey: keyof IssueChangeLogState = null) =>
    (state: IssueStoreState) => {
        const newState = {
            ...state,
            ...setDone(),
        }
        if (changeLogKey) {
            newState.changeLog = { ...state.changeLog, [changeLogKey]: {} }
        }
        return newState
    }

const reducersIndex: {
    [key: string]: (state: IssueStoreState, action: any) => IssueStoreState
} = {
    [ACTIONS.ISSUE_EXPAND_SECTION]: (state: IssueStoreState, action: any) => ({
        ...state,
        expanded: {
            ...state.expanded,
            [action.payload.sectionName]: action.payload.expanded,
        },
    }),
    [ACTIONS.ISSUE_EXPAND_INIT]: (state: IssueStoreState, action: any) => ({
        ...state,
        expanded: {
            ...action.payload,
        },
    }),
    [ACTIONS.ISSUE_LIGHT_MODE]: (state: IssueStoreState, action: any) => ({
        ...state,
        lightMode: action.payload,
    }),
    [ACTIONS.ISSUE_SELECT_ROLES]: (state: IssueStoreState, action: any) => ({
        ...state,
        selectedRolesCodes: [...action.payload],
    }),
    [ACTIONS.ISSUE_LOAD_CALL]: (state: IssueStoreState) => {
        const stateLoadCall = createLoadingAction('Loading issue…')
        return {
            ...stateLoadCall(state),
            issueLoadDone: false,
        }
    },
    [ACTIONS.ISSUE_SAVE_CLOSING_CALL]: createLoadingAction(
        'Saving issue closure section…'
    ),
    [ACTIONS.ISSUE_SAVE_EFFECTIVENESS_CALL]: createLoadingAction(
        'Saving effectiveness and sustainability verification section…'
    ),
    [ACTIONS.ISSUE_REOPEN_CALL]: createLoadingAction('Returning the issue…'),
    [ACTIONS.ISSUE_CONFRIM_EFFECTIVENESS_CALL]: createLoadingAction(
        'Confirming the issue…'
    ),
    [ACTIONS.ISSUE_REVERT_TO_NOT_ASSIGNED_CALL]: createLoadingAction(
        'Reverting the issue to Not assigned…'
    ),
    [ACTIONS.ISSUE_SAVE_CALL]: createLoadingAction('Saving issue…'),
    [ACTIONS.ISSUE_SEND_MESSAGE_CALL]: createLoadingAction('Sending message…'),
    [ACTIONS.ISSUE_SUBMIT_CALL]: createLoadingAction('Submitting the issue…'),
    [ACTIONS.ISSUE_SAVE_AS_CALL]: createLoadingAction('Saving issue…'),
    [ACTIONS.ISSUE_DELETE_CALL]: createLoadingAction('Deleting draft…'),
    [ACTIONS.ISSUE_CANCEL_CALL]: (state: IssueStoreState) => ({
        ...state,
        error: null,
        loading: false,
        loadingDesc: null,
    }),
    [ACTIONS.ISSUE_CLEAR_ERROR]: (state: IssueStoreState) => ({
        ...state,
        error: null,
        loading: false,
        loadingDesc: null,
    }),
    [ACTIONS.ISSUE_LOAD_DONE]: (state: IssueStoreState, action: any) => {
        var issue = action.payload as Issue
        return {
            ...state,
            ...setDone(),
            ...issue,
            oldTargetResolutionAndVerificationDate:
                issue?.captureSection?.targetResolutionAndVerificationDate,
            captureSection: issue?.captureSection
                ? {
                      ...state.captureSection,
                      ...issue.captureSection,
                  }
                : null,
            assignSection: issue.assignSection
                ? { ...state.assignIssue, ...issue.assignSection }
                : null,
            resolutionSection: issue.resolutionSection
                ? {
                      ...state.resolutionSection,
                      ...issue.resolutionSection,
                  }
                : null,
            closingSection: issue.closingSection
                ? {
                      ...state.closingSection,
                      ...issue.closingSection,
                  }
                : null,
            effectivenessSection: issue.effectivenessSection
                ? {
                      ...state.effectivenessSection,
                      ...issue.effectivenessSection,
                  }
                : null,
            changeLog: {},
            issueLoadDone: true,
        }
    },
    [ACTIONS.ISSUE_EXTEND_TARGET_RESOLUTION_DATE_CALL]: createLoadingAction(
        'Extending Target Resolution and Verification date…'
    ),
    [ACTIONS.ISSUE_LOAD_CONTEXT_CALL]: createLoadingAction(
        'Loading issue context…'
    ),
    [ACTIONS.ISSUE_ATTACHMENTS_CALL]: createLoadingAction(
        'Loading attachments…'
    ),
    [ACTIONS.ISSUE_SAVE_DONE]: (state: IssueStoreState, action: any) => {
        const newCaptureSection: CaptureSection = action?.payload || {}
        return {
            ...state,
            ...setDone(),
            oldTargetResolutionAndVerificationDate:
                newCaptureSection?.targetResolutionAndVerificationDate,
            captureSection: {
                ...newCaptureSection,
            },
            changeLog: {
                ...state.changeLog,
                capture: {},
            },
        }
    },

    [ACTIONS.ISSUE_SET_MATRIX]: (state: IssueStoreState, action: any) => ({
        ...state,
        currentActions: [...action.payload.currentActions],
        dependedFields: { ...action.payload.dependedFields },
    }),
    [ACTIONS.ISSUE_LOAD_MATRIX_CALL]: createLoadingAction(
        'Loading issue permissions…'
    ),
    [ACTIONS.ISSUE_UPDATE_HIGH_IMPACT]: (
        state: IssueStoreState,
        action: any
    ) => {
        const propKey = getHighImpactKey(action.payload.key as keyof HighImpact)
        if (state?.captureSection?.highImpact && state.errorsList) {
            if (
                propKey === IssueValuesKeys.PotentialBusinessImpact ||
                propKey === IssueValuesKeys.ImpactReason
            ) {
                state.errorsList = state.errorsList.filter(
                    (err) => err.field !== propKey
                )
            } else {
                ;(
                    [...getHighImpactCategoriesKeys()] as Array<IssueValuesKeys>
                ).forEach((key) => {
                    state.errorsList = state.errorsList.filter(
                        (err) => err.field !== key
                    )
                })
            }
        }
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                highImpact: {
                    ...state.captureSection.highImpact,
                    [action.payload.key]: action.payload.value,
                },
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_ABB_PRODUCT]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeCaptureSectionError(state, 'productCID')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                productCID: action?.payload?.id ?? null,
                product: action.payload && {
                    ...action.payload,
                },
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_PRODUCT_LINE_LOCATION]: (
        state: IssueStoreState,
        action: { payload: ProductLineLocationProps }
    ) => {
        removeCaptureSectionError(state, 'productLineLocationId')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                productLineLocationId: action?.payload?.id ?? null,
                productLineLocation: action.payload && {
                    ...action.payload,
                },
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_FAILURE_MODE]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeCaptureSectionError(state, 'failureModeId')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                failureModeId: action?.payload?.id ?? null,
                failureModeName: action?.payload?.name ?? null,
                failureModeValidation: action?.payload?.validation ?? null,
                isFailureModeDescriptionRequired:
                    action?.payload?.isDescriptionRequired ?? null,
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_RESOLVING_UNIT]: (
        state: IssueStoreState,
        action: {
            payload: ResolvingUnit
        }
    ) => {
        removeCaptureSectionError(state, 'resolvingUnitId')

        let captureSection = { ...state.captureSection }
        const isDiffId =
            state?.captureSection?.resolvingUnitId !== action?.payload?.id
        const captureLog: IssueChangeLog = {}
        if (isDiffId) {
            const npsIssue =
                state?.captureSection?.issueCreationType ===
                IssueCreationTypes.NPS
            captureLog.ProductCID = [state.captureSection?.product?.name, null]
            captureLog.ProductLineLocationId = [
                getProductLineLocationName(
                    state.captureSection?.productLineLocation
                ),
                null,
            ]
            if (!npsIssue) {
                captureLog.FailureModeId = [
                    state.captureSection.failureModeName,
                    null,
                ]
            }

            captureSection = {
                ...state.captureSection,
                resolvingUnitId: action?.payload?.id ?? null,
                resolvingUnit: action.payload && {
                    ...action.payload,
                },
                failureModeId: npsIssue
                    ? state.captureSection?.failureModeId
                    : null,
                failureModeName: npsIssue
                    ? state.captureSection?.failureModeName
                    : null,
                failureModeDescription: npsIssue
                    ? state.captureSection?.failureModeDescription
                    : null,
                failureModeValidation: npsIssue
                    ? state.captureSection?.failureModeValidation
                    : null,
                productCID: null,
                product: null,
                productLineLocation: null,
                productLineLocationId: null,
            }
        }
        return {
            ...state,
            captureSection,
            changeLog: {
                ...state.changeLog,
                capture: { ...state.changeLog.capture, ...captureLog },
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_CUSTOMER]: (state: IssueStoreState, action: any) => {
        removeCaptureSectionError(state, 'customer')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                customer: action.payload && { ...action.payload },

                customerContact: null,
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_SUPPLIER]: (state: IssueStoreState, action: any) => {
        removeCaptureSectionError(state, 'supplier')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                supplier: {
                    ...action.payload,
                },
            },
        }
    },
    [ACTIONS.ISSUE_DELETE_SUPPLIER]: (state: IssueStoreState, action: any) => {
        removeCaptureSectionError(state, 'supplier')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                supplier: null,
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_CUSTOMER_CONTACT]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeCaptureSectionError(state, 'customerContact')
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                customerContact: action.payload && { ...action.payload },
            },
        }
    },
    [ACTIONS.ISSUE_SELECT_PG_CATEGORY]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeResolutionSectionError(state, 'pgRcaCategory')
        return {
            ...state,
            resolutionSection: {
                ...state.resolutionSection,
                pgRcaCategory: action.payload && { ...action.payload },
            },
        }
    },
    [ACTIONS.ISSUE_CAPTURE_UPDATE]: (state: IssueStoreState, action: any) => {
        removeCaptureSectionError(state, action.payload.key)
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                [action.payload.key]: action.payload.value,
            },
        }
    },
    [ACTIONS.ISSUE_UPDATE_RESOLUTION]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeResolutionSectionError(
            state,
            action.payload.key as keyof ResolutionSection
        )
        return {
            ...state,
            resolutionSection: {
                ...state.resolutionSection,
                [action.payload.key]: action.payload.value,
            },
            // changeLog: { ...state.changeLog, resolve: {} },
        }
    },
    [ACTIONS.ISSUE_EFFECTIVENESS_UPDATE]: (
        state: IssueStoreState,
        action: any
    ) => {
        removeEffectivenessSectionError(
            state,
            action.payload.key as keyof EffectivenessSection
        )
        return {
            ...state,
            effectivenessSection: {
                ...state.effectivenessSection,
                [action.payload.key]: action.payload.value,
            },
        }
    },
    [ACTIONS.ISSUE_WARRANTY_UPDATE]: (state: IssueStoreState, action: any) => {
        if (state?.captureSection?.warranty && state.errorsList) {
            const propPathKeys = `captureSection.warranty.${action.payload.key}`
            state.errorsList = state.errorsList.filter(
                (err) => err.field !== propPathKeys
            )
        }
        return {
            ...state,
            captureSection: {
                ...state.captureSection,
                warranty: {
                    ...state.captureSection.warranty,
                    [action.payload.key]: action.payload.value,
                },
            },
        }
    },
    [ACTIONS.ISSUE_UPDATE_CLOSING_SECTION]: (
        state: IssueStoreState,
        action: any
    ) => {
        return {
            ...state,
            closingSection: {
                ...state.closingSection,
                [action.payload.key]: action.payload.value,
            },
        }
    },
    [ACTIONS.ISSUE_UPDATE_QUICK_FIX]: (state: IssueStoreState, action: any) => {
        const quickFixVariant: QuickFixVariants = action.payload
        return {
            ...state,
            assignSection: {
                ...state.assignSection,
                quickFixVariant,
            },
        }
    },
    [ACTIONS.ISSUE_UPDATE_ASSIGN]: (state: IssueStoreState, action: any) => {
        removeAssignSectionError(
            state,
            action.payload.key as keyof AssignSection
        )
        return {
            ...state,
            assignSection: {
                ...state.assignSection,
                [action.payload.key]: action.payload.value,
            },
        }
    },
    [ACTIONS.ISSUE_SAVE_ASSIGN_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        errorsList: [],
        assignSection: {
            ...action.payload,
        },
        changeLog: { ...state.changeLog, assign: {} },
    }),
    [ACTIONS.ISSUE_REMOVE_RCA_DONE]: (state: IssueStoreState, action: any) => ({
        ...state,
        ...setDone(),
        errorsList: [],
        assignSection: {
            ...action.payload,
        },
        changeLog: { ...state.changeLog, assign: {} },
    }),
    [ACTIONS.ISSUE_NEW_INIT]: (state: IssueStoreState) => ({
        ...getDraftState(),
        attachmentsNewIssue: state.attachmentsNewIssue,
        ...setLoading('Preparing draft…'),
    }),
    [ACTIONS.ISSUE_REMOVE]: (state: IssueStoreState) => {
        return {
            ...getDefaultState(),
            attachmentsNewIssue: state.attachmentsNewIssue,
        }
    },
    [ACTIONS.ISSUE_SUBMIT_PREPARE]: (state: IssueStoreState) => {
        if (state?.captureSection?.issueType === IssueTypes.Internal_NC) {
            return {
                ...state,
                captureSection: {
                    whatAreCustomerExpectations: null,
                    customer: null,
                    customerContact: null,
                    highImpact: { ...getHighImpact() },
                    isRootCauseRequiredByCustomer: true,
                    isContainmentActionRequired: true,
                    quantity: 0,
                    ...state.captureSection,
                },
            }
        }
        return state
    },
    [ACTIONS.ISSUE_ADD_ATTACHMENT]: (state: IssueStoreState, action: any) => {
        const attachment = action.payload as Attachment
        if (
            state.errorsList &&
            attachment.attachmentType === AttachmentTypes.Resolution_Rca
        ) {
            state.errorsList = [...state.errorsList].filter(
                (e) => e.field !== String(IssueValuesKeys.RcaAttachments)
            )
        }
        return {
            ...state,
            attachments: [...(state?.attachments ?? []), { ...attachment }],
        }
    },

    [ACTIONS.NEW_ISSUE_ADD_ATTACHMENTS]: (
        state: IssueStoreState,
        action: any
    ) => {
        const attachments = action.payload as AttachmentNewIssueStatus
        return {
            ...state,
            attachmentsNewIssue: attachments,
        }
    },

    [ACTIONS.ISSUE_DELETE_ATTACHMENT]: (
        state: IssueStoreState,
        action: any
    ) => {
        const { id } = action.payload
        return {
            ...state,
            attachments: [
                ...(state?.attachments ?? []).filter((a) => a.id !== id),
            ],
        }
    },

    [ACTIONS.ISSUE_REFRESH_ATTACHMENTS]: (
        state: IssueStoreState,
        action: any
    ) => {
        if (!action.payload || action.payload.length === 0) {
            return state
        }
        return { ...state, attachments: [...action.payload] }
    },
    [ACTIONS.ISSUE_ATTACHMENTS_DONE]: (state: IssueStoreState, action: any) => {
        const attachments = action.payload
        return {
            ...state,
            ...setDone(),
            attachments,
        }
    },
    [ACTIONS.ISSUE_DELETE_CONTEXT]: () => ({}),
    [ACTIONS.ISSUE_COMPLETE_CALL]: createLoadingAction('Completing the issue…'),
    [ACTIONS.ISSUE_CONFIRM_HI_CALL]: createLoadingAction(
        'Confirming high imapct…'
    ),
    [ACTIONS.ISSUE_DOWNGRADE_HI_CALL]: createLoadingAction(
        'Downgrading issue impact…'
    ),
    [ACTIONS.ISSUE_RETURN_TO_OWNER_CALL]: createLoadingAction(
        'Returning the issue…'
    ),
    [ACTIONS.ISSUE_REJECT_RESOLUTION_CALL]: createLoadingAction(
        'Returning the issue…'
    ),
    [ACTIONS.ISSUE_REJECT_RESOLUTION_TO_RO_CALL]: createLoadingAction(
        'Reopening the issue…'
    ),
    [ACTIONS.ISSUE_REMOVE_RCA_CALL]: createLoadingAction('Removing RCA…'),
    [ACTIONS.ISSUE_SAVE_ASSIGN_CALL]: createLoadingAction(
        'Saving issue assignment section…'
    ),
    [ACTIONS.ISSUE_GENERATE_REPORT_CALL]:
        createLoadingAction('Generating report…'),
    [ACTIONS.ISSUE_SAVE_RESOLUTION_CALL]: createLoadingAction(
        'Saving resolution section…'
    ),
    [ACTIONS.ISSUE_GET_ISSUE_OWNER_CALL]: createLoadingAction(),
    [ACTIONS.ISSUE_RESOLVE_CALL]: createLoadingAction('Resolving the issue…'),
    [ACTIONS.ISSUE_ASSIGN_CALL]: createLoadingAction('Assigning the issue…'),
    [ACTIONS.ISSUE_VERIFY_RESOLUTION_CALL]: createLoadingAction(
        'Verifying resolution…'
    ),
    [ACTIONS.ISSUE_VERIFY_CONTAINMENT_CALL]: createLoadingAction(
        'Confirming containment actions…'
    ),
    [ACTIONS.ISSUE_CLEAR_LINKED_ISSUE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        assignSection: {
            ...state.assignSection,
            quickFixIssueId: -1,
            quickFixIssueNumber: null,
        },
    }),
    [ACTIONS.ISSUE_LOAD_MATRIX_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        accessMatrixes: [...action.payload],
    }),
    [ACTIONS.ISSUE_SAVE_AS_DONE]: (state: IssueStoreState, action: any) => ({
        ...state,
        ...setDone(),
        ...action.payload,
    }),
    [ACTIONS.ISSUE_SAVE_RESOLUTION_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        resolutionSection: {
            ...state.resolutionSection,
            ...action.payload,
        },
        changeLog: { ...state.changeLog, resolve: {} },
    }),

    [ACTIONS.ISSUE_SAVE_CLOSING_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        closingSection: {
            ...state.closingSection,
            ...action.payload,
        },
        changeLog: { ...state.changeLog, closed: {} },
    }),
    [ACTIONS.ISSUE_SAVE_EFFECTIVENESS_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        effectivenessSection: {
            ...state.effectivenessSection,
            ...action.payload,
        },
        changeLog: { ...state.changeLog, effectiveness: {} },
    }),
    [ACTIONS.ISSUE_CONFRIM_EFFECTIVENESS_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        ...setDone(),
        effectivenessSection: {
            ...state.effectivenessSection,
            ...action.payload,
        },
        changeLog: { ...state.changeLog, effectiveness: {} },
    }),
    [ACTIONS.ISSUE_GET_ISSUE_OWNER_DONE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        captureSection: {
            ...state.captureSection,
            issueOwner: { ...action.payload },
        },
    }),
    [ACTIONS.ISSUE_LOAD_CONTEXT_DONE]: (
        state: IssueStoreState,
        action: any
    ) => {
        const context = action?.payload?.context as IssueContext
        return {
            ...state,
            issueStatusId: context.issueStatusId,
            context: { ...context },
            captureSection: {
                ...state.captureSection,
                rowVersion: context.rowVersion,
            },
            assignSection: {
                ...state.assignSection,
                rowVersion: context.rowVersion,
            },
            resolutionSection: {
                ...state.resolutionSection,
                rowVersion: context.rowVersion,
            },
            closingSection: {
                ...state.closingSection,
                rowVersion: context.rowVersion,
            },
            effectivenessSection: {
                ...state.effectivenessSection,
                rowVersion: context.rowVersion,
            },
        }
    },
    [ACTIONS.ISSUE_ASSIGN_DONE]: createDoneAction('assign'),
    [ACTIONS.ISSUE_RESOLVE_DONE]: createDoneAction('resolve'),
    [ACTIONS.ISSUE_RETURN_TO_OWNER_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_REJECT_RESOLUTION_TO_RO_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_REJECT_RESOLUTION_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_COMPLETE_DONE]: createDoneAction('closed'),
    [ACTIONS.ISSUE_DELETE_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_REOPEN_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_SUBMIT_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_GENERATE_REPORT_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_VERIFY_RESOLUTION_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_VERIFY_CONTAINMENT_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_REVERT_TO_NOT_ASSIGNED_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_ACTION_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_SEND_MESSAGE_DONE]: createDoneAction(),
    [ACTIONS.ISSUE_ACTION_FAIL]: (state: IssueStoreState, action: any) => {
        if (action.payload?.error?.status === 412) {
            let errorsList = [...action.payload.error.errors]
            // If Issue owner has got some validation error then deactive light mode
            const issueOwnerError = errorsList?.some(
                (e) => e.field === IssueValuesKeys.IssueOwner
            )
            return {
                ...state,
                loading: false,
                error: null,
                errorsList,
                lightMode: issueOwnerError ? false : state.lightMode,
            }
        }
        return { ...state, ...setFail(action.payload.error) }
    },
    [ACTIONS.ISSUE_UPDATE_ROW_VERSION]: (
        state: IssueStoreState,
        action: any
    ) => {
        const rowVersion = action?.payload
        if (rowVersion) {
            return {
                ...state,
                rowVersion,
                captureSection: {
                    ...state.captureSection,
                    rowVersion,
                },
                assignSection: {
                    ...state.assignSection,
                    rowVersion,
                },
                resolutionSection: {
                    ...state.resolutionSection,
                    rowVersion,
                },
                closingSection: {
                    ...state.closingSection,
                    rowVersion,
                },
                effectivenessSection: {
                    ...state.effectivenessSection,
                    rowVersion,
                },
            }
        }
        return state
    },
    [ACTIONS.ISSUE_SET_WARRNINGS]: (state: IssueStoreState, action: any) => ({
        ...state,
        warrnings: action.payload,
    }),
    [ACTIONS.ISSSUE_SET_LOADING_STATE]: (
        state: IssueStoreState,
        action: any
    ) => ({
        ...state,
        loading: action.payload.loading,
        loadingDesc: action.payload.label,
    }),
    [ACTIONS.ISSUE_REMOVE_CONTEXT_ACTION]: (state, action) => ({
        ...state,
        context: {
            ...state.context,
            actions: state.context.actions.filter(
                (a) => a.actionCode !== (action.payload as IssueActionsTypes)
            ),
        },
    }),
    [ACTIONS.ISSUE_ACTION_PLAN_LOAD_CALL]: (state) => ({
        ...state,
        loadingActionPlan: true,
    }),
    [ACTIONS.ISSUE_ACTION_PLAN_LOAD_DONE]: (
        state,
        action: LoadIssueActionPlanDoneCommand
    ) => ({
        ...state,
        loadingActionPlan: false,
        actionItems: action.payload.actionItems,
    }),
    [ACTIONS.ISSUE_ACTION_PLAN_LOAD_FAIL]: (state) => ({
        ...state,
        loadingActionPlan: false,
    }),
    [AuthenticationActions.LogoutSuccess]: () => ({ ...getDefaultState() }),
    [ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_ACTIVE]: (state, action) => {
        const isActive = Boolean(action.payload.isActive)
        if (!isActive) {
            return {
                ...state,
                sendMessage: {
                    isActive: false,
                    fullScreen: false,
                    maximize: false,
                },
            }
        }
        return {
            ...state,
            sendMessage: {
                ...state.sendMessage,
                isActive: action.payload.isActive,
            },
        }
    },
    [ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_MAXIMIZE]: (state, action) => ({
        ...state,
        sendMessage: {
            ...state.sendMessage,
            maximize: action.payload.maximize,
        },
    }),
    [ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_FULLSCREEN]: (state, action) => ({
        ...state,
        sendMessage: {
            ...state.sendMessage,
            fullScreen: action.payload.fullScreen,
        },
    }),
    [ACTIONS.ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT]: createLoadingAction(
        'Generating PowerPoint overview...'
    ),
    [ACTIONS.ISSUE_GENERATE_SUMMARY_REPORT]: createLoadingAction(
        'Generating summary report...'
    ),
}

export const issueReducer = (
    state: IssueStoreState = getDefaultState(),
    action: AnyAction
): IssueStoreState => reducersIndex[action?.type]?.(state, action) || state
