import { ExpandMore, Memory } from '@mui/icons-material'
import { CircularProgress, Collapse, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { TREE_NODE } from 'consts/selectorIds'
import { useContext, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import theme from 'styles/themeBase'
import { customize } from 'utils'
import TreeNodes from './TreeNodes'
import { TreeContext } from './TreeRoot'
import { GenericNode, NodeBase } from './types'

const useCcrpTreeNode = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: theme.typography.fontWeightBold as number,
            ...theme.typography.body2,
        },
        header: {
            display: 'inline-flex',
            height: 54,
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        selectableHeader: {
            textTransform: 'uppercase',
            '&$selected': {
                backgroundColor: 'rgba(255,0,0,0.1)',
            },
        },
        labelName: {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        selected: {},
        selectIcon: {
            color: 'red',
            marginRight: theme.spacing(1),
        },
        expandMoreButton: {
            marginRight: theme.spacing(0.5),
        },
        selectable: {
            cursor: 'pointer',
        },
        expandMoreIcon: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            '&$expanded': {
                transform: 'rotate(180deg)',
            },
        },
        nodeLoading: {
            marginRight: theme.spacing(2),
        },
        expanded: {},
        isNotActive: {
            color: theme.palette.grey[400],
            fontWeight: theme.typography.fontWeightRegular as number,
        },
    }),
    { name: 'TreeNode' }
)

export interface TreeNodeExpandableProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    node: GenericNode
}

export const TreeNodeExpandable: React.FC<TreeNodeExpandableProps> = (
    props
) => {
    const { node, children, ...other } = props
    const classes = useCcrpTreeNode(props)
    const [isHover, setIsHover] = useState({ display: 'none' })
    const treeContext = useContext(TreeContext)
    const expanded = treeContext.expandedNodes.some((id) => id === node.id)
    const loading = treeContext.loadingNodes.some((id) => id === node.id)
    const isSelectedNode = treeContext.selectedNodes.some(
        (n) => n.id === node.id
    )
    return (
        <>
            <div
                {...customize(TREE_NODE.CONTAINER, node.name)}
                className={clsx(
                    classes.header,
                    classes.selectableHeader,
                    isSelectedNode && classes.selected,
                    node.isSelectable && classes.selectable,
                    !node.isActive && classes.isNotActive
                )}
                {...other}
                onClick={(e) => {
                    node.isSelectable && treeContext.onSelectNode(node)
                    e.stopPropagation()
                    treeContext.onExpandNode(node, expanded)
                }}
                onMouseEnter={() => {
                    setIsHover({ display: 'block' })
                }}
                onMouseLeave={() => {
                    setIsHover({ display: 'none' })
                }}
                onDoubleClick={() =>
                    node.isSelectable && treeContext.onDoubleClickConfirm(node)
                }
            >
                {node.isSelectable && <Memory className={classes.selectIcon} />}
                <span className={classes.labelName}>{node.name}</span>
                <span style={{ flexGrow: 1 }} />
                <div style={isHover}>
                    {node.isSelectable &&
                        treeContext.onConfirmButton &&
                        treeContext.onConfirmButton(node)}
                </div>
                {loading ? (
                    <CircularProgress
                        className={classes.nodeLoading}
                        size={24}
                        color="secondary"
                        style={{ padding: theme.spacing(0.95) }}
                    />
                ) : (
                    <IconButton
                        className={classes.expandMoreButton}
                        onClick={(e) => {
                            e.stopPropagation()
                            treeContext.onExpandNode(node, expanded)
                        }}
                        size="large"
                    >
                        <ExpandMore
                            {...TREE_NODE.EXPAND_MORE_BUTTON}
                            className={clsx(
                                classes.expandMoreIcon,
                                expanded && classes.expanded
                            )}
                        />
                    </IconButton>
                )}
            </div>
            {node.hasChildren && children && (
                <Collapse
                    in={expanded}
                    {...customize(TREE_NODE.CHILDREN_LIST, node.name)}
                >
                    {children}
                </Collapse>
            )}
        </>
    )
}

export interface TreeNodeSelectableProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    node: GenericNode
    selected: boolean
}

export const TreeNodeSelectable = (props: TreeNodeSelectableProps) => {
    const { node, selected, ...other } = props
    const classes = useCcrpTreeNode(props)
    const treeContext = useContext(TreeContext)
    const [isHover, setIsHover] = useState({ display: 'none' })
    return (
        <div
            {...customize(TREE_NODE.CONTAINER, node.name)}
            className={clsx(
                classes.header,
                classes.selectableHeader,
                selected && classes.selected,
                node.isSelectable && classes.selectable,
                !node.isActive && classes.isNotActive
            )}
            onMouseEnter={(e) => {
                setIsHover({ display: 'block' })
            }}
            onMouseLeave={(e) => {
                setIsHover({ display: 'none' })
            }}
            onDoubleClick={() =>
                node.isSelectable && treeContext.onDoubleClickConfirm(node)
            }
            {...other}
        >
            {node.isSelectable && <Memory className={classes.selectIcon} />}
            <span className={classes.labelName}>{node.name}</span>
            <span style={{ flexGrow: 1 }} />
            <div style={isHover}>
                {node.isSelectable &&
                    treeContext.onConfirmButton &&
                    treeContext.onConfirmButton(node)}
            </div>
        </div>
    )
}

export interface ElmentTreeNodeProps
    extends NodeBase,
        React.HtmlHTMLAttributes<HTMLLIElement> {}

export const TreeNode = (props: ElmentTreeNodeProps) => {
    const { node, level, ...other } = props
    const classes = useCcrpTreeNode(props)
    const treeContext = useContext(TreeContext)
    const isSelectedNode = treeContext.selectedNodes.some(
        (n) => n.id === node.id
    )

    if (!node.hasChildren) {
        return (
            <li
                className={classes.root}
                {...other}
                onClick={() =>
                    node.isSelectable && treeContext.onSelectNode(node)
                }
            >
                <TreeNodeSelectable
                    style={{
                        paddingLeft: level * 16,
                        paddingRight: theme.spacing(7),
                    }}
                    node={node}
                    selected={isSelectedNode}
                />
            </li>
        )
    }

    return (
        <li className={classes.root} {...other}>
            <TreeNodeExpandable style={{ paddingLeft: level * 16 }} node={node}>
                <TreeNodes nodes={node.nodes} level={level + 1} />
            </TreeNodeExpandable>
        </li>
    )
}

TreeNode.defaultProps = {
    level: 0,
}

export default TreeNode
