import { PeopleAltOutlined, PersonOutlined, Refresh } from '@mui/icons-material'
import { getAdminProcessOwners } from 'api'
import { AxiosResponse } from 'axios'
import { Button, EmployeeChip, ValueViewNext } from 'components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import theme from 'styles/themeBase'
import { Employee } from 'types/issueTypes'
import { dataTestId, getEmployeeOrganizationInfoName } from 'utils'
import { AdminEmployeeLabel } from './AdminEmployeesChips/AdminEmployeeLabel'
import { makeStyles } from '@mui/styles'
import {
    FormControlProps,
    CircularProgress,
    Typography,
    IconButton,
} from '@mui/material'

export interface ProcessOwnerInfoProps
    extends Omit<FormControlProps, 'children'> {
    cache?: boolean
    processOwners?: Employee[]
    divisionCode?: string
    businessUnitCode?: string
    productGroupCode?: string
    customMessage?: string
    countryCode?: string
    regionCode?: string
    disabledHideProcessOwners?: boolean
    showProcessOwners?: boolean
    onProcessOwnersLoad?: (processOwners: Employee[]) => void
    newConcept?: boolean
    requestToGetProcessOwners?: () => Promise<AxiosResponse<Employee[]>>
}

const useStyles = makeStyles(
    (theme) => ({
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            width: '100%',
        },
        noProcessOwners: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
        },
        refresh: {
            marginRight: theme.spacing(1),
        },
        chip: {
            marginRight: theme.spacing(1),
            margin: theme.spacing(0.5, 0),
        },
        hideButton: {
            marginTop: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: theme.spacing(0, 1),
        },
    }),
    { name: 'ProcessOwnerInfo' }
)

export const ProcessOwnerInfo = (props: ProcessOwnerInfoProps) => {
    const {
        cache = true,
        businessUnitCode = null,
        divisionCode = null,
        productGroupCode = null,
        countryCode = null,
        regionCode = null,
        customMessage,
        processOwners: processOwnersProp,
        disabledHideProcessOwners = true,
        onProcessOwnersLoad,
        newConcept = false,
        showProcessOwners,
        requestToGetProcessOwners,
    } = props
    const classes = useStyles()
    const dispatch = useDispatch()
    let content = null
    const [loading, setLoading] = useState(false)
    const [processOwners, setProcessOwners] = useState<Employee[]>(null)
    const [disabledHidePO, setdisabledHidePO] = useState(
        disabledHideProcessOwners
    )
    useEffect(() => {
        if (processOwnersProp !== undefined) {
            setProcessOwners(processOwnersProp)
        }
    }, [processOwnersProp])
    useEffect(() => {
        if (!cache) {
            setTimeout(() => setProcessOwners(null), 300)
        }
    }, [cache])
    useEffect(() => {
        showProcessOwners ? loadProcessOwners() : setProcessOwners(null)
    }, [showProcessOwners])
    const loadProcessOwners = async () => {
        try {
            setLoading(true)
            const { data } = requestToGetProcessOwners
                ? await requestToGetProcessOwners()
                : await getAdminProcessOwners({
                      businessUnitCode: businessUnitCode,
                      divisionCode: divisionCode,
                      productGroupCode: productGroupCode,
                      countryCode: countryCode,
                      regionCode: regionCode,
                  })
            setProcessOwners(data)
            onProcessOwnersLoad && onProcessOwnersLoad(data)
            newConcept && setdisabledHidePO(!disabledHidePO)
        } catch (error: any) {
            dispatch(showSnackbar('Cannot load process owners', true))
        } finally {
            setLoading(false)
        }
    }

    switch (true) {
        case loading:
            content = <CircularProgress size={32} color="secondary" />
            break
        case Boolean(customMessage):
            content = <Typography variant="body2">{customMessage}</Typography>
            break
        case 0 < processOwners?.length:
            content = (
                <>
                    {newConcept && (
                        <>
                            <AdminEmployeeLabel
                                name="Process Owners"
                                icon={() => (
                                    <PersonOutlined className={classes.icon} />
                                )}
                            />
                        </>
                    )}
                    {processOwners.map((p, i) => (
                        <EmployeeChip
                            key={p.geid + i}
                            customLabel={`${
                                p.fullName
                            } - ${getEmployeeOrganizationInfoName(
                                p?.organizationInfo
                            )}`}
                            className={classes.chip}
                            employee={p}
                        />
                    ))}

                    {!newConcept
                        ? !disabledHidePO && (
                              <Button
                                  className={classes.hideButton}
                                  style={
                                      newConcept
                                          ? { margin: theme.spacing(0) }
                                          : {}
                                  }
                                  onClick={() => {
                                      setProcessOwners(null)
                                      newConcept &&
                                          setdisabledHidePO(!disabledHidePO)
                                  }}
                              >
                                  Hide Process Owners
                              </Button>
                          )
                        : null}
                </>
            )
            break
        case Array.isArray(processOwners) && processOwners?.length === 0:
            content = (
                <div className={classes.noProcessOwners}>
                    <IconButton
                        className={classes.refresh}
                        size="small"
                        onClick={loadProcessOwners}
                    >
                        <Refresh fontSize="small" />
                    </IconButton>
                    <Typography variant="body2">
                        No Process Owners...
                    </Typography>
                </div>
            )
            break
        default:
            content = !newConcept ? (
                <Button
                    onClick={loadProcessOwners}
                    color="secondary"
                    {...dataTestId(`SHOW_PROCESS_OWNERS`)}
                >
                    Show Process Owners
                </Button>
            ) : null

            break
    }

    return (
        <>
            {newConcept ? (
                <>{content}</>
            ) : (
                <ValueViewNext
                    label="Process Owners"
                    renderIcon={(props) => <PeopleAltOutlined {...props} />}
                    fullWidth
                >
                    <div className={classes.content}>{content}</div>
                </ValueViewNext>
            )}
        </>
    )
}
