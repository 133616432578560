import { Dispatch, AnyAction, Action } from 'redux'
import * as ACTIONS from './actionsTypes'
import {
    Attachment,
    Issue,
    AssignSection,
    ClosingSection,
    EffectivenessSection,
    Product,
    ResolvingUnit,
    Customer,
    CustomerContact,
    Supplier,
    CaptureSection,
    ResolutionSection,
    PGRcaCategory,
    Warranty,
    Employee,
    IssueContext,
    HighImpact,
    AttachmentNewIssueStatus,
} from 'types/issueTypes'

import {
    IssueRoles,
    IssueStatuses,
    ActionsKeys,
    IssueActionsTypes,
    RcaReportTypes,
    QuickFixVariants,
    IssueValuesKeys,
    EffectivenessTypes,
} from 'types/enums'
import {
    ReturnToIssueOwnerQuery,
    ReopenIssueQuery,
    RejectResolutionQuery,
    RevertToNotAssignedQuery,
} from 'types/actions'
import { GenericNode } from '../../components/TreeView/types'
import { RootState } from '../types'
import { AccessMatrixItem } from 'types/matrixTypes'
import { ISSUE_SELECT_ROLES } from './actionsTypes'
import {
    DependedFields,
    ExpandedIssueState,
    IssueChangeLogState,
    IssueStoreState,
} from './initState'
import {
    GenerateReportCommand,
    LoadAttachmentsCommand,
    LoadIssueCommand,
    LoadIssueContextCommand,
    LoadMatrixSchemeCommand,
    RejectResolutionCommand,
    RejectResolutionToROCommand,
    ReturnToIssueOwnerCommand,
    SetCurrentMatrixCommand,
    SubmitDraftCommand,
    VerifyContainmentCommand,
} from '.'
import {
    ActionDoneCommand,
    ActionFailCommand,
    LoadIssueActionPlanCommand,
    LoadIssueActionPlanDoneCommand,
    RevertToNotAssignedCommand,
    SendIssueMessageCommand,
    SetLoadingStateCommand,
} from './types'
import { ActionItem } from 'types/actionPlanTypes'
import { IssueMailMessage } from 'types/sendMessageTypes'
import {
    getAssignSectionKey,
    getCaptureSectionKey,
    getClosingSectionKey,
    getEffectivenessSectionKey,
    getHighImpactKey,
    getResolutionSectionKey,
} from 'Issue/matrix'
import {
    getPrettyDate,
    getProductLineLocationName,
    getResolutionUnitName,
} from 'utils'
import { ProductLineLocationProps } from 'Issue/Pickers'

let cancelRequestExecutor: () => void

const logChange = (
    sectionKey: keyof IssueChangeLogState,
    state: IssueStoreState,
    key: IssueValuesKeys,
    oldValue: string,
    newValue: string
) => {
    const section = state.changeLog?.[sectionKey]
    const log = section?.[key] ?? null
    if (oldValue === newValue) {
        return
    }
    if (log) {
        state.changeLog[sectionKey][key][1] = newValue
    } else {
        if (!section) {
            state.changeLog = { ...(state?.changeLog ?? {}), [sectionKey]: {} }
        }
        state.changeLog[sectionKey][key] = [oldValue, newValue]
    }
}

const logCaptureChange = (
    state: IssueStoreState,
    key: keyof CaptureSection,
    oldValue: string,
    newValue: string
) => logChange('capture', state, getCaptureSectionKey(key), oldValue, newValue)

const logHighImpactChange = (
    state: IssueStoreState,
    key: keyof HighImpact,
    oldValue: string,
    newValue: string
) => logChange('capture', state, getHighImpactKey(key), oldValue, newValue)

const logAssignmentChange = (
    state: IssueStoreState,
    key: keyof AssignSection,
    oldValue: string,
    newValue: string
) => logChange('assign', state, getAssignSectionKey(key), oldValue, newValue)

const logResolutionChange = (
    state: IssueStoreState,
    key: keyof ResolutionSection,
    oldValue: string,
    newValue: string
) =>
    logChange(
        'resolve',
        state,
        getResolutionSectionKey(key),
        oldValue,
        newValue
    )

const logClosingChange = (
    state: IssueStoreState,
    key: keyof ClosingSection,
    oldValue: string,
    newValue: string
) => logChange('closed', state, getClosingSectionKey(key), oldValue, newValue)

const logEffectivenessChange = (
    state: IssueStoreState,
    key: keyof EffectivenessSection,
    oldValue: string,
    newValue: string
) =>
    logChange(
        'effectiveness',
        state,
        getEffectivenessSectionKey(key),
        oldValue,
        newValue
    )

export const hasActionInContext = (
    issue: Issue,
    issueActionType: IssueActionsTypes
): boolean => {
    const actions = issue?.context?.actions
    if (actions && 0 < actions.length) {
        return actions.some((a) => a.actionCode === issueActionType)
    }
    return false
}

export const issueCancelRequests = () => {
    cancelRequestExecutor && cancelRequestExecutor()
    return { type: ACTIONS.ISSUE_CANCEL_CALL }
}

export const clearError = (): Action => ({
    type: ACTIONS.ISSUE_CLEAR_ERROR,
})

export const setAccessMatrix = (accessMatrixes: Array<AccessMatrixItem>) => ({
    type: ACTIONS.ISSUE_LOAD_MATRIX_DONE,
    payload: [...accessMatrixes],
})

export const selectCurrentMatrix = (
    currentActions: Array<ActionsKeys> = [],
    dependedFields: DependedFields = {}
): SetCurrentMatrixCommand => ({
    type: ACTIONS.ISSUE_SET_MATRIX,
    payload: { currentActions, dependedFields },
})

export const selectCurrentRoles = (selectedRolesCodes: Array<IssueRoles>) => ({
    type: ISSUE_SELECT_ROLES,
    payload: selectedRolesCodes,
})

export const loadIssue = (
    issueIdOrNumber: number | string,
    loadActionPlan: boolean = false
): LoadIssueCommand => ({
    type: ACTIONS.ISSUE_LOAD_CALL,
    payload: { issueIdOrNumber, loadActionPlan },
})

export const loadIssueDone = (issue: Issue) => ({
    type: ACTIONS.ISSUE_LOAD_DONE,
    payload: issue,
})

export const clearIssue = () => ({ type: ACTIONS.ISSUE_REMOVE })

export const generateReport = (
    issueId: number,
    reportType: RcaReportTypes
): GenerateReportCommand => ({
    type: ACTIONS.ISSUE_GENERATE_REPORT_CALL,
    payload: {
        issueId,
        reportType,
    },
})

export const generateReportDone = (): Action => ({
    type: ACTIONS.ISSUE_GENERATE_REPORT_DONE,
})

export const loadMatrixScheme = (
    issueId: number,
    roleId: Array<IssueRoles>,
    issueStatusId: IssueStatuses = null
): LoadMatrixSchemeCommand => ({
    type: ACTIONS.ISSUE_LOAD_MATRIX_CALL,
    payload: { issueId, issueStatusId, myRoles: roleId },
})

export const loadAttachmets = (issueId: number): LoadAttachmentsCommand => ({
    type: ACTIONS.ISSUE_ATTACHMENTS_CALL,
    payload: { issueId },
})
export const loadAttachmentsDone = (attachments: Array<Attachment>) => ({
    type: ACTIONS.ISSUE_ATTACHMENTS_DONE,
    payload: attachments,
})

export const returnToIssueOwner = (
    query: ReturnToIssueOwnerQuery
): ReturnToIssueOwnerCommand => ({
    type: ACTIONS.ISSUE_RETURN_TO_OWNER_CALL,
    payload: { query },
})
export const returnToIssueOwnerDone = (): Action => ({
    type: ACTIONS.ISSUE_RETURN_TO_OWNER_DONE,
})

export const assignIssue = (): Action => ({
    type: ACTIONS.ISSUE_ASSIGN_CALL,
})
export const assignIssueDone = (): Action => ({
    type: ACTIONS.ISSUE_ASSIGN_DONE,
})

export const saveAssignSection = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_ASSIGN_CALL,
})
export const saveAssignSectionDone = (assignSection: AssignSection) => ({
    type: ACTIONS.ISSUE_SAVE_ASSIGN_DONE,
    payload: assignSection,
})

export const removeRca = (): Action => ({
    type: ACTIONS.ISSUE_REMOVE_RCA_CALL,
})

export const removeRcaDone = (assignSection: AssignSection) => ({
    type: ACTIONS.ISSUE_REMOVE_RCA_DONE,
    payload: { ...assignSection },
})

export const saveResolutionSection = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_RESOLUTION_CALL,
})
export const saveResolutionSectionDone = (
    resolutionSection: ResolutionSection
) => ({
    type: ACTIONS.ISSUE_SAVE_RESOLUTION_DONE,
    payload: resolutionSection,
})

export const resolveIssue = (): Action => ({
    type: ACTIONS.ISSUE_RESOLVE_CALL,
})
export const resolveIssueDone = (): Action => ({
    type: ACTIONS.ISSUE_RESOLVE_DONE,
})

export const saveClosingSection = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_CLOSING_CALL,
})
export const saveClosingSectionDone = (closingSection: ClosingSection) => ({
    type: ACTIONS.ISSUE_SAVE_CLOSING_DONE,
    payload: closingSection,
})

export const saveEffectivenessSection = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_EFFECTIVENESS_CALL,
})
export const saveEffectivenessSectionDone = (
    effectivenessSection: EffectivenessSection
) => ({
    type: ACTIONS.ISSUE_SAVE_EFFECTIVENESS_DONE,
    payload: { ...effectivenessSection },
})

export const reopenIssue = (query: ReopenIssueQuery) => ({
    type: ACTIONS.ISSUE_REOPEN_CALL,
    payload: { query },
})

export const reopenIssueDone = (): Action => ({
    type: ACTIONS.ISSUE_REOPEN_DONE,
})

export const confirmIssueEffectiveness = (): Action => ({
    type: ACTIONS.ISSUE_CONFRIM_EFFECTIVENESS_CALL,
})

export const confirmIssueEffectivenessDone = (
    effectivenessSection: EffectivenessSection
) => ({
    type: ACTIONS.ISSUE_CONFRIM_EFFECTIVENESS_DONE,
    payload: effectivenessSection,
})

export const clearLinkedIssue = (): Action => ({
    type: ACTIONS.ISSUE_CLEAR_LINKED_ISSUE,
})

export const deleteIssueEditContext = (): Action => ({
    type: ACTIONS.ISSUE_DELETE_CONTEXT,
})

export const completeIssue = (): Action => ({
    type: ACTIONS.ISSUE_COMPLETE_CALL,
})
export const completeIssueDone = (): Action => ({
    type: ACTIONS.ISSUE_COMPLETE_DONE,
})

export const rejectResolution = (
    query: RejectResolutionQuery
): RejectResolutionCommand => ({
    type: ACTIONS.ISSUE_REJECT_RESOLUTION_CALL,
    payload: { query },
})
export const rejectResolutionDone = (): Action => ({
    type: ACTIONS.ISSUE_REJECT_RESOLUTION_DONE,
})

export const getIssueOwnerRequested = (): Action => ({
    type: ACTIONS.ISSUE_GET_ISSUE_OWNER_CALL,
})
export const getIssueOwnerDone = (issueOwner: Employee) => ({
    type: ACTIONS.ISSUE_GET_ISSUE_OWNER_DONE,
    payload: { ...issueOwner },
})

export const rejectResolutionToRO = (
    query: RejectResolutionQuery
): RejectResolutionToROCommand => ({
    type: ACTIONS.ISSUE_REJECT_RESOLUTION_TO_RO_CALL,
    payload: { query },
})
export const rejectResolutionToRODone = (): Action => ({
    type: ACTIONS.ISSUE_REJECT_RESOLUTION_TO_RO_DONE,
})

export const disposeIssue = () => (dispatch: any) => {
    dispatch(issueCancelRequests())
    dispatch(deleteIssueEditContext())
}

export const initNewIssue = (): Action => ({ type: ACTIONS.ISSUE_NEW_INIT })

export const updateIssueImpactClassification =
    (key: keyof HighImpact, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const issue = getState().issue
        logHighImpactChange(
            issue,
            key,
            String(issue?.captureSection?.highImpact?.[key] ?? null),
            String(value)
        )
        dispatch({
            type: ACTIONS.ISSUE_UPDATE_HIGH_IMPACT,
            payload: { key, value },
        })
    }

export const updateIssueDescription = (key: string, value: any) => ({
    type: ACTIONS.ISSUE_UPDATE_ISSUE_DESCRIPTION,
    payload: { key, value },
})

export const updateResolutionSection =
    (key: keyof ResolutionSection, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const issue = getState().issue
        switch (key) {
            case 'whatContainmentActionsWereTaken':
            case 'whatWasTheRootCause':
            case 'howDidWeResolveThisIssue':
                logResolutionChange(
                    issue,
                    key,
                    issue.resolutionSection[key],
                    value
                )
        }
        dispatch({
            type: ACTIONS.ISSUE_UPDATE_RESOLUTION,
            payload: { key, value },
        })
    }

export const updateEffectivenessSection =
    (key: keyof EffectivenessSection, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const { issue, resources } = getState()
        switch (key) {
            case 'effectivenessTypeId':
                const effectivenessTypeId = value as EffectivenessTypes
                logEffectivenessChange(
                    issue,
                    key,
                    resources?.effectivenessType?.find(
                        (x) =>
                            x.code ===
                            issue?.effectivenessSection?.effectivenessTypeId
                    )?.name ?? null,
                    resources?.effectivenessType?.find(
                        (x) => x.code === effectivenessTypeId
                    )?.name ?? null
                )
                break
            case 'reasonWhyNotEffectiveOrNotRequired':
                logEffectivenessChange(
                    issue,
                    key,
                    issue?.effectivenessSection
                        ?.reasonWhyNotEffectiveOrNotRequired ?? null,
                    value
                )
                break
            case 'nextEscalationDate':
                logEffectivenessChange(
                    issue,
                    key,
                    getPrettyDate(
                        issue?.effectivenessSection?.nextEscalationDate
                    ) ?? null,
                    getPrettyDate(value)
                )
        }
        dispatch({
            type: ACTIONS.ISSUE_EFFECTIVENESS_UPDATE,
            payload: { key, value },
        })
    }

export const setExpandMode = (
    sectionName: keyof ExpandedIssueState,
    expanded: boolean = false
) => ({
    type: ACTIONS.ISSUE_EXPAND_SECTION,
    payload: { sectionName, expanded },
})

export const initExpandMode = (expandState: ExpandedIssueState) => ({
    type: ACTIONS.ISSUE_EXPAND_INIT,
    payload: { ...expandState },
})

const getCaptureValueAsString = (
    state: RootState,
    key: keyof CaptureSection,
    value: any
): string => {
    switch (key) {
        case 'issueType':
            return (
                state.resources.issueType.find((x) => x.code === value)?.name ??
                null
            )
        case 'resolvingUnit':
            return value ? getResolutionUnitName(value as ResolvingUnit) : null
        case 'product':
            return (value as Product)?.name ?? null
        case 'howManyDefectsFound':
            return String(value)
        case 'targetResolutionAndVerificationDate':
        case 'commissioningDate':
        case 'manufacturingDate':
        case 'containmentActionDeadlineDate':
            return getPrettyDate(value)
        case 'supplier':
            return (value as Supplier)?.name ?? null
        case 'issueOwner':
        case 'proposedResolutionOwner':
            return (value as Employee)?.email ?? null
        case 'partiesReference':
            return (value as Employee[]).length > 0
                ? (value as Employee[])?.map((e) => e.email)?.join(', ') ?? null
                : null
        case 'issueOwnerTeam':
            return (value as Employee[]).length > 0
                ? (value as Employee[])?.map((e) => e.email)?.join(', ') ?? null
                : null
        case 'behalfBuCode':
            const businessUnit = state.resources.businessUnits.find(
                (d) => d.code === value
            )
            return businessUnit
                ? `${businessUnit?.divisionCode} ${businessUnit?.code} ${businessUnit?.name}`
                : null
        case 'behalfCountryCode':
            return (
                state.resources.countries.find((c) => c.code === value)?.name ??
                null
            )
        default:
            return value
    }
}

export const updateCaptureRoot =
    (key: keyof CaptureSection, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue

        if (
            key === 'issueOwner' &&
            hasActionInContext(
                state.issue,
                IssueActionsTypes.To_Replace_Inactive_IO
            )
        ) {
            dispatch(
                removeContextAction(IssueActionsTypes.To_Replace_Inactive_IO)
            )
        }
        if (issue.issueStatusId !== IssueStatuses.New) {
            let oldValueLog = getCaptureValueAsString(
                getState(),
                key,
                state?.issue?.captureSection[key]
            )
            let newValueLog = getCaptureValueAsString(getState(), key, value)

            logCaptureChange(state.issue, key, oldValueLog, newValueLog)
        }

        dispatch({
            type: ACTIONS.ISSUE_CAPTURE_UPDATE,
            payload: { key, value: value },
        })
    }

export const updateWarrantyRoot =
    (key: keyof Warranty, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const issue = getState().issue
        switch (key) {
            case 'reference':
                logChange(
                    'capture',
                    issue,
                    IssueValuesKeys.WarrantyReference,
                    issue?.captureSection?.warranty?.reference,
                    value
                )
                break
            case 'startDate':
                logChange(
                    'capture',
                    issue,
                    IssueValuesKeys.WarrantyStartDate,
                    getPrettyDate(issue?.captureSection?.warranty?.startDate),
                    getPrettyDate(value)
                )
                break
            case 'endDate':
                logChange(
                    'capture',
                    issue,
                    IssueValuesKeys.WarrantyEndDate,
                    getPrettyDate(issue?.captureSection?.warranty?.endDate),
                    getPrettyDate(value)
                )
                break
        }

        dispatch({
            type: ACTIONS.ISSUE_WARRANTY_UPDATE,
            payload: { key, value },
        })
    }

export const selectProduct =
    (product: GenericNode | Product) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue
        logCaptureChange(
            issue,
            'productCID',
            issue?.captureSection?.product?.name ?? null,
            product?.name ?? null
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_ABB_PRODUCT,
            payload: product && { ...product },
        })
    }

export const selectProductLineLocation =
    (productLineLocation: ProductLineLocationProps) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue
        const newNamePLL = getProductLineLocationName(productLineLocation)
        const oldNamePLL = getProductLineLocationName(
            issue?.captureSection?.productLineLocation
        )
        logCaptureChange(
            issue,
            'productLineLocationId',
            oldNamePLL.length ? oldNamePLL : null,
            newNamePLL.length ? newNamePLL : null
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_PRODUCT_LINE_LOCATION,
            payload: productLineLocation && { ...productLineLocation },
        })
    }

export const selectFailureMode =
    (failureMode: GenericNode) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue
        logCaptureChange(
            issue,
            'failureModeId',
            issue?.captureSection?.failureModeName ?? null,
            failureMode?.name ?? null
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_FAILURE_MODE,
            payload: failureMode && { ...failureMode },
        })
    }

export const selectPGCategory =
    (pgCategory: PGRcaCategory) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue
        logResolutionChange(
            issue,
            'pgRcaCategory',
            issue?.resolutionSection?.pgRcaCategory?.name ?? null,
            pgCategory?.name ?? null
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_PG_CATEGORY,
            payload: pgCategory && { ...pgCategory },
        })
    }

export const selectResolvingUnit =
    (resolvingUnit: ResolvingUnit) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const state = getState()
        const issue = state.issue
        if (
            hasActionInContext(issue, IssueActionsTypes.To_Replace_Inactive_RU)
        ) {
            dispatch(
                removeContextAction(IssueActionsTypes.To_Replace_Inactive_RU)
            )
        }
        if (hasActionInContext(issue, IssueActionsTypes.To_Assign_Updated_RU)) {
            dispatch(
                removeContextAction(IssueActionsTypes.To_Assign_Updated_RU)
            )
        }
        logCaptureChange(
            issue,
            'resolvingUnitId',
            getCaptureValueAsString(
                state,
                'resolvingUnit',
                issue?.captureSection?.resolvingUnit ?? null
            ),
            getCaptureValueAsString(state, 'resolvingUnit', resolvingUnit)
        )

        dispatch({
            type: ACTIONS.ISSUE_SELECT_RESOLVING_UNIT,
            payload: resolvingUnit && { ...resolvingUnit },
        })
    }

export const selectCustomerName =
    (customer: Customer) => (dispatch: Dispatch, getState: () => RootState) => {
        const prev = getState()
        const issue = prev.issue
        logCaptureChange(
            issue,
            'customer',
            issue?.captureSection?.customer?.name ?? null,
            customer && customer?.name
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_CUSTOMER,
            payload: customer && { ...customer },
        })
    }

export const selectCustomerContact =
    (customerContact: CustomerContact) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const prev = getState()
        const issue = prev.issue
        logCaptureChange(
            issue,
            'customerContact',
            issue?.captureSection?.customerContact?.email ?? null,
            customerContact && customerContact?.email
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_CUSTOMER_CONTACT,
            payload: customerContact,
        })
    }

export const selectSupplier =
    (supplier: Supplier) => (dispatch: Dispatch, getState: () => RootState) => {
        const prev = getState()
        const issue = prev.issue
        logCaptureChange(
            issue,
            'supplier',
            issue?.captureSection?.supplier?.name ?? null,
            supplier?.name
        )
        dispatch({
            type: ACTIONS.ISSUE_SELECT_SUPPLIER,
            payload: { ...supplier },
        })
    }

export const deleteSupplier =
    () => (dispatch: Dispatch, getState: () => RootState) => {
        const prev = getState()
        const issue = prev.issue
        logCaptureChange(
            issue,
            'supplier',
            issue?.captureSection?.supplier?.name ?? null,
            null
        )
        dispatch({
            type: ACTIONS.ISSUE_DELETE_SUPPLIER,
        })
    }

export const saveDraft = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_CALL,
})

export const saveDraftDone = (captureSection: CaptureSection) => ({
    type: ACTIONS.ISSUE_SAVE_DONE,
    payload: { ...captureSection },
})

export const addAttachment =
    (attachment: Attachment) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({
            type: ACTIONS.ISSUE_ADD_ATTACHMENT,
            payload: { ...attachment },
        })
    }

export const newIssueAddAttachment =
    (attachment: AttachmentNewIssueStatus) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({
            type: ACTIONS.NEW_ISSUE_ADD_ATTACHMENTS,
            payload: attachment,
        })
    }

export const deleteAttachment =
    (attachment: Attachment) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        dispatch({
            type: ACTIONS.ISSUE_DELETE_ATTACHMENT,
            payload: { ...attachment },
        })
    }

export const saveAsDraft = (): Action => ({
    type: ACTIONS.ISSUE_SAVE_AS_CALL,
})

export const saveAsDraftDone = (issue: Issue) => ({
    type: ACTIONS.ISSUE_SAVE_AS_DONE,
    payload: issue,
})

export const submitDraft = (saveAndSubmit = false): SubmitDraftCommand => ({
    type: ACTIONS.ISSUE_SUBMIT_CALL,
    payload: { saveAndSubmit },
})

export const prepareDraft = (): Action => ({
    type: ACTIONS.ISSUE_SUBMIT_PREPARE,
})

export const submitDraftDone = (): Action => ({
    type: ACTIONS.ISSUE_SUBMIT_DONE,
})

export const deleteDraft = (): Action => ({
    type: ACTIONS.ISSUE_DELETE_CALL,
})

export const deleteDraftDone = (): Action => ({
    type: ACTIONS.ISSUE_DELETE_DONE,
})

export const updateAssignSection =
    (key: keyof AssignSection, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const issue = getState().issue
        if (
            key === 'primaryResolutionOwner' &&
            hasActionInContext(
                getState().issue,
                IssueActionsTypes.To_Replace_Inactive_RO
            )
        ) {
            dispatch(
                removeContextAction(IssueActionsTypes.To_Replace_Inactive_RO)
            )
        }
        switch (key) {
            case 'primaryResolutionOwner' || 'primaryActiveResolutionOwner':
                logAssignmentChange(
                    issue,
                    key,
                    issue.assignSection?.primaryResolutionOwner?.email ?? null,
                    (value as Employee)?.email ?? null
                )
                break

            case 'resolutionOwnerTeam':
                logAssignmentChange(
                    issue,
                    key,
                    issue.assignSection?.resolutionOwnerTeam.length > 0
                        ? issue.assignSection?.resolutionOwnerTeam
                              ?.map((e) => e.email)
                              ?.join(', ') ?? null
                        : null,
                    (value as Employee[]).length > 0
                        ? (value as Employee[])
                              ?.map((e) => e.email)
                              ?.join(', ') ?? null
                        : null
                )
                break
            case 'solutionWillBeValidatedByCoordinator':
                logAssignmentChange(
                    issue,
                    key,
                    String(
                        issue.assignSection
                            ?.solutionWillBeValidatedByCoordinator
                    ),
                    String(value)
                )
                break
        }
        dispatch({
            type: ACTIONS.ISSUE_UPDATE_ASSIGN,
            payload: { key, value },
        })
    }

export const setLightMode = (lightMode: boolean) => ({
    type: ACTIONS.ISSUE_LIGHT_MODE,
    payload: lightMode,
})

export const updateClosing =
    (key: keyof ClosingSection, value: any) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const issue = getState().issue
        switch (key) {
            case 'isWaitingForExecution':
                logClosingChange(
                    issue,
                    key,
                    String(
                        issue?.closingSection?.isWaitingForExecution ?? null
                    ),
                    String(value)
                )
                break
            case 'customerComments':
                logClosingChange(
                    issue,
                    key,
                    issue?.closingSection?.customerComments,
                    value
                )
                break
            case 'expectedExecutionDate':
                logClosingChange(
                    issue,
                    key,
                    getPrettyDate(issue?.closingSection?.expectedExecutionDate),
                    getPrettyDate(value)
                )
                break
        }
        dispatch({
            type: ACTIONS.ISSUE_UPDATE_CLOSING_SECTION,
            payload: { key, value },
        })
    }

export const verifyResolution = (): Action => ({
    type: ACTIONS.ISSUE_VERIFY_RESOLUTION_CALL,
})

export const verifyResolutionDone = (): Action => ({
    type: ACTIONS.ISSUE_VERIFY_RESOLUTION_DONE,
})

export const verifyContainment = (
    query: ResolutionSection
): VerifyContainmentCommand => ({
    type: ACTIONS.ISSUE_VERIFY_CONTAINMENT_CALL,
    payload: { query },
})

export const verifyContainmentDone = (): Action => ({
    type: ACTIONS.ISSUE_VERIFY_CONTAINMENT_DONE,
})

export const updateIssueRowVersion = (rowVersion: string): AnyAction => ({
    type: ACTIONS.ISSUE_UPDATE_ROW_VERSION,
    payload: rowVersion,
})

export const updateQuickFixVariant =
    (quickFixVariant: QuickFixVariants) =>
    (dispatch: Dispatch, getState: () => RootState) => {
        const { issue, resources } = getState()
        const getQuickFixVariantName = (quickFix: QuickFixVariants) =>
            resources.quickFixVariant.find((x) => x.code === quickFix)?.name ??
            null
        logAssignmentChange(
            issue,
            'quickFixVariant',
            getQuickFixVariantName(issue?.assignSection?.quickFixVariant),
            getQuickFixVariantName(quickFixVariant)
        )
        dispatch({
            type: ACTIONS.ISSUE_UPDATE_QUICK_FIX,
            payload: quickFixVariant,
        })
    }

export const setIssueWarnings = (warrnings: Array<string>): AnyAction => ({
    type: ACTIONS.ISSUE_SET_WARRNINGS,
    payload: [...warrnings],
})

export const removeContextAction = (issueActionType: IssueActionsTypes) => ({
    type: ACTIONS.ISSUE_REMOVE_CONTEXT_ACTION,
    payload: issueActionType,
})

export const loadIssueContext = (issueId: number): LoadIssueContextCommand => ({
    type: ACTIONS.ISSUE_LOAD_CONTEXT_CALL,
    payload: { issueId },
})

export const loadIssueContextDone = (context: IssueContext) => ({
    type: ACTIONS.ISSUE_LOAD_CONTEXT_DONE,
    payload: { context },
})

export const setLoadingState = (
    loading: boolean,
    label: string = null
): SetLoadingStateCommand => ({
    type: ACTIONS.ISSSUE_SET_LOADING_STATE,
    payload: { label, loading },
})

export const setIssueActionDone = (actionType: string): ActionDoneCommand => ({
    type: ACTIONS.ISSUE_ACTION_DONE,
    payload: { actionType },
})

export const setIssueActionFail = (
    actionType: string,
    error: any
): ActionFailCommand => ({
    type: ACTIONS.ISSUE_ACTION_FAIL,
    payload: { actionType, error },
})

export const revertToNotAssignedCall = (
    query: RevertToNotAssignedQuery
): RevertToNotAssignedCommand => ({
    type: ACTIONS.ISSUE_REVERT_TO_NOT_ASSIGNED_CALL,
    payload: { query },
})

export const revertToNotAssignedDone = () => ({
    type: ACTIONS.ISSUE_REVERT_TO_NOT_ASSIGNED_DONE,
})

export const revertToNotAssignedFail = (error: any) => ({
    type: ACTIONS.ISSUE_ACTION_FAIL,
    payload: error,
})

export const loadActionPlan = (
    issueIdOrNumber: number | string
): LoadIssueActionPlanCommand => ({
    type: ACTIONS.ISSUE_ACTION_PLAN_LOAD_CALL,
    payload: { issueIdOrNumber },
})

export const loadActionPlanDone = (
    actionItems: ActionItem[]
): LoadIssueActionPlanDoneCommand => ({
    type: ACTIONS.ISSUE_ACTION_PLAN_LOAD_DONE,
    payload: { actionItems },
})

export const loadActionPlanFail = (): AnyAction => ({
    type: ACTIONS.ISSUE_ACTION_PLAN_LOAD_FAIL,
})

export const updateSendMessageActive = (isActive: boolean): AnyAction => ({
    type: ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_ACTIVE,
    payload: { isActive },
})

export const updateSendMessageMaximize = (maximize: boolean): AnyAction => ({
    type: ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_MAXIMIZE,
    payload: { maximize },
})
export const updateSendMessageFullScreen = (
    fullScreen: boolean
): AnyAction => ({
    type: ACTIONS.ISSUE_SEND_MESSAGE_UPDATE_FULLSCREEN,
    payload: { fullScreen },
})

export const generateOverviewInPowerPoint = (): AnyAction => ({
    type: ACTIONS.ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT,
})

export const generateSummaryReport = (): AnyAction => ({
    type: ACTIONS.ISSUE_GENERATE_SUMMARY_REPORT,
})

export const confirmHI = (motivation: string): AnyAction => ({
    type: ACTIONS.ISSUE_CONFIRM_HI_CALL,
    payload: motivation,
})

export const confirmHIFail = (error: any) => ({
    type: ACTIONS.ISSUE_CONFIRM_HI_FAIL,
    payload: error,
})

export const confirmHIDone = () => ({
    type: ACTIONS.ISSUE_CONFIRM_HI_DONE,
})

export const downgradeHI = (motivation: string): AnyAction => ({
    type: ACTIONS.ISSUE_DOWNGRADE_HI_CALL,
    payload: motivation,
})

export const downgradeHIFail = (error: any) => ({
    type: ACTIONS.ISSUE_DOWNGRADE_HI_FAIL,
    payload: error,
})

export const downgradeHIDone = () => ({
    type: ACTIONS.ISSUE_DOWNGRADE_HI_DONE,
})

export const sendIssueMessageAction = (
    query: IssueMailMessage
): SendIssueMessageCommand => ({
    type: ACTIONS.ISSUE_SEND_MESSAGE_CALL,
    payload: query,
})

export const sendIssueMessageDone = () => ({
    type: ACTIONS.ISSUE_SEND_MESSAGE_DONE,
})
