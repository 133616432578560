import { OverrideErrors } from './types'

export const getContentInitState = () => ({
    notFound: false,
    title: '',
    subTitle: '',
    referenceId: '',
    showError: false,
    image: null,
})

export const getDefaultMessage = (
    defaultTitle: string,
    status: number,
    dictionary: OverrideErrors
): string => {
    if (dictionary) {
        return dictionary[String(status)] ?? defaultTitle
    }
    return defaultTitle
}
