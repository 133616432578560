import { UserProfile } from 'types/profile'

export const initUserProfile = {
    address: '',
    countryCode: '',
    email: '',
    firstName: '',
    geid: '',
    initials: '',
    isActive: true,
    lastName: '',
    locationCity: '',
    mobilePhone: '',
    officePhone: '',
    hrgtOrganizationInfo: null,
    roles: [],
    zipCode: '',
}

export interface DashboardStoreState {
    searching: boolean
    openSearchBar: boolean
    profile: UserProfile
    loadingProfile: boolean
    avatar: any
}

export const initState: DashboardStoreState = {
    openSearchBar: false,
    searching: false,
    loadingProfile: false,
    profile: { ...initUserProfile },
    avatar: null,
}
