import MoreIcon from '@mui/icons-material/MoreHoriz'
import { ChipProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import MuiChip from '../Chips/Chip'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        label: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        icon: {
            marginLeft: 0,
            marginRight: 0,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    }),
    { name: 'MoreChip' }
)

const MoreChip = (props: ChipProps) => {
    const classes = useStyles()
    return <MuiChip {...props} icon={<MoreIcon />} classes={classes} />
}
MoreChip.muiName = 'MoreChip'

export default MoreChip
