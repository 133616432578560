import { useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { SurveyTranslationContext, SurveyTranslationContextProps } from '.'
import { BaseBar, Content } from 'components'
import { useStyles } from './Survey.style'
import {
    CannotLoadSurvey,
    PreviouslyUnsubscribedSurvey,
    SuccessfullyUnsubscribedSurvey,
    SurveyFooter,
} from './components'
import { getSubscriptionStatus, subscriptionSurvey } from '../api'
import { useParams } from 'react-router'
import { SuccessfullySubscribedSurvey } from './components/SuccessfullySubscribedSurvey'

type SubscriptionViewStates =
    | 'loading-state'
    | 'error'
    | 'previously-unsubscribed'
    | 'successfully-subscribed'
    | 'successfully-unsubscribed'

export const Unsubscribe = () => {
    const { lang, surveyKey } = useParams<{ lang: string, surveyKey: string }>()
    const [subscriptionStates, setSubscriptionStates] =
        useState<SubscriptionViewStates>('loading-state')
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    const classes = useStyles()
    useEffect(() => {
        setSubscriptionStates('loading-state')
        getSubscriptionStatus(surveyKey)
            .then(({ data }) => {
                if (data.isSubscribed) {
                    handleSubscribe('successfully-unsubscribed')
                } else {
                    setSubscriptionStates('previously-unsubscribed')
                }
            })
            .catch(() => setSubscriptionStates('error'))
    }, [])
    const handleSubscribe = async (
        successSubscriptionState: SubscriptionViewStates
    ) => {
        try {
            setSubscriptionStates('loading-state')
            await subscriptionSurvey(surveyKey)

            setSubscriptionStates(successSubscriptionState)
        } catch (error) {
            setSubscriptionStates('error')
        }
    }
    const content = useMemo(() => {
        switch (subscriptionStates) {
            case 'error':
                return <CannotLoadSurvey />
            case 'previously-unsubscribed':
                return (
                    <PreviouslyUnsubscribedSurvey
                        onSubscribe={() =>
                            handleSubscribe('successfully-subscribed')
                        }
                    />
                )
            case 'successfully-unsubscribed':
                return (
                    <SuccessfullyUnsubscribedSurvey
                        onSubscribe={() =>
                            handleSubscribe('successfully-subscribed')
                        }
                    />
                )
            case 'successfully-subscribed':
                return <SuccessfullySubscribedSurvey />
            default:
                return null
        }
    }, [subscriptionStates, t, lang])
    const loading = subscriptionStates === 'loading-state'
    return (
        <>
            <Helmet>
                <title>{t('Survey_PageTitle', 'Customer survey')}</title>
            </Helmet>
            <BaseBar title="" />
            <Content loading={loading}>
                {!loading && (
                    <div className={classes.root}>
                        {content}
                        <SurveyFooter />
                    </div>
                )}
            </Content>
        </>
    )
}
