import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(() => ({
    root: {
        padding: '4px 4px 0',
        display: 'flex',
        justifyContent: 'space-between',
    },
}))

export interface CardInformationProps {
    informationResult: {
        label: string
        text: string
    }[]
}

const CardInformation = (props: CardInformationProps) => {
    const { informationResult } = props
    const classes = useStyles()
    return (
        <div className={classes.root}>
            {informationResult.map((i) => (
                <Typography
                    key={i.label}
                    variant="caption"
                    {...dataTestId(
                        i.label === 'Address'
                            ? 'ADRESS_PO_INFO'
                            : 'ACTIVITY_INFO'
                    )}
                >
                    {i.label} : {i.text}
                </Typography>
            ))}
        </div>
    )
}

export default CardInformation
