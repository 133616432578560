import { default as GenericError } from './svg/GenericError.svg'

export interface GenericErrorSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const GenericErrorSvg = (props: GenericErrorSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? GenericError}
            width={width}
            height={height}
            alt={alt ?? 'Generic error'}
            {...other}
        />
    )
}

export default GenericErrorSvg
