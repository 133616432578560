import { makeStyles } from '@mui/styles'
import { DragDropContext } from 'react-beautiful-dnd'
import { ActionItem } from 'types/actionPlanTypes'
import { ActionPlanDashboardColumn, DashboardColumn } from '..'
import { DashboardDesktopProps } from './types'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(0, 4),
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(4),
            justifyContent: 'space-between',
            '@media (max-width: 768px)': {
                flexDirection: 'column',
            },
        },
    }),
    { name: 'DashboardDesktop' }
)

export const DashboardDesktop = (props: DashboardDesktopProps) => {
    const { onDragEnd, columnOrder, columns, items } = props
    const classes = useStyles()

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <div className={classes.root}>
                {columnOrder.map((columnId: string) => {
                    const column: ActionPlanDashboardColumn = columns[columnId]
                    const parseItems: ActionItem[] = column.itemsIds.map(
                        (itemId: number) => items[itemId]
                    )
                    return (
                        <DashboardColumn
                            isDropDisabled={false}
                            key={column.id}
                            column={column}
                            items={parseItems}
                        />
                    )
                })}
            </div>
        </DragDropContext>
    )
}
