import {
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { useMemo } from 'react'
import TextField from '../Base/TextField'

import { PermIdentity } from '@mui/icons-material'
import { BaseTextFieldProps, CircularProgress, useTheme } from '@mui/material'
import { searchEmployees as searchEmployeesApi } from 'api/employees'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { Employee } from 'types/issueTypes'
import { getEmployeeOrganizationInfoName } from 'utils'
import { arrayToStringLine, createArrayWithoutNulls } from '../../utils/helpers'
import EmployeeItem, { formatEmployee } from './components/EmployeeItem'
import { useFieldState } from './utils'

export interface EmployeeSelectProps extends Partial<BaseTextFieldProps> {
    employee?: Employee
    onEmployeeChanged?: (employee?: Employee) => void
    startAdornment?: React.ReactNode
    onlyActive?: boolean
}

export const EmployeeSelect = (props: EmployeeSelectProps) => {
    const {
        disabled,
        employee,
        onEmployeeChanged: onEmailChanged,
        error: errorProps,
        helperText: helperTextProps,
        startAdornment: startAdornmentProp,
        onlyActive = true,
        ...other
    } = props

    const theme = useTheme()
    const [{ loading, options, error, helperText }, setState] =
        useFieldState<Employee>()
    const employeeDetails = useMemo(() => {
        const tags = createArrayWithoutNulls(
            employee?.fullName,
            employee?.countryCode,
            employee?.organizationInfo
                ? getEmployeeOrganizationInfoName(employee?.organizationInfo)
                : ''
        )
        return arrayToStringLine(tags)
    }, [employee])
    const searchEmployees = debounce(async (wildChard: string) => {
        try {
            if (loading || (!wildChard && wildChard?.length < 4)) {
                return
            }
            setState({ loading: true, error: null, helperText: null })

            const { data: employees } = await searchEmployeesApi(
                wildChard,
                1,
                20,
                onlyActive
            )

            if (employees?.length === 1) {
                handleEmailChange(employees[0])
                setState({ loading: false, options: [] })
            } else {
                setState({ loading: false, options: [...employees] })
            }
        } catch (error) {
            setState({
                loading: false,
                error,
                options: [],
                helperText: 'Cannot load employees list',
            })
        }
    }, 800)
    const handleEmailChange = (employee: Employee) =>
        onEmailChanged && onEmailChanged(employee)

    const handleInputChange = (
        e: any,
        newValue: string,
        reason: AutocompleteInputChangeReason
    ) => {
        if (reason === 'input') {
            searchEmployees(newValue)
        }
    }
    const getOptionSelected = (option: Employee, value: Employee): boolean => {
        return option?.email === value?.email
    }
    const handleOnFocus = () => setState({ error: null, helperText: null })
    return (
        <Autocomplete
            disabled={disabled}
            value={employee ?? null}
            autoSelect={true}
            onInputChange={handleInputChange}
            options={options}
            getOptionLabel={formatEmployee}
            isOptionEqualToValue={getOptionSelected}
            getOptionDisabled={(option) => !Boolean(option?.isActive)}
            noOptionsText={'No loaded employees. Enter at least 3 characters…'}
            loading={loading && options?.length === 0}
            loadingText="Loading employees…"
            disableClearable={loading || disabled}
            onFocus={handleOnFocus}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) => {
                          const label = state.getOptionLabel(f)
                          return label
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      })
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as Employee
                switch (reason) {
                    case 'clear':
                        handleEmailChange(null)
                        break
                    case 'blur':
                        handleEmailChange({ ...employee })
                        return
                    default:
                        handleEmailChange({ ...selectedValue })
                }
            }}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <EmployeeItem details={option} />
                    </li>
                )
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={Boolean(error) || errorProps}
                    helperText={
                        helperText || helperTextProps || employeeDetails
                    }
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                        startAdornment: (loading && (
                            <CircularProgress
                                size={20}
                                color="secondary"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        )) ||
                            startAdornmentProp ||
                            params.InputProps.startAdornment || (
                                <PermIdentity
                                    fontSize="small"
                                    style={{
                                        marginRight: theme.spacing(0.5),
                                    }}
                                />
                            ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default EmployeeSelect
