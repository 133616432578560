import { Language } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip, getShortOptionLabel } from 'components'
import { ChangeEvent, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { CountryFilter, FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { RootState } from '../../store/types'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected } from './utils'

export interface MultiCountrySelectProps extends Partial<BaseTextFieldProps> {
    regions?: FilterBase[]
    selectedCountries: CountryFilter[]
    onCountriesChanged: (countries: CountryFilter[]) => void
}

export const MultiCountrySelect = (props: MultiCountrySelectProps) => {
    const {
        regions = null,
        selectedCountries = [],
        onCountriesChanged,
        error,
        helperText,
        disabled,
        ...other
    } = props
    const countries = useSelector<RootState, CountryFilter[]>(
        (state) => state.resources.countries
    )
    const options = useMemo(() => {
        if (Array.isArray(regions) && 0 < regions?.length) {
            return countries.filter((c) =>
                regions?.some((r) => r.code === c.regionCode)
            )
        }
        return countries
    }, [countries, regions])

    useEffect(() => {
        if (Array.isArray(regions) && 0 < regions?.length) {
            const newCountries = [...selectedCountries]
            const countriesToRemove = []
            newCountries.forEach((x, i) => {
                if (!regions.map((r) => r.code).includes(x.regionCode)) {
                    countriesToRemove.push(i)
                }
            })
            if (0 < countriesToRemove?.length) {
                countriesToRemove.forEach((i) => {
                    newCountries.splice(i, 1)
                })
                onCountriesChanged(newCountries)
            }
        }
    }, [regions])

    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | CountryFilter>
    ) =>
        onCountriesChanged &&
        onCountriesChanged([...values] as Array<CountryFilter>)

    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedCountries}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            disableClearable={disabled}
            noOptionsText={'No match countries'}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Countries"
                    error={error}
                    helperText={helperText}
                    {...dataTestId('COUNTRIES_COMMON')}
                    renderIcon={(props) => <Language {...props} />}
                    {...other}
                />
            )}
        />
    )
}
