import {
    Grid,
    GridProps,
    GridSpacing,
    useMediaQuery,
    useTheme,
} from '@mui/material'
import { FC } from 'react'

export interface GridContainerProps extends GridProps {
    desktopSpacing?: GridSpacing
    mobileSpacing?: GridSpacing
}

export const GridContainer: FC<GridContainerProps> = (props) => {
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('sm'))
    const { desktopSpacing = 2, mobileSpacing = 1, children, ...other } = props

    return (
        <Grid
            container
            spacing={isDesktop ? desktopSpacing : mobileSpacing}
            {...other}
        >
            {children}
        </Grid>
    )
}
