import dayjs from 'dayjs'
import { ProductLineLocationProps } from 'Issue/Pickers'
import { Address } from 'types/adminTypes'
import { RcaReportTypes } from 'types/enums'
import { OrganizationalInfo, ResolvingUnit } from 'types/issueTypes'

export const humanizeDateTime = (date: string): string => {
    const newDate = dayjs(date).utc()
    const actuallyDate = dayjs().utc()
    const during = newDate.diff(actuallyDate)

    if (newDate.isValid()) {
        const basicFormat = newDate.utc().format('YYYY-MM-DD')
        if (newDate.diff(actuallyDate, 'month') <= -18)
            return `${basicFormat} (${newDate.add(6, 'month').fromNow()})`
        else if (newDate.diff(actuallyDate, 'month') >= 18)
            return `${basicFormat} (${newDate.subtract(6, 'month').fromNow()})`
        else if (Math.abs(during) > Math.abs(165600000))
            return `${basicFormat} (${newDate.subtract(12, 'h').fromNow()})`
        else if (Math.abs(during) > 129600000 && Math.abs(during) < 165600000)
            return `${basicFormat} (${newDate.subtract(8, 'h').fromNow()})`
        else return `${basicFormat} (${newDate.subtract(30, 'm').fromNow()})`
    } else return '-'
}

export const getPrettyDateTime: (date: string) => string = (date) =>
    date && dayjs(date).utc().local().format('YYYY-MM-DD HH:mm:ss')

export const getPrettyDate: (date: string) => string = (date) => {
    if (date === null && date === '') {
        return null
    }
    const newDate = dayjs(date)
    return newDate.isValid() ? newDate.utc().format('YYYY-MM-DD') : ''
}

export const getEmployeeOrganizationInfoName = (
    organizationInfo: OrganizationalInfo
): string => {
    return `(${
        organizationInfo?.division?.code ? organizationInfo?.division.code : ''
    }${
        organizationInfo?.businessUnit?.code
            ? '/' + organizationInfo?.businessUnit.code
            : ''
    })${
        organizationInfo?.productGroup?.code
            ? '/' + organizationInfo?.productGroup.code
            : ''
    }${organizationInfo?.region ? ' (' + organizationInfo?.region + ')' : ''}${
        organizationInfo?.country ? ' (' + organizationInfo?.country + ')' : ''
    }`
}

export const getResolutionUnitName = (
    resolutionUnitObj: ResolvingUnit
): string => {
    const nc = (value: string, conj: string = ',') =>
        value ? value + conj : ''
    if (
        !resolutionUnitObj ||
        !resolutionUnitObj?.id ||
        !resolutionUnitObj?.organizationalInfo
    ) {
        return ''
    }

    const { organizationalInfo } = resolutionUnitObj
    const { division, businessUnit, productGroup } = organizationalInfo

    return `${division?.code} ${nc(businessUnit?.code, '')} ${
        productGroup?.code ? `(${productGroup?.code})` : ''
    } ${nc(productGroup?.name)} ${nc(resolutionUnitObj?.activity)} ${nc(
        resolutionUnitObj?.balId
    )}${resolutionUnitObj?.isGlobal ? 'GLOBAL, ' : ' '}${nc(
        resolutionUnitObj?.countryCode,
        ''
    )} ${nc(resolutionUnitObj?.city)} ${nc(resolutionUnitObj?.address, '')}`
}

export const getProductLineLocationName = (
    productLineLocation: ProductLineLocationProps
): string => {
    if (!productLineLocation || !productLineLocation?.id) {
        return ''
    }

    const { name, productLineCode, productLineLocationId } = productLineLocation

    return `${productLineLocationId}, (${productLineCode}), ${name}`
}

export const getRcaReportTypeName = (reportType: RcaReportTypes): string => {
    switch (reportType) {
        case RcaReportTypes.DMAIC:
            return 'DMAIC report'
        case RcaReportTypes.Report8D:
            return '8D report'
        case RcaReportTypes.RCA_Tools:
            return 'RCA tools'
        case RcaReportTypes.RcaTemplates:
            return 'RCA Templates'
        default:
            return null
    }
}

export const formatAddress = (address: Partial<Address>): string => {
    let results = null
    if (address?.countryCode) {
        results = address.countryCode + ','
    }
    if (address?.city) {
        results = `${results ?? ''} ${address.city},`
    }
    if (address?.address) {
        results = `${results ?? ''} ${address.address}`
    }
    if (address?.zipCode) {
        results = `${results ?? ''}; ${address.zipCode}`
    }
    return results ?? EMPTY_VALUE_PLACEHOLDER
}

export const EMPTY_VALUE_PLACEHOLDER = '...'
