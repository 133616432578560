import { CcrpResources } from 'types/models'
import { AnyAction } from 'redux'

export const SET_RESOURCES = 'SET_RESOURCES'

export interface GlobalResourcesState extends CcrpResources {
    loading: boolean
}

export const initState: GlobalResourcesState = {
    loading: true,
    action: [],
    roleCode: [],
    complaintStatus: [],
    complaintAttachmentType: [],
    targetResolvingDateChangeReason: [],
    quickFixVariant: [],
    effectivenessType: [],
    issueType: [],
    divisions: [],
    businessUnits: [],
    productGroups: [],
    regions: [],
    countries: [],
    cities: [],
    actionMessageType: [],
    customerAccountType: [],
    issueSourceType: [],
    reportDateType: [],
    reportDatePeriodType: [],
    allInOneReportColumns: [],
    resolvingUnitDescriptiveNames: [],
    reportNotificationRepeatType: [],
    allInOneReportEmployeeRoles: [],
    activities: [],
    hrgtLevel1: [],
    hrgtLevel2: [],
    hrgtLevel3: [],
}

export const setGlobalResources = (resources: CcrpResources): AnyAction => ({
    type: SET_RESOURCES,
    payload: resources,
})

export const resourcesReducer = (state = initState, action: AnyAction) =>
    action.type === SET_RESOURCES
        ? { ...state, loading: false, ...action.payload }
        : state
