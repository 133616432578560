import axios, { AxiosResponse, CancelToken } from 'axios'
import { IssueContext } from 'types/issueTypes'

const controllerName = '/issue'

/**
 * @param {number} issueId - capture section object
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const getContext = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<IssueContext>> =>
    axios.get(`${controllerName}/${issueId}/context`, { cancelToken })
