import MuiAvatar, { AvatarProps } from '@mui/material/Avatar'

export interface AbbAvatar extends AvatarProps {
    size: 'small' | 'medium' | 'big'
}

export const Avatar = (props: AbbAvatar) => {
    const { size, style, ...other } = props
    let radius = 0
    let fontSize = 0
    switch (size) {
        case 'small':
            radius = 32
            fontSize = 12
            break
        case 'big':
            radius = 48
            fontSize = 16
            break
        default:
            radius = 40
            fontSize = 16
            break
    }
    const avatarStyle = {
        ...style,
        width: radius,
        height: radius,
        fontSize: fontSize,
    }
    return <MuiAvatar {...other} style={avatarStyle} />
}

Avatar.muiName = 'Avatar'

Avatar.defaultProps = {
    size: 'medium',
}

export default Avatar
