import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface MultiDescriptiveNamesSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedItems: FilterBase<string>[]
    onSelectedItemsChanged?: (regions: FilterBase<string>[]) => void
}

export const MultiDescriptiveNamesSelect = (
    props: MultiDescriptiveNamesSelectProps
) => {
    const options = useSelector((state: RootState) =>
        state.resources.resolvingUnitDescriptiveNames.map((x) => ({
            ...x,
            code: String(x.code),
        }))
    )
    const { selectedItems, onSelectedItemsChanged, disabled, ...other } = props
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: (string | FilterBase<string>)[]
    ) =>
        onSelectedItemsChanged &&
        onSelectedItemsChanged(
            ([...values] as FilterBase[]).map((x) => ({
                ...x,
                code: x.code,
            })) as Array<FilterBase<string>>
        )
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedItems?.map((x) => ({
                ...x,
                code: String(x.code),
            }))}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            noOptionsText="No match Descriptive names"
            disableClearable={Boolean(disabled)}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Descriptive names"
                    {...dataTestId('DESCRIPTIVE_NAMES_COMMON')}
                    {...other}
                />
            )}
        />
    )
}
