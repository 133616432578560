import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        Link: {
            fontWeight: 400,
            borderBottom: `2px solid ${theme.palette.grey[200]}`,
            textDecoration: 'none',
            transition: theme.transitions.create('border-bottom', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut,
            }),
            color: theme.palette.grey[700],
            '&:hover': {
                borderBottom: `2px solid ${theme.palette.secondary.main}`,
            },
            '&:visited &:active': {
                borderBottom: `2px solid ${theme.palette.shades.red1_10}`,
            },
        },
    }),
    { name: 'Link' }
)

export interface LinkProps extends React.HtmlHTMLAttributes<HTMLAnchorElement> {
    label: string
    url: string
    [key: string]: any
}

export const Link = (props: LinkProps) => {
    const classes = useStyles()
    const { label, url, ...other } = props
    return (
        <a href={url} className={classes.Link} {...other}>
            {label}
        </a>
    )
}

Link.muiName = 'Link'

export default Link
