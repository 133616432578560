import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useSvgIconStyles = makeStyles(
    (theme: AbbTheme) => ({
        inherit: { fontSize: 'inherit' },
        small: { fontSize: theme.typography.pxToRem(20) },
        medium: { fontSize: theme.typography.pxToRem(24) },
        large: { fontSize: theme.typography.pxToRem(35) },
    }),
    { name: 'SvgIcon' }
)
