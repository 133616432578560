import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { Content } from '../components'

export const SfdcRedirectPage = () => {
    const location = useLocation()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        const hash = location.hash.replaceAll('#', '')
        const parts = hash.split('/')
        if (hash.search('dashboard')) {
            history.replace('/')
        } else if (hash.search('account')) {
            window.location.replace(
                `https://app.powerbi.com/groups/60f44b49-dfb6-484d-990d-3392b2fc8cbb/reports/8aeb54af-ae37-46d2-85d7-daa4b5d81ee7/ReportSectiondbd0851f67e4a94a9dda?filter=Customer/Customer_x0020_Code eq '${
                    parts[parts.length - 1]
                }'`
            )
        } else if (hash.search('contact')) {
            window.location.replace(
                `https://app.powerbi.com/groups/60f44b49-dfb6-484d-990d-3392b2fc8cbb/reports/8aeb54af-ae37-46d2-85d7-daa4b5d81ee7/ReportSectiondbd0851f67e4a94a9dda?filter=Customer_x0020_Contact/Email eq '${
                    parts[parts.length - 1]
                }'`
            )
        } else {
            setLoading(false)
        }
    }, [])
    return <Content loading={loading} loadingDesc="Redirecting..." />
}
