import { makeStyles } from '@mui/styles'
import { useContext } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { AbbTheme } from 'styles/createAbbTheme'
import { ActionItem } from 'types/actionPlanTypes'
import { ActionPlanContext } from '../../context/ActionPlanContext'
import DashboardHeader from '../DashboardHeader'
import DashboardItem from '../DashboardItem'
import { DashboardColumnProps } from '../types'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            width: '100%',
            maxWidth: '450px',
            '@media (max-width: 768px)': {
                maxWidth: '100%',
            },
        },
        item: {
            marginBottom: theme.spacing(4),
        },
    }),
    { name: 'DashboardColumn' }
)

export const DashboardColumn = (props: DashboardColumnProps) => {
    const { openActionItem } = useContext(ActionPlanContext)
    const { column, items, isDropDisabled } = props
    const classes = useStyles()
    const onClickLabel = (item: ActionItem) => {
        openActionItem(item.issueId, item.actionItemId)
    }

    return (
        <Droppable droppableId={column.id} isDropDisabled={isDropDisabled}>
            {(provided, snapshot) => (
                <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={classes.root}
                >
                    <DashboardHeader column={column} count={items.length} />
                    {items.map((item: ActionItem, index: number) => (
                        <Draggable
                            draggableId={`${item.actionItemId}`}
                            index={index}
                            key={item.actionItemId}
                        >
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className={classes.item}
                                >
                                    <DashboardItem
                                        isDragging={snapshot.isDragging}
                                        item={item}
                                        onClickLabel={onClickLabel}
                                    />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    )
}

export default DashboardColumn
