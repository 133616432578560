import { Chip } from '@mui/material'
import { IssueTypes, RcaReportTypes } from 'types/enums'
import { getRcaReportTypeName } from '../../utils/formaters'
import { ReportsViewProps, useStyles } from './ReportsView'

export interface ToolsViewProps extends ReportsViewProps {
    issueType: IssueTypes
}

const ToolsView = (props: ToolsViewProps) => {
    const { generateRaport, issueType, ...other } = props
    const classes = useStyles(props)

    return issueType === IssueTypes.Internal_NC ? (
        <div className={classes.root} {...other}>
            <span className={classes.label}>RCA Tools</span>
            <div>
                <Chip
                    className={classes.chip}
                    label={getRcaReportTypeName(RcaReportTypes.RCA_Tools)}
                    onClick={() =>
                        generateRaport &&
                        generateRaport(RcaReportTypes.RCA_Tools)
                    }
                    variant="outlined"
                />
            </div>
        </div>
    ) : null
}

export default ToolsView
