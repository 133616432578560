import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { HrgtLevelFilter } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionLabel, getOptionSelected } from './utils'

export interface OwnerMngrLvl1SelectProps extends Partial<BaseTextFieldProps> {
    selectedItems: HrgtLevelFilter[]
    onSelectedItemsChanged?: (items: HrgtLevelFilter[]) => void
}

export const OwnerMngrLvl1Select = (props: OwnerMngrLvl1SelectProps) => {
    const {
        selectedItems,
        onSelectedItemsChanged,
        disabled,
        error,
        helperText,
        ...other
    } = props
    const [searchText, setSearchText] = useState('')
    const options = useSelector<RootState, HrgtLevelFilter[]>(
        (state) => state?.resources?.hrgtLevel1 ?? []
    )
    const handleOnChange = (
        e: ChangeEvent<any>,
        value: Array<string | HrgtLevelFilter>
    ) => {
        setSearchText('')
        onSelectedItemsChanged &&
            onSelectedItemsChanged((value as HrgtLevelFilter[]) ?? [])
    }
    return (
        <Autocomplete
            multiple
            disabled={disabled}
            value={selectedItems ?? []}
            inputValue={searchText}
            onInputChange={(e, value, reason) => {
                if (reason === 'input') {
                    setSearchText(value)
                } else if (reason === 'clear') {
                    setSearchText('')
                }
            }}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, { code, name }) => (
                <li {...props}>{`(${code}) ${name ?? ''}`}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option?.code}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    error={error}
                    helperText={helperText}
                    label={`Owner Mngr Lv1`}
                    {...dataTestId('OWNER_MNGR_LV1')}
                    {...other}
                />
            )}
        />
    )
}
