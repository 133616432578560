import { Business } from '@mui/icons-material'
import {
    AdminDialog,
    CustomerContactsManagePageDialogModeType,
    useCustomerContactsContext,
} from 'Admin'
import {
    Button,
    FormControlLabel,
    FormGridItem,
    GridContainer,
    Switch,
    TextField,
    ValueViewNext,
} from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import { CustomerContactErrors, CustomerContactQuery } from 'types/issueTypes'
import { dataTestId } from 'utils'

const getInitQuery: () => CustomerContactQuery = () => ({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    isActive: false,
})

export interface CustomerContactsManagePageDialogProps {
    dialogMode: CustomerContactsManagePageDialogModeType
    onClose: () => void
    errors: CustomerContactErrors
    setErrors: any
}

export const CustomerContactsManagePageDialog = (
    props: CustomerContactsManagePageDialogProps
) => {
    const {
        addCustomerContact,
        customer,
        customerContact,
        updateCustomerContact,
    } = useCustomerContactsContext()
    const { onClose, dialogMode, errors, setErrors } = props
    const [query, setQuery] = useState(getInitQuery())

    useEffect(() => {
        setQuery({
            ...customerContact,
        })
    }, [customerContact, dialogMode])

    const handleChange =
        (key: keyof CustomerContactQuery) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setErrors((prev: CustomerContactErrors) => ({
                ...prev,
                [key]: null,
            }))
            setQuery((prev: CustomerContactQuery) => ({
                ...prev,
                [key]: e.target.value,
            }))
        }

    const getFieldProps = (fieldName: keyof CustomerContactQuery) => {
        const helperText = errors[fieldName] ?? ''

        return {
            value: query[fieldName] ?? '',
            onChange: handleChange(fieldName),
            error: Boolean(errors[fieldName]),
            helperText,
        }
    }

    const title =
        dialogMode === 'edit'
            ? 'Update customer contact'
            : dialogMode === 'add'
            ? 'Add new customer contact'
            : ''

    const subTitle =
        dialogMode === 'edit'
            ? 'Update contact to'
            : dialogMode === 'add'
            ? 'Add contact to'
            : ''
    const buttonName =
        dialogMode === 'edit' ? 'Update' : dialogMode === 'add' ? 'Add' : ''

    const toggleCheckbox = (key: keyof CustomerContactQuery) => (e: any) => {
        setQuery((prev: CustomerContactQuery) => ({
            ...prev,
            [key]: e.target.checked,
        }))
    }

    return (
        <AdminDialog
            title={title}
            pageTitle={customer?.name}
            subTitle={subTitle}
            open={Boolean(dialogMode)}
            onClose={onClose}
            renderActions={() => (
                <>
                    <Button onClick={onClose} {...dataTestId(`CLOSE_CONTACT`)}>
                        Close
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => {
                            if (dialogMode === 'add') {
                                addCustomerContact({
                                    customerGuid: customer.guid,
                                    email: query.email,
                                    firstName: query.firstName,
                                    lastName: query.lastName,
                                    phone: query.phone,
                                })
                            } else if (dialogMode === 'edit') {
                                updateCustomerContact(customerContact.id, {
                                    email: query.email,
                                    firstName: query.firstName,
                                    lastName: query.lastName,
                                    phone: query.phone,
                                    isActive: query.isActive,
                                })
                            }
                        }}
                        {...dataTestId(`ADD_CONTACT`)}
                    >
                        {buttonName}
                    </Button>
                </>
            )}
        >
            <ValueViewNext
                renderIcon={(props) => <Business {...props} />}
                label="Selected Customer "
                fullWidth
            >
                {customer?.name}
            </ValueViewNext>
            <GridContainer>
                <FormGridItem>
                    <TextField
                        label="First name"
                        placeholder="Contact first name"
                        required
                        {...getFieldProps('firstName')}
                        {...dataTestId(`FIRST_NAME_CONTACT`)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <TextField
                        label="Last name"
                        placeholder="Contact last name"
                        required
                        {...getFieldProps('lastName')}
                        {...dataTestId(`LAST_NAME_CONTACT`)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <TextField
                        label="Email"
                        placeholder="Contact email"
                        required
                        {...getFieldProps('email')}
                        {...dataTestId(`EMAIL_CONTACT`)}
                    />
                </FormGridItem>
                <FormGridItem>
                    <TextField
                        label="Phone"
                        placeholder="Contact phone number"
                        {...getFieldProps('phone')}
                        {...dataTestId(`PHONE_CONTACT`)}
                    />
                </FormGridItem>
                {dialogMode === 'edit' && (
                    <FormGridItem>
                        <FormControlLabel
                            label={
                                query?.isActive
                                    ? 'Customer contact is active'
                                    : `Customer contact isn't active`
                            }
                            error={Boolean(errors?.isActive)}
                            control={
                                <Switch
                                    checked={query?.isActive}
                                    onChange={toggleCheckbox('isActive')}
                                    color="secondary"
                                />
                            }
                        />
                    </FormGridItem>
                )}
            </GridContainer>
        </AdminDialog>
    )
}
