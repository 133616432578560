import axios, { AxiosResponse } from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    ISSUE_SAVE_CALL,
    IssueStoreState,
    prepareDraft,
    saveDraftDone,
    //updateIssueRowVersion,
} from '..'

import {
    // IssueActionsTypes,
    TargetResolvingDateChangeReasons,
} from 'types/enums'
import { RootState } from 'store/types'
import { showSnackbar } from 'store/app'
import { saveCaptureSection } from 'api'
import { loadIssue, setIssueActionFail } from '../actions'
import { CaptureSection } from 'types/issueTypes'

export function* saveDraftAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        yield put(prepareDraft())
        const issue: IssueStoreState = yield select((s: RootState) => s.issue)
        const { data: captureSection } = (yield call(
            saveCaptureSection,
            issue.captureSection,
            issue.changeLog?.capture ?? {},
            cancelSource.token
        )) as AxiosResponse<CaptureSection>
        captureSection.targetResolutionDateChangeReasonId =
            TargetResolvingDateChangeReasons.MoreTimeRequired
        yield put(saveDraftDone(captureSection))
        yield put(showSnackbar('Issue was saved', false))
        yield put(loadIssue(issue.issueId))
    } catch (error) {
        yield put(showSnackbar("Issue wasn't saved!", true))
        yield put(setIssueActionFail(ISSUE_SAVE_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveDraftSaga() {
    yield takeLatest([ISSUE_SAVE_CALL], saveDraftAction)
}
