export interface OverrideErrors {
    [status: number]: string
}

export type ContentVariant = 'default' | 'medium' | 'large'

export enum ContentVariantEnum {
    default = 768,
    medium = 1200,
    large = 1400,
}

export interface ContentProps {
    className?: string
    title?: string
    label?: string
    loading?: boolean
    loadingDesc?: string
    error?: any
    overrideTitle?: OverrideErrors
    overrideSubtitle?: OverrideErrors
    issueId?: number
    coverBackground?: boolean
    variant?: ContentVariant
    onRetry?: () => void
    onNotFound?: () => void
}

export interface ContentState {
    notFound: boolean
    title: string
    subTitle: string
    referenceId: string
    showError: boolean
    image?: React.ReactNode
}
