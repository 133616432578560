import { useMediaQuery, useTheme } from '@mui/material'
import { FC } from 'react'
import { AdminDialogDesktop } from './components/AdminDialogDesktop'
import { AdminDialogMobile } from './components/AdminDialogMobile'
import { AdminDialogProps } from './types'

export const AdminDialog: FC<AdminDialogProps> = (props) => {
    const {
        children,
        pageTitle,
        subTitle,
        adminDialogDesktopProps = {},
        disabledMobile = false,
        ...other
    } = props
    const theme = useTheme()
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

    return isDesktop || disabledMobile ? (
        <AdminDialogDesktop {...other} {...adminDialogDesktopProps}>
            {children}
        </AdminDialogDesktop>
    ) : (
        <AdminDialogMobile subTitle={subTitle} pageTitle={pageTitle} {...other}>
            {children}
        </AdminDialogMobile>
    )
}
