import { Button } from '@mui/material'
import { DialogBase, ReturnToResolutionOwnerDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { ToVerifyResolutionState } from 'Issues/Tiles'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { rejectResolution, verifyResolution } from 'store/myActions'
import { RejectResolutionQuery } from 'types/actions'
import { IssueActionsTypes } from 'types/enums'
import { TileActionsProps } from '..'

export const ToVerifyResolutionActions = (props: TileActionsProps) => {
    const { myActionMeta, issueNumber } = props

    const dispatch = useDispatch()
    const [state, setState] = useState<ToVerifyResolutionState>({
        returnOpen: false,
        verifyOpen: false,
        expanded: false,
        loading: true,
        attachments: [],
    })

    const handleVerifyResolution = () => {
        setState((prev) => ({ ...prev, verifyOpen: false }))
        dispatch(verifyResolution(myActionMeta.issueId))
    }

    const handleRejectResolution = (formData: RejectResolutionQuery) => {
        setState((prev) => ({ ...prev, returnOpen: false }))
        dispatch(
            rejectResolution(formData, IssueActionsTypes.To_Verify_Resolution)
        )
    }

    return (
        <>
            <Button
                onClick={() =>
                    setState((prev) => ({ ...prev, returnOpen: true }))
                }
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .RETURN_TO_RESOLUTION_OWNER}
                event="Return to Resolution Owner on dashboard"
            >
                Return to Resolution Owner
            </Button>
            <Button
                color="secondary"
                onClick={() =>
                    setState((prev) => ({ ...prev, verifyOpen: true }))
                }
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.VERIFY}
                event="Verify Resolution on dashboard"
            >
                Verify
            </Button>
            <ReturnToResolutionOwnerDialog
                open={state.returnOpen}
                message="You are about to return this issue to Resolution Owner. You have to provide the reason why you're doing this."
                number={issueNumber}
                issueId={myActionMeta.issueId}
                rowVersion={myActionMeta.rowVersion}
                onReturnToRO={handleRejectResolution}
                onClose={() =>
                    setState((prev) => ({ ...prev, returnOpen: false }))
                }
            />
            <DialogBase
                title="Verify resolution"
                message={`
                    You are about to verify resolution for issue ${issueNumber}. Proceed?`}
                confirmLabel="Verify"
                closeLabel="Cancel"
                onConfirm={handleVerifyResolution}
                onClose={() =>
                    setState((prev) => ({ ...prev, verifyOpen: false }))
                }
                open={state.verifyOpen}
            />
        </>
    )
}
