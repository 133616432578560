import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { Button, ButtonProps } from 'components'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        divider: {
            margin: theme.spacing(1, 0, 2, 0),
        },
        icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'AddPersonButton' }
)

export interface AddPersonButtonProps extends ButtonProps {}

export const AddPersonButton = (props: AddPersonButtonProps) => {
    const { className, children, ...other } = props
    const classes = useStyles()

    return (
        <Button className={clsx(classes.divider, className)} {...other}>
            <PersonAddIcon className={classes.icon} fontSize="small" />
            {children}
        </Button>
    )
}
