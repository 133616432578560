import { Attachment } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes, memo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { SalesforceIsssueInfo } from 'types/issueTypes'
import {
    DataArea,
    HyperLink,
    Label,
    useDataAreaStyles,
    ValueView,
} from '../../components'
import { getArray } from '../../utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        dataArea: {
            marginBottom: theme.spacing(2),
        },
        attachments: {
            display: 'flex',
            flexDirection: 'column',
        },

        icon: {
            marginRight: theme.spacing(2),
        },
    }),
    {
        name: 'AdditionalProductInformation',
    }
)

export interface SalesforceAdditionalInformationProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    sfdcAdditionalInfo: SalesforceIsssueInfo
}

export const SalesforceAdditionalInformation = memo(
    (props: SalesforceAdditionalInformationProps) => {
        const { className, sfdcAdditionalInfo, ...other } = props
        const classes = useStyles(props)
        const dataAreaClasses = useDataAreaStyles(props)
        const subject = sfdcAdditionalInfo?.subject
        const originatingCountry = sfdcAdditionalInfo?.originatingCountry
        const division = sfdcAdditionalInfo?.division
        const businessUnit = sfdcAdditionalInfo?.businessUnit
        const productGroup = sfdcAdditionalInfo?.productGroup
        const product = sfdcAdditionalInfo?.product
        const abbOrderNumber = sfdcAdditionalInfo?.abbOrderNumber
        const salesOrderNumber = sfdcAdditionalInfo?.salesOrderNumber
        const purchaseOrderNumber = sfdcAdditionalInfo?.purchaseOrderNumber
        const serviceAgreement = sfdcAdditionalInfo?.serviceAgreement
        const sapNumber = sfdcAdditionalInfo?.sapNumber
        const attachments = getArray(sfdcAdditionalInfo, 'attachments')
        return (
            <div className={clsx(className, classes.root)} {...other}>
                <Typography variant="h6">
                    Additional information from Salesforce
                </Typography>
                <DataArea className={classes.dataArea}>
                    {subject && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Subject"
                            value={subject}
                            singleLine={false}
                        />
                    )}
                    {originatingCountry && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Originating country"
                            value={originatingCountry}
                        />
                    )}
                    {division && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Business area"
                            value={division}
                        />
                    )}
                    {businessUnit && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Division"
                            value={businessUnit}
                        />
                    )}
                    {productGroup && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Product group"
                            value={productGroup}
                        />
                    )}
                    {product && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Product"
                            value={product}
                        />
                    )}
                    {abbOrderNumber && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="ABB order number"
                            value={abbOrderNumber}
                        />
                    )}
                    {salesOrderNumber && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Sales order number"
                            value={salesOrderNumber}
                        />
                    )}
                    {purchaseOrderNumber && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Purchase order number"
                            value={purchaseOrderNumber}
                        />
                    )}
                    {serviceAgreement && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Service agreement"
                            value={serviceAgreement}
                        />
                    )}
                    {sapNumber && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="SAP number"
                            value={sapNumber}
                        />
                    )}
                </DataArea>
                {attachments && (
                    <>
                        <Label>Links to attachments from Salesforce</Label>
                        <div className={classes.attachments}>
                            {attachments.map((a) => (
                                <HyperLink
                                    key={a.id}
                                    href={a.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Attachment
                                        className={classes.icon}
                                        fontSize="small"
                                    />{' '}
                                    {a.displayName}
                                </HyperLink>
                            ))}
                        </div>
                    </>
                )}
            </div>
        )
    },
    (prev, next) => prev === next
)

export default SalesforceAdditionalInformation
