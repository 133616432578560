import ArrowBack from '@mui/icons-material/ArrowBack'
import { AppBar, Toolbar } from '@mui/material'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { Fragment, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { RootState } from 'store/types'
import FlexGrow from '../../components/Base/FlexGrow'
import { copyRca } from '../../store/linkRca/index'
import { PreviewLinkRcaParams } from '../../types/urlParams'
import { useIssueAppBarStyle } from './IssueAppBar.style'

const IssueViewBar = () => {
    const classes = useIssueAppBarStyle()
    const dispatch = useDispatch()
    const { id: issueIdFromUrl, previewId } = useParams<PreviewLinkRcaParams>()

    const history = useHistory()
    const {
        issue: { issueId },
        linkRca,
    } = useSelector<RootState, RootState>((state) => state)
    const goBack = useCallback(() => {
        const backLink = previewId
            ? `/issue/${issueIdFromUrl}/link`
            : `/issue/${issueIdFromUrl}`
        history.replace(backLink)
    }, [history, issueIdFromUrl, previewId])
    useEffect(() => {
        if (!issueId) {
            goBack()
        }
    }, [goBack, issueId])
    const handleCopyRca = () =>
        dispatch(copyRca(issueId, Number(previewId), issueIdFromUrl))
    const button = previewId ? (
        <Button
            color="secondary"
            onClick={handleCopyRca}
            disabled={linkRca.copyingRca}
        >
            Copy RCA
        </Button>
    ) : null
    return (
        <Fragment>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton aria-label="Back" className={classes.back} onClick={goBack} size="large">
                        <ArrowBack />
                    </IconButton>
                    <Typography variant="h5">Select issue</Typography>
                    <FlexGrow />
                    {button}
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Fragment>
    );
}

export default IssueViewBar
