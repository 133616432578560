import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const Accordion = withStyles((theme: AbbTheme) => ({
    root: {
        margin: theme.spacing(2, 0, 0, 1),
        width: '100%',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion)

export const AccordionSummary = withStyles((theme: AbbTheme) => ({
    root: {
        marginBottom: -1,
        minHeight: 40,
        padding: theme.spacing(0, 1),
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: theme.spacing(2, 0, 0, 1),
        },
    },
    expanded: {},
}))(MuiAccordionSummary)

export const AccordionDetails = withStyles((theme: AbbTheme) => ({
    root: { margin: theme.spacing(2, 0, 0, 0) },
}))(MuiAccordionDetails)
