import { CountabledChip, FilterGroup, FilterGroupProps } from '../components'
import { DashboardFilter, FilterTooltip } from '../types/models'

export interface DashboardFilterGroupProps extends FilterGroupProps {
    filters?: Array<DashboardFilter>
    onFilterSelect?: (issueType: DashboardFilter) => void
    getCount?: (filter: DashboardFilter) => number
    [key: string]: any
    tooltip?: (code: DashboardFilter) => FilterTooltip | null
}

export const DashboardFilterGroup = (props: DashboardFilterGroupProps) => {
    const { filters, getCount, onFilterSelect, tooltip, ...other } = props

    return (
        <FilterGroup {...other}>
            {filters?.map((filter: DashboardFilter) => {
                const count = getCount ? getCount(filter) : 0
                return (
                    <CountabledChip
                        label={filter.name}
                        key={filter.code}
                        onClick={() =>
                            onFilterSelect &&
                            onFilterSelect({
                                ...filter,
                                selected: !Boolean(filter?.selected),
                            })
                        }
                        selected={filter.selected}
                        count={count}
                        tooltip={tooltip && tooltip(filter)}
                    />
                )
            })}
        </FilterGroup>
    )
}

export default DashboardFilterGroup
