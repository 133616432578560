import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionSelected, getShortOptionLabel } from './utils'

export interface ReportDatePeriodTypeSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedItem: FilterBase<number>
    onSelectedItemChanged?: (item: FilterBase<number>) => void
}

export const ReportDatePeriodTypeSelect = (
    props: ReportDatePeriodTypeSelectProps
) => {
    const options = useSelector(
        (state: RootState) => state.resources.reportDatePeriodType
    )
    const { selectedItem, onSelectedItemChanged, disabled, ...other } = props
    const handleOnChange = (
        e: ChangeEvent<any>,
        value: string | FilterBase<number>
    ) => {
        const results = value as FilterBase<number>
        onSelectedItemChanged &&
            onSelectedItemChanged(
                value ? { ...results, code: Number(results?.code) } : null
            )
    }
    return (
        <Autocomplete
            disabled={disabled}
            value={selectedItem}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getShortOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Date range"
                    {...dataTestId('DATE_RANGE')}
                    {...other}
                />
            )}
        />
    )
}
