import { List } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AttachmentTypes } from 'types/enums'
import { Attachment } from 'types/issueTypes'
import { AttachmentUploadDto } from './FileManager'
import FileUploadItem from './FileUploadItem'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            width: '100%',
            paddingTop: 0,
        },
    }),
    { name: 'FileUploadList' }
)

export interface FileUploadListProps {
    files: Array<AttachmentUploadDto>
    issueId: number
    attachmentType: AttachmentTypes
    onFileUploaded: (attachment: Attachment, id: string) => void
    onFileRemoveFromPending: (id: string) => void
}

export const FileUploadList = (props: FileUploadListProps) => {
    const {
        files,
        issueId,
        attachmentType,
        onFileUploaded,
        onFileRemoveFromPending,
    } = props
    const classes = useStyles()
    return (
        <List className={classes.root}>
            {files.map((file) => (
                <FileUploadItem
                    key={file.id}
                    file={file}
                    issueId={issueId}
                    attachmentType={attachmentType}
                    onFileUploaded={onFileUploaded}
                    onFileRemoveFromPending={onFileRemoveFromPending}
                />
            ))}
        </List>
    )
}

export default FileUploadList
