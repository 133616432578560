import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { IssueStoreState } from 'store/issue'
import { RootState } from 'store/types'
import { ActionsKeys, IssueActionsTypes, IssueValuesKeys } from 'types/enums'
import { Issue, IssueAction } from 'types/issueTypes'
import {
    AccessMatrixSetting,
    DependentOn,
    FieldMatrix,
    MatrixError,
    MatrixField,
    MatrixFieldConfig,
} from 'types/matrixTypes'
import { getIssueGetter } from '.'

export const initMatrixField: MatrixField = {
    required: false,
    disabled: true,
}

export const initMatrixError: MatrixError = {
    error: false,
    helperText: null,
}

export const useError = (key: IssueValuesKeys): { error: boolean } => {
    const errorsList = useSelector<RootState, IssueStoreState>(
        (state) => state.issue?.errorsList
    )
    const error = errorsList && errorsList.some((e: any) => e.field === key)
    return { error }
}

export const useFieldError = (
    key: IssueValuesKeys,
    config: MatrixFieldConfig = {}
): MatrixError => {
    const errorsList = useSelector<RootState, IssueStoreState>(
        (state) => state.issue?.errorsList
    )
    const error =
        errorsList && errorsList.find((e: any) => e.field === key)?.message
    return {
        error: config?.defaultError || Boolean(error),
        helperText: error || config?.defaultHelperText,
    }
}

const calculateRequired = (
    issue: Issue,
    dependentsOn: Array<DependentOn>
): boolean => {
    let required = false
    if (Array.isArray(dependentsOn)) {
        if (!dependentsOn.length) {
            return true
        }
        return dependentsOn.some((d) => isRequired(issue, d))
    }

    return required
}

const isRequired = (issue: Issue, dependentOn: DependentOn): boolean => {
    let required = false
    const values: Array<string> = dependentOn.values
    if (!Array.isArray(values)) {
        return required
    }
    const getter = getIssueGetter(dependentOn.key as IssueValuesKeys)
    const value = getter ? getter(issue) : null
    required = Boolean(
        // eslint-disable-next-line eqeqeq
        values.some((x) => value == x)
    )

    if (dependentOn.and) {
        return required && isRequired(issue, dependentOn.and)
    }

    return required
}

export const useFieldMatrix = (
    key: IssueValuesKeys,
    config: MatrixFieldConfig = {}
): MatrixField => {
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    let accessMatrixItem: AccessMatrixSetting =
        useMemo<AccessMatrixSetting>(() => {
            try {
                return issue.dependedFields[key] ?? null
            } catch {
                return null
            }
        }, [issue, key])
    if (config?.disabled || !accessMatrixItem) {
        return {
            disabled: true,
            required: false,
        }
    }
    if (config?.matrixModifier) {
        const newMatrix = config?.matrixModifier(accessMatrixItem, issue)
        if (newMatrix) {
            accessMatrixItem = { ...accessMatrixItem, ...newMatrix }
        }
    }

    if (accessMatrixItem?.isRequired) {
        return {
            disabled: accessMatrixItem?.isDisabled,
            required: calculateRequired(issue, accessMatrixItem?.dependentOn),
        }
    }
    return {
        disabled: accessMatrixItem?.isDisabled,
        required: false,
    }
}

export const useFieldWithError = (
    key: IssueValuesKeys,
    config: MatrixFieldConfig = {}
): FieldMatrix => {
    const error = useFieldError(key, config)
    const matrix = useFieldMatrix(key, config)
    return { ...matrix, ...error }
}

export const useIssueAction = (
    actionName: ActionsKeys,
    disabled: boolean = false
) => {
    const actionDisabled = useSelector<RootState, boolean>((state) =>
        (state.issue?.currentActions ?? [])?.some((a) => a === actionName)
    )
    return {
        disabled: disabled || !actionDisabled,
    }
}

export const useHasActionInContext = (
    issueActionsTypes: IssueActionsTypes
): boolean => {
    const actions = useSelector<RootState, Array<IssueAction>>(
        (state) => state?.issue?.context?.actions
    )
    if (actions && 0 < actions.length) {
        return actions.some((a) => a.actionCode === issueActionsTypes)
    }

    return false
}
