import { ChipProps, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { MouseEventHandler, useMemo, useState } from 'react'
import { Employee } from 'types/issueTypes'
import {
    arrayToStringLine,
    createArrayWithoutNulls,
    getEmployeeOrganizationInfoName,
} from 'utils'
import Chip from '../Chips/Chip'

export interface EmployeeChipProps extends ChipProps {
    employee: Employee
    customTitle?: string
    customLabel?: string
    open?: boolean
    onClose?: () => void
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        copied: {
            ...theme.typography.caption,
            visibility: 'hidden',
            width: 'max-content',
            fontWeight: 500,
            borderRadius: theme.spacing(0.5),
            opacity: 0,
            position: 'absolute',
            bottom: 45,
            left: '50%',
            transform: 'translateX(-50%)',
            background: 'rgba(38, 38, 38, 0.9)',
            color: '#fff',
            padding: theme.spacing(1),
            marginBottom: 0,
            transition:
                'visibility .2s, opacity .2s cubic-bezier(0.4, 0, 0.2, 1)',
            zIndex: 1,
            cursor: 'pointer',
        },
        visible: {
            visibility: 'visible',
            opacity: 1,
        },
        conector: {
            position: 'absolute',
            height: 70,
            width: 45,
            bottom: 0,
            left: '50%',
            transform: 'translateX(-50%)',
        },
    }),
    { name: 'EmployeeChip' }
)

export const EmployeeChip = (props: EmployeeChipProps) => {
    const classes = useStyles()
    const {
        customTitle,
        customLabel,
        employee,
        onDelete,
        disabled,
        open,
        onClose,
        ...other
    } = props
    const [visible, setVisible] = useState(false)
    const title = useMemo(() => {
        if (customTitle) {
            return customTitle
        }
        const tags = createArrayWithoutNulls(
            employee?.fullName,
            employee?.email,
            employee?.countryCode,
            employee?.organizationInfo
                ? getEmployeeOrganizationInfoName(employee?.organizationInfo)
                : '',
            employee?.isActive ? 'Active' : 'Inactive'
        )
        return arrayToStringLine(tags)
    }, [employee, customTitle])

    const handleOnClick: MouseEventHandler<HTMLDivElement> | null = useMemo(
        () => () => setVisible((prev) => !prev),
        []
    )

    return (
        <div
            style={{ display: 'inline', position: 'relative' }}
            onMouseLeave={() => setVisible(false)}
        >
            <div className={classes.conector} />
            <Tooltip
                title={title}
                aria-label={title}
                open={open && open}
                onClose={onClose}
                onClick={handleOnClick}
            >
                <Chip
                    label={customLabel ?? employee?.email}
                    onDelete={disabled ? null : onDelete}
                    {...other}
                />
            </Tooltip>

            <div
                className={clsx(classes.copied, visible && classes.visible)}
                onClick={() => {
                    navigator.clipboard.writeText(employee?.email)
                    setVisible(false)
                }}
                onMouseOut={() => {
                    setVisible(false)
                }}
            >
                Copy
            </div>
        </div>
    )
}

export default EmployeeChip
