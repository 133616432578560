import { Workbook } from 'exceljs'
import dayjs from 'dayjs'
import {
    ExcelExportConfig,
    ExcelExportOptions,
    addFiltersToExcel,
    saveWorkbookAndDownload,
} from '.'
import { getUtcAppendix } from '..'

export const generateExcelFromTemplate1 = <T = {}>(
    config: ExcelExportConfig<T>,
    items: T[],
    options: ExcelExportOptions = null
) => {
    return new Promise(async (resolve, reject) => {
        const { columns } = config
        const workbook = new Workbook()
        const worksheet = workbook.addWorksheet(
            options?.workSheetName ?? 'Export'
        )

        let tableStartingPosition = 1

        if (options?.filters) {
            addFiltersToExcel(worksheet, options?.filters)
            tableStartingPosition = options.filters.length + 3
        }

        const headerRow = worksheet.getRow(tableStartingPosition)
        headerRow.height = 40
        columns.forEach((column, index) => {
            const excelColumn = worksheet.getColumn(index + 1)
            excelColumn.width = column.width ?? 40

            const cell = headerRow.getCell(index + 1)
            if (column.isDate) {
                cell.value = `${column.name} ${getUtcAppendix(
                    !column.localDate
                )}`
                excelColumn.numFmt = 'yyyy-m-d h:mm:ss AM/PM'
            } else {
                cell.value = column.name
            }

            cell.font = {
                bold: true,
                size: 12,
            }
            cell.alignment = {
                horizontal: 'center',
                vertical: 'middle',
                wrapText: true,
            }
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFd8e9fd' },
            }
        })

        const tableDataStartingPosition = tableStartingPosition + 1
        items.forEach((item, index) => {
            const excelRow = worksheet.getRow(index + tableDataStartingPosition)
            columns.forEach((column, columnIndex) => {
                const cell = excelRow.getCell(columnIndex + 1)
                cell.alignment = { wrapText: true }
                const columnSettting = columns[columnIndex]
                const cellValue = columnSettting?.getValue(item) ?? ''
                if (Boolean(cellValue) && column.isDate) {
                    let date = dayjs(cellValue as string).utc()
                    if (column?.localDate) {
                        date = dayjs(cellValue as string)
                            .utc(true)
                            .local()
                    }
                    if (date.isValid()) {
                        cell.value = new Date(date.toISOString())
                    } else {
                        cell.value = ''
                    }
                } else if (cellValue && column.getHyperLink) {
                    const hyperLink = column.getHyperLink(item)
                    cell.value = {
                        text: cellValue as string,
                        hyperlink: hyperLink,
                    }
                    cell.font = {
                        color: { argb: 'FF004c97' },
                        underline: 'single',
                    }
                } else {
                    cell.value = cellValue
                }
            })
        })

        worksheet.autoFilter = {
            from: {
                row: tableStartingPosition,
                column: 1,
            },
            to: {
                row: tableStartingPosition,
                column: columns.length,
            },
        }

        const dateFormated = dayjs().format('YYYY-MM-DD')
        const excelFileName = `${options.fileName}-${dateFormated}`

        try {
            await saveWorkbookAndDownload(workbook, excelFileName)
            resolve('Export completed successfully')
        } catch (err) {
            reject('Export failed')
        }
    })
}
