import DayjsUtils from '@date-io/dayjs'
import DateRange from '@mui/icons-material/DateRange'
import DatePicker, { DatePickerProps } from '@mui/lab/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'

const DATE_FORMAT = 'YYYY-MM-DD hh:mm'

export interface AbbDateTimePickerProps
    extends DatePickerProps<string | number | object | Date> {
    helperTextIcon?: React.ReactNode
}

export const DateTimePicker = (props: AbbDateTimePickerProps) => {
    const { helperTextIcon, ...other } = props
    return (
        <LocalizationProvider adapterLocale={DayjsUtils}>
            <DatePicker
                {...other}
                id={props.id}
                disableToolbar={true}
                inputVariant="filled"
                format={DATE_FORMAT}
                emptyLabel={DATE_FORMAT}
                variant="inline"
                InputProps={{
                    endAdornment: (
                        <DateRange
                            fontSize="small"
                            style={{ marginRight: 8 }}
                        />
                    ),
                }}
                PopoverProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    },
                }}
            />
        </LocalizationProvider>
    )
}
