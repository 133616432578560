import Button from '@mui/material/Button'
import { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Dialog } from '.'
import { DIALOG } from '../../consts/selectorIds'

export interface DialogBaseProps extends DialogProps {
    title?: string
    message?: string
    closeLabel?: string
    confirmLabel?: string
    onClose: () => void
    onConfirm?: () => void
    disabledConfirm?: boolean
}

export const DialogBase = (props: DialogBaseProps) => {
    const {
        open,
        id,
        title,
        message,
        closeLabel,
        children,
        confirmLabel,
        onClose,
        onConfirm,
        disabledConfirm = false,
        ...other
    } = props
    return (
        <Dialog
            {...DIALOG.CONTAINER}
            open={open}
            {...other}
            PaperProps={{
                square: true,
            }}
        >
            {title && (
                <DialogTitle {...DIALOG.TITLE_TEXT}>
                    {title}
                </DialogTitle>
            )}
            <DialogContent {...DIALOG.CONTENT_CONTAINER}>
                {message && (
                    <DialogContentText {...DIALOG.CONTENT_TEXT}>
                        {message}
                    </DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions {...DIALOG.DIALOG_ACTIONS_CONTAINER}>
                <Button {...DIALOG.DIALOG_CLOSE_BUTTON} onClick={onClose}>
                    {closeLabel}
                </Button>
                {confirmLabel && (
                    <Button
                        {...DIALOG.DIALOG_CONFIRM_BUTTON}
                        color="secondary"
                        onClick={onConfirm}
                        disabled={disabledConfirm}
                    >
                        {confirmLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

DialogBase.defaultProps = {
    open: false,
}

export default DialogBase
