import axios, { AxiosResponse, CancelToken } from 'axios'
import {
    AllInOneReportDataFields,
    AllInOneReportSettings,
    ReportResult,
} from 'types/reportTypes'

const getBaseUrl = (reportId: string) => `/report/${reportId}/settings`

export const allInOneReportId = 'ef15f5a3-b110-4470-bdd7-a872914f5bc7'

export const getReportSettings = (
    reportId: string,
    isUnitInternal: boolean = false,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AllInOneReportSettings[]>> =>
    axios.get(getBaseUrl(reportId), {
        cancelToken,
        params: { isUnitInternal },
    })

export const getReportSettingById = (
    reportId: string,
    settingsId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AllInOneReportSettings>> =>
    axios.get(`${getBaseUrl(reportId)}/${settingsId}`, {
        cancelToken,
    })

export const deleteReportSettings = (
    reportId: string,
    settingId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.delete(`${getBaseUrl(reportId)}/${settingId}`, {
        cancelToken,
    })

export const saveReportSettings = (
    reportId: string,
    data: AllInOneReportSettings,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.put(
        getBaseUrl(reportId),
        { ...data },
        {
            cancelToken,
        }
    )

export const generateReport = (
    fieldData: AllInOneReportDataFields,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ReportResult[]>> =>
    axios.post(
        '/report/all-in-one',
        { ...fieldData },
        {
            cancelToken,
        }
    )

export const downloadReportAnalyzer = (
    isUnitInternal = false,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get('report/analyzer', {
        cancelToken,
        params: { isUnitInternal },
        responseType: 'blob',
    })
