import { LocalOffer } from '@mui/icons-material'
import { HtmlHTMLAttributes } from 'react'
import { IssueTypes } from 'types/enums'
import { DataArea, DateView, ValueView } from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { useIssueTypeName } from '../../hooks'
import { useTilesStyles } from './tilesStyles'

export interface IssueInfoBaseRowProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    deadlineDate: string
    deadlineDateLabel?: string
    issueType: IssueTypes
}

export const IssueInfoBaseRow = (props: IssueInfoBaseRowProps) => {
    const {
        deadlineDate,
        deadlineDateLabel = 'Action deadline',
        issueType,
        ...other
    } = props
    const classes = useTilesStyles(props)
    const issueTypeName = useIssueTypeName(issueType)
    return (
        <DataArea {...other}>
            {deadlineDate && (
                <DateView
                    className={classes.cell}
                    date={deadlineDate}
                    actionName={deadlineDateLabel}
                    {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                        .TARGET_OR_ACTION_DATE}
                />
            )}
            <ValueView
                {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.ISSUE_TYPE}
                className={classes.cell}
                icon={<LocalOffer className={classes.labelIcon} />}
                label="Issue type"
                value={issueTypeName}
                singleLine={true}
            />
        </DataArea>
    )
}

export default IssueInfoBaseRow
