import { makeStyles } from '@mui/styles'
import {
    activeTreeBranch,
    AdminNode,
    BusinessUnitSelect,
    Button,
    changeNodeChunk,
    CircularProgressLoader,
    Content,
    deactiveTreeBranch,
    DivisionSelect,
    FormGridItem,
    GenericNode,
    GenericNodeBase,
    GridContainer,
    insertChildNode,
    insertNodes,
    NoResults,
    PageTitle,
    PageTitleProps,
    ProductGroupSelect,
    SearchPanel,
    SearchResultsHeader,
    TreeRoot,
} from 'components'
import { ReactNode, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from 'store/app'
import {
    BusinessUnitFilterBase,
    DivisionFilterBase,
    ProductGroupFilterBase,
} from 'types/models'
import { AdminSearchPanelActions } from '..'
import { AdminTreeNodes } from './AdminTreeNodes'
import {
    TreeViewManageDialog,
    TreeViewManageDialogVariant,
} from './TreeViewManageDialog'

import { Add } from '@mui/icons-material'
import { AxiosResponse, CancelToken } from 'axios'
import { useProcessOwnerChecker } from 'hooks'
import { RootState } from 'store/types'
import { Employee } from 'types/issueTypes'
import { UserProfile } from 'types/profile'
import { dataTestId, permissionProcessOwner } from 'utils'
import { ProcessOwnerInfo } from '../ProcessOwnerInfo'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        processOwners: {
            margin: theme.spacing(4, 0, 1),
        },
        searchTitle: {
            marginTop: theme.spacing(4),
        },
        tree: {
            marginTop: theme.spacing(1),
        },
        field: {
            marginBottom: 0,
        },
        addRootIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'TreeViewManagePage' }
)

export interface TreeQueryFields {
    business: BusinessUnitFilterBase
    division: DivisionFilterBase
    productGroup: ProductGroupFilterBase
}

const getInitQuery = (): TreeQueryFields => ({
    business: null,
    division: null,
    productGroup: null,
})

export interface TreeViewManagePageProps extends PageTitleProps {
    searchTitle: string
    nodeNameLabel: string
    getNodes: (
        code: string,
        nodeId: number | null,
        cancelToken: CancelToken
    ) => Promise<AxiosResponse<AdminNode[]>>
    createNode: (
        parentNodeId: number,
        name: string,
        code: string,
        cancelToken: CancelToken
    ) => Promise<AxiosResponse<AdminNode>>
    deleteNode: (
        failureModeId: number,
        cancelToken: CancelToken
    ) => Promise<AxiosResponse<unknown>>
    updateNode: (
        failureModeId: number,
        name: string,
        isActive: boolean,
        cancelToken: CancelToken
    ) => Promise<AxiosResponse<AdminNode>>
    canEditAllNodes?: boolean
}

export const TreeViewManagePage = (props: TreeViewManagePageProps) => {
    const {
        title,
        desc,
        to,
        searchTitle,
        nodeNameLabel,
        getNodes,
        createNode,
        updateNode,
        deleteNode,
        canEditAllNodes = false,
    } = props
    const [processOwners, setProcessOwners] = useState<Employee[]>(null)
    const classes = useStyles()
    const profile = useSelector<RootState, UserProfile>(
        (store) => store?.dashboard?.profile
    )
    const [error, setError] = useState<unknown>(null)
    const [searching, setSearching] = useState(false)
    const [editing, setEditing] = useState<TreeViewManageDialogVariant | null>(
        null
    )
    const loadingDesc = useMemo(() => {
        switch (editing) {
            case 'add':
                return 'Adding new node...'
            case 'edit':
                return 'Updating node...'
            case 'deactive':
                return 'Deactving nodes...'
            default:
                return ''
        }
    }, [editing])
    const [checkProcessOwner] = useProcessOwnerChecker()
    const [nodes, setNodes] = useState<AdminNode[]>(null)
    const [open, setOpen] = useState(true)
    const [selectedNode, setSelectedNode] = useState<GenericNodeBase>(null)
    const [dialogVariant, setDialogVariant] =
        useState<TreeViewManageDialogVariant | null>(null)
    const [expandedNodes, setExpandedNodes] = useState<string[]>([])
    const [loadingNodes, setLoadingNodes] = useState<string[]>([])
    const [query, setQuery] = useState<TreeQueryFields>(getInitQuery())
    const isPermissionProcessOwner = permissionProcessOwner(
        profile?.roles,
        query?.productGroup?.code,
        query?.productGroup?.businessUnitCode ?? query?.business?.code,
        query?.productGroup?.divisionCode ?? query?.division?.code
    )
    const canEditTree = useMemo(
        () =>
            checkProcessOwner(
                {
                    division:
                        {
                            ...query?.division,
                            code: query?.productGroup?.divisionCode ?? null,
                        } ?? null,
                    businessUnit:
                        {
                            ...query?.business,
                            code: query?.productGroup?.businessUnitCode ?? null,
                        } ?? null,
                    productGroup: query?.productGroup ?? null,
                    country: null,
                    region: null,
                    abacusCode: null,
                    genericCode: null,
                },
                true
            ),
        [profile, searching, checkProcessOwner]
    )
    const dispatch = useDispatch()
    const resetForm = () => setQuery(getInitQuery())
    const handleSearch = async () => {
        if (!query?.productGroup?.code) {
            return
        }
        try {
            setSearching(true)
            const { data } = await getNodes(query.productGroup.code, null, null)
            setNodes(
                data.map((n: AdminNode) => ({
                    ...n,
                    nodes: [],
                }))
            )
            setExpandedNodes([])
            setLoadingNodes([])
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot load root elements', true))
        } finally {
            setSearching(false)
        }
    }
    const handleChange =
        (fieldName: keyof TreeQueryFields) => (newValue: ReactNode) => {
            setProcessOwners(null)
            setQuery((prev: TreeQueryFields) => ({
                ...prev,
                [fieldName]: newValue,
            }))
        }
    const handleExpandNode = async (node: GenericNode, expanded: boolean) => {
        const nodeLoading = Boolean(loadingNodes.find((n) => n === node.id))
        if (nodeLoading) {
            return
        }
        if (expanded) {
            setExpandedNodes((prev) => prev.filter((n) => n !== node.id))
        } else {
            if (node.hasChildren && node.nodes.length === 0) {
                try {
                    setLoadingNodes((prev) => [...prev, node.id])

                    const { data } = await getNodes(
                        query.productGroup.code,
                        node.id,
                        null
                    )
                    const tree = [...nodes]
                    insertNodes(
                        tree,
                        node.id,
                        data.map((n: GenericNode) => ({
                            ...n,
                            nodes: [],
                        }))
                    )
                    setNodes(tree)
                    setExpandedNodes((prev) => [...prev, node.id])
                    setLoadingNodes((prev) => prev.filter((n) => n !== node.id))
                } catch (error) {
                    setError(error)
                    dispatch(
                        showSnackbar(
                            `Cannot load elements ${node?.name ?? ''}`,
                            true
                        )
                    )
                    setExpandedNodes((prev) =>
                        prev.filter((n) => n !== node.id)
                    )
                    setLoadingNodes((prev) => prev.filter((n) => n !== node.id))
                }
            } else {
                setExpandedNodes((prev) => [...prev, node.id])
            }
        }
    }
    const createNodeHandler =
        (variant: TreeViewManageDialogVariant) => (node: GenericNodeBase) => {
            setDialogVariant(variant)
            setSelectedNode(node)
        }
    const handleAddTreeNode = async (
        parentNode: GenericNodeBase,
        newNode: GenericNodeBase
    ) => {
        try {
            setEditing('add')
            const { data } = await createNode(
                parentNode?.id ?? null,
                newNode.name,
                query.productGroup.code,
                null
            )
            if (data?.parentId) {
                insertChildNode(nodes, data?.parentId, data)
                if (!expandedNodes.some((x) => String(parentNode.id) === x)) {
                    setExpandedNodes((prev) => [...prev, parentNode.id])
                }
            } else {
                setNodes((prev) => [data, ...prev])
            }
        } catch (error) {
            dispatch(showSnackbar('Item with this name already exists!', true))
        } finally {
            setEditing(null)
        }
    }
    const handleDeleteNode = async (node: GenericNodeBase) => {
        try {
            setEditing('deactive')
            await deleteNode(node.id, null)
            deactiveTreeBranch(nodes, node.id)
        } catch (error) {
            dispatch(showSnackbar('Cannot deactive node!', true))
        } finally {
            setEditing(null)
        }
    }
    const handleEditNode = async (node: GenericNodeBase) => {
        try {
            setEditing('edit')
            const { data } = await updateNode(
                node.id,
                node.name,
                node.isActive,
                null
            )
            changeNodeChunk(nodes, node.id, {
                name: data.name,
                isActive: data.isActive,
                hasChildren: data.hasChildren,
                itemPath: data.itemPath,
            })
            if (data.isActive) {
                node?.parentId && activeTreeBranch(nodes, node?.parentId)
            } else {
                deactiveTreeBranch(nodes, node.id)
            }
        } catch (error) {
            dispatch(showSnackbar('Cannot deactive node!', true))
        } finally {
            setEditing(null)
        }
    }
    return (
        <Content
            loading={Boolean(editing)}
            loadingDesc={loadingDesc}
            error={error}
            onRetry={() => setError(null)}
        >
            <PageTitle title={title} desc={desc} to={to} />
            <SearchPanel
                initOpen={true}
                open={open}
                onOpen={(open) => {
                    setOpen(open)
                }}
                placeholder={searchTitle}
                disabledTextSearch={true}
            >
                <GridContainer>
                    <FormGridItem fullWidth>
                        <DivisionSelect
                            className={classes.field}
                            disabled={searching}
                            selectedDivision={query.division}
                            onDivisionChanged={handleChange('division')}
                            {...dataTestId(
                                title === 'Failure modes'
                                    ? 'FAILURE_MODES_DIV'
                                    : `PG_RCA_CATEGORIES_DIV`
                            )}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <BusinessUnitSelect
                            className={classes.field}
                            disabled={searching}
                            division={query.division}
                            selectedBusinessUnit={query.business}
                            onBusinessUnitChanged={handleChange('business')}
                            {...dataTestId(
                                title === 'Failure modes'
                                    ? 'FAILURE_MODES_BA'
                                    : `PG_RCA_CATEGORIES_BA`
                            )}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <ProductGroupSelect
                            className={classes.field}
                            disabled={searching}
                            businessUnit={query.business}
                            division={query.division}
                            selectedProductGroup={query.productGroup}
                            onProductGroupChanged={handleChange('productGroup')}
                            required
                            {...dataTestId(
                                title === 'Failure modes'
                                    ? 'FAILURE_MODES_PG'
                                    : `PG_RCA_CATEGORIES_PG`
                            )}
                        />
                    </FormGridItem>
                    <FormGridItem fullWidth>
                        <ProcessOwnerInfo
                            cache={open}
                            processOwners={processOwners}
                            onProcessOwnersLoad={setProcessOwners}
                            productGroupCode={query?.productGroup?.code}
                            divisionCode={query?.productGroup?.divisionCode}
                            businessUnitCode={
                                query?.productGroup?.businessUnitCode
                            }
                            customMessage={
                                query?.productGroup?.code
                                    ? null
                                    : 'Select Product group to load Process Owners...'
                            }
                        />
                    </FormGridItem>
                </GridContainer>
                <AdminSearchPanelActions
                    spacing={true}
                    isSearching={searching}
                    disabledSearch={!Boolean(query?.productGroup)}
                    resetForm={resetForm}
                    handleSearch={handleSearch}
                />
            </SearchPanel>
            {searching ? (
                <CircularProgressLoader />
            ) : nodes && 0 < nodes.length ? (
                <>
                    <SearchResultsHeader
                        isSearching={searching}
                        renderAction={
                            isPermissionProcessOwner && canEditAllNodes
                                ? () => (
                                      <Button
                                          onClick={() => {
                                              setDialogVariant('add')
                                              setSelectedNode(null)
                                          }}
                                      >
                                          <Add
                                              className={classes.addRootIcon}
                                              fontSize="small"
                                          />
                                          Add root node
                                      </Button>
                                  )
                                : null
                        }
                    />
                    <TreeRoot
                        PaperProps={{ className: classes.tree }}
                        nodes={nodes}
                        expandedNodes={expandedNodes}
                        onExpandNode={handleExpandNode}
                        loadingNodes={loadingNodes}
                        renderNodes={(nodes, level) => (
                            <AdminTreeNodes
                                disabled={!canEditTree}
                                nodes={nodes as AdminNode[]}
                                level={level}
                                canEdit={isPermissionProcessOwner}
                                canEditAllNodes={canEditAllNodes}
                                {...dataTestId(
                                    title === 'Failure modes'
                                        ? 'FAILURE_MODE_PICKER_RESULTS_TREE '
                                        : `PG_RCA_CATEGORIES_RESULTS_TREE`
                                )}
                            />
                        )}
                        onAddNode={createNodeHandler('add')}
                        onDeleteNode={createNodeHandler('deactive')}
                        onEditNode={createNodeHandler('edit')}
                    />
                </>
            ) : (
                nodes !== null && (
                    <NoResults
                        title="No results!"
                        subTitle="Change search criteria and try again."
                    />
                )
            )}
            <TreeViewManageDialog
                open={Boolean(dialogVariant)}
                node={selectedNode}
                variant={dialogVariant}
                nodeNameLabel={nodeNameLabel}
                onClose={() => {
                    setSelectedNode(null)
                    setDialogVariant(null)
                }}
                onAddNode={handleAddTreeNode}
                onEditNode={handleEditNode}
                onDeleteNode={handleDeleteNode}
                canEditAllNodes={canEditAllNodes}
                productGroup={query?.productGroup}
            />
        </Content>
    )
}
