import { Button } from '@mui/material'
import {
    ExportButton,
    SearchPanel,
    SearchResultsWithPagination,
    Space,
} from 'components'
import SortPanel from 'components/Base/SortPanel'
import { appInsights } from 'configs/appInsights'
import { NEW_DASHBOARD } from 'features'
import { DashboardFilterGroup, MyIssueTile2 } from 'Issues'
import { getSortProperties, SortPropertiesKeys } from 'Issues/components/utils'
import { MyIssueTile } from 'Issues/Tiles'
import theme from 'styles/themeBase'
import { IssueStatuses } from 'types/enums'
import { DashboardFilter } from 'types/models'
import { MyAction2, MyIssue, MyIssue2 } from 'types/myDashboard'
import { dataTestId } from 'utils'

const PAGE_SIZE = 30

export interface TabsMyIssuesProps {
    open: boolean
    setOpen: (value: boolean) => void
    wildcard: string
    setWildcard: (value: string) => void
    setDescending: (value: any) => void
    setSortKey: (value: any) => void
    issueTypesFilters?: DashboardFilter[]
    setIssueTypesFilters: (value: DashboardFilter[]) => void
    issueStatusesFilters: DashboardFilter[]
    setIssueStatusesFilters: (value: DashboardFilter[]) => void
    setPage: (value: number) => void
    page: number
    handlePageChange: (page: number) => void
    filtredItems: (MyIssue2 | MyIssue)[]
    exportingIssues: boolean
    handleExportIssuesToExcel: () => void
    setEditDate: (value: boolean) => void
    setIssue: (value: MyIssue2 | MyAction2) => void
    handleIssueUpdate: (newIssue: any) => void
    handleClearFilters: () => void
    setDefaultFilters: () => void
    updating: boolean
    loading: boolean
    sortedFiltredItems: (MyIssue | MyIssue2)[]
    issueStatusesCount: any
    issueTypesCount: any
    issueRolesCount?: any
    issueRolesFilters?: DashboardFilter[]
    setIssueRolesFilters?: (value: DashboardFilter[]) => void
}

const TabsMyIssues = (props: TabsMyIssuesProps) => {
    const {
        exportingIssues,
        filtredItems,
        handleExportIssuesToExcel,
        handleIssueUpdate,
        handlePageChange,
        issueStatusesFilters,
        issueTypesFilters,
        open,
        page,
        setDescending,
        setEditDate,
        setIssue,
        setIssueStatusesFilters,
        setIssueTypesFilters,
        setOpen,
        setPage,
        setSortKey,
        setWildcard,
        wildcard,
        handleClearFilters,
        loading,
        setDefaultFilters,
        sortedFiltredItems,
        updating,
        issueStatusesCount,
        issueTypesCount,
        issueRolesCount,
        issueRolesFilters,
        setIssueRolesFilters,
    } = props
    return (
        <div>
            <SearchPanel
                open={open}
                onOpen={setOpen}
                placeholder="Search in visible issues..."
                searchText={wildcard}
                onChange={setWildcard}
                onClearText={() => setWildcard('')}
                renderEndAdornment={() =>
                    NEW_DASHBOARD ? (
                        <SortPanel
                            sortKeys={getSortProperties()}
                            selectedSortKey={SortPropertiesKeys.taregetDate}
                            selectedDescending={false}
                            onDescendingChange={setDescending}
                            onSortKeyChange={setSortKey}
                        />
                    ) : null
                }
                renderActions={() => (
                    <>
                        <Button
                            onClick={handleClearFilters}
                            {...dataTestId('MY_ISSUES_CLEAR')}
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={setDefaultFilters}
                            {...dataTestId('MY_ISSUES_SET_TO_DEFAULT')}
                        >
                            Set to default
                        </Button>
                    </>
                )}
            >
                <DashboardFilterGroup
                    filters={issueTypesFilters}
                    onFilterSelect={(filter) => {
                        setIssueTypesFilters(
                            issueTypesFilters.map((a) =>
                                a.code === filter.code ? filter : a
                            )
                        )
                        setPage(1)
                    }}
                    title="Issue types"
                    style={{ marginBottom: theme.spacing(2) }}
                    getCount={(f) => issueTypesCount[f.code]}
                />
                <DashboardFilterGroup
                    title="Statuses"
                    filters={issueStatusesFilters}
                    onFilterSelect={(filter) => {
                        setIssueStatusesFilters(
                            issueStatusesFilters.map((a) =>
                                a.code === filter.code ? filter : a
                            )
                        )
                        setPage(1)
                    }}
                    style={{ marginBottom: theme.spacing(2) }}
                    getCount={(f) => issueStatusesCount[f.code]}
                    tooltip={(f) =>
                        f.code === IssueStatuses.Completed && {
                            filter: f.name,
                            text: 'From the last 3 months',
                        }
                    }
                />
                {setIssueRolesFilters && (
                    <DashboardFilterGroup
                        title="Roles"
                        filters={issueRolesFilters}
                        onFilterSelect={(filter) => {
                            setIssueRolesFilters(
                                issueRolesFilters.map((a) =>
                                    a.code === filter.code ? filter : a
                                )
                            )
                            setPage(1)
                        }}
                        getCount={(f) => issueRolesCount[f.code]}
                    />
                )}
            </SearchPanel>
            <SearchResultsWithPagination
                page={page}
                pageSize={PAGE_SIZE}
                onPageChange={handlePageChange}
                isSearching={updating ? false : loading}
                itemCount={filtredItems.length}
                errorVisible={filtredItems.length === 0}
                errorTitle="No actions to display!"
                renderAction={() => (
                    <ExportButton
                        loading={exportingIssues}
                        onClick={handleExportIssuesToExcel}
                    >
                        Export to Excel
                    </ExportButton>
                )}
            >
                {sortedFiltredItems
                    .filter((_, i) => {
                        return (
                            PAGE_SIZE * (page - 1) <= i && i < PAGE_SIZE * page
                        )
                    })
                    .map((x) => {
                        if (NEW_DASHBOARD) {
                            const item = x as MyIssue2
                            return (
                                <MyIssueTile2
                                    key={item.issueId}
                                    onExtendTargetResolutionDate={() => {
                                        setEditDate(true)
                                        setIssue(item)
                                    }}
                                    data={item}
                                    onOpenIssue={() => {
                                        window.open(`/issue/${item.issueId}`)
                                        appInsights.trackEvent({
                                            name: `IssueNumber - ${item.issueNumber} on My Issue`,
                                        })
                                    }}
                                />
                            )
                        } else {
                            const item = x as MyIssue
                            return (
                                <MyIssueTile
                                    key={item.issueId + item.rowVersion}
                                    myIssue={{ ...item }}
                                    onIssueChange={handleIssueUpdate}
                                    showActionStatus={false}
                                />
                            )
                        }
                    })}
                <Space />
            </SearchResultsWithPagination>
        </div>
    )
}

export default TabsMyIssues
