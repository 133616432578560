import { BusinessHoursMap } from 'dayjs'

export const businessTimes: BusinessHoursMap = {
    sunday: null,
    monday: [{ start: '08:00:00', end: '23:59:59' }],
    tuesday: [{ start: '00:00:00', end: '23:59:59' }],
    wednesday: [{ start: '00:00:00', end: '23:59:59' }],
    thursday: [{ start: '00:00:00', end: '23:59:59' }],
    friday: [{ start: '00:00:00', end: '18:00:00' }],
    saturday: null,
}
