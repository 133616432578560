import { ErrorOutline, OpenInNew } from '@mui/icons-material'
import {
    Chip,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
} from '@mui/material'
import clsx from 'clsx'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    deleteDraft,
    SendMessageState,
    updateSendMessageActive,
    updateSendMessageMaximize,
} from 'store/issue'
import { RootState } from 'store/types'
import { IssueRoles, IssueStatuses, IssueTypes } from 'types/enums'
import { Dates, IssueContext } from 'types/issueTypes'
import { dataTestId } from 'utils'
import {
    AccordionActions,
    Button,
    ChipLabel,
    DialogBase,
    HyperLink,
    OverdueChipLabel,
    SelectableChip,
    StatusChipLabel,
} from '../../components'
import { ScrollService } from '../components/ScrollService'
import { ProcessFlowView } from '../ProcessFlow'
import useIssueFormStyles from './useIssueFormStyles'

export interface SummaryProps
    extends React.HtmlHTMLAttributes<HTMLAnchorElement>,
        Partial<{
            disabled: boolean
            disabledActionLogs?: boolean
            number: string
            issueStatus: IssueStatuses
            subject: string
            isOverdue: boolean
            roles: Array<SummaryRole>
            actions: React.ReactNode
            warrnings?: Array<string>
            isHighImpact?: boolean
            solutionWillBeValidatedByCoordinator: boolean
            issueType: IssueTypes
            isWaitingForExecution?: boolean
            dates?: Dates
            isDeleted?: boolean
            sfdcUrl?: string
            sfdcCaseNumber?: string
            context: IssueContext
            selectRoleCode: (roleCode: IssueRoles) => void
            openActionLogs: () => void
            onGenerateSummaryRaport: () => void
            onGenerateOverviewInPowerPoint: () => void
        }> {}

export interface SummaryRole
    extends Partial<{
        roleCode: IssueRoles
        selected: boolean
        name: string
    }> {}

const Summary = (props: SummaryProps) => {
    const dispatch = useDispatch()
    const {
        disabled,
        disabledActionLogs,
        className: classNameProp,
        number,
        issueStatus,
        subject,
        isOverdue,
        roles,
        actions,
        warrnings,
        isHighImpact,
        isWaitingForExecution,
        solutionWillBeValidatedByCoordinator,
        dates,
        issueType,
        isDeleted,
        sfdcCaseNumber,
        sfdcUrl,
        context,
        selectRoleCode,
        openActionLogs,
        onGenerateSummaryRaport,
        onGenerateOverviewInPowerPoint,
        ...other
    } = props
    const theme = useTheme()
    const classes = useIssueFormStyles()

    const isIssueDeleted = Boolean(isDeleted)
    const canDeleteDraft =
        0 <=
        context?.myRoles.findIndex(
            (r) => r === IssueRoles.Issue_Owner || IssueRoles.Issue_Owner_Team
        )
    const [open, setOpen] = useState(false)

    const onDeleteHandler = () => {
        setOpen(false)
        dispatch(deleteDraft())
    }

    const { isActive } = useSelector<RootState, SendMessageState>(
        (state) => state?.issue?.sendMessage
    )

    const handleOpenSendMessage = () => {
        dispatch(updateSendMessageActive(true))
        dispatch(updateSendMessageMaximize(true))
    }
    const canSendEmail = ![IssueStatuses.New, IssueStatuses.Draft].includes(
        issueStatus
    )

    return (
        <>
            <ScrollService />
            <section
                className={clsx(
                    classNameProp,
                    classes.section,
                    classes.card,
                    classes.border
                )}
                {...other}
            >
                <div className={classes.header}>
                    <Typography
                        variant="h4"
                        {...ISSUE_VIEW_PAGE.SUMMARY.ISSUE_NUMBER}
                    >
                        {number}
                    </Typography>
                    <span style={{ flexGrow: 1 }} />

                    {isOverdue || isHighImpact ? (
                        <OverdueChipLabel
                            {...ISSUE_VIEW_PAGE.SUMMARY.OVERDUE_HI_LABEL}
                            isHighImpact={isHighImpact}
                            isOverdue={isOverdue}
                            className={classes.overdue}
                        />
                    ) : null}
                    {isDeleted && (
                        <ChipLabel
                            className={classes.overdue}
                            label="Deleted"
                            variant="red"
                        />
                    )}
                    <StatusChipLabel
                        issueStatusId={issueStatus}
                        {...ISSUE_VIEW_PAGE.SUMMARY.STATUS_LABEL}
                    />
                </div>

                <Typography
                    variant="body1"
                    style={{ wordBreak: 'break-all' }}
                    {...ISSUE_VIEW_PAGE.SUMMARY.SUBJECT}
                >
                    {subject}
                </Typography>
                {sfdcCaseNumber && (
                    <HyperLink
                        href={sfdcUrl ?? '#'}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {`Case reference no.: ${sfdcCaseNumber}`}
                        <OpenInNew
                            style={{
                                width: 18,
                                height: 18,
                                marginLeft: theme.spacing(1),
                            }}
                            fontSize="small"
                        />
                    </HyperLink>
                )}
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: theme.spacing(2),
                    }}
                >
                    {Array.isArray(roles) && 0 < roles.length && (
                        <>
                            <Typography
                                variant="caption"
                                style={{
                                    fontWeight: theme.typography
                                        .fontWeightMedium as number,
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                Your's roles in this issue
                            </Typography>
                            <div
                                style={{
                                    display: 'inline-flex',
                                    flexWrap: 'wrap',
                                }}
                                {...ISSUE_VIEW_PAGE.SUMMARY.YOUR_ROLES}
                            >
                                {roles &&
                                    roles.map((r: any) => (
                                        <SelectableChip
                                            key={r.roleCode}
                                            selected={r.selected}
                                            label={r.name}
                                            onClick={() =>
                                                selectRoleCode(r.roleCode)
                                            }
                                            disabled={disabled || isDeleted}
                                        />
                                    ))}
                            </div>
                        </>
                    )}
                    {actions}
                </div>

                {Array.isArray(warrnings) && 0 < warrnings.length && (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: theme.spacing(2),
                        }}
                    >
                        <Typography
                            variant="caption"
                            style={{
                                fontWeight: theme.typography
                                    .fontWeightMedium as number,
                                marginBottom: theme.spacing(1),
                            }}
                        >
                            Issue warnings
                        </Typography>
                        <List
                            {...ISSUE_VIEW_PAGE.SUMMARY.ISSUE_WARNINGS.LIST}
                            dense={true}
                        >
                            {warrnings.map((w) => (
                                <ListItem key={w} style={{ paddingLeft: 0 }}>
                                    <ListItemIcon style={{ minWidth: 32 }}>
                                        <ErrorOutline
                                            fontSize="small"
                                            color="secondary"
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        {...ISSUE_VIEW_PAGE.SUMMARY
                                            .ISSUE_WARNINGS.ITEM}
                                        primary={w}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                )}
                <Typography
                    variant="caption"
                    style={{
                        fontWeight: theme.typography.fontWeightMedium as number,
                        marginBottom: theme.spacing(1),
                    }}
                >
                    Issue resolution progress
                </Typography>

                <ProcessFlowView
                    issueStatus={issueStatus}
                    highImpact={isHighImpact}
                    issueType={issueType}
                    solutionWillBeValidatedByCoordinator={
                        solutionWillBeValidatedByCoordinator
                    }
                    isWaitingForExecution={isWaitingForExecution}
                    dates={dates}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        margin: theme.spacing(2, 0),
                    }}
                >
                    <Typography
                        variant="caption"
                        style={{
                            fontWeight: theme.typography
                                .fontWeightMedium as number,
                            marginBottom: theme.spacing(1),
                        }}
                    >
                        Generate issue files
                    </Typography>
                    <div>
                        <Chip
                            style={{ marginRight: theme.spacing(1) }}
                            label="CCRP Summary Report"
                            onClick={onGenerateSummaryRaport}
                            variant="outlined"
                            {...dataTestId('CCRP_SUMMARY_REPORT')}
                        />
                        <Chip
                            label="Overview in PowerPoint"
                            onClick={onGenerateOverviewInPowerPoint}
                            variant="outlined"
                            {...dataTestId('OVERVIEW_IN_POWERPOINT')}
                        />
                    </div>
                </div>

                <AccordionActions>
                    {issueStatus === 2 && canDeleteDraft && !isIssueDeleted && (
                        <>
                            <Button
                                onClick={() => {
                                    setOpen(true)
                                }}
                                eventName="Delete draft"
                            >
                                Delete draft
                            </Button>
                            <DialogBase
                                open={open}
                                title={number}
                                message="You are about to delete this draft. Please confirm."
                                closeLabel="Cancel"
                                confirmLabel="Delete"
                                disabledConfirm={!open}
                                onClose={() => {
                                    setOpen(false)
                                }}
                                onConfirm={onDeleteHandler}
                            ></DialogBase>
                        </>
                    )}
                    {!disabledActionLogs && (
                        <>
                            <Button
                                onClick={openActionLogs}
                                eventName="Open actions logs"
                                {...ISSUE_VIEW_PAGE.SUMMARY.ACTION_LOGS}
                            >
                                Action logs
                            </Button>
                            <>
                                {canSendEmail && (
                                    <Button
                                        color="secondary"
                                        disabled={isActive}
                                        onClick={handleOpenSendMessage}
                                        {...dataTestId('SEND_MESSAGE')}
                                    >
                                        Send message
                                    </Button>
                                )}
                            </>
                        </>
                    )}
                </AccordionActions>
            </section>
        </>
    )
}

export default memo(Summary)
