import axios from 'axios'
import { call, cancelled, put, select, takeLatest } from 'redux-saga/effects'
import { verifyContainmentAction } from 'api'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import {
    removeMyActionById,
    verifyContainmentDone,
    verifyContainmentFail,
} from '../actions'
import { MY_ACTIONS_VERIFY_CONTAINMENT_CALL } from '../actionTypes'
import { VerifyContainmentActionCommand } from '../types'
import { RootState } from '../../types'
import { MyAction } from '../../../types/myDashboard'
import { IssueChangeLog } from '../../issue'
import { getResolutionSectionKey } from '../../../Issue/matrix'

export function* verifyContainmentActionSaga() {
    yield takeLatest(
        MY_ACTIONS_VERIFY_CONTAINMENT_CALL,
        function* (action: VerifyContainmentActionCommand) {
            const cancelSource = axios.CancelToken.source()
            const issueAction: MyAction = yield select((s: RootState) =>
                s.myActions.items.find(
                    (a) => a.issueId === action.payload.issueId
                )
            )
            const oldValue = issueAction.whatContainmentActionsWereTaken
            const newValue = action.payload.whatContainmentActionsWereTaken
            const changeLog: IssueChangeLog =
                oldValue !== newValue
                    ? {
                          [getResolutionSectionKey(
                              'whatContainmentActionsWereTaken'
                          )]: [oldValue, newValue],
                      }
                    : null
            try {
                yield call(
                    verifyContainmentAction,
                    action.payload,
                    changeLog,
                    cancelSource.token
                )
                yield put(verifyContainmentDone())
                yield put(showSnackbar('Containment actions was confirmed'))
                yield put(
                    removeMyActionById(
                        action.payload.issueId,
                        IssueActionsTypes.To_Execute_Containment_Actions
                    )
                )
            } catch (error) {
                yield put(verifyContainmentFail(error))
                yield put(showSnackbar('Verify containment actions fail', true))
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
