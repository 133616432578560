import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import AbbLogo, { AbbLogoProps } from './AbbLogo'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        appName: {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightBold as number as number,
            marginLeft: theme.spacing(2),
        },
    }),
    { name: 'AppLogo' }
)

export interface AppLogoProps extends AbbLogoProps {
    title?: string
}

export const AppLogo = (props: AppLogoProps) => {
    const { title, ...other } = props
    const classes = useStyles(props)
    return (
        <span className={classes.root}>
            <AbbLogo {...other} />
            <span className={classes.appName}>{title ?? 'CCRP'}</span>
        </span>
    )
}

export default AppLogo
