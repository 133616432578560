import { SupervisorAccount } from '@mui/icons-material'
import Autocomplete from '@mui/material/Autocomplete'
import { BaseTextFieldProps } from '@mui/material/TextField'
import { searchEmployees as searchEmployeesApi } from 'api/employees'
import { EMPLOYEE_SELECT } from 'consts/selectorIds'
import isEmpty from 'lodash/isEmpty'
import { ChangeEvent, useState } from 'react'
import { useDebounce } from 'react-use'
import { Employee } from 'types/issueTypes'
import { customize } from 'utils'
import { EmployeeChip } from '../Chips'
import { AutoCompleteInput } from './components'
import EmployeeItem from './components/EmployeeItem'
import { useFieldState } from './utils'

export interface MultiEmployeeSelectProps extends Partial<BaseTextFieldProps> {
    employees?: Array<Employee>
    onEmployeesChanged?: (employees: Array<Employee>) => void
}

export const MultiEmployeeSelect = (props: MultiEmployeeSelectProps) => {
    const {
        disabled,
        employees = [],
        onEmployeesChanged,
        error: errorProps,
        helperText: helperTextProps,
        ...other
    } = props

    const [{ loading, options, error, helperText }, setState] =
        useFieldState<Employee>()
    const [searchText, setSearchText] = useState('')
    useDebounce(
        async () => {
            try {
                if (loading || !searchText || searchText.length < 4) {
                    return
                }
                setState({ loading: true, error: null, helperText: null })
                const { data: employeesOptions } = await searchEmployeesApi(
                    searchText
                )

                if (employeesOptions?.length === 1) {
                    setSearchText('')
                    onEmployeesChanged &&
                        onEmployeesChanged([...employees, ...employeesOptions])
                    setState({ loading: false, options: [] })
                } else {
                    setState({ loading: false, options: [...employeesOptions] })
                }
            } catch (error) {
                setState({
                    loading: false,
                    error,
                    helperText: 'Cannot load employees list',
                })
            }
        },
        1000,
        [searchText]
    )
    const getOptionLabel = (option: Employee): string => {
        return option?.email ?? ''
    }
    const getOptionSelected = (option: Employee, value: Employee): boolean => {
        return option?.geid === value?.geid
    }
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | Employee>
    ) => {
        setSearchText('')
        onEmployeesChanged && onEmployeesChanged([...values] as Array<Employee>)
    }
    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={employees ?? []}
            options={[...options]}
            inputValue={searchText}
            onInputChange={(e, value, reason) => {
                if (reason === 'input') {
                    setSearchText(value)
                } else if (reason === 'clear') {
                    setSearchText('')
                }
            }}
            onBlur={() => setSearchText('')}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            getOptionDisabled={(option) => !Boolean(option?.isActive)}
            noOptionsText={'No loaded employees. Enter at least 3 characters…'}
            loading={loading && options.length === 0}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) => {
                          const label = state.getOptionLabel(f)
                          return label
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      })
            }}
            loadingText={'Loading employees…'}
            disableClearable={Boolean(loading || disabled)}
            renderOption={(props, option) => {
                return (
                    <li {...props}>
                        <EmployeeItem details={option} />
                    </li>
                )
            }}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <EmployeeChip
                            employee={option}
                            {...getTagProps({ index })}
                            {...customize(
                                EMPLOYEE_SELECT.CHIP.CONTAINER,
                                option.email
                            )}
                        />
                    )
                })
            }
            renderInput={(params) => {
                return (
                    <AutoCompleteInput
                        error={Boolean(error) || errorProps}
                        helperText={helperText || helperTextProps}
                        renderIcon={(props) => <SupervisorAccount {...props} />}
                        params={params}
                        loading={loading}
                        {...other}
                    />
                )
            }}
        />
    )
}

export default MultiEmployeeSelect
