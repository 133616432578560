import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { useContentConfig } from 'hooks/content'
import { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AbbTheme } from 'styles/createAbbTheme'
import ContentError from './components/ContentError'
import ContentLoading from './components/ContentLoading'
import { ContentProps, ContentState, ContentVariantEnum } from './types'
import { getContentInitState, getDefaultMessage } from './utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            boxSizing: 'border-box',
            width: '100%',
            bottom: '0',
            position: 'relative',
            margin: '0 auto',
            height: '100%',
            paddingTop: theme.spacing(4),
            paddingBottom: 56,
            '&$default': {
                maxWidth: ContentVariantEnum.default,
            },
            '&$medium': {
                maxWidth: ContentVariantEnum.medium,
            },
            '&$large': {
                maxWidth: ContentVariantEnum.large,
            },
        },
        default: {},
        medium: {},
        large: {},
        cover: {},
        headline: {
            margin: 0,
            paddingRight: theme.spacing(1),
            paddingLeft: theme.spacing(1),
        },
        newVersion: {
            margin: '0 auto',
            marginTop: theme.spacing(3),
        },
        image: {
            margin: '0 auto',
            textAlign: 'center',
        },
    }),
    { name: 'Content' }
)

export const Content: FC<ContentProps> = (props) => {
    const [state, setState] = useState<ContentState>(getContentInitState())
    const classes = useStyles(props)
    const {
        className: classNameProp,
        children,
        loading = false,
        loadingDesc = '',
        overrideTitle,
        overrideSubtitle,
        label = 'Retry',
        title,
        error = null,
        coverBackground = false,
        variant = 'medium',
        onRetry,
        onNotFound,
        ...other
    } = props

    const config = useContentConfig(error)

    useEffect(() => {
        if (error) {
            const status = error.status ? error.status : null
            setState((prev) => {
                const newState = { ...prev }
                newState.referenceId = error.referenceId
                    ? error.referenceId
                    : null
                newState.notFound = false

                // useContentConfig
                newState.showError = config.showError
                newState.image = config.image
                newState.title = config.title
                newState.subTitle = config.subTitle

                newState.title = getDefaultMessage(
                    newState.title,
                    status,
                    overrideTitle
                )
                newState.subTitle = getDefaultMessage(
                    newState.subTitle,
                    status,
                    overrideSubtitle
                )
                return newState
            })
        } else {
            setState((prev) => ({ ...prev, showError: false }))
        }
    }, [error, overrideTitle, overrideSubtitle, config])

    const handleRetry = () => {
        setState((prev) => ({ ...prev, showError: false }))
        onRetry && onRetry()
    }

    return (
        <div
            className={clsx(classes.root, classes[variant], classNameProp)}
            {...other}
        >
            {title && (
                <Helmet>
                    <title>{title}</title>
                </Helmet>
            )}

            {loading ? (
                <ContentLoading loading={loading} loadingDesc={loadingDesc} />
            ) : (
                state.showError && (
                    <ContentError
                        label={label}
                        state={state}
                        handleRetry={handleRetry}
                        onNotFound={onNotFound}
                        coverBackground={coverBackground}
                    />
                )
            )}

            {children}
        </div>
    )
}

export default Content
