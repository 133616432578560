import axios, { AxiosResponse, CancelToken } from 'axios'
import { GenericNode } from '../components/TreeView/types'

const controllerName = '/pg-category'

export const getPgByResolvingUnitAndId = (
    resolvingUnitId: number,
    id: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<GenericNode>> =>
    axios.get(`${controllerName}/${resolvingUnitId}/${id}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getPgRootsByResolvingUnit = (
    resolvingUnitId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/roots/${resolvingUnitId}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} parentId - product CID
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getPgChildren = (
    resolvingUnitId: number,
    parentId: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/children/${resolvingUnitId}/${parentId}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} name - failure mode name
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const searchPgByName = (
    resolvingUnitId: number,
    name: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.post(
        `${controllerName}/search`,
        { resolvingUnitId, name },
        { cancelToken }
    )
