import {
    FlagOutlined,
    LocalActivity,
    PersonOutlined,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import {
    AdminCardHeader,
    AdminEmployeeChip,
    AdminEmployeesChipsSimple,
    ProcessOwnerInfo,
    ResolvingUnitsManagePageCardProps,
    RUProcessOwnersResults,
} from 'Admin'
import { deleteEmailFromAdminResolvingUnit } from 'api/admin'
import {
    Button,
    CardViewResults,
    CardViewResultsContainer,
    ChipLabel,
    ExpandableCardView,
    InfoBar,
} from 'components'
import { usePermissions } from 'hooks'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AdminEmployeeTypeEnum, AdminResolvingUnit } from 'types/adminTypes'
import { AdminResolvingUnitDeleteQuery } from 'types/queries'
import { arrayToStringLine, EMPTY_VALUE_PLACEHOLDER } from 'utils'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        header: {
            paddingBottom: 0,
        },
    }),
    {
        name: 'ResolvingUnitsManagePageCard',
    }
)

export const ResolvingUnitsManagePageCard = (
    props: ResolvingUnitsManagePageCardProps
) => {
    const dispatch = useDispatch()
    const {
        profile,
        adminResolvingUnit,
        togglePerson,
        personsIds,
        addPerson,
        updateResolvingUnitsLocal,
    } = props
    const {
        id,
        balId,
        isActive,
        processOwners,
        coordinators,
        divisionCode,
        businessUnitCode,
        productGroupCode,
        isInTransitionPeriod,
        address,
        isGlobal,
    } = adminResolvingUnit
    const permissions = usePermissions(
        {
            division: { code: divisionCode, name: '' },
            businessUnit: { code: businessUnitCode, name: '' },
            productGroup: { code: productGroupCode, name: '' },
            country: address?.countryCode ?? null,
            region: address?.regionCode ?? null,
            abacusCode: null,
            genericCode: null,
        },
        isGlobal
    )
    const [expand, setExpand] = useState(false)
    const canEdit = useMemo(() => {
        const { balIds, isSupportDesk, isProcessOwner } = permissions
        return isSupportDesk || balIds.includes(balId) || isProcessOwner
    }, [permissions, balId])

    const classes = useStyles()

    const headerTitle = arrayToStringLine([
        divisionCode,
        businessUnitCode,
        productGroupCode,
    ])

    const checked = personsIds.includes(adminResolvingUnit.id)

    const results = useMemo<CardViewResults>(() => {
        const { address, locationActivity } = adminResolvingUnit
        return !isGlobal
            ? [
                  {
                      label: 'Address',
                      renderIcon: (props) => <FlagOutlined {...props} />,
                      text: address?.full ?? EMPTY_VALUE_PLACEHOLDER,
                  },
                  {
                      label: 'Activity',
                      renderIcon: (props) => <LocalActivity {...props} />,
                      text: `${
                          locationActivity?.name
                              ? locationActivity.name
                              : EMPTY_VALUE_PLACEHOLDER
                      }`,
                  },
              ]
            : []
    }, [adminResolvingUnit])

    const deletePersonFromResolvingUnit = async (
        query: AdminResolvingUnitDeleteQuery
    ) => {
        const { personEmail, ruPersonType } = query
        try {
            await deleteEmailFromAdminResolvingUnit(query)
            const updatedRU: AdminResolvingUnit = {
                ...adminResolvingUnit,
            }
            if (ruPersonType === AdminEmployeeTypeEnum.Coordinator) {
                const index = adminResolvingUnit.coordinators.findIndex(
                    (item) => item.email === personEmail
                )
                0 <= index && updatedRU.coordinators.splice(index, 1)
            } else if (ruPersonType === AdminEmployeeTypeEnum.RUProcessOwner) {
                const index = adminResolvingUnit.processOwners.findIndex(
                    (item) => item.email === personEmail
                )
                0 <= index && updatedRU.processOwners.splice(index, 1)
            }
            dispatch(showSnackbar('Successfully deleted'))
            updateResolvingUnitsLocal([updatedRU])
        } catch (err) {
            dispatch(showSnackbar('Deletion failed', true))
        }
    }

    const renderContent = () => {
        return (
            <div>
                {isInTransitionPeriod && (
                    <InfoBar status="info">
                        Resolving unit is in transition period
                    </InfoBar>
                )}
                {!isActive && (
                    <InfoBar status="error">
                        Resolving unit isn't active
                    </InfoBar>
                )}
                <CardViewResultsContainer results={results} />

                <RUProcessOwnersResults
                    disabled={!canEdit}
                    profile={profile}
                    id={adminResolvingUnit.id}
                    employees={processOwners}
                    deletePersonFromResolvingUnit={
                        deletePersonFromResolvingUnit
                    }
                />

                <AdminEmployeesChipsSimple
                    label="Coordinators"
                    renderIcon={(props) => <PersonOutlined {...props} />}
                >
                    {coordinators
                        ? coordinators.map((employee) => (
                              <AdminEmployeeChip
                                  disabled={!canEdit}
                                  key={employee.email}
                                  employee={employee}
                                  onDelete={() =>
                                      deletePersonFromResolvingUnit({
                                          personEmail: employee.email,
                                          resolvingUnitId: id,
                                          ruPersonType:
                                              AdminEmployeeTypeEnum.Coordinator,
                                      })
                                  }
                              />
                          ))
                        : EMPTY_VALUE_PLACEHOLDER}
                </AdminEmployeesChipsSimple>
                {productGroupCode && (
                    <ProcessOwnerInfo
                        cache={expand}
                        divisionCode={divisionCode}
                        businessUnitCode={businessUnitCode}
                        productGroupCode={productGroupCode}
                        countryCode={address?.countryCode}
                        regionCode={address?.regionCode}
                    />
                )}
            </div>
        )
    }

    return (
        <ExpandableCardView
            expand={expand}
            onExpand={setExpand}
            renderHeader={() => (
                <AdminCardHeader
                    disabled={!canEdit}
                    className={classes.header}
                    renderTags={() => (
                        <>
                            <ChipLabel label={balId} variant="grey" />
                            {!isActive && (
                                <ChipLabel label="Inactive RU" variant="red" />
                            )}
                        </>
                    )}
                    checked={checked}
                    onChangeChecked={() => togglePerson(adminResolvingUnit.id)}
                >
                    {headerTitle}
                </AdminCardHeader>
            )}
            renderContent={renderContent}
            collapsedSize={100}
        >
            {canEdit && isActive && (
                <Button
                    color="secondary"
                    onClick={() => {
                        addPerson(adminResolvingUnit.id)
                    }}
                >
                    Add PERSON
                </Button>
            )}
        </ExpandableCardView>
    )
}
