import axios, { AxiosResponse, CancelToken } from 'axios'
import { AdminNode } from 'components'
import {
    AdminClassification,
    AdminHubInfo,
    AdminHubStructureItem,
    AdminProcessOwner,
    AdminResolvingUnit,
    ClassificationItem,
} from 'types/adminTypes'
import { Customer, CustomerContact, Employee } from 'types/issueTypes'
import {
    AdminHubsSearchQuery,
    AdminProcessOwnerAddQuery,
    AdminProcessOwnerGetQuery,
    AdminProcessOwnerSearchQuery,
    AdminResolvingUnitAddQuery,
    AdminResolvingUnitDeleteQuery,
    AdminResolvingUnitSearchQuery,
} from 'types/queries'
import { SearchResultsBase } from '../types/models'

const controllerName = '/admin'

export const searchAdminResolvingUnits = (
    query: Partial<AdminResolvingUnitSearchQuery>,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<{ items: AdminResolvingUnit[]; total: number }>> =>
    axios.post(
        `${controllerName}/resolving-unit/search`,
        {
            ...query,
        },
        {
            cancelToken,
        }
    )

export const addAdminResolvingUnits = (
    query: AdminResolvingUnitAddQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<null>> =>
    axios.post(
        `${controllerName}/resolving-unit/add`,
        {
            ...query,
        },
        {
            cancelToken,
        }
    )

export const deleteEmailFromAdminResolvingUnit = (
    query: AdminResolvingUnitDeleteQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<null>> =>
    axios.delete(`${controllerName}/resolving-unit/delete`, {
        cancelToken,
        data: {
            ...query,
        },
    })

export const searchAdminProcessOwners = (
    query: AdminProcessOwnerSearchQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminProcessOwner[]>> =>
    axios.post(
        `${controllerName}/process-owner/search`,
        {
            ...query,
        },
        {
            cancelToken,
        }
    )

export const getAdminProcessOwners = (
    params: AdminProcessOwnerGetQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee[]>> =>
    axios.get(`${controllerName}/process-owner`, {
        cancelToken,
        params: { ...params },
    })

export const addAdminProcessOwner = (
    query: Partial<AdminProcessOwnerAddQuery>,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.post(
        `${controllerName}/process-owner`,
        {
            ...query,
        },
        {
            cancelToken,
        }
    )

export const deleteAdminProcessOwner = (
    processOwnerId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<null>> =>
    axios.delete(`${controllerName}/process-owner/${processOwnerId}`, {
        cancelToken,
    })

export const getAdminFailureModes = (
    productGroupCode: string,
    failureModeId: number | null = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode[]>> =>
    axios.get(
        `${controllerName}/failure-mode/${
            failureModeId !== null ? failureModeId : ''
        }`,
        {
            params: { productGroupCode },
            cancelToken,
        }
    )

export const createAdminFailureModes = (
    parentId: number,
    name: string,
    productGroupCode: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.post(
        `${controllerName}/failure-mode`,
        { parentId, name, productGroupCode },
        { cancelToken }
    )

export const deleteAdminFailureModes = (
    failureModeId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.delete(`${controllerName}/failure-mode/${failureModeId}`, {
        cancelToken,
    })

export const updateAdminFailureModes = (
    failureModeId: number,
    name: string,
    isActive: boolean = true,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.put(
        `${controllerName}/failure-mode/${failureModeId}`,
        { name, isActive },
        {
            cancelToken,
        }
    )

export const getAdminPgRcaCategories = (
    productGroupCode: string,
    nodeId: number | null = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode[]>> =>
    axios.get(`${controllerName}/pg-rca/${nodeId !== null ? nodeId : ''}`, {
        params: { productGroupCode },
        cancelToken,
    })

export const createAdminPgRcaCategories = (
    parentId: number,
    name: string,
    productGroupCode: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.post(
        `${controllerName}/pg-rca`,
        { parentId, name, productGroupCode },
        { cancelToken }
    )

export const deleteAdminPgRcaCategories = (
    failureModeId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.delete(`${controllerName}/pg-rca/${failureModeId}`, {
        cancelToken,
    })

export const updateAdminPgRcaCategories = (
    pgRcaId: number,
    name: string,
    isActive: boolean = true,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.put(
        `${controllerName}/pg-rca/${pgRcaId}`,
        { name, isActive },
        {
            cancelToken,
        }
    )

export const searchHubs = (
    query: AdminHubsSearchQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<SearchResultsBase<AdminHubInfo>>> =>
    axios.post(
        `${controllerName}/hub/search`,
        { ...query },
        {
            cancelToken,
        }
    )

export interface AdminCustomerSearchQuery {
    cityCode: string
    contactSearchPhrase: string
    countryCode: string
    guid: string
    name: string
    state: string
    street: string
    zipCode: string
}

export interface AdminCustomer extends Customer {
    contacts: CustomerContact[]
}

export const searchAdminCustomers = (
    query: Partial<AdminCustomerSearchQuery>,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminCustomer[]>> =>
    axios.post(`${controllerName}/customer/search`, {
        ...query,
    })

export const getHubsStructure = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminHubStructureItem[]>> =>
    axios.get(
        `${controllerName}/hub/structure`,

        {
            cancelToken,
        }
    )

export const changeHubsUsage = (
    code: string,
    isPgHub: boolean,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminHubStructureItem[]>> =>
    axios.put(
        `${controllerName}/hub/change-usage`,
        { code, isPgHub },
        {
            cancelToken,
        }
    )

export const addHubProcessOwners = (
    hubIds: number[],
    hubProcessOwnerGeids: string[],
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(
        `${controllerName}/hub/process-owner`,
        { hubIds, hubProcessOwnerGeids },
        {
            cancelToken,
        }
    )

export const deleteHubProcessOwner = (
    hubId: number,
    processOwnerGeid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.delete(`${controllerName}/hub/process-owner`, {
        cancelToken,
        data: { hubId, processOwnerGeid },
    })

export const getAdminPgRcaCategory = (
    pgCode: string,

    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode[]>> =>
    axios.get(`${controllerName}/pg-rca/${pgCode}`, {
        cancelToken,
    })

export const createAdminPgRcaCategory = (
    parentId: number,
    name: string,
    productGroupCode: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.post(
        `${controllerName}/pg-rca`,
        { parentId, name, productGroupCode },
        { cancelToken }
    )

export const deleteAdminPgRcaCategory = (
    failureModeId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.delete(`${controllerName}/pg-rca/${failureModeId}`, {
        cancelToken,
    })

export const updateAdminPgRcaCategory = (
    failureModeId: number,
    name: string,
    isActive: boolean = true,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminNode>> =>
    axios.put(
        `${controllerName}/pg-rca/${failureModeId}`,
        { name, isActive },
        {
            cancelToken,
        }
    )

export const getSupportGroup = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee[]>> =>
    axios.get(`${controllerName}/support-desk`, { cancelToken })

export const addUserToSupportGroup = (
    userGeid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee[]>> =>
    axios.post(`${controllerName}/support-desk`, null, {
        cancelToken,
        params: { userGeid },
    })

export const deactivateUserFromSupportGroup = (
    userGeid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee[]>> =>
    axios.put(`${controllerName}/support-desk`, null, {
        cancelToken,
        params: { userGeid },
    })

export const getAdminClassifications = (
    businessAreaCode: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<AdminClassification>> =>
    axios.post(
        `${controllerName}/classification/search`,
        { code: businessAreaCode },
        {
            cancelToken,
        }
    )

export const addAdminClassifications = (
    businessAreaCode: string,
    categoryName: string,
    isActive: boolean,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ClassificationItem>> =>
    axios.put(
        `${controllerName}/classification/add`,
        {
            categoryName,
            isActive,
            code: businessAreaCode,
        },
        {
            cancelToken,
        }
    )
export const deleteAdminClassifications = (
    id: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ClassificationItem>> =>
    axios.delete(`${controllerName}/classification/delete`, {
        cancelToken,
        data: { id },
    })

export const editAdminClassifications = (
    categoryName: string,
    isActive: boolean,
    id: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ClassificationItem>> =>
    axios.post(
        `${controllerName}/classification/edit`,
        { categoryName, isActive, id },
        { cancelToken }
    )

export const getAdminClassificationsProcessOwners = (
    divisionCode: string,
    businessUnitCode: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee[]>> =>
    axios.post(
        `${controllerName}/classification/process-owners`,
        {
            divisionCode,
            businessUnitCode,
        },
        {
            cancelToken,
        }
    )
