import { Button } from '@mui/material'
import { HighImpactActionDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { confirmHI, downgradeHI } from 'store/myActions'
import { TileActionsProps } from '..'

export const ToVerifyHighImpactActions = (props: TileActionsProps) => {
    const dispatch = useDispatch()
    const [openDowngrade, setOpenDowngrade] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const { myActionMeta, issueNumber } = props
    const handleConfirmHighImpact = (motivation: string) => {
        setOpenConfirm(false)
        dispatch(
            confirmHI({
                rowVersion: myActionMeta.rowVersion,
                issueId: myActionMeta.issueId,
                motivation: motivation,
            })
        )
    }
    const handleDowngradeHighImpact = (motivation: string) => {
        setOpenDowngrade(false)
        dispatch(
            downgradeHI({
                rowVersion: myActionMeta.rowVersion,
                issueId: myActionMeta.issueId,
                motivation: motivation,
            })
        )
    }
    return (
        <>
            <Button
                onClick={() => setOpenDowngrade(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.DOWNGRADE}
                eventName="Downgrade HI issue on dashboard"
            >
                Downgrade
            </Button>
            <Button
                color="secondary"
                onClick={() => setOpenConfirm(true)}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CONFIRM_HIGH_IMPACT}
                eventName="Confirm HI issue on dashboard"
            >
                Confirm high impact
            </Button>
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to validate High Impact proposal for issue ${issueNumber}. Proceed?`}
                confirmLabel="Confirm high impact"
                closeLabel="Cancel"
                onConfirm={handleConfirmHighImpact}
                onClose={() => setOpenConfirm(false)}
                open={openConfirm}
            />
            <HighImpactActionDialog
                title="Confirm action"
                message={`You are about to downgrade the CCRP issue ${issueNumber} to normal impact. Do you really wish to proceed?`}
                confirmLabel="Downgrade"
                closeLabel="Cancel"
                onConfirm={handleDowngradeHighImpact}
                onClose={() => setOpenDowngrade(false)}
                open={openDowngrade}
            />
        </>
    )
}
