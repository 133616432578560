import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import InfoIcon from '@mui/icons-material/Info'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
        content: {
            paddingLeft: theme.spacing(1),
        },
        infoIcon: {
            color: theme.palette.info.dark,
        },
        icon: {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
            marginRight: theme.spacing(1),
        },
    }),
    {
        name: 'InfoBar',
    }
)

export interface InfoBarProps extends HtmlHTMLAttributes<HTMLDivElement> {
    status: 'error' | 'info'
}

export const InfoBar = (props: InfoBarProps) => {
    const { status, children, className, ...other } = props
    const classes = useStyles()

    return (
        <div className={clsx(classes.root, className)} {...other}>
            {status === 'info' && (
                <InfoIcon className={clsx(classes.icon, classes.infoIcon)} />
            )}
            {status === 'error' && (
                <ErrorOutlineOutlinedIcon
                    className={classes.icon}
                    color="error"
                />
            )}
            <Typography className={classes.content}>{children}</Typography>
        </div>
    )
}
