import { ChipLabelVariants } from 'components'
import { IssueStatuses } from 'types/enums'
import { MyAction2, MyIssue2 } from 'types/myDashboard'
import dayjs from 'dayjs'
import { RemainingTimeColor } from './MyIssueItem'

export const useRemainingTimeColor = (date: RemainingTimeColor) => {
    let color: ChipLabelVariants = 'grey'
    let remainingTime = null
    if (!date) {
        return [remainingTime, color]
    }

    const specificDuration: number =
        date[1] &&
        dayjs()
            .utc()
            .businessMinutesDiff(dayjs(date[0] as string).utc()) * 60000

    const duration = specificDuration
        ? specificDuration
        : dayjs(date[0] as string)
              .utc()
              .diff(dayjs().utc())

    const minutes = dayjs.duration(duration).minutes()
    const hours = dayjs.duration(duration).hours()
    const days =
        duration < 0
            ? +dayjs.duration(duration).asDays().toFixed()
            : Math.floor(+dayjs.duration(duration).asDays())

    if (hours < 0 || minutes < 0 || days < 0) {
        color = 'red'
    } else if (hours >= 0 && days <= 2) {
        color = 'yellow'
    } else if (days > 2) {
        color = 'green'
    }

    if (days && days !== 0) {
        remainingTime = `${days}d ${Math.abs(hours)}h`
    } else if (hours) {
        remainingTime = `${hours}h ${Math.abs(minutes)}m`
    } else {
        remainingTime = `${minutes}m`
    }

    return [remainingTime, color]
}

export const getSortProperties = () => {
    return [
        { code: 'status', name: 'Status' },
        { code: 'subject', name: 'Subject' },
        { code: 'ccrpNo', name: 'CCRP NO' },
        { code: 'targetDate', name: 'Target date' },
        { code: 'createDate', name: 'Create date' },
        { code: 'customer', name: 'Customer' },
        { code: 'issueOwner', name: 'Issue Owner' },
        { code: 'resolutionOwner', name: 'Resolution Owner' },
        { code: 'businessArea', name: 'Business area' },
        { code: 'division', name: 'Division' },
        { code: 'productGroup', name: 'Product group' },
        { code: 'ruCountru', name: 'RU Country' },
    ]
}

export enum SortPropertiesKeys {
    status = 'status',
    subject = 'subject',
    ccrpNo = 'ccrpNo',
    taregetDate = 'targetDate',
    createDate = 'createDate',
    customer = 'customer',
    issueOwner = 'issueOwner',
    resolutionOwner = 'resolutionOwner',
    businessArea = 'businessArea',
    division = 'division',
    productGroup = 'productGroup',
    ruCountru = 'ruCountru',
}

export const sortElements = (
    sortedItems: MyAction2[] | MyIssue2[],
    sortKey: SortPropertiesKeys,
    descending: boolean
) => {
    const getSortValueHandler = (
        sortKey: SortPropertiesKeys
    ): ((value: MyAction2 | MyIssue2) => string | IssueStatuses | Date) => {
        const map: Partial<
            Record<
                SortPropertiesKeys,
                (value: MyAction2 | MyIssue2) => string | IssueStatuses | Date
            >
        > = {
            [SortPropertiesKeys.ccrpNo]: (value) => value?.issueNumber ?? '',
            [SortPropertiesKeys.subject]: (value) => value?.subject ?? '',
            [SortPropertiesKeys.status]: (value) => value?.issueStatusId ?? '',
            [SortPropertiesKeys.taregetDate]: (value) =>
                new Date(value?.dates?.targetResolutionAndVerificationDate) ??
                '',
            [SortPropertiesKeys.createDate]: (value) =>
                new Date(value?.dates?.creationDate) ?? '',
            [SortPropertiesKeys.issueOwner]: (value) =>
                value?.issueOwners[0]?.fullName ?? '',
            [SortPropertiesKeys.customer]: (value) => value?.customerName ?? '',
            [SortPropertiesKeys.resolutionOwner]: (value) =>
                value?.resolutionOwner?.fullName ?? '',
            [SortPropertiesKeys.division]: (value) =>
                value?.resolvingUnit?.organizationalInfo.businessUnit.code ??
                '',
            [SortPropertiesKeys.businessArea]: (value) =>
                value?.resolvingUnit?.organizationalInfo?.division?.code ?? '',
            [SortPropertiesKeys.productGroup]: (value) =>
                value?.resolvingUnit?.organizationalInfo?.productGroup?.code ??
                '',
            [SortPropertiesKeys.ruCountru]: (value) =>
                value?.resolvingUnit?.countryCode ?? '',
        }
        return map[sortKey] ?? map[SortPropertiesKeys.taregetDate]
    }

    sortedItems?.sort((a: MyAction2 | MyIssue2, b: MyAction2 | MyIssue2) => {
        const getSortValue = getSortValueHandler(sortKey)
        let itemA = getSortValue(a)
        let itemB = getSortValue(b)

        if (itemA < itemB) {
            if (descending || [itemA, itemB].includes('')) {
                return 1
            }
            return -1
        } else if (itemA > itemB) {
            if (descending || [itemA, itemB].includes('')) {
                return -1
            }
            return 1
        }
        return 0
    })
}
