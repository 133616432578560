import {
    AssignmentOutlined,
    ExpandMore,
    FilterList,
    GradeOutlined,
    Person,
    PersonOutlined,
    SettingsOutlined,
} from '@mui/icons-material'
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    allInOneReportId,
    deleteReportSettings,
    generateReport,
    getReportSettingById,
    getReportSettings,
    saveReportSettings,
} from 'api'
import { Button, Content, NoResults, PageTitle } from 'components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { AllInOneColumn } from 'types/models'
import {
    AllInOneReportDataFields,
    AllInOneReportSettings,
    ReportResult,
    SpecialCasesState,
} from 'types/reportTypes'
import { base64Number, dataTestId, deepCopy } from 'utils'
import { ReportGrid } from '..'
import {
    CustomerFilters,
    IssueFilters,
    IssueOwnerFilters,
    ReportSettingsFilters,
    ResolverFilters,
} from './components'
import { UnitInternalNCReportProps } from './types'
import {
    getInitColumns,
    getInitCustomerState,
    getInitIssueOwnerState,
    getInitIssueState,
    getInitResolverState,
    getInitSpecialCases,
    getInitState,
} from './utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        filtersIcon: {
            marginRight: theme.spacing(2),
        },
        filtersRoot: {
            border: `1px solid ${theme.palette.divider}`,
        },
        filtersContent: {
            flexDirection: 'column',
            padding: 0,
        },
    }),
    { name: 'GenericAllInOneReport' }
)

export interface GenericAllInOneReportProps extends UnitInternalNCReportProps {}

interface ExpandSectionsState {
    resolverFilters: boolean
    issueOwnerFilters: boolean
    customerFilters: boolean
    issueFilters: boolean
    reportSettingsFilters: boolean
}

export const GenericAllInOneReport = (props: GenericAllInOneReportProps) => {
    const { isUnitInternal = false } = props
    const [openFilters, setOpenFilters] = useState(true)
    const dispatch = useDispatch()
    const location = useLocation()
    const [expanded, setExpanded] = useState<ExpandSectionsState>({
        resolverFilters: true,
        issueOwnerFilters: true,
        customerFilters: true,
        issueFilters: true,
        reportSettingsFilters: false,
    })
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [error, setError] = useState(null)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [settings, setSettings] = useState<AllInOneReportSettings[]>([])

    const resources = useSelector((state: RootState) => state.resources)
    const [state, setState] = useState<AllInOneReportDataFields>(
        getInitState(resources)
    )
    const [data, setData] = useState<ReportResult[]>(null)
    const classes = useStyles()
    const pageTitle = isUnitInternal
        ? 'Unit Internal NC All in One Report'
        : 'All in One Report'
    const loadReportSettings = async (setDefaultSettings = false) => {
        try {
            setLoading(true)
            setLoadingDesc('Loading report settings...')
            const { data } = await getReportSettings(
                allInOneReportId,
                isUnitInternal
            )
            setSettings(data)
            if (setDefaultSettings) {
                const defaultSettings = data.find((x) => x.isDefault)
                if (defaultSettings) {
                    setState({
                        columns: defaultSettings.columns,
                        customer: defaultSettings.customer,
                        isUnitInternal: defaultSettings.isUnitInternal,
                        issue: defaultSettings.issue,
                        issueOwner: defaultSettings.issueOwner,
                        resolver: defaultSettings.resolver,
                        specialCases: defaultSettings.specialCases,
                    })
                }
            }
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const loadLinkedReportSettings = async (reportSettingsId: number) => {
        try {
            setLoading(true)
            setLoadingDesc('Loading report settings...')
            const { data } = await getReportSettingById(
                allInOneReportId,
                reportSettingsId
            )
            if (!data) {
                return
            }
            setSettings([data])
            const defaultSettings = deepCopy(data)
            setState({
                columns: defaultSettings.columns,
                customer: defaultSettings.customer,
                isUnitInternal: defaultSettings.isUnitInternal,
                issue: defaultSettings.issue,
                issueOwner: defaultSettings.issueOwner,
                resolver: defaultSettings.resolver,
                specialCases: defaultSettings.specialCases,
            })
            handleGenerateReport(() => setOpenFilters(false))
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const settingsId = base64Number(searchParams.get('settingsId'))
        if (settingsId === null) {
            loadReportSettings(true)
        } else {
            loadLinkedReportSettings(settingsId)
        }
    }, [])
    const createExpandHandler = (key: keyof ExpandSectionsState) => () =>
        setExpanded((prev) => {
            prev[key] = !prev[key]
            return { ...prev }
        })
    const createStateHandler =
        (key: keyof AllInOneReportDataFields) => (value: any) =>
            setState((prev) => {
                prev[key] = { ...value }
                return { ...prev }
            })
    const handleSaveSettings = async (setting: AllInOneReportSettings) => {
        try {
            setLoading(true)
            setLoadingDesc('Saving report settings...')
            let newSetting: AllInOneReportSettings = {
                reportId: allInOneReportId,
                isUnitInternal,
                resolver: state?.resolver
                    ? { ...state.resolver }
                    : getInitResolverState(),
                issueOwner: state?.issueOwner
                    ? { ...state.issueOwner }
                    : getInitIssueOwnerState(),
                issue: state?.issue
                    ? { ...state.issue }
                    : getInitIssueState(resources),
                customer: state?.customer
                    ? { ...state.customer }
                    : getInitCustomerState(resources),
                columns: state?.columns
                    ? [...state.columns]
                    : getInitColumns(resources),
                specialCases: state?.specialCases
                    ? { ...state.specialCases }
                    : getInitSpecialCases(),
                date: setting.date,
                id: setting.id,
                isDefault: setting.isDefault,
                name: setting.name,
                notificationSettings: setting?.notificationSettings
                    ? { ...setting?.notificationSettings }
                    : null,
            }
            //Remove temporary string guid id
            if (typeof newSetting.id === 'string') {
                delete newSetting.id
            }
            await saveReportSettings(allInOneReportId, newSetting)
            dispatch(showSnackbar('Report settings was saved'))
            loadReportSettings()
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handleDeleteSettings = async (setting: AllInOneReportSettings) => {
        try {
            await deleteReportSettings(allInOneReportId, Number(setting.id))
            const index = settings.findIndex((s) => s.id === setting.id)
            settings.splice(index, 1)
            setSettings([...settings])
            dispatch(showSnackbar('Report settings was removed'))
            loadReportSettings()
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }

    const handleColumnChange = (columns: AllInOneColumn[]) =>
        setState((prev) => ({ ...prev, columns }))
    const handleGenerateReport = async (callBack: () => void = null) => {
        try {
            setLoading(true)
            setLoadingDesc('Generating report...')
            const { data } = await generateReport({ ...state, isUnitInternal })
            setData(data)
            setPage(1)
            callBack && callBack()
        } catch (error) {
            setError(error)
            dispatch(showSnackbar('Cannot generate report', true))
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const handleSpecialCasesChange = (specialCasesState: SpecialCasesState) => {
        const specialCases = { ...specialCasesState }
        Object.keys(specialCases).forEach((k) => {
            specialCases[k] = Boolean(specialCases[k])
        })
        setState((prev) => ({ ...prev, specialCases }))
    }
    return (
        <Content
            title={pageTitle}
            loading={loading}
            error={error}
            loadingDesc={loadingDesc}
            variant="large"
            onRetry={() => setError(null)}
        >
            <PageTitle title={pageTitle} />
            <div>
                <Accordion
                    className={classes.filtersRoot}
                    expanded={openFilters}
                    onChange={(e, expanded) => setOpenFilters(expanded)}
                    elevation={0}
                >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <FilterList className={classes.filtersIcon} />
                        <Typography variant="h5">Report filters</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.filtersContent}>
                        <ResolverFilters
                            title="Resolver"
                            icon={<Person fontSize="small" />}
                            expanded={expanded.resolverFilters}
                            onExpand={createExpandHandler('resolverFilters')}
                            value={state?.resolver ?? getInitResolverState()}
                            onChange={createStateHandler('resolver')}
                            {...dataTestId('RESOLVER_SECTION')}
                        />
                        <IssueOwnerFilters
                            title="Issue Owner"
                            icon={<PersonOutlined fontSize="small" />}
                            expanded={expanded.issueOwnerFilters}
                            onExpand={createExpandHandler('issueOwnerFilters')}
                            value={
                                state?.issueOwner ?? getInitIssueOwnerState()
                            }
                            onChange={createStateHandler('issueOwner')}
                        />
                        {!isUnitInternal && (
                            <CustomerFilters
                                title="Customer"
                                icon={<GradeOutlined fontSize="small" />}
                                expanded={expanded.customerFilters}
                                value={
                                    state?.customer ??
                                    getInitCustomerState(resources)
                                }
                                onChange={createStateHandler('customer')}
                                onExpand={createExpandHandler(
                                    'customerFilters'
                                )}
                            />
                        )}
                        <IssueFilters
                            title="Issue"
                            {...dataTestId('ALLINONE_ISSUE')}
                            icon={<AssignmentOutlined fontSize="small" />}
                            expanded={expanded.issueFilters}
                            onExpand={createExpandHandler('issueFilters')}
                            value={state?.issue ?? getInitIssueState(resources)}
                            onChange={createStateHandler('issue')}
                            columns={state.columns}
                            onColumnsChange={handleColumnChange}
                            specialCases={
                                state?.specialCases ?? getInitSpecialCases()
                            }
                            onSpecialCasesChange={handleSpecialCasesChange}
                            isUnitInternal={isUnitInternal}
                        />
                        <ReportSettingsFilters
                            title="Report settings"
                            {...dataTestId('ALLINONE_REPORTS_SETTINGS')}
                            settings={settings ?? []}
                            onDeleteSettings={handleDeleteSettings}
                            onSaveSettings={handleSaveSettings}
                            icon={<SettingsOutlined fontSize="small" />}
                            dataField={state}
                            onApplySettings={(state) => {
                                if (state) setState(state)
                                else setState(getInitState(resources))
                            }}
                            expanded={expanded.reportSettingsFilters}
                            onExpand={createExpandHandler(
                                'reportSettingsFilters'
                            )}
                        />
                    </AccordionDetails>
                    <AccordionActions>
                        <Button
                            onClick={() => setState(getInitState(resources))}
                            {...dataTestId('ALLINONE_CLEAR_FILTERS')}
                        >
                            Clear filters
                        </Button>
                        <Button
                            onClick={() => handleGenerateReport()}
                            eventName="View All in One report"
                            color="secondary"
                            {...dataTestId('ALLINONE_VIEW_REPORT')}
                        >
                            View Report
                        </Button>
                    </AccordionActions>
                </Accordion>
            </div>
            {Array.isArray(data) && 0 < data?.length ? (
                <ReportGrid
                    columns={state.columns}
                    page={page}
                    onPageChange={setPage}
                    data={data}
                    isUnitInternal={isUnitInternal}
                />
            ) : data ? (
                <NoResults
                    title="No results!"
                    subTitle="Change search criteria and try again."
                />
            ) : null}
        </Content>
    )
}
