import { SvgIconProps, Theme, Typography, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes, ReactNode } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
        },
        title: {
            fontWeight: theme.typography.fontWeightMedium as number,
            marginBottom: theme.spacing(0.5),
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            flexGrow: 1,
        },
        subTitle: {
            display: 'block',
            marginBottom: theme.spacing(1),
            fontSize: '1rem',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular as number as number,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        tags: {
            display: 'flex',
            alignSelf: 'flex-start',
            marginLeft: theme.spacing(1),
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
        },
    }),
    { name: 'CardViewHeader' }
)

export interface CardViewHeaderProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    title: string
    subTitle?: ReactNode
    titleProps?: Partial<TypographyProps>
    subTitleProps?: Partial<TypographyProps>
    renderTag?: (iconProps: Partial<SvgIconProps>) => ReactNode
    onClickLabel?: () => void
    onMouseMoveLabel?: () => void
    onMouseDownLabel?: () => void
}

export const CardViewHeader = (props: CardViewHeaderProps) => {
    const {
        className,
        onClickLabel,
        onMouseMoveLabel,
        onMouseDownLabel,
        title,
        titleProps = {},
        subTitle,
        subTitleProps = {},
        renderTag,
        ...other
    } = props
    const classes = useStyles()
    return (
        <div
            className={clsx(classes.root, className)}
            onClick={onClickLabel}
            onMouseMove={onMouseMoveLabel}
            onMouseDown={onMouseDownLabel}
            style={{
                cursor: onClickLabel && 'pointer',
            }}
            {...other}
        >
            <div className={classes.header}>
                <Typography
                    variant="h6"
                    className={classes.title}
                    {...titleProps}
                >
                    {title}
                </Typography>
                <div className={classes.tags}>{renderTag && renderTag({})}</div>
            </div>
            {subTitle && (
                <Typography variant="body2" {...subTitleProps}>
                    {subTitle}
                </Typography>
            )}
        </div>
    )
}
