import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { OrganizationalInfo } from 'types/issueTypes'
import { RootState } from 'store/types'
import { IssueRoles } from 'types/enums'
import { CcrpRole, CcrpUserScope } from 'types/profile'
import { CountryFilter } from '../types/models'

export const useProcessOwnerChecker = (): [
    (
        organizationInfo: OrganizationalInfo,
        isGlobal: boolean,
        isSingleOwner?: boolean
    ) => boolean,
    Record<IssueRoles, CcrpRole>,
    Record<string, CountryFilter>
] => {
    const [roles, countries] = useSelector<
        RootState,
        [Record<IssueRoles, CcrpRole>, Record<string, CountryFilter>]
    >((state) => {
        const { dashboard, resources } = state
        const roles = dashboard?.profile?.roles.reduce((p, r) => {
            p[r.roleId] = r
            return p
        }, {}) as Record<IssueRoles, CcrpRole>
        const countries = resources?.countries.reduce((p, r) => {
            p[r.code] = r
            return p
        }, {}) as Record<string, CountryFilter>
        return [roles, countries]
    })
    const validateProcessOwner = useCallback(
        (
            organizationInfo: OrganizationalInfo,
            isGlobal: boolean = false,
            isSingleOwner: boolean = true
        ) => {
            if (roles[IssueRoles.Support_Desk]) {
                return true
            }
            const checkRegionalScope = (scope: CcrpUserScope): boolean => {
                if (!isSingleOwner) {
                    if (!isGlobal && scope?.countryCode) {
                        return Boolean(
                            organizationInfo?.country ||
                                organizationInfo?.region ||
                                countries[organizationInfo?.country]?.regionCode
                        )
                    }
                    if (!isGlobal && scope?.regionCode) {
                        return Boolean(
                            organizationInfo?.region ||
                                countries[organizationInfo?.country]?.regionCode
                        )
                    }
                } else if (isSingleOwner) {
                    if (!isGlobal && scope?.countryCode) {
                        return (
                            organizationInfo?.country &&
                            scope.countryCode === organizationInfo?.country
                        )
                    }
                    if (!isGlobal && scope?.regionCode) {
                        return (
                            organizationInfo?.region &&
                            scope.regionCode ===
                                (countries[organizationInfo?.country]
                                    ?.regionCode || organizationInfo?.region)
                        )
                    }
                }

                return true
            }

            if (!organizationInfo) {
                return false
            }

            if (roles[IssueRoles.Div_Process_Owner]) {
                const role = roles[IssueRoles.Div_Process_Owner]

                const scope = role.scopes.find(
                    (s) =>
                        organizationInfo?.division &&
                        organizationInfo.division.code === s.divisionCode
                )
                if (scope && checkRegionalScope(scope)) {
                    return true
                }
            }
            if (roles[IssueRoles.Bu_Process_Owner]) {
                const role = roles[IssueRoles.Bu_Process_Owner]
                const scope = role.scopes.find(
                    (s) =>
                        organizationInfo?.businessUnit &&
                        organizationInfo.businessUnit.code ===
                            s.businessUnitCode
                )
                if (scope && checkRegionalScope(scope)) {
                    return true
                }
            }
            if (roles[IssueRoles.PG_Process_Owner]) {
                const role = roles[IssueRoles.PG_Process_Owner]
                const scope = role.scopes.find(
                    (s) =>
                        organizationInfo?.productGroup &&
                        organizationInfo.productGroup.code ===
                            s.productGroupCode
                )
                if (scope && checkRegionalScope(scope)) {
                    return true
                }
            }
            return false
        },
        [roles, countries]
    )
    return [validateProcessOwner, roles, countries]
}

export const usePermissions = (
    organizationInfo: OrganizationalInfo,
    isGlobal: boolean = false
): {
    isSupportDesk: boolean
    isProcessOwner: boolean
    isDeveloper: boolean
    roles: Record<IssueRoles, CcrpRole>
    countries: Record<string, CountryFilter>
    balIds: string[]
} => {
    const [validateProcessOwner, roles, countries] = useProcessOwnerChecker()
    const isSupportDesk = Boolean(roles[IssueRoles.Support_Desk])
    const isDeveloper = Boolean(roles[IssueRoles.Developer])
    const isProcessOwner = useMemo(
        () => validateProcessOwner(organizationInfo, isGlobal),
        [organizationInfo]
    )
    const balIds =
        roles[IssueRoles.Ru_Process_Owner]?.scopes?.map((s) => s.balId) ?? []
    return {
        isSupportDesk,
        isProcessOwner,
        isDeveloper,
        roles,
        countries,
        balIds,
    }
}
