import axios, { AxiosResponse, CancelToken } from 'axios'
import { CaptureSection } from 'types/issueTypes'
import { IssueChangeLog } from '../store/issue'

const controllerName = '/issue'

/**
 * @param {object} captureSection - capture section object
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of POST request
 */
export const saveCaptureSection = (
    captureSection: CaptureSection,
    changeLog: IssueChangeLog = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<CaptureSection>> =>
    axios.put(
        `${controllerName}/${captureSection.issueId}/capture`,
        { ...captureSection, changeLog },
        { cancelToken }
    )

export const getCaptureSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<CaptureSection>> =>
    axios.get(`${controllerName}/${issueId}/capture`, { cancelToken })
