import { InputLabel, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes, useState } from 'react'
import { DropEvent, useDropzone } from 'react-dropzone'
import { AbbTheme } from 'styles/createAbbTheme'
import { Button, RoundedButton } from '..'

const useStyles = makeStyles<AbbTheme>(
    (theme) => ({
        root: {},
        content: {
            minHeight: 120,
            backgroundColor: theme.palette.grey[100],
            border: `2px dashed ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            '&$dropping': {
                border: `2px dashed ${theme.palette.blue.main}`,
                backgroundColor: theme.palette.blue.light,
            },
            '&$error': {
                border: `2px dashed ${theme.palette.red.main}`,
                backgroundColor: theme.palette.red.light,
            },
        },
        dropping: {},
        error: {},
        addButton: {
            marginTop: theme.spacing(2),
            height: 32,
        },
        noFiles: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2),
        },
        files: {},
        filesActions: {
            margin: theme.spacing(1),
            display: 'flex',
            justifyContent: 'flex-end',
        },
    }),
    { name: 'AttachmentsDropZone' }
)

export interface AttachmentsDropZoneProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    label?: string
    disabled?: boolean
    required?: boolean
    helperText?: string
    error?: boolean
    maxSize?: number
    acceptTypes?: string | string[]
    addFilesLabel?: string
    onFilesDrop?: <T extends File>(files: T[], event: DropEvent) => void
}

export const AttachmentsDropZone: FC<AttachmentsDropZoneProps> = (props) => {
    const {
        className,
        label,
        disabled,
        children,
        required,
        error,
        helperText,
        maxSize,
        acceptTypes = '',
        addFilesLabel = 'Add file',
        onFilesDrop,
        ...other
    } = props
    const classes = useStyles()
    const [dropping, setDropping] = useState(false)
    const { getRootProps, getInputProps, open } = useDropzone({
        disabled,
        noClick: true,
        noKeyboard: true,
        onDragEnter: () => setDropping(false),
        onDragLeave: () => setDropping(false),
        onDragOver: () => setDropping(true),
        onDropAccepted: (files, event) => {
            onFilesDrop && onFilesDrop(files, event)
            setDropping(false)
        },
        accept: acceptTypes ?? [],
        maxSize,
    })

    return (
        <div className={clsx(className, classes.root)} {...other}>
            <InputLabel disabled={disabled} required={required}>
                {label}
            </InputLabel>
            <section
                className={clsx(
                    classes.content,
                    dropping && classes.dropping,
                    error && classes.error
                )}
            >
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {children ? (
                        <div className={classes.files}>
                            {children}
                            <div className={classes.filesActions}>
                                <Button
                                    color="secondary"
                                    onClick={open}
                                    disabled={disabled}
                                >
                                    {addFilesLabel}
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div className={classes.noFiles}>
                            <Typography variant="body1">
                                Drop files here to upload
                            </Typography>
                            {!disabled && (
                                <RoundedButton
                                    variant="contained"
                                    color="secondary"
                                    className={classes.addButton}
                                    onClick={open}
                                    size="large"
                                >
                                    {addFilesLabel}
                                </RoundedButton>
                            )}
                        </div>
                    )}
                </div>
            </section>
            <Typography variant="body2" color={error ? 'secondary' : 'primary'}>
                {helperText}
            </Typography>
        </div>
    )
}
