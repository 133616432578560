import { GenericNode } from './types'

export const insertNodes = (
    treeNodes: GenericNode[],
    nodeId: string,
    childrenNodes: GenericNode[]
): boolean => {
    const traversTree = (
        treeNodes: GenericNode[],
        nodeId: string,
        childrenNodes: GenericNode[]
    ) => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                node.nodes = [...childrenNodes]
                return true
            }
            if (
                node.hasChildren &&
                traversTree(node.nodes, nodeId, childrenNodes)
            ) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId, childrenNodes)
}

export const deleteNode = (
    treeNodes: GenericNode[],
    nodeId: string
): boolean => {
    const traversTree = (treeNodes: GenericNode[], nodeId: string) => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                treeNodes.splice(index, 1)
                return true
            }
            if (node.hasChildren && traversTree(node.nodes, nodeId)) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId)
}

export const changeNodeProperty = (
    treeNodes: GenericNode[],
    nodeId: string,
    key: keyof GenericNode,
    value: any
): boolean => {
    const traversTree = (
        treeNodes: GenericNode[],
        nodeId: string,
        key: keyof GenericNode,
        value: any
    ): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                node[String(key)] = value
                return true
            }
            if (
                node.hasChildren &&
                traversTree(node.nodes, nodeId, key, value)
            ) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId, key, value)
}

export const changeNodeChunk = (
    treeNodes: GenericNode[],
    nodeId: string,
    chunk: Partial<Omit<GenericNode, 'parentId' | 'id'>>
): boolean => {
    const traversTree = (
        treeNodes: GenericNode[],
        nodeId: string,
        chunk: Partial<Omit<GenericNode, 'parentId' | 'id'>>
    ): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                treeNodes[index] = { ...node, ...chunk }
                return false
            }
            if (node.hasChildren && traversTree(node.nodes, nodeId, chunk)) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId, chunk)
}

export const insertChildNode = (
    treeNodes: GenericNode[],
    nodeId: number,
    insertedNode: GenericNode
): boolean => {
    const traversTree = (
        treeNodes: GenericNode[],
        nodeId: number,
        insertedNode: GenericNode
    ): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                const nodes = node?.nodes ?? []
                treeNodes[index] = {
                    ...node,
                    hasChildren: true,
                    nodes: [insertedNode, ...nodes],
                }
                return false
            }
            if (
                node.hasChildren &&
                traversTree(node.nodes, nodeId, insertedNode)
            ) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId, insertedNode)
}

const deactiveAll = (treeNodes: GenericNode[]): boolean => {
    const traversTree = (treeNodes: GenericNode[]): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            treeNodes[index] = { ...node, isActive: false }
            if (node.hasChildren && traversTree(node.nodes)) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes)
}

export const deactiveTreeBranch = (
    treeNodes: GenericNode[],
    nodeId: number
): boolean => {
    const traversTree = (treeNodes: GenericNode[], nodeId: number): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                treeNodes[index] = { ...node, isActive: false }
                if (node.hasChildren) {
                    deactiveAll(node.nodes)
                }
            }
            if (node.hasChildren && traversTree(node.nodes, nodeId)) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId)
}

export const findNode = (treeNodes: GenericNode[], nodeId): GenericNode => {
    const traversTree = (
        treeNodes: GenericNode[],
        nodeId: number
    ): GenericNode => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                return node
            }
            if (node.hasChildren) {
                const results = traversTree(node.nodes, nodeId)
                return results
            }
        }
        return null
    }
    return traversTree(treeNodes, nodeId)
}

export const activeTreeBranch = (treeNodes: GenericNode[], nodeId: string) => {
    const traversTree = (treeNodes: GenericNode[], nodeId: string): boolean => {
        for (let index = 0; index < treeNodes.length; index++) {
            const node = treeNodes[index]
            if (node.id === nodeId) {
                treeNodes[index] = { ...node, isActive: true }
                if (node?.parentId) {
                    activeTreeBranch(treeNodes, node.parentId)
                }
                return false
            }
            if (node.hasChildren && traversTree(node.nodes, nodeId)) {
                return true
            }
        }
        return false
    }
    return traversTree(treeNodes, nodeId)
}

export const buildNodeTree = (
    rootNodes: GenericNode[],
    flatListOfNodes: GenericNode[]
) => {
    const expandedNodes = []
    const newTree = []
    const traversTree = (node: GenericNode, flatListOfNodes: GenericNode[]) => {
        node.nodes = [
            ...flatListOfNodes.filter((n) => {
                if (node.id === n.parentId) {
                    expandedNodes.push(n.id)
                    return true
                }
                return false
            }),
        ]
        for (const childNode of node.nodes) {
            if (childNode.hasChildren) {
                traversTree(childNode, flatListOfNodes)
            }
        }
    }
    for (const node of rootNodes) {
        newTree.push(node)
        expandedNodes.push(node.id)
        traversTree(node, flatListOfNodes)
    }
    return { expandedNodes, newTree }
}

export const expandAllNodes = (nodes: GenericNode[]): Array<string> => {
    const expandedNodes: Array<string> = []
    const traversTree = (node: GenericNode) => {
        if (node.hasChildren) {
            node.nodes.forEach(traversTree)
            expandedNodes.push(node.id)
        }
    }
    nodes.forEach(traversTree)
    return expandedNodes
}
