import { Build, Work } from '@mui/icons-material'
import { Button, Paper } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AccordionActions, ValueView } from 'components'
import { LINK_ISSUE_ITEM_ELEMENT } from 'consts/selectorIds'
import TileHeader from 'Issues/Tiles/TileHeader'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueStatuses } from 'types/enums'
import { IssueRcaPreview } from 'types/issueTypes'
import { customize } from 'utils'
import { urlBuilder } from 'utils/urlBuilder'
import { ISSUE_LINK_ISSUE_PREVIEW } from './routes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(2.5),
        },
        resultHeader: {
            display: 'inline-flex',
            boxSizing: 'border-box',
            width: '100%',
            alignItems: 'center',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            height: 64,
            fontSize: 20,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        resultContent: {
            width: 'auto',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        valuesInline: {
            display: 'flex',
            flexDirection: 'row',
            '@media (max-width: 768px)': {
                flexDirection: 'column',
            },
        },
        inline: {
            width: '50%',
            '&:first-child': {
                paddingRight: theme.spacing(1),
            },
            '&:nth-child(2)': {
                paddingLeft: theme.spacing(1),
            },
            '@media (max-width: 768px)': {
                '&:first-child': {
                    paddingRight: 0,
                },
                '&:nth-child(2)': {
                    paddingLeft: 0,
                },
            },
        },
        icon: {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'LinkIssueItem' }
)

export interface LinkIssueItemProps {
    onCopyRca: (issueId: number) => void
    destinationId: number | string
    item: IssueRcaPreview
    issueStatusId: IssueStatuses
}

export const LinkIssueItem = (props: LinkIssueItemProps) => {
    const { item, onCopyRca, destinationId } = props

    const classes = useStyles()
    return (
        <Paper
            {...customize(LINK_ISSUE_ITEM_ELEMENT.CONTAINER, item.issueNumber)}
            className={classes.root}
            key={item.issueId}
            square={true}
            elevation={0}
        >
            <TileHeader
                number={item.issueNumber}
                issueStatusId={item.issueStatusId}
                id={item.issueId}
                subject={item.description}
                to={urlBuilder(ISSUE_LINK_ISSUE_PREVIEW, {
                    id: destinationId,
                    previewId: item.issueId,
                })}
            />
            <div className={classes.resultContent}>
                <div className={classes.valuesInline}>
                    {item.failureMode && (
                        <ValueView
                            {...LINK_ISSUE_ITEM_ELEMENT.FAILURE_MODE_LABEL}
                            className={classes.inline}
                            icon={<Build className={classes.icon} />}
                            label="Failure mode"
                            value={item.failureMode}
                        />
                    )}
                    {item.customer && (
                        <ValueView
                            {...LINK_ISSUE_ITEM_ELEMENT.CUSTOMER_LABEL}
                            className={classes.inline}
                            icon={<Work className={classes.icon} />}
                            label="Customer"
                            value={item.customer}
                        />
                    )}
                </div>
            </div>
            <AccordionActions>
                <Button
                    {...LINK_ISSUE_ITEM_ELEMENT.ACTION_BUTTONS.COPY_RCA}
                    color="secondary"
                    onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        onCopyRca && onCopyRca(item.issueId)
                    }}
                >
                    Copy RCA
                </Button>
            </AccordionActions>
        </Paper>
    )
}

export default LinkIssueItem
