import MuiBadge, { BadgeProps } from '@mui/material/Badge'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        badge: {},
        dot: {
            backgroundColor: theme.palette.secondary.main,
        },
    }),
    { name: 'Badge' }
)

const Badge = (props: BadgeProps) => {
    const classes = useStyles()
    return <MuiBadge {...props} classes={classes} />
}
Badge.muiName = 'Badge'

export default Badge
