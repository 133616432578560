import { NavButton } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { TileActionsProps } from '..'

export const ToReplaceInactiveIOActionTileActions = (
    props: TileActionsProps
) => {
    const { myActionMeta } = props
    return (
        <>
            <NavButton
                color="secondary"
                linkProps={{ to: `/issue/${myActionMeta.issueId}` }}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .REPLACE_INACTIVE_IO}
                eventName={'Replace inactive issue owner'}
            >
                REPLACE INACTIVE ISSUE OWNER
            </NavButton>
        </>
    )
}
