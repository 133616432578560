import { DialogProps } from '@mui/material'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { RETURN_TO_RESOLUTION_OWNER_DIALOG } from '../../consts/selectorIds'
import { ReturnToRO } from '../../types/actions'
import TextField from '../Base/TextField'
import Dialog from './Dialog'

const MAX_LENGTH_REASON = 1000

export interface ReturnToResolutionOwnerDialogProps extends DialogProps {
    number: string
    message: string
    minHeight: number
    issueId: number
    rowVersion: string
    onReturnToRO: (date: ReturnToRO) => void
}

export const ReturnToResolutionOwnerDialog = (
    props: ReturnToResolutionOwnerDialogProps
) => {
    const [reason, setReason] = useState('')
    const [error, setError] = useState('')

    const {
        open,
        issueId,
        rowVersion,
        number,
        message,
        minHeight,
        onClose,
        onReturnToRO,
        ...other
    } = props
    const onTypeReason = (event: any) => {
        setReason(event.target.value)
        if (reason) {
            setError('')
        }
    }
    const onClickReturn = (event: any) => {
        if (!reason) {
            setError('Return reason is required')
        } else if (MAX_LENGTH_REASON < reason.length) {
            setError(
                `The field Return reason must be a string with a maximum length of ${MAX_LENGTH_REASON}.`
            )
        } else {
            setError('')
            setReason('')
            onReturnToRO &&
                onReturnToRO({
                    issueId,
                    reason,
                    rowVersion,
                })
        }
    }
    const handleClose = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        onClose(e, 'backdropClick')
    }
    return (
        <Dialog open={open} {...other}>
            <DialogTitle {...RETURN_TO_RESOLUTION_OWNER_DIALOG.TITLE}>
                Return to Resolution Owner
            </DialogTitle>
            <DialogContent style={{ minHeight }}>
                <DialogContentText
                    {...RETURN_TO_RESOLUTION_OWNER_DIALOG.CONTENT_TEXT}
                >
                    {message}
                </DialogContentText>
                <TextField
                    variant="filled"
                    {...RETURN_TO_RESOLUTION_OWNER_DIALOG.RETURN_REASON_INPUT}
                    error={error !== ''}
                    helperText={error}
                    autoFocus
                    label="Return Reason"
                    rows={3}
                    multiline
                    value={reason}
                    onChange={onTypeReason}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    {...RETURN_TO_RESOLUTION_OWNER_DIALOG.ACTION_BUTTONS.CANCEL}
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={onClickReturn}
                    {...RETURN_TO_RESOLUTION_OWNER_DIALOG.ACTION_BUTTONS
                        .RETURN_ISSUE}
                >
                    Return Issue
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReturnToResolutionOwnerDialog.defaultProps = {
    open: false,
    minHeight: 200,
    rowVersion: null,
}

export default ReturnToResolutionOwnerDialog
