import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ExpandIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/Help'
import { FormLabel, Tooltip, Typography, useTheme } from '@mui/material'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import { KeyboardDatePicker } from 'components/Base'
import dayjs from 'dayjs'
import { addAttachment, deleteAttachment } from 'store/issue/actions'
import {
    confirmIssueEffectiveness,
    reopenIssue,
    saveEffectivenessSection,
} from 'store/issue/index'
import {
    ActionsKeys,
    EffectivenessTypes,
    IssueStatuses,
    IssueValuesKeys,
} from 'types/enums'
import { Attachment, EffectivenessSection } from 'types/issueTypes'
import Accordion from '../../components/Base/Accordion'
import AccordionActions from '../../components/Base/AccordionActions'
import AccordionDetails from '../../components/Base/AccordionDetails'
import AccordionSummary from '../../components/Base/AccordionSummary'
import FlexGrow from '../../components/Base/FlexGrow'
import TextField from '../../components/Base/TextField'
import ReturnToResolutionOwnerDialog from '../../components/Dialogs/ReturnToResolutionOwnerDialog'
import EffectivenessTypeGroup from '../../components/Fields/EffectivenessTypeGroup'
import FileManager from '../../components/Files/FileManager'
import { appInsights } from '../../configs/appInsights'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { ReopenIssueQuery } from '../../types/actions'
import { useFieldMatrix, useFieldWithError, useIssueAction } from '../matrix'
import useIssueFormStyles from './useIssueFormStyles'
import { EFFECTIVENESS_SECTION_ID } from './utils'

export const DEFAULT_DATE = '0001-01-01T00:00:00Z'

export interface EffectivenessAndSustainabilityProps {
    expanded: boolean
    issueNumber: string
    onExpand: (expanded: boolean) => void
    onSectionChange?: (propKey: keyof EffectivenessSection, value: any) => void
    disabled: boolean
    effectivenessSection: EffectivenessSection
    attachments: Array<Attachment>
    issueStatusId: IssueStatuses
}

const EffectivenessAndSustainability = (
    props: EffectivenessAndSustainabilityProps
) => {
    const {
        expanded,
        issueNumber,
        onExpand,
        onSectionChange,
        disabled,
        effectivenessSection,
        attachments = [],
        issueStatusId,
    } = props
    const classes = useIssueFormStyles()
    const theme = useTheme()

    const dispatch = useDispatch()
    const [
        reasonWhyNotEffectiveOrNotRequired,
        setReasonWhyNotEffectiveOrNotRequired,
    ] = useState<string>('')
    useEffect(() => {
        setReasonWhyNotEffectiveOrNotRequired(
            effectivenessSection?.reasonWhyNotEffectiveOrNotRequired ?? ''
        )
    }, [effectivenessSection])
    const [reopenIssueOpen, setReopenIssueOpen] = useState(false)
    const saveEffectivenessActions = useIssueAction(
        ActionsKeys.SaveEffectivenessSection
    )
    const reopenIssueAction = useIssueAction(ActionsKeys.ReopenIssue)
    const confirmedAction = useIssueAction(ActionsKeys.Confirmed)
    const effectivenessTypeIdGroup = useFieldWithError(
        IssueValuesKeys.EffectivenessTypeId,
        { disabled }
    )
    const attachmentsGroup = useFieldMatrix(
        IssueValuesKeys.EffectivenessAttachments,
        { disabled }
    )
    const reasonWhyNotEffectiveOrNotRequiredField = useFieldWithError(
        IssueValuesKeys.ReasonWhyNotEffectiveOrNotRequired,
        {
            disabled,
        }
    )

    const nextEscalationDateField = useFieldWithError(
        IssueValuesKeys.NextEscalationDate,
        {
            disabled,
            defaultHelperText:
                effectivenessSection?.nextEscalationDate &&
                [IssueStatuses.Completed].includes(issueStatusId) &&
                effectivenessSection?.nextEscalationDate !== DEFAULT_DATE
                    ? `Your local time: ${dayjs(
                          effectivenessSection?.nextEscalationDate
                      )
                          .utc()
                          .endOf('day')
                          .local()
                          .format('YYYY-MM-DD • HH:mm')}`
                    : null,
            matrixModifier(currentMatrix, issueState) {
                const createDate = dayjs(effectivenessSection?.createDate)
                    .utc()
                    .add(1, 'day')

                if (
                    effectivenessSection?.escalationState > 1 ||
                    createDate?.add(1, 'year') < dayjs().utc() ||
                    effectivenessTypeIdGroup?.disabled
                )
                    return {
                        ...currentMatrix,
                        isDisabled: true,
                        isRequired: false,
                    }
                return { ...currentMatrix, isRequired: true }
            },
        }
    )

    const handleReturnToRO = (query: ReopenIssueQuery) => {
        setReopenIssueOpen(false)
        dispatch(reopenIssue(query))
    }
    const handleConfirmEffectiveness = () => {
        dispatch(confirmIssueEffectiveness())
    }

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const minDate = dayjs().utc().add(1, 'day').endOf('day')
    const maxDate = dayjs(effectivenessSection?.createDate)
        .utc()
        .add(1, 'year')
        .endOf('day')

    return effectivenessSection ? (
        <Accordion
            id={EFFECTIVENESS_SECTION_ID}
            className={classes.section}
            expanded={expanded}
            onChange={(e, expanded) => onExpand && onExpand(expanded)}
        >
            <AccordionSummary
                {...ISSUE_VIEW_PAGE.EFFECTIVENESS.EXPANSION_PANEL}
                expandIcon={<ExpandIcon />}
            >
                <Typography variant="h5">
                    Effectiveness and sustainability verification
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                {...ISSUE_VIEW_PAGE.EFFECTIVENESS.DETAILS_CONTAINER}
            >
                <KeyboardDatePicker
                    disablePast={true}
                    label="Effectiveness reminder date"
                    minDate={minDate}
                    maxDate={maxDate}
                    value={
                        [IssueStatuses.Completed].includes(issueStatusId) &&
                        effectivenessSection?.nextEscalationDate !==
                            DEFAULT_DATE
                            ? dayjs(
                                  effectivenessSection?.nextEscalationDate
                              ).utc()
                            : null
                    }
                    onChange={(date, value) => {
                        onSectionChange('nextEscalationDate', date)
                    }}
                    {...nextEscalationDateField}
                    slotProps={{
                        textField: {
                            ...nextEscalationDateField,
                            ...ISSUE_VIEW_PAGE.EFFECTIVENESS
                                .EFFECTIVENESS_REMINDER_DATE,
                        },
                    }}
                />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel required={effectivenessTypeIdGroup.required}>
                        Corrective action effective and sustainable?
                    </FormLabel>
                    <Tooltip
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        arrow
                        title={
                            <Typography variant="caption">
                                Here as Issue owner who represent the Voice of
                                Customer, you check and confirm that the
                                solution implemented for this CCRP has solved
                                Customer issue in effective and sustainable way
                                and is fully satisfactory to him/her.
                                <br /> <br /> Means that the issue is not
                                reoccurring towards particular Customer again.
                                <br /> <br />
                                (YES=corrective actions taken, effective and
                                sustainable, NO=corrective actions taken, but
                                not effective and sustainable; N/A=no corrective
                                actions defined, solution effective and
                                sustainable)
                            </Typography>
                        }
                    >
                        <HelpIcon
                            style={{
                                color: theme.palette.grey[600],
                                fontSize: 22,
                                marginLeft: theme.spacing(1),
                            }}
                        />
                    </Tooltip>
                </div>
                <EffectivenessTypeGroup
                    {...ISSUE_VIEW_PAGE.EFFECTIVENESS
                        .CORRECTIVE_ACTION_RADIO_GROUP}
                    style={{ marginBottom: theme.spacing(2) }}
                    disabled={effectivenessTypeIdGroup.disabled}
                    value={effectivenessSection.effectivenessTypeId}
                    {...effectivenessTypeIdGroup}
                    onChange={(event, value) =>
                        onSectionChange &&
                        onSectionChange('effectivenessTypeId', Number(value))
                    }
                />
                <Collapse
                    in={
                        effectivenessSection?.effectivenessTypeId &&
                        effectivenessSection.effectivenessTypeId !==
                            EffectivenessTypes.YES
                    }
                >
                    <TextField
                        {...ISSUE_VIEW_PAGE.EFFECTIVENESS.WHY_INPUT}
                        label="Why was the corrective/preventive action not effective or not required?"
                        {...reasonWhyNotEffectiveOrNotRequiredField}
                        value={reasonWhyNotEffectiveOrNotRequired}
                        onChange={(e: any) =>
                            setReasonWhyNotEffectiveOrNotRequired(
                                e.target.value
                            )
                        }
                        onBlur={() =>
                            onSectionChange &&
                            onSectionChange(
                                'reasonWhyNotEffectiveOrNotRequired',
                                reasonWhyNotEffectiveOrNotRequired
                            )
                        }
                        fullWidth
                        multiline
                        rows={3}
                    />
                </Collapse>
                <FileManager
                    {...ISSUE_VIEW_PAGE.EFFECTIVENESS.ATTACHMENTS_LIST}
                    label={'Attachments (max file size is 100 MB)'}
                    issueId={effectivenessSection.issueId}
                    attachmentType={10}
                    attachments={attachments}
                    {...attachmentsGroup}
                    onFileUploaded={(attachment) =>
                        dispatch(addAttachment(attachment))
                    }
                    onFileRemove={(attachment) =>
                        dispatch(deleteAttachment(attachment))
                    }
                ></FileManager>
            </AccordionDetails>
            {disabled ? null : (
                <AccordionActions style={{ marginTop: theme.spacing(3) }}>
                    <ReturnToResolutionOwnerDialog
                        open={reopenIssueOpen}
                        message={`You are about to reopen issue ${String(
                            issueNumber
                        )}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                        minHeight={220}
                        rowVersion={effectivenessSection.rowVersion}
                        number={issueNumber}
                        issueId={effectivenessSection.issueId}
                        onReturnToRO={(formData) => handleReturnToRO(formData)}
                        onClose={() => setReopenIssueOpen(false)}
                    />

                    {saveEffectivenessActions.disabled ? null : (
                        <Button
                            {...ISSUE_VIEW_PAGE.EFFECTIVENESS.ACTION_BUTTONS
                                .SAVE}
                            onClick={() => dispatch(saveEffectivenessSection())}
                        >
                            Save
                        </Button>
                    )}
                    <FlexGrow />
                    {reopenIssueAction.disabled ? null : (
                        <Button
                            onClick={() => {
                                appInsights.trackEvent({
                                    name: 'Reopen issue',
                                })
                                setReopenIssueOpen(true)
                            }}
                            {...ISSUE_VIEW_PAGE.EFFECTIVENESS.ACTION_BUTTONS
                                .REOPEN}
                        >
                            Reopen
                        </Button>
                    )}
                    {confirmedAction.disabled ? null : (
                        <Button
                            {...ISSUE_VIEW_PAGE.EFFECTIVENESS.ACTION_BUTTONS
                                .CONFIRMED}
                            color="secondary"
                            onClick={() => {
                                appInsights.trackEvent({
                                    name: 'Effectivness confirm',
                                })
                                handleConfirmEffectiveness()
                            }}
                        >
                            Confirmed
                        </Button>
                    )}
                </AccordionActions>
            )}
        </Accordion>
    ) : null
}

EffectivenessAndSustainability.defaultProps = {
    disabled: false,
    actionRequied: false,
}

export default EffectivenessAndSustainability
