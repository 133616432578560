import HelpIcon from '@mui/icons-material/Help'
import { Button, Theme, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import theme from 'styles/themeBase'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            display: 'flex',
            justifyContent: 'flex-end',
            height: 'fit-content',
            margin: theme.spacing(1),
            minWidth: 300,
        },
    }),
    {
        name: 'AdminEmployeeLabel',
    }
)

export interface ProcessOwnerActionsProps {
    setShowProcessOwners: (value: boolean) => void
    addPerson: () => void
    showProcessOwners: boolean
    canEdit: boolean
    isActive: boolean
    showTooltipText: string
}

export const ProcessOwnerActions = (props: ProcessOwnerActionsProps) => {
    const classes = useStyles()
    const {
        setShowProcessOwners,
        addPerson,
        showProcessOwners,
        canEdit,
        isActive,
        showTooltipText,
    } = props

    return (
        <div className={classes.root}>
            {showProcessOwners ? (
                <Button onClick={() => setShowProcessOwners(false)}>
                    Hide Process Owners
                </Button>
            ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={showTooltipText}>
                        <HelpIcon
                            style={{
                                color: theme.palette.grey[600],
                                fontSize: 16,
                                marginLeft: -theme.spacing(0.5),
                            }}
                        />
                    </Tooltip>
                    <Button
                        onClick={() => setShowProcessOwners(true)}
                        {...dataTestId('SHOW_PROCESS_OWNERS_BUTTON')}
                    >
                        Show Process Owners
                    </Button>
                </div>
            )}
            <Button
                disabled={!canEdit || !isActive}
                color="secondary"
                onClick={() => {
                    addPerson()
                }}
                {...dataTestId(
                    !canEdit || !isActive
                        ? 'ADD_PERSON_RU_BUTTON_DISABLED'
                        : 'ADD_PERSON_RU_BUTTON'
                )}
            >
                Add PERSON
            </Button>
        </div>
    )
}
