import AddIcon from '@mui/icons-material/Add'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'
import { ActionPlanContext } from 'ActionPlan/context/ActionPlanContext'
import { DashboardDesktop } from 'ActionPlan/IssueDashboard/DashboardDesktop'
import { DashboardMobile } from 'ActionPlan/IssueDashboard/DashboardMobile'
import { ActionPlanDashboardColumn } from 'ActionPlan/IssueDashboard/types'
import { Content, PageAppBar, RoundedButton } from 'components'
import { useCanCreateActionPlanItem, useIssueForm, useMergeErrors } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { showSnackbar } from 'store/app'
import { IssueStoreState } from 'store/issue'
import { loadActionPlan, loadIssue } from 'store/issue/actions'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { ActionPlanStatusEnum } from 'types/actionPlanTypes'
import { ActionPlanItemParams } from 'types/urlParams'
import { dataTestId } from 'utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        wrapper: {
            margin: theme.spacing(0, 4),
            '@media (max-width: 768px)': {
                margin: theme.spacing(0, 2),
            },
        },
        icon: {
            marginRight: theme.spacing(1),
        },
        addButton: {
            marginBottom: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(3),
            height: theme.spacing(4),
        },
    }),
    { name: 'IssueActionPlanDashboard' }
)

export const IssueActionPlanDashboard = () => {
    const dispatch = useDispatch()
    const {
        openActionItem,
        assignActionItem,
        rejectActionItem,
        completeActionItem,
        reopenActionItem,
        error,
        setError,
        dashboardState,
        updateActionItemsDashboard,
        updateDashboardState,
    } = useContext(ActionPlanContext)

    useEffect(() => {
        if (error) {
            if (error?.errors && Array.isArray(error.errors)) {
                dispatch(showSnackbar(error.errors[0]?.message, true))
                setError(null)
            } else {
                dispatch(showSnackbar('Error', true))
            }
        }
    }, [error])

    const { id: issueIdOrNumberParam } = useParams<ActionPlanItemParams>()
    const { actionItems, context, issueId } = useSelector<
        RootState,
        IssueStoreState
    >((state) => state.issue)
    const canAddTask = useCanCreateActionPlanItem()
    const { loadingActionPlan } = useIssueForm()

    useEffect(() => {
        if (loadingActionPlan) return
        if (Number.isInteger(Number(issueIdOrNumberParam))) {
            if (issueId) dispatch(loadActionPlan(issueId))
            else dispatch(loadIssue(issueIdOrNumberParam, true))
        } else {
            if (issueId) dispatch(loadActionPlan(issueId))
            else dispatch(loadIssue(issueIdOrNumberParam, true))
        }
    }, [])

    useEffect(() => {
        updateActionItemsDashboard(actionItems)
    }, [actionItems])

    const [navigationValue, setNavigationValue] = useState(0)
    const matches = useMediaQuery('(max-width: 1024px)')

    const classes = useStyles()

    const onDragEnd = async (result: DropResult) => {
        // move insite column
        const { destination, source, draggableId } = result
        if (!destination) {
            return
        }
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return
        }
        const column: ActionPlanDashboardColumn =
            dashboardState.columns[source.droppableId]
        const finish: ActionPlanDashboardColumn =
            dashboardState.columns[destination.droppableId]
        if (column === finish) {
            const newItemsIds = [...column.itemsIds]
            newItemsIds.splice(source.index, 1)
            newItemsIds.splice(destination.index, 0, parseInt(draggableId))
            const newColumn = {
                ...column,
                itemsIds: newItemsIds,
            }
            updateDashboardState({
                columns: {
                    ...dashboardState.columns,
                    [newColumn.id]: newColumn,
                },
            })
            return
        }

        // move between columns and update item
        const item = dashboardState.items[draggableId]
        const matrix = item.accessMatrix[0].matrix
        if (source.droppableId === ActionPlanStatusEnum.Draft) {
            if (destination.droppableId === ActionPlanStatusEnum.InProgress) {
                if (matrix.actionPlan_Assign) {
                    assignActionItem(item, result)
                }
            }
        } else if (source.droppableId === ActionPlanStatusEnum.InProgress) {
            if (destination.droppableId === ActionPlanStatusEnum.Draft) {
                if (matrix.actionPlan_Reject) {
                    rejectActionItem(item, result)
                }
            } else if (
                destination.droppableId === ActionPlanStatusEnum.Closed
            ) {
                if (matrix.actionPlan_Close) {
                    completeActionItem(item, result)
                }
            }
        } else if (source.droppableId === ActionPlanStatusEnum.Closed) {
            if (destination.droppableId === ActionPlanStatusEnum.InProgress) {
                if (matrix.actionPlan_Reopen) {
                    reopenActionItem(item, result)
                }
            }
        }
    }

    const { isLoading, loadingDescription } = useMergeErrors()

    return (
        <>
            <PageAppBar
                title="Action plan dashboard"
                caption={context?.issueNumber}
            />
            <Content
                variant="large"
                loading={isLoading || loadingActionPlan}
                loadingDesc={loadingDescription}
                error={error}
                onRetry={() => setError(null)}
            >
                <div className={classes.wrapper}>
                    {canAddTask && (
                        <RoundedButton
                            variant="contained"
                            color="secondary"
                            className={classes.addButton}
                            onClick={() => openActionItem(issueIdOrNumberParam)}
                            {...dataTestId('ACTION_PLAN_ACTION_PLAN_ITEM')}
                        >
                            <AddIcon
                                className={classes.icon}
                                fontSize="small"
                            />
                            Action plan item
                        </RoundedButton>
                    )}
                </div>
                {matches ? (
                    <DashboardMobile
                        columnOrder={dashboardState.columnOrder}
                        columns={dashboardState.columns}
                        items={dashboardState.items}
                        navigationValue={navigationValue}
                        setNavigationValue={setNavigationValue}
                    />
                ) : (
                    <DashboardDesktop
                        columnOrder={dashboardState.columnOrder}
                        columns={dashboardState.columns}
                        items={dashboardState.items}
                        onDragEnd={onDragEnd}
                    />
                )}
            </Content>
        </>
    )
}
