import {
    Employee,
    HrgtOrganizationInfo,
    OrganizationalInfo,
} from './issueTypes'
import { BusinessUnitFilterClassification } from './models'

export interface LocationActivity {
    code: string
    desc: string
    id: number
    name: string
}

export enum AdminEmployeeTypeEnum {
    All = 0,
    RUProcessOwner = 1,
    Coordinator = 2,
}

export type AdminEmployeeType =
    | AdminEmployeeTypeEnum.All
    | AdminEmployeeTypeEnum.Coordinator
    | AdminEmployeeTypeEnum.RUProcessOwner

export interface Address {
    address: string
    addressId: string
    city: string
    cityLatin: string
    cityUniqueKey: string
    countryCode: string
    regionCode: string
    zipCode: string
    full: string
}
export interface AdminResolvingUnit {
    abacusCode: string
    address: Address
    balId: string
    coordinators: Employee[]
    descriptiveName: string
    id: number
    isActive: boolean
    isGlobal: boolean
    isInTransitionPeriod: boolean
    locationActivity: Partial<LocationActivity>
    processOwners: Employee[]
    businessUnitCode: string
    divisionCode: string
    productGroupCode: string
    productGroupName: string
}

export interface ProcessOwner {
    firstName: string
    lastName: string
    fullName: string
    isActive: boolean
    roleCode: number
    organizationInfo: OrganizationalInfo
    geid: string
    email: string
    id: number
    phone: string
}

export interface AdminProcessOwner {
    organizationalInfo: OrganizationalInfo
    hrgtOrganizationalInfo: HrgtOrganizationInfo
    processOwners: ProcessOwner[]
    reactId?: string
}

export interface AdminHubInfo {
    belongingCountriesWithNames: string
    belongingCountryCodesRaw: string
    businessUnitCode: string
    divisionCode: string
    code: string
    name: string
    id: number
    isActive: boolean
    leadCountryCode: string
    leadCountryName: string
    productGroupCode: string
    processOwners: Employee[]
    hubProcessOwners: Employee[]
    reportingPeriodName: string
}

export interface AdminHubStructureBU {
    code: string
    divisionCode: string
    divisionName: string
    id: number
    isActive: boolean
    isPgHubs: boolean
    name: string
}

export interface AdminHubStructureItem {
    divisionCode: string
    divisionName: string
    businessUnits: AdminHubStructureBU[]
}

export interface AdminClassification {
    page: number
    items: ClassificationItem[]
    size: number
    total: number
    count: number
}

export interface ClassificationBase {
    categoryName: string
    id: number
}

export interface ClassificationIssueForm extends ClassificationBase {
    isSelected: boolean
}

export interface ClassificationItem extends ClassificationBase {
    bu: BusinessUnitFilterClassification
    isActive: boolean
}
