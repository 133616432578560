import { makeStyles } from '@mui/styles'
import { useMemo } from 'react'
import { Label } from '../../components'
import { AbbTheme } from '../../styles/createAbbTheme'

export interface HighlightsItemProps {
    name: string
    values: string[]
}

const useStyles = makeStyles((theme: AbbTheme) => ({
    root: {
        '& > em': {
            backgroundColor: theme.palette.grey[200],
            borderRadius: theme.shape.borderRadius,
        },
    },
}))

export const HighlightsItem = (props: HighlightsItemProps) => {
    const { name, values } = props
    const classes = useStyles()
    const label = useMemo(() => getPropertyLabel(name), [name])
    return (
        <div className={classes.root}>
            <Label>{label}</Label>
            {values.map((x) => (
                <div
                    className={classes.root}
                    key={x}
                    dangerouslySetInnerHTML={{ __html: x }}
                />
            ))}
        </div>
    )
}

export const getPropertyLabel = (key: string): string => {
    const map: { [key: string]: string } = {
        issueStatus: 'Issue status',
        issueNumber: 'Issue number',
        issueOwner: 'Issue owner',
        issueOwnerSubstitutes: 'Substitute(s)',
        issueOwnerSupervisor: 'Issue owner supervisor',
        customerName: 'Customer name',
        customerCommonName: 'Customer name',
        customerContact: 'Customer contact',
        issueSubject: 'Issue subject',
        issueDescription: 'Issue description',
        whatIsHappening: 'What is happening?',
        whyIsThisAProblem: 'Why is this a problem?',
        howDoWeKnowWeHaveAProblem: 'How do we know we have a problem?',
        whereDoesDidTheProblemHappen: 'Where does/did the problem happen?',
        whenDoesDidTheProblemHappen: 'Where does/did the problem happen?',
        whatIsCustomerExpectedResolution:
            "What are the customer's execptations?",
        containmentActionDescription: 'containment action description?',
        highImpactReason: 'High impact reason',
        resolvingUnitBALId: 'Resolving unit Bal id',
        proposedResolutionOwner: 'Proposed Resolution Owner',
        resolvingUnitCoordinators: 'Resolving unit coordinators',
        product: 'Product',
        failureMode: 'Failure mode',
        productSerialNo: 'Product serial No',
        projectNo: 'Project No',
        projectName: 'Project name',
        customerReferenceNumber: 'Customer reference number',
        warrantyReference: 'Warranty reference',
        resolutionOwner: 'Resolution owner',
        resolutionOwnerTeam: 'Resolution owner team',
        whatWasTheRootCauseOfThisIssue: 'What was the root cause of this issue',
        howDidWeResolveTheIssue: 'How did we resolve the issue',
        resolutionDescription: 'Resolution description',
        productGroupCategory: 'Product group category',
        customerComments: 'Customer comments',
        salesforceABBOrderNumber: 'Salseforce ABB order number',
        salesforceSalesOrderNumber: 'Sales order number',
        salesforcePurchaseOrderNumber: 'Purchase order number',
        salesforceServiceAgreement: 'Service agreement',
        salesforceSAPNumber: 'SAP number',
        salesforceCaseNumber: 'Case number',
        nPSRedCardDetails: 'NPS Redcard details',
    }

    return map[key] ?? key
}
