import axios, { AxiosResponse, CancelToken } from 'axios'
import { Customer } from 'types/issueTypes'
import { CustomerQuery } from '../types/queries'

const controllerName = '/customers'

export const searchCustomers = (
    query: CustomerQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Customer[]>> => {
    const data: CustomerQuery = {
        page: 1,
        pageSize: 100,
        ...query,
    }
    Object.keys(data).forEach(
        (key: string) =>
            data[key as keyof CustomerQuery] === null &&
            delete data[key as keyof CustomerQuery]
    )
    return axios.post(
        `${controllerName}/search`,
        { ...data },
        {
            cancelToken,
        }
    )
}

export const searchCustomersHeadquarters = (
    searchPhrase: string,
    accountType: number = 0,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Customer[]>> =>
    axios.get(`${controllerName}/headquarters`, {
        params: { searchPhrase, accountType },
        cancelToken,
    })

export const getRecentlyCustomers = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Customer[]>> =>
    axios.get(`${controllerName}/recent`, {
        cancelToken,
    })
