import { HubsManagePageCard, HubsManagePageExcelExport } from '../..'
import { AdminHubInfo } from 'types/adminTypes'
import { UserProfile } from 'types/profile'
import { Employee } from 'types/issueTypes'
import { SearchResultsWithPagination } from 'components'

export interface HubsManagePageResultsProps {
    noResults?: boolean
    hubs: AdminHubInfo[]
    searching: boolean
    page: number
    pageSize: number
    onPageChange: (page: number) => void
    selectedHubs: number[]
    profile: UserProfile
    onSelectHub: (hubId: number, select: boolean) => void
    onDeleteHubProcessOwner: (processOwner: Employee, hub: AdminHubInfo) => void
    onAddProcessOnwer: (hubId: number) => void
}

export const HubsManagePageResults = (props: HubsManagePageResultsProps) => {
    const {
        noResults,
        hubs,
        searching,
        page,
        pageSize,
        onPageChange,
        selectedHubs,
        profile,
        onAddProcessOnwer,
        onSelectHub,
        onDeleteHubProcessOwner,
    } = props
    return (
        <SearchResultsWithPagination
            isSearching={searching}
            page={page}
            pageSize={pageSize}
            itemCount={hubs.length}
            onPageChange={onPageChange}
            renderAction={() => (
                <HubsManagePageExcelExport
                    isSearching={searching}
                    hubs={hubs}
                />
            )}
            errorTitle="No results!"
            errorSubTitle="Change search criteria and try again."
            errorVisible={noResults}
        >
            {hubs
                .filter(
                    (h, i) => pageSize * (page - 1) <= i && i < pageSize * page
                )
                .map((h) => (
                    <HubsManagePageCard
                        profile={profile}
                        key={h.id}
                        selected={selectedHubs.includes(h.id)}
                        onSelect={onSelectHub}
                        hub={h}
                        onDeleteProcessOwner={onDeleteHubProcessOwner}
                        onAddHubProcessOwner={onAddProcessOnwer}
                    />
                ))}
        </SearchResultsWithPagination>
    )
}
