import * as ACTIONS from './actionTypes'

import { MyIssue, MyIssue2 } from 'types/myDashboard'
import { initState, MyIssuesStoreState } from './initState'
import { AnyAction } from 'redux'
import { SET_RESOURCES } from '../resources'
import { FilterBase } from 'types/models'
import { AuthenticationActions } from 'react-aad-msal'
import { ExtendTargetResolutionDateQuery } from 'types/actions'
import { NEW_DASHBOARD } from '../../features'

const reducersIndex: {
    [key: string]: (
        state: MyIssuesStoreState,
        action: AnyAction
    ) => MyIssuesStoreState
} = {
    [ACTIONS.MY_ISSUES_LOAD_CALL]: (state) => ({
        ...state,
        loading: true,
        updating: false,
        loadingDesc: 'Loading your issues…',
        error: null,
    }),
    [ACTIONS.MY_ISSUES_LOAD_ERROR]: (state, action) => ({
        ...state,
        loading: false,
        updating: false,
        loadingDesc: null,
        error: action.payload,
    }),
    [ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL]: (state) => ({
        ...state,
        loading: false,
        updating: true,
        error: null,
        loadingDesc: 'Extending Target Resolution and Verification date…',
    }),
    [ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_DONE]: (state, action) => {
        const payload = action.payload as ExtendTargetResolutionDateQuery
        const newItems = [...state.items]
        const index = newItems.findIndex((x) => x.issueId === payload.issueId)
        if (NEW_DASHBOARD) {
            let newItem = newItems[index] as MyIssue2
            newItem.dates = {
                ...newItem.dates,
                targetResolutionAndVerificationDate:
                    payload.targetResolutionAndVerificationDate,
            }
            newItems[index] = newItem
        } else {
            let newItem = newItems[index] as MyIssue
            newItem.targetResolutionAndVerificationDate =
                payload.targetResolutionAndVerificationDate
            newItems[index] = newItem
        }

        state.items = newItems
        return state
    },
    [ACTIONS.MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_FAIL]: (
        state,
        action
    ) => ({
        ...state,
        loading: false,
        updating: false,
        loadingDesc: null,
        error: action.payload,
    }),
    [ACTIONS.MY_ISSUES_REFRESH_BY_ID_CALL]: (state) => ({
        ...state,
        loading: false,
        updating: true,
        loadingDesc: 'Refreshing issue data…',
        error: null,
    }),
    [ACTIONS.MY_ISSUES_REFRESH_BY_ID_ERROR]: (state, action) => ({
        ...state,
        loading: false,
        updating: false,
        error: action.payload,
        loadingDesc: null,
    }),
    [ACTIONS.MY_ISSUES_REFRESH_BY_ID_DONE]: (state, action) => {
        const newIssue: MyIssue | MyIssue2 = action.payload
        const issues = [...state.items]
        const index = issues.findIndex((x) => x.issueId === newIssue.issueId)
        issues[index] = newIssue
        return {
            ...state,
            items: issues,
            loading: false,
            updating: false,
            error: null,
            loadingDesc: null,
        }
    },
    [ACTIONS.MY_ISSUES_LOAD_DONE]: (state, action) => ({
        ...state,
        loading: false,
        loadingMore: false,
        updating: false,
        items: action.payload,
    }),
    [SET_RESOURCES]: (state, action) => ({
        ...state,
        issueTypesFilters: action.payload.issueType.map((a: FilterBase) => ({
            ...a,
            selected: false,
            display: true,
        })),
        issueRolesFilters: action.payload.roleCode.map((a: FilterBase) => ({
            ...a,
            selected: false,
            display: true,
        })),
        issueStatusesFilters: action.payload.complaintStatus.map(
            (a: FilterBase) => ({
                ...a,
                selected: false,
                display: true,
            })
        ),
        selectedFiltersCount: 0,
    }),
    [ACTIONS.MY_ISSUES_REMOVE_ALL]: (state) => ({
        ...state,
        items: [],
    }),
    [ACTIONS.MY_ISSUES_REMOVE_BY_ID]: (state, action) => {
        state.items = state.items.filter((x) => x.issueId !== action.payload)
        return state
    },
    [ACTIONS.MY_ISSUES_CLEAR_ERROR]: (state) => ({
        ...state,
        error: null,
        loading: false,
        loadingMore: false,
        loadingDesc: '',
    }),
    [AuthenticationActions.LogoutSuccess]: () => ({
        ...initState,
    }),
    [ACTIONS.MY_ISSUES_EXPORT_CALL]: (state) => ({
        ...state,
        exportingIssues: true,
    }),
    [ACTIONS.MY_ISSUES_EXPORT_DONE]: (state) => ({
        ...state,
        exportingIssues: false,
    }),
    [ACTIONS.MY_ISSUES_EXPORT_FAIL]: (state) => ({
        ...state,
        exportingIssues: false,
    }),
}

export const myIssuesReducer = (
    state: MyIssuesStoreState = initState,
    action: AnyAction
): MyIssuesStoreState => reducersIndex[action?.type]?.(state, action) || state
