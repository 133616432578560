import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { HrgtLevelFilter } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionLabel, getOptionSelected } from './utils'

export interface OwnerMngrLvl3SelectProps extends Partial<BaseTextFieldProps> {
    divisionCodes: string[]
    businessUnitCodes: string[]
    selectedItems: HrgtLevelFilter[]
    onSelectedItemsChanged?: (items: HrgtLevelFilter[]) => void
}

export const OwnerMngrLvl3Select = (props: OwnerMngrLvl3SelectProps) => {
    const {
        selectedItems = [],
        onSelectedItemsChanged,
        disabled,
        error,
        helperText,
        divisionCodes = [],
        businessUnitCodes = [],
        ...other
    } = props
    const [searchText, setSearchText] = useState('')
    const allOptions = useSelector<RootState, HrgtLevelFilter[]>(
        (state) => state?.resources?.hrgtLevel3 ?? []
    )
    const options = useMemo(() => {
        const filterByDivision =
            Array.isArray(divisionCodes) && 0 < divisionCodes.length
        const filterByBusinessUnit =
            Array.isArray(businessUnitCodes) && 0 < businessUnitCodes?.length
        return allOptions
            .filter((x) =>
                filterByDivision
                    ? divisionCodes.includes(x.organizationInfo.division.code)
                    : true
            )
            .filter((x) =>
                filterByBusinessUnit
                    ? businessUnitCodes.includes(
                          x.organizationInfo.businessUnit.code
                      )
                    : true
            )
    }, [allOptions, divisionCodes, businessUnitCodes])
    useEffect(() => {
        const filterByDivision =
            Array.isArray(divisionCodes) && 0 < divisionCodes.length
        const filterByBusinessUnit =
            Array.isArray(businessUnitCodes) && 0 < businessUnitCodes?.length
        if (filterByDivision || filterByBusinessUnit) {
            const newSelectedItems = [...selectedItems]
            const productGroupsToRemove = []
            newSelectedItems.forEach((x, i) => {
                if (
                    !(
                        businessUnitCodes.includes(
                            x.organizationInfo?.businessUnit?.code
                        ) ||
                        divisionCodes.includes(
                            x.organizationInfo?.division?.code
                        )
                    )
                ) {
                    productGroupsToRemove.push(i)
                }
            })
            if (0 < productGroupsToRemove?.length) {
                productGroupsToRemove.forEach((i) => {
                    newSelectedItems.splice(i, 1)
                })
                onSelectedItemsChanged(newSelectedItems)
            }
        }
    }, [divisionCodes, businessUnitCodes])
    const handleOnChange = (
        e: ChangeEvent<any>,
        value: Array<string | HrgtLevelFilter>
    ) => {
        setSearchText('')
        onSelectedItemsChanged &&
            onSelectedItemsChanged((value as HrgtLevelFilter[]) ?? [])
    }
    return (
        <Autocomplete
            multiple
            disabled={disabled}
            value={selectedItems ?? []}
            inputValue={searchText}
            onInputChange={(e, value, reason) => {
                if (reason === 'input') {
                    setSearchText(value)
                } else if (reason === 'clear') {
                    setSearchText('')
                }
            }}
            options={options}
            clearOnBlur={true}
            isOptionEqualToValue={getOptionSelected}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            disableClearable={Boolean(disabled)}
            renderOption={(props, { code, name }) => (
                <li {...props}>{`(${code}) ${name ?? ''}`}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    error={error}
                    helperText={helperText}
                    label={`Owner Mngr Lv3`}
                    {...dataTestId('OWNER_MNGR_LV3')}
                    {...other}
                />
            )}
        />
    )
}
