export const MY_ISSUES_LOAD_CALL = 'MY_ISSUES_LOAD_CALL'
export const MY_ISSUES_LOAD_ERROR = 'MY_ISSUES_LOAD_ERROR'
export const MY_ISSUES_LOAD_DONE = 'MY_ISSUES_LOAD_DONE'
export const MY_ISSUES_CLEAR_ERROR = 'MY_ISSUES_CLEAR_ERROR'
export const MY_ISSUES_REFRESH_BY_ID_CALL = 'MY_ISSUES_REFRESH_BY_ID_CALL'
export const MY_ISSUES_REFRESH_BY_ID_ERROR = 'MY_ISSUES_REFRESH_BY_ID_ERROR'
export const MY_ISSUES_REFRESH_BY_ID_DONE = 'MY_ISSUES_REFRESH_BY_ID_DONE'
export const MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL =
    'MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL'
export const MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_DONE =
    'EXTEND_TARGET_RESOLUTION_DATE_DONE'
export const MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_FAIL =
    'MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_FAIL'
export const MY_ISSUES_REMOVE_ALL = 'MY_ISSUES_REMOVE_ALL'
export const MY_ISSUES_REMOVE_BY_ID = 'MY_ISSUES_REMOVE_BY_ID'

export const MY_ISSUES_EXPORT_CALL = 'MY_ISSUES_EXPORT_CALL'
export const MY_ISSUES_EXPORT_DONE = 'MY_ISSUES_EXPORT_DONE'
export const MY_ISSUES_EXPORT_FAIL = 'MY_ISSUES_EXPORT_FAIL'
