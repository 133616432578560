import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { IssueStoreState } from 'store/issue'
import { RootState } from 'store/types'
import { IssueRoles, IssueStatuses } from 'types/enums'

export const useIssueForm = () =>
    useSelector<RootState, IssueStoreState>((store) => store.issue)

export const useCanCreateActionPlanItem = (): boolean => {
    const { context, issueStatusId } = useIssueForm()
    if (Array.isArray(context?.myRoles)) {
        return [
            IssueRoles.Resolution_Owner,
            IssueRoles.Resolution_Owner_Team,
            IssueRoles.PG_Process_Owner,
            IssueRoles.Bu_Process_Owner,
            IssueRoles.Issue_Coordinator,
            IssueRoles.Support_Desk,
        ].some(
            (r) =>
                context.myRoles.includes(r) &&
                [
                    IssueStatuses.In_Process,
                    IssueStatuses.In_Process_Overdue,
                ].includes(issueStatusId)
        )
    }
    return false
}

export const useMinTargetDate = (date: number) => {
    const { captureSection, dates } = useIssueForm()

    const minDate = [IssueStatuses.Draft, IssueStatuses.New].includes(
        captureSection?.issueStatusId
    )
        ? dayjs().utc().endOf('day').addBusinessDays(date)
        : dayjs(dates?.submitDate).utc().endOf('day').addBusinessDays(date)

    return minDate
}
