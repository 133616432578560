import { checkboxClasses } from '@mui/material/Checkbox'
import { radioClasses } from '@mui/material/Radio'
import { alpha, darken } from '@mui/material/styles'
import { pickersYearClasses } from '@mui/x-date-pickers'
import { pickersMonthClasses } from '@mui/x-date-pickers/MonthCalendar'
import { pickersDayClasses } from '@mui/x-date-pickers/PickersDay'
import createAbbTheme, { Shades } from './createAbbTheme'

declare global {
    interface ButtonProps {
        [key: string]: any
    }
}

const fontFamilyBase: string = ['abbvoice', 'sans-serif'].join(', ')

const greys = {
    50: '#fafafa',
    100: '#f0f0f0',
    200: '#d2d2d2',
    300: '#a9a9a9',
    400: '#6e6e6e',
    500: '#9e9e9e',
    600: '#4a4a4a',
    700: '#262626',
}

const shades: Shades = {
    gray06_t: 'rgba(0, 0, 0, .02)',
    gray05_t: 'rgba(0, 0, 0, .02)',
    red05_t: 'rgba(255, 0, 15, .05)',
    red10_t: 'rgba(255, 0, 15, .1)',
    red80_t: 'rgba(255, 0, 15, .8)',
    red1_10: '#e5000d',
}

const fontWeights: { [key: string]: number } = {
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
}

const mainColor: string = '#ff000f'
const whiteBase: string = '#fff'
const developmentColor: string = 'rgb(150 184 255)'
const stageColor: string = 'rgb(176 255 209)'
const localColor: string = 'rgb(255 242 182)'
const blackColor: string = '#000000'

const spacing = (x: number): number => 8 * x

const theme = createAbbTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 768,
            md: 1200,
            lg: 1400,
            xl: 1920,
        },
    },
    palette: {
        primary: { main: greys[600] },
        secondary: {
            light: alpha(mainColor, 0.64),
            main: mainColor,
            dark: darken(mainColor, 0.34),
        },
        background: { paper: whiteBase, default: whiteBase },
        error: {
            light: alpha(mainColor, 0.12),
            main: mainColor,
            dark: darken(mainColor, 0.34),
            contrastText: mainColor,
        },
        blue: { main: 'rgb(0, 76, 151)', light: 'rgba(0, 76, 151, .34)' },
        green: { main: 'rgb(0, 122, 51)', light: 'rgba(0, 122, 51, .12)' },
        yellow: {
            main: 'rgb(255, 209, 0)',
            light: 'rgba(255, 209, 0, .12)',
        },
        red: { main: mainColor, light: 'rgba(255, 0, 15, .12)' },
        grey: { ...greys },
        shades: { ...shades },
        text: {
            primary: '#000',
            secondary: '#4a4a4a',
            disabled: '#a9a9a9',
            // hint: '#a9a9a9',
        },
    },
    typography: {
        fontFamily: fontFamilyBase,
        fontSize: 16,
        fontWeightLight: fontWeights.light,
        fontWeightRegular: fontWeights.regular,
        fontWeightMedium: fontWeights.medium,
        fontWeightBold: fontWeights.bold,
        subtitle1: {
            fontWeight: fontWeights.medium,
        },
        subtitle2: {
            fontWeight: fontWeights.medium,
        },
        h1: {
            fontSize: '2rem',
            lineHeight: '2.5rem',
            fontWeight: fontWeights.medium,
        },
        h2: {
            fontSize: '1.75rem',
            lineHeight: '2.25rem',
            fontWeight: fontWeights.medium,
        },
        h3: {
            fontSize: '1.5rem',
            lineHeight: '2rem',
            fontWeight: fontWeights.medium,
        },
        h4: {
            fontSize: '1.25rem',
            lineHeight: '1.75rem',
            fontWeight: fontWeights.medium,
        },
        h5: {
            fontSize: '1.125rem',
            lineHeight: '1.5rem',
            fontWeight: fontWeights.medium,
        },
        h6: {
            fontSize: '1rem',
            lineHeight: '1.25rem',
            fontWeight: fontWeights.medium,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: '1.5rem',
        },
        body2: {
            fontSize: '0.875rem',
            lineHeight: '1.25rem',
        },
        caption: {
            fontSize: '0.75rem',
            lineHeight: '1rem',
        },
        button: {
            fontSize: '0.875rem',
            lineHeight: '1rem',
        },
    },
    components: {
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    maxHeight: 'none !important',
                    height: 'auto !important',
                },
            },
        },
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#000',
            },
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    minHeight: 100,
                    height: 100,
                    color: whiteBase,
                    backgroundColor: blackColor,
                    marginTop: 0,
                },
                switchViewIcon: {
                    color: whiteBase,
                },
                label: {
                    fontSize: '1.2rem',
                },
            },
        },
        MuiPickersArrowSwitcher: {
            styleOverrides: {
                button: {
                    color: whiteBase,
                },
            },
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    fontSize: '0.875rem !important',
                },
                weekContainer: {
                    marginBottom: 2,
                },
            },
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem !important',
                    [`&.${pickersDayClasses.selected}`]: {
                        backgroundColor: `${mainColor} !important`,
                    },
                    [`&.${pickersDayClasses.today}`]: {
                        borderColor: greys[200],
                    },
                },
            },
        },
        MuiPickersMonth: {
            styleOverrides: {
                monthButton: {
                    [`&.${pickersMonthClasses.selected}`]: {
                        backgroundColor: `${mainColor} !important`,
                    },
                    [`&.${pickersMonthClasses.disabled}`]: {
                        color: `${greys[200]} !important`,
                    },
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                yearButton: {
                    [`&.${pickersYearClasses.selected}`]: {
                        backgroundColor: `${mainColor} !important`,
                    },
                    [`&.${pickersYearClasses.disabled}`]: {
                        color: `${greys[200]} !important`,
                    },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h4: {
                    fontFamily: fontFamilyBase,
                },
                body2: {
                    fontFamily: fontFamilyBase,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: fontFamilyBase,
                    fontSize: 14,
                },
                textPrimary: {
                    color: blackColor,
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    // padding: 3,
                    color: blackColor,
                },
                sizeSmall: {
                    padding: 3,
                },
            },
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    color: greys[700],
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                    color: greys[700],
                    fontWeight: fontWeights.medium,
                    paddingLeft: spacing(2),
                    paddingRight: spacing(2),
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: spacing(2),
                    paddingRight: spacing(2),
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: greys[600],
                    fontWeight: fontWeights.regular,
                    marginBottom: spacing(3),
                },
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingLeft: spacing(1),
                    paddingRight: spacing(1),
                },
            },
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    backgroundColor: greys[700],
                    borderRadius: 0,
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    marginBottom: spacing(2),
                },
            },
            defaultProps: {
                fullWidth: true,
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontFamily: fontFamilyBase,
                    fontWeight: 500,
                    transform: 'translate(0, 1.5px) scale(0.75) !important',
                },
                asterisk: {
                    color: mainColor,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    paddingTop: '0',
                    paddingBottom: '0',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: whiteBase,
                },
                colorDefault: {
                    backgroundColor:
                        process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT'
                            ? developmentColor
                            : process.env.REACT_APP_ENVIRONMENT === 'STAGE'
                            ? stageColor
                            : process.env.REACT_APP_ENVIRONMENT === 'LOCAL'
                            ? localColor
                            : whiteBase,
                },
            },
            defaultProps: {
                elevation: 2,
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    [`&.${checkboxClasses.checked}`]: {
                        color: `${mainColor} !important`,
                    },
                    '&.Mui-disabled': {
                        color: `${greys[200]} !important`,
                    },
                },
                disabled: {},
            },
        },
        MuiChip: {
            styleOverrides: {
                label: {
                    overflow: null,
                    overflowX: 'clip',
                },
                outlined: {
                    borderColor: '#0000003b',
                },
                deleteIcon: {
                    color: greys[400],
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        backgroundColor: greys[50],
                    },
                    '&.Mui-focused': {
                        backgroundColor: greys[50],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                        color: greys[600],
                    },
                    marginTop: 20,
                    fontSize: 16,
                    lineHeight: '22px',
                    // padding: '8px 12px',
                    border: `1px solid ${greys[100]}`,
                    borderRadius: '2px',
                    backgroundColor: 'transparent',
                },
                notchedOutline: {
                    border: 0,
                },
                input: {
                    backgroundColor: 'transparent',
                    height: 22,
                    paddingTop: spacing(1),
                    paddingBottom: spacing(1),
                    paddingLeft: 12,
                    paddingRight: spacing(1),
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                icon: {
                    right: spacing(2),
                },
                select: {
                    backgroundColor: 'inherit !important',
                },
                standard: {
                    margin: '0 !important',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontSize: '0.875rem',
                    lineHeight: '1.25rem',
                    fontWeight: fontWeights.bold,
                    '&.Mui-disabled': {
                        color: greys[600],
                    },
                },
                asterisk: {
                    color: mainColor,
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginRight: 0,
                },
                label: {
                    '@media (max-width: 480px)': {
                        fontSize: 14,
                    },
                    '&.Mui-disabled': {
                        color: greys[300],
                    },
                },
                disabled: {},
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    position: 'relative',
                    fontWeight: fontWeights.medium,
                },
                contained: {
                    marginLeft: 0,
                    marginRight: 0,
                },
            },
        },

        MuiFilledInput: {
            styleOverrides: {
                root: {
                    minHeight: 40,
                    marginTop: 20,
                    borderRadius: '2px !important',
                    border: `1px solid rgba(0,0,0,0.34)`,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: greys[50],
                    },
                    '&.Mui-focused': {
                        backgroundColor: greys[50],
                    },
                    '&.Mui-disabled': {
                        backgroundColor: 'transparent',
                        color: greys[300],
                    },
                    '&.Mui-error': {
                        border: `1px solid ${mainColor}`,
                    },
                },
                multiline: {
                    paddingTop: spacing(1),
                    paddingBottom: spacing(1),
                    paddingLeft: spacing(1.5),
                    paddingRight: spacing(1.5),
                },
                input: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                underline: {
                    '&::before': {
                        display: 'none',
                    },
                    '&::after': {
                        display: 'none',
                    },
                },
                adornedStart: {
                    paddingLeft: 0,
                },
                adornedEnd: {
                    paddingRight: 0,
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 11,
                    letterSpacing: 0.5,
                    lineHeight: 1.5,
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    color: `${mainColor} !important`,
                    [`.Mui-checked.Mui-checked + &`]: {
                        backgroundColor: mainColor,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    [`&.${radioClasses.checked}`]: {
                        color: mainColor,
                    },
                    [`&.${radioClasses.disabled}`]: {
                        color: greys[200],
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                expandIconWrapper: {
                    color: blackColor,
                },
            },
        },
    },

    // props: {
    //     MuiTextField: {
    //         fullWidth: true,
    //     },
    //     MuiAppBar: {
    //         elevation: 2,
    //     },
    //     MuiFormHelperText: {
    //         onClick: (e) => {
    //             e.stopPropagation()
    //         },
    //     },
    // },
})

export default theme
