import { Typography } from '@mui/material'
import { CANNOT_LOAD_PAGE } from '../../consts/selectorIds'
import RoundedButton from '../Base/RoundedButton'
import { GenericErrorSvg } from '../Images'
import useStyles from './styles'

export interface CannotLoadProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title?: string
    subTitle?: string
    buttonLabel?: string
    errorHandler: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const CannotLoad = (props: CannotLoadProps) => {
    const classes = useStyles(props)

    let { title, subTitle, buttonLabel, errorHandler } = props
    title = title ? title : 'Cannot load issue!'
    subTitle = subTitle ? subTitle : 'Try again in a moment.'
    buttonLabel = buttonLabel ? buttonLabel : 'Try again'
    return (
        <div className={classes.content}>
            <GenericErrorSvg />
            <section
                className={classes.info}
                {...CANNOT_LOAD_PAGE.INFO_SECTION}
            >
                <Typography variant="h5" {...CANNOT_LOAD_PAGE.HEADLINE}>
                    {title}
                </Typography>
                <Typography
                    variant="body1"
                    component="p"
                    {...CANNOT_LOAD_PAGE.SUB_HEADLINE}
                >
                    {subTitle}
                </Typography>
            </section>
            {errorHandler && (
                <RoundedButton
                    color="secondary"
                    variant="contained"
                    onClick={errorHandler}
                    {...CANNOT_LOAD_PAGE.TRY_AGAIN_BUTTON}
                >
                    {buttonLabel}
                </RoundedButton>
            )}
        </div>
    )
}

export default CannotLoad
