import {
    BusinessCenterOutlined,
    FlagOutlined,
    FolderSharedOutlined,
} from '@mui/icons-material'
import {
    AccordionActions,
    Button,
    CircularProgress,
    InputBaseProps,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CustomerSearchList } from 'Admin'
import { getRecentlyCustomers, searchCustomers } from 'api/customers'
import {
    CitySelect,
    Content,
    CountrySelect,
    ListTitle,
    NoResults,
    SearchPanel,
    TextField,
} from 'components'
import { CUSTOMER_PICKER } from 'consts/selectorIds'
import { PickerAppBar } from 'Issue'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AbbTheme } from 'styles/createAbbTheme'
import { Customer } from 'types/issueTypes'
import { FilterBase } from 'types/models'
import { CustomerQuery } from 'types/queries'
import { dataTestId } from 'utils'

const parseCustomerData = (
    query: CustomerSearchBaseQuery,
    isSupplier: boolean = false
): CustomerQuery => {
    const countryCode = query.country?.code ? query.country?.code : null
    const city = query.city?.name ? query.city?.name : null
    const address = query.address ? query.address : null
    const guid = query.guid ? query.guid : null
    const globalHqGuid = query.globalHqGuid ? query.globalHqGuid : null
    const name = query.name ? query.name : null
    return { name, address, countryCode, city, guid, globalHqGuid, isSupplier }
}

const getInitQuery: () => CustomerSearchBaseQuery = () => ({
    name: '',
    address: '',
    country: null,
    city: null,
    globalHqGuid: '',
    guid: '',
})

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginBottom: theme.spacing(2),
        },
        loader: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(10, 0),
        },
        results: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(2, 0),
        },
        loadMore: {
            display: 'inline-flex',
            height: 56,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        icon: {
            margin: theme.spacing(0, 1),
        },
        resultTitle: {
            '@media (max-width: 768px)': {
                marginLeft: theme.spacing(2),
            },
        },
    }),
    { name: 'CustomerSearchBase' }
)

export interface CustomerState {
    results: Customer[]
    recent?: boolean
}

export const PAGE_SIZE = 40

export interface CustomerSearchBaseProps {
    isSupplier: boolean
    onConfirm: (customer: Customer) => void
    searchPlaceholder: string
    title: string
    pageTitle: string
    InputBaseProps?: Partial<InputBaseProps>
}

export interface CustomerSearchBaseQuery {
    name: string
    address: string
    country: FilterBase
    city: FilterBase
    guid: string
    globalHqGuid: string
}

const CustomerSearchBase = (props: CustomerSearchBaseProps) => {
    const {
        isSupplier,
        searchPlaceholder,
        onConfirm,
        title,
        pageTitle,
        InputBaseProps,
    } = props

    const dispatch = useDispatch()

    const [results, setResults] = useState<CustomerState>(null)
    const [searching, setSearching] = useState<boolean>(false)
    const [searchingMore, setSearchingMore] = useState<boolean>(false)
    const [error, setError] = useState(null)
    const [page, setPage] = useState(2)
    const [query, setQuery] = useState<CustomerSearchBaseQuery>(getInitQuery())

    const classes = useStyles(props)

    const resetForm = () => {
        setQuery(getInitQuery())
        loadRecents()
    }

    const loadRecents = useCallback(async () => {
        setSearching(true)
        try {
            const { data } = await getRecentlyCustomers()
            setResults({ results: data, recent: true })
            setSearching(false)
        } catch (err) {
            setError(err)
            setSearching(false)
        }
    }, [])

    useEffect(() => {
        loadRecents()
    }, [])

    const handleSearch = async () => {
        setPage(2)
        if (searching) {
            return
        }
        const newQuery = parseCustomerData(query, isSupplier)
        setSearching(true)
        try {
            const res = await searchCustomers({
                ...newQuery,
                page: 1,
                pageSize: PAGE_SIZE,
            })
            setResults({ results: res.data, recent: false })
            setSearching(false)
        } catch (error: any) {
            setSearching(false)
            if (error?.status === 0) {
                setError(error)
            } else {
                dispatch(showSnackbar(error))
            }
        }
    }

    const handleLoadMoreResults = async () => {
        const newQuery = parseCustomerData(query, isSupplier)
        setSearchingMore(true)
        try {
            const res = await searchCustomers({
                ...newQuery,
                page: page,
                pageSize: PAGE_SIZE,
            })
            setPage((page) => page + 1)
            setResults((prev) => ({
                results: [...prev.results, ...res.data],
                recent: false,
            }))
            setSearchingMore(false)
        } catch (error) {
            setSearchingMore(false)
        }
    }

    const handleChange =
        (key: keyof CustomerSearchBaseQuery) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setQuery((prev: CustomerSearchBaseQuery) => ({
                ...prev,
                [key]: e.target.value,
            }))
        }

    const isSearching = searching || searchingMore

    return (
        <>
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <PickerAppBar title={title} />
            <Content
                error={error}
                coverBackground={true}
                onRetry={() => setError(null)}
                {...dataTestId('CUSTOMER_PICKER_CONTENT')}
            >
                <SearchPanel
                    initOpen={true}
                    placeholder={searchPlaceholder}
                    onEnter={() => !searching && handleSearch()}
                    searchText={query.name}
                    onChange={(name) => {
                        setQuery((prev) => ({ ...prev, name }))
                    }}
                    onClearText={() =>
                        setQuery((prev) => ({ ...prev, name: '' }))
                    }
                    disabled={isSearching}
                    InputBaseProps={{ ...InputBaseProps }}
                >
                    <CountrySelect
                        disabled={isSearching}
                        selectedCountry={query.country}
                        onCountryChanged={(item) => {
                            setQuery((prev) => ({
                                ...prev,
                                country: { ...item },
                            }))
                        }}
                        {...dataTestId('CUSTOMER_PICKER_COUNTRY')}
                    />
                    <CitySelect
                        disabled={isSearching}
                        country={query.country}
                        selectedCity={query.city}
                        onCityChanged={(item) => {
                            setQuery((prev) => ({
                                ...prev,
                                city: { ...item },
                            }))
                        }}
                        {...dataTestId('CUSTOMER_PICKER_CITY')}
                    />

                    <TextField
                        {...CUSTOMER_PICKER.ADDRESS_INPUT}
                        label="Address"
                        value={query?.address}
                        onChange={handleChange('address')}
                        disabled={isSearching}
                        startAdornment={
                            <FlagOutlined
                                fontSize="small"
                                className={classes.icon}
                            />
                        }
                    />
                    <TextField
                        {...CUSTOMER_PICKER.GLOBAL_HQ_INPUT}
                        label="Global HQ Guid"
                        value={query?.globalHqGuid}
                        onChange={handleChange('globalHqGuid')}
                        disabled={isSearching}
                        startAdornment={
                            <BusinessCenterOutlined
                                fontSize="small"
                                className={classes.icon}
                            />
                        }
                    />

                    <TextField
                        {...CUSTOMER_PICKER.BUSINES_PARTNER_INPUT}
                        label="Business Partner GUID"
                        value={query?.guid}
                        onChange={handleChange('guid')}
                        disabled={isSearching}
                        startAdornment={
                            <FolderSharedOutlined
                                fontSize="small"
                                className={classes.icon}
                            />
                        }
                    />
                    <AccordionActions>
                        <Button
                            {...CUSTOMER_PICKER.ACTION_BUTTONS.CLEAR}
                            onClick={resetForm}
                            disabled={isSearching}
                        >
                            Clear
                        </Button>
                        <Button
                            {...CUSTOMER_PICKER.ACTION_BUTTONS.SEARCH}
                            onClick={handleSearch}
                            color="secondary"
                            disabled={isSearching}
                        >
                            Search
                        </Button>
                    </AccordionActions>
                </SearchPanel>
                <section {...CUSTOMER_PICKER.RESULTS_LIST.CONTAINER}>
                    {searching ? (
                        <div className={classes.loader}>
                            <CircularProgress color="secondary" size={36} />
                        </div>
                    ) : (
                        <div className={classes.results}>
                            {results && 0 < results.results.length && (
                                <ListTitle
                                    className={classes.resultTitle}
                                    title={
                                        results.recent
                                            ? 'Recently used'
                                            : 'Search results'
                                    }
                                    count={results?.results.length}
                                />
                            )}
                            {results && 0 < results.results.length ? (
                                <CustomerSearchList
                                    onSelect={onConfirm}
                                    results={results.results}
                                    searchingMore={searchingMore}
                                    handleLoadMoreResults={
                                        handleLoadMoreResults
                                    }
                                />
                            ) : (
                                results !== null && (
                                    <NoResults
                                        title="No results!"
                                        subTitle="Change search criteria try again."
                                    />
                                )
                            )}
                        </div>
                    )}
                </section>
            </Content>
        </>
    )
}

CustomerSearchBase.defaultProps = {
    isSupplier: false,
    InputBaseProps: {},
}

export default CustomerSearchBase
