import { Close } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AdminCustomer } from 'api'
import clsx from 'clsx'
import { Chip } from 'components'
import { HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
            width: '100%',
            minHeight: 48,
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        chips: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginLeft: theme.spacing(2),
        },
        clearButton: {
            marginRight: theme.spacing(1),
        },
        divider: {
            display: 'block',
            width: 1,
            height: 24,
            borderLeft: `1px solid ${theme.palette.divider}`,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }),
    { name: 'SelectedCustomersField' }
)

export interface SelectedCustomersFieldProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    selectedItems?: AdminCustomer[]
    onClear?: () => void
    onDelete?: (guid: string) => void
}

export const SelectedCustomersField = (props: SelectedCustomersFieldProps) => {
    const { className, selectedItems, onClear, onDelete, ...other } = props
    const classes = useStyles()
    const notEmpty =
        Array.isArray(selectedItems) && Boolean(selectedItems.length)
    return (
        <div className={clsx(classes.root, className)} {...other}>
            <div className={classes.chips}>
                {notEmpty
                    ? selectedItems.map((c) => (
                          <Chip
                              className={classes.chip}
                              key={c.guid}
                              label={c.name}
                              onDelete={() => onDelete && onDelete(c.guid)}
                          />
                      ))
                    : 'No selected customers...'}
            </div>
            {notEmpty && (
                <Tooltip title="Clear selection">
                    <IconButton
                        className={classes.clearButton}
                        onClick={() => onClear && onClear()}
                        size="large"
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </Tooltip>
            )}
        </div>
    )
}
