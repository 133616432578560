import { NavButton } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { TileActionsProps } from '..'

export const ToResolveActions = (props: TileActionsProps) => {
    const { myActionMeta } = props
    return (
        <>
            <NavButton
                color="secondary"
                linkProps={{ to: `/issue/${myActionMeta.issueId}` }}
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.RESOLVE}
                eventName="Resolve issue dashboard"
            >
                To Resolve
            </NavButton>
        </>
    )
}
