import ExpandMore from '@mui/icons-material/ExpandMore'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FlexGrow } from 'components'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        section: {
            marginBottom: `${theme.spacing(4)} !important`,
        },
        card: {
            display: 'block',
            boxSizing: 'border-box',
            width: '100%',
            height: '128px',
            flexDirection: 'column',
            padding: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            width: '100%',
            height: '56px',
            backgroundColor: theme.palette.common.white,
        },
        icon: {
            color: theme.palette.grey[100],
        },
        bigLine: {
            height: 24,
            backgroundColor: theme.palette.grey[100],
            animation: 'pulse 1s infinite ease-in-out',
        },
        smallLine: {
            height: 18,
            marginTop: theme.spacing(2),
            backgroundColor: theme.palette.grey[100],
            animation: 'pulse 1s infinite ease-in-out',
        },
    }),
    { name: 'IssuePlaceholder' }
)

export const IssuePlaceholder = () => {
    const classes = useStyles()
    return (
        <>
            <div className={clsx(classes.card, classes.section)}>
                <div className={classes.bigLine} style={{ width: 32 + '%' }} />
                <div
                    className={classes.smallLine}
                    style={{ width: 80 + '%' }}
                />
                <div
                    className={classes.smallLine}
                    style={{ width: 64 + '%' }}
                />
            </div>
            {[43, 36, 63, 48, 50].map((x, i) => (
                <div key={i} className={clsx(classes.header, classes.section)}>
                    <div
                        className={classes.bigLine}
                        style={{ width: `${x}%` }}
                    />
                    <FlexGrow />
                    <ExpandMore />
                </div>
            ))}
        </>
    )
}

export default IssuePlaceholder
