import { makeStyles } from '@mui/styles'
import { useEffect, useState } from 'react'

import KeyBoardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const BUTTON_HEIGHT = 24

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        SmallButton: {
            position: 'fixed',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: theme.palette.red.main,
            color: theme.palette.common.white,
            bottom: theme.spacing(6),
            opacity: 0,
            left: '50%',
            height: BUTTON_HEIGHT,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(2),
            boxShadow: theme.shadows[3],
            borderRadius: BUTTON_HEIGHT / 2,
            transform: 'translateX(-50%)',
            textTransform: 'none',
            fontWeight: theme.typography.fontWeightMedium as number,
            zIndex: theme.zIndex.drawer,
        },
        IsVisible: {
            opacity: 1,
        },
        Icon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'GoTopButton' }
)

function handleScrollTop() {
    const offset = document.documentElement.scrollTop || document.body.scrollTop
    if (offset > 0) {
        window.requestAnimationFrame(handleScrollTop)
        window.scrollTo(0, offset - offset / 2)
    }
}

export const GoTopButton = () => {
    const classes = useStyles()
    const [isVisible, setVisibility] = useState(false)
    useEffect(() => {
        function handleScrollChange() {
            setVisibility(window.innerHeight * 1.5 < window.scrollY)
        }
        window.addEventListener('scroll', handleScrollChange)
        return () => window.removeEventListener('scroll', handleScrollChange)
    }, [])
    return (
        <div
            className={clsx(
                classes.SmallButton,
                isVisible ? classes.IsVisible : null
            )}
            onClick={handleScrollTop}
        >
            <KeyBoardArrowUp className={classes.Icon} />
            <span>Go top</span>
        </div>
    )
}

export default GoTopButton
