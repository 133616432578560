import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { rejectResolutionToRO } from 'api'
import { showSnackbar } from 'store/app'
import {
    rejectResolutionDone,
    rejectResolutionFail,
    removeMyActionById,
} from '../actions'
import { MY_ACTIONS_REJECT_RESOLUTION_CALL } from '../actionTypes'
import { RejectResolutionCommand } from '../types'

export function* rejectResolutionSaga() {
    yield takeLatest(
        MY_ACTIONS_REJECT_RESOLUTION_CALL,
        function* (action: RejectResolutionCommand) {
            const cancelSource = axios.CancelToken.source()
            try {
                const { query, actionType } = action.payload
                yield call(rejectResolutionToRO, query, cancelSource.token)
                yield put(rejectResolutionDone())
                yield put(showSnackbar('Resolution was rejected'))
                yield put(removeMyActionById(query.issueId, actionType))
            } catch (error) {
                yield put(rejectResolutionFail(error))
                yield put(showSnackbar('Reject resolution fail', true))
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
