import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { checkCharactersLimit } from 'Issue/Sections/utils'
import { ReactNode, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles((theme: AbbTheme) => ({
    root: { position: 'relative' },
    length: {
        position: 'absolute',
        right: 0,
        bottom: 0,
    },
}))

export interface TextFieldLimitProps {
    disabled: boolean
    currentLength: number
    maxLength: number
    children: ReactNode
}

export const TextFieldLimit = (props: TextFieldLimitProps) => {
    const classes = useStyles()
    const { children, currentLength = 0, maxLength, disabled } = props

    const isExceededLimit = useMemo(
        () => checkCharactersLimit(currentLength, maxLength),
        [currentLength, maxLength]
    )

    return (
        <div className={classes.root}>
            {children}
            <Typography
                className={classes.length}
                variant="caption"
                color={isExceededLimit && !disabled ? 'error' : 'inherit'}
            >
                {currentLength}/{maxLength}
            </Typography>
        </div>
    )
}
