import Button from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import Axios from 'axios'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Content, TextField } from '../components'
import { OVERRIDE_TOKEN_KEY } from '../configs/authProvider'
import { showSnackbar } from '../store/app'
import { AbbTheme } from 'styles/createAbbTheme'

interface FakeAccountData {
    email?: string
    givenName: string
    familyName: string
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        token: {
            margin: theme.spacing(4, 0, 2, 0),
            wordBreak: 'break-word',
        },
        content: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
        },
    }),
    { name: 'TokenGenrator' }
)

const TokenGenerator = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [formData, setFormData] = useState<FakeAccountData>({
        email: '',
        givenName: '',
        familyName: '',
    })
    const [token, setToken] = useState<string>(null)
    const handleGenrateToken = async () => {
        try {
            const { data } = await Axios.post('/tokengenerator', {
                ...formData,
            })
            setToken(data)
        } catch (error) {
            dispatch(showSnackbar('Cannot generate token', true))
        }
    }
    const handleSaveToken = () => {
        localStorage.setItem(OVERRIDE_TOKEN_KEY, token)
        dispatch(showSnackbar('Token was saved', false))
    }
    const handleCopyToken = async () => {
        await navigator.clipboard.writeText(token)
        dispatch(showSnackbar('Token was copied', false))
    }
    return (
        <Content coverBackground={true}>
            <div className={classes.content}>
                <TextField
                    label="Email"
                    required
                    fullWidth
                    value={formData.email}
                    onChange={(e) => {
                        setFormData((prev) => ({
                            ...prev,
                            email: e.target.value,
                        }))
                    }}
                />
                <TextField
                    label="Given name"
                    required
                    fullWidth
                    value={formData.givenName}
                    onChange={(e) => {
                        setFormData((prev) => ({
                            ...prev,
                            givenName: e.target.value,
                        }))
                    }}
                />
                <TextField
                    label="Family name"
                    required
                    fullWidth
                    value={formData.familyName}
                    onChange={(e) => {
                        setFormData((prev) => ({
                            ...prev,
                            familyName: e.target.value,
                        }))
                    }}
                />
                <Button onClick={handleGenrateToken}>Generate token</Button>
                {token && (
                    <>
                        <div className={classes.token}>{token}</div>
                        <span>
                            <Button onClick={handleSaveToken}>
                                Save token in local storage
                            </Button>
                            <Button onClick={handleCopyToken}>
                                Copy token
                            </Button>
                        </span>
                    </>
                )}
            </div>
        </Content>
    )
}

export default TokenGenerator
