import { makeStyles } from '@mui/styles'
import * as api from 'api/index'
import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { ExpandedIssueState } from 'store/issue/initState'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { AttachmentTypes, IssueTypes } from 'types/enums'
import { Issue } from 'types/issueTypes'
import { Content, GoTopButton } from '../components'
import { PreviewLinkRcaParams } from '../types/urlParams'
import IssuePlaceholder from './IssuePlaceholder'
import Assigment from './Sections/Assignment'
import Capture from './Sections/Capture'
import Closing from './Sections/Closing'
import Effectiveness from './Sections/EffectivenessAndSustainability'
import Resolution from './Sections/Resolution/Resolution'
import Summary from './Sections/Summary'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            width: 768,
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingBottom: 56,
            '@media (max-width: 768px)': {
                width: '100%',
            },
        },
        section: {
            marginBottom: `${theme.spacing(4)} !important`,
        },
    }),
    { name: 'PreviewIssueView' }
)

export const PreviewIssueView = (props: any) => {
    const [state, setState] = useState<{
        loading: boolean
        issue: Issue
        error: any
    }>({
        loading: true,
        issue: null,
        error: null,
    })
    const [expanded, setExpanded] = useState<ExpandedIssueState>({
        capture: true,
        assign: false,
        resolve: false,
        closed: false,
        effectiveness: false,
    })
    const history = useHistory()
    const params = useParams<PreviewLinkRcaParams>()

    const classes = useStyles(props)
    const {
        issue: { issueId },
        linkRca,
    } = useSelector<RootState, RootState>((state) => state)
    const { fullLoadingDesc, copyingRca, error } = linkRca
    const { issue, loading } = state
    const contentLoaderDesc = copyingRca
        ? fullLoadingDesc
        : 'Loading issue preview…'
    const title = issue ? issue.issueNumber : null
    const { previewId, id: issueIdFromUrl } = params
    useEffect(() => {
        const load = async () => {
            try {
                if (issueId) {
                    const { data: issue } = await api.getIssue(previewId)
                    setState((prev) => ({
                        ...prev,
                        issue,
                        loading: false,
                    }))
                } else {
                    if (issueIdFromUrl) {
                        history.replace(`/issue/${issueIdFromUrl}`)
                    } else {
                        history.replace('/dashboard')
                    }
                }
            } catch (error) {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                    error,
                }))
            }
        }
        load()
    }, [history, issueId, issueIdFromUrl, previewId])
    const attachments = issue?.attachments ?? []
    const issueType = issue?.captureSection?.issueType || IssueTypes.External
    return (
        <Content
            loading={loading || copyingRca}
            loadingDesc={contentLoaderDesc}
            error={error}
            variant={'medium'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <div className={classes.content}>
                {issue ? (
                    <>
                        <Summary
                            number={issue.issueNumber}
                            subject={issue.captureSection.subject}
                            issueStatus={issue.issueStatusId}
                            roles={null}
                            className={classes.section}
                            disabledActionLogs={true}
                        />
                        <Capture
                            expanded={expanded.capture}
                            attachments={attachments.filter(
                                (a) =>
                                    a.attachmentType ===
                                    AttachmentTypes.Information
                            )}
                            onExpand={(e: boolean) =>
                                setExpanded((prev) => ({ ...prev, capture: e }))
                            }
                            captureSection={issue.captureSection}
                            className={classes.section}
                            disabled
                        />

                        <Assigment
                            expanded={expanded.assign}
                            issueType={issueType}
                            onExpand={(e: boolean) =>
                                setExpanded((prev) => ({
                                    ...prev,
                                    assign: e,
                                }))
                            }
                            assignSection={issue.assignSection}
                            className={classes.section}
                            disabled
                        />

                        <Resolution
                            issueType={issueType}
                            expanded={expanded.resolve}
                            attachments={attachments.filter(
                                (a) =>
                                    a.attachmentType ===
                                    AttachmentTypes.Issue_Resolution
                            )}
                            rcaAttachments={attachments.filter(
                                (a) =>
                                    a.attachmentType ===
                                    AttachmentTypes.Resolution_Rca
                            )}
                            onExpand={(e: boolean) =>
                                setExpanded((prev) => ({
                                    ...prev,
                                    resolve: e,
                                }))
                            }
                            resolvingUnitId={
                                issue?.captureSection?.resolvingUnitId
                            }
                            resolutionSection={issue.resolutionSection}
                            className={classes.section}
                            disabled
                        />

                        <Closing
                            issueType={issueType}
                            expanded={expanded.closed}
                            issueNumber={issue.issueNumber}
                            captureSection={issue.captureSection}
                            attachments={attachments.filter(
                                (a) =>
                                    a.attachmentType ===
                                    AttachmentTypes.Acceptance
                            )}
                            onExpand={(e: boolean) =>
                                setExpanded((prev) => ({
                                    ...prev,
                                    closed: e,
                                }))
                            }
                            closingSection={issue.closingSection}
                            className={classes.section}
                            disabled
                        />

                        <Effectiveness
                            issueNumber={issue.issueNumber}
                            expanded={expanded.effectiveness}
                            attachments={attachments.filter(
                                (a) =>
                                    a.attachmentType ===
                                    AttachmentTypes.Effectiveness
                            )}
                            onExpand={(e: boolean) =>
                                setExpanded((prev) => ({
                                    ...prev,
                                    effectiveness: e,
                                }))
                            }
                            effectivenessSection={issue.effectivenessSection}
                            disabled
                            issueStatusId={issue.issueStatusId}
                        />
                        <GoTopButton />
                    </>
                ) : (
                    <IssuePlaceholder />
                )}
            </div>
        </Content>
    )
}

export default PreviewIssueView
