import { Typography } from '@mui/material'
import { AbbTypography } from 'components'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SurveyTranslationContext, SurveyTranslationContextProps } from '..'
import { useStyles } from '../Survey.style'

export const CannotLoadSurvey = () => {
    const classes = useStyles()
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    return (
        <>
            <Helmet>
                <title>
                    {t(
                        'Survey_NotExistHeader',
                        'Could not load closure confirmation'
                    )}
                </title>
            </Helmet>
            <AbbTypography variant="h2">
                {t(
                    'Survey_NotExistHeader',
                    'Could not load closure confirmation'
                )}
            </AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                {t(
                    'Survey_NotExistsMessage',
                    'Unable to load closure confirmation. Please check if the URL you are accessing is the same as in the email you have received.'
                )}
            </Typography>
        </>
    )
}
