import { Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { RoundedButton } from 'components'
import { CONTENT_ERROR_PAGE } from '../../../../consts/selectorIds'
import ContentFixed from './ContentFixed'
import ContentOverlay from './ContentOverlay'
import { ContentErrorProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        cover: {},
        image: {
            margin: '0 auto',
            textAlign: 'center',
        },
        retry: {
            width: 'auto',
            marginTop: theme.spacing(4),
            margin: '0 auto',
        },
    }),
    { name: 'ContentError' }
)

export const ContentError = (props: ContentErrorProps) => {
    const { label, state, handleRetry, coverBackground, onNotFound } = props
    const classes = useStyles()
    const theme = useTheme()

    let button = (
        <RoundedButton
            className={classes.retry}
            color="secondary"
            variant="contained"
            onClick={handleRetry}
            {...CONTENT_ERROR_PAGE.RETRY_BUTTON}
        >
            {label ?? 'Retry'}
        </RoundedButton>
    )

    if (state.notFound) {
        button = (
            <RoundedButton
                className={classes.retry}
                color="secondary"
                variant="contained"
                onClick={() => {
                    onNotFound && onNotFound()
                }}
                {...CONTENT_ERROR_PAGE.GO_TO_DASHBOARD_BUTTON}
            >
                {'Go to dashboard'}
            </RoundedButton>
        )
    }

    return (
        <>
            <ContentOverlay
                className={coverBackground && classes.cover}
                {...CONTENT_ERROR_PAGE.OVERLAY}
            />
            <ContentFixed {...CONTENT_ERROR_PAGE.CONTAINER}>
                <div className={classes.image}>{state.image}</div>
                <Typography variant="h3" {...CONTENT_ERROR_PAGE.HEADLINE}>
                    {state.title}
                </Typography>
                {state.subTitle && (
                    <Typography
                        style={{ marginTop: theme.spacing(2) }}
                        variant="body1"
                        {...CONTENT_ERROR_PAGE.SUB_HEADLINE}
                    >
                        {state.subTitle}
                    </Typography>
                )}
                {state.referenceId && (
                    <Typography
                        style={{
                            marginTop: theme.spacing(2),
                            fontWeight: theme.typography
                                .fontWeightBold as number,
                        }}
                        variant="caption"
                        component="div"
                        {...CONTENT_ERROR_PAGE.REFERENCE}
                    >
                        {state.referenceId}
                    </Typography>
                )}
                {button}
            </ContentFixed>
        </>
    )
}

export default ContentError
