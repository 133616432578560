import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { IssueTypes } from 'types/enums'
import { FilterBase } from '../types/models'

export const useIssueTypeMap = (): Map<IssueTypes, string> => {
    const issueTypesResources = useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.issueType
    )
    return new Map<IssueTypes, string>(
        issueTypesResources.map((r) => [Number(r.code) as IssueTypes, r.name])
    )
}

export const useIssueTypeName = (issueType: IssueTypes): string => {
    const issueTypesResources = useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.issueType
    )
    return issueTypesResources.find(
        (r) => (Number(r.code) as IssueTypes) === issueType
    ).name
}

export const useIssueTypeResources = (): FilterBase<number>[] => {
    return useSelector<RootState, FilterBase<number>[]>(
        (state) => state.resources.issueType
    )
}
