import { IssueRoles, IssueStatuses } from 'types/enums'
import { CcrpRole } from 'types/profile'

const EXTEND_TARGET_AND_VERIFICATION_DATE_PERMISSIONS = new Map([
    [
        IssueRoles.Issue_Owner,
        [
            IssueStatuses.Not_Assigned,
            IssueStatuses.In_Process,
            IssueStatuses.In_Process_Overdue,
            IssueStatuses.In_Process_Verification,
            IssueStatuses.In_Process_Verification_Overdue,
        ],
    ],
    [
        IssueRoles.Issue_Owner_Supervisor,
        [
            IssueStatuses.Not_Assigned,
            IssueStatuses.In_Process,
            IssueStatuses.In_Process_Overdue,
            IssueStatuses.In_Process_Verification,
            IssueStatuses.In_Process_Verification_Overdue,
        ],
    ],
    [
        IssueRoles.Issue_Owner_Team,
        [
            IssueStatuses.Not_Assigned,
            IssueStatuses.In_Process,
            IssueStatuses.In_Process_Overdue,
            IssueStatuses.In_Process_Verification,
            IssueStatuses.In_Process_Verification_Overdue,
        ],
    ],
])

export const canExtendTargetAndVerificationDate = (
    status: IssueStatuses,
    roles: Array<IssueRoles>
): boolean => {
    if (!status || !roles) {
        return false
    }
    const permissions = EXTEND_TARGET_AND_VERIFICATION_DATE_PERMISSIONS
    const role = roles.find((x) => permissions.has(x))
    if (!role) {
        return false
    }
    if (!permissions.get(role).find((s) => s === status)) {
        return false
    }
    return true
}

export const checkIsProcessOwner = (roles: CcrpRole[]) => {
    return roles.some((r) =>
        [
            IssueRoles.Div_Process_Owner,
            IssueRoles.Process_Owner,
            IssueRoles.Bu_Process_Owner,
            IssueRoles.PG_Process_Owner,
            IssueRoles.Support_Desk,
        ].some((role) => role === r.roleId)
    )
}

export const permissionProcessOwner = (
    roles: CcrpRole[],
    product?: string,
    businessCode?: string,
    division?: string
) => {
    return roles.some(
        (r) =>
            [
                IssueRoles.Div_Process_Owner,
                IssueRoles.Process_Owner,
                IssueRoles.Bu_Process_Owner,
                IssueRoles.Support_Desk,
                IssueRoles.PG_Process_Owner,
            ].some((role) => role === r.roleId) &&
            r.scopes.some((c) => {
                if (!product) {
                    return (
                        !(Boolean(c.countryCode) || Boolean(c.regionCode)) ||
                        r.roleId === IssueRoles.Support_Desk ||
                        r.roleId === IssueRoles.Div_Process_Owner
                    )
                } else {
                    return (
                        (!(Boolean(c.countryCode) || Boolean(c.regionCode)) &&
                            c.productGroupCode === product) ||
                        (r.roleId === IssueRoles.Bu_Process_Owner &&
                            !(
                                Boolean(c.countryCode) || Boolean(c.regionCode)
                            ) &&
                            c.businessUnitCode === businessCode) ||
                        r.roleId === IssueRoles.Support_Desk ||
                        (r.roleId === IssueRoles.Div_Process_Owner &&
                            c.divisionCode === division)
                    )
                }
            })
    )
}
export const permissionDivBuProcessOwner = (
    roles: CcrpRole[],
    businessCode?: string,
    division?: string
) => {
    return roles.some(
        (r) =>
            [
                IssueRoles.Developer,
                IssueRoles.Support_Desk,
                IssueRoles.Bu_Process_Owner,
                IssueRoles.Div_Process_Owner,
            ].some((role) => role === r.roleId) &&
            r.scopes.some((c) => {
                if (!businessCode && !division)
                    return !(Boolean(c.countryCode) || Boolean(c.regionCode))

                return (
                    r.roleId === IssueRoles.Developer ||
                    r.roleId === IssueRoles.Support_Desk ||
                    (r.roleId === IssueRoles.Bu_Process_Owner &&
                        c.businessUnitCode === businessCode) ||
                    (r.roleId === IssueRoles.Div_Process_Owner &&
                        c.divisionCode === division)
                )
            })
    )
}
