import { useSelector, useDispatch } from 'react-redux'
import EffectivenessAndSustainability from './EffectivenessAndSustainability'
import { RootState } from 'store/types'
import {
    IssueStoreState,
    setExpandMode,
    updateEffectivenessSection,
} from 'store/issue'
import { AttachmentTypes } from 'types/enums'

const EffectivenessAndSustainabilityContainer = (props: any) => {
    const dispatch = useDispatch()
    const {
        effectivenessSection,
        expanded,
        attachments,
        issueNumber,
        issueStatusId,
    } = useSelector<RootState, IssueStoreState>((state) => state.issue)
    return (
        <EffectivenessAndSustainability
            {...props}
            issueNumber={issueNumber}
            effectivenessSection={effectivenessSection}
            expanded={Boolean(expanded?.effectiveness)}
            onExpand={(expanded) =>
                dispatch(setExpandMode('effectiveness', expanded))
            }
            onSectionChange={(propKey, value) => {
                dispatch(updateEffectivenessSection(propKey, value))
            }}
            attachments={
                attachments?.filter(
                    (a) => a.attachmentType === AttachmentTypes.Effectiveness
                ) ?? []
            }
            issueStatusId={issueStatusId}
        />
    )
}

export default EffectivenessAndSustainabilityContainer
