import { Typography, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        cursor: {
            '&::before': {
                display: 'block',
                content: '"—"',
                fontSize: 'inherit',
                color: theme.palette.secondary.main,
            },
        },
    }),
    { name: 'AbbTypography' }
)

interface AbbTypographyProps extends TypographyProps {
    abbCursor?: boolean
}

export const AbbTypography = (props: AbbTypographyProps) => {
    const classes = useStyles(props)
    const {
        children,
        abbCursor = true,
        className: classNameProp,
        ...other
    } = props
    return (
        <Typography
            className={clsx(classNameProp, abbCursor && classes.cursor)}
            {...other}
        >
            {children}
        </Typography>
    )
}

export default AbbTypography
