import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useActionPlanSectionStyles = makeStyles(
    (theme: AbbTheme) => ({
        list: {
            padding: theme.spacing(0.5, 0),
        },
        item: {
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        icon: {
            height: theme.spacing(3),
        },
        actionName: {
            fontWeight: theme.typography.fontWeightMedium as number,
        },
    }),
    { name: 'ActionPlanSection' }
)
