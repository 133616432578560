import { ArrowBack } from '@mui/icons-material'
import { MenuItem, MenuList } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    AccordionGroup,
    DialogBase,
    LinkMenuItem,
    NavMenuItem,
} from 'components'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as Routes from 'routes'
import { clearIssue } from 'store/issue'
import { RootState } from 'store/types'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueRoles } from 'types/enums'
import { UserProfile } from 'types/profile'
import {
    dataTestId,
    permissionDivBuProcessOwner,
    permissionProcessOwner,
} from 'utils'
import { downloadAnalzyer } from '../store/dashboard'
import { YAMMER_LINK } from './utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        rootMobile: {
            paddingTop: 0,
        },
        item: {
            '& a': {
                paddingLeft: theme.spacing(3),
            },
        },
    }),
    { name: 'MainMenu' }
)

export interface MainMenuProps {
    mobile: boolean
    onClose: () => void
}

export const MainMenu = (props: MainMenuProps) => {
    const { mobile, onClose } = props
    const classes = useStyles(props)
    const { roles } = useSelector<RootState, UserProfile>(
        (state) => state.dashboard?.profile
    )
    const isDeveloper =
        roles?.some((r) => r.roleId === IssueRoles.Developer) ?? false
    const isPermissionProcessOwner = permissionProcessOwner(roles)
    const isPermissionDivBuProcessOwner = permissionDivBuProcessOwner(roles)
    const dispatch = useDispatch()
    const history = useHistory()
    const [state, setState] = useState<string>(window.location.pathname)
    const [open, setOpen] = useState(false)

    const unblockRef = useRef<any>()

    useEffect(() => {
        unblockRef.current =
            window.location.pathname.includes('/issue/new') &&
            history.block((e) => {
                const pattern = /\d+/g
                const issueNumber =
                    e?.pathname?.match(pattern) && e.pathname.match(pattern)[0]

                if (
                    [`/issue/${issueNumber}`, `/issue/new`].every(
                        (i) => !e?.pathname?.includes(i)
                    )
                ) {
                    const issueNumber2 =
                        window?.location?.pathname?.match(pattern) &&
                        window.location.pathname.match(pattern)[0]

                    setState(e.pathname)
                    setOpen(true)

                    if (
                        window.location.pathname.includes(
                            `/issue/${issueNumber2}`
                        )
                    ) {
                        history.block(true)
                        return
                    }
                    return false
                }
            })
        return () => {
            unblockRef.current && unblockRef.current()
        }
    }, [window.location.pathname])

    return (
        <>
            <DialogBase
                open={open}
                title="Warning"
                message="Are you sure you want leave new draft? You will lose all unsaved data."
                confirmLabel="Confirm"
                closeLabel="Cancel"
                onClose={() => setOpen(false)}
                onConfirm={() => {
                    dispatch(clearIssue())
                    history.block(true)
                    history.replace(state)
                    setOpen(false)
                }}
            />
            <MenuList className={clsx(mobile && classes.rootMobile)}>
                {mobile && (
                    <MenuItem
                        // button={true}
                        onClick={onClose}
                        divider
                        {...dataTestId('DASHBOARD_LEFT_MENU_BACK_ARROW')}
                    >
                        <ArrowBack fontSize="small" />
                    </MenuItem>
                )}
                <NavMenuItem
                    to={Routes.ISSUE_NEW}
                    {...dataTestId('DASHBOARD_NEW_ISSUE')}
                    eventName={'New issue on Left Menu'}
                >
                    New issue
                </NavMenuItem>
                <NavMenuItem
                    to={Routes.GLOBAL_SEARCH}
                    eventName={'Global search on Left Menu'}
                >
                    Global search
                </NavMenuItem>
                <AccordionGroup label="My Dashboard" initExpand={true}>
                    <NavMenuItem
                        level={1}
                        to={Routes.DASHBOARD}
                        {...dataTestId(
                            'DASHBOARD_LEFT_MENU_NAVIGATION_ACTIONS'
                        )}
                        eventName={'My actions on Left Menu'}
                    >
                        My actions
                    </NavMenuItem>

                    <NavMenuItem
                        level={1}
                        to={Routes.MY_ISSUES}
                        {...dataTestId(
                            'DASHBOARD_LEFT_MENU_NAVIGATION_MY_ISSUES'
                        )}
                        eventName={'My issues on Left Menu'}
                    >
                        My issues
                    </NavMenuItem>
                    <NavMenuItem
                        level={1}
                        to={Routes.MY_ACTION_PLAN_ITEMS}
                        {...dataTestId(
                            'DASHBOARD_LEFT_MENU_NAVIGATION_MY_ACTION_PLAN_ITEMS'
                        )}
                        eventName={'My action plan items on Left Menu'}
                    >
                        My action plan items
                    </NavMenuItem>
                </AccordionGroup>

                <AccordionGroup
                    label="Reports"
                    {...dataTestId('DASHBOARD_REPORTS')}
                    initExpand={false}
                >
                    <AccordionGroup
                        label="CCRP reports"
                        initExpand={false}
                        level={1}
                        divider={false}
                        {...dataTestId('DASHBOARD_CCRP_REPORTS')}
                    >
                        <NavMenuItem
                            level={1}
                            to={Routes.ALL_IN_ONE_REPORT}
                            {...dataTestId('DASHBOARD_ALL_IN_ONE')}
                            eventName={'All in one on Left Menu'}
                        >
                            All in one
                        </NavMenuItem>
                        <LinkMenuItem
                            className={classes.item}
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(downloadAnalzyer(false))
                            }}
                            {...dataTestId('DASHBOARD_CCRP_ANALYZER')}
                            eventName={'CCRP Analyzer on Left Menu'}
                        >
                            CCRP Analyzer
                        </LinkMenuItem>
                        <LinkMenuItem
                            className={classes.item}
                            to={
                                'https://app.powerbi.com/groups/me/apps/256fe751-7945-4047-94ea-160bb54ac58b/reports/cb89b6bd-5894-456e-a448-a8d8d720e452/ReportSection49a05e48957eb5d3a0e5'
                            }
                            {...dataTestId('DASHBOARD_CCRP_POWER_BI_REPORT')}
                            eventName={'CCRP PowerBI report on Left Menu'}
                        >
                            CCRP PowerBI report
                        </LinkMenuItem>
                    </AccordionGroup>
                    <AccordionGroup
                        label="Unit-internal NC reports"
                        initExpand={false}
                        level={1}
                        divider={false}
                        {...dataTestId('DASHBOARD_UNIT_INTERNAL_NC_REPORTS')}
                    >
                        <NavMenuItem
                            level={1}
                            {...dataTestId('DASHBOARD_UI_NC_ALL_IN_ONE')}
                            to={Routes.NC_ALL_IN_ONE_REPORT}
                            eventName={'All in one on Left Menu'}
                        >
                            All in one
                        </NavMenuItem>

                        <LinkMenuItem
                            {...dataTestId('DASHBOARD_UI_NC_CCRP_ANALYZER')}
                            className={classes.item}
                            onClick={(e) => {
                                e.preventDefault()
                                dispatch(downloadAnalzyer(true))
                            }}
                            eventName={'CCRP Analyzer on Left Menu'}
                        >
                            CCRP Analyzer
                        </LinkMenuItem>
                    </AccordionGroup>
                    <AccordionGroup
                        label="Other quality reports"
                        initExpand={false}
                        level={1}
                        divider={false}
                        {...dataTestId('DASHBOARD_OTHER_QUALITY_REPORTS')}
                    >
                        <LinkMenuItem
                            className={classes.item}
                            {...dataTestId('DASHBOARD_ABB_CUSTOMER_ISSUE_KPI')}
                            to="https://app.powerbi.com/Redirect?action=OpenApp&appId=d530a592-d569-427c-a6e3-c444b60e3f0c&ctid=372ee9e0-9ce0-4033-a64a-c07073a91ecd"
                            eventName={'ABB Customer Issue KPI on Left Menu'}
                        >
                            ABB Customer Issue KPI
                        </LinkMenuItem>
                        <LinkMenuItem
                            className={classes.item}
                            {...dataTestId('DASHBOARD_MOTION_OPERATIONS')}
                            to="https://app.powerbi.com/groups/me/apps/3f94a22a-7a9b-46ad-9359-a065494674d7/reports/47c793eb-b0b6-479f-ae5b-fc999f487b65/ReportSection760c1c9a8f6a36077529"
                            eventName={
                                'Motion Operations Dashboard on Left Menu'
                            }
                        >
                            Motion Operations Dashboard
                        </LinkMenuItem>
                    </AccordionGroup>
                </AccordionGroup>

                <AccordionGroup
                    label="Admin"
                    {...dataTestId('DASHBOARD_ADMIN_PANEL')}
                >
                    <NavMenuItem
                        {...dataTestId('DASHBOARD_ADMIN_RESOLVING_UNIT')}
                        level={1}
                        to={Routes.ADMIN_RESOLVING_UNITS}
                        eventName={'Admin - Resolving units on Left Menu'}
                    >
                        Resolving units
                    </NavMenuItem>
                    <NavMenuItem
                        {...dataTestId('DASHBOARD_ADMIN_CUSTOMER_CONTACTS')}
                        level={1}
                        to={Routes.ADMIN_CUSTOMER_CONTACTS}
                        eventName={'Admin - Customer contacts on Left Menu'}
                    >
                        Customer contacts
                    </NavMenuItem>
                    <NavMenuItem
                        {...dataTestId('DASHBOARD_ADMIN_MANAGE_HUBS')}
                        level={1}
                        to={Routes.ADMIN_MANAGE_HUBS}
                        eventName={'Admin - HUBs on Left Menu'}
                    >
                        HUBs
                    </NavMenuItem>
                    <NavMenuItem
                        {...dataTestId('DASHBOARD_ADMIN_PROCESS_OWNERS')}
                        level={1}
                        to={Routes.ADMIN_PROCESS_OWNERS}
                        eventName={'Admin - Process owners on Left Menu'}
                    >
                        Process owners
                    </NavMenuItem>
                    {isPermissionProcessOwner && (
                        <NavMenuItem
                            {...dataTestId('DASHBOARD_ADMIN_PG_RCA_CATEGORIES')}
                            level={1}
                            to={Routes.ADMIN_PG_RCA_CATEGORIES}
                            eventName={'Admin - PG RCA categories on Left Menu'}
                        >
                            PG RCA categories
                        </NavMenuItem>
                    )}
                    {isPermissionProcessOwner && (
                        <NavMenuItem
                            {...dataTestId('DASHBOARD_ADMIN_FAILURE_MODES')}
                            level={1}
                            to={Routes.ADMIN_FAILURE_MODES}
                            eventName={'Admin - Failure modes on Left Menu'}
                        >
                            Failure modes
                        </NavMenuItem>
                    )}
                    {isPermissionDivBuProcessOwner && (
                        <NavMenuItem
                            {...dataTestId('DASHBOARD_ADMIN_CLASSIFICATIONS')}
                            level={1}
                            to={Routes.ADMIN_CLASSIFICATIONS}
                            eventName={'Admin - Classifications on Left Menu'}
                        >
                            Classifications
                        </NavMenuItem>
                    )}
                </AccordionGroup>
                <AccordionGroup
                    label="Help/Links/Trainings"
                    {...dataTestId('HELP_LINKS')}
                    initExpand={false}
                >
                    <LinkMenuItem
                        className={classes.item}
                        to="https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/CCRP-Training-Materials.aspx"
                        eventName={'Trainings on Left Menu'}
                        {...dataTestId('LINKS_TRAININGS')}
                    >
                        Trainings
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to={YAMMER_LINK}
                        eventName={'Yammer on Left Menu'}
                        {...dataTestId('LINKS_YAMMER')}
                    >
                        Viva Engage
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/FAQ.aspx"
                        eventName={'FAQ on Left Menu'}
                        {...dataTestId('LINKS_FAQ')}
                    >
                        FAQ
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/Customer-Care.aspx"
                        eventName={'CCRP SharePoint on Left Menu'}
                        {...dataTestId('LINKS_CCRP_SHAREPOINT')}
                    >
                        CCRP SharePoint
                    </LinkMenuItem>

                    <NavMenuItem
                        className={classes.item}
                        to={Routes.RELEASE_NOTES}
                        eventName={'Release notes on Left Menu'}
                        {...dataTestId('LINKS_RELEASE_NOTES')}
                    >
                        Release notes
                    </NavMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/Log-a-support-ticket.aspx"
                        eventName={'Help Desk on Left Menu'}
                        {...dataTestId('LINKS_HELP_DESK')}
                    >
                        Help Desk
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://go.insideplus.abb.com/businesses/electrification/el-academy/el-operations-academy/general-skills/l6s-training-and-certification"
                        eventName={'Lean 6sigma on Left Menu'}
                        {...dataTestId('LINKS_LEAN_6SIGMA')}
                    >
                        Lean 6sigma
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://abb.sharepoint.com/sites/NPS/CCRP/SitePages/Process%20Flow.aspx"
                        eventName={'CCRP process flow on Left Menu'}
                        {...dataTestId('LINKS_CCRP_PROCESS_FLOW')}
                    >
                        CCRP process flow
                    </LinkMenuItem>
                    <LinkMenuItem
                        className={classes.item}
                        to="https://supplier-non-conformity-resolution-process.abb.com/"
                        eventName={'SNCRP on Left Menu'}
                        {...dataTestId('LINKS_SNCP')}
                    >
                        SNCRP
                    </LinkMenuItem>
                </AccordionGroup>

                {isDeveloper && (
                    <AccordionGroup
                        label="Developer tools"
                        {...dataTestId('DEVELOPER_TOOLS')}
                    >
                        <NavMenuItem
                            level={1}
                            to={Routes.USER_IMPERSONATE}
                            eventName={'User impersonate on Left Menu'}
                        >
                            User impersonate
                        </NavMenuItem>
                        <NavMenuItem
                            level={1}
                            to={Routes.NOTIFICATION_SETTINGS}
                            eventName={'Notification Settings on Left Menu'}
                        >
                            Notification Settings
                        </NavMenuItem>
                        <NavMenuItem
                            level={1}
                            to={Routes.MANAGE_SUPPORT_GROUP}
                            eventName={'Manage support desk group on Left Menu'}
                        >
                            Manage support desk group
                        </NavMenuItem>
                        <NavMenuItem
                            level={1}
                            to={Routes.EMAIL_THEME_EDITOR}
                            eventName={'Email theme editor on Left Menu'}
                        >
                            Email theme editor
                        </NavMenuItem>
                    </AccordionGroup>
                )}
            </MenuList>
        </>
    )
}

export default MainMenu
