import ExpandIcon from '@mui/icons-material/ExpandMore'
import List from '@mui/material/List'
import { useEffect, useState } from 'react'
import AttachmentView from './Attachment'

import { Attachment } from 'types/issueTypes'
import useIssueFormStyles from '../../Issue/Sections/useIssueFormStyles'
import {
    InternalAccordion,
    InternalAccordionDetails,
    InternalAccordionSummary,
} from '../Base/InternalAccordion'

export interface AttachmentsProps {
    expanded: boolean
    disabled: boolean
    attachments: Array<Attachment>
}

export const Attachments = (props: AttachmentsProps) => {
    const { disabled, attachments, expanded: expandedProp } = props
    const classes: any = useIssueFormStyles()

    const [expanded, setExpanded] = useState(false)
    useEffect(() => {
        setExpanded(expandedProp)
    }, [expandedProp])
    return attachments && 0 < attachments.length ? (
        <InternalAccordion
            expanded={expanded}
            onChange={(e: any, expanded: boolean) => setExpanded(expanded)}
        >
            <InternalAccordionSummary expandIcon={<ExpandIcon />}>
                <div className={classes.SubHeadline}>{'Attachments'}</div>
            </InternalAccordionSummary>
            <InternalAccordionDetails>
                <List>
                    {attachments.map((file) => (
                        <AttachmentView
                            key={file.id}
                            file={file}
                            disabled={disabled}
                        />
                    ))}
                </List>
            </InternalAccordionDetails>
        </InternalAccordion>
    ) : null
}

Attachments.defaultProps = {
    expanded: false,
    disabled: true,
    attachments: [],
}

export default Attachments
