import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { createBrowserHistory } from 'history'

const browserHistory = createBrowserHistory()
export const reactPlugin: any = new ReactPlugin()
reactPlugin.storeAuthStateInCookie = false
reactPlugin.isCookieUseDisabled = true
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_KEY,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        isCookieUseDisabled: true,
        isStorageUseDisabled: true,
    },
})

appInsights.loadAppInsights()
