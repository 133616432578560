import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { showSnackbar } from 'store/app'
import {
    extendTargetResolutionDateDone,
    extendTargetResolutionDateFail,
    refreshMyIssueById,
} from '../actions'
import { MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL } from '../actionTypes'
import { ExtendTargetResolutionDateCommand } from '../types'

import { extendTargetResolvingDate as extendTargetResolvingDateApi } from 'api'

export function* extendTargetResolutionDate() {
    yield takeLatest(
        MY_ISSUES_EXTEND_TARGET_RESOLUTION_DATE_CALL,
        function* (action: ExtendTargetResolutionDateCommand) {
            const cancelSource = axios.CancelToken.source()
            try {
                yield call(
                    extendTargetResolvingDateApi,
                    action.payload,
                    cancelSource.token
                )

                yield put(extendTargetResolutionDateDone(action.payload))
                yield put(refreshMyIssueById(action.payload.issueId))
            } catch (error) {
                yield put(extendTargetResolutionDateFail(error))
                yield put(
                    showSnackbar(
                        'Extend Target Resolution and Verification date fail',
                        true
                    )
                )
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
