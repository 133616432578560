import { takeLatest } from 'redux-saga/effects'
import { DASHBOARD_LOAD_PROFILE_DONE } from '..'
import { appInsights } from 'configs/appInsights'
import { AnyAction } from 'redux'
import { UserProfile } from 'types/profile'

export function* loadProfileDone() {
    yield takeLatest(
        DASHBOARD_LOAD_PROFILE_DONE,
        function* (action: AnyAction) {
            const profile = action.payload as UserProfile
            appInsights.addTelemetryInitializer((envelop) => {
                envelop.data['divCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtDivision?.code ?? ''
                envelop.data['buCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code ?? ''
                envelop.data['pgCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtProductGroup?.code ?? ''
                envelop.data['countryCode'] = profile?.countryCode ?? ''
                envelop.tags['divCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtDivision?.code ?? ''
                envelop.tags['buCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtBusinessUnit?.code ?? ''
                envelop.tags['pgCode'] =
                    profile?.hrgtOrganizationInfo?.hrgtProductGroup?.code ?? ''
                envelop.tags['countryCode'] = profile?.countryCode ?? ''
            })
            appInsights.setAuthenticatedUserContext(
                profile?.geid ?? '',
                profile?.geid ?? '',
                true
            )
            yield
        }
    )
}
