import { Typography, useTheme } from '@mui/material'
import {
    AllInOneColumnsSelect,
    DateRangeSelect,
    EmployeeSelect,
    FormGridItem,
    GridContainer,
    MultiEmployeeRolesSelect,
    MultiIssueSourceSelect,
    MultiIssueStatusesSelect,
    NullableBooleanSelect,
    ReportDatePeriodTypeSelect,
} from 'components'
import { ReportDateTypesSelect } from 'components/Fields/ReportDateTypesSelect'
import dayjs from 'dayjs'
import { EMPLOYEE_ROLES_ALL_IN_ONE } from 'features'
import { useEffect } from 'react'
import { AllInOneColumn, FilterBase } from 'types/models'
import { IssueFiltersState, SpecialCasesState } from 'types/reportTypes'
import { dataTestId } from 'utils'
import { FilterSection } from '.'
import { getStartOfQuarter } from '../../../utils/dateTime'
import { FiltersSectionBaseProps } from '../types'
import { SpecialCases } from './SpecialCases'

export interface IssueFiltersProps extends FiltersSectionBaseProps {
    value: IssueFiltersState
    onChange: (value: IssueFiltersState) => void
    columns: AllInOneColumn[]
    onColumnsChange: (columns: AllInOneColumn[]) => void
    specialCases: SpecialCasesState
    onSpecialCasesChange: (SpecialCases: SpecialCasesState) => void
}

export const IssueFilters = (props: IssueFiltersProps) => {
    const {
        value,
        onChange,
        columns,
        onColumnsChange,
        isUnitInternal,
        specialCases,
        onSpecialCasesChange,
        ...other
    } = props
    const {
        employee,
        employeeRole,
        issueSources,
        issueStatuses,
        dateType,
        reportDatePeriodType,
        fromDate,
        toDate,
    } = value
    const theme = useTheme()
    const createChangeHandler =
        (key: keyof IssueFiltersState) => (newValue: any) => {
            ;(value[key] as any) = newValue
            onChange({ ...value })
        }
    const createPlaceholder = (
        key: keyof IssueFiltersState,
        placeholder: string = 'Click or type to select'
    ) => ((value[key] as Array<any>)?.length <= 0 ? placeholder : '')
    const handleReportDatePeriodTypeChnage = (item: FilterBase<number>) => {
        onChange({
            ...value,
            reportDatePeriodType: item,
        })
    }
    useEffect(() => {
        if (!reportDatePeriodType) {
            return
        }

        let fromDate = value.fromDate
        let toDate = value.toDate
        const dayDifferenece = (days: number) => {
            fromDate = dayjs().utc().subtract(days, 'day').toISOString()
            toDate = dayjs().utc().toISOString()
        }
        switch (reportDatePeriodType?.code) {
            case 1:
                dayDifferenece(7)
                break
            case 2:
                dayDifferenece(30)
                break
            case 3:
                dayDifferenece(90)
                break
            case 4:
                dayDifferenece(365)
                break
            case 5:
                fromDate = dayjs().utc().startOf('month').toISOString()
                toDate = dayjs().utc().toISOString()
                break
            case 6:
                fromDate = getStartOfQuarter(dayjs().utc()).toISOString()
                toDate = dayjs().utc().toISOString()
                break
            case 7:
                fromDate = dayjs().utc().startOf('year').toISOString()
                toDate = dayjs().utc().toISOString()
                break
            case 8:
                fromDate = dayjs().utc().startOf('week').toISOString()
                toDate = dayjs().utc().toISOString()
                break
            default:
                break
        }
        onChange({
            ...value,
            fromDate,
            toDate,
        })
    }, [reportDatePeriodType])
    return (
        <FilterSection {...other}>
            <GridContainer>
                <FormGridItem>
                    {!isUnitInternal && (
                        <>
                            <NullableBooleanSelect
                                label="Issue type"
                                value={value?.issueType ?? null}
                                onChange={createChangeHandler('issueType')}
                                optionFalseLabel="Internal (ABB)"
                                optionTrueLabel="External"
                                optionNullLabel="ALL"
                                {...dataTestId('ISSUE_TYPE')}
                            />
                            <MultiIssueSourceSelect
                                selectedItems={issueSources ?? []}
                                onSelectedItemsChanged={createChangeHandler(
                                    'issueSources'
                                )}
                            />
                        </>
                    )}
                    <MultiIssueStatusesSelect
                        placeholder={createPlaceholder('issueStatuses')}
                        selectedItems={issueStatuses ?? []}
                        onSelectedItemsChanged={createChangeHandler(
                            'issueStatuses'
                        )}
                    />
                </FormGridItem>
                <FormGridItem>
                    <ReportDateTypesSelect
                        selectedItem={dateType}
                        onSelectedItemChanged={createChangeHandler('dateType')}
                    />
                    <ReportDatePeriodTypeSelect
                        selectedItem={reportDatePeriodType}
                        onSelectedItemChanged={handleReportDatePeriodTypeChnage}
                    />
                    {reportDatePeriodType?.code === 0 ? (
                        <DateRangeSelect
                            fromDate={fromDate}
                            toDate={toDate}
                            onFromDateChange={createChangeHandler('fromDate')}
                            onToDateChange={createChangeHandler('toDate')}
                        />
                    ) : (
                        <Typography style={{ marginTop: theme.spacing(2) }}>
                            This report will contain all issues from date when
                            report is generated
                        </Typography>
                    )}
                </FormGridItem>
                {!isUnitInternal && (
                    <FormGridItem fullWidth>
                        <SpecialCases
                            value={specialCases}
                            onChange={onSpecialCasesChange}
                        />
                    </FormGridItem>
                )}
                {EMPLOYEE_ROLES_ALL_IN_ONE && (
                    <>
                        <FormGridItem>
                            <EmployeeSelect
                                label="Employee"
                                employee={employee}
                                onEmployeeChanged={createChangeHandler(
                                    'employee'
                                )}
                            />
                        </FormGridItem>

                        <FormGridItem>
                            <MultiEmployeeRolesSelect
                                employee={employee}
                                selectedItems={employeeRole ?? []}
                                onSelectedItemsChanged={createChangeHandler(
                                    'employeeRole'
                                )}
                            />
                        </FormGridItem>
                    </>
                )}
                <FormGridItem fullWidth>
                    <AllInOneColumnsSelect
                        selectedColumns={columns}
                        onColumnsChanged={onColumnsChange}
                        isUnitInternal={isUnitInternal}
                    />
                </FormGridItem>
            </GridContainer>
        </FilterSection>
    )
}
