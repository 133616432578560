import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTypography, RoundedButton, TextField } from 'components'
import { useContext, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    CustomerSurveyQuestion,
    CustomerSurveyStatus,
    SurveyFieldType,
} from 'types/surveyTypes'
import {
    QuestionLabel,
    Score,
    ScoreValues,
    SurveyTranslationContext,
    SurveyTranslationContextProps,
} from '..'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        description: {
            margin: theme.spacing(1, 0, 4, 0),
        },
        actions: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: theme.spacing(4),
        },
        question: {
            marginBottom: theme.spacing(4),
        },
        send: {
            margin: theme.spacing(2, 0, 8, 0),
        },
        space: {
            minHeight: '10vh',
        },
        footer: {
            textAlign: 'center',
            color: theme.palette.grey[300],
        },
    }),
    { name: 'Survey' }
)

export interface SurveyContentProps {
    survey: CustomerSurveyStatus
    onSurveyChange: (survey: CustomerSurveyStatus) => void
    onSubmit: (survey: CustomerSurveyStatus) => void
}

export const SurveyContent = (props: SurveyContentProps) => {
    const { survey, onSurveyChange, onSubmit } = props
    const [errors, setErrors] = useState<
        Record<Partial<SurveyFieldType>, string>
    >({
        ValueFromOneToFiveEffort: null,
        ValueFromOneToFiveSatisfaction: null,
        Textarea: null,
    })
    const classes = useStyles()
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    const handleSubmit = () => {
        let isValid = true
        survey.questions.forEach((q) => {
            if (
                [
                    'ValueFromOneToFiveEffort',
                    'ValueFromOneToFiveSatisfaction',
                ].includes(q.type)
            ) {
                if (!q.result) {
                    isValid = false
                    setErrors((prev) => ({
                        ...prev,
                        [q.type]: t('SurveyQuestion_NotValid', ''),
                    }))
                }
            }
        })

        if (isValid) {
            const request = { ...survey }
            request.questions = request.questions.map((q) => {
                q.result = String(q.result)
                return q
            })
            onSubmit(request)
        }
    }
    const getField = (question: CustomerSurveyQuestion) => {
        const updateQuestion = (value: any) => {
            const results = { ...survey }
            const index = results.questions.findIndex(
                (q) => q.id === question.id
            )
            const newState = results.questions[index]
            results.questions[index] = {
                ...newState,
                result: value,
            }
            setErrors((prev) => ({ ...prev, [question.type]: null }))
            onSurveyChange(results)
        }
        switch (question.type) {
            case 'ValueFromOneToFiveSatisfaction':
                return (
                    <QuestionLabel
                        key={question.id}
                        className={classes.question}
                        label={t(
                            'Survey_Question_Satisfaction_1',
                            'How satisfied were you with the manner in which we handled your issue?'
                        )}
                        required
                    >
                        <Score
                            key={question.id}
                            score={question.result as ScoreValues}
                            onChange={updateQuestion}
                            minimalValueLabel={t(
                                'SurveyQuestion_Satisfaction_NotSatisfied',
                                'Being unsatisfied'
                            )}
                            maximumValueLabel={t(
                                'SurveyQuestion_Satisfaction_Satisfied',
                                'Being very satisfied'
                            )}
                            error={Boolean(errors[question.type])}
                            helperText={errors[question.type] ?? ''}
                        />
                    </QuestionLabel>
                )
            case 'ValueFromOneToFiveEffort':
                return (
                    <QuestionLabel
                        key={question.id}
                        className={classes.question}
                        label={t(
                            'Survey_Question_Short_Effort_1',
                            'How much effort did you have to make in order to have your issue resolved?'
                        )}
                        required
                    >
                        <Score
                            key={question.id}
                            score={question.result as ScoreValues}
                            onChange={updateQuestion}
                            minimalValueLabel={t(
                                'SurveyQuestion_Effort_IntenseEffort',
                                'Intense effort'
                            )}
                            maximumValueLabel={t(
                                'SurveyQuestion_Effort_NoEffort',
                                'Almost no effort at all'
                            )}
                            error={Boolean(errors[question.type])}
                            helperText={errors[question.type] ?? ''}
                        />
                    </QuestionLabel>
                )
            case 'Textarea':
                return (
                    <QuestionLabel
                        key={question.id}
                        label={t(
                            'Survey_Comment_1',
                            'Please feel free to leave a comment and/or make suggestions as to how we can improve your experience (optional).'
                        )}
                    >
                        <TextField
                            key={question.id}
                            value={question.result as String}
                            onChange={(e) => updateQuestion(e.target.value)}
                            minRows={6}
                            multiline
                        />
                    </QuestionLabel>
                )
            default:
                return null
        }
    }
    return (
        <div>
            <AbbTypography variant="h2">
                {t('Survey_PageTitle', 'How did we handle your complaint?')}
            </AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                {t(
                    'Survey_HeaderDescription',
                    'Please select the option below that best reflects your opinion.'
                )}
            </Typography>
            {survey.questions.map((q) => getField(q))}
            <div className={classes.actions}>
                <RoundedButton
                    className={classes.send}
                    onClick={handleSubmit}
                    variant="contained"
                >
                    {t('Survey_SubmitButton', 'Send')}
                </RoundedButton>
            </div>
        </div>
    )
}
