import axios, { AxiosResponse, CancelToken } from 'axios'
import { Employee } from 'types/issueTypes'

const controllerName = '/employees'

export const searchEmployees = (
    wildcard: string,
    page: number = 1,
    pageSize: number = 20,
    onlyActive: boolean = true,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<Employee>>> =>
    axios.get(controllerName + '/search', {
        params: {
            page,
            pageSize,
            wildcard,
            onlyActive,
        },
        cancelToken,
    })

export const getEmployee = (
    emailOrGeid: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Employee>> =>
    axios.get(`${controllerName}/${emailOrGeid}`, { cancelToken })
