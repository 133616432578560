import { makeStyles } from '@mui/styles'
import { TileContainer } from 'components'
import { ReactNode } from 'react'
import { AdminCardHeader } from './AdminCardHeader'
import { AdminEmployeesChipsSimple } from './AdminEmployeesChips'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        newConceptRoot: {
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            justifyContent: 'space-between',
        },
        tile: {
            padding: theme.spacing(1.5),
        },
        border: {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        newConcept: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
        },
        bootmPanel: {
            display: 'flex',
            '@media (max-width: 700px)': {
                flexDirection: 'column',
            },
        },
        showProcessOwner: {
            flexGrow: 1,
            margin: theme.spacing(1, 1.5),
        },
    }),
    {
        name: 'HubsManagePageCard',
    }
)

export interface AdminTileContainerProps {
    disabled: boolean
    checked: boolean
    onChangeChecked: () => void
    renderTags: () => ReactNode
    headerLabel: string
    headerContent: ReactNode
    mainContent: ReactNode
    bottomContent: ReactNode
    actions: ReactNode
}

export const AdminTileContainer = (props: AdminTileContainerProps) => {
    const classes = useStyles()
    const {
        checked,
        disabled,
        renderTags,
        headerLabel,
        headerContent,
        onChangeChecked,
        mainContent,
        bottomContent,
        actions,
        ...other
    } = props
    return (
        <TileContainer {...other}>
            <div className={classes.tile}>
                <AdminCardHeader
                    className={classes.newConceptRoot}
                    newConcept={true}
                    disabled={disabled}
                    checked={checked}
                    onChangeChecked={onChangeChecked}
                    renderTags={renderTags}
                >
                    {headerLabel}
                </AdminCardHeader>
                {headerContent}
            </div>
            <div className={classes.border} />
            {mainContent}
            <div className={classes.bootmPanel}>
                <div className={classes.showProcessOwner}>
                    <AdminEmployeesChipsSimple
                        newConceptRender={(props) => (
                            <div className={classes.newConcept}>{props}</div>
                        )}
                    >
                        {bottomContent}
                    </AdminEmployeesChipsSimple>
                </div>
                {actions}
            </div>
        </TileContainer>
    )
}
