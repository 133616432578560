import { Typography } from '@mui/material'
import { AdminDialog } from 'Admin/components'
import { TreeViewManageDialogVariant } from 'Admin/components/TreeViewManagePage/TreeViewManageDialog'
import {
    Button,
    FormControlLabel,
    Switch,
    TextField,
    TextFieldLimit,
} from 'components'
import { useCallback } from 'react'
import { ClassificationItem } from 'types/adminTypes'
import { dataTestId } from 'utils'

export interface ClassificationsManageDialogProps {
    variant: TreeViewManageDialogVariant
    title: string
    onCloseModal: () => void
    onAddClassification: () => Promise<void>
    onDeleteClassification: () => Promise<void>
    onEditClassification: () => Promise<void>
    onSetSelectedClassification: (item: ClassificationItem) => void
    classificationItem: ClassificationItem
}

export const ClassificationsManageDialog = (
    props: ClassificationsManageDialogProps
) => {
    const {
        variant,
        title,
        classificationItem,
        onCloseModal,
        onAddClassification,
        onDeleteClassification,
        onEditClassification,
        onSetSelectedClassification,
    } = props

    const renderActions = useCallback(() => {
        switch (variant) {
            case 'deactive':
                return (
                    <>
                        <Button
                            onClick={onCloseModal}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                onDeleteClassification()
                                onCloseModal()
                            }}
                            color="secondary"
                            {...dataTestId(`DEACTIVATE_BUTTON `)}
                        >
                            Deactivate
                        </Button>
                    </>
                )
            case 'edit':
                return (
                    <>
                        <Button
                            onClick={onCloseModal}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                onEditClassification()
                                onCloseModal()
                            }}
                            color="secondary"
                            {...dataTestId(`EDIT_BUTTON `)}
                            disabled={
                                classificationItem?.categoryName.length > 100
                            }
                        >
                            Edit
                        </Button>
                    </>
                )
            case 'add':
                return (
                    <>
                        <Button
                            onClick={onCloseModal}
                            {...dataTestId(`CLOSE_BUTTON `)}
                        >
                            Close
                        </Button>
                        <Button
                            disabled={
                                classificationItem?.categoryName.length === 0 ||
                                classificationItem?.categoryName.length > 100
                            }
                            color="secondary"
                            onClick={() => {
                                onAddClassification()
                                onCloseModal()
                            }}
                            {...dataTestId(`ADD_BUTTON `)}
                        >
                            Add
                        </Button>
                    </>
                )
            default:
                return null
        }
    }, [variant, classificationItem])

    return (
        <AdminDialog
            open={Boolean(variant)}
            onClose={onCloseModal}
            renderActions={renderActions}
            title={title}
        >
            <div style={{ minHeight: 148 }}>
                {variant === 'deactive' && (
                    <Typography variant="body1">
                        Do you want to deactivate this node?
                    </Typography>
                )}
                {(variant === 'add' || variant === 'edit') && (
                    <TextFieldLimit
                        disabled={false}
                        currentLength={classificationItem?.categoryName.length}
                        maxLength={100}
                    >
                        <TextField
                            autoFocus
                            label={'Classification name'}
                            value={classificationItem?.categoryName}
                            placeholder="Classification name"
                            onChange={(e) =>
                                onSetSelectedClassification({
                                    ...classificationItem,
                                    categoryName: e.target.value,
                                })
                            }
                            error={
                                classificationItem?.categoryName.length > 100
                            }
                            {...dataTestId(`PG_RCA_CATEGORY_NAME_TEXT_FIELD `)}
                        />
                    </TextFieldLimit>
                )}
                {variant === 'edit' && (
                    <FormControlLabel
                        control={
                            <>
                                <Switch
                                    checked={classificationItem?.isActive}
                                    onChange={(e, checked) =>
                                        onSetSelectedClassification({
                                            ...classificationItem,
                                            isActive: checked,
                                        })
                                    }
                                    {...dataTestId(`IS_ACTIVE `)}
                                />
                            </>
                        }
                        label="Is active"
                    />
                )}
            </div>
        </AdminDialog>
    )
}
