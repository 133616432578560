import { Add, InfoOutlined } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId, readFileToBase64, uuid } from 'utils'
import { MailAttachmentDto } from '../../../types/sendMessageTypes'
import { MailAttachment, MessageAttachment } from './MessageAttachment'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            borderTop: `1px solid ${theme.palette.divider}`,
            borderBottom: `1px solid ${theme.palette.divider}`,
            minHeight: 42,
            width: '100%',
            overflow: 'hidden',
        },
        content: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
            padding: theme.spacing(0, 1),
        },
        attachments: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flexGrow: 1,
            width: '100%',
        },
        label: { marginLeft: theme.spacing(1) },
        chip: {
            position: 'relative',
            display: 'flex',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            margin: theme.spacing(0.5, 1, 0.5, 0),
        },
        chipLabel: {
            display: 'block',
            width: 'auto',
            maxWidth: '100%',
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'MessageAttachments' }
)

const MAX_FILES_SIZE_IN_BYTES = 62914560

const mapAttachmentDto = async (
    attachments: MailAttachment[]
): Promise<MailAttachmentDto[]> => {
    const results = []
    for (const attachment of attachments) {
        const item = {
            name: attachment.name,
            mimeType: attachment.file.type,
            content: '',
        }
        const content = await readFileToBase64(attachment.file)
        item.content = content
        results.push(item)
    }
    return results
}

export interface MessageAttachmentsProps {
    disabled?: boolean
    onAttachmentsChange: (attachments: MailAttachmentDto[]) => void
    onAttachmentsValidationChange: (attachmentsValid: boolean) => void
}

export const MessageAttachments = (props: MessageAttachmentsProps) => {
    const {
        disabled = false,
        onAttachmentsChange,
        onAttachmentsValidationChange,
    } = props

    const ref = useRef(null)
    const classes = useStyles()
    const [attachments, setAttachments] = useState<MailAttachment[]>([])
    const validAttachments = useMemo(() => {
        const size = attachments?.reduce((size, a) => size + a.file.size, 0)
        const results = MAX_FILES_SIZE_IN_BYTES < size
        onAttachmentsValidationChange(!results)
        return results
    }, [attachments])
    const mapFiles = (files: FileList): MailAttachment[] => {
        const map =
            [...files]?.map((x) => {
                return { guid: uuid(), name: x.name, file: x }
            }) ?? null
        const results = [...attachments, ...map]
        setAttachments(results)
        return results
    }

    const handleOpenFilePicker = () => {
        ref.current.click()
        return false
    }
    const handleAttachmentsAdd = async (e: ChangeEvent<HTMLInputElement>) => {
        const files = mapFiles(e.target.files)
        const attachments = await mapAttachmentDto(files)
        onAttachmentsChange(attachments)
        e.target.value = ''
    }
    const handleAttachmentDelete = async (mailAttachment: MailAttachment) => {
        const nextAttachments = [...attachments]
        const index = nextAttachments.findIndex(
            (a) => a.guid === mailAttachment.guid
        )
        nextAttachments.splice(index, 1)
        const attachmentsDto = await mapAttachmentDto(nextAttachments)
        onAttachmentsChange(attachmentsDto)
        setAttachments(nextAttachments)
    }
    return (
        <div className={classes.root}>
            <div className={classes.content}>
                <div className={classes.attachments}>
                    {0 < attachments.length ? (
                        attachments.map((a) => (
                            <MessageAttachment
                                key={a.guid}
                                className={classes.chip}
                                classes={{ label: classes.chipLabel }}
                                mailAttachment={a}
                                onDelete={() => handleAttachmentDelete(a)}
                            />
                        ))
                    ) : (
                        <span className={classes.label}>
                            Attachements (max: 60 MB)
                        </span>
                    )}
                </div>
                {validAttachments ? (
                    <Tooltip title="Total size of attachments is over 60 MB">
                        <InfoOutlined color="secondary" />
                    </Tooltip>
                ) : (
                    <IconButton
                        onClick={handleOpenFilePicker}
                        size="small"
                        disabled={disabled}
                        {...dataTestId('ADD_ATTACHMENT')}
                    >
                        <Add />
                    </IconButton>
                )}
            </div>
            <input
                ref={ref}
                type="file"
                style={{ position: 'fixed', top: '-200%' }}
                onChange={handleAttachmentsAdd}
                multiple
            />
        </div>
    )
}
