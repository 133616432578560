import { ExpandMore, FilterList } from '@mui/icons-material'
import {
    Accordion as MuiAccordion,
    AccordionDetails,
    AccordionSummary as MuiAccordionSummary,
    Typography,
} from '@mui/material'
import { makeStyles, withStyles } from '@mui/styles'
import { FC } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { ChipLabel } from '../../../components'
import { FiltersSectionBaseProps } from '../types'

const Accordion = withStyles((theme: AbbTheme) => ({
    root: {
        borderTop: `1px solid ${theme.palette.divider}`,
        width: '100%',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
}))(MuiAccordion)

const AccordionSummary = withStyles((theme: AbbTheme) => ({
    root: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
}))(MuiAccordionSummary)

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        title: {
            margin: theme.spacing(0, 2),
        },
        content: {
            padding: theme.spacing(3, 2),
        },
    }),
    { name: 'FilterSection' }
)

export const FilterSection: FC<FiltersSectionBaseProps> = (props) => {
    const {
        title,
        icon,
        expanded,
        badgeContent,
        onExpand,
        children,
        ...other
    } = props
    const classes = useStyles()
    return (
        <Accordion expanded={expanded} onChange={onExpand} elevation={0} square>
            <AccordionSummary expandIcon={<ExpandMore />} {...other}>
                {icon ?? <FilterList fontSize="small" />}
                <Typography className={classes.title} variant="h6">
                    {title}
                </Typography>
                {badgeContent && (
                    <ChipLabel label={badgeContent} variant="grey" />
                )}
            </AccordionSummary>
            <AccordionDetails className={classes.content}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}
