import {
    createAdminPgRcaCategories,
    deleteAdminPgRcaCategories,
    getAdminPgRcaCategories,
    updateAdminPgRcaCategories,
} from 'api'
import { TreeViewManagePage } from '../components/TreeViewManagePage'

export const PGRCACategoriesManagePage = () => {
    return (
        <TreeViewManagePage
            title="PG RCA Categories"
            desc="This panel allows you to manage trees of categories for Product Groups RCA."
            searchTitle="Search PG RCA Categories"
            nodeNameLabel="PG RCA Category name"
            getNodes={getAdminPgRcaCategories}
            createNode={createAdminPgRcaCategories}
            deleteNode={deleteAdminPgRcaCategories}
            updateNode={updateAdminPgRcaCategories}
            canEditAllNodes={true}
            to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B7A480929-06D9-4724-9599-49B662A565F2%7D&file=CCRP%20Managing%20PG%20RCA%20categories.pptx&action=edit&mobileredirect=true"
        />
    )
}
