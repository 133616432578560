import Error from '@mui/icons-material/Error'
import { FormHelperText, useTheme } from '@mui/material'
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup'
import { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { FilterBase } from '../../types/models'
import FormControlLabel from '../Base/FormControlLabel'
import Radio from '../Base/Radio'

export interface EffectivenessTypeGroupProps extends RadioGroupProps {
    disabled: boolean
    error?: boolean
    helperText?: string
}

export const EffectivenessTypeGroupBase = (
    props: EffectivenessTypeGroupProps
) => {
    const theme = useTheme()
    const effectivenessType = useSelector<any, Array<FilterBase<number>>>(
        (state) => state.resources.effectivenessType
    )
    const {
        disabled,
        value: valueProp,
        onChange,
        error,
        helperText,
        ...other
    } = props
    const valueMapped = useMemo(
        () => effectivenessType.find((x) => x.code === valueProp)?.name ?? null,
        [valueProp]
    )
    const handleChange = (e, value: string) => {
        onChange(
            e,
            String(
                effectivenessType.find((x) => x.name === value)?.code ?? null
            )
        )
    }
    return (
        <RadioGroup value={valueMapped} onChange={handleChange} {...other}>
            {effectivenessType.map((type) => (
                <FormControlLabel
                    disabled={disabled}
                    key={type.code}
                    value={type.name}
                    label={type.name}
                    control={<Radio />}
                />
            ))}
            {helperText && (
                <FormHelperText
                    style={{ marginTop: theme.spacing(1) }}
                    error={error}
                >
                    <span style={{ marginRight: theme.spacing(0.75) }}>
                        <Error fontSize="inherit" />
                    </span>
                    {helperText}
                </FormHelperText>
            )}
        </RadioGroup>
    )
}

const EffectivenessTypeGroup = memo<EffectivenessTypeGroupProps>(
    EffectivenessTypeGroupBase
)

EffectivenessTypeGroupBase.defaultProps = {
    disabled: true,
}

export default EffectivenessTypeGroup
