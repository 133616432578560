import { EditOutlined, Delete } from '@mui/icons-material'
import { Paper, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import theme from 'styles/themeBase'
import { AdminClassification, ClassificationItem } from 'types/adminTypes'
import { dataTestId } from 'utils'

const useStyles = makeStyles(
    (theme) => ({
        header: {
            display: 'inline-flex',
            height: 54,
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            paddingLeft: theme.spacing(2),
            textTransform: 'uppercase',
        },
        labelName: {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        actions: {
            marginRight: theme.spacing(1),
            minWidth: 148,
            textAlign: 'right',
        },
        ulRoot: {
            listStyleType: 'none',
            'padding-inline-start': 0,
            margin: 0,
        },
        liRoot: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: theme.typography.fontWeightBold as number as number,

            ...theme.typography.body2,
        },
        isNotActive: {
            color: theme.palette.grey[400],
            fontWeight: theme.typography.fontWeightRegular as number as number,
        },
    }),
    { name: 'ClassificationsList' }
)

export interface ClassificationsListProps {
    onEditClassification: (item: ClassificationItem) => void
    onDeleteClassification: (item: ClassificationItem) => void
    classifications: AdminClassification
    canEdit: boolean
}

export const ClassificationsList = (props: ClassificationsListProps) => {
    const classes = useStyles()
    const {
        onEditClassification,
        onDeleteClassification,
        classifications,
        canEdit,
    } = props

    return (
        <Paper
            style={{
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
            }}
            elevation={0}
        >
            <ul className={classes.ulRoot}>
                {classifications?.items.map((item) => (
                    <li className={clsx(classes.liRoot)} key={item.id}>
                        <div
                            className={clsx(classes.header)}
                            {...dataTestId(`${item.categoryName}_ITEM_LIST`)}
                        >
                            <span
                                className={clsx(
                                    classes.labelName,
                                    !item.isActive && classes.isNotActive
                                )}
                            >
                                {item.categoryName}
                            </span>
                            <span style={{ flexGrow: 1 }} />
                            {canEdit && (
                                <div className={classes.actions}>
                                    <IconButton
                                        onClick={() => {
                                            onEditClassification(item)
                                        }}
                                        {...dataTestId(`EDIT_CATEGORY_BUTTON `)}
                                    >
                                        <EditOutlined fontSize="small" />
                                    </IconButton>
                                    {item.isActive && (
                                        <IconButton
                                            onClick={() => {
                                                onDeleteClassification(item)
                                            }}
                                            {...dataTestId(
                                                `DELETE_CATEGORY_BUTTON `
                                            )}
                                        >
                                            <Delete fontSize="small" />
                                        </IconButton>
                                    )}
                                </div>
                            )}
                        </div>
                    </li>
                ))}
            </ul>
        </Paper>
    )
}
