import { useTheme } from '@mui/material'
import {
    Button,
    Content,
    ExportButton,
    PageTitle,
    SearchPanel,
    SearchResultsWithPagination,
    Space,
} from 'components'
import SortPanel from 'components/Base/SortPanel'
import { appInsights } from 'configs/appInsights'
import { DashboardFilterGroup, TileActions } from 'Issues'
import {
    getSortProperties,
    sortElements,
    SortPropertiesKeys,
} from 'Issues/components/utils'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    clearError,
    exportMyActions,
    MyActionsStoreState,
} from 'store/myActions/index'
import { GlobalResourcesState } from 'store/resources'
import { RootState } from 'store/types'
import { DashboardFilter } from 'types/models'
import { MyAction, MyAction2 } from 'types/myDashboard'
import { MyActionsQuery } from 'types/queries'
import { dataTestId } from 'utils'
import { NEW_DASHBOARD } from '../features'
import { MyIssueItem } from '../Issues/components/MyIssueItem'
import ActionSelector from '../Issues/Tiles/ActionSelector'

const PAGE_SIZE = 30

export const MY_ACTIONS_FILTERS_STORAGE_KEY = 'ccrp-my-actions-filters'

const getSearchProperties = (): Array<keyof MyAction> => [
    'issueNumber',
    'subject',
]

export const MyActionsPage = () => {
    const dispatch = useDispatch()
    const myActions = useSelector<RootState, MyActionsStoreState>(
        (state) => state?.myActions
    )

    const { loading, updating, items, loadingDesc, error, exportingActions } =
        myActions
    const [page, setPage] = useState(1)
    const [open, setOpen] = useState(true)
    const [wildcard, setWildcard] = useState('')
    const [descending, setDescending] = useState(null)
    const [sortKey, setSortKey] = useState(null)
    const resources = useSelector<RootState, GlobalResourcesState>(
        (state) => state.resources
    )
    const [issueTypesFilters, setIssueTypesFilters] = useState<
        DashboardFilter[]
    >([])
    const [actionsFilters, setActionsFilters] = useState<DashboardFilter[]>([])
    const setDefaultFilters = () => {
        setIssueTypesFilters(
            resources.issueType.map((x) => ({ ...x, selected: true }))
        )
        setActionsFilters(
            resources.action.map((x) => ({ ...x, selected: true }))
        )
    }
    useEffect(() => {
        const data = JSON.parse(
            window.localStorage.getItem(MY_ACTIONS_FILTERS_STORAGE_KEY) ?? null
        ) as {
            open: boolean
            issueTypesFilters: DashboardFilter[]
            actionsFilters: DashboardFilter[]
        }
        if (data) {
            setOpen(data.open)
            setIssueTypesFilters(data.issueTypesFilters)
            setActionsFilters(data.actionsFilters)
        } else {
            setDefaultFilters()
        }
    }, [])
    useEffect(() => {
        window.localStorage.setItem(
            MY_ACTIONS_FILTERS_STORAGE_KEY,
            JSON.stringify({
                open,
                issueTypesFilters,
                actionsFilters,
            })
        )
    }, [open, issueTypesFilters, actionsFilters])
    const theme = useTheme()
    const filtredItems = useMemo(() => {
        const someIssueTypes = issueTypesFilters.some((x) => x.selected)
        const someActions = actionsFilters.some((x) => x.selected)

        let results = Boolean(wildcard)
            ? items.filter((x) => {
                  return Boolean(
                      getSearchProperties().find((key) =>
                          String(x[key])
                              .toLocaleUpperCase()
                              .includes(wildcard.toLocaleUpperCase(), 0)
                      )
                  )
              })
            : items
        if (!someIssueTypes && !someActions) {
            return []
        }
        if (someIssueTypes) {
            results = results.filter((a) => {
                return issueTypesFilters?.some(
                    (t) => t.selected && Number(t.code) === Number(a.issueType)
                )
            })
        }
        if (someActions) {
            results = results.filter((a) => {
                if (NEW_DASHBOARD) {
                    const actonItem = a as MyAction2
                    return actionsFilters?.some(
                        (t) =>
                            t.selected &&
                            Number(t.code) === Number(actonItem.action)
                    )
                } else {
                    const actonItem = a as MyAction
                    return actionsFilters?.some(
                        (t) =>
                            t.selected &&
                            Number(t.code) ===
                                Number(actonItem.action.actionCode)
                    )
                }
            })
        }
        return results
    }, [items, actionsFilters, issueTypesFilters, wildcard])
    const issueTypesCount = useMemo<Record<number, number>>(
        () =>
            items.reduce((prev, a) => {
                const count = prev[a.issueType] ?? 0
                prev[a.issueType] = count + 1
                return prev
            }, {}),
        [items]
    )
    const actionsCount = useMemo(
        () =>
            items.reduce((prev, a) => {
                if (NEW_DASHBOARD) {
                    const item = a as MyAction2
                    const count = prev[item.action] ?? 0
                    prev[item.action] = count + 1
                    return prev
                } else {
                    const item = a as MyAction
                    const count = prev[item.action.actionCode] ?? 0
                    prev[item.action.actionCode] = count + 1
                    return prev
                }
            }, {}),
        [items]
    )
    const handleClearFilters = () => {
        const clearFilters = (
            filters: DashboardFilter[]
        ): DashboardFilter[] => {
            filters.forEach((x) => (x.selected = false))
            return [...filters]
        }
        setIssueTypesFilters(clearFilters)
        setActionsFilters(clearFilters)
    }
    const handleExportIssuesToExcel = () => {
        const query: MyActionsQuery = {
            actionCodes: actionsFilters
                .filter((x) => x.selected)
                .map((x) => x.code),
            issueTypes: issueTypesFilters
                .filter((x) => x.selected)
                .map((x) => x.code),
        }
        dispatch(exportMyActions(query))
        appInsights.trackEvent({
            name: 'Export to excel',
        })
    }

    const sortedFiltredItems = useMemo(() => {
        if (NEW_DASHBOARD) {
            const newFiltered = [...filtredItems] as MyAction2[]
            sortElements(newFiltered as MyAction2[], sortKey, descending)
            return newFiltered
        } else return filtredItems
    }, [sortKey, filtredItems, descending])

    return (
        <Content
            coverBackground={true}
            loading={updating}
            loadingDesc={loadingDesc}
            error={error}
            label="Reload"
            title="My Actions"
            onRetry={() => {
                dispatch(clearError())
            }}
            variant="medium"
        >
            <PageTitle
                title="My actions"
                desc="This dashboard presents all issues in which your action is required."
                to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B56C84027-3B1D-46B4-BC26-71BEA984B2A8%7D&file=CCRP%20Light%20-%20Dashboard%20overview.pptx&action=edit&mobileredirect=true"
            />
            <SearchPanel
                open={open}
                onOpen={setOpen}
                placeholder="Search in visible issues..."
                searchText={wildcard}
                onChange={setWildcard}
                onClearText={() => setWildcard('')}
                renderEndAdornment={() =>
                    NEW_DASHBOARD ? (
                        <SortPanel
                            sortKeys={getSortProperties()}
                            selectedSortKey={SortPropertiesKeys.taregetDate}
                            selectedDescending={false}
                            onDescendingChange={setDescending}
                            onSortKeyChange={setSortKey}
                        />
                    ) : null
                }
                renderActions={() => (
                    <>
                        <Button
                            onClick={handleClearFilters}
                            {...dataTestId('MY_ACTIONS_CLEAR')}
                        >
                            Clear
                        </Button>
                        <Button
                            onClick={setDefaultFilters}
                            {...dataTestId('MY_ACTIONS_SET_TO_DEFAULT')}
                        >
                            Set to default
                        </Button>
                    </>
                )}
            >
                <DashboardFilterGroup
                    filters={issueTypesFilters}
                    onFilterSelect={(filter) => {
                        setIssueTypesFilters((prev) =>
                            prev.map((a) =>
                                a.code === filter.code ? filter : a
                            )
                        )
                        setPage(1)
                    }}
                    title="Issue types"
                    style={{ marginBottom: theme.spacing(2) }}
                    getCount={(f) => issueTypesCount[f.code]}
                />
                <DashboardFilterGroup
                    filters={actionsFilters}
                    onFilterSelect={(filter) => {
                        setActionsFilters((prev) =>
                            prev.map((a) =>
                                a.code === filter.code ? filter : a
                            )
                        )
                        setPage(1)
                    }}
                    title="Actions"
                    getCount={(f) => actionsCount[f.code]}
                />
            </SearchPanel>
            <SearchResultsWithPagination
                page={page}
                pageSize={PAGE_SIZE}
                onPageChange={setPage}
                isSearching={updating ? false : loading}
                itemCount={filtredItems.length}
                errorVisible={filtredItems.length === 0}
                errorTitle="No actions to display!"
                renderAction={() => (
                    <ExportButton
                        loading={exportingActions}
                        onClick={handleExportIssuesToExcel}
                    >
                        Export to Excel
                    </ExportButton>
                )}
            >
                {sortedFiltredItems
                    .filter((_, i) => {
                        return (
                            PAGE_SIZE * (page - 1) <= i && i < PAGE_SIZE * page
                        )
                    })
                    .map((x) => {
                        if (NEW_DASHBOARD) {
                            const item = x as MyAction2
                            return (
                                <MyIssueItem
                                    key={`${item.action}-${item.issueId}`}
                                    data={item}
                                    onOpenIssue={() => {
                                        window.open(`/issue/${item.issueId}`)
                                        appInsights.trackEvent({
                                            name: `IssueNumber - ${item.issueNumber} on My Action`,
                                        })
                                    }}
                                    renderActions={() => (
                                        <TileActions
                                            myActionMeta={item}
                                            issueNumber={item?.issueNumber}
                                        />
                                    )}
                                />
                            )
                        } else {
                            const item = x as MyAction
                            return (
                                <ActionSelector
                                    key={`${item.action}-${item.issueId}`}
                                    actionIssue={item}
                                />
                            )
                        }
                    })}
                <Space />
            </SearchResultsWithPagination>
        </Content>
    )
}

export default MyActionsPage
