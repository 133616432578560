import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Fragment } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FollowUpDetails } from 'types/issueTypes'
import { FollowUpMethodChip, Label } from '../../../components'
import { getArray } from '../../../utils'

const useStyles = makeStyles((theme: AbbTheme) => ({
    header: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: theme.typography.fontWeightBold as number,
    },
    aggreedAction: {
        marginTop: theme.spacing(0.5),
    },
    followUpBy: {
        marginLeft: theme.spacing(1),
    },
    updates: {
        marginLeft: theme.spacing(1.5),
    },
    from: {
        fontWeight: theme.typography.fontWeightMedium as number,
        marginTop: theme.spacing(1),
        '&:first-child': {
            marginTop: 0,
        },
    },
    text: {
        marginTop: theme.spacing(0.5),
    },
}))

export interface FollowUpActionProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    details: FollowUpDetails
}

export const FollowUpAction = (props: FollowUpActionProps) => {
    const { details, ...other } = props
    const classes = useStyles(props)
    const confirmedBy = details?.confirmedBy
    const confirmedByName = `${confirmedBy?.name} ${
        confirmedBy?.email && '(' + confirmedBy.email + ')'
    }`
    const aggreedAction = details?.aggreedAction
    const followUpBy = details?.followUpBy
    const updates = getArray(details, 'updates')
    return (
        <div {...other}>
            <div className={classes.header}>
                {confirmedByName}{' '}
                <FollowUpMethodChip
                    className={classes.followUpBy}
                    followUpByMethod={followUpBy}
                />
            </div>
            <Typography className={classes.aggreedAction} variant="body2">
                {aggreedAction}
            </Typography>
            {updates && (
                <Fragment>
                    <Label>Follow-up updated by</Label>
                    <div className={classes.updates}>
                        {updates.map((u, i) => {
                            const from = u?.from
                            const text = u?.text
                            const fromName = `${from?.name} ${
                                from?.email && '(' + from.email + ')'
                            }`
                            return (
                                <Fragment key={i}>
                                    <Typography
                                        className={classes.from}
                                        variant="body2"
                                    >
                                        {fromName}
                                    </Typography>
                                    <Typography
                                        className={classes.text}
                                        variant="caption"
                                    >
                                        {text}
                                    </Typography>
                                </Fragment>
                            )
                        })}
                    </div>
                </Fragment>
            )}
        </div>
    )
}

export default FollowUpAction
