import { useSelector } from 'react-redux'
import { CcrpResourcesStore } from 'pages/AppResourcesLoader'
import { FilterBase } from 'types/models'
import ChipLabel, { ChipLabelProps } from './ChipLabel'

export interface ActionChipLabelProps extends ChipLabelProps {
    actionCode: number
}

export const ActionChipLabel = (props: ActionChipLabelProps) => {
    const actions = useSelector<CcrpResourcesStore, FilterBase<number>[]>(
        (state) => state.resources.action
    )
    const { actionCode, ...other } = props
    const actionItem = actions.find(
        (item) => String(item.code) === String(actionCode)
    )
    const actionLabel = actionItem && actionItem.name
    return actionLabel ? <ChipLabel {...other} label={actionLabel} /> : null
}

export default ActionChipLabel
