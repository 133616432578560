import GroupIcon from '@mui/icons-material/Group'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import PersonIcon from '@mui/icons-material/Person'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { Employee } from 'types/issueTypes'
import { dataTestId } from 'utils'
import { AssignedUsersGroup } from '.'

export interface AssignedUsersProps {
    issueOwner: Employee[]
    resolutionOwner: Employee
    coordinators: Employee[]
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        users: {
            padding: theme.spacing(1),
            backgroundColor: theme.palette.grey[100],
            borderRadius: theme.shape.borderRadius * 6,
            border: `1px solid ${theme.palette.grey[300]}`,
            margin: theme.spacing(1),
            fontWeight: 800,
        },
    }),
    { name: 'AssignedUsers' }
)

export const AssignedUsers = (props: AssignedUsersProps) => {
    const classes = useStyles()
    const { issueOwner, resolutionOwner, coordinators } = props

    return (
        <div className={clsx(classes.root)}>
            {issueOwner && (
                <AssignedUsersGroup
                    usersGroup={issueOwner}
                    label={'Issue Owner: '}
                    renderIcon={(props) => (
                        <PeopleAltIcon style={{ ...props }} />
                    )}
                    {...dataTestId('ISSUE_TILE_ISSUE_OWNER')}
                ></AssignedUsersGroup>
            )}
            {resolutionOwner && (
                <AssignedUsersGroup
                    usersGroup={[resolutionOwner]}
                    label={'Resolution Owner: '}
                    renderIcon={(props) => <PersonIcon style={{ ...props }} />}
                    {...dataTestId('ISSUE_TILE_RESOLUTION_OWNER')}
                ></AssignedUsersGroup>
            )}
            {coordinators && (
                <AssignedUsersGroup
                    usersGroup={coordinators}
                    label={'Coordinators: '}
                    renderIcon={(props) => <GroupIcon style={{ ...props }} />}
                    {...dataTestId('ISSUE_TILE_COORDINATORS')}
                ></AssignedUsersGroup>
            )}
        </div>
    )
}
