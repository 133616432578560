import {
    FormControl,
    FormControlLabel,
    FormControlProps,
    InputLabel,
    Radio,
    RadioGroup,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import { ReportRepeatBy } from 'types/reportTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            display: 'flex',
            flexDirection: 'row',
            margin: theme.spacing(2, 0),
        },
        item: {
            marginRight: theme.spacing(2),
        },
    }),
    { name: 'RepeatInSelect' }
)

export interface RepeatBySelectProps
    extends Omit<FormControlProps, 'onChange'> {
    value: ReportRepeatBy
    onChange: (value: ReportRepeatBy) => void
}

export const RepeatBySelect = (props: RepeatBySelectProps) => {
    const { value, onChange, ...other } = props
    const classes = useStyles()
    return (
        <FormControl fullWidth {...other}>
            <InputLabel>Repeat by</InputLabel>
            <RadioGroup
                className={classes.content}
                value={value}
                onChange={(_, value) => onChange(value as ReportRepeatBy)}
            >
                <FormControlLabel
                    className={classes.item}
                    value="m"
                    control={<Radio />}
                    label="day of the month"
                />
                <FormControlLabel
                    className={classes.item}
                    value="w"
                    control={<Radio />}
                    label="day of the week"
                />
            </RadioGroup>
        </FormControl>
    )
}
