import { Apartment } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { ChangeEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput } from './components/AutoCompleteInput'
import { getOptionLabel, getOptionSelected } from './utils'

export interface MultiDivisionSelectProps extends Partial<BaseTextFieldProps> {
    selectedDivisions: FilterBase[]
    onBusinessAreasChanged: (selectedDivisions: FilterBase[]) => void
    displayInactive?: boolean
}

export const MultiDivisionSelect = (props: MultiDivisionSelectProps) => {
    const {
        selectedDivisions = [],
        onBusinessAreasChanged,
        displayInactive = false,
        disabled,
        ...other
    } = props
    const allOptions = useSelector(
        (state: RootState) => state.resources.divisions
    )
    const options = useMemo(
        () => allOptions.filter((x) => (displayInactive ? true : x.isActive)),
        [allOptions]
    )
    const handleOnChange = (
        e: ChangeEvent<any>,
        values: Array<string | FilterBase>
    ) =>
        onBusinessAreasChanged &&
        onBusinessAreasChanged([...values] as Array<FilterBase>)

    return (
        <Autocomplete
            disabled={disabled}
            multiple={true}
            value={selectedDivisions}
            options={options}
            clearOnBlur={true}
            onChange={handleOnChange}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            disableClearable={disabled}
            noOptionsText={'No match Business Areas'}
            renderOption={(props, option) => (
                <li {...props}>{getOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                        ></Chip>
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label="Business Areas"
                    {...dataTestId('BUSINESS_AREAS')}
                    renderIcon={(props) => <Apartment {...props} />}
                    {...other}
                />
            )}
        />
    )
}
