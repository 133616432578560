import { useTheme } from '@mui/material'
import { ChipLabel } from 'components'
import { useMemo } from 'react'
import { ActionItemBase } from 'types/actionPlanTypes'
import { ActionPlanStatusChip } from '.'

export const useActionPlanStatusChips = (item: ActionItemBase) => {
    const theme = useTheme()
    return useMemo(() => {
        const { actionStatus, isRejected, isReopened, isDeleted } = item
        return (
            <>
                {isRejected && (
                    <ChipLabel
                        style={{ marginRight: theme.spacing(1) }}
                        label="Rejected"
                        variant="red"
                    />
                )}
                {isReopened && (
                    <ChipLabel
                        style={{ marginRight: theme.spacing(1) }}
                        label="Reopened"
                        variant="red"
                    />
                )}
                {isDeleted && (
                    <ChipLabel
                        style={{ marginRight: theme.spacing(1) }}
                        label="Deleted"
                        variant="red"
                    />
                )}
                <ActionPlanStatusChip status={actionStatus} />
            </>
        )
    }, [item])
}
