import { ArrowBack } from '@mui/icons-material'
import { AppBar, IconButton, Theme, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useHistory } from 'react-router-dom'
import { dataTestId } from 'utils'

export interface PageAppBarProps {
    title: string
    caption?: string
}

export const useStyles = makeStyles<Theme>(
    (theme) => ({
        title: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            margin: theme.spacing(0, 2),
            overflow: 'hidden',
        },
        ellipsis: {
            display: 'inline',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
    }),
    { name: 'PageAppBar' }
)

export const PageAppBar = (props: PageAppBarProps) => {
    const { title, caption } = props
    const classes = useStyles()
    const history = useHistory()
    return (
        <>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        {...dataTestId('BACK_BUTTON')}
                        onClick={() => history.goBack()}
                        size="large"
                    >
                        <ArrowBack />
                    </IconButton>
                    <div className={classes.title}>
                        <Typography
                            className={classes.ellipsis}
                            variant={caption ? 'subtitle2' : 'subtitle1'}
                        >
                            {title}
                        </Typography>
                        {Boolean(caption) && (
                            <Typography
                                className={classes.ellipsis}
                                variant="caption"
                            >
                                {caption}
                            </Typography>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar />
        </>
    )
}
