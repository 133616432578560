import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { ContentFixedProps } from './types'

const useStyles = makeStyles(
    () => ({
        root: {
            position: 'absolute',
            left: '50%',
            top: 'calc(50vh - 64px)',
            width: '100%',
            zIndex: 120,
        },
        fixed: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'fixed',
            transform: 'translate(-50%, -50%)',
        },
    }),
    { name: 'ContentFixed' }
)

export const ContentFixed: FC<ContentFixedProps> = (props) => {
    const { className, children, ...other } = props
    const classes = useStyles(props)

    return (
        <div className={clsx(classes.root, className)} {...other}>
            <div className={classes.fixed}>{children}</div>
        </div>
    )
}

export default ContentFixed
