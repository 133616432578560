import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { ContentOverlayProps } from './types'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        overlay: {
            display: 'block',
            position: 'absolute',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            backgroundColor: theme.palette.grey[100],
            opacity: 0.86,
            '&$cover': {
                opacity: 1,
            },
            zIndex: 100,
        },
        cover: {},
    }),
    { name: 'ContentOverlay' }
)

export const ContentOverlay: FC<ContentOverlayProps> = (props) => {
    const { className, children, ...other } = props
    const classes = useStyles(props)
    return <div className={clsx(classes.overlay, className)} {...other}></div>
}

export default ContentOverlay
