import axios, { AxiosResponse } from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
    loadIssueDone,
    ISSUE_LOAD_CALL,
    setIssueWarnings,
    setLightMode,
    getHighImpact,
} from '..'
import { getIssue } from 'api'
import {
    AttachmentTypes,
    FailureModeValidation,
    IssueActionsTypes,
    IssueTypes,
    TargetResolvingDateChangeReasons,
} from 'types/enums'
import { Attachment, CaptureSection, Issue } from 'types/issueTypes'
import { LoadIssueCommand } from '../types'
import {
    setIssueActionFail,
    loadActionPlan as loadActionPlanAction,
} from '../actions'
import { ISSUE_NEW } from 'routes'
import { getPrettyDateTime } from 'utils'
import { msalInstance } from 'configs/authProvider'

export const checkInitLighMode = (
    captureSection: CaptureSection,
    attachments: Array<Attachment> = []
): boolean => {
    if (!captureSection) {
        return false
    }

    const isUnitInternalNcIssue = Boolean(
        captureSection?.issueType === IssueTypes.Internal_NC
    )
    const failureModeValidation = captureSection?.failureModeValidation
    switch (true) {
        case !isUnitInternalNcIssue &&
            Boolean(captureSection?.highImpact?.isHighImpact):
        case !isUnitInternalNcIssue &&
            Boolean(captureSection?.isContainmentActionRequired):
        case Boolean(captureSection?.isCopySentToParties):
        case Boolean(
            attachments
                ?.filter(
                    (a) => a.attachmentType === AttachmentTypes.Information
                )
                .some(() => true)
        ):
        case !isUnitInternalNcIssue && Boolean(captureSection?.quantity):
        case Boolean(captureSection?.customerReference):
        case Boolean(captureSection?.supplier?.name):
        case Boolean(captureSection?.supplier?.guid):
        case Boolean(captureSection?.commissioningDate):
        case Boolean(captureSection?.manufacturingDate):
        case failureModeValidation !== FailureModeValidation.SerialNo &&
            Boolean(captureSection?.serialNo):
        case failureModeValidation !== FailureModeValidation.PurchaseOrder &&
            Boolean(captureSection?.purchaseOrderNo):
        case failureModeValidation !== FailureModeValidation.ABBProject &&
            Boolean(captureSection?.abbProject):
        case failureModeValidation !== FailureModeValidation.ABBProject &&
            Boolean(captureSection?.projectName):
        case Boolean(captureSection?.warranty?.startDate):
        case Boolean(captureSection?.warranty?.endDate):
        case Boolean(captureSection?.warranty?.reference):
        case !isUnitInternalNcIssue &&
            Boolean(captureSection?.isRootCauseRequiredByCustomer):
        case Boolean(
            captureSection?.issueOwner.email !==
                msalInstance.getActiveAccount().username ?? ''
        ):
        case Boolean(captureSection?.issueOwnerTeam?.length):
        case Boolean(captureSection?.proposedResolutionOwner?.email):
        case Boolean(captureSection?.productLineLocationId):
        case Boolean(captureSection?.behalfBuCode):
        case Boolean(captureSection?.behalfCountryCode):
            return false
        default:
            return true
    }
}

export function* checkIssueWarrnings(issue: Issue) {
    const warrnings: Set<string> = new Set()
    const actions = issue?.context?.actions
    const { isHighImpact, validationDeadlineDate, validationActionDate } =
        issue?.captureSection?.highImpact ?? getHighImpact()

    if (isHighImpact && validationDeadlineDate && !validationActionDate) {
        warrnings.add(
            `This issue is pending High Impact validation. Deadline: ${getPrettyDateTime(
                validationDeadlineDate
            )}`
        )
    }

    if (actions && 0 < actions.length) {
        actions.some(
            (a) => a.actionCode === IssueActionsTypes.To_Assign_Updated_RU
        ) &&
            warrnings.add(
                'The Resolving Unit is inactive, please update it before assigning issue to the Resolution Owner.'
            )
        actions.some(
            (a) => a.actionCode === IssueActionsTypes.To_Replace_Inactive_RO
        ) &&
            warrnings.add(
                'The Resolution Owner is inactive, please provide the new name.'
            )
        actions.some(
            (a) => a.actionCode === IssueActionsTypes.To_Replace_Inactive_IO
        ) &&
            warrnings.add(
                'The Issue Owner is inactive, please provide the new name.'
            )
        actions.some(
            (a) => a.actionCode === IssueActionsTypes.To_Replace_Inactive_RU
        ) && warrnings.add('The Resolving Unit is inactive, please update it.')
    }
    yield put(
        setIssueWarnings(0 < warrnings.size ? [...warrnings.values()] : [])
    )
}

export function* loadIssueAction(action: LoadIssueCommand) {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueIdOrNumber, loadActionPlan } = action.payload
        const { data: issue } = (yield call(
            getIssue,
            issueIdOrNumber,
            cancelSource.token
        )) as AxiosResponse<Issue>
        if (loadActionPlan) {
            yield put(loadActionPlanAction(issue.issueId))
        }
        yield checkIssueWarrnings(issue)
        issue.context.myRoles = [...issue.context.myRoles]
        issue.captureSection.targetResolutionDateChangeReasonId =
            TargetResolvingDateChangeReasons.MoreTimeRequired
        const isNewIssue =
            window.location.pathname.toLocaleLowerCase() ===
            ISSUE_NEW.toLocaleLowerCase()
        if (isNewIssue) {
            return
        }
        yield put(setLightMode(false))
        yield put(loadIssueDone(issue))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_LOAD_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* loadIssueSaga() {
    yield takeLatest(ISSUE_LOAD_CALL, loadIssueAction)
}
