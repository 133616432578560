import { Theme, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { CardViewResultItemProps, ValueViewNext } from 'components'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'CardViewResultItem' }
)

export const CardViewResultItem = (props: CardViewResultItemProps) => {
    const classes = useStyles()
    const { result } = props
    const { label, text, tooltip, idTest, ...other } = result

    const renderView = (className?: string) => (
        <ValueViewNext
            className={className}
            key={label}
            label={label}
            {...dataTestId(idTest)}
            {...other}
        >
            <Typography variant="body2" component="div">
                {text}
            </Typography>
        </ValueViewNext>
    )

    return tooltip ? (
        <Tooltip title={tooltip} className={classes.root}>
            {renderView()}
        </Tooltip>
    ) : (
        <>{renderView(classes.root)}</>
    )
}
