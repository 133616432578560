import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    completeIssueDone,
    setIssueActionFail,
    ISSUE_COMPLETE_CALL,
    IssueStoreState,
    loadIssue,
} from '..'

import { RootState } from 'store/types'
import { completeIssue } from 'api'
import { removeMyActionById } from 'store/myActions'
import { showSnackbar } from 'store/app'
import { IssueActionsTypes } from 'types/enums'

export function* completeIssueAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { closingSection, issueId, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        yield call(
            completeIssue,
            closingSection,
            changeLog?.closed ?? null,
            cancelSource.token
        )
        yield put(completeIssueDone())
        yield put(removeMyActionById(issueId, IssueActionsTypes.To_Complete))
        yield put(loadIssue(issueId))
        yield put(showSnackbar('Issue was completed'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_COMPLETE_CALL, error))
        yield put(showSnackbar('Cannot complete issue!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* completeIssueSaga() {
    yield takeLatest([ISSUE_COMPLETE_CALL], completeIssueAction)
}
