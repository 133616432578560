import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import { ActionItem } from 'types/actionPlanTypes'
import { DashboardColumnProps, DashboardHeader } from '..'
import DashboardItem from '../DashboardItem'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(0, 2),
        },
        item: { marginBottom: theme.spacing(4) },
    }),
    { name: 'DashboardMobileColumn' }
)

export const DashboardMobileColumn = (props: DashboardColumnProps) => {
    const { column, items, onClickLabel, onMouseMoveLabel, onMouseDownLabel } =
        props
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <DashboardHeader column={column} count={items.length} />
            {items.map((item: ActionItem) => (
                <DashboardItem
                    onClickLabel={onClickLabel}
                    onMouseMoveLabel={onMouseMoveLabel}
                    onMouseDownLabel={onMouseDownLabel}
                    key={item.actionItemId}
                    className={classes.item}
                    item={item}
                />
            ))}
        </div>
    )
}

export default DashboardMobileColumn
