import { ArrowDropDown } from '@mui/icons-material'
import { ButtonBase, ButtonBaseProps, Popover } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { MouseEvent, ReactNode, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            minWidth: 56,
            height: 36,
            borderRadius: theme.shape.borderRadius,
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightBold as number,
            padding: theme.spacing(0, 0.5),
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        content: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: 1,
        },
        arrowDropDown: {
            width: 16,
            height: 16,
        },
    }),
    { name: 'OptionsButton' }
)

export interface OptionsButtonProps extends ButtonBaseProps {
    renderOptions: (close: () => void) => ReactNode
}

export const OptionsButton = (props: OptionsButtonProps) => {
    const { className, children, onClick, renderOptions, ...other } = props
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const classes = useStyles()
    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
        e.preventDefault()
        onClick && onClick(e)
    }
    const handleClose = () => setAnchorEl(null)
    return (
        <>
            <ButtonBase
                onClick={handleClick}
                onMouseDown={(e) => e.preventDefault()}
                className={clsx(className, classes.root)}
                {...other}
            >
                <div className={classes.content}>{children}</div>
                <ArrowDropDown className={classes.arrowDropDown} />
            </ButtonBase>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                disableEnforceFocus
                disableAutoFocus
            >
                {renderOptions(handleClose)}
            </Popover>
        </>
    )
}
