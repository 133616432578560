import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            minHeight: 56,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            '&$expanded': {
                minHeight: 48,
            },
        },
        expanded: {},
        content: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            '&$expanded': {
                marginTop: theme.spacing(2),
                marginBottom: theme.spacing(2),
            },
        },
        expandIcon: {},
    }),
    { name: 'AccordionSummary' }
)

export const AccordionSummary = (props: AccordionSummaryProps) => {
    const classes = useStyles()
    return <MuiAccordionSummary {...props} classes={classes} />
}
AccordionSummary.muiName = 'AccordionSummary'

export default AccordionSummary
