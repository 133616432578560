import { LocalActivityOutlined } from '@mui/icons-material'
import { AutocompleteChangeReason, BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip } from 'components'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { Activity } from 'types/actions'
import { FilterBase } from 'types/models'
import { dataTestId } from 'utils'
import { AutoCompleteInput, FieldItem } from '.'

export interface MultiActivitySelectProps extends Partial<BaseTextFieldProps> {
    selectedActivities: FilterBase[]
    onActivitiesChanged: (items: FilterBase[]) => void
}

export const MultiActivitySelect = (props: MultiActivitySelectProps) => {
    const {
        label = 'Activities',
        disabled,
        selectedActivities,
        onActivitiesChanged,
        error: errorProps,
        helperText: helperTextProps,
        ...other
    } = props
    const options = useSelector(
        (state: RootState) => state.resources.activities
    )
    const handleSelectFilter = (item: FilterBase[]) => onActivitiesChanged(item)
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => option?.code === value?.code
    const getOptionLabel = (option: FilterBase): string => option?.name ?? ''
    return (
        <Autocomplete
            multiple
            disabled={disabled}
            value={selectedActivities ?? []}
            autoSelect={true}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match Activities'}
            disableClearable={false}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          f.name
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValues = newValue as FilterBase[]
                switch (reason) {
                    case 'clear':
                        handleSelectFilter([])
                        break
                    default:
                        handleSelectFilter([...selectedValues])
                }
            }}
            renderOption={(props, option) => (
                <li {...props}>
                    <FieldItem
                        name={option?.name}
                        caption={(option as Activity)?.desc}
                    />
                </li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip label={option.name} {...getTagProps({ index })} />
                    )
                })
            }
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label={label}
                    {...dataTestId('ACTIVITIES_COMMON')}
                    renderIcon={(props) => <LocalActivityOutlined {...props} />}
                    {...other}
                />
            )}
        />
    )
}

export default MultiActivitySelect
