import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AdminResolvingUnit } from 'types/adminTypes'
import { formatAddress, generateExcelFromTemplate1 } from 'utils'
import { ExportButton } from 'components'

export interface ResolvingUnitsManagePageExcelExportProps {
    adminResolvingUnits: AdminResolvingUnit[]
    isSearching: boolean
}

export const ResolvingUnitsManagePageExcelExport = (
    props: ResolvingUnitsManagePageExcelExportProps
) => {
    const dispatch = useDispatch()
    const { adminResolvingUnits, isSearching } = props
    const [loading, setLoading] = useState(false)

    const excelExport = async () => {
        setLoading(true)
        try {
            generateExcelFromTemplate1<AdminResolvingUnit>(
                {
                    columns: [
                        {
                            name: 'BalId ',
                            getValue: ({ balId }) => balId,
                        },
                        {
                            name: 'BA',
                            getValue: ({ divisionCode }) => divisionCode,
                        },
                        {
                            name: 'DIV',
                            getValue: ({ businessUnitCode }) =>
                                businessUnitCode,
                        },
                        {
                            name: 'PG',
                            getValue: ({ productGroupCode }) =>
                                productGroupCode,
                        },
                        {
                            name: 'PG Description',
                            getValue: ({ productGroupName }) =>
                                productGroupName,
                        },
                        {
                            name: 'LocationActivity ',
                            getValue: ({ locationActivity }) =>
                                locationActivity?.name,
                        },
                        {
                            name: 'AbacusCode',
                            getValue: ({ abacusCode }) => abacusCode,
                        },
                        {
                            name: 'DescriptiveName ',
                            getValue: ({ descriptiveName }) => descriptiveName,
                        },
                        {
                            name: 'Address',
                            getValue: ({ address }) => formatAddress(address),
                        },
                        {
                            name: 'IsActive',
                            getValue: ({ isActive }) =>
                                isActive ? 'True' : 'False',
                        },
                        {
                            name: 'IsGlobal',
                            getValue: ({ isGlobal }) =>
                                isGlobal ? 'True' : 'False',
                        },
                        {
                            name: 'RuProcessOwners ',
                            getValue: ({ processOwners }) =>
                                processOwners &&
                                processOwners
                                    .map((item) => item?.email)
                                    .join('; '),
                        },
                        {
                            name: 'Coordinators',
                            getValue: ({ coordinators }) =>
                                coordinators &&
                                coordinators
                                    .map((item) => item?.email)
                                    .join('; '),
                        },
                    ],
                },
                adminResolvingUnits,
                {
                    fileName: 'export-resolving-units',
                }
            )
        } catch {
            dispatch(showSnackbar('Export failed'))
        } finally {
            dispatch(showSnackbar('Export completed successfully'))
            setTimeout(() => setLoading(false), 1000)
        }
    }

    return (
        adminResolvingUnits.length > 0 && (
            <ExportButton
                loading={loading}
                disabled={isSearching}
                onClick={excelExport}
            >
                Export to Excel
            </ExportButton>
        )
    )
}
