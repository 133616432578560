import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            position: 'relative',
        },
        list: {
            borderTop: `1px solid ${theme.palette.divider}`,
            margin: 0,
            padding: 0,
            zIndex: theme.zIndex.modal,
            top: 42,
            left: theme.spacing(2),
            right: theme.spacing(2),
            position: 'absolute',
            listStyle: 'none',
            backgroundColor: theme.palette.background.paper,
            overflow: 'auto',
            maxHeight: 280,
            boxShadow: theme.shadows[4],
            '& li': {
                display: 'flex',
                flexDirection: 'column',
                padding: theme.spacing(1),
            },
            '& li[data-focus="true"]': {
                backgroundColor: theme.palette.grey[100],
                cursor: 'pointer',
            },
            '& li[aria-selected="true"]': {
                backgroundColor: theme.palette.grey[100],
                cursor: 'pointer',
            },
            '& li:active': {
                backgroundColor: theme.palette.grey[100],
            },
        },
        chip: {
            position: 'relative',
            display: 'flex',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            margin: theme.spacing(0.5, 1, 0.5, 0),
        },
        chipLabel: {
            display: 'block',
            width: 'auto',
            maxWidth: '100%',
        },
        arrowIcon: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'SendMessageSelect' }
)
