import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        overlay: {
            display: 'block',
            position: 'fixed',
            zIndex: 100,
            width: '100%',
            height: '100vh',
            backgroundColor: theme.palette.common.white,
            opacity: 0.64,
        },
        loading: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 120,
            textAlign: 'center',
        },
        label: {
            marginTop: theme.spacing(2),
        },
    }),
    { name: 'ContentLoader' }
)

export interface ContentLoaderProps {
    loading: boolean
    label?: string
    overlay?: boolean
}

export const ContentLoader = (props: ContentLoaderProps) => {
    const { loading, label, overlay = true } = props
    const classes = useStyles(props)
    return loading ? (
        <>
            {overlay && <div className={classes.overlay} />}
            <div className={classes.loading}>
                <div>
                    <CircularProgress color="secondary" size={32} />
                    <div className={classes.label}>{label}</div>
                </div>
            </div>
        </>
    ) : null
}

ContentLoader.defaultProps = {
    loading: false,
}

export default ContentLoader
