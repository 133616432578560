import { DialogOrganizationalInfo, ProcessOwnersManagePageFields } from '.'

export const getInitQueryProcessOwnersManagePage =
    (): ProcessOwnersManagePageFields => {
        return {
            employee: null,
            businessAreas: [],
            divisions: [],
            productGroups: [],
            regions: [],
            countries: [],
        }
    }

export const getInitDialogOrganizationalInfo =
    (): DialogOrganizationalInfo => ({
        businessUnit: null,
        division: null,
        productGroup: null,
    })
