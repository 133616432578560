import { BusinessCenterOutlined, FlagOutlined } from '@mui/icons-material'
import { AccordionActions } from '@mui/material'
import { Button, CardView, CardViewResults, ChipLabel } from 'components'
import { useMemo } from 'react'

import { dataTestId, EMPTY_VALUE_PLACEHOLDER } from 'utils'
import { CustomerSearchItemProps } from './types'

const CustomerSearchItem = (props: CustomerSearchItemProps) => {
    const { customer, onSelect, ...other } = props
    const title = customer?.name ?? ''
    const tagName = customer?.guid
    const results: CardViewResults = useMemo(
        () => [
            {
                label: 'Address',
                renderIcon: (props) => <FlagOutlined {...props} />,
                text: customer?.address?.full ?? EMPTY_VALUE_PLACEHOLDER,
                ...dataTestId('CUSTOMER_PICKER_ITEM_ADDRESS'),
            },
            {
                label: 'Global HQ Guid',
                renderIcon: (props) => <BusinessCenterOutlined {...props} />,
                text: customer?.headquarterGuid ?? EMPTY_VALUE_PLACEHOLDER,
                ...dataTestId('CUSTOMER_PICKER_ITEM_GLOBAL_HQ'),
            },
        ],
        [customer]
    )

    return (
        <CardView
            results={results}
            title={title}
            {...other}
            renderTag={() => <ChipLabel label={tagName} variant="grey" />}
            renderActions={() => (
                <AccordionActions>
                    <Button
                        onClick={() => onSelect(customer)}
                        color="secondary"
                        {...dataTestId('CUSTOMER_PICKER_ITEM_SELECT')}
                    >
                        Select
                    </Button>
                </AccordionActions>
            )}
        ></CardView>
    )
}

export default CustomerSearchItem
