import axios, { AxiosResponse, CancelToken } from 'axios'
import { RevertToNotAssignedQuery } from '../types/actions'
import { ResolutionSection } from 'types/issueTypes'
import { IssueChangeLog } from 'store/issue'

const controllerName = '/issue'

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const saveResolutionSection = (
    query: ResolutionSection,
    changeLog: IssueChangeLog,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ResolutionSection>> =>
    axios.put(
        `${controllerName}/${query.issueId}/resolution`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const resolveIssue = (
    query: ResolutionSection,
    changeLog: IssueChangeLog,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ResolutionSection>> =>
    axios.post(
        `${controllerName}/${query.issueId}/actions/resolve`,
        { ...query, changeLog },
        { cancelToken }
    )

/**
 * @param {number} issueId - Issue id
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const getResolutionSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<ResolutionSection>> =>
    axios.get(`${controllerName}/${issueId}/resolution`, { cancelToken })

/**
 * @param {object} query
 * @param {CancelToken} cancelToken - Axios CancelToken
 *  @returns {Promise} Axios promise of PUT request
 */
export const revertToNotAssigned = (
    query: RevertToNotAssignedQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(
        `${controllerName}/${query.issueId}/actions/revert-to-not-assigned`,
        { ...query },
        { cancelToken }
    )
