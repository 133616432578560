import axios, { AxiosResponse, CancelToken } from 'axios'
import { IssueRoles, MessageTemplateType } from 'types/enums'
import { MessageTemplate, MessageTemplateMeta } from '../types/models'
import { IssueMailMessage } from '../types/sendMessageTypes'

const controllerName = '/messageTemplates'

export const getMessageTemplates = (
    roles: IssueRoles[],
    messageTemplateType: MessageTemplateType = MessageTemplateType.User,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<MessageTemplateMeta[]>> =>
    axios.get(`${controllerName}`, {
        cancelToken,
        params: { roles, messageTemplateType },
    })

export const getSpecificMessageTemplate = (
    templateId: number,
    issueNumberOrId: number | string = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<MessageTemplate>> =>
    axios.get(`${controllerName}/${templateId}`, {
        cancelToken,
        params: {
            issueNumberOrId: issueNumberOrId ? String(issueNumberOrId) : null,
            id: templateId,
        },
    })

export const sendMessageTemplate = (
    query: IssueMailMessage,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(`${controllerName}/send`, { ...query }, { cancelToken })
