import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
        },
        details: {
            color: theme.palette.grey[600],
            ...theme.typography.caption,
        },
    }),
    { name: 'FieldItem' }
)

export interface FieldItemProps extends HtmlHTMLAttributes<HTMLDivElement> {
    name: string
    caption?: string
}

export const FieldItem = (props: FieldItemProps) => {
    const { className: classNameProp, name, caption, ...other } = props
    const classes = useStyles(props)
    return (
        <div className={clsx(classes.root, classNameProp)} {...other}>
            {name}
            {caption && <span className={classes.details}>{caption}</span>}
        </div>
    )
}

export default FieldItem
