import { EmailOutlined, PhoneOutlined } from '@mui/icons-material'
import { Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useCustomerContactsContext } from 'Admin'
import { AdminCustomer } from 'api'
import { ChipLabel, ListItemWithEditing } from 'components'
import { useMemo } from 'react'
import { CustomerContact } from 'types/issueTypes'
import { dataTestId } from 'utils'

export interface CustomerContactsListItemProps {
    customerContact: CustomerContact
    customer: AdminCustomer
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        chip: {
            marginLeft: theme.spacing(2),
        },
        fullName: {
            fontWeight: theme.typography.fontWeightMedium as number,
            color: theme.palette.common.black,
        },
        details: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'wrap',
        },
        icon: {
            width: 18,
            height: 18,
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            '&:first-child': {
                marginLeft: 0,
            },
        },
    }),
    { name: 'SearchSection' }
)

export const CustomerContactsListItem = (
    props: CustomerContactsListItemProps
) => {
    const classes = useStyles()
    const { openCustomerContactDialog } = useCustomerContactsContext()
    const { customerContact, customer, ...others } = props
    const { firstName, lastName, source, email, phone } = customerContact

    const fullName = `${firstName ? firstName : ''} ${lastName ? lastName : ''}`
    const emailAndPhone = useMemo(
        () => (
            <>
                {email && (
                    <>
                        <EmailOutlined className={classes.icon} />
                        {email}
                    </>
                )}
                {phone && (
                    <>
                        <PhoneOutlined className={classes.icon} />
                        {phone}
                    </>
                )}
            </>
        ),
        [phone, email]
    )

    const onEdit = () => {
        openCustomerContactDialog('edit', {
            customerContact,
            customer,
        })
    }

    return (
        <ListItemWithEditing
            onEdit={customerContact.source === 'CCRP' && onEdit}
            {...others}
        >
            <Typography variant="body1" className={classes.fullName}>
                {fullName}
                <ChipLabel
                    className={classes.chip}
                    label={source}
                    variant="grey"
                />
            </Typography>
            <Typography
                className={classes.details}
                variant="body2"
                {...dataTestId(`CONTACT_EMAIL`)}
            >
                {emailAndPhone}
            </Typography>
        </ListItemWithEditing>
    )
}
