import axios, { AxiosResponse } from 'axios'
import { SurveyTranslationKeys } from 'Survey'
import { CustomerSurveyQuestion, CustomerSurveyStatus, CustomerSurveySubscriptionStatus } from 'types/surveyTypes'

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
})

export const getSurveyTranslations = (
    culture: string
): Promise<AxiosResponse<{ name: SurveyTranslationKeys; value: string }[]>> =>
    httpClient.get('resources/survey', { params: { culture } })

export const getSurveyStatus = (
    surveyKey: string
): Promise<AxiosResponse<CustomerSurveyStatus>> =>
    httpClient.get(`/survey/${surveyKey}`)

export const getSubscriptionStatus = (
    surveyKey: string
): Promise<AxiosResponse<CustomerSurveySubscriptionStatus>> =>
    httpClient.get(`/subscription/${surveyKey}`)

export const sendSurveyStatus = (
    surveyKey: string,
    questions: CustomerSurveyQuestion[]
): Promise<AxiosResponse<unknown>> =>
    httpClient.post(`/survey/${surveyKey}`, { questions })

export const subscriptionSurvey = (
    surveyKey: string
): Promise<AxiosResponse<CustomerSurveySubscriptionStatus>> =>
    httpClient.post(`/subscription/${surveyKey}`)
