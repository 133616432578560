import {
    AutocompleteChangeReason,
    BaseTextFieldProps,
    useTheme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { FilterBase } from '../../types/models'
import TextField from '../Base/TextField'

import { LocalOfferOutlined } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { useIssueTypeResources } from '../../hooks'

export interface IssueTypeSelectProps extends Partial<BaseTextFieldProps> {
    selectedIssueType?: FilterBase<number>
    onIssueTypeChanged: (item: FilterBase<number>) => void
}

export const IssueTypeSelect = (props: IssueTypeSelectProps) => {
    const {
        label = 'Issue type',
        disabled,
        selectedIssueType: selectedBusinessArea,
        onIssueTypeChanged: onBusinessAreaChanged,
        error: errorProps,
        helperText: helperTextProps,
        ...other
    } = props
    const theme = useTheme()
    const issueTypesResources = useIssueTypeResources()
    const handleSelectFilter = (item: FilterBase<number>) =>
        onBusinessAreaChanged(item)
    const getOptionSelected = (
        option: FilterBase<number>,
        value: FilterBase<number>
    ): boolean => {
        return option?.code === value?.code
    }
    const getOptionLabel = (option: FilterBase<number>): string => {
        return option?.name ?? ''
    }

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedBusinessArea ?? null}
            autoSelect={true}
            options={issueTypesResources}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter((f) =>
                          f.name
                              .toUpperCase()
                              .includes(inputValue.toUpperCase())
                      )
            }}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as FilterBase<number>
                switch (reason) {
                    case 'clear':
                        handleSelectFilter(null)
                        break
                    default:
                        handleSelectFilter({ ...selectedValue })
                }
            }}
            renderOption={(props, option) => {
                return <li {...props}>{option?.name}</li>
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={Boolean(errorProps)}
                    label={label}
                    helperText={helperTextProps}
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                        startAdornment: (
                            <LocalOfferOutlined
                                fontSize="small"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default IssueTypeSelect
