import {
    BusinessAreaActivityEnum,
    EmployeeStatusEnum,
    EmployeeTypeEnum,
    ResolvingUnitsManagePageFields,
    RUPersonsAssociationEnum,
} from './types'

export const getInitQueryForAdminResolvingUnits =
    (): ResolvingUnitsManagePageFields => {
        return {
            businessArea: null,
            division: null,
            productGroup: null,
            ruPersonsAssociation: RUPersonsAssociationEnum.All,
            region: null,
            country: null,
            city: null,
            activity: null,
            businessAreaActivity: BusinessAreaActivityEnum.All,
            employee: null,
            employeeType: EmployeeTypeEnum.All,
            employeeStatus: EmployeeStatusEnum.OnlyActiveRUs,
            ruPersonAssigned: false,
            withoutActivePeople: false,
        }
    }
