import { FormControl, InputLabel, List, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Button } from 'components'
import { useMemo, useState } from 'react'
import { ReportSettingsDialog } from 'Reports/components'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    AllInOneReportDataFields,
    AllInOneReportSettings,
} from 'types/reportTypes'
import { FilterSection, ReportItemSettings } from '.'
import { dataTestId, deepCopy } from '../../../utils'
import { FiltersSectionBaseProps } from '../types'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            margin: theme.spacing(3, 0, 2),
        },
    }),
    { name: 'ReportSettingsFilters' }
)

export interface ReportSettingsFiltersProps extends FiltersSectionBaseProps {
    dataField: AllInOneReportDataFields
    settings: AllInOneReportSettings[]
    raportId: string
    onApplySettings: (settings: AllInOneReportSettings) => void
    onSaveSettings: (settings: AllInOneReportSettings) => void
    onDeleteSettings: (settings: AllInOneReportSettings) => void
}

export const ReportSettingsFilters = (props: ReportSettingsFiltersProps) => {
    const {
        settings,
        raportId,
        onDeleteSettings,
        onSaveSettings,
        dataField,
        onApplySettings,

        ...other
    } = props
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const settingsNames = useMemo(
        () => settings?.map((x) => x.name) ?? [],
        [settings]
    )
    const [selectedSettings, setSelectedSettings] =
        useState<AllInOneReportSettings>()
    const handleCloseDialog = () => {
        setSelectedSettings(null)
        setOpen(false)
    }

    return (
        <FilterSection {...other}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Report settings</InputLabel>
                <div className={classes.content}>
                    {0 < settings?.length ? (
                        <List disablePadding>
                            {settings.map((s) => (
                                <ReportItemSettings
                                    key={s.id}
                                    settings={s}
                                    onEdit={(settings) => {
                                        setSelectedSettings(settings)
                                        setOpen(true)
                                    }}
                                    onDelete={() => onDeleteSettings(s)}
                                    onAccept={(s) =>
                                        onApplySettings(deepCopy(s))
                                    }
                                />
                            ))}
                        </List>
                    ) : (
                        <Typography>
                            No report settings to display...
                        </Typography>
                    )}
                </div>
                <div>
                    <Button
                        onClick={() => setOpen(true)}
                        color="secondary"
                        {...dataTestId('ALLINONE_ADD_SETTINGS')}
                    >
                        Add Setting
                    </Button>
                </div>
            </FormControl>

            <ReportSettingsDialog
                raportId={raportId}
                title="Report settins"
                open={open}
                settings={selectedSettings}
                settingsNames={settingsNames}
                onClose={handleCloseDialog}
                onSave={onSaveSettings}
            />
        </FilterSection>
    )
}
