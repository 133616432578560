import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            color: theme.palette.grey[400],
        },
        checked: {
            color: `${theme.palette.secondary.main} !important`,
        },
        disabled: {
            color: `${theme.palette.grey[200]} !important`,
        },
    }),
    { name: 'Checkbox' }
)

export const Checkbox = (props: CheckboxProps) => {
    const classes = useStyles()
    return <MuiCheckbox {...props} classes={classes} />
}

Checkbox.muiName = 'Checkbox'

export default Checkbox
