import { Delete, DeleteForever } from '@mui/icons-material'
import { Tooltip } from '@mui/material'
import { AdminEmployeeChip } from 'Admin'
import { EmployeeChipProps } from 'components'
import { useState } from 'react'
import { dataTestId } from 'utils'

export interface AdminEmployeeChipWithProtectionProps
    extends EmployeeChipProps {
    isLast: boolean
    isLastMessage: string
}

export const AdminEmployeeChipWithProtection = (
    props: AdminEmployeeChipWithProtectionProps
) => {
    const { employee, isLast, isLastMessage, onDelete, disabled, ...other } =
        props
    const [open, setOpen] = useState(false)

    return (
        <AdminEmployeeChip
            disabled={disabled}
            open={open}
            onMouseEnter={() => {
                setOpen(true)
            }}
            onClose={() => {
                setOpen(false)
            }}
            deleteIcon={
                !disabled && isLast ? (
                    <Tooltip
                        title={isLastMessage}
                        onOpen={() => {
                            setOpen(false)
                        }}
                        onClose={() => {
                            setOpen(true)
                        }}
                    >
                        <DeleteForever
                            {...dataTestId('DELETE_PROCESS_OWNER_BUTTON')}
                        />
                    </Tooltip>
                ) : (
                    <Delete {...dataTestId('DELETE_PROCESS_OWNER_BUTTON')} />
                )
            }
            onDelete={(e) => {
                if (!isLast) {
                    onDelete(e)
                }
            }}
            employee={employee}
            key={employee.email}
            {...other}
        />
    )
}
