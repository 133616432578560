import axios, { AxiosResponse, CancelToken } from 'axios'
import { ConfirmHIIssueQuery, DowngradeHIIssueQuery } from '../types/actions'
import { HighImpact } from 'types/issueTypes'

const controllerName = '/issue'

export const confirmHIIssue = (
    query: ConfirmHIIssueQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(
        `${controllerName}/${query.issueId}/high-impact/confirm`,
        query,
        {
            cancelToken,
        }
    )

export const downgradeHIIssue = (
    query: DowngradeHIIssueQuery,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(
        `${controllerName}/${query.issueId}/high-impact/downgrade`,
        query,
        {
            cancelToken,
        }
    )

export const getHighImpactSection = (
    issueId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<HighImpact>> =>
    axios.get(`${controllerName}/${issueId}/high-impact`, { cancelToken })
