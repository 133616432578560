import { StaticSvg, StaticSvgProps } from './StaticSvg'

export interface NoInternetSvgProps extends StaticSvgProps {}

export const NoInternetSvg = (props: NoInternetSvgProps) => {
    const { src, width = 256, height = 256, ...other } = props
    return (
        <StaticSvg
            width={Number(width.valueOf())}
            height={Number(height.valueOf())}
            viewBox={[0, 0, 256, 256].join(' ')}
            {...other}
        >
            <defs>
                <linearGradient id="a" x1="100%" x2="0%" y1="50%" y2="50%">
                    <stop stopColor="#3F3F3F" offset="0" />
                    <stop stopColor="#393939" offset=".0254" />
                    <stop stopColor="#333" offset=".05879" />
                    <stop stopColor="#2E2E2E" offset=".10593" />
                    <stop stopColor="#292929" offset=".17257" />
                    <stop stopColor="#252525" offset=".26447" />
                    <stop stopColor="#232323" offset=".38741" />
                    <stop stopColor="#212121" offset=".54713" />
                    <stop stopColor="#1F1F1F" offset=".74941" />
                    <stop stopColor="#1F1F1F" offset="1" />
                </linearGradient>
                <linearGradient id="b" x1="100%" x2="0%" y1="50%" y2="50%">
                    <stop stopColor="#3F3F3F" offset="0" />
                    <stop stopColor="#3B3B3B" offset=".08203" />
                    <stop stopColor="#373737" offset=".16365" />
                    <stop stopColor="#333" offset=".24815" />
                    <stop stopColor="#2F2F2F" offset=".33882" />
                    <stop stopColor="#2B2B2B" offset=".43896" />
                    <stop stopColor="#272727" offset=".55185" />
                    <stop stopColor="#232323" offset=".6808" />
                    <stop stopColor="#212121" offset=".82908" />
                    <stop stopColor="#1F1F1F" offset="1" />
                </linearGradient>
                <linearGradient id="c" x1="50%" x2="50%" y2="100%">
                    <stop stopColor="#FF000F" offset="0" />
                    <stop stopColor="#F30410" offset=".06063" />
                    <stop stopColor="#E90512" offset=".13023" />
                    <stop stopColor="#E00612" offset=".21037" />
                    <stop stopColor="#D90713" offset=".30261" />
                    <stop stopColor="#D40713" offset=".4085" />
                    <stop stopColor="#D00814" offset=".52963" />
                    <stop stopColor="#CE0814" offset=".66754" />
                    <stop stopColor="#CC0814" offset=".82381" />
                    <stop stopColor="#CC0814" offset="1" />
                </linearGradient>
                <linearGradient id="e" x1="50%" x2="50%" y2="100%">
                    <stop stopColor="#9A5A2A" offset="0" />
                    <stop stopColor="#985929" offset=".16861" />
                    <stop stopColor="#965728" offset=".31114" />
                    <stop stopColor="#925527" offset=".43259" />
                    <stop stopColor="#8E5225" offset=".538" />
                    <stop stopColor="#894F22" offset=".63237" />
                    <stop stopColor="#834B1F" offset=".72074" />
                    <stop stopColor="#7C461C" offset=".80812" />
                    <stop stopColor="#744118" offset=".89953" />
                    <stop stopColor="#6C3B13" offset="1" />
                </linearGradient>
                <linearGradient id="f" x1="50%" x2="50%" y2="100%">
                    <stop stopColor="#EFEFEF" offset="0" />
                    <stop stopColor="#EFEFEF" offset=".22952" />
                    <stop stopColor="#EEE" offset=".41542" />
                    <stop stopColor="#EDEDED" offset=".5637" />
                    <stop stopColor="#EBEBEB" offset=".68038" />
                    <stop stopColor="#E9E9E9" offset=".77147" />
                    <stop stopColor="#E5E5E5" offset=".84296" />
                    <stop stopColor="#E0E0E0" offset=".90088" />
                    <stop stopColor="#D8D8D8" offset=".95122" />
                    <stop stopColor="#CFCFCF" offset="1" />
                </linearGradient>
                <linearGradient id="g" x1="50%" x2="50%" y1="100%">
                    <stop stopColor="#CC0814" offset="0" />
                    <stop stopColor="#CC0814" offset=".17619" />
                    <stop stopColor="#CE0814" offset=".33246" />
                    <stop stopColor="#D00814" offset=".47037" />
                    <stop stopColor="#D40713" offset=".5915" />
                    <stop stopColor="#D90713" offset=".69739" />
                    <stop stopColor="#E00612" offset=".78963" />
                    <stop stopColor="#E90512" offset=".86977" />
                    <stop stopColor="#F30410" offset=".93937" />
                    <stop stopColor="#FF000F" offset="1" />
                </linearGradient>
                <linearGradient id="j" x1="50%" x2="50%" y2="100%">
                    <stop stopColor="#EFEFEF" offset="0" />
                    <stop stopColor="#EEE" offset=".16861" />
                    <stop stopColor="#EDEDED" offset=".31114" />
                    <stop stopColor="#ECECEC" offset=".43259" />
                    <stop stopColor="#EAEAEA" offset=".538" />
                    <stop stopColor="#E7E7E7" offset=".63237" />
                    <stop stopColor="#E3E3E3" offset=".72074" />
                    <stop stopColor="#DEDEDE" offset=".80812" />
                    <stop stopColor="#D7D7D7" offset=".89953" />
                    <stop stopColor="#CFCFCF" offset="1" />
                </linearGradient>
                <linearGradient id="m" x1="50%" x2="50%" y2="100%">
                    <stop stopColor="#9A5A2A" offset="0" />
                    <stop stopColor="#995929" offset=".13108" />
                    <stop stopColor="#955728" offset=".24719" />
                    <stop stopColor="#8F5325" offset=".35259" />
                    <stop stopColor="#894F22" offset=".45158" />
                    <stop stopColor="#814A1F" offset=".54842" />
                    <stop stopColor="#7A441B" offset=".64741" />
                    <stop stopColor="#734017" offset=".75281" />
                    <stop stopColor="#6E3C14" offset=".86892" />
                    <stop stopColor="#6C3B13" offset="1" />
                </linearGradient>
                <linearGradient id="n" x1="50%" x2="50%" y1=".288%" y2="100%">
                    <stop stopColor="#2F2F2F" offset="0" />
                    <stop stopColor="#2D2D2D" offset=".08203" />
                    <stop stopColor="#2B2B2B" offset=".16365" />
                    <stop stopColor="#292929" offset=".24815" />
                    <stop stopColor="#262626" offset=".33882" />
                    <stop stopColor="#242424" offset=".43896" />
                    <stop stopColor="#232323" offset=".55185" />
                    <stop stopColor="#212121" offset=".6808" />
                    <stop stopColor="#202020" offset=".82908" />
                    <stop stopColor="#1F1F1F" offset="1" />
                </linearGradient>
                <path
                    id="d"
                    d="M111.94 64.456c9.559 4.355 22.603 54.796 23.907 59.556l.16.57c1.306 4.548 3.157 8.807.326 11.206-1 .847-5.994 2.86-7.86 1.026-1.867-1.834-.618-5.59 1.108-5.871l.369-.066c4.055-.792-2.857-2.134-3.388-7.307l-.018-.221c-.234-3.552-1.846-7.87-4.836-12.954l-.304-.511-7.4-13.107c-5.485-9.764-10.983-13.302-14.78-17.8-7.616-9.018 3.158-18.877 12.716-14.521z"
                />
                <path
                    id="h"
                    d="M42.278 73.574c-3.233 6.964-5.261 35.803-3.173 38.988 2.09 3.184 21.688 12.652 28.985 15.534 7.296 2.881 19.731 5.113 22.013 5.113 2.281 0 6.277-.595 6.892-.965l.181-.114c.421-.283.363-.439.211-1.394-.17-1.075-2.888-.479-4.995-.54-.92-.027-2.598-1.28-2.29-2.1.31-.821 4.091-.519 4.808-1.058.422-.318.371-1.091-.322-1.535-.482-.308-5.16-.14-7.731-.671l-.123-.033c-1.455-.43-11.241-4.364-14.245-6.864l-2.287-1.49c-8.213-5.378-12.808-8.714-13.782-10.008-1.416-1.88 4.134-23.217 3.282-25.53l-.16-.215c-2.707-3.543-14.33-13.438-17.264-7.118z"
                />
                <path
                    id="l"
                    d="m93.814 50.532-1.044 3.894c-0.927 3.462-2.156 7.193-3.686 11.192-4.43-2.07-7.878-4.354-10.341-6.85-2.464-2.497-3.965-5.547-4.505-9.151a121.5 121.5 0 0 0 2.86-17.76c-3.056-7.281-1.672-11.247 1.645-13.409 7.408-4.827 18.59-4.077 33.544 2.25-2.724 15.964-6.343 25.778-10.857 29.44-1.934 1.568-4.473 1.7-7.616 0.394z"
                />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M255 132.923c6.354 28.141-18.588 64.345-46.226 82.177-27.601 17.782-57.943 17.153-84.791 16.776l-7.46-.12c-23.813-.434-45.003-1.578-65.598-12.026-22.72-11.527-44.642-34.477-49.723-62.41-5.075-27.693 6.4-60.23 25.455-86.484l.58-.794c19.355-26.43 46.397-46.299 71.98-46.04 25.582.26 49.87 20.623 80.791 40.758l3.708 2.396c30.19 19.369 65.146 38.79 71.284 65.767z"
                    fill="#FFF"
                    fillRule="nonzero"
                />
                <g transform="translate(35.205 19.401)">
                    <path
                        d="M106.502 153.59l.013.834.007.886v.942c-.056 12.795-2.179 34.791-8.56 56.333l-1.576-.013-1.258-3.594-.85-3.317c-6.174-24.252-9.538-40.758-10.093-49.516l22.317-2.554z"
                        fill="url(#a)"
                        fillRule="nonzero"
                    />
                    <path
                        d="M85.104 153.964l.008.539.017.55c.354 9.185 4.331 28.36 11.931 57.524-2.792-.023-5.555-.064-8.282-.102l-7.46-.12c-6.757-.124-13.303-.304-19.685-.738l-.31-.977c-3.26-10.35-5.552-19.164-6.874-26.44-1.404-7.731-2.104-15.868-2.098-24.41l32.753-5.826z"
                        fill="url(#b)"
                        fillRule="nonzero"
                    />
                    <path
                        d="M133.637 130.115c1.498.844 2.573 1.313 4.021 2.155l1.76 1.032c26.516 15.633 44.457 30.997 53.772 46.152a124 124 0 01-2.384 2.391c-8.954-15.001-26.972-30.472-54.113-46.345l-.953-.553-1.346-.767-2.351-1.322 1.594-2.743z"
                        fill="url(#c)"
                    />
                    <use fill="#FFF" href="#d" />
                    <use fill="url(#e)" fillOpacity=".9" href="#d" />
                    <path
                        d="M74.077 50.037c9.01 0 24.708 2.467 33.45 6.574 8.741 4.108 16.908 23.16 16.564 23.673-.23.342-5.734 3.875-16.512 10.6-.758 8.76-1.137 15.15-1.137 19.17 0 6.028 2.363 42.398 1.075 43.489-1.289 1.09-7.835 4.83-23.401 8.544-6.692 1.597-17.628 1.597-32.807 0 1.544-22.481 1.847-38.601.91-48.361-1.405-14.64-9.826-46.7-3.78-54.964 4.032-5.51 16.628-8.725 25.638-8.725z"
                        fill="url(#f)"
                    />
                    <path
                        d="M107.809 123.703c.182.086.329.218.433.39.105.174.165.347.19.833l.013.238 2.18-.114a.546.546 0 01.058 1.09l-2.181.115.171 3.271 2.181-.114a.548.548 0 01.575.516.548.548 0 01-.518.574l-2.18.115.012.237c.025.486-.016.665-.102.848a.992.992 0 01-.39.434c-.154.093-.308.15-.682.18l-4.989.263a5.54 5.54 0 01-.172.007l-.135.01a4.369 4.369 0 01-4.095-2.328h-.049c-44.688.279-73.883 15.262-87.878 44.91l-1.544 3.465a2639.074 2639.074 0 00-6.04 13.707A100.333 100.333 0 010 190.36l2.779-6.396c2.077-4.75 3.991-9.065 4.073-9.244 14.384-31.467 44.802-47.364 90.952-47.728l.052-.003a4.37 4.37 0 013.96-3.118l5.145-.27c.486-.025.665.016.848.102z"
                        fill="url(#g)"
                    />
                    <use fill="#FFF" href="#h" />
                    <use fill="url(#e)" fillOpacity=".9" href="#h" />
                    <path
                        d="M59.702 55.143l.391.186c4.2 2.07 9.01 6.92 4.87 21.18l-.407 1.383c-1.407 4.865-2.851 11.06-3.552 14.16l-.435 1.952s-1.64 1.823-8.644 1.316c-8.122-.59-15.127-4.448-15.127-4.448l.378-2.175c1.118-6.21 4.579-23.869 8.517-28.43 4.802-5.563 9.683-7.106 14.009-5.124z"
                        fill="url(#j)"
                    />
                    <path
                        d="M120.552 128.246c.702-2.621 2.689-4.367 4.436-3.898.101.027.199.06.294.1l-.025-.005c1.75.518 3.737 1.441 5.96 2.768 1.467.882 1.652 2.654 1.266 4.186-.386 1.533-1.132 3.213-2.933 3.043l-6.318-.533a2.257 2.257 0 01-.788-.066c-1.747-.469-2.594-2.973-1.892-5.595z"
                        fill="url(#g)"
                    />
                    <use fill="#FFF" href="#l" />
                    <use fill="url(#m)" fillOpacity=".9" href="#l" />
                    <path
                        d="M83.432 43.396c2.231 2.805 5.521 5.112 9.87 6.92l.526.215c-.005.004-.01.003-.014 0l-1.09 4.063c-4.728-2.626-7.825-6.358-9.292-11.198z"
                        fill="#6C3B13"
                    />
                    <path
                        d="M84.895 22.555c-.788-1.161-2.526-2.255-4.142-1.11-2.51 1.778-4.124 9.718-.657 10.93l.184.057c.967.26 1.114 3.79-3.764 4.773 0-1.77-1.282-6.033-1.882-10.628-.449-3.432.038-7.292.726-10.629-4.67-3.337-1.294-10.778 2.27-9.823 1.343-7.291 13.68-7.936 16.977-2.663 5.914-4.197 16.456 1.585 15.884 6.447 8.874-2.092 9.737 10.291 1.227 12.998l-.367.111c-3.48 1.007-8.4 1.033-18.144-1.578l-.326-.08c-1.407-.311-2.741-.14-3.483 1.54-.798 1.81.332 3.68-2.108 3.677-2.44-.003-1.236-1.585-2.395-4.022z"
                        fill="url(#n)"
                    />
                </g>
            </g>
        </StaticSvg>
    )
}

export default NoInternetSvg
