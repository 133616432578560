import axios, { AxiosResponse } from 'axios'
import { replace } from 'connected-react-router'
import { call, cancelled, put, select, takeEvery } from 'redux-saga/effects'
import {
    saveAsDraftDone,
    ISSUE_SAVE_AS_CALL,
    setIssueActionFail,
    prepareDraft,
    setLightMode,
    loadIssue,
} from '..'
import { saveAsDraft } from 'api'
import { ISSUE_VIEW } from 'Issue/routes'
import { Issue } from 'types/issueTypes'
import { urlBuilder } from 'utils'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { IssueStoreState } from '../initState'

export function* saveAsDraftAction() {
    const cancelSource = axios.CancelToken.source()
    yield put(prepareDraft())
    try {
        const issue: IssueStoreState = yield select((s: RootState) => s.issue)
        const { data } = (yield call(
            saveAsDraft,
            issue.captureSection,
            cancelSource.token
        )) as AxiosResponse<Issue>
        yield put(saveAsDraftDone(data))
        yield put(loadIssue(data.issueId))
        yield put(
            replace(
                urlBuilder(ISSUE_VIEW, {
                    id: data.issueId,
                })
            )
        )

        yield put(setLightMode(issue.lightMode))
        yield put(showSnackbar('Issue was saved as draft', false))
    } catch (error) {
        yield put(showSnackbar("Issue wasn't saved as draft!", true))
        yield put(setIssueActionFail(ISSUE_SAVE_AS_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveAsDraftSaga() {
    yield takeEvery(ISSUE_SAVE_AS_CALL, saveAsDraftAction)
}
