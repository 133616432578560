import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { DASHBOARD_PAGE } from 'consts/selectorIds'
import { customize } from 'utils'
import { ValueViewNext } from '.'

const useStyles = makeStyles(
    () => ({
        root: {
            display: 'block',
            flex: '0 0 100%',
        },
        content: {
            display: 'flex',
            justifyContent: 'start',
            flexWrap: 'wrap',
        },
    }),
    { name: 'FilterGroup' }
)

export interface FilterGroupProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    title: string
    filters?: Array<any>
}

export const FilterGroup: React.FC<FilterGroupProps> = (props) => {
    const { title, className, children, ...other } = props
    const classes = useStyles(props)
    return (
        <section
            className={clsx(className, classes.root)}
            {...customize(DASHBOARD_PAGE.FILTERS.GROUP, title)}
            {...other}
        >
            <ValueViewNext label={title} fullWidth>
                <section className={classes.content}>{children}</section>
            </ValueViewNext>
        </section>
    )
}

export default FilterGroup
