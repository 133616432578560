import { AnyAction } from 'redux'
import { call, cancelled, put, takeEvery } from 'redux-saga/effects'
import { DASHBOARD_DOWNLOAD_ANALYZER } from '..'
import { showSnackbar } from '../../app'
import axios, { AxiosResponse } from 'axios'
import { downloadReportAnalyzer } from 'api'
import FileSaver from 'file-saver'

function* downloadAnalyzer(action: AnyAction) {
    const isUnitInternal: boolean = action?.payload ?? false
    const cancelSource = axios.CancelToken.source()
    try {
        const { data } = (yield call(
            downloadReportAnalyzer,
            isUnitInternal,
            cancelSource.token
        )) as AxiosResponse<any>
        FileSaver.saveAs(
            data,
            isUnitInternal
                ? 'CCRP Unit Internal Analyzer.xlsx'
                : 'CCRP Analyzer.xlsx'
        )
    } catch (error) {
        yield put(showSnackbar(`Download analyzer file failed`, true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* downloadAnalyzerSaga() {
    yield takeEvery([DASHBOARD_DOWNLOAD_ANALYZER], downloadAnalyzer)
}
