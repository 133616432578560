import MuiButton, { ButtonProps } from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const buttonHeight = 40

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            fontFamily: theme.typography.button.fontFamily,
            height: buttonHeight,
            borderRadius: buttonHeight / 2,
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
            fontSize: '0.875rem',
            boxShadow: theme.shadows[0],
            fontWeight: 500,
            '&:hover': {
                backgroundColor: theme.palette.common.white,
                boxShadow: theme.shadows[2],
            },
        },
        contained: {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
                boxShadow: theme.shadows[2],
            },
        },
        rootDisabled: {
            color: `${theme.palette.text.disabled} !important`,
            backgroundColor: 'rgba(0,0,0, .0) !important',
        },
        containedDisabled: {
            color: `${theme.palette.text.disabled} !important`,
            backgroundColor: `${theme.palette.grey[100]} !important`,
        },
    }),
    { name: 'RoundedButton' }
)

export const RoundedButton = (props: ButtonProps) => {
    const classes = useStyles()
    const { disabled, variant } = props
    const { rootDisabled, containedDisabled, ...otherClasses } = classes
    const newProps = { ...props }
    newProps.classes = otherClasses
    if (disabled) {
        switch (variant) {
            case 'contained':
                newProps.classes.disabled = containedDisabled
                break
            default:
                newProps.classes.disabled = rootDisabled
                break
        }
    }
    return <MuiButton {...newProps} />
}

RoundedButton.muiName = 'RoundedButton'

export default RoundedButton
