const getFeatureToggle = (featureToggleName: string): boolean =>
    Boolean(Number(process.env[featureToggleName]))

export const FAKE_AZURE_AD = getFeatureToggle('REACT_APP_USE_MOCK_MSAL')
export const TOKEN_GENERATOR = getFeatureToggle('REACT_APP_TOKEN_GENERATOR')
export const EMPLOYEE_ROLES_ALL_IN_ONE = getFeatureToggle(
    'REACT_APP_EMPLOYEE_ROLES_ALL_IN_ONE'
)
export const NEW_DASHBOARD = getFeatureToggle('REACT_APP_NEW_DASHBOARD')
export const LEFT_MENU_DISPLAY = getFeatureToggle('REACT_APP_LEFT_MENU_DISPLAY')
export const ADMIN_PANEL_NEW_CONCEPT = getFeatureToggle(
    'REACT_APP_ADMIN_PANEL_NEW_CONCEPT'
)
