import { LocalOffer, Subject } from '@mui/icons-material'
import AssignmentLate from '@mui/icons-material/AssignmentLate'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { dataTestId } from 'utils'
import {
    Button,
    DataArea,
    NavButton,
    ResolutionUnitValue,
    ReturnToResolutionOwnerDialog,
    ValueView,
} from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { useIssueTypeName } from '../../hooks'
import { reopenIssue } from '../../store/myActions/index'
import { ReturnToIssueOwnerQuery } from '../../types/actions'
import { MyAction } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import { useTilesStyles } from './tilesStyles'

export interface EffectivnessCheckProps {
    actionIssue: MyAction
}

export const EffectivnessCheck = (props: EffectivnessCheckProps) => {
    const { actionIssue, ...other } = props
    const classes = useTilesStyles(props)
    const { action, resolvingUnit } = actionIssue
    const [returnDialogOpen, setReturnDialogOpen] = useState(false)
    const dispatch = useDispatch()
    const handleReturnToRO = (formData: ReturnToIssueOwnerQuery) => {
        setReturnDialogOpen(false)
        dispatch(reopenIssue(formData))
    }
    const issueTypeName = useIssueTypeName(actionIssue.issueType)
    let actions = (
        <>
            <Button
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.REOPEN_ISSUE}
                onClick={() => {
                    setReturnDialogOpen(true)
                }}
                eventName="Reopen issue on effectivness tile"
            >
                Reopen issue
            </Button>
            <NavButton
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CHECK_EFFECTIVENESS}
                color="secondary"
                linkProps={{ to: `/issue/${actionIssue.issueId}` }}
                eventName="Check effectiveness on tile"
            >
                Check effectiveness
            </NavButton>
        </>
    )
    return (
        <>
            <ExpandTile
                issueNumber={actionIssue.issueNumber}
                subject={actionIssue.subject}
                issueStatusId={actionIssue.issueStatusId}
                isOverdue={action.isActionOverdue}
                isHighImpact={actionIssue.isHighImpact}
                showActionStatus={true}
                actionCode={action.actionCode}
                {...other}
                issueId={actionIssue.issueId}
                collapsedSize="110px"
                actions={actions}
            >
                <ResolutionUnitValue
                    resolvingUnit={resolvingUnit}
                    {...dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT')}
                />
                <DataArea>
                    {actionIssue.pgCategoryName && (
                        <ValueView
                            className={classes.cell}
                            icon={<LocalOffer className={classes.labelIcon} />}
                            singleLine={false}
                            label="RCA category"
                            value={actionIssue.pgCategoryName}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                                .RCA_CATEGORY}
                        />
                    )}
                    <ValueView
                        className={classes.cell}
                        icon={<LocalOffer className={classes.labelIcon} />}
                        label="Issue type"
                        value={issueTypeName}
                        singleLine={true}
                    />
                    {actionIssue.whatWasTheRootCause && (
                        <ValueView
                            className={classes.singleRow}
                            icon={
                                <AssignmentLate className={classes.labelIcon} />
                            }
                            label="Root cause of the issue"
                            singleLine={false}
                            value={actionIssue.whatWasTheRootCause}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.ROOT_CAUSE}
                        />
                    )}
                    {actionIssue.resolutionDescription && (
                        <ValueView
                            className={classes.singleRow}
                            icon={<Subject className={classes.labelIcon} />}
                            singleLine={false}
                            label="Resolution description"
                            value={actionIssue.resolutionDescription}
                            {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS
                                .RESOLUTION_DESCRIPTION}
                        />
                    )}
                </DataArea>
            </ExpandTile>
            <ReturnToResolutionOwnerDialog
                open={returnDialogOpen}
                message={`You are about to reopen issue ${actionIssue.issueNumber}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                minHeight={220}
                rowVersion={actionIssue.rowVersion}
                number={actionIssue.issueNumber}
                issueId={actionIssue.issueId}
                onReturnToRO={(formData) => handleReturnToRO(formData)}
                onClose={() => setReturnDialogOpen(false)}
            />
        </>
    )
}

export default EffectivnessCheck
