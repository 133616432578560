import { useEffect, useState } from 'react'

export const useBrowserTabVisibility = (
    handler: (visible: boolean) => void = null
): boolean => {
    const [visibility, setVisibility] = useState(true)
    function handleVisibilityChange() {
        const isVisible = document.visibilityState === 'visible'
        setVisibility(isVisible)
        handler && handler(isVisible)
    }
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange)
        return () =>
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            )
    }, [])
    return visibility
}
