import { FormControlLabel } from '@mui/material'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { IssueStoreState, setLightMode } from 'store/issue'
import { RootState } from 'store/types'
import { dataTestId } from 'utils'
import Switch from '../../components/Base/Switch'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'

import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'inline-flex',
        },
        flexGrow: {
            flexGrow: 1,
        },
    }),
    { name: 'LightModeSwitch' }
)

const LightModeSwitch = (props: any) => {
    const { className, ...other } = props
    const classes = useStyles()
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const dispatch = useDispatch()
    return (
        <>
            <div className={clsx(classes.root, className)} {...other}>
                <FormControlLabel
                    {...dataTestId('SWITCH_BUTTON_TEXT')}
                    control={
                        <>
                            <Switch
                                checked={Boolean(issue?.lightMode)}
                                onChange={(e) => {
                                    e.stopPropagation()
                                    dispatch(
                                        setLightMode(!Boolean(issue?.lightMode))
                                    )
                                }}
                                {...ISSUE_VIEW_PAGE.ISSUE_CAPTURE
                                    .LIGHT_MODE_SWITCH}
                            />
                        </>
                    }
                    labelPlacement="start"
                    label={
                        Boolean(issue?.lightMode)
                            ? 'Only mandatory fields'
                            : 'All fields'
                    }
                />
                <span className={classes.flexGrow} />
            </div>
        </>
    )
}

export default LightModeSwitch
