import { memo, HtmlHTMLAttributes } from 'react'
import { FollowUpByMethod } from 'types/enums'
import ChipLabel from './ChipLabel'

export interface FollowUpMethodChipProps
    extends HtmlHTMLAttributes<HTMLSpanElement> {
    followUpByMethod: FollowUpByMethod
}

export const FollowUpMethodChip = memo(
    (props: FollowUpMethodChipProps) => {
        const { followUpByMethod, ...other } = props
        let labels = {
            [FollowUpByMethod.Chat]: 'Chat/Messaging',
            [FollowUpByMethod.Email]: 'Email',
            [FollowUpByMethod.Phone]: 'Phone',
            [FollowUpByMethod.Meeting]: 'Meeting',
            [FollowUpByMethod.FollowUpNotPossible]: 'Follow-up not possible',
        }
        const label =
            labels[followUpByMethod] ??
            labels[FollowUpByMethod.FollowUpNotPossible]
        return <ChipLabel label={label} variant="green" {...other} />
    },
    (prev, next) => prev.followUpByMethod === next.followUpByMethod
)

export default FollowUpMethodChip
