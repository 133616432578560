import { MyAction, MyAction2 } from 'types/myDashboard'

export interface MyActionsStoreState {
    loading: boolean
    loadingDesc?: any
    updating: boolean
    error?: any
    items: Array<MyAction | MyAction2>
    exportingActions: boolean
}

export const initState: MyActionsStoreState = {
    loading: true,
    loadingDesc: 'Loading your issues…',
    updating: false,
    error: null,
    items: [],
    exportingActions: false,
}
