import BusinessIcon from '@mui/icons-material/Business'
import PersonRoundedIcon from '@mui/icons-material/PersonRounded'
import { getAccessMatrixForActionPlan } from 'api'
import { CardViewResults } from 'components'
import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { showSnackbar } from 'store/app'
import { DependedFields, IssueStoreState, loadIssue } from 'store/issue'
import { RootState } from 'store/types'
import {
    ActionItem,
    ActionItemAccessMatrix,
    ActionItemFields,
    ActionItemStatus,
} from 'types/actionPlanTypes'
import { IssueRoles } from 'types/enums'
import { AccessMatrixSetting } from 'types/matrixTypes'
import { UserProfile } from 'types/profile'
import { getResolutionUnitName } from 'utils'
import { getInitQueryForItemContainer } from '../ActionPlan/ActionPlanItem/utils'
import { ActionPlanContext } from '../ActionPlan/context/ActionPlanContext'

export const useMergeErrors = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingDescription, setLoadingDescription] = useState<string>('')

    const { loading, loadingDesc } = useContext(ActionPlanContext)
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const { loadingActionPlan } = issue
    const issueLoading = issue.loading
    const issueLoadingDesc = issue.loadingDesc

    useEffect(() => {
        setIsLoading(loading || issueLoading || loadingActionPlan)
        setLoadingDescription(
            loadingDesc || issueLoadingDesc || 'Loading action plan...'
        )
    }, [
        loading,
        issueLoading,
        loadingActionPlan,
        loadingDesc,
        issueLoadingDesc,
    ])

    return { isLoading, loadingDescription }
}

export const useResolvingUnitAndResolutionOwner = (
    item: ActionItem,
    full?: boolean
) => {
    const results = useMemo<CardViewResults>(() => {
        const { resolvingUnit, resolutionOwner } = item
        return [
            {
                label: 'Resolving unit',
                renderIcon: (props) => <BusinessIcon {...props} />,
                text: `${getResolutionUnitName(resolvingUnit)}`,
                fullWidth: full,
            },
            {
                label: 'Resolution owner',
                renderIcon: (props) => <PersonRoundedIcon {...props} />,
                text: resolutionOwner?.fullName,
                fullWidth: full,
                tooltip: resolutionOwner?.email,
            },
        ]
    }, [item])

    return results
}

export const useMergeAccessMatrix = (accessMatrixes: ActionItemAccessMatrix) =>
    useMemo(() => {
        if (!accessMatrixes && accessMatrixes.length === 0) {
            return { currentActions: [], dependedFields: {} }
        }
        const roles = accessMatrixes.map((x) => x.roleId)
        const currentMatrixes = accessMatrixes.filter((x) =>
            roles?.some((c) => c === x.roleId)
        )
        const currentActions = new Set<string>()
        let dependedFields: DependedFields = {}
        currentMatrixes.forEach((m) => {
            Object.getOwnPropertyNames(m.matrix).forEach((a) =>
                currentActions.add(a)
            )
            for (const key in m.matrix) {
                if (m.matrix.hasOwnProperty(key)) {
                    const item = m.matrix[key]
                    item.forEach((i: AccessMatrixSetting) => {
                        let currentField = dependedFields[
                            i.key
                        ] as AccessMatrixSetting
                        if (currentField) {
                            if (
                                !currentField.dependentOn?.length &&
                                i.dependentOn?.length
                            ) {
                                currentField.dependentOn = i.dependentOn
                            }
                            if (i.isRequired) {
                                currentField.isRequired = true
                            }
                            if (!i.isDisabled) {
                                currentField.isDisabled = false
                            }
                        } else {
                            dependedFields[i.key] = {
                                ...i,
                                dependentOn: i?.dependentOn
                                    ? [...i.dependentOn]
                                    : null,
                            }
                        }
                    })
                }
            }
        })
        const enabledFields = []
        const requiredFields = []
        Object.keys(dependedFields).forEach((k) => {
            const field = dependedFields[k]
            const key = k.replace('.', '')
            if (!field.isDisabled) enabledFields.push(ActionItemFields[key])
            if (field.isRequired) requiredFields.push(ActionItemFields[key])
        })
        return { enabledFields, requiredFields, actions: [...currentActions] }
    }, [accessMatrixes])

export interface ActionPlanItemErrors {
    actionName: string
    actionDescription: string
    activitiesDescription: string
    actionCreator: string
    actionOwner: string
    actionDeadline: string
    actionType: string
    descriptionAttachments: string
    activitiesAttachments: string
    interestedParties: string
}

const getInitActionItemErrors = () => ({
    actionName: null,
    actionDescription: null,
    activitiesDescription: null,
    actionCreator: null,
    actionOwner: null,
    actionDeadline: null,
    actionType: null,
    descriptionAttachments: null,
    activitiesAttachments: null,
    interestedParties: null,
})

export const useValidationErrors = () => {
    const dispatch = useDispatch()
    const [errors, setErrors] = useState<ActionPlanItemErrors>(
        getInitActionItemErrors()
    )
    const { error, setError } = useContext(ActionPlanContext)

    const removeError = useCallback((key: keyof ActionPlanItemErrors) => {
        setErrors((prev) => ({
            ...prev,
            [key]: null,
        }))
    }, [])

    useEffect(() => {
        if (error) {
            if (error?.errors) {
                const newErrors: ActionPlanItemErrors =
                    getInitActionItemErrors()

                error.errors.forEach((item) => {
                    const newField = item.field.split('')
                    newField[0] = newField[0].toLowerCase()
                    // for invalid field key from backend (split)
                    const key = newField.join('').split('.')[0]
                    if (newErrors[key]) {
                        newErrors[key] = `${newErrors[key]}, ${item.message}`
                    } else {
                        newErrors[key] = item.message
                    }
                })

                setErrors(newErrors)
                setError(null)
            } else if (error?.error) {
                dispatch(showSnackbar(error.error, true))
            } else {
                if (
                    Number.isInteger(error?.status) &&
                    (error.status === 500 || error.status === 0)
                ) {
                } else {
                    dispatch(showSnackbar('Error', true))
                    setError(null)
                }
            }
        }
    }, [error])

    return { errors, removeError }
}

export const useActionItemForNew = (
    issueIdOrNumber: number | string,
    catchErrorsAndLoading: (cb: () => void) => void
) => {
    const dispatch = useDispatch()
    const didMountRef = useRef(false)
    const [actionItem, setActionItem] = useState<ActionItem>(null)
    const [matrix, setMatrix] = useState<ActionItemAccessMatrix>([])

    const roles = useSelector<RootState, IssueRoles[]>(
        (state) => state.issue?.context?.myRoles ?? []
    )

    const { actionPlanId: actionPlanIdParam } = useParams<{
        actionPlanId: string
    }>()

    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )

    const loading = useSelector<RootState, boolean>(
        (state) => state.issue.loading
    )

    const profile = useSelector<RootState, UserProfile>(
        (state) => state.dashboard.profile
    )

    const isNewItem = actionPlanIdParam && actionPlanIdParam === 'new'

    useEffect(() => {
        if (isNewItem && !issue.captureSection?.resolvingUnit) {
            dispatch(loadIssue(issueIdOrNumber))
        }
    }, [])

    useEffect(() => {
        if (isNewItem) {
            catchErrorsAndLoading(async () => {
                const res = await getAccessMatrixForActionPlan(
                    [...roles, IssueRoles.Action_Creator],
                    ActionItemStatus.New
                )
                setMatrix(res.data)
            })
        }
    }, [issue?.context?.myRoles])

    useEffect(() => {
        if (didMountRef.current) {
            if (!loading) {
                setActionItem({
                    ...getInitQueryForItemContainer(),
                    actionCreator: {
                        email: profile.email,
                        geid: profile.geid,
                    },
                    resolutionOwner:
                        issue.assignSection?.primaryResolutionOwner,
                    resolvingUnit: issue.captureSection?.resolvingUnit,
                    accessMatrix: [...matrix],
                    actionStatus: 0,
                    issueId: issue.issueId,
                    issueNumber: issue.issueNumber,
                    rowVersion: issue.rowVersion,
                })
            }
        } else didMountRef.current = true
    }, [issue, matrix, loading])

    return actionItem
}
