export const ISSUE_ACTION_FAIL = 'ISSUE_ACTION_FAIL'
export const ISSUE_ACTION_DONE = 'ISSUE_ACTION_DONE'
export const ISSUE_CANCEL_CALL = 'ISSUE_CANCEL_REQUESTS'
export const ISSUE_DELETE_CONTEXT = 'ISSUE_DELETE_CONTEXT'
export const ISSUE_CLEAR_LINKED_ISSUE = 'ISSUE_CLEAR_LINKED_ISSUE'
export const ISSUE_CLEAR_ERROR = 'ISSUE_CLEAR_ERROR'
export const ISSUE_LOAD_MATRIX_CALL = 'ISSUE_LOAD_MATRIX_CALL'
export const ISSUE_LOAD_MATRIX_DONE = 'ISSUE_LOAD_MATRIX_DONE'
export const ISSUE_SET_MATRIX = 'ISSUE_SET_MATRIX'
export const ISSUE_SELECT_ROLES = 'ISSUE_SELECT_ROLES'
export const ISSUE_LOAD_CALL = 'ISSUE_LOAD_CALL'
export const ISSUE_LOAD_DONE = 'ISSUE_LOAD_DONE'
export const ISSUE_REMOVE = 'ISSUE_REMOVE'
export const ISSUE_ATTACHMENTS_CALL = 'ISSUE_ATTACHMENTS_CALL'
export const ISSUE_ATTACHMENTS_DONE = 'ISSUE_ATTACHMENTS_DONE'
export const ISSUE_EXTEND_TARGET_RESOLUTION_DATE_CALL =
    'ISSUE_EXTEND_TARGET_RESOLUTION_DATE_CALL'
export const ISSUE_EXTEND_TARGET_RESOLUTION_DATE_DONE =
    'ISSUE_EXTEND_TARGET_RESOLUTION_DATE_DONE'
export const ISSUE_RETURN_TO_OWNER_CALL = 'ISSUE_RETURN_TO_OWNER_CALL'
export const ISSUE_RETURN_TO_OWNER_DONE = 'ISSUE_RETURN_TO_OWNER_DONE'
export const ISSUE_ASSIGN_CALL = 'ISSUE_ASSIGN_CALL'
export const ISSUE_ASSIGN_DONE = 'ISSUE_ASSIGN_DONE'
export const ISSUE_SAVE_ASSIGN_CALL = 'ISSUE_SAVE_ASSIGN_CALL'
export const ISSUE_SAVE_ASSIGN_DONE = 'ISSUE_SAVE_ASSIGN_DONE'
export const ISSUE_SAVE_CLOSING_CALL = 'ISSUE_SAVE_CLOSING_CALL'
export const ISSUE_SAVE_CLOSING_DONE = 'ISSUE_SAVE_CLOSING_DONE'
export const ISSUE_SAVE_RESOLUTION_CALL = 'ISSUE_SAVE_RESOLUTION_CALL'
export const ISSUE_SAVE_RESOLUTION_DONE = 'ISSUE_SAVE_RESOLUTION_DONE'
export const ISSUE_RESOLVE_CALL = 'ISSUE_RESOLVE_CALL'
export const ISSUE_RESOLVE_DONE = 'ISSUE_RESOLVE_DONE'
export const ISSUE_SAVE_EFFECTIVENESS_CALL = 'ISSUE_SAVE_EFFECTIVENESS_CALL'
export const ISSUE_SAVE_EFFECTIVENESS_DONE = 'ISSUE_SAVE_EFFECTIVENESS_DONE'
export const ISSUE_REOPEN_CALL = 'ISSUE_REOPEN_CALL'
export const ISSUE_REOPEN_DONE = 'ISSUE_REOPEN_DONE'
export const ISSUE_CONFRIM_EFFECTIVENESS_CALL =
    'ISSUE_CONFRIM_EFFECTIVENESS_CALL'
export const ISSUE_CONFRIM_EFFECTIVENESS_DONE =
    'ISSUE_CONFRIM_EFFECTIVENESS_DONE'
export const ISSUE_EFFECTIVENESS_UPDATE = 'ISSUE_EFFECTIVENESS_UPDATE'
export const ISSUE_COMPLETE_CALL = 'ISSUE_COMPLETE_CALL'
export const ISSUE_COMPLETE_DONE = 'ISSUE_COMPLETE_DONE'
export const ISSUE_REJECT_RESOLUTION_TO_RO_CALL =
    'ISSUE_REJECT_RESOLUTION_TO_RO_CALL'
export const ISSUE_REJECT_RESOLUTION_TO_RO_DONE =
    'ISSUE_REJECT_RESOLUTION_TO_RO_DONE'
export const ISSUE_NEW_INIT = 'ISSUE_NEW_INIT'
export const ISSUE_UPDATE_HIGH_IMPACT = 'ISSUE_UPDATE_HIGH_IMPACT'
export const ISSUE_UPDATE_ISSUE_DESCRIPTION = 'ISSUE_UPDATE_ISSUE_DESCRIPTION'
export const ISSUE_CAPTURE_UPDATE = 'ISSUE_CAPTURE_UPDATE'
export const ISSUE_ADDITIONAL_INFORMATION_UPDATE =
    'ISSUE_ADDITIONAL_INFORMATION_UPDATE'
export const ISSUE_WARRANTY_UPDATE = 'ISSUE_WARRANTY_UPDATE'
export const ISSUE_SELECT_ABB_PRODUCT = 'ISSUE_SELECT_ABB_PRODUCT'
export const ISSUE_SELECT_PRODUCT_LINE_LOCATION =
    'ISSUE_SELECT_PRODUCT_LINE_LOCATION'
export const ISSUE_SELECT_FAILURE_MODE = 'ISSUE_SELECT_FAILURE_MODE'
export const ISSUE_SELECT_RESOLVING_UNIT = 'ISSUE_SELECT_RESOLVING_UNIT'
export const ISSUE_SELECT_CUSTOMER = 'ISSUE_SELECT_CUSTOMER'
export const ISSUE_SELECT_CUSTOMER_CONTACT = 'ISSUE_SELECT_CUSTOMER_CONTACT'
export const ISSUE_SELECT_SUPPLIER = 'ISSUE_SELECT_SUPPLIER'
export const ISSUE_SELECT_PG_CATEGORY = 'ISSUE_SELECT_PG_CATEGORY'
export const ISSUE_DELETE_SUPPLIER = 'DELETE_SUPPLIER'
export const ISSUE_SAVE_CALL = 'ISSUE_SAVE_REQUEST'
export const ISSUE_SUBMIT_PREPARE = 'ISSUE_SUBMIT_PREPARE'
export const ISSUE_SAVE_DONE = 'ISSUE_SAVE_DONE'
export const ISSUE_SAVE_AS_CALL = 'ISSUE_SAVE_AS_CALL'
export const ISSUE_SAVE_AS_DONE = 'ISSUE_SAVE_AS_DONE'
export const ISSUE_SUBMIT_CALL = 'ISSUE_SUBMIT_REQUEST'
export const ISSUE_SUBMIT_DONE = 'ISSUE_SUBMIT_DONE'
export const ISSUE_DELETE_CALL = 'ISSUE_DELETE_REQUEST'
export const ISSUE_DELETE_DONE = 'ISSUE_DELETE_DONE'
export const ISSUE_UPDATE_ASSIGN = 'ISSUE_UPDATE_ASSIGN'
export const ISSUE_UPDATE_QUICK_FIX = 'ISSUE_UPDATE_QUICK_FIX'
export const ISSUE_LIGHT_MODE = 'ISSUE_LIGHT_MODE'
export const ISSUE_ADD_ATTACHMENT = 'ISSUE_ADD_ATTACHMENT'
export const ISSUE_NEW_ADD_ATTACHMENTS = 'ISSUE_NEW_ADD_ATTACHMENTS'
export const NEW_ISSUE_ADD_ATTACHMENTS = 'NEW_ISSUE_ADD_ATTACHMENTS'
export const ISSUE_DELETE_ATTACHMENT = 'ISSUE_DELETE_ATTACHMENT'
export const ISSUE_REFRESH_ATTACHMENTS = 'ISSUE_REFRESH_ATTACHMENTS'
export const ISSUE_EXPAND_SECTION = 'ISSUE_EXPAND_SECTION'
export const ISSUE_EXPAND_INIT = 'ISSUE_EXPAND_INIT'
export const ISSUE_UPDATE_RESOLUTION = 'ISSUE_UPDATE_RESOLUTION'
export const ISSUE_UPDATE_CLOSING_SECTION = 'ISSUE_UPDATE_CLOSING_SECTION'
export const ISSUE_UPDATE_CLOSED_EXCECUTION = 'ISSUE_UPDATE_CLOSED_EXCECUTION'
export const ISSUE_GENERATE_REPORT_CALL = 'ISSUE_GENERATE_REPORT_REQUEST'
export const ISSUE_GENERATE_REPORT_DONE = 'ISSUE_GENERATE_REPORT_DONE'
export const ISSUE_VERIFY_RESOLUTION_CALL = 'ISSUE_VERIFY_RESOLUTION_CALL'
export const ISSUE_VERIFY_RESOLUTION_DONE = 'ISSUE_VERIFY_RESOLUTION_DONE'
export const ISSUE_VERIFY_CONTAINMENT_CALL = 'ISSUE_VERIFY_CONTAINMENT_CALL'
export const ISSUE_VERIFY_CONTAINMENT_DONE = 'ISSUE_VERIFY_CONTAINMENT_DONE'
export const ISSUE_UPDATE_ROW_VERSION = 'ISSUE_UPDATE_ROW_VERSION'
export const ISSUE_REJECT_RESOLUTION_CALL = 'ISSUE_REJECT_RESOLUTION_CALL'
export const ISSUE_REJECT_RESOLUTION_DONE = 'ISSUE_REJECT_RESOLUTION_DONE'
export const ISSUE_REMOVE_RCA_CALL = 'ISSUE_REMOVE_RCA_CALL'
export const ISSUE_REMOVE_RCA_DONE = 'ISSUE_REMOVE_RCA_DONE'
export const ISSUE_SET_WARRNINGS = 'ISSUE_SET_WARRNINGS'
export const ISSUE_REMOVE_CONTEXT_ACTION = 'ISSUE_REMOVE_CONTEXT_ACTION'
export const ISSUE_GET_ISSUE_OWNER_CALL = 'ISSUE_GET_ISSUE_OWNER_CALL'
export const ISSUE_GET_ISSUE_OWNER_DONE = 'ISSUE_GET_ISSUE_OWNER_DONE'
export const ISSUE_LOAD_CONTEXT_CALL = 'ISSUE_LOAD_CONTEXT_CALL'
export const ISSUE_LOAD_CONTEXT_DONE = 'ISSUE_LOAD_CONTEXT_DONE'
export const ISSSUE_SET_LOADING_STATE = 'ISSSUE_SET_LOADING_STATE'
export const ISSUE_REVERT_TO_NOT_ASSIGNED_CALL =
    'ISSUE_REVERT_TO_NOT_ASSIGNED_CALL'
export const ISSUE_REVERT_TO_NOT_ASSIGNED_DONE =
    'ISSUE_REVERT_TO_NOT_ASSIGNED_DONE'
export const ISSUE_ACTION_PLAN_LOAD_CALL = 'ISSUE_ACTION_PLAN_LOAD_CALL'
export const ISSUE_ACTION_PLAN_LOAD_DONE = 'ISSUE_ACTION_PLAN_LOAD_DONE'
export const ISSUE_ACTION_PLAN_LOAD_FAIL = 'ISSUE_ACTION_PLAN_LOAD_FAIL'

export const ISSUE_SEND_MESSAGE_UPDATE_FULLSCREEN =
    'ISSUE_SEND_MESSAGE_UPDATE_FULLSCREEN'

export const ISSUE_GENERATE_SUMMARY_REPORT = 'ISSUE_GENERATE_SUMMARY_REPORT'

export const ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT =
    'ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT'

export const ISSUE_SEND_MESSAGE_UPDATE_ACTIVE =
    'ISSUE_SEND_MESSAGE_UPDATE_ACTIVE'

export const ISSUE_SEND_MESSAGE_UPDATE_MAXIMIZE =
    'ISSUE_SEND_MESSAGE_UPDATE_MAXIMIZE'

export const ISSUE_CONFIRM_HI_CALL = 'ISSUE_CONFIRM_HI_CALL'
export const ISSUE_CONFIRM_HI_DONE = 'ISSUE_CONFIRM_HI_DONE'
export const ISSUE_CONFIRM_HI_FAIL = 'ISSUE_CONFIRM_HI_FAIL'

export const ISSUE_DOWNGRADE_HI_CALL = 'ISSUE_DOWNGRADE_HI_CALL'
export const ISSUE_DOWNGRADE_HI_DONE = 'ISSUE_DOWNGRADE_HI_DONE'
export const ISSUE_DOWNGRADE_HI_FAIL = 'ISSUE_DOWNGRADE_HI_FAIL'

export const ISSUE_SEND_MESSAGE_CALL = 'ISSUE_SEND_MESSAGE_CALL'
export const ISSUE_SEND_MESSAGE_DONE = 'ISSUE_SEND_MESSAGE_DONE'
