import { useEffect, useCallback } from 'react'
import { useLocation, useHistory, useParams } from 'react-router'
import {
    loadIssue as loadIssueApi,
    clearError,
    IssueStoreState,
} from 'store/issue/index'
import { useSelector, useDispatch } from 'react-redux'
import SummaryContainer from './Sections/SummaryContainer'
import CaptureContainer from './Sections/CaptureContainer'
import { GoTopButton, Content } from 'components'
import AssignmentContainer from './Sections/AssignmentContainer'
import ResolutionContainer from './Sections/ResolutionContainer'
import ClosingContainer from './Sections/ClosingContainer'
import EffectivenessAndSustainability from './Sections/EffectivenessAndSustainabilityContainer'
import { Helmet } from 'react-helmet'
import * as ROUTES from 'routes'
import { RootState } from 'store/types'
import { IssueParams } from 'types/urlParams'
import { SEARCH_RCA_DISPOSE } from 'store/linkRca'

export const Issue = () => {
    const dispatch = useDispatch()
    const { issueId, issueNumber, error, loading, loadingDesc } = useSelector<
        RootState,
        IssueStoreState
    >((state) => state.issue)
    const params = useParams<IssueParams>()
    const title = issueNumber
    const history = useHistory()
    const location = useLocation()
    const loadIssue = useCallback(() => {
        dispatch(loadIssueApi(params.id))
    }, [dispatch, params.id])
    useEffect(() => {
        const paramIdOrNumber: string = params.id
        if (error || loading) {
            return
        }
        dispatch({ type: SEARCH_RCA_DISPOSE })
        if (isNaN(parseInt(paramIdOrNumber))) {
            if (issueNumber !== paramIdOrNumber) {
                loadIssue()
            }
        } else {
            if (issueId !== parseInt(paramIdOrNumber)) {
                loadIssue()
            }
        }
    }, [
        issueId,
        issueNumber,
        loading,
        loadIssue,
        location.hash,
        params,
        error,
        dispatch,
    ])

    return (
        <Content
            coverBackground={false}
            loading={loading}
            loadingDesc={loadingDesc}
            error={error}
            label="Return to Issue"
            onRetry={() => dispatch(clearError())}
            onNotFound={() => history.push(ROUTES.DASHBOARD)}
            overrideTitle={{ 404: 'Issue not found' }}
            variant="medium"
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>
            <SummaryContainer />
            <CaptureContainer />
            <AssignmentContainer />
            <ResolutionContainer />
            <ClosingContainer />
            <EffectivenessAndSustainability />
            <GoTopButton />
        </Content>
    )
}

export default Issue
