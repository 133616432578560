import { Close } from '@mui/icons-material'
import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { AdminDialog } from 'Admin'
import {
    Button,
    FormGridItem,
    GridContainer,
    MultiEmployeeSelect,
    ValueViewNext,
} from 'components'
import { useMemo, useState } from 'react'
import { AdminHubInfo } from 'types/adminTypes'
import { Employee } from 'types/issueTypes'
import { dataTestId } from 'utils'

export interface HubsManagePageDialogProps {
    open: boolean
    onClose: () => void
    selectedHubs: number[]
    hubs: AdminHubInfo[]
    onSave: (hubIds: number[], newHubProcessOwner: Employee[]) => void
    onUnselectHub: (hubId: number) => void
}

export const HubsManagePageDialog = (props: HubsManagePageDialogProps) => {
    const { open, onClose, onUnselectHub, onSave, selectedHubs, hubs } = props
    const [employees, setEmployees] = useState<Employee[]>([])
    const selectedHubsItems = useMemo<
        Array<{ id: number; name: string; code: string }>
    >(() => {
        const results = []
        selectedHubs.forEach((hubId) => {
            const hub = hubs.find((h) => h.id === hubId)
            if (hub) {
                results.push({ id: hub.id, name: hub.name, code: hub.code })
            }
        })
        return results
    }, [selectedHubs, hubs, open])
    const renderActions = () => (
        <>
            <Button onClick={onClose} {...dataTestId(`CLOSE_BUTTON`)}>
                Close
            </Button>
            <Button
                disabled={employees.length === 0}
                color="secondary"
                onClick={() => {
                    onSave(
                        selectedHubs,
                        employees.map((e) => ({
                            ...e,
                            organizationInfo: { ...e.organizationInfo },
                        }))
                    )
                    setEmployees([])
                }}
                {...dataTestId(`SAVE_BUTTON`)}
            >
                Add
            </Button>
        </>
    )
    const canUnselected = 1 < selectedHubs.length
    return (
        <AdminDialog
            title="Add Hub person(s)"
            open={open}
            onClose={onClose}
            renderActions={renderActions}
        >
            <GridContainer>
                <FormGridItem fullWidth>
                    <MultiEmployeeSelect
                        label="New Hub process owners"
                        employees={employees}
                        onEmployeesChanged={setEmployees}
                        {...dataTestId(`NEW_HUB_PROCESS_OWNERS_TEXT_FIELD`)}
                    />
                </FormGridItem>
                <FormGridItem fullWidth>
                    <ValueViewNext label="Selected HUBs" fullWidth>
                        <List disablePadding>
                            {selectedHubsItems.map((x) => (
                                <ListItem key={x.id} disableGutters>
                                    <ListItemText
                                        primary={x.code}
                                        primaryTypographyProps={{
                                            variant: 'body2',
                                        }}
                                    />
                                    {canUnselected && (
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() =>
                                                    onUnselectHub(x.id)
                                                }
                                                edge="end"
                                                size="large">
                                                <Close fontSize="small" />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    )}
                                </ListItem>
                            ))}
                        </List>
                    </ValueViewNext>
                </FormGridItem>
            </GridContainer>
        </AdminDialog>
    );
}
