import { all } from 'redux-saga/effects'
import { confirmHighImpactSaga } from './confirmHighImpact'
import { downgradeHighImpactSaga } from './downgradeHighImpact'
import { returnToIssueOwnerSaga } from './returnToIssueOwner'
import { verifyContainmentActionSaga } from './verifyContainmentAction'
import { verifyResolutionSaga } from './verifyResolution'
import { reopenIssueSaga } from './reopenIssue'
import { rejectResolutionSaga } from './rejectResolution'
import { exportMyActionsToExcel } from './exportMyActionsToExcel'

export function* root() {
    yield all([
        reopenIssueSaga(),
        confirmHighImpactSaga(),
        downgradeHighImpactSaga(),
        returnToIssueOwnerSaga(),
        verifyContainmentActionSaga(),
        verifyResolutionSaga(),
        rejectResolutionSaga(),
        exportMyActionsToExcel(),
    ])
}

export default root
