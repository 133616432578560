import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ActionsView, Dialog } from 'components'
import { AdminDialogDesktopProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        paper: {
            position: 'absolute',
            top: theme.spacing(8),
            overflow: 'hidden',
            display: 'flex',
            margin: '0 auto',
            width: '100%',
        },
        header: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: theme.mixins.toolbar.minHeight,
            padding: theme.spacing(0, 2),
            borderBottom: `1px solid ${theme.palette.divider}`,
        },
        title: {
            paddingLeft: theme.spacing(2),
        },
        content: {
            padding: theme.spacing(2),
            overflow: 'auto',
            '&$disabledContentPadddings': {
                padding: theme.spacing(0),
                overflowX: 'hidden',
            },
        },
        actions: {
            borderTop: `1px solid ${theme.palette.divider}`,
            padding: theme.spacing(0, 2),
        },
        disabledContentPadddings: {},
    }),
    {
        name: 'AdminDialogDesktop',
    }
)

export const AdminDialogDesktop = (props: AdminDialogDesktopProps) => {
    const {
        open,
        onClose,
        title,
        children,
        renderActions,
        disabledContentPadddings,
        ...other
    } = props
    const classes = useStyles()

    return (
        <Dialog
            open={open}
            onClose={(e, reason) => reason === 'escapeKeyDown' && onClose()}
            {...other}
            classes={{
                paper: classes.paper,
            }}
            maxWidth="sm"
        >
            <div className={classes.header}>
                <IconButton size="small" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
            </div>
            <section
                className={clsx(
                    classes.content,
                    disabledContentPadddings && classes.disabledContentPadddings
                )}
            >
                {children}
            </section>
            {renderActions && (
                <ActionsView className={classes.actions}>
                    {renderActions()}
                </ActionsView>
            )}
        </Dialog>
    )
}
