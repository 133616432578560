import { ViewColumn } from '@mui/icons-material'
import { BaseTextFieldProps } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { Chip, getShortOptionLabel } from 'components'
import { isEmpty } from 'lodash'
import { ChangeEvent, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store/types'
import { AllInOneColumn } from 'types/models'
import { getInitColumns } from '../../Reports/AllInOne/utils'
import { AutoCompleteInput } from './components'

export interface AllInOneColumnsSelectProps
    extends Partial<BaseTextFieldProps> {
    selectedColumns: AllInOneColumn[]
    onColumnsChanged: (items: AllInOneColumn[]) => void
    isUnitInternal?: boolean
}

export const getUnitInternalNcColumnFilter =
    (isUnitInternal: boolean = false) =>
    (column: AllInOneColumn) => {
        return column.isUnitInternal !== null
            ? column.isUnitInternal === isUnitInternal
            : true
    }

export const AllInOneColumnsSelect = (props: AllInOneColumnsSelectProps) => {
    const {
        label = 'Report columns',
        disabled,
        selectedColumns = [],
        onColumnsChanged,
        isUnitInternal = false,
        ...other
    } = props
    const resources = useSelector((state: RootState) => state?.resources)
    const columns = resources.allInOneReportColumns
    const options = useMemo(
        () =>
            columns
                .filter(getUnitInternalNcColumnFilter(isUnitInternal))
                .sort((a, b) => a.order - b.order),
        [columns, isUnitInternal]
    )
    const getOptionSelected = (
        option: AllInOneColumn,
        value: AllInOneColumn
    ): boolean => {
        return option.code === value.code
    }

    const handleOnChange = (
        e: ChangeEvent<any>,
        value: (string | AllInOneColumn)[]
    ) => {
        let col = value as AllInOneColumn[]
        const allIndex = col.findIndex((x) => x.code === '00')
        if (allIndex < col?.length - 1) {
            col = col.filter((x) => x.code !== '00')
        } else if (allIndex === col?.length - 1) {
            col = getInitColumns(resources)
        }
        onColumnsChanged && onColumnsChanged(col)
    }
    return (
        <Autocomplete
            multiple
            disabled={disabled}
            value={selectedColumns.sort((a, b) => a.order - b.order) ?? []}
            autoSelect={true}
            options={options}
            getOptionLabel={getShortOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText={'No match columns'}
            disableClearable={false}
            onChange={handleOnChange}
            renderOption={(props, option) => (
                <li {...props}>{getShortOptionLabel(option)}</li>
            )}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                    return (
                        <Chip
                            label={option?.name}
                            {...getTagProps({ index })}
                        />
                    )
                })
            }
            filterOptions={(filters, state) => {
                const inputValue = state.inputValue
                const emptyInput = isEmpty(inputValue)
                return emptyInput
                    ? filters
                    : filters.filter(
                          (f) =>
                              [String(f.order), f.code].includes(inputValue) ||
                              f.name
                                  .toLowerCase()
                                  .includes(inputValue.toLowerCase())
                      )
            }}
            renderInput={(params) => (
                <AutoCompleteInput
                    params={params}
                    label={label}
                    renderIcon={(props) => <ViewColumn {...props} />}
                    {...other}
                />
            )}
        />
    )
}

export default AllInOneColumnsSelect
