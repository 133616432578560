import axios, { AxiosResponse, CancelToken } from 'axios'
import { GlobalAppNotification } from 'DeveloperTools/UserGlobalNotification/types'

const controllerName = '/app-notifications'

export const getGlobalNotification = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<GlobalAppNotification>> =>
    axios.get(controllerName, { cancelToken })

export const saveGlobalNotification = (
    globalNotification: GlobalAppNotification,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.put(controllerName, { ...globalNotification }, { cancelToken })

export const deleteGlobalNotification = (
    cancelToken: CancelToken = null
): Promise<AxiosResponse<unknown>> =>
    axios.post(controllerName, { cancelToken })
