import { Collapse, Typography } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addAttachment, deleteAttachment } from 'store/issue/actions'
import { IssueValuesKeys } from 'types/enums'
import { Attachment } from 'types/issueTypes'
import FileManager from '../../components/Files/FileManager'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { useFieldMatrix } from '../matrix'

export interface CaptureAttachmentsProps
    extends React.HtmlHTMLAttributes<HTMLDivElement> {
    lightMode: boolean
    disabled: boolean
    issueId: number
    attachments?: Array<Attachment>
}

const CaptureAttachments = (props: CaptureAttachmentsProps) => {
    const { disabled, lightMode, issueId, attachments, ...other } = props
    const dispatch = useDispatch()

    const attachmentsField = useFieldMatrix(IssueValuesKeys.CaptureAttachments)
    return (
        <Collapse in={!lightMode}>
            <div {...other}>
                <Typography variant="h6">
                    Attachments (max file size is 100 MB)
                </Typography>
                <FileManager
                    {...ISSUE_VIEW_PAGE.ATTACHMENTS.FILE_MANAGER}
                    issueId={issueId}
                    attachmentType={2}
                    attachments={attachments}
                    disabled={attachmentsField.disabled || disabled}
                    onFileUploaded={(attachment) =>
                        dispatch(addAttachment(attachment))
                    }
                    onFileRemove={(attachment) =>
                        dispatch(deleteAttachment(attachment))
                    }
                />
            </div>
        </Collapse>
    )
}

CaptureAttachments.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default CaptureAttachments
