import { ButtonBase, ButtonBaseProps, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { ColorConfig } from './ColorPallete'

export interface ColorButtonProps
    extends ButtonBaseProps /*extends Omit<ButtonBaseProps, "onClick">*/ {
    colorConfig: ColorConfig
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            boxSizing: 'border-box',
            width: 42,
            height: 42,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            transition: 'all .4s',
            '&:hover': {
                opacity: 0.6,
                boxShadow: theme.shadows[1],
            },
        },
    }),
    { name: 'ColorButton' }
)

export const ColorButton = (props: ColorButtonProps) => {
    const { className, style, colorConfig, ...other } = props

    const classes = useStyles()
    return (
        <Tooltip title={colorConfig.label}>
            <ButtonBase
                className={clsx(className, classes.root)}
                style={{ ...style, backgroundColor: colorConfig.color }}
                onMouseDown={(e) => e.preventDefault()}
                {...other}
            />
        </Tooltip>
    )
}
