import { TagMeta } from '../types'

export const getSendEmailsTags: () => TagMeta[] = () => [
    {
        description: 'Containment Actions deadline date',
        tag: '%CONTAINMENT_ACTIONS_DEADLINE%',
    },
    { description: 'Coordinator email', tag: '%COORDINATOR_CONTACT%' },
    { description: 'Coordinator name', tag: '%COORDINATOR_NAME%' },
    { description: 'Current date', tag: '%CURRENT_DATE%' },
    { description: 'Customer Contact name', tag: '%CUSTOMER_CONTACT_NAME%' },
    {
        description: 'Expected Customer Execution date',
        tag: '%EXPECTED_EXECUTION_DATE%',
    },
    {
        description: 'High impact category descriptions',
        tag: '%HIGH_IMPACT_CATEGORY%',
    },
    { description: 'High impact reason', tag: '%HIGH_IMPACT_REASON%' },
    { description: 'Issue description', tag: '%ISSUE_DESCRIPTION%' },
    { description: 'Issue Owner name', tag: '%ISSUE_OWNER%' },
    { description: 'Issue Owner email', tag: '%ISSUE_OWNER_CONTACT%' },
    {
        description: 'Issue Owner Supervisor name',
        tag: '%ISSUE_OWNER_SUPERVISOR%',
    },
    {
        description: 'Issue Owner telephone/address',
        tag: '%ISSUE_OWNER_TELEADRESS%',
    },
    { description: 'Issue subject', tag: '%ISSUE_SUBJECT%' },
    { description: 'CCRP number', tag: '%ISSUENUMBER%' },
    { description: 'Resolution Owner name', tag: '%RESOLUTION_OWNER%' },
    {
        description: 'Target Resolution and Verification date',
        tag: '%TARGET_DATE%',
    },
]
