export const EMPTY = 0
export const NEW = 1
export const DRAFT = 2
export const NOT_ASSIGNED = 3
export const IN_PROCESS = 4
export const IN_PROCESS_OVERDUE = 5
export const RESOLVED = 6
export const COMPLETED = 8
export const IN_PROCESS_VERIVICATION = 11
export const IN_PROCESS_VERIVICATION_OVERDUE = 12
export const COMPLETED_EXECUTION_PENDING = 13
