import { ValueView } from 'components'
import { useMemo } from 'react'
import { ClosingSection } from 'types/issueTypes'
import { EMPTY_VALUE_PLACEHOLDER, getPrettyDateTime } from 'utils'

export interface ClosureConfirmationStatusContainerProps {
    closingSection: ClosingSection
}

const ClosureConfirmationStatusContainer = (
    props: ClosureConfirmationStatusContainerProps
) => {
    const { closingSection } = props
    const value = useMemo(() => {
        const {
            closureConfirmationStatus,
            isCurrentUserSubscribedForSurveys,
            isSendCustomerSurvey,
        } = closingSection
        const {
            isDeliveryFailure,
            isSurveySent,
            surveyResponseTime,
            surveySentTime,
            isValid,
        } = closureConfirmationStatus
        switch (true) {
            case !isSendCustomerSurvey || !isCurrentUserSubscribedForSurveys:
                return 'Survey has not been sent'
            case !isSurveySent && !isDeliveryFailure:
                return 'Survey is waiting for sending'
            case isDeliveryFailure:
                return 'Delivery failure'
            case Boolean(surveyResponseTime):
                return `Survey responed on ${getPrettyDateTime(
                    surveyResponseTime
                )}`
            case !isValid:
                return 'The survey has expired'
            case isSurveySent:
                return `Closure confirmation sent on ${getPrettyDateTime(
                    surveySentTime
                )}`
            default:
                return EMPTY_VALUE_PLACEHOLDER
        }
    }, [closingSection])

    return <ValueView label="Closure confirmation status" value={value} />
}

export default ClosureConfirmationStatusContainer
