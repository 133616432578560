import { ContentBlock, ContentState } from 'draft-js'
import { FC } from 'react'

export const findLinkEntities = (
    contentBlock: ContentBlock,
    callback: (start: number, end: number) => void,
    contentState: ContentState
) =>
    contentBlock.findEntityRanges((character) => {
        const entityKey = character.getEntity()
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === 'LINK'
        )
    }, callback)

export interface DraftHyperLinkProps {
    contentState: ContentState
    entityKey: string
}

export const DraftHyperLink: FC<DraftHyperLinkProps> = ({
    contentState,
    entityKey,
    children,
}) => <a href={contentState.getEntity(entityKey).getData()}>{children}</a>
