import { Close } from '@mui/icons-material'
import { Dialog, Grid, IconButton, Theme, Typography } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { makeStyles } from '@mui/styles'
import { createContactName, updateContactName } from 'api'
import {
    Button,
    Content,
    FormControlLabel,
    FullScreenDialogTransition,
    Switch,
} from 'components'
import { ChangeEvent, useEffect, useState } from 'react'
import { CustomerContactErrors, CustomerContactQuery } from 'types/issueTypes'
import { dataTestId } from 'utils'
import { getInitErrorsForCustomerContactDialog, getInitQuery } from '.'
import ErrorMessage from './ErrorMessage'
import GridItem from './GridItem'
import { CustomerContactDialogProps } from './types'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            backgroundColor: theme.palette.grey[100],
        },
        header: {
            '@media (max-width: 768px)': {
                marginLeft: theme.spacing(2),
            },
        },
        section: {
            marginBottom: theme.spacing(2),
            backgroundColor: theme.palette.common.white,
            padding: theme.spacing(2, 2, 0, 2),
            marginTop: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        actions: {
            display: 'inline-flex',
            height: 56,
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
        },
    }),
    { name: 'CustomerContactDialog' }
)

export const CustomerContactDialog = (props: CustomerContactDialogProps) => {
    const {
        customer,
        open,
        onClose,
        initQuery,
        onAddCustomerContact,
        onUpdateCustomerContact,
    } = props
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState(getInitQuery())
    const [errors, setErrors] = useState<CustomerContactErrors>(
        getInitErrorsForCustomerContactDialog()
    )
    const [error, setError] = useState<string>('')

    useEffect(() => {
        if (open === 'edit') {
            setQuery(initQuery)
        } else if (open === 'add') {
            setQuery(getInitQuery())
        }
    }, [open, initQuery])

    useEffect(() => {
        setError('')
        setErrors(getInitErrorsForCustomerContactDialog())
    }, [open])

    const pageTitle = `${customer?.name ?? ''}, ${customer?.countryCode ?? ''}`

    const classes = useStyles(props)

    const handleChange =
        (key: keyof CustomerContactQuery) =>
        (e: ChangeEvent<HTMLInputElement>) => {
            setErrors((prev: CustomerContactErrors) => ({
                ...prev,
                [key]: null,
            }))

            setQuery((prev: CustomerContactQuery) => ({
                ...prev,
                [key]: e.target.value,
            }))
        }

    const toggleCheckbox = (key: keyof CustomerContactQuery) => (e: any) => {
        setQuery((prev: CustomerContactQuery) => ({
            ...prev,
            [key]: e.target.checked,
        }))
    }

    const clearForm = () => {
        setErrors(getInitErrorsForCustomerContactDialog())
        setError(null)
        setQuery((prev: CustomerContactQuery) => ({
            ...prev,
            ...getInitQuery(),
        }))
    }

    const exitDialog = () => {
        clearForm()
        onClose()
    }

    const catchErrors = async (cb: () => void) => {
        try {
            setLoading(true)
            await cb()
        } catch (error: any) {
            if (error.error) {
                setError(error.error)
            } else if (error.errors) {
                const newErrors = {}

                error.errors.forEach((item) => {
                    const newField = item.field.split('')
                    newField[0] = newField[0].toLowerCase()
                    const key = newField.join('')
                    if (newErrors[key]) {
                        newErrors[key] = `${newErrors[key]}, ${item.message}`
                    } else {
                        newErrors[key] = item.message
                    }
                })

                setErrors((prev) => ({ ...prev, ...newErrors }))
            }
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateContactName = () => {
        catchErrors(async () => {
            const { id, ...customer } = query
            const res = await updateContactName(id, customer)
            exitDialog()
            onUpdateCustomerContact(res.data)
        })
    }

    const handleCreateContactName = () => {
        catchErrors(async () => {
            const res = await createContactName({
                ...query,
                customerGuid: customer.guid,
            })
            exitDialog()
            onAddCustomerContact(res.data)
        })
    }

    const sendForm = () => {
        if (open === 'edit') {
            handleUpdateContactName()
        } else if (open === 'add') {
            handleCreateContactName()
        }
    }

    const getFieldProps = (fieldName: keyof CustomerContactQuery) => {
        const helperText = errors[fieldName] ?? ''
        return {
            value: query[fieldName] ?? '',
            onChange: handleChange(fieldName),
            error: Boolean(errors[fieldName]),
            helperText,
        }
    }

    return (
        <Dialog
            fullScreen
            open={Boolean(open)}
            TransitionComponent={FullScreenDialogTransition}
            disableAutoFocus={true}
            disableRestoreFocus={true}
            classes={{ paper: classes.root }}
        >
            <AppBar position="static" color="default" elevation={0}>
                <Toolbar />
            </AppBar>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={onClose}
                        aria-label="close"
                        {...dataTestId('CUSTOMER_CLOSE_BUTTON')}
                        size="large"
                    >
                        <Close />
                    </IconButton>
                    <Typography variant="h6">
                        {open === 'edit'
                            ? 'Update customer contact'
                            : 'Create new customer contact'}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Content
                loading={loading}
                loadingDesc={
                    open === 'edit'
                        ? 'Updating customer contact…'
                        : 'Creating customer contact…'
                }
            >
                {open === 'edit' ? (
                    <Typography variant="caption">Update contact</Typography>
                ) : (
                    <Typography variant="caption">Add contact to</Typography>
                )}
                <Typography variant="h5">{pageTitle}</Typography>
                <section className={classes.section}>
                    {error ? (
                        <ErrorMessage
                            error={error}
                            onClick={() => setError(null)}
                        />
                    ) : null}
                    <Grid container spacing={2}>
                        <GridItem
                            label="First name"
                            placeholder="Contact first name"
                            required
                            {...getFieldProps('firstName')}
                            {...dataTestId('ADD_NEW_CONTACT_FIRST_NAME')}
                        />
                        <GridItem
                            label="Last name"
                            placeholder="Contact last name"
                            required
                            {...getFieldProps('lastName')}
                            {...dataTestId('ADD_NEW_CONTACT_LAST_NAME')}
                        />
                        <GridItem
                            label="Email"
                            placeholder="Contact email"
                            required
                            {...getFieldProps('email')}
                            {...dataTestId('ADD_NEW_CONTACT_EMAIL')}
                        />
                        <GridItem
                            label="Phone"
                            placeholder="Contact phone number"
                            {...getFieldProps('phone')}
                            {...dataTestId('ADD_NEW_CONTACT_PHONE')}
                        />

                        {open === 'edit' && (
                            <Grid item xs={12}>
                                <FormControlLabel
                                    label={
                                        query?.isActive
                                            ? 'Customer contact is active'
                                            : `Customer contact isn't active`
                                    }
                                    error={Boolean(errors?.isActive)}
                                    control={
                                        <Switch
                                            checked={query?.isActive}
                                            onChange={toggleCheckbox(
                                                'isActive'
                                            )}
                                            color="secondary"
                                            {...dataTestId(
                                                'CUSTOMER_SWITCH_ACTIVE_BUTTON'
                                            )}
                                        />
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                    <div className={classes.actions}>
                        <Button
                            onClick={clearForm}
                            {...dataTestId('ADD_NEW_CONTACT_CLEAR')}
                        >
                            Clear
                        </Button>
                        {open === 'edit' ? (
                            <Button
                                color="secondary"
                                onClick={sendForm}
                                {...dataTestId(
                                    'ADD_NEW_CONTACT_UPDATE_CONTACT'
                                )}
                            >
                                Update contact
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                onClick={sendForm}
                                {...dataTestId(
                                    'ADD_NEW_CONTACT_UPDATE_CONTACT'
                                )}
                            >
                                Create contact
                            </Button>
                        )}
                    </div>
                </section>
            </Content>
        </Dialog>
    )
}
