import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    loadIssue,
    resolveIssueDone,
    setIssueActionFail,
    ISSUE_RESOLVE_CALL,
} from '..'

import { resolveIssue } from 'api'
import { AttachmentTypes, IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { RootState } from 'store/types'
import { removeMyActionById } from 'store/myActions'

export function* resolveIssueAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { resolutionSection, attachments, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        if (!resolutionSection) {
            return
        }
        yield call(
            resolveIssue,
            {
                ...resolutionSection,
                rcaAttachments:
                    attachments?.filter(
                        (f) =>
                            f.attachmentType === AttachmentTypes.Resolution_Rca
                    ) ?? [],
            },
            changeLog?.resolve ?? null,
            cancelSource.token
        )
        yield put(resolveIssueDone())
        yield put(
            removeMyActionById(
                resolutionSection.issueId,
                IssueActionsTypes.To_Resolve
            )
        )
        yield put(showSnackbar('Issue was resolved'))
        yield put(loadIssue(resolutionSection.issueId))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_RESOLVE_CALL, error))
        yield put(showSnackbar('Cannot resolve issue!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* resolveIssueSaga() {
    yield takeLatest([ISSUE_RESOLVE_CALL], resolveIssueAction)
}
