import { AccordionActions } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes, ReactNode } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        content: { padding: theme.spacing(2) },
    }),
    { name: 'PageSection' }
)

export interface PageSectionProps extends HtmlHTMLAttributes<HTMLDivElement> {
    renderActions?: () => ReactNode
}

export const PageSection: FC<PageSectionProps> = (props) => {
    const { className, renderActions, children, ...other } = props
    const classes = useStyles()
    return (
        <section className={clsx(classes.root, className)} {...other}>
            <div className={classes.content}>{children}</div>
            {renderActions && (
                <AccordionActions>{renderActions()}</AccordionActions>
            )}
        </section>
    )
}
