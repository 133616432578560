import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { loadIssue, setIssueActionFail } from '..'
import { revertToNotAssigned } from 'api'
import { showSnackbar } from 'store/app'
import {
    ISSUE_ACTION_FAIL,
    ISSUE_REVERT_TO_NOT_ASSIGNED_CALL,
} from '../actionsTypes'
import { RevertToNotAssignedCommand } from '../types'

function* revertToNotAssignedAction(action: RevertToNotAssignedCommand) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    try {
        yield call(revertToNotAssigned, query, cancelSource.token)
        yield put(loadIssue(query.issueId))
        yield put(showSnackbar('Issue was reverted to Not assigned'))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_ACTION_FAIL, error))
        yield put(showSnackbar("Issue wasn't reverted to Not assigned!", true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* revertToNotAssignedSaga() {
    yield takeLatest(
        [ISSUE_REVERT_TO_NOT_ASSIGNED_CALL],
        revertToNotAssignedAction
    )
}
