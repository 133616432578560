import { FilterSection } from '.'
import {
    FormGridItem,
    GridContainer,
    MultiActivitySelect,
    MultiDivisionSelect,
    MultiCountrySelect,
    MultiBusinessUnitSelect,
    MultiProductGroupSelect,
    MultiRegionSelect,
    NullableBooleanSelect,
    MultiCitySelect,
    MultiDescriptiveNamesSelect,
} from 'components'
import { FiltersSectionBaseProps } from '../types'
import { ResolverFiltersState } from 'types/reportTypes'
import { dataTestId } from 'utils'

export interface ResolverFiltersProps extends FiltersSectionBaseProps {
    value: ResolverFiltersState
    onChange: (value: ResolverFiltersState) => void
}

export const ResolverFilters = (props: ResolverFiltersProps) => {
    const { value, onChange, ...other } = props
    const createChangeHandler =
        (
            key: keyof ResolverFiltersState,
            modifier: (
                value: ResolverFiltersState
            ) => ResolverFiltersState = null
        ) =>
        (newValue: any) => {
            let next = { ...value }
            next[key] = newValue
            if (modifier) {
                next = modifier(next)
            }
            onChange(next)
        }
    const createPlaceholder = (
        key: keyof ResolverFiltersState,
        placeholder: string = 'Click or type to select'
    ) => ((value[key] as Array<any>)?.length <= 0 ? placeholder : '')
    return (
        <FilterSection {...other}>
            <GridContainer>
                <FormGridItem>
                    <NullableBooleanSelect
                        label="Business Area type"
                        value={value.isGlobal}
                        optionTrueLabel="Global"
                        optionFalseLabel="Local"
                        optionNullLabel="All"
                        {...dataTestId('ALLINONE_BUSINESS_AREA_TYPE')}
                        onChange={createChangeHandler('isGlobal', (next) => {
                            if (next.isGlobal) {
                                return {
                                    ...next,
                                    regions: [],
                                    countries: [],
                                    cities: [],
                                    activities: [],
                                    resolvingUnitDescriptiveNames: [],
                                }
                            }
                            return next
                        })}
                    />
                    {/* Mapping bu->div and div->bu */}
                    <MultiDivisionSelect
                        placeholder={createPlaceholder('divisions')}
                        selectedDivisions={value?.divisions ?? []}
                        onBusinessAreasChanged={createChangeHandler(
                            'divisions'
                        )}
                        displayInactive={true}
                    />
                    <MultiBusinessUnitSelect
                        placeholder={createPlaceholder('businessUnits')}
                        divisions={value?.divisions ?? []}
                        selectedBusinessUnits={value?.businessUnits ?? []}
                        onBusinessUnitChanged={createChangeHandler(
                            'businessUnits'
                        )}
                        displayAll={true}
                        displayInactive={true}
                    />
                    <MultiProductGroupSelect
                        placeholder={createPlaceholder('productGroups')}
                        style={{ marginBottom: 0 }}
                        division={value?.divisions ?? []}
                        businessUnit={value?.businessUnits ?? []}
                        selectedProductsGroups={value?.productGroups ?? []}
                        onProductsGroupsChanged={createChangeHandler(
                            'productGroups'
                        )}
                        displayAll={true}
                        displayInactive={true}
                    />
                </FormGridItem>
                <FormGridItem>
                    <MultiRegionSelect
                        placeholder={createPlaceholder('regions')}
                        disabled={value.isGlobal === true}
                        selectedRegions={value?.regions ?? []}
                        onRegionsChanged={createChangeHandler('regions')}
                    />
                    <MultiCountrySelect
                        placeholder={createPlaceholder('countries')}
                        disabled={value.isGlobal === true}
                        regions={value?.regions ?? []}
                        selectedCountries={value?.countries ?? []}
                        onCountriesChanged={createChangeHandler('countries')}
                    />
                    <MultiCitySelect
                        placeholder={createPlaceholder('cities')}
                        disabled={value.isGlobal === true}
                        regions={value?.regions ?? []}
                        countries={value.countries ?? []}
                        selectedCities={value?.cities ?? []}
                        onCitiesChanged={createChangeHandler('cities')}
                        displayAll={true}
                    />
                    <MultiActivitySelect
                        placeholder={createPlaceholder('activities')}
                        style={{ marginBottom: 0 }}
                        disabled={value.isGlobal === true}
                        selectedActivities={value?.activities ?? []}
                        onActivitiesChanged={createChangeHandler('activities')}
                    />
                </FormGridItem>
                <FormGridItem fullWidth>
                    <MultiDescriptiveNamesSelect
                        placeholder={createPlaceholder(
                            'resolvingUnitDescriptiveNames'
                        )}
                        selectedItems={
                            value?.resolvingUnitDescriptiveNames ?? []
                        }
                        onSelectedItemsChanged={createChangeHandler(
                            'resolvingUnitDescriptiveNames'
                        )}
                        disabled={Boolean(value?.isGlobal)}
                    />
                </FormGridItem>
            </GridContainer>
        </FilterSection>
    )
}
