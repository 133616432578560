import axios, { AxiosResponse, CancelToken } from 'axios'
import { GenericNode } from 'components/TreeView/types'

const controllerName = '/failure-modes'

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */

export const getFailureModeByResolvingUnitAndFailureModeId = (
    resolvingUnitId: number,
    failureModeId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<GenericNode>> =>
    axios.get(`${controllerName}/${resolvingUnitId}/${failureModeId}`, {
        cancelToken,
    })

export const getFailureModeRootsByResolvingUnit = (
    resolvingUnitId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/roots/${resolvingUnitId}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} parentId - product CID
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const getFailureModeChildren = (
    resolvingUnitId: number,
    parentId: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.get(`${controllerName}/children/${resolvingUnitId}/${parentId}`, {
        cancelToken,
    })

/**
 * @param {number} resolvingUnitId - resolving unit id
 * @param {string} name - failure mode name
 * @param {CancelToken} cancelToken - Axios CancelToken
 * @returns {Promise} Axios promise of GET request
 */
export const searchFailureModesByName = (
    resolvingUnitId: number,
    name: string,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<GenericNode>>> =>
    axios.post(
        `${controllerName}/search`,
        { resolvingUnitId, name },
        { cancelToken }
    )
