import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'inline-flex',
            alignItems: 'center',
            color: theme.palette.grey[600],
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightBold as number,
            marginBottom: theme.spacing(0.5),
        },
    }),
    {
        name: 'Label',
    }
)

export interface LabelProps extends HtmlHTMLAttributes<HTMLSpanElement> {}

export const Label: FC<LabelProps> = (props) => {
    const { className, children, ...other } = props
    const classes = useStyles(props)
    return (
        <span className={clsx(classes.root, className)} {...other}>
            {children}
        </span>
    )
}

export default Label
