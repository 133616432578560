import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    ISSUE_VERIFY_RESOLUTION_CALL,
    loadIssue,
    verifyResolutionDone,
    setIssueActionFail,
} from '..'

import { RootState } from 'store/types'
import { AttachmentTypes, IssueActionsTypes } from 'types/enums'
import { removeMyActionById } from 'store/myActions'
import { showSnackbar } from 'store/app'
import { putVerifyResolution } from 'api'

export function* verifyResolutionAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueId, resolutionSection, attachments, changeLog } =
            (yield select((s: RootState) => s.issue)) as IssueStoreState
        yield call(
            putVerifyResolution,
            issueId,
            {
                ...resolutionSection,
                rcaAttachments:
                    attachments?.filter(
                        (a) =>
                            a.attachmentType === AttachmentTypes.Resolution_Rca
                    ) ?? [],
            },
            changeLog?.resolve ?? null,
            cancelSource.token
        )
        yield put(verifyResolutionDone())
        yield put(
            removeMyActionById(issueId, IssueActionsTypes.To_Verify_Resolution)
        )
        yield put(showSnackbar('Resolution was verified', false))
        yield put(loadIssue(issueId))
    } catch (error) {
        yield put(showSnackbar('Failed to verify issue resolution', true))
        yield put(setIssueActionFail(ISSUE_VERIFY_RESOLUTION_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* verifyResolutionSaga() {
    yield takeLatest([ISSUE_VERIFY_RESOLUTION_CALL], verifyResolutionAction)
}
