import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { DateView } from 'components'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueStatuses } from 'types/enums'

interface TargetDateProps extends HtmlHTMLAttributes<HTMLDivElement> {
    date: string
    canExtendTargetResolutionDate?: boolean
    extendTargetDate?: () => void
    issueStatus: number
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            position: 'relative',
            flexDirection: 'row',
            alignItems: 'ceneter',
            marginLeft: theme.spacing(5),
        },
        edit: {
            position: 'absolute',
            bottom: 20,
            left: 105,
            width: 10,
            height: 10,
        },
        target: {
            marginBottom: 0,
            marginTop: theme.spacing(1),
        },
        dot: {
            backgroundColor: theme.palette.success.dark,
            borderRadius: '50%',
            marginTop: theme.spacing(0.4),
            width: 12,
            height: 12,
            marginRight: theme.spacing(0.5),
        },
        timeLeft: {
            display: 'flex',
            justifyContent: 'flex-end',
        },
        red: { backgroundColor: theme.palette.error.main },
    }),
    { name: 'TargetDate' }
)

export const TargetDate = (props: TargetDateProps) => {
    const {
        date,
        canExtendTargetResolutionDate,
        extendTargetDate,
        issueStatus,
    } = props
    const classes = useStyles()

    const targetDateLeft = dayjs(date).utc().format()
    const duration = useMemo(() => {
        return dayjs(date).utc().diff(dayjs().utc())
    }, [date])

    const handleExtendTargetDate = () => extendTargetDate && extendTargetDate()
    return (
        <div className={classes.root}>
            {canExtendTargetResolutionDate && (
                <IconButton
                    onClick={handleExtendTargetDate}
                    className={classes.edit}
                    size="large"
                >
                    <EditIcon style={{ width: 18, height: 18 }} />
                </IconButton>
            )}
            <DateView
                actionName={`Target date`}
                date={targetDateLeft}
                className={classes.target}
                formatValue={
                    ![
                        IssueStatuses.Completed_Execution_Pending,
                        IssueStatuses.Completed,
                    ].includes(issueStatus)
                        ? (date) => (
                              <>
                                  <span
                                      className={clsx(
                                          classes.dot,
                                          duration < 0 && classes.red
                                      )}
                                  />
                                  {date}
                              </>
                          )
                        : null
                }
            />
        </div>
    )
}
