import { Grid, GridProps, Paper, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FC } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            boxSizing: 'border-box',
            padding: theme.spacing(2),
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        title: {
            marginBottom: theme.spacing(2),
        },
    }),
    { name: 'EditorSection' }
)

export interface EditorSectionProps extends GridProps {
    title: string
}

export const EditorSection: FC<EditorSectionProps> = (props) => {
    const { title, children } = props
    const classes = useStyles()
    return (
        <Grid sm={12} xs={12} md={12} lg={6} xl={6} item>
            <Paper className={classes.root} elevation={0}>
                <Typography className={classes.title} variant="h6">
                    {title}
                </Typography>
                {children}
            </Paper>
        </Grid>
    )
}
