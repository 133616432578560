import { PersonOutlined, SupervisorAccountOutlined } from '@mui/icons-material'
import { Button, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    AdminCardHeader,
    AdminEmployeeChipWithProtection,
    AdminEmployeesChipsSimple,
    DialogModeType,
    DialogOrganizationalInfo,
    generateHeaderForProcessOwnerCard,
    generateProcessOwnerGroups,
    getFilteredWithEmptyProcessOwners,
    getInitDialogOrganizationalInfo,
    ProcessOwnersManagePageCardProps,
} from 'Admin'
import { AdminEmployeeLabel } from 'Admin/components/AdminEmployeesChips/AdminEmployeeLabel'
import { CardViewSimple } from 'components'
import { useProcessOwnerChecker } from 'hooks'
import { useMemo } from 'react'
import { OrganizationalInfo } from 'types/issueTypes'
import { dataTestId } from 'utils'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        newConcept: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            padding: theme.spacing(1, 1.5),
        },
        icon: {
            width: theme.spacing(2),
            height: theme.spacing(2),
            margin: theme.spacing(0, 1),
        },
        border: {
            border: `1px solid ${theme.palette.grey[100]}`,
            width: '100%',
        },
        card: {
            padding: 0 + '!important',
        },
        container: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        button: {
            height: 'fit-content',
            minWidth: '105px',
            margin: '.5rem',
        },
        chip: {
            margin: theme.spacing(0.5),
        },
    }),
    {
        name: 'ProcessOwnersManagePageCard',
    }
)

export const ProcessOwnersManagePageCard2 = (
    props: ProcessOwnersManagePageCardProps
) => {
    const classes = useStyles()
    const {
        adminProcessOwner,
        isSupportDesk,
        openDialog,
        deleteProcessOwner,
        ...other
    } = props
    const [checkProcessOwner] = useProcessOwnerChecker()

    const filtered = useMemo(() => {
        const groups = generateProcessOwnerGroups(adminProcessOwner)
        const filtered = Object.values(groups).filter(
            (item) => item.items.length > 0
        )
        return getFilteredWithEmptyProcessOwners(filtered, adminProcessOwner)
    }, [adminProcessOwner])

    const onOpenDialog = (
        dialogMode: DialogModeType,
        organizationalInfo: DialogOrganizationalInfo
    ) => {
        const { division, businessUnit, productGroup } = organizationalInfo
        let updatedOrganizationalInfo: DialogOrganizationalInfo =
            getInitDialogOrganizationalInfo()

        if (
            dialogMode === 'businessArea' ||
            dialogMode === 'businessAreaAndRegionOrCountry'
        ) {
            updatedOrganizationalInfo.division = division
        } else if (
            dialogMode === 'businessUnit' ||
            dialogMode === 'businessUnitAndRegionOrCountry'
        ) {
            updatedOrganizationalInfo.businessUnit = businessUnit
        } else if (
            dialogMode === 'productGroup' ||
            dialogMode === 'productGroupAndRegionOrCountry'
        ) {
            updatedOrganizationalInfo.productGroup = productGroup
        }
        openDialog(dialogMode, updatedOrganizationalInfo)
    }
    const getOriganizationInfoAppendix = (
        dialogMode: DialogModeType,
        organizationInfo: OrganizationalInfo
    ): string => {
        switch (dialogMode) {
            case 'businessArea':
            case 'businessUnit':
            case 'productGroup':
                return ''
            case 'businessAreaAndRegionOrCountry':
            case 'businessUnitAndRegionOrCountry':
            case 'productGroupAndRegionOrCountry':
                return ` (${
                    organizationInfo?.country ?? organizationInfo?.region ?? ''
                })`
            default:
                return ''
        }
    }

    return (
        <CardViewSimple
            renderHeader={() => (
                <AdminCardHeader>
                    {generateHeaderForProcessOwnerCard(
                        adminProcessOwner.organizationalInfo
                    )}
                </AdminCardHeader>
            )}
            classes={{ content: classes.card }}
            {...other}
        >
            {filtered.map(({ title, items, dialogMode, organizationInfo }) => {
                const disabled = !checkProcessOwner(
                    organizationInfo,
                    false,
                    false
                )
                return (
                    <>
                        <div className={classes.border} />
                        <div className={classes.container}>
                            <AdminEmployeesChipsSimple
                                key={title}
                                label={title}
                                renderIcon={(props) => (
                                    <PersonOutlined {...props} />
                                )}
                                newConceptRender={(props) => (
                                    <div className={classes.newConcept}>
                                        {props}
                                    </div>
                                )}
                            >
                                <AdminEmployeeLabel
                                    name={title}
                                    icon={() => (
                                        <SupervisorAccountOutlined
                                            className={classes.icon}
                                        />
                                    )}
                                />
                                {items.map((processOwner) => {
                                    const disabled = !checkProcessOwner(
                                        processOwner.organizationInfo,
                                        false,
                                        true
                                    )
                                    const isLast = isSupportDesk
                                        ? false
                                        : items.length === 1
                                    return (
                                        <>
                                            <AdminEmployeeChipWithProtection
                                                className={classes.chip}
                                                disabled={disabled}
                                                key={processOwner.id}
                                                label={`${
                                                    processOwner.fullName
                                                }${getOriganizationInfoAppendix(
                                                    dialogMode,
                                                    processOwner.organizationInfo
                                                )}`}
                                                employee={processOwner}
                                                isLast={isLast}
                                                isLastMessage="Cannot delete the only Process Owner"
                                                onDelete={() =>
                                                    deleteProcessOwner(
                                                        processOwner.id
                                                    )
                                                }
                                            />
                                        </>
                                    )
                                })}
                            </AdminEmployeesChipsSimple>
                            <div>
                                <Button
                                    {...dataTestId(
                                        disabled
                                            ? 'ADD_PERSON_RU_BUTTON_DISABLED'
                                            : 'ADD_PERSON_RU_BUTTON'
                                    )}
                                    className={classes.button}
                                    disabled={disabled}
                                    color="secondary"
                                    onClick={() => {
                                        onOpenDialog(
                                            dialogMode,
                                            adminProcessOwner.organizationalInfo
                                        )
                                    }}
                                >
                                    Add PERSON
                                </Button>
                            </div>
                        </div>
                    </>
                )
            })}
        </CardViewSimple>
    )
}
