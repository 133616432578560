import { Collapse, useTheme } from '@mui/material'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    deleteSupplier,
    IssueStoreState,
    updateCaptureRoot,
    updateIssueImpactClassification,
    updateWarrantyRoot,
} from 'store/issue/index'
import { RootState } from 'store/types'
import { AttachmentTypes, IssueActionsTypes, IssueTypes } from 'types/enums'
import { CaptureSection } from 'types/issueTypes'
import { urlBuilder } from 'utils'
import * as ROUTES from '../routes'
import AdditionalProductInformation from './AdditionalProductInformation'
import CaptureAttachments from './CaptureAttachments'
import Classifications from './Classifications'
import CustomerSelection from './CustomerSelection'
import ImpactClassification from './ImpactClassification'
import InformationAboutIssue from './InformationAboutIssue'
import IssueOwnerAndResolvingUnit from './IssueOwnerAndResolvingUnit'
import IssueTypeSection from './IssueTypeSection'
import { NpsAdditionalInformation } from './NpsAdditionalInformation'
import OnBehalfOf from './OnBehalfOf'
import ProductAndSubjectInformation from './ProductAndSubjectInformation/ProductAndSubjectInformation'
import SalesforceAdditionalInformation from './SalesForceAdditionalInformation'
import TargetDateAndContainmentActions from './TargetDateAndContainmentActions'

const CaptureContent = () => {
    const {
        issueNumber,
        context,
        captureSection,
        lightMode,
        disabled,
        attachments,
        oldTargetResolutionAndVerificationDate,
    } = useSelector<RootState, IssueStoreState>((state) => state.issue)
    const dispatch = useDispatch()
    const canConfirmHI = useMemo(() => {
        return context?.actions?.some(
            (a) => a.actionCode === IssueActionsTypes.To_Verify_HI
        )
    }, [context])
    const theme = useTheme()
    const style = { marginTop: theme.spacing(3) }
    const history = useHistory()
    const issueId = captureSection?.issueId
    const issueType = captureSection?.issueType ?? IssueTypes.External
    const npsAdditionalInfo = captureSection?.npsAdditionalInfo
    const customer = captureSection?.customer
    const customerContact = captureSection?.customerContact
    const sfdcAdditionalInfo = captureSection?.sfdcAdditionalInfo
    const handleCaptureSectionUpdate = (
        key: keyof CaptureSection,
        value: any
    ) => dispatch(updateCaptureRoot(key, value))
    return (
        <>
            <IssueTypeSection
                issueType={issueType}
                issueCreationType={captureSection?.issueCreationType}
                onIssueTypeChanged={(issueType: IssueTypes) => {
                    dispatch(updateCaptureRoot('issueType', issueType))
                }}
            />
            {npsAdditionalInfo && (
                <NpsAdditionalInformation
                    npsAdditionalInfo={npsAdditionalInfo}
                    customer={customer}
                    customerContact={customerContact}
                    issueType={issueType}
                />
            )}
            {sfdcAdditionalInfo && (
                <SalesforceAdditionalInformation
                    sfdcAdditionalInfo={sfdcAdditionalInfo}
                />
            )}
            <InformationAboutIssue
                lightMode={lightMode}
                issueType={issueType}
                captureSection={captureSection}
                onCaptureSectionChanged={(key, newValue) => {
                    dispatch(updateCaptureRoot(key, newValue))
                }}
            />
            <Collapse in={issueType !== IssueTypes.Internal_NC}>
                <ImpactClassification
                    lightMode={lightMode}
                    issueNumber={issueNumber}
                    canConfirmHI={canConfirmHI}
                    style={{ ...style }}
                    highImpact={captureSection?.highImpact}
                    onImpactClassificationChanged={(key, newValue) =>
                        dispatch(updateIssueImpactClassification(key, newValue))
                    }
                    issueOwner={captureSection?.issueOwner}
                />
            </Collapse>

            <TargetDateAndContainmentActions
                lightMode={lightMode}
                style={{ ...style }}
                issueType={issueType}
                captureSection={captureSection}
                oldTargetResolutionAndVerificationDate={
                    oldTargetResolutionAndVerificationDate
                }
                onCaptureSectionChanged={(propName, value) =>
                    dispatch(updateCaptureRoot(propName, value))
                }
            />
            <Collapse in={issueType !== IssueTypes.Internal_NC}>
                <CustomerSelection
                    style={{ ...style }}
                    issueType={captureSection?.issueType}
                    customer={captureSection?.customer}
                    customerContact={captureSection?.customerContact}
                    onEditCustomerName={() =>
                        issueId
                            ? history.push(
                                  urlBuilder(ROUTES.ISSUE_VIEW_CUSTOMER_NAME, {
                                      id: issueId,
                                  })
                              )
                            : history.push(ROUTES.ISSUE_NEW_CUSTOMER_NAME)
                    }
                    onEditCustomerContactName={() =>
                        issueId
                            ? history.push(
                                  urlBuilder(
                                      ROUTES.ISSUE_VIEW_CUSTOMER_CONTACT_NAME,
                                      { id: issueId }
                                  )
                              )
                            : history.push(
                                  ROUTES.ISSUE_NEW_CUSTOMER_CONTACT_NAME
                              )
                    }
                />
            </Collapse>
            <ProductAndSubjectInformation
                lightMode={lightMode}
                style={{ ...style }}
                resolvingUnit={captureSection?.resolvingUnit ?? null}
                failureModeName={captureSection?.failureModeName}
                failureModeDescription={
                    captureSection?.failureModeDescription ?? null
                }
                failureModeDescriptionRequired={
                    captureSection?.isFailureModeDescriptionRequired ?? null
                }
                productName={captureSection?.product?.name ?? null}
                productLine={captureSection?.productLineLocation ?? null}
                captureSection={captureSection}
                onCaptureSectionChanged={handleCaptureSectionUpdate}
                onFailureModeDescriptionChanged={(desc: string) =>
                    dispatch(updateCaptureRoot('failureModeDescription', desc))
                }
                onEditResolvingUnit={() =>
                    issueId
                        ? history.push(
                              urlBuilder(ROUTES.ISSUE_VIEW_RESOLVING_UNIT, {
                                  id: issueId,
                              })
                          )
                        : history.push(ROUTES.ISSUE_NEW_RESOLVING_UNIT)
                }
                onEditAbbProduct={() =>
                    issueId
                        ? history.push(
                              urlBuilder(ROUTES.ISSUE_VIEW_ABB_PRODUCT, {
                                  id: issueId,
                              })
                          )
                        : history.push(ROUTES.ISSUE_NEW_ABB_PRODUCT)
                }
                onEditFailureMode={() =>
                    issueId
                        ? history.push(
                              urlBuilder(ROUTES.ISSUE_VIEW_FAILURE_MODE, {
                                  id: issueId,
                              })
                          )
                        : history.push(ROUTES.ISSUE_NEW_FAILURE_MODE)
                }
                onEditProductLine={() =>
                    issueId
                        ? history.push(
                              urlBuilder(ROUTES.ISSUE_VIEW_PRODUCT_LINE, {
                                  id: issueId,
                              })
                          )
                        : history.push(ROUTES.ISSUE_NEW_PRODUCT_LINE)
                }
            />
            <AdditionalProductInformation
                disabled={disabled}
                lightMode={lightMode}
                style={{ ...style }}
                captureSection={captureSection}
                onCaptureSectionChanged={handleCaptureSectionUpdate}
                onWarrantyChanged={(key, value) =>
                    dispatch(updateWarrantyRoot(key, value))
                }
                onEditSupplier={() =>
                    issueId
                        ? history.push(
                              urlBuilder(ROUTES.ISSUE_VIEW_SUPPLIER, {
                                  id: issueId,
                              })
                          )
                        : history.push(ROUTES.ISSUE_NEW_SUPPLIER)
                }
                onClearSupplier={() => dispatch(deleteSupplier())}
            />

            <CaptureAttachments
                lightMode={lightMode}
                style={{ ...style }}
                disabled={disabled}
                issueId={captureSection?.issueId}
                attachments={
                    attachments?.filter(
                        (a) => a.attachmentType === AttachmentTypes.Information
                    ) ?? []
                }
            />
            <IssueOwnerAndResolvingUnit
                lightMode={lightMode}
                style={{ ...style }}
                issueOwner={captureSection?.issueOwner}
                issueOwnerTeam={captureSection?.issueOwnerTeam}
                isCopySentToParties={Boolean(
                    captureSection?.isCopySentToParties
                )}
                proposedResolutionOwner={
                    captureSection?.proposedResolutionOwner
                }
                partiesReference={captureSection?.partiesReference}
                onCaptureSectionChanged={handleCaptureSectionUpdate}
            />
            {!lightMode && (
                <>
                    <OnBehalfOf
                        style={{ ...style }}
                        onCaptureSectionChanged={handleCaptureSectionUpdate}
                        captureSection={captureSection}
                        disabled={disabled}
                    />
                    {captureSection?.resolvingUnit?.classifications.length >
                        0 && (
                        <Classifications
                            style={{ ...style }}
                            onCaptureSectionChanged={handleCaptureSectionUpdate}
                            captureSection={captureSection}
                            disabled={disabled}
                        />
                    )}
                </>
            )}
        </>
    )
}

CaptureContent.defaultProps = {
    disabled: false,
}

export default CaptureContent
