import Clear from '@mui/icons-material/Clear'
import FilterList from '@mui/icons-material/FilterList'
import Search from '@mui/icons-material/Search'
import {
    AccordionActions,
    Badge,
    Collapse,
    IconButton,
    InputBase,
    InputBaseProps,
    Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { SEARCH_PANEL_ELEMENT } from 'consts/selectorIds'
import { ReactNode, useEffect, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { FlexGrow } from '.'

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        const placeholderStyle = {
            fontSize: 16,
            fontWeight: theme.typography.fontWeightLight,
            opacity: 1,
            color: theme.palette.grey[600],
        }
        return {
            root: {
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: theme.palette.common.white,
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: theme.shape.borderRadius,
            },
            searchBar: {
                display: 'inline-flex',
                width: '100%',
                height: 56,
                boxSizing: 'border-box',
                alignItems: 'center',
                padding: theme.spacing(0, 1),
                transition: 'borderBottom .4s ',
                '&$error': {
                    backgroundColor: theme.palette.error.light,
                    color: theme.palette.error.contrastText,
                },
            },
            error: {},
            divider: {
                borderBottom: `1px solid ${theme.palette.divider}`,
            },
            input: {
                margin: theme.spacing(0, 1),
                flexGrow: 1,
                '& input': {
                    '&::placeholder': {
                        ...placeholderStyle,
                    },
                    '&::-moz-placeholder': {
                        ...placeholderStyle,
                    },
                    '&:-ms-input-placeholder': {
                        ...placeholderStyle,
                    },
                    ':-moz-placeholder': {
                        ...placeholderStyle,
                    },
                },
            },
            filters: {
                padding: theme.spacing(2),
            },
            filtersBadge: {
                marginTop: theme.spacing(1.5),
                marginRight: theme.spacing(1.5),
            },
            filterIcon: {
                margin: theme.spacing(0, 1.5),
            },
            disabledTextSearch: { paddingLeft: theme.spacing(2) },
            title: { marginLeft: theme.spacing(2) },
        }
    },
    { name: 'SearchBar' }
)

export interface SearchPanelProps
    extends Partial<{
        className?: string
        open?: boolean
        initOpen: boolean
        searchText: string
        placeholder: string
        disabled: boolean
        error: any
        InputBaseProps?: Partial<InputBaseProps>
        renderActions?: () => ReactNode
        renderEndAdornment?: () => ReactNode
        onEnter: () => void
        onChange: (text: string) => void
        onClearText: () => void
        onOpen?: (open: boolean) => void
        selectedFiltersCount: number
        disabledTextSearch?: boolean
    }> {}

export const SearchPanel: React.FC<SearchPanelProps> = (props) => {
    const {
        className,
        open: openProp,
        onOpen,
        children,
        initOpen,
        searchText,
        placeholder,
        disabled,
        InputBaseProps,
        error,
        renderActions,
        onEnter,
        onChange,
        onClearText,
        selectedFiltersCount,
        disabledTextSearch = false,
        renderEndAdornment,
    } = props
    const classes = useStyles(props)
    const [open, setOpen] = useState(false)
    useEffect(() => initOpen && setOpen(true), [initOpen])
    useEffect(() => {
        if (openProp) {
            setOpen(openProp)
        }
    }, [openProp])
    const handleSearchEnter = (e: any) =>
        Boolean(e.target.value) && e.keyCode === 13 && onEnter && onEnter()
    const handleOpen = () => {
        setOpen((prev) => {
            const results = !prev
            onOpen && onOpen(results)
            return results
        })
    }
    return (
        <div className={clsx(classes.root, className)}>
            <div
                className={clsx(
                    classes.searchBar,
                    children && open && classes.divider,
                    error && classes.error,
                    disabledTextSearch && classes.disabledTextSearch
                )}
            >
                {disabledTextSearch ? (
                    <>
                        <Search fontSize="small" color="primary" />
                        <Typography className={classes.title} variant="h6">
                            {placeholder}
                        </Typography>
                        <FlexGrow />
                        <Badge
                            badgeContent={selectedFiltersCount}
                            color="secondary"
                            classes={{ badge: classes.filtersBadge }}
                        >
                            <IconButton
                                disabled={disabled}
                                onClick={handleOpen}
                                size="large"
                            >
                                <FilterList fontSize="small" color="primary" />
                            </IconButton>
                        </Badge>
                    </>
                ) : (
                    <>
                        <IconButton
                            onClick={() => onClearText && onClearText()}
                            disabled={!Boolean(searchText) || disabled}
                            size="large"
                        >
                            {!Boolean(searchText) ? (
                                <Search fontSize="small" color="primary" />
                            ) : (
                                <Clear
                                    fontSize="small"
                                    color="primary"
                                    {...SEARCH_PANEL_ELEMENT.CLEAR_BUTTON}
                                />
                            )}
                        </IconButton>
                        <InputBase
                            {...SEARCH_PANEL_ELEMENT.SEARCH_TEXT_INPUT}
                            disabled={disabled}
                            onChange={(e) =>
                                onChange && onChange(e.target.value)
                            }
                            className={classes.input}
                            inputProps={{ onKeyUp: handleSearchEnter }}
                            placeholder={placeholder}
                            value={searchText}
                            {...InputBaseProps}
                        />

                        {renderEndAdornment ? renderEndAdornment() : null}
                        {children && (
                            <Badge
                                badgeContent={selectedFiltersCount}
                                color="secondary"
                                classes={{ badge: classes.filtersBadge }}
                            >
                                <IconButton
                                    {...SEARCH_PANEL_ELEMENT.FILTERS_LIST_EXPAND_BUTTON}
                                    disabled={disabled}
                                    onClick={handleOpen}
                                    size="large"
                                >
                                    <FilterList
                                        fontSize="small"
                                        color="primary"
                                    />
                                </IconButton>
                            </Badge>
                        )}
                    </>
                )}
            </div>
            {children && (
                <Collapse in={open}>
                    <div className={clsx(classes.filters)}>{children}</div>
                    {renderActions && (
                        <AccordionActions>{renderActions()}</AccordionActions>
                    )}
                </Collapse>
            )}
        </div>
    )
}

SearchPanel.defaultProps = {
    searchText: '',
    placeholder: 'Search…',
    selectedFiltersCount: 0,
    error: false,
    disabled: false,
    InputBaseProps: {},
}

export default SearchPanel
