import { Button } from '@mui/material'
import { ReturnToResolutionOwnerDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { rejectResolution } from 'store/myActions'
import { ReturnToRO } from 'types/actions'
import { TileActionsProps } from '..'

export const ToCompleteIssueActions = (props: TileActionsProps) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [state, setState] = useState({
        returnOpen: false,
        surveyConfirmOpen: false,
        completeOpen: false,
        formData: null,
    })
    const { returnOpen } = state
    const { myActionMeta, issueNumber } = props
    const handleReturnToRO = (formData: ReturnToRO) => {
        setState((prev) => ({ ...prev, returnOpen: false }))
        dispatch(rejectResolution(formData))
    }
    return (
        <>
            <Button
                onClick={() =>
                    setState((prev) => ({ ...prev, returnOpen: true }))
                }
                eventName="Return to resolution owner on dashboard"
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.RETURN_ISSUE}
            >
                Return issue
            </Button>
            <Button
                color="secondary"
                onClick={() => history.push(`/issue/${myActionMeta.issueId}`)}
                eventName="Complete issue on dashboard"
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.COMPLETE_ISSUE}
            >
                Complete issue
            </Button>
            <ReturnToResolutionOwnerDialog
                open={returnOpen}
                message={`You are about to reject resolution for issue ${issueNumber}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                minHeight={220}
                rowVersion={myActionMeta.rowVersion}
                number={issueNumber}
                issueId={myActionMeta.issueId}
                onReturnToRO={handleReturnToRO}
                onClose={() =>
                    setState((prev) => ({ ...prev, returnOpen: false }))
                }
            />
        </>
    )
}
