import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getActionPlanDashboard } from 'api'
import { NoResults, SearchResultsWithPagination, Space } from 'components'
import { HtmlHTMLAttributes, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router'
import { ISSUE_ACTION_PLAN_ITEM } from 'routes'
import { RootState } from 'store/types'
import { ActionItemBase, ActionItemStatus } from 'types/actionPlanTypes'
import { UserProfile } from 'types/profile'
import { getResolutionUnitName } from 'utils'
import {
    ActionPlanExportSection,
    ActionPlanItemsQuery,
    ActionPlanItemsStats,
    ActionPlanItemViewModel,
    getActionPlanStats,
    getSearchProporties,
    sortFilteredResults,
} from '.'
import { ActionPlanItemDashboard } from './ActionPlanItemDashboard'

const PAGE_SIZE = 10

const useStyles = makeStyles<Theme>(
    (theme) => ({
        loader: {
            display: 'flex',
            minHeight: 124,
            justifyContent: 'center',
            alignItems: 'center',
        },
        pagination: {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        },
    }),
    { name: 'ActionPlanList' }
)

export interface ActionPlanListProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    actionPlanItemsQuery: ActionPlanItemsQuery
    onStatsChanged: (stats: ActionPlanItemsStats) => void
}

export const ActionPlanList = (props: ActionPlanListProps) => {
    const { actionPlanItemsQuery, onStatsChanged } = props
    const { email: currentUserEmail } = useSelector<RootState, UserProfile>(
        (state) => state.dashboard.profile
    )
    const {
        wildcard,
        statuses,
        actionCreatorFilter,
        actionOwnerFilter,
        coordinatiorFilter,
        sortByProperty,
        descending,
    } = actionPlanItemsQuery
    const history = useHistory()
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [items, setItems] = useState<ActionPlanItemViewModel[]>([])
    const handleStatsChange = (items: ActionItemBase[]) =>
        onStatsChanged(getActionPlanStats(items, currentUserEmail))
    useEffect(() => {
        if (loading) {
            return
        }
        setLoading(true)
        getActionPlanDashboard()
            .then((res) => {
                const results = res.data.map((x) => ({
                    ...x,
                    resolvingUnitName: getResolutionUnitName(x.resolvingUnit),
                    actionOwnerFullName: x?.actionOwner?.fullName ?? '',
                    actionCreatorFullName: x?.actionCreator?.fullName ?? '',
                    resolutionOwnerFullName: x?.resolutionOwner?.fullName ?? '',
                    coordinatorFullName: x?.issueCoordinator?.fullName ?? '',
                    actionTypeName: x?.actionType?.name ?? '',
                }))
                setItems(results)
                handleStatsChange(results)
            })
            .catch(setError)
            .finally(() => setLoading(false))
    }, [])
    useEffect(() => {
        setPage(1)
    }, [wildcard])
    const itemsFiltred = useMemo(() => {
        const searchProperties = getSearchProporties()
        let results = [...items] ?? []
        if (
            0 === statuses.length &&
            !actionCreatorFilter &&
            !actionOwnerFilter &&
            !coordinatiorFilter
        ) {
            return []
        }
        results = Boolean(wildcard)
            ? items.filter((x) => {
                  return Boolean(
                      searchProperties.find((key) =>
                          String(x[key])
                              .toLocaleUpperCase()
                              .includes(wildcard.toLocaleUpperCase(), 0)
                      )
                  )
              })
            : items
        if (0 < statuses.length) {
            results = results.filter(
                (x) =>
                    ((statuses.includes(x.actionStatus) && !x.isDeleted) ||
                        (statuses.includes(ActionItemStatus.Deleted) &&
                            x.isDeleted)) ??
                    false
            )
        }
        if (actionCreatorFilter || actionOwnerFilter || coordinatiorFilter) {
            results = results.filter(
                (x) =>
                    (actionCreatorFilter
                        ? x.actionCreator?.email === currentUserEmail
                        : false) ||
                    (actionOwnerFilter
                        ? x.actionOwner?.email === currentUserEmail
                        : false) ||
                    (coordinatiorFilter
                        ? x.issueCoordinator?.email === currentUserEmail
                        : false)
            )
        }
        setPage(1)
        sortFilteredResults(results, sortByProperty, descending)
        return results
    }, [
        items,
        wildcard,
        statuses,
        actionCreatorFilter,
        actionOwnerFilter,
        coordinatiorFilter,
        sortByProperty,
        descending,
    ])
    const classes = useStyles()
    const handleOpenActionItem = (item: ActionItemBase) => {
        const { actionItemId: actionPlanId, issueId: id } = item
        history.push(
            generatePath(ISSUE_ACTION_PLAN_ITEM, {
                id,
                actionPlanId,
            })
        )
    }
    const handlePageChange = (page: number) => {
        setPage(page)
        window.scrollTo({ top: 0, behavior: 'auto' })
    }
    switch (true) {
        case loading:
            return (
                <div className={classes.loader}>
                    <CircularProgress color="secondary" size={36} />
                </div>
            )
        case Boolean(error):
            return (
                <NoResults
                    title="No action plan items to display!"
                    subTitle=""
                />
            )
        default:
            return (
                <SearchResultsWithPagination
                    page={page}
                    pageSize={PAGE_SIZE}
                    onPageChange={handlePageChange}
                    isSearching={loading}
                    itemCount={itemsFiltred.length}
                    errorVisible={itemsFiltred.length === 0}
                    errorTitle="No actions plan items to display!"
                    renderAction={() => (
                        <ActionPlanExportSection
                            style={{ marginTop: 0 }}
                            actionPlanItemsQuery={actionPlanItemsQuery}
                            itemsFiltred={itemsFiltred}
                        />
                    )}
                >
                    {itemsFiltred
                        .filter((_, i) => {
                            return (
                                PAGE_SIZE * (page - 1) <= i &&
                                i < PAGE_SIZE * page
                            )
                        })
                        .map((actionPlanItem) => (
                            <ActionPlanItemDashboard
                                key={actionPlanItem.actionItemId}
                                actionPlanItem={actionPlanItem}
                                openActionItem={handleOpenActionItem}
                            />
                        ))}
                    <Space />
                </SearchResultsWithPagination>
            )
    }
}
