import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { SELECTABLE_CHIP } from '../../consts/selectorIds'
import { customize } from '../../utils'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            ...theme.typography.caption,
            fontWeight: theme.typography.fontWeightMedium as number,
            height: theme.spacing(4),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(1.5),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            borderRadius: theme.spacing(2),
            background: theme.palette.grey[100],
            color: theme.palette.grey[600],
            boxSizing: 'border-box',
            transition: theme.transitions.create('transform color', {
                duration: theme.transitions.duration.shorter,
                easing: theme.transitions.easing.easeOut,
            }),
            '&:hover': {
                background: theme.palette.grey[200],
                color: theme.palette.grey[600],
            },
        },
        focusVisible: {
            background: theme.palette.grey[400],
            color: theme.palette.common.white,
        },
        selectedChip: {
            background: theme.palette.grey[400],
            color: theme.palette.common.white,
        },
        disabled: {
            opacity: 0.8,
            '&$selectedChip': {
                opacity: 0.6,
            },
        },
    }),
    { name: 'SelectableChip' }
)

export interface SelectableChipProps extends ButtonBaseProps {
    label: string
    selected: boolean
}

export const SelectableChip = (props: SelectableChipProps) => {
    const classes = useStyles()
    const { label, selected, onClick, ...other } = props
    const { selectedChip, ...newClasses } = classes
    return (
        <ButtonBase
            {...customize(SELECTABLE_CHIP.CHIP_BUTTON, label)}
            className={clsx(classes.root, selected ? selectedChip : null)}
            onClick={onClick}
            classes={newClasses}
            {...other}
        >
            {label}
        </ButtonBase>
    )
}

SelectableChip.defaultProps = {
    selected: false,
}

export default SelectableChip
