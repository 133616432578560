import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Content, EmployeeSelect } from 'components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AbbTheme } from 'styles/createAbbTheme'
import { getEmployee } from '../api/employees'
import { showSnackbar } from '../store/app'
import { Employee } from '../types/issueTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            backgroundColor: theme.palette.common.white,
            marginTop: theme.spacing(4),
        },
        content: {
            position: 'relative',
            paddingRight: theme.spacing(4),
            paddingLeft: theme.spacing(4),
        },
        overrideUserEmail: {
            margin: theme.spacing(4, 0),
        },
        actions: {
            display: 'inline-flex',
            width: '100%',
            justifyContent: 'flex-end',
        },
    }),
    { name: 'OverrideUser' }
)

export const OVERRIDE_USER_HEADER_KEY = 'override-user'

const OverrideUser = (props: any) => {
    const dispatch = useDispatch()
    const classes = useStyles(props)
    const [employee, setEmployee] = useState<Employee>(null)
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        const email = window.sessionStorage.getItem(OVERRIDE_USER_HEADER_KEY)
        if (email) {
            setLoading(true)
            getEmployee(email)
                .then(({ data }) => {
                    setEmployee(data)
                })
                .finally(() => setLoading(false))
        }
    }, [])
    const handleAddOverrideUser = () => {
        if (employee) {
            setError(null)
            window.sessionStorage.setItem(
                OVERRIDE_USER_HEADER_KEY,
                employee.email
            )
            dispatch(showSnackbar('Current user overridden'))
        } else {
            setError('Current user is required')
        }
    }

    const handleRemoveOverrideUser = () => {
        window.sessionStorage.removeItem(OVERRIDE_USER_HEADER_KEY)
        dispatch(showSnackbar('Current user removed'))
        setEmployee(null)
        setError(null)
    }

    return (
        <Content
            className={classes.root}
            loading={loading}
            coverBackground={false}
        >
            <div className={classes.content}>
                <Typography variant="h4">Override current user</Typography>
                <EmployeeSelect
                    employee={employee}
                    onEmployeeChanged={(employee) => {
                        setEmployee(employee)
                        setError(null)
                    }}
                    label="Override user"
                    className={classes.overrideUserEmail}
                    helperText={error}
                    error={Boolean(error)}
                    required
                />

                <div className={classes.actions}>
                    <Button onClick={handleRemoveOverrideUser}>Remove</Button>
                    <Button onClick={handleAddOverrideUser} color="secondary">
                        Override user
                    </Button>
                </div>
            </div>
        </Content>
    )
}

export default OverrideUser
