import { Error } from '@mui/icons-material'
import { CircularProgress, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { FlexGrow, Switch } from 'components'
import { useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { AdminHubStructureBU } from 'types/adminTypes'
import { IssueRoles } from 'types/enums'
import { CcrpRole, UserProfile } from 'types/profile'
import { dataTestId } from 'utils'

const useStyles = makeStyles<AbbTheme>(
    (theme) => ({
        root: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            marginLeft: theme.spacing(2),
        },
        loader: {
            marginRight: theme.spacing(2),
        },
        icon: {
            color: theme.palette.yellow.main,
            marginRight: theme.spacing(1),
        },
        title: {},
    }),
    { name: 'HubStructureItem' }
)

export interface HubStructureItemProps {
    hub: AdminHubStructureBU
    updating: boolean
    profile: UserProfile
    onHubChange: (hub: AdminHubStructureBU, isActive: boolean) => void
}

export const HubStructureItem = (props: HubStructureItemProps) => {
    const {
        hub: { code, name, isActive, divisionCode, isPgHubs },
        profile,
        updating,
        onHubChange,
    } = props
    const classes = useStyles()
    const canEdit = useMemo(() => {
        const roles = profile?.roles.reduce((p, r) => {
            p[r.roleId] = r
            return p
        }, {}) as Record<IssueRoles, CcrpRole>
        if (roles[IssueRoles.Support_Desk]) {
            return true
        }
        const divProcessOwner =
            roles[IssueRoles.Div_Process_Owner]?.scopes?.map(
                (s) => s?.divisionCode
            ) ?? []
        const buProcessOwner =
            roles[IssueRoles.Bu_Process_Owner]?.scopes?.map(
                (s) => s?.businessUnitCode
            ) ?? []
        switch (true) {
            case divProcessOwner.includes(divisionCode):
            case buProcessOwner.includes(code):
                return true
            default:
                return false
        }
    }, [profile, code])
    return (
        <div
            className={classes.root}
            {...dataTestId(`DIV_NAME_${name.toUpperCase()}`)}
        >
            <Typography className={classes.title} variant="body2">
                {`${code} - ${name}`}
            </Typography>
            <FlexGrow />
            {!isActive && (
                <Tooltip title="This DIV does not work in the HUB structure">
                    <Error className={classes.icon} />
                </Tooltip>
            )}

            {updating ? (
                <CircularProgress
                    className={classes.loader}
                    size={24}
                    color="secondary"
                />
            ) : isActive ? (
                <Tooltip
                    title={canEdit ? '' : "You haven't got permission to edit"}
                >
                    <Switch
                        color={canEdit ? 'secondary' : 'primary'}
                        checked={isPgHubs}
                        onChange={(e, checked) =>
                            canEdit && onHubChange({ ...props.hub }, checked)
                        }
                        {...dataTestId(`SWITCH_BUTTON`)}
                    />
                </Tooltip>
            ) : null}
        </div>
    )
}
