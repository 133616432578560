import { Link, LinkOff } from '@mui/icons-material'
import { IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    CompositeDecorator,
    Editor,
    EditorState,
    Modifier,
    RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import {
    DraftHyperLink,
    findLinkEntities,
} from 'Issue/SendMessage/components/RichTextEditor/decorators'
import {
    HtmlHTMLAttributes,
    MouseEventHandler,
    useEffect,
    useRef,
    useState,
} from 'react'
import { useUpdateEffect } from 'react-use'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        label: {
            display: 'flex',
            marginBottom: theme.spacing(1),
            alignItems: 'center',
            color: theme.palette.grey[600],
            fontSize: 10,
            fontWeight: theme.typography.fontWeightBold as number,
        },
        ribbon: {
            display: 'flex',
            flexDirection: 'row',
            height: 42,
            borderBottom: `1px solid ${theme.palette.divider}`,
            marginBottom: theme.spacing(1),
        },
        content: {
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
        },
        editor: {
            padding: theme.spacing(1),
        },
        astrisk: {
            color: theme.palette.red.main,
        },
    }),
    { name: 'TextAreaWithLinks' }
)

export interface TextAreaWithLinksProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    label?: string
    required?: boolean
    initValue?: string
    onValueChange?: (value: string) => void
    maxLength?: number
}

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: DraftHyperLink,
    },
])

export const TextAreaWithLinks = (props: TextAreaWithLinksProps) => {
    const {
        className,
        label,
        initValue,
        onValueChange,
        required = false,
        maxLength = 256,
        ...other
    } = props
    const editorRef = useRef<Editor>()
    const classes = useStyles()
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(decorator)
    )
    const [textLength, setTextLength] = useState(0)
    useEffect(() => {
        if (initValue) {
            setEditorState(
                EditorState.createWithContent(
                    stateFromHTML(initValue),
                    decorator
                )
            )
        } else {
            setEditorState(EditorState.createEmpty(decorator))
        }
    }, [initValue])
    useUpdateEffect(() => {
        const content = editorState.getCurrentContent()
        setTextLength(content?.getPlainText()?.length ?? 0)
    }, [editorState])
    const handleEditorStateChange = (state: EditorState) => {
        setEditorState(state)
    }
    const handleAddLink = () => {
        const url = prompt('Insert link')
        if (url) {
            let displayLink = window.prompt('Link name')
            if (displayLink) {
                const contentState = editorState.getCurrentContent()
                contentState.createEntity('LINK', 'MUTABLE', {
                    url,
                    target: '_blank',
                    rel: 'noreferrer',
                })

                const entityKey = contentState.getLastCreatedEntityKey()

                const newEditorState = editorState.getSelection()

                const textWithEntity = Modifier.insertText(
                    contentState,
                    newEditorState,
                    displayLink,
                    null,
                    entityKey
                )
                let newState = EditorState.createWithContent(
                    textWithEntity,
                    decorator
                )
                setEditorState(newState)
                onValueChange &&
                    onValueChange(stateToHTML(newState.getCurrentContent()))
            }
        }
    }
    const handleRemoveLink: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault()
        const selection = editorState.getSelection()
        if (!selection.isCollapsed()) {
            const nextEditorState = RichUtils.toggleLink(
                editorState,
                selection,
                null
            )
            setEditorState(nextEditorState)
            onValueChange &&
                onValueChange(stateToHTML(nextEditorState.getCurrentContent()))
        }
    }
    const handleBlur = () =>
        onValueChange &&
        onValueChange(stateToHTML(editorState.getCurrentContent()))
    return (
        <div className={clsx(classes.root, className)} {...other}>
            {label && (
                <label className={classes.label}>
                    {label}
                    {required && <span className={classes.astrisk}>*</span>}
                </label>
            )}
            <div className={classes.content}>
                <div className={classes.ribbon}>
                    <IconButton onClick={handleAddLink} size="large">
                        <Link />
                    </IconButton>
                    <IconButton onClick={handleRemoveLink} size="large">
                        <LinkOff />
                    </IconButton>
                </div>
                <div className={classes.editor}>
                    <Editor
                        editorState={editorState}
                        onChange={handleEditorStateChange}
                        onBlur={handleBlur}
                        placeholder="Enter message for user..."
                        ref={editorRef}
                        handleReturn={() => 'handled'}
                    />
                </div>
            </div>
            <Typography variant="caption" color="initial">
                {textLength}/{maxLength}
            </Typography>
        </div>
    )
}
