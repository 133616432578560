import { Button, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'
import { Dialog, Paper } from '../components'
import ieIcon from './images/ie-icon.png'
import modernBrowsersIcon from './images/modern-browsers.png'
import { InternetExplorerAlertProps } from './types'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            margin: theme.spacing(3, 2),
        },
        section: {
            marginBottom: theme.spacing(2),
            textAlign: 'center',
        },
        image: {
            display: 'flex',
            justifyContent: 'center',
            margin: theme.spacing(2),
        },
        actions: {
            display: 'flex',
            marginTop: theme.spacing(2),
            justifyContent: 'center',
        },
        round: {
            height: 32,
            borderRadius: 16,
        },
        browsers: {
            maxWidth: 356,
            width: 'auto',
            '@media (max-width: 380px)': {
                maxWidth: '80vw',
            },
        },
    }),
    { name: 'InternetExplorerAlert' }
)

export const InternetExplorerAlert = (props: InternetExplorerAlertProps) => {
    const { open, onClose } = props
    const classes = useStyles()
    return (
        <Dialog
            open={open}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    onClose()
                }
            }}
        >
            <Paper className={classes.root}>
                <section className={classes.section}>
                    <Typography variant="h6">
                        You are currently using CCRP in Internet Explorer…
                    </Typography>
                </section>
                <section className={classes.image}>
                    <img src={ieIcon} alt="Legacy internet explorer icon" />
                </section>
                <section className={classes.section}>
                    <Typography variant="h6">
                        For better user experience try using other web browsers.
                    </Typography>
                </section>
                <section className={classes.image}>
                    <img
                        className={classes.browsers}
                        src={modernBrowsersIcon}
                        alt="Modern browsers icons"
                    />
                </section>
                <section className={classes.actions}>
                    <Button
                        className={classes.round}
                        color="secondary"
                        variant="contained"
                        onClick={onClose}
                    >
                        Close message
                    </Button>
                </section>
            </Paper>
        </Dialog>
    )
}
