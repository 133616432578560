import ReactDOM from 'react-dom'
import './styles/index.css'
import App from './App'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjsBusinessTime from 'dayjs-business-time'
import { businessTimes } from 'utils/businessTimes'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from 'configs/authProvider'
import {
    EventMessage,
    EventType,
    AuthenticationResult,
} from '@azure/msal-browser'

dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)
dayjs.extend(dayjsBusinessTime)
dayjs.setBusinessTime(businessTimes)

const rootElment = document.getElementById('root')

if (
    !msalInstance.getActiveAccount() &&
    msalInstance?.getAllAccounts()?.length > 0
) {
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
            event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            event.eventType === EventType.SSO_SILENT_SUCCESS) &&
        event.payload
    ) {
        const payload = event.payload as AuthenticationResult
        const account = payload.account
        msalInstance.setActiveAccount(account)
    }
})

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <App />
    </MsalProvider>,
    rootElment
)
