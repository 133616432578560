import { Menu } from '@mui/icons-material'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbLogo, FlexGrow } from 'components'
import { Fragment, MouseEventHandler, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId } from 'utils'
import ContextMenuShell from './ContextMenuShell'
import IssueSearchBar from './IssueSearchBar'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        logo: {
            margin: theme.spacing(0, 2, 0, 1),
        },
    }),
    { name: 'MainAppBar' }
)

export interface MainAppBarProps {
    mobile: boolean
    openMenu: MouseEventHandler<HTMLButtonElement>
    logout: () => void
}

export const MainAppBar = (props: MainAppBarProps) => {
    const { mobile, openMenu, logout } = props
    const [search, setSearch] = useState(false)
    const classes = useStyles()
    return (
        <Fragment>
            <AppBar position="fixed" color="default">
                <Toolbar>
                    {mobile && (
                        <IconButton
                            onClick={openMenu}
                            size="small"
                            {...dataTestId('DASHBOARD_OPEN_MENU')}
                        >
                            <Menu />
                        </IconButton>
                    )}
                    <AbbLogo className={classes.logo} variant="small" />
                    <Typography variant="h6">CCRP</Typography>
                    <FlexGrow />
                    {(!mobile || search) && (
                        <IssueSearchBar
                            mobile={mobile}
                            onClose={() => setSearch(false)}
                        />
                    )}
                    <ContextMenuShell
                        logout={logout}
                        search={mobile}
                        onSearchOpen={() => setSearch(true)}
                    />
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}

export default MainAppBar
