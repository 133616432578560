import {
    AutocompleteRenderInputParams,
    CircularProgress,
    SvgIconProps,
    Theme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { AbbTextFieldProps, TextField } from 'components'
import { ReactNode } from 'react'

export interface AutoCompleteInputProps extends Partial<AbbTextFieldProps> {
    params: AutocompleteRenderInputParams
    loading?: boolean
    renderIcon?: (iconProps: Partial<SvgIconProps>) => ReactNode
}

const useStyles = makeStyles<Theme>(
    (theme) => ({
        icon: {
            marginRight: theme.spacing(0.5),
        },
    }),
    {
        name: 'AutoCompleteInput',
    }
)

export const AutoCompleteInput = (props: AutoCompleteInputProps) => {
    const {
        error,
        params,
        loading,
        helperText,
        renderIcon,
        endAdornment,
        ...other
    } = props
    const classes = useStyles()

    return (
        <TextField
            {...params}
            error={Boolean(error)}
            helperText={helperText}
            InputProps={{
                autoComplete: 'off',
                style: { paddingTop: 0 },
                ref: params.InputProps.ref,
                className: params.InputProps.className,
                endAdornment:
                    endAdornment ?? params?.InputProps?.endAdornment ?? null,
                startAdornment: (
                    <>
                        {loading ? (
                            <CircularProgress
                                className={classes.icon}
                                size={20}
                                color="secondary"
                            />
                        ) : (
                            renderIcon &&
                            renderIcon({
                                className: classes.icon,
                                fontSize: 'small',
                            })
                        )}
                        {params.InputProps.startAdornment}
                    </>
                ),
            }}
            {...other}
        />
    )
}
