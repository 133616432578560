import { ExportMyIssuesToExcelCommand } from '../types'
import { takeLatest, put, call, cancelled } from 'redux-saga/effects'
import { MY_ISSUES_EXPORT_CALL } from '../actionTypes'
import axios, { AxiosResponse } from 'axios'
import { NotificationType, showSnackbar } from 'store/app'

import { exportMyIssuesDone, exportMyIssuesFail } from '../actions'
import { exportMyIssues } from 'api/exports'
import FileSaver from 'file-saver'
import dayjs from 'dayjs'

export function* exportMyIssuesToExcel() {
    yield takeLatest(
        MY_ISSUES_EXPORT_CALL,
        function* (action: ExportMyIssuesToExcelCommand) {
            const cancelSource = axios.CancelToken.source()
            const { query } = action.payload
            const title = 'My issues have been exported'
            try {
                const dateFormated = dayjs().format('YYYY-MM-DD')
                const fileName = `export-my-issues-${dateFormated}.xlsx`
                var res: AxiosResponse<any> = yield call(
                    exportMyIssues,
                    query,
                    cancelSource.token
                )
                FileSaver.saveAs(res.data, fileName)
                yield put(exportMyIssuesDone())
                yield put(showSnackbar(title))
            } catch (error) {
                yield put(exportMyIssuesFail(error))
                yield put(
                    showSnackbar(
                        'Export My Issues error',
                        NotificationType.Error
                    )
                )
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
