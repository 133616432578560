import { IssueStoreState } from 'store/issue'
import { MailRecipient, MessageRecipiantsTags } from 'types/sendMessageTypes'
import { IssueMessageTags } from './RichTextEditor/types'

export const getTagTooltipInfo = (
    tagKey: MessageRecipiantsTags,
    issue: IssueStoreState
): string[] => {
    const getValue = (value: string): string[] => (value ? [value] : [])
    switch (tagKey) {
        case 'IssueOwner':
            return getValue(issue?.captureSection?.issueOwner?.email)
        case 'IssueOwnerTeam':
            return (
                issue?.captureSection?.issueOwnerTeam?.map((e) => e.email) ?? []
            )
        case 'ResolutionOwner':
            return getValue(issue?.assignSection?.primaryResolutionOwner?.email)
        case 'ResolutionOwnerTeam':
            return (
                issue?.assignSection?.resolutionOwnerTeam?.map(
                    (e) => e.email
                ) ?? []
            )
        case 'CustomerContact':
            return getValue(issue?.captureSection?.customerContact?.email)
        case 'Coordinators':
            return (
                issue?.captureSection?.resolvingUnit?.coordinatorsEmails ?? []
            )
        case 'AccountManager':
            return getValue(
                issue?.captureSection?.customer?.accountManagerEmail
            )
        default:
            return []
    }
}

export const getRecipiantsTags = (issue: IssueStoreState): MailRecipient[] =>
    (
        [
            'IssueOwner',
            'IssueOwnerTeam',
            'Coordinators',
            'ResolutionOwner',
            'ResolutionOwnerTeam',
            'AccountManager',
            'CustomerContact',
        ] as MessageRecipiantsTags[]
    ).map((k) => {
        return {
            displayLabel: k,
            identification: {
                geid: null,
                email: null,
            },
            isValid: true,
            tooltipInfo: getTagTooltipInfo(k, issue),
        }
    })

export const getRecipiantsEmails = (recipients: MailRecipient[]): string[] =>
    recipients.reduce((emails, current) => {
        if (current?.identification?.geid) {
            emails.push(current?.identification?.email)
            return emails
        }
        return [...emails, ...current.tooltipInfo]
    }, [])

export const getIssueMessageTagValue = (tag: IssueMessageTags) => {}

export const transformMessageBody = (
    body: string,
    issue: IssueStoreState
): string => {
    return ''
}
