import { createStyles } from '@mui/styles'
import { withStyles, WithStyles } from '@mui/styles'
import flowRight from 'lodash/flowRight'
import { PureComponent } from 'react'
import { Helmet } from 'react-helmet'
import { RouteComponentProps, withRouter } from 'react-router'
import { AbbTheme } from 'styles/createAbbTheme'
import { RoundedButton, ServerDownSvg } from '../components'

const styles = (theme: AbbTheme) =>
    createStyles({
        content: {
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            textAlign: 'center',
            width: 768,
            paddingTop: theme.spacing(4),
            marginRight: 'auto',
            marginLeft: 'auto',
            paddingBottom: 56,
            '@media (max-width: 768px)': {
                width: '100%',
            },
        },
        image: {
            margin: '0 auto',
        },
        error: {
            marginTop: theme.spacing(4),
            fontSize: 24,
            color: theme.palette.grey[800],
            fontWeight: theme.typography.fontWeightBold as number,
        },
        description: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            color: theme.palette.grey[400],
        },
        goBack: {
            margin: '0 auto',
            width: 240,
            marginTop: theme.spacing(4),
        },
    })

interface Error5xxState {
    status: number
    message?: string
}

interface Error5xxProps
    extends WithStyles<typeof styles>,
        RouteComponentProps {}

class Error5xx extends PureComponent<Error5xxProps, Error5xxState> {
    state = {
        status: 500,
        message: '',
    }
    componentDidMount() {
        const location: any = this.props.history?.location
        location &&
            this.setState((prev) => ({
                ...prev,
                ...location.state,
            }))
    }
    render() {
        const { classes, history } = this.props
        const { status, message } = this.state
        const errorTitle = message ? message : 'Server error'
        return (
            <div className={classes.content}>
                {status !== 502 && (
                    <Helmet>
                        <title>Error {status}</title>
                    </Helmet>
                )}
                <ServerDownSvg className={classes.image} />
                {status !== 502 && (
                    <div className={classes.error}>
                        {errorTitle} {status && `(${status})`}
                    </div>
                )}
                <div className={classes.description}>
                    {status !== 502
                        ? 'Try to click go back or feel free to contact us if the problem persists.'
                        : 'There are too many records. Please narrow down search criteria to display results.'}
                </div>
                <RoundedButton
                    className={classes.goBack}
                    onClick={() => history.goBack()}
                    color="secondary"
                    variant="contained"
                >
                    Go back
                </RoundedButton>
            </div>
        )
    }
}

export default flowRight(withRouter, withStyles(styles))(Error5xx)
