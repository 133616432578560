import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { TimelineOrientation, TimelineState } from './types'

export interface TimelineConnectorProps
    extends React.HTMLProps<HTMLSpanElement> {
    orientation?: TimelineOrientation
    thickness?: number
    state?: TimelineState
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            flexGrow: 1,
            width: (props: TimelineConnectorProps) =>
                props.orientation === 'vertical' ? props.thickness : 'auto',
            height: (props: TimelineConnectorProps) =>
                props.orientation === 'horizontal' ? props.thickness : 'auto',
            backgroundColor: theme.palette.grey[300],
            transition: theme.transitions.create(['background-color'], {
                duration: 400,
            }),
            '&$inprocess': {
                backgroundColor: theme.palette.blue.main,
            },
            '&$done': {
                backgroundColor: theme.palette.blue.main,
            },
        },
        ['waiting' as TimelineState]: {},
        ['inprocess' as TimelineState]: {},
        ['done' as TimelineState]: {},
    }),
    { name: 'TimelineConnector' }
)
export const TimelineConnector = (props: TimelineConnectorProps) => {
    const {
        className: classNameProps,
        orientation = 'horizontal',
        state = 'waiting',
        thickness = 4,
        ...other
    } = props
    const classes = useStyles({ ...props, orientation, thickness })
    return (
        <span
            className={clsx(classes.root, classNameProps, classes[state])}
            {...other}
        />
    )
}

export default TimelineConnector
