import { Domain } from '@mui/icons-material'
import {
    AutocompleteChangeReason,
    BaseTextFieldProps,
    useTheme,
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { TextField } from 'components'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useUpdateEffect } from 'react-use'
import {
    BusinessUnitFilterBase,
    DivisionFilterBase,
    FilterBase,
} from 'types/models'
import { RootState } from '../../store/types'
import { getOptionLabel } from './utils'

export interface BusinessUnitSelectProps extends Partial<BaseTextFieldProps> {
    division?: DivisionFilterBase
    selectedBusinessUnit: BusinessUnitFilterBase
    onBusinessUnitChanged: (item: BusinessUnitFilterBase) => void
    displayAll?: boolean
    displayInactive?: boolean
}

export const BusinessUnitSelect = (props: BusinessUnitSelectProps) => {
    const {
        label = 'Division',
        disabled,
        selectedBusinessUnit,
        division,
        onBusinessUnitChanged,
        error,
        helperText,
        displayInactive = false,
        ...other
    } = props

    const theme = useTheme()
    const handleSelectFilter = (item: FilterBase) => onBusinessUnitChanged(item)

    const allOptions = useSelector(
        (state: RootState) => state.resources.businessUnits
    )
    const options = useMemo(() => {
        const filterByDivision = division && Object.keys(division).length !== 0
        return allOptions
            .filter((x) => (displayInactive ? true : x.isActive))
            .filter((b) =>
                filterByDivision ? division.code === b.divisionCode : true
            )
    }, [allOptions, division, displayInactive])
    useUpdateEffect(() => {
        if (
            !division ||
            division?.code !== selectedBusinessUnit?.divisionCode
        ) {
            handleSelectFilter(null)
        }
    }, [division])
    const getOptionSelected = (
        option: FilterBase,
        value: FilterBase
    ): boolean => {
        return option?.code === value?.code
    }

    return (
        <Autocomplete
            disabled={disabled}
            value={selectedBusinessUnit ?? null}
            autoSelect={true}
            options={options}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={getOptionSelected}
            noOptionsText="No match Divisions"
            loadingText="Loading Divisions…"
            disableClearable={false}
            onChange={(e, newValue, reason: AutocompleteChangeReason) => {
                const selectedValue = newValue as FilterBase
                switch (reason) {
                    case 'clear':
                        handleSelectFilter(null)
                        break
                    default:
                        handleSelectFilter({ ...selectedValue })
                }
            }}
            renderOption={(props, option) => (
                <li {...props}>{getOptionLabel(option)}</li>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    label={label}
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        style: { paddingTop: 0 },
                        startAdornment: (
                            <Domain
                                fontSize="small"
                                style={{
                                    marginRight: theme.spacing(0.5),
                                }}
                            />
                        ),
                    }}
                    {...other}
                />
            )}
        />
    )
}

export default BusinessUnitSelect
