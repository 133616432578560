import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { SVGProps } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            userSelect: 'none',
            display: 'block',
            fill: 'currentcolor',
            flexShrink: 0,
            transition: theme.transitions.create('fill', {
                duration: theme.transitions.duration.shorter,
            }),
        },
    }),
    { name: 'SvgImage' }
)

export interface StaticSvgProps extends SVGProps<SVGSVGElement> {
    [string: string]: any
}

export const StaticSvg = (props: StaticSvgProps) => {
    const {
        classList,
        style,
        width = 256,
        height = 256,
        viewBox = '0 0 24 24',
        children,
        ...other
    } = props
    const classes = useStyles(props)
    return (
        <svg
            style={{ width, height, ...style }}
            viewBox={viewBox}
            focusable="false"
            className={clsx(classes.root, classList)}
            {...other}
        >
            {children}
        </svg>
    )
}

export default StaticSvg
