import { makeStyles } from '@mui/styles'
import {
    Button,
    CountabledChip,
    SearchPanel,
    SearchPanelProps,
    ValueViewNext,
} from 'components'
import SortPanel from 'components/Base/SortPanel'
import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { ActionItemStatus } from 'types/actionPlanTypes'
import { dataTestId } from 'utils'
import { ActionPlanItemsQuery, ActionPlanItemsStats } from '.'
import { getInitActionPlanItemsQuery, getOrderProperties } from './utils'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        chips: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        sortBy: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            flexGrow: 1,
            minWidth: 200,
            minHeight: 42,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(0.5),
            marginRight: theme.spacing(0.5),
        },
        sortIcon: {
            color: 'rgba(0, 0, 0, 0.54)',
            transition: 'transform .1s',
            '&$asscending': {
                transform: 'rotateZ(90deg)',
            },
            '&$descending': {
                transform: 'rotateZ(-90deg)',
            },
        },
        asscending: {},
        descending: {},
    }),
    { name: 'SearchSection' }
)

export interface SearchSectionProps extends SearchPanelProps {
    actionPlanItemsQuery: ActionPlanItemsQuery
    onFiltersChanged: (filters: ActionPlanItemsQuery) => void
    stats: ActionPlanItemsStats
    disabled?: boolean
}

export const SearchSection = (props: SearchSectionProps) => {
    const {
        className,
        disabled,
        actionPlanItemsQuery,
        stats,
        onFiltersChanged,
        ...other
    } = props
    const classes = useStyles()
    const [searchText, setSearchText] = useState('')
    const [descending, setDescending] = useState(null)
    const [sortKey, setSortKey] = useState(null)

    useEffect(() => {
        setSearchText(actionPlanItemsQuery?.wildcard ?? '')
    }, [actionPlanItemsQuery])

    useEffect(
        () =>
            onFiltersChanged({
                ...(actionPlanItemsQuery ?? getInitActionPlanItemsQuery()),
                descending: descending,
                sortByProperty: sortKey,
            }),
        [sortKey, descending]
    )

    const applyWildcard = useCallback(
        debounce(
            (wildcard) =>
                onFiltersChanged({
                    ...(actionPlanItemsQuery ?? getInitActionPlanItemsQuery()),
                    wildcard,
                }),
            800
        ),
        [actionPlanItemsQuery]
    )
    const createStatusChangeHandler =
        (status: ActionItemStatus) => (state: boolean) => {
            const newQuery = { ...actionPlanItemsQuery }
            const statuses = new Set<ActionItemStatus>(newQuery.statuses)
            if (state) {
                statuses.add(status)
            } else {
                statuses.delete(status)
            }
            newQuery.statuses = [...statuses]
            onFiltersChanged(newQuery)
        }

    return (
        <SearchPanel
            className={className}
            placeholder="Search in visible actions..."
            searchText={searchText}
            onChange={(text) => {
                setSearchText(text)
                applyWildcard(text)
            }}
            renderEndAdornment={() => (
                <SortPanel
                    sortKeys={getOrderProperties()}
                    selectedSortKey={'actionName'}
                    selectedDescending={false}
                    onDescendingChange={setDescending}
                    onSortKeyChange={setSortKey}
                />
            )}
            renderActions={() => (
                <>
                    <Button
                        onClick={() =>
                            onFiltersChanged({
                                wildcard: '',
                                descending: false,
                                sortByProperty: 'actionName',
                                actionCreatorFilter: false,
                                actionOwnerFilter: false,
                                coordinatiorFilter: false,
                                statuses: [],
                            })
                        }
                        {...dataTestId('CLEAR_BUTTON')}
                    >
                        Clear
                    </Button>
                    <Button
                        onClick={() =>
                            onFiltersChanged(getInitActionPlanItemsQuery())
                        }
                        {...dataTestId('SET_TO_DEFAULT_BUTTON')}
                    >
                        Set to default
                    </Button>
                </>
            )}
            onClearText={() =>
                onFiltersChanged({
                    ...actionPlanItemsQuery,
                    wildcard: '',
                })
            }
            {...other}
        >
            <ValueViewNext label="Statuses" fullWidth={true}>
                <div className={classes.chips}>
                    <CountabledChip
                        label="Draft"
                        {...dataTestId('ACTION_FILTER_DRAFT')}
                        selected={actionPlanItemsQuery.statuses.includes(
                            ActionItemStatus.ToDo
                        )}
                        onSelectChanged={createStatusChangeHandler(
                            ActionItemStatus.ToDo
                        )}
                        count={stats.draft}
                    />
                    <CountabledChip
                        label="In progress"
                        {...dataTestId('ACTION_FILTER_IN_PROGRESS')}
                        selected={actionPlanItemsQuery.statuses.includes(
                            ActionItemStatus.InProgress
                        )}
                        onSelectChanged={createStatusChangeHandler(
                            ActionItemStatus.InProgress
                        )}
                        count={stats.inprogress}
                    />
                    <CountabledChip
                        label="In progress overdue"
                        {...dataTestId('ACTION_FILTER_IN_PROGRESS_OVERDUE')}
                        selected={actionPlanItemsQuery.statuses.includes(
                            ActionItemStatus.InProgressOverdue
                        )}
                        onSelectChanged={createStatusChangeHandler(
                            ActionItemStatus.InProgressOverdue
                        )}
                        count={stats.inprogressOverdue}
                    />
                    <CountabledChip
                        label="Closed"
                        {...dataTestId('ACTION_FILTER_CLOSED')}
                        selected={actionPlanItemsQuery.statuses.includes(
                            ActionItemStatus.Complated
                        )}
                        onSelectChanged={createStatusChangeHandler(
                            ActionItemStatus.Complated
                        )}
                        count={stats.closed}
                        tooltip={{
                            filter: 'Closed',
                            text: 'From the last 3 months',
                        }}
                    />
                    <CountabledChip
                        label="Deleted"
                        {...dataTestId('ACTION_FILTER_DELETED')}
                        selected={actionPlanItemsQuery.statuses.includes(
                            ActionItemStatus.Deleted
                        )}
                        onSelectChanged={createStatusChangeHandler(
                            ActionItemStatus.Deleted
                        )}
                        count={stats.deleted}
                    />
                </div>
            </ValueViewNext>
            <ValueViewNext label="Roles" fullWidth={true}>
                <div className={classes.chips}>
                    <CountabledChip
                        label="Action creator"
                        {...dataTestId('ACTION_FILTER_ACTION_CREATOR')}
                        selected={actionPlanItemsQuery.actionCreatorFilter}
                        onSelectChanged={(state) =>
                            onFiltersChanged({
                                ...actionPlanItemsQuery,
                                actionCreatorFilter: state,
                            })
                        }
                        count={stats.actionCreator}
                    />
                    <CountabledChip
                        label="Action owner"
                        {...dataTestId('ACTION_FILTER_ACTION_OWNER')}
                        selected={actionPlanItemsQuery.actionOwnerFilter}
                        onSelectChanged={(state) =>
                            onFiltersChanged({
                                ...actionPlanItemsQuery,
                                actionOwnerFilter: state,
                            })
                        }
                        count={stats.actionOwner}
                    />
                    <CountabledChip
                        label="Coordinatior"
                        {...dataTestId('ACTION_FILTER_COORDINATOR')}
                        selected={actionPlanItemsQuery.coordinatiorFilter}
                        onSelectChanged={(state) =>
                            onFiltersChanged({
                                ...actionPlanItemsQuery,
                                coordinatiorFilter: state,
                            })
                        }
                        count={stats.issueCoordinator}
                    />
                </div>
            </ValueViewNext>
        </SearchPanel>
    )
}
