import { useEffect, useState } from 'react'
import { Content, PageTitle, SearchPanel } from 'components'
import { GlobalSearchResults } from '.'
import { searchCognitiveIssues } from 'api'
import { IssueGlobalSearchResult } from '../../types/models'
import { useDispatch } from 'react-redux'
import { showSnackbar } from '../../store/app'

const pageTitle = 'Global search'
const pageSize = 30

export const GlobalSearchPage = () => {
    const dispatch = useDispatch()
    const [searching, setSearching] = useState(false)
    const [searchingDesc, setSearchingDesc] = useState('')
    const [error, setError] = useState(null)
    const [searchText, setSearchText] = useState('')
    const [data, setData] = useState<IssueGlobalSearchResult>(null)
    const [page, setPage] = useState(1)
    const searchIssues = async () => {
        if (searchText.length <= 3) {
            dispatch(
                showSnackbar(
                    'The search phrase must be a string with a minimum length of 4.',
                    true
                )
            )
            return
        }
        try {
            setSearching(true)
            setSearchingDesc('Searching issues...')
            const { data } = await searchCognitiveIssues(
                searchText,
                page,
                pageSize
            )
            setPage(page)
            setData({ ...data })
        } catch (error) {
            setError(error)
        } finally {
            setSearching(false)
            setSearchingDesc('')
        }
    }
    useEffect(() => {
        if (searchText) {
            searchIssues()
            window.scrollTo({ top: 0, behavior: 'auto' })
        }
    }, [page])

    return (
        <Content
            variant="medium"
            loading={searching}
            loadingDesc={searchingDesc}
            title={pageTitle}
            error={error}
            onRetry={() => setError(null)}
        >
            <PageTitle
                title={pageTitle}
                desc="Global search results are refreshed every 6 hours"
            />
            <SearchPanel
                searchText={searchText}
                onChange={setSearchText}
                onEnter={searchIssues}
                onClearText={() => setSearchText('')}
            />

            <GlobalSearchResults
                page={page}
                pageSize={pageSize}
                searchText={searchText}
                items={data?.items}
                totalSize={data?.total ?? 0}
                searching={searching}
                onPageChange={setPage}
            />
        </Content>
    )
}
