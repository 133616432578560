import Axios from 'axios'
import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects'
import { DASHBOARD_LOAD_AVATAR_DONE, DASHBOARD_LOAD_AVATAR_CALL } from '..'
import { FAKE_AZURE_AD } from 'features'
import { RootState } from 'store/types'
import { DashboardStoreState } from '../initState'
import { getAccessToken, msalInstance } from 'configs/authProvider'

class UnauthorizedError extends Error {
    response = { status: 401 }
}

export const graphAxios = Axios.create({
    baseURL: 'https://graph.microsoft.com/v1.0',
})
graphAxios.interceptors.request.use(async function (config) {
    const tokenId = await getAccessToken(msalInstance, [
        'https://graph.microsoft.com/.default',
    ])
    let token = tokenId
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    } else {
        throw new UnauthorizedError()
    }
    config.timeout = Number(process.env.REACT_APP_TIMEOUT)
    return config
})

export function* loadAvatar() {
    yield takeLatest(DASHBOARD_LOAD_AVATAR_CALL, function* () {
        const cancelSource = Axios.CancelToken.source()
        try {
            const dashboard: DashboardStoreState = yield select(
                (s: RootState) => s.dashboard
            )
            if (dashboard.avatar || FAKE_AZURE_AD) {
                return
            }
            const res = yield call(
                graphAxios.get,
                '/me/photos/240x240/$value',
                {
                    headers: {
                        'Content-Type': 'image/jpg',
                    },
                    responseType: 'blob',
                }
            )
            yield put({
                type: DASHBOARD_LOAD_AVATAR_DONE,
                payload: window.URL.createObjectURL(res.data),
            })
        } catch (error) {
            console.log(error)
        } finally {
            if (yield cancelled()) {
                yield call(cancelSource.cancel, 'Abort')
            }
        }
    })
}
