import { Button } from '@mui/material'
import { reopenIssue } from 'api'
import { NavButton, ReturnToResolutionOwnerDialog } from 'components'
import { DASHBOARD_PAGE } from 'consts'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ReturnToIssueOwnerQuery } from 'types/actions'
import { TileActionsProps } from '..'

export const EffectivnessCheckActions = (props: TileActionsProps) => {
    const { myActionMeta, issueNumber } = props
    const [returnDialogOpen, setReturnDialogOpen] = useState(false)
    const dispatch = useDispatch()
    const handleReturnToRO = (formData: ReturnToIssueOwnerQuery) => {
        setReturnDialogOpen(false)
        dispatch(reopenIssue(formData))
    }
    return (
        <>
            <Button
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.REOPEN_ISSUE}
                onClick={() => {
                    setReturnDialogOpen(true)
                }}
                eventName="Reopen issue on effectivness tile"
            >
                Reopen issue
            </Button>
            <NavButton
                {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS
                    .CHECK_EFFECTIVENESS}
                color="secondary"
                linkProps={{ to: `/issue/${myActionMeta.issueId}` }}
                eventName="Check effectiveness on tile"
            >
                Check effectiveness
            </NavButton>
            <ReturnToResolutionOwnerDialog
                open={returnDialogOpen}
                message={`You are about to reopen issue ${issueNumber}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                minHeight={220}
                rowVersion={myActionMeta.rowVersion}
                number={issueNumber}
                issueId={myActionMeta.issueId}
                onReturnToRO={(formData) => handleReturnToRO(formData)}
                onClose={() => setReturnDialogOpen(false)}
            />
        </>
    )
}
