import { useState } from 'react'
import {
    returnToIssueOwner,
    saveAssignSection,
    assignIssue,
    updateAssignSection,
    removeRca,
    IssueStoreState,
    setExpandMode,
    updateQuickFixVariant,
} from 'store/issue/index'
import { useDispatch, useSelector } from 'react-redux'
import Assignment from './Assignment'
import * as ROUTES from 'routes'
import { urlBuilder } from 'utils'
import { RootState } from 'store/types'
import { ReturnToIssueOwnerQuery } from 'types/actions'
import { appInsights } from 'configs/appInsights'
import { IssueContext } from 'types/issueTypes'
import { IssueRoles, IssueActionsTypes, IssueTypes } from 'types/enums'
import { useHistory, useParams } from 'react-router'
import { IssueParams } from 'types/urlParams'
import { DialogBase, ReturnToIssueOwnerDialog } from 'components'

export const canAssignOrReturnIssue = (context: IssueContext): boolean => {
    if (context) {
        if (
            0 <=
            context.myRoles.findIndex(
                (roleCode: IssueRoles) =>
                    roleCode === IssueRoles.Issue_Coordinator
            )
        ) {
            if (
                context.actions.find(
                    (action) =>
                        action.actionCode === IssueActionsTypes.To_Assign
                )
            ) {
                return true
            }
        } else {
            return false
        }
    }
    return false
}

const AssignmentContainer = (props: any) => {
    const dispatch = useDispatch()

    const history = useHistory()
    const params = useParams<IssueParams>()
    const [openReturnIssue, setOpenReturnIssue] = useState<boolean>(false)
    const [openRemoveRca, setOpenRemoveRca] = useState<boolean>(false)
    const issue = useSelector<RootState, IssueStoreState>(
        (state) => state.issue
    )
    const { captureSection, assignSection, expanded } = issue
    const handleLinkIssue = () => {
        appInsights.trackEvent({ name: 'Link issue' })
        const id = params.id
        id && history.push(urlBuilder(ROUTES.ISSUE_LINK_ISSUE, { id }))
    }
    const handleReturnToIssue = (query: ReturnToIssueOwnerQuery) => {
        appInsights.trackEvent({ name: 'Return to Issue Owner' })
        setOpenReturnIssue(false)
        dispatch(returnToIssueOwner(query))
    }
    const handleRemoveRca = () => {
        setOpenRemoveRca(false)
        dispatch(removeRca())
    }
    const handleSaveAssignSection = () => dispatch(saveAssignSection())
    return assignSection ? (
        <>
            <Assignment
                issueType={captureSection?.issueType || IssueTypes.External}
                expanded={Boolean(expanded?.assign)}
                isRootCauseRequiredByCustomer={
                    captureSection?.isRootCauseRequiredByCustomer
                }
                isHighImpact={captureSection?.highImpact?.isHighImpact}
                assignSection={assignSection}
                onLinkIssueClick={handleLinkIssue}
                onExpand={(expanded) =>
                    dispatch(setExpandMode('assign', expanded))
                }
                onRemoveLink={() => setOpenRemoveRca(true)}
                onAssignSectionChanged={(key, value) => {
                    dispatch(updateAssignSection(key, value))
                }}
                onSaveClick={handleSaveAssignSection}
                onReturnToIssueOwner={() => setOpenReturnIssue(true)}
                onQuickFixVariantChanged={(quickFixVariant) =>
                    dispatch(updateQuickFixVariant(quickFixVariant))
                }
                onAssign={() => {
                    appInsights.trackEvent({ name: 'Assign issue' })
                    dispatch(assignIssue())
                }}
            />
            <ReturnToIssueOwnerDialog
                open={openReturnIssue}
                issueId={issue.issueId}
                rowVersion={assignSection.rowVersion}
                number={issue?.issueNumber}
                onClose={() => setOpenReturnIssue(false)}
                onReturnIssue={handleReturnToIssue}
            />
            <DialogBase
                open={openRemoveRca}
                title="Remove Rca"
                message="Are you sure that you want remove linked issue RCA and save assign section?"
                confirmLabel="Yes"
                closeLabel="No"
                onClose={() => setOpenRemoveRca(false)}
                onConfirm={() => handleRemoveRca()}
            />
        </>
    ) : null
}

export default AssignmentContainer
