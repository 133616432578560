import { Add, Delete, EditOutlined, ExpandMore } from '@mui/icons-material'
import { CircularProgress, Collapse, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AdminNode, NodeBase, TreeContext } from 'components'
import { FC, HtmlHTMLAttributes, useContext, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId } from 'utils'
import { AdminTreeNodes } from './AdminTreeNodes'

const useCcrpTreeNode = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            fontWeight: theme.typography.fontWeightBold as number as number,
            ...theme.typography.body2,
        },
        header: {
            display: 'inline-flex',
            height: 54,
            alignItems: 'center',
            borderBottom: `1px solid ${theme.palette.divider}`,
            textTransform: 'uppercase',
        },
        labelName: {
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
        selectIcon: {
            color: 'red',
            marginRight: theme.spacing(1),
        },
        expandMoreButton: {
            marginRight: theme.spacing(0.5),
        },
        expandMoreIcon: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            '&$expanded': {
                transform: 'rotate(180deg)',
            },
        },
        nodeLoading: {
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
        noChildren: {
            paddingLeft: theme.spacing(3),
        },
        expanded: {},
        isNotActive: {
            color: theme.palette.grey[400],
            fontWeight: theme.typography.fontWeightRegular as number as number,
        },
        actions: {
            marginRight: theme.spacing(1),
            minWidth: 148,
            textAlign: 'right',
        },
    }),
    { name: 'AdminTreeNode' }
)

export interface AdminTreeBaseProps extends HtmlHTMLAttributes<HTMLDivElement> {
    node: AdminNode
    disabled?: boolean
    canEdit?: boolean
    canEditAllNodes?: boolean
}

export const AdminTreeNodeBase: FC<AdminTreeBaseProps> = (props) => {
    const {
        node,
        disabled = false,
        canEdit: canEditProp = false,
        canEditAllNodes = false,
        children,
        ...other
    } = props
    const classes = useCcrpTreeNode(props)
    const {
        expandedNodes,
        loadingNodes,
        onAddNode,
        onDeleteNode,
        onEditNode,
        onExpandNode,
    } = useContext(TreeContext)
    const expanded = expandedNodes.some((id) => id === node.id)
    const loading = loadingNodes.some((id) => id === node.id)
    const handleAddNode = () => onAddNode && onAddNode({ ...node })
    const handleDeleteNode = () => onDeleteNode && onDeleteNode({ ...node })
    const handleEditNode = () => onEditNode && onEditNode({ ...node })
    return <>
        <div
            className={clsx(
                classes.header,
                !node.isActive && classes.isNotActive
            )}
            {...other}
        >
            {node.hasChildren ? (
                loading ? (
                    <CircularProgress
                        className={classes.nodeLoading}
                        size={24}
                        color="secondary"
                    />
                ) : (
                    <IconButton
                        className={classes.expandMoreButton}
                        onClick={(e) => {
                            e.stopPropagation()
                            onExpandNode(node, expanded)
                        }}
                        {...dataTestId(`EXPAND_CATEGORY`)}
                        size="large">
                        <ExpandMore
                            className={clsx(
                                classes.expandMoreIcon,
                                expanded && classes.expanded
                            )}
                        />
                    </IconButton>
                )
            ) : null}
            <span
                className={clsx(
                    classes.labelName,
                    !node.hasChildren && classes.noChildren
                )}
            >
                {node.name}
            </span>
            <span style={{ flexGrow: 1 }} />
            {!disabled && canEditProp && (
                <div className={classes.actions}>
                    {node.isActive && node.isChildAllowed && (
                        <IconButton
                            onClick={handleAddNode}
                            {...dataTestId(`ADD_CATEGORY_BUTTON `)}
                            size="large">
                            <Add fontSize="small" />
                        </IconButton>
                    )}
                    {((canEditAllNodes && canEditProp) || node.canEdit) && (
                        <>
                            <IconButton
                                onClick={handleEditNode}
                                {...dataTestId(`EDIT_CATEGORY_BUTTON `)}
                                size="large">
                                <EditOutlined fontSize="small" />
                            </IconButton>
                            {node.isActive && (
                                <IconButton
                                    onClick={handleDeleteNode}
                                    {...dataTestId(
                                        `DELETE_CATEGORY_BUTTON `
                                    )}
                                    size="large">
                                    <Delete fontSize="small" />
                                </IconButton>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
        {node.hasChildren && children && (
            <Collapse in={expanded}>{children}</Collapse>
        )}
    </>;
}

export interface ElmentTreeNodeProps
    extends NodeBase<AdminNode>,
        React.HtmlHTMLAttributes<HTMLLIElement> {
    disabled?: boolean
    canEdit?: boolean
    canEditAllNodes?: boolean
}

export const AdminTreeNode = (props: ElmentTreeNodeProps) => {
    const {
        node,
        disabled = false,
        canEdit = false,
        level,
        canEditAllNodes,
        ...other
    } = props
    const classes = useCcrpTreeNode(props)
    const paddingLeft = useMemo(() => {
        return (level - 1) * 16
    }, [level])
    return (
        <li className={classes.root} {...other}>
            <AdminTreeNodeBase
                disabled={disabled}
                style={{ paddingLeft }}
                canEdit={canEdit}
                node={node}
                canEditAllNodes={canEditAllNodes}
            >
                {node.hasChildren && Array.isArray(node?.nodes) && (
                    <AdminTreeNodes
                        disabled={disabled}
                        nodes={node.nodes}
                        level={level + 1}
                        canEdit={canEdit}
                        canEditAllNodes={canEditAllNodes}
                    />
                )}
            </AdminTreeNodeBase>
        </li>
    )
}
