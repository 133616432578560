import MuiAccordionDetails, {
    AccordionDetailsProps,
} from '@mui/material/AccordionDetails'
import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingBottom: theme.spacing(1),
        },
    }),
    { name: 'AccordionDetails' }
)

export const AccordionDetails = (props: AccordionDetailsProps) => {
    const classes = useStyles()
    return <MuiAccordionDetails {...props} classes={classes} />
}
AccordionDetails.muiName = 'AccordionDetails'

export default AccordionDetails
