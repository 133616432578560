import axios, { AxiosResponse } from 'axios'
import { OVERRIDE_USER_HEADER_KEY } from 'pages/OverrideUser'
import { ApiError } from 'types/errors'
import { getTokenId, loginRequest, msalInstance } from './authProvider'

class UnauthorizedError extends Error {
    response = { status: 401 }
}

export default function setupAxios(history: any) {
    axios.defaults.baseURL = process.env.REACT_APP_BASEURL
    axios.interceptors.request.use(async function (config) {
        let token = await getTokenId(msalInstance, loginRequest.scopes)
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
            const overrideUserEmail = window.sessionStorage.getItem(
                OVERRIDE_USER_HEADER_KEY
            )
            if (overrideUserEmail) {
                config.headers[OVERRIDE_USER_HEADER_KEY] = overrideUserEmail
            }
        } else {
            throw new UnauthorizedError()
        }
        config.timeout = Number(process.env.REACT_APP_TIMEOUT)
        return config
    })
    axios.interceptors.response.use(
        function (response) {
            return response
        },
        function (error) {
            const response: AxiosResponse<any> = error?.response ?? null
            let status = response ? response.status : 0
            const data = response && response?.data
            if (error?.code === 'ECONNABORTED') {
                status = 408 //Timeout
            }
            let mappedError: ApiError = {
                status,
            }
            if (typeof data === 'string' || data instanceof String) {
                mappedError = {
                    ...mappedError,
                    error: data,
                }
            } else {
                mappedError = { ...mappedError, ...(data ?? {}) }
            }
            switch (true) {
                case status === 418:
                    //Use global window object because React Context doesn't work
                    ;(window as any)?.setGlocalNotification(
                        error?.response?.data
                    )
                    break
                case status === 302:
                    if (data.error.type === 'NotAllowedDivisionAccessException')
                        window.location.replace(data.error.message)
                    else history.push(`/302`, { status, message: data })
                    return Promise.reject(mappedError)
                //CCRP erros response mapping
                case status === 401:
                    return Promise.reject(mappedError)
                default:
                    return Promise.reject(mappedError)
            }
        }
    )
}
