import { makeStyles } from '@mui/styles'
import { HtmlHTMLAttributes } from 'react'
import TreeNode from './TreeNode'
import { GenericNode } from './types'

const useCcrpTreeNodes = makeStyles(
    () => ({
        root: {
            listStyleType: 'none',
            'padding-inline-start': 0,
            margin: 0,
        },
    }),
    { name: 'TreeNodes' }
)

export interface RootTreeNodesProps
    extends HtmlHTMLAttributes<HTMLUListElement> {
    nodes: Array<GenericNode>
    level: number
}

export const TreeNodes = (props: RootTreeNodesProps) => {
    const { nodes, level, ...other } = props
    const classes = useCcrpTreeNodes(props)
    return (
        <ul className={classes.root} {...other}>
            {nodes &&
                nodes.map((node: GenericNode) => (
                    <TreeNode key={node.id} node={node} level={level + 1} />
                ))}
        </ul>
    )
}

export default TreeNodes
