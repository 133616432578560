import axios, { AxiosResponse } from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { ISSUE_LOAD_CONTEXT_CALL, loadIssueContextDone } from '..'
import { getContext } from 'api'
import { IssueContext } from 'types/issueTypes'
import { LoadIssueContextCommand } from '../types'

export function* loadContextAction(action: LoadIssueContextCommand) {
    const cancelSource = axios.CancelToken.source()
    const issueId = action?.payload?.issueId
    try {
        const { data: context } = (yield call(
            getContext,
            issueId,
            cancelSource.token
        )) as AxiosResponse<IssueContext>
        yield put(loadIssueContextDone(context))
    } catch (error) {
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* loadContextSaga() {
    yield takeLatest([ISSUE_LOAD_CONTEXT_CALL], loadContextAction)
}
