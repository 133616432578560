import { Dayjs } from 'dayjs'

export const getStartOfQuarter = (date: Dayjs): Dayjs => {
    let newDate = date.clone()
    const month = newDate.month()
    switch (month) {
        case 0:
        case 1:
        case 2:
            newDate = newDate.month(0)
            break
        case 3:
        case 4:
        case 5:
            newDate = newDate.month(3)
            break
        case 6:
        case 7:
        case 8:
            newDate = newDate.month(6)
            break
        case 9:
        case 10:
        case 11:
            newDate = newDate.month(9)
            break
    }
    return newDate.startOf('month')
}
