import ArrowBack from '@mui/icons-material/ArrowBack'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { appInsights } from '../../configs/appInsights'
import { PICKER_TOP_BAR } from '../../consts/selectorIds'
import { customize } from '../../utils'
import { useIssueAppBarStyle } from './IssueAppBar.style'

export interface PickerAppBarProps {
    title?: string
    caption?: string
    eventName?: string
    onConfirm?: () => void
    disabledConfirm?: boolean
}

export const PickerAppBar = (props: PickerAppBarProps) => {
    const { title, caption, eventName, onConfirm, disabledConfirm, ...other } =
        props
    const classes = useIssueAppBarStyle(props)
    const history = useHistory()

    const handleOnConfirm = () => {
        eventName && appInsights.trackEvent({ name: eventName })
        onConfirm && onConfirm()
    }
    return (
        <Fragment>
            <AppBar position="fixed" color="default" {...other}>
                <Toolbar {...customize(PICKER_TOP_BAR.CONTAINER, title)}>
                    <IconButton
                        {...PICKER_TOP_BAR.ACTION_BUTTONS.BACK}
                        edge="start"
                        color="inherit"
                        onClick={() => history.goBack()}
                        size="large">
                        <ArrowBack />
                    </IconButton>
                    <div className={classes.title} {...PICKER_TOP_BAR.TITLE}>
                        <Typography
                            className={classes.ellipsis}
                            variant={caption ? 'subtitle2' : 'subtitle1'}
                        >
                            {title}
                        </Typography>
                        {Boolean(caption) && (
                            <Typography
                                className={classes.ellipsis}
                                variant="caption"
                            >
                                {caption}
                            </Typography>
                        )}
                    </div>
                    {onConfirm && (
                        <Button
                            {...PICKER_TOP_BAR.ACTION_BUTTONS.CONFIRM}
                            color="secondary"
                            disabled={disabledConfirm}
                            onClick={handleOnConfirm}
                        >
                            Confirm
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
            <Toolbar />
        </Fragment>
    );
}

PickerAppBar.defaultProps = {
    disabledConfirm: false,
}
export default PickerAppBar
