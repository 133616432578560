import { Typography } from '@mui/material'
import { AbbTypography, RoundedButton } from 'components'
import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SurveyTranslationContext, SurveyTranslationContextProps } from '..'
import { useStyles } from '../Survey.style'

export interface PreviouslyUnsubscribedSurveyProps {
    onSubscribe: () => void
}

export const PreviouslyUnsubscribedSurvey = ({
    onSubscribe,
}: PreviouslyUnsubscribedSurveyProps) => {
    const classes = useStyles()
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    return (
        <>
            <Helmet>
                <title>
                    {t(
                        'Survey_AlreadyUnsubscribed',
                        'You have been previously unsubscribed from taking this closure confirmation.'
                    )}
                </title>
            </Helmet>
            <AbbTypography variant="h2">
                {t(
                    'Survey_AlreadyUnsubscribed',
                    'You have been previously unsubscribed from taking this closure confirmation.'
                )}
            </AbbTypography>
            <Typography
                className={classes.description}
                variant="caption"
                component="p"
            >
                {t(
                    'Survey_AlreadyUnsubscribed_Resubscribe',
                    'If you wish to subscribe and participate in a closure confirmation, click the button below.'
                )}
            </Typography>
            <RoundedButton
                onClick={onSubscribe}
                color="secondary"
                variant="contained"
            >
                {t('Survey_SubscribeButton', 'Subscribe')}
            </RoundedButton>
        </>
    )
}
