import axios, { AxiosResponse, CancelToken } from 'axios'
import { Attachment } from 'types/issueTypes'
import { AttachmentTypes } from 'types/enums'
import { ActionLogAttachment } from '../types/models'

const controllerName = '/issue'

/**
 * @param {number} issueId - issue id
 * @param {number} type - attachment type id
 * @param {CancelToken} cancelToken - Axios CancelToken
 */
export const getAttachments = (
    issueId: number,
    typeId: AttachmentTypes = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<Attachment>>> =>
    axios.get(`${controllerName}/${issueId}/attachments`, {
        params: { typeId },
        cancelToken,
    })

export const downloadAttachment = (
    issueId: number,
    attachmentId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(
        `${controllerName}/${issueId}/attachments/${attachmentId}/download`,
        {
            cancelToken,
            responseType: 'blob',
        }
    )

export const downloadAllAttachment = (
    issueId: number,
    typeId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(`${controllerName}/${issueId}/attachments/zip?typeId=${typeId}`, {
        cancelToken,
        responseType: 'blob',
    })

export const uploadFile = (
    issueId: number,
    typeId: AttachmentTypes,
    file: File,
    progressCallback: (progressEvent: any) => void,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Attachment>> => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('typeId', String(typeId))
    formData.append('issueId', String(issueId))
    return axios.post(`${controllerName}/${issueId}/attachments`, formData, {
        onUploadProgress: progressCallback,
        cancelToken,
    })
}

export const deleteFile = (
    issueId: number,
    attachmentId: number,
    cancelToken: CancelToken = null
): Promise<AxiosResponse> =>
    axios.delete(
        `${controllerName}/${issueId}/attachments/${attachmentId}/delete`,
        {
            cancelToken,
        }
    )

export const downloadActionLogAttachment = (
    attachment: ActionLogAttachment,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<any>> =>
    axios.get(
        `${controllerName}/action-log/${attachment.type}/attachments/${attachment.id}/download`,
        {
            cancelToken,
            responseType: 'blob',
            params: { fileName: attachment.fileName },
        }
    )
