import { Close, InfoOutlined } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { Button } from 'components'
import { dataTestId } from 'utils'
import { ErrorMessageProps } from './types'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: theme.palette.error.light,
            border: `1px solid ${theme.palette.error.main}`,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(2),
        },
        content: {
            display: 'flex',
            alignItems: 'center',
        },
        icon: {
            margin: theme.spacing(2, 2, 2, 2),
        },
    }),
    { name: 'CustomerContactDialogErrorMessage' }
)

const ErrorMessage = (props: ErrorMessageProps) => {
    const { error, onClick } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div
                className={classes.content}
                {...dataTestId('SNACKBAR_CONTAINER')}
            >
                <InfoOutlined className={classes.icon} />
                {error}
            </div>
            <Button onClick={onClick}>
                <Close />
            </Button>
        </div>
    )
}

export default ErrorMessage
