import { SupervisorAccountOutlined } from '@mui/icons-material'
import {
    AdminEmployeesChipsSimple,
    RUAdminChip,
    RUProcessOwnersResultsProps,
} from 'Admin'
import { AdminEmployeeTypeEnum } from 'types/adminTypes'
import { IssueRoles } from 'types/enums'
import { EMPTY_VALUE_PLACEHOLDER } from 'utils'

export const RUProcessOwnersResults = (props: RUProcessOwnersResultsProps) => {
    const {
        profile,
        disabled = false,
        employees,
        deletePersonFromResolvingUnit,
        id,
    } = props
    const isLast = employees?.length === 1
    const isSupportDesk = profile.roles.some(
        (r) => r.roleId === IssueRoles.Support_Desk
    )
    return (
        <AdminEmployeesChipsSimple
            renderIcon={(props) => <SupervisorAccountOutlined {...props} />}
            label="RU Process owners"
        >
            {employees
                ? employees.map((employee) => {
                      const onDelete = () => {
                          deletePersonFromResolvingUnit({
                              personEmail: employee.email,
                              resolvingUnitId: id,
                              ruPersonType:
                                  AdminEmployeeTypeEnum.RUProcessOwner,
                          })
                      }
                      return (
                          <RUAdminChip
                              disabled={isSupportDesk ? false : disabled}
                              onDelete={onDelete}
                              employee={employee}
                              isLast={isSupportDesk ? false : isLast}
                              key={employee.email}
                          />
                      )
                  })
                : EMPTY_VALUE_PLACEHOLDER}
        </AdminEmployeesChipsSimple>
    )
}
