import { NavButton } from 'components'
import { dataTestId, urlBuilder } from 'utils'
import { TileActionsProps } from '..'
import * as ROUTES from '../../../routes'

export const ToSubmitActions = (props: TileActionsProps) => {
    const { myActionMeta } = props
    return (
        <>
            <NavButton
                color="secondary"
                linkProps={{
                    to: urlBuilder(ROUTES.ISSUE_VIEW, {
                        id: myActionMeta.issueId,
                    }),
                }}
                {...dataTestId('DASHBOARD_ISSUE_TILE_TO_SUBMIT')}
            >
                To Submit
            </NavButton>
        </>
    )
}
