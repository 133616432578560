import { Memory, Work } from '@mui/icons-material'
import { dataTestId } from 'utils'
import { ResolutionUnitValue } from '.'
import { DataArea, NavButton, ValueView } from '../../components'
import { DASHBOARD_PAGE } from '../../consts/selectorIds'
import { MyAction } from '../../types/myDashboard'
import ExpandTile from './ExpandTile'
import IssueInfoBaseRow from './IssueInfoBaseRow'
import { useTilesStyles } from './tilesStyles'

export interface GenericActionTileProps {
    actionIssue: MyAction
    actionLabel?: string
}

export const GenericActionTile = (props: GenericActionTileProps) => {
    const { actionLabel, actionIssue, ...other } = props

    const classes = useTilesStyles(props)
    const resolvingUnit = actionIssue?.resolvingUnit
    const actions = actionLabel ? (
        <NavButton
            color="secondary"
            linkProps={{ to: `/issue/${actionIssue.issueId}` }}
            {...DASHBOARD_PAGE.ISSUE_TILE.ACTION_BUTTONS.RESOLVE}
            eventName={actionLabel}
        >
            {actionLabel}
        </NavButton>
    ) : (
        <div className={classes.unsupport}>
            This action is not supported yet. To perform the action, please
            visit ccrp.abb.com.
        </div>
    )
    return (
        <ExpandTile
            {...other}
            {...actionIssue}
            showActionStatus={true}
            actionCode={actionIssue.action.actionCode}
            issueId={actionIssue.issueId}
            issueNumber={actionIssue.issueNumber}
            collapsedSize="64px"
            isOverdue={actionIssue.action.isActionOverdue}
            isHighImpact={actionIssue.isHighImpact}
            actions={actions}
        >
            <IssueInfoBaseRow
                deadlineDate={actionIssue?.action?.deadlineDate}
                issueType={actionIssue.issueType}
            />
            <ResolutionUnitValue
                resolvingUnit={resolvingUnit}
                {...dataTestId('DASHBOARD_ISSUE_TILE_RESOLVING_UNIT')}
            />
            <DataArea>
                {actionIssue.productName && (
                    <ValueView
                        className={classes.cell}
                        icon={<Memory className={classes.labelIcon} />}
                        label="Product"
                        value={actionIssue.productName}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.PRODUCT}
                    />
                )}
                {actionIssue.customerName && (
                    <ValueView
                        className={classes.cell}
                        icon={<Work className={classes.labelIcon} />}
                        label="Customer"
                        value={actionIssue.customerName}
                        {...DASHBOARD_PAGE.ISSUE_TILE.COMPONENTS.CUSTOMER}
                    />
                )}
            </DataArea>
        </ExpandTile>
    )
}

export default GenericActionTile
