import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    IssueStoreState,
    ISSUE_REJECT_RESOLUTION_CALL,
    loadIssue,
    rejectResolutionDone,
    setIssueActionFail,
} from '..'

import { RootState } from 'store/types'
import { ActionsKeys, IssueActionsTypes } from 'types/enums'
import { removeMyActionById } from 'store/myActions'
import { showSnackbar } from 'store/app'
import { RejectResolutionCommand } from 'store/myActions/types'
import { rejectResolution } from 'api'

export function* rejectResolutionAction(action: RejectResolutionCommand) {
    const cancelSource = axios.CancelToken.source()
    const query = action?.payload?.query
    try {
        const { currentActions } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        let actionType: IssueActionsTypes = IssueActionsTypes.To_Complete
        if (
            currentActions.find(
                (a) => a === ActionsKeys.ReturnToROFromInProcessVerification
            )
        ) {
            actionType = IssueActionsTypes.To_Verify_Resolution
        }
        yield call(rejectResolution, query, cancelSource.token)
        yield put(rejectResolutionDone())
        yield put(removeMyActionById(query.issueId, actionType))
        yield put(loadIssue(query.issueId))
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_REJECT_RESOLUTION_CALL, error))
        yield put(showSnackbar('Cannot reject issue!', true))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* rejectResolutionSaga() {
    yield takeLatest([ISSUE_REJECT_RESOLUTION_CALL], rejectResolutionAction)
}
