import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import { IssueStoreState, setIssueActionDone } from '..'
import { RootState } from 'store/types'
import FileSaver from 'file-saver'
import { showSnackbar } from 'store/app'
import { ISSUE_GENERATE_SUMMARY_REPORT } from '../actionsTypes'
import axios, { AxiosResponse } from 'axios'
import { getSummaryReport } from 'api'

export function* generateCcrpOverviewReport() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueId, issueNumber } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const res = (yield call(getSummaryReport, issueId)) as AxiosResponse
        FileSaver.saveAs(res.data, `${issueNumber}-summary-report.docx`)
        yield put(setIssueActionDone('generateCcrpOverviewReport'))
        yield put(showSnackbar('Report is generated!', false))
    } catch (error) {
        yield put(showSnackbar('Report generating fail', true))
        yield put(setIssueActionDone(' generateCcrpOverviewReport'))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* generateCcrpOverviewReportSaga() {
    yield takeLatest(
        [ISSUE_GENERATE_SUMMARY_REPORT],
        generateCcrpOverviewReport
    )
}
