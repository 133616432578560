import { CSSProperties } from 'react'
import { FillTextColorInlineStyles, TextColorInlineStyles } from '../types'

export const getInlineStylesMap: () => Record<
    TextColorInlineStyles & FillTextColorInlineStyles,
    CSSProperties
> = () => ({
    ABB_BLACK: { color: AbbColorMap['ABB_BLACK'] },
    ABB_WHITE: { color: AbbColorMap['ABB_WHITE'] },
    ABB_GRAY_01: { color: AbbColorMap['ABB_GRAY_01'] },
    ABB_GRAY_02: { color: AbbColorMap['ABB_GRAY_02'] },
    ABB_GRAY_03: { color: AbbColorMap['ABB_GRAY_03'] },
    ABB_GRAY_04: { color: AbbColorMap['ABB_GRAY_04'] },
    ABB_GRAY_05: { color: AbbColorMap['ABB_GRAY_05'] },
    ABB_GRAY_06: { color: AbbColorMap['ABB_GRAY_06'] },
    ABB_BLUE: { color: AbbColorMap['ABB_BLUE'] },
    ABB_GREEN: { color: AbbColorMap['ABB_GREEN'] },
    ABB_RED: { color: AbbColorMap['ABB_RED'] },
    ABB_YELLOW: { color: AbbColorMap['ABB_YELLOW'] },
    ABB_BLACK_FILL: { backgroundColor: AbbColorMap['ABB_BLACK'] },
    ABB_WHITE_FILL: { backgroundColor: AbbColorMap['ABB_WHITE'] },
    ABB_GRAY_01_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_01'] },
    ABB_GRAY_02_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_02'] },
    ABB_GRAY_03_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_03'] },
    ABB_GRAY_04_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_04'] },
    ABB_GRAY_05_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_05'] },
    ABB_GRAY_06_FILL: { backgroundColor: AbbColorMap['ABB_GRAY_06'] },
    ABB_BLUE_FILL: { backgroundColor: AbbColorMap['ABB_BLUE'] },
    ABB_GREEN_FILL: { backgroundColor: AbbColorMap['ABB_GREEN'] },
    ABB_RED_FILL: { backgroundColor: AbbColorMap['ABB_RED'] },
    ABB_YELLOW_FILL: { backgroundColor: AbbColorMap['ABB_YELLOW'] },
})

export const getInlineStylesMapForHtml = () => {
    const map = getInlineStylesMap()
    const results = {}
    for (const key in map) {
        results[key] = { style: map[key] }
    }
    return results
}

const AbbColorMap: Record<TextColorInlineStyles, string> = {
    ABB_BLACK: '#000000',
    ABB_WHITE: '#ffffff',
    ABB_GRAY_01: '#262626',
    ABB_GRAY_02: '#6e6e6e',
    ABB_GRAY_03: '#a9a9a9',
    ABB_GRAY_04: '#d2d2d2',
    ABB_GRAY_05: '#f0f0f0',
    ABB_GRAY_06: '#fafafa',
    ABB_BLUE: '#004c97',
    ABB_GREEN: '#007a33',
    ABB_RED: '#ff000f',
    ABB_YELLOW: '#ffd100',
}

export const getAbbColor = (inlineStyle: TextColorInlineStyles) =>
    AbbColorMap[inlineStyle]
