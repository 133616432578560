import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import { setIssueActionDone } from '..'
import { RootState } from 'store/types'
import { showSnackbar } from 'store/app'
import { ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT } from '../actionsTypes'
import axios, { AxiosResponse } from 'axios'
import { getPowerPointOverview } from 'api'
import FileSaver from 'file-saver'
import { IssueStoreState } from '../initState'

export function* generateOverviewInPowerPoint() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { issueId, issueNumber } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const res = (yield call(
            getPowerPointOverview,
            issueId
        )) as AxiosResponse
        FileSaver.saveAs(res.data, `${issueNumber}-overview.pptx`)
        yield put(setIssueActionDone('generateOverviewInPowerPoint'))
        yield put(showSnackbar('Report is generated!', false))
    } catch (error) {
        yield put(showSnackbar('Report generating fail', true))
        yield put(setIssueActionDone('generateOverviewInPowerPoint'))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* generateOverviewInPowerPointSaga() {
    yield takeLatest(
        [ISSUE_GENERATE_OVERVIEW_IN_POWERPOINT],
        generateOverviewInPowerPoint
    )
}
