import { ReactNode, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SnackbarContentCallback, useSnackbar } from 'notistack'
import {
    AppStoreNotificationErrorType,
    AppStoreNotifications,
    NotificationType,
    removeSnackbar,
} from 'store/app'
import { RootState } from 'store/types'
import { VariantType } from 'notistack'
import MessageItem from 'components/Base/MessageItem'
import { dataTestId } from 'utils'

let displayed: string[] = []

const getVariant = (error: AppStoreNotificationErrorType): VariantType => {
    if (typeof error === 'boolean') {
        return error ? 'success' : 'error'
    } else {
        switch (error) {
            case NotificationType.Success:
                return 'success'
            case NotificationType.Error:
                return 'error'
            case NotificationType.Warning:
                return 'warning'
            case NotificationType.Information:
                return 'info'
            default:
                return 'default'
        }
    }
}

const SnackbarNotifier = () => {
    const ref = useRef<HTMLDivElement>()

    const dispatch = useDispatch()
    const notifications = useSelector<RootState, AppStoreNotifications>(
        (state) => state.app.notifications || []
    )

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const storeDisplayed = (id: string) => {
        displayed = [...displayed, id]
    }

    const removeDisplayed = (id: string) => {
        displayed = [...displayed.filter((key) => id !== key)]
    }

    useEffect(() => {
        notifications.forEach(({ key, message, error, dismissed }) => {
            if (dismissed) {
                closeSnackbar(key)
                return
            }

            if (displayed.includes(key)) return

            enqueueSnackbar(message, {
                key,
                content: (
                    snackbarKey: string,
                    message: string | ReactNode
                ): SnackbarContentCallback => (
                    <MessageItem
                        ref={ref}
                        key={snackbarKey}
                        variant={getVariant(error)}
                        message={<span id="message-id">{message}</span>}
                        onClose={() => closeSnackbar(key)}
                        {...dataTestId('SNACKBAR_CONTAINER')}
                    />
                ),
                onExited: (event, myKey) => {
                    dispatch(removeSnackbar(`${myKey}`))
                    removeDisplayed(`${myKey}`)
                },
            })

            storeDisplayed(key)
        })
    }, [notifications])

    return null
}

export default SnackbarNotifier
