import { ErrorOutline } from '@mui/icons-material'
import { Collapse, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { EmployeeSelect, HelpTip, MultiEmployeeSelect } from 'components'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import { useFieldWithError, useHasActionInContext } from 'Issue/matrix'
import { memo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueActionsTypes, IssueValuesKeys } from 'types/enums'
import { CaptureSection, Employee } from 'types/issueTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
    }),
    {
        name: 'IssueOwnerAndResolvingUnit',
    }
)

export interface IssueOwnerAndResolvingUnitProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        Partial<{
            disabled: boolean
            lightMode: boolean
            issueOwner?: Employee
            isCopySentToParties: boolean
            issueOwnerTeam: Array<Employee>
            proposedResolutionOwner: Employee
            partiesReference: Array<Employee>
            onCaptureSectionChanged: (
                key: keyof CaptureSection,
                value: any
            ) => void
        }> {}

const IssueOwnerAndResolvingUnit = (props: IssueOwnerAndResolvingUnitProps) => {
    const {
        disabled,
        lightMode,
        isCopySentToParties,
        issueOwner,
        issueOwnerTeam,
        proposedResolutionOwner,
        partiesReference,
        onCaptureSectionChanged,
        ...other
    } = props
    const theme = useTheme()

    const classes = useStyles(props)

    const hasToReplaceInactiveIO = useHasActionInContext(
        IssueActionsTypes.To_Replace_Inactive_IO
    )
    const issueOwnerEmailField = useFieldWithError(IssueValuesKeys.IssueOwner, {
        disabled,
        defaultError: hasToReplaceInactiveIO,
    })
    const issueOwnerTeamField = useFieldWithError(
        IssueValuesKeys.IssueOwnerTeamEmails,
        { disabled }
    )
    const proposedResolutionOwnerField = useFieldWithError(
        IssueValuesKeys.ProposedResolutionOwner,
        { disabled }
    )
    const partiesReferenceEmailsField = useFieldWithError(
        IssueValuesKeys.PartiesReference,
        { disabled }
    )

    return (
        <div {...other}>
            <Typography variant="h6">
                Issue owner, Proposed resolution owner and interested parties
            </Typography>
            <EmployeeSelect
                {...ISSUE_VIEW_PAGE.IO_AND_RU.ISSUE_OWNER_SELECT}
                className={classes.field}
                label="Issue owner"
                employee={issueOwner}
                onEmployeeChanged={(employee) =>
                    onCaptureSectionChanged &&
                    onCaptureSectionChanged('issueOwner', employee)
                }
                {...issueOwnerEmailField}
                startAdornment={
                    issueOwner &&
                    !issueOwner.isActive && (
                        <HelpTip
                            icon={
                                <ErrorOutline
                                    fontSize="small"
                                    style={{
                                        color: theme.palette.secondary.main,
                                    }}
                                />
                            }
                            size="small"
                            title="Issue owner isn't active"
                        />
                    )
                }
            />
            <Collapse in={!lightMode}>
                <MultiEmployeeSelect
                    {...ISSUE_VIEW_PAGE.IO_AND_RU.SUBSTITUTES_SELECT}
                    className={classes.field}
                    label="Substitute(s)"
                    employees={issueOwnerTeam ?? []}
                    onEmployeesChanged={(employees: Array<Employee>) =>
                        onCaptureSectionChanged &&
                        onCaptureSectionChanged('issueOwnerTeam', [
                            ...employees,
                        ])
                    }
                    {...issueOwnerTeamField}
                />
                <EmployeeSelect
                    {...ISSUE_VIEW_PAGE.IO_AND_RU.PROPOSED_RO_SELECT}
                    className={classes.field}
                    label="Proposed resolution owner"
                    employee={proposedResolutionOwner}
                    onEmployeeChanged={(employee: Employee) =>
                        onCaptureSectionChanged &&
                        onCaptureSectionChanged('proposedResolutionOwner', {
                            ...employee,
                        })
                    }
                    {...proposedResolutionOwnerField}
                />

                <MultiEmployeeSelect
                    {...ISSUE_VIEW_PAGE.IO_AND_RU.SEND_COPY_TO_SELECT}
                    className={classes.field}
                    label="Send copy to all interested parties"
                    {...partiesReferenceEmailsField}
                    employees={partiesReference ?? []}
                    onEmployeesChanged={(employees: Array<Employee>) =>
                        onCaptureSectionChanged &&
                        onCaptureSectionChanged('partiesReference', [
                            ...employees,
                        ])
                    }
                />
            </Collapse>
        </div>
    )
}

IssueOwnerAndResolvingUnit.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default memo(IssueOwnerAndResolvingUnit)
