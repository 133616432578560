import { getGlobalNotification } from 'api'
import { useBrowserTabVisibility } from 'hooks'
import { createContext, FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useInterval } from 'react-use'
import { RootState } from 'store/types'
import { IssueRoles } from 'types/enums'
import { TechnicalBreakOverlay } from './components'
import {
    GlobalAppNotification,
    GlobalAppNotificationContextProps,
} from './types'

export const GlobalNotificationContext =
    createContext<GlobalAppNotificationContextProps>({})

export const getDefaultGlobalAppNotification = (): GlobalAppNotification => ({
    technicalBreak: false,
    technicalBreakTitle: '',
    technicalBreakDescription: '',
    message: '',
    messageVisible: false,
    messageFrom: null,
    messageTo: null,
    messageVariant: null,
    businessUnit: null,
})

const DELAY_TIMER = 600000 // 10 minutes

export const GlobalNotificationProvider: FC<any> = (props) => {
    const { children } = props
    const [isRunning, toggleIsRunning] = useState(true)

    const [notification, setNotification] = useState<GlobalAppNotification>(
        getDefaultGlobalAppNotification()
    )

    useBrowserTabVisibility((visible) => {
        toggleIsRunning(visible)
        if (visible) {
            loadNotifications()
        }
    })
    const isDeveloper = useSelector<RootState, boolean>(
        (state) =>
            state?.dashboard?.profile?.roles?.some(
                (r) => r.roleId === IssueRoles.Developer
            ) ?? false
    )
    const loadNotifications = async () => {
        try {
            const { data } = await getGlobalNotification()
            setNotification(data)
        } catch (error) {
            console.error('Cannot load notifications')
        }
    }
    useInterval(
        () => {
            loadNotifications()
        },
        isRunning ? DELAY_TIMER : null
    )
    useEffect(() => {
        ;(window as any).setGlocalNotification = setNotification
        loadNotifications()
        return () => toggleIsRunning(false)
    }, [])
    const {
        technicalBreak,
        technicalBreakDescription: technicalBreakMessage,
        technicalBreakTitle,
    } = notification
    const overlay = (
        <TechnicalBreakOverlay
            title={technicalBreakTitle}
            description={technicalBreakMessage}
        />
    )
    return (
        <GlobalNotificationContext.Provider
            value={{ notification, setNotification }}
        >
            {technicalBreak && !isDeveloper ? overlay : children}
        </GlobalNotificationContext.Provider>
    )
}
