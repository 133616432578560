import { Collapse, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { TileFooter, TileFooterProps } from 'components'
import {
    HtmlHTMLAttributes,
    ReactNode,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            float: 'left',
            marginBottom: theme.spacing(3),
            '@media (max-width: 768px)': {
                float: 'none',
                margin: 0,
                marginBottom: theme.spacing(2.5),
            },
        },
        content: {
            padding: theme.spacing(2),
            boxSizing: 'border-box',
            ...theme.typography.body2,
            color: theme.palette.grey[600],
            position: 'relative',
            overflow: 'hidden',
            marginBottom: theme.spacing(1),
        },
        bottomShadow: {
            position: 'absolute',
            display: 'block',
            height: theme.spacing(4),
            bottom: 0,
            left: 0,
            right: 0,
            background: 'linear-gradient(rgba(255,255,255,0), white)',
        },
    }),
    {
        name: 'ExpandableCardView',
    }
)

export interface ExpandableCardViewProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    expand?: boolean
    onExpand?: (expand: boolean) => void
    renderHeader?: () => ReactNode
    renderContent?: () => ReactNode
    collapsedSize?: number
    tileFooterProps?: TileFooterProps
}

export const ExpandableCardView = (props: ExpandableCardViewProps) => {
    const ref = useRef(null)
    const {
        className,
        expand: expandProp,
        children,
        renderHeader,
        renderContent,
        collapsedSize = 64,
        tileFooterProps = {},
        onExpand,
        ...other
    } = props
    const classes = useStyles(props)
    const [expandabled, setExpandabled] = useState(false)
    const [expand, setExpand] = useState(false)
    useEffect(() => {
        if (expand !== expandProp) {
            setExpand(expandProp)
        }
    }, [expandProp])
    useLayoutEffect(() => {
        const current = ref.current
        setExpandabled(current.scrollHeight - 16 > current.clientHeight)
    }, [ref])
    const handleExpand = () => {
        const newExpandState = !expand
        setExpand(newExpandState)
        onExpand && onExpand(newExpandState)
    }

    return (
        <div className={clsx(classes.root, className)} {...other}>
            {renderHeader && renderHeader()}
            <Collapse
                className={classes.content}
                in={expand}
                collapsedSize={expandabled ? collapsedSize : 48}
                ref={ref}
            >
                {renderContent && renderContent()}
                {!expand && expandabled && (
                    <div className={classes.bottomShadow} />
                )}
            </Collapse>
            {expandabled && (
                <TileFooter
                    expanded={expand}
                    onExpand={handleExpand}
                    {...tileFooterProps}
                >
                    {children}
                </TileFooter>
            )}
        </div>
    )
}
