import { Delete } from '@mui/icons-material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { EmployeeChip, EmployeeChipProps } from 'components'
import { dataTestId } from 'utils'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginRight: theme.spacing(1.5),
            marginBottom: theme.spacing(1),
        },
        rootError: {
            '&:hover': {
                backgroundColor: theme.palette.error.light,
            },
        },
        errorIcon: {
            color: theme.palette.error.main,
        },
    }),
    {
        name: 'AdminEmployeeChip',
    }
)

export interface AdminEmployeeChipProps extends EmployeeChipProps {}

export const AdminEmployeeChip = (props: AdminEmployeeChipProps) => {
    const {
        className,
        employee,
        onDelete,
        deleteIcon = <Delete {...dataTestId('DELETE_RU_PERSONS_BUTTON')} />,
        open,
        onClose,
        ...other
    } = props
    const classes = useStyles()

    const isEmployeeInActive = !employee?.isActive
    return (
        <EmployeeChip
            className={clsx(
                classes.root,
                isEmployeeInActive && classes.rootError,
                className
            )}
            icon={
                isEmployeeInActive ? (
                    <ErrorOutlineIcon className={classes.errorIcon} />
                ) : null
            }
            employee={employee}
            customTitle={
                isEmployeeInActive && 'This person is no longer active'
            }
            customLabel={employee?.fullName}
            deleteIcon={onDelete && deleteIcon}
            onDelete={onDelete}
            open={open}
            onClose={onClose}
            {...other}
        />
    )
}
