import { IEMessageDocument } from './types'

const IEMessageDocumentStorageKey = 'ccrp-ie-message-alert'

export const getIEMessageDocuments: () => IEMessageDocument[] = () => {
    const data = localStorage.getItem(IEMessageDocumentStorageKey)
    if (data) {
        const documents = JSON.parse(data) as IEMessageDocument[]
        return documents
    }
    return []
}

const save = (items: IEMessageDocument[]) =>
    localStorage.setItem(IEMessageDocumentStorageKey, JSON.stringify(items))

export const setIEMessageDocument: (item: IEMessageDocument) => void = (
    item
) => {
    let items = getIEMessageDocuments()
    if (items.length === 0) {
        save([item])
    } else if (0 < items.length) {
        const index = items.findIndex((x) => x.email === item.email)
        if (-1 < index) {
            items[index] = item
        } else {
            items = [item, ...items]
        }
        save(items)
    }
}
