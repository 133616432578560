import { alpha, CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { FC, HtmlHTMLAttributes } from 'react'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            position: 'relative',
        },
        overlay: {
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            zIndex: 1,
            backgroundColor: alpha(theme.palette.common.white, 0.65),
        },
        loader: {
            marginTop: theme.spacing(6),
        },
    }),
    { name: 'SectionLoader' }
)

export interface SectionLoaderProps extends HtmlHTMLAttributes<Element> {
    loading: boolean
    error?: any
}

export const SectionLoader: FC<SectionLoaderProps> = (props) => {
    const { className, loading, error, children, ...other } = props
    const classes = useStyles()
    return (
        <div className={clsx(className, classes.root)} {...other}>
            {loading && (
                <div className={classes.overlay}>
                    <CircularProgress
                        className={classes.loader}
                        color="secondary"
                    />
                </div>
            )}
            {children}
        </div>
    )
}
