import { List } from '@mui/material'
import { NoResults } from 'components'
import { useMemo } from 'react'
import { CustomerContact } from 'types/issueTypes'
import CustomerContactItem from './Item'
import { CustomerContactListProps } from './types'

const CustomerContactList = (props: CustomerContactListProps) => {
    const {
        onSelect,
        onEdit,
        results,
        errorMessage,
        subTitle = 'Create first contact',
    } = props

    const listResults = useMemo(
        () => (
            <List>
                {results.map((c: CustomerContact) => (
                    <CustomerContactItem
                        key={c.id}
                        item={c}
                        onSelect={onSelect}
                        onEdit={onEdit}
                    />
                ))}
            </List>
        ),
        [results]
    )

    return (
        <>
            {results && 0 < results.length ? (
                listResults
            ) : (
                <NoResults title={errorMessage} subTitle={subTitle} />
            )}
        </>
    )
}

export default CustomerContactList
