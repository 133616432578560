import { ChangeEvent, useEffect, useState } from 'react'

import { ErrorOutline, ExpandMore } from '@mui/icons-material'
import { Typography, useTheme } from '@mui/material'
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    FileManager,
    FlexGrow,
    PickerTextField,
    ReturnToResolutionOwnerDialog,
    RevertToNotAssignedDialog,
    TextField,
} from 'components'
import { ISSUE_VIEW_PAGE } from 'consts/selectorIds'
import TemplateView from 'Issue/components/TemplateView'
import ToolsView from 'Issue/components/ToolsView'
import { useDispatch, useSelector } from 'react-redux'
import {
    addAttachment,
    deleteAttachment,
    rejectResolution,
    revertToNotAssignedCall,
    selectPGCategory,
} from 'store/issue/actions'
import { RootState } from 'store/types'
import { RejectResolutionQuery, RevertToNotAssignedQuery } from 'types/actions'
import {
    ActionsKeys,
    IssueStatuses,
    IssueTypes,
    IssueValuesKeys,
} from 'types/enums'
import {
    Attachment,
    OrganizationalInfo,
    ResolutionSection,
} from 'types/issueTypes'
import { dataTestId } from 'utils'
import ReportsView, { ReportsViewProps } from '../../components/ReportsView'
import { useFieldWithError, useIssueAction } from '../../matrix'
import { ActionPlanSection } from '../ActionPlanSection'
import useIssueFormStyles from '../useIssueFormStyles'
import { RESOLUTION_SECTION_ID, useResolvedActionActive } from '../utils'
import PgRcaCategoryStartAdornment from './components/PgRcaCategoryStartAdornment'

// TODO: This biznes logic is talked out?
export const actionPlanIsVisible = (issueStatusId) =>
    issueStatusId &&
    issueStatusId !== IssueStatuses.New &&
    issueStatusId !== IssueStatuses.Draft &&
    issueStatusId !== IssueStatuses.Not_Assigned

export interface ResolutionProps
    extends Partial<{
            className: string
            expanded: boolean
            issueType: IssueTypes
            resolvingUnitId: number
            onExpand: (expanded: boolean) => void
            disabled: boolean
            issueStatusId?: IssueStatuses
            containmentActionRequired: boolean
            resolutionSection: ResolutionSection
            number: string
            attachments: Array<Attachment>
            rcaAttachments: Array<Attachment>
            onSectionChnage?: (
                key: keyof ResolutionSection,
                value: string
            ) => void
            onSectionSave?: () => void
            onVerified?: () => void
            onResolved?: () => void
            onEditPGCategory?: () => void
            onVerifyContainmentAction?: () => void
        }>,
        ReportsViewProps {}

interface ResolutionSectionState {
    whatWasTheRootCause?: string
    howDidWeResolveThisIssue?: string
    whatContainmentActionsWereTaken?: string
}

const Resolution = (props: ResolutionProps) => {
    const {
        resolutionSection,
        issueStatusId,
        resolvingUnitId,
        issueType,
        expanded,
        disabled,
        attachments = [],
        rcaAttachments = [],
        onExpand,
        number,
        onSectionChnage,
        onSectionSave,
        onResolved,
        onVerified,
        onEditPGCategory,
        onVerifyContainmentAction,
        generateRaport,
        containmentActionRequired,
        ...other
    } = props
    const classes = useIssueFormStyles()
    const theme = useTheme()
    const dispatch = useDispatch()
    const [returnToRoOpen, setReturnToRo] = useState<boolean>(false)
    const [revertToNotAssignOpen, setRevertToNotAssign] =
        useState<boolean>(false)
    const [state, setState] = useState<ResolutionSectionState>({
        whatWasTheRootCause: '',
        howDidWeResolveThisIssue: '',
        whatContainmentActionsWereTaken: '',
    })

    useEffect(() => {
        setState((prev) => ({
            ...prev,
            whatWasTheRootCause: resolutionSection?.whatWasTheRootCause ?? '',
            howDidWeResolveThisIssue:
                resolutionSection?.howDidWeResolveThisIssue ?? '',
            whatContainmentActionsWereTaken:
                resolutionSection?.whatContainmentActionsWereTaken ?? '',
        }))
    }, [resolutionSection])
    const saveResolution = useIssueAction(ActionsKeys.SaveResolutionSection)
    const revertToNotAssigned = useIssueAction(ActionsKeys.RevertToNotAssigned)
    const verifyContainmentAction = useIssueAction(
        ActionsKeys.ContainmentActionsExecuted
    )
    const resolvedAction = useIssueAction(ActionsKeys.Resolved)
    const resolvedActionActive = useResolvedActionActive(
        issueStatusId,
        resolvedAction.disabled
    )

    const whatWasTheRootCauseField = useFieldWithError(
        IssueValuesKeys.WhatWasTheRootCause,
        { disabled }
    )
    const returnToROFromInProcessVerificationAction = useIssueAction(
        ActionsKeys.ReturnToROFromInProcessVerification
    )
    const verifiedAction = useIssueAction(ActionsKeys.Verified)
    const howDidWeResolveThisIssueField = useFieldWithError(
        IssueValuesKeys.HowDidWeResolveThisIssue,
        { disabled }
    )
    const whatContainmentActionsWereTakenField = useFieldWithError(
        IssueValuesKeys.WhatContainmentActionsWereTaken,
        { disabled }
    )
    const resolvingUnitOrganizational = useSelector<
        RootState,
        OrganizationalInfo
    >(
        (state) =>
            state?.issue?.captureSection?.resolvingUnit?.organizationalInfo
    )

    const pgRcaCategoryField = useFieldWithError(
        IssueValuesKeys.PgRcaCategory,
        {
            disabled,
            matrixModifier(currentMatrix) {
                if (
                    !resolvingUnitOrganizational?.division?.code ||
                    !resolvingUnitOrganizational?.productGroup?.code ||
                    (resolvedAction.disabled &&
                        (currentMatrix.isDisabled ||
                            issueStatusId === IssueStatuses.In_Process))
                )
                    return {
                        ...currentMatrix,
                        isRequired: false,
                    }
                return {
                    ...currentMatrix,
                    isRequired: true,
                }
            },
        }
    )

    const rcaAttachmentsField = useFieldWithError(
        IssueValuesKeys.RcaAttachments,
        { disabled }
    )
    const attachmentsField = useFieldWithError(
        IssueValuesKeys.ResolutionAttachments,
        { disabled }
    )
    if (resolutionSection == null) {
        return null
    }
    const handleCommitField = (key: keyof ResolutionSection, value: any) =>
        onSectionChnage && onSectionChnage(key, value)
    const handleUpdateField = (key: keyof ResolutionSection, value: any) =>
        setState((prev: ResolutionSectionState) => ({ ...prev, [key]: value }))
    const pgRcaCategory = resolutionSection.pgRcaCategory
    const handleReturnToRo = (query: RejectResolutionQuery) => {
        setReturnToRo(!returnToRoOpen)
        dispatch(rejectResolution(query))
    }
    const handleRevertToNotAssigned = (query: RevertToNotAssignedQuery) => {
        setRevertToNotAssign(false)
        dispatch(revertToNotAssignedCall(query))
    }
    const handleFileUpload = (attachment: Attachment) => {
        dispatch(addAttachment(attachment))
    }
    const handleFileRemove = (attachment: Attachment) => {
        dispatch(deleteAttachment(attachment))
    }

    return resolutionSection ? (
        <>
            <Accordion
                id={RESOLUTION_SECTION_ID}
                className={classes.section}
                expanded={expanded}
                onChange={(e: any, expanded: boolean) =>
                    onExpand && onExpand(expanded)
                }
                {...other}
            >
                <AccordionSummary
                    {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.EXPANSION_PANEL}
                    expandIcon={<ExpandMore />}
                >
                    <Typography variant="h5">Issue resolution - RCA</Typography>
                </AccordionSummary>
                <AccordionDetails
                    {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.DETAILS_CONTAINER}
                >
                    {!resolvedActionActive &&
                        [
                            IssueStatuses.In_Process,
                            IssueStatuses.In_Process_Overdue,
                        ].includes(issueStatusId) &&
                        !resolvedAction.disabled && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography
                                    variant="caption"
                                    style={{
                                        fontWeight: theme.typography
                                            .fontWeightMedium as number,
                                        marginBottom: theme.spacing(1),
                                    }}
                                >
                                    Issue warnings
                                </Typography>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        margin: theme.spacing(1, 0, 2),
                                    }}
                                >
                                    <ErrorOutline
                                        fontSize="small"
                                        color="secondary"
                                        style={{
                                            marginRight: theme.spacing(1),
                                        }}
                                    />
                                    <Typography
                                        variant="body2"
                                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                            .WARNINGS_ACTION_ITEM}
                                    >
                                        This issue cannot be resolved as there
                                        are still some action plan items which
                                        have to be closed first
                                    </Typography>
                                </div>
                            </div>
                        )}
                    {actionPlanIsVisible(issueStatusId) && expanded && (
                        <ActionPlanSection expanded={expanded} />
                    )}
                    {containmentActionRequired && (
                        <TextField
                            {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                .CONTAINMENT_ACTIONS_INPUT}
                            {...whatContainmentActionsWereTakenField}
                            label="Containment actions"
                            value={state.whatContainmentActionsWereTaken}
                            onChange={(e) =>
                                handleUpdateField(
                                    'whatContainmentActionsWereTaken',
                                    e.target.value
                                )
                            }
                            onBlur={() =>
                                handleCommitField(
                                    'whatContainmentActionsWereTaken',
                                    state.whatContainmentActionsWereTaken
                                )
                            }
                            fullWidth
                            multiline
                        />
                    )}
                    <TextField
                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.ROOT_CAUSE_INPUT}
                        label="What was the root cause of this issue?"
                        value={state?.whatWasTheRootCause ?? ''}
                        {...whatWasTheRootCauseField}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleUpdateField(
                                'whatWasTheRootCause',
                                e?.target?.value ?? ''
                            )
                        }}
                        onBlur={() =>
                            handleCommitField(
                                'whatWasTheRootCause',
                                state?.whatWasTheRootCause ?? ''
                            )
                        }
                        fullWidth
                        multiline
                    />
                    <TextField
                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.HOW_INPUT}
                        {...howDidWeResolveThisIssueField}
                        label="How did we resolve this issue?"
                        value={state.howDidWeResolveThisIssue}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            handleUpdateField(
                                'howDidWeResolveThisIssue',
                                e.target.value
                            )
                        }}
                        onBlur={() =>
                            handleCommitField(
                                'howDidWeResolveThisIssue',
                                state.howDidWeResolveThisIssue
                            )
                        }
                        fullWidth
                        multiline
                    />

                    <PickerTextField
                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.PG_RCA_CATEGORY}
                        className={classes.rcaCategory}
                        {...pgRcaCategoryField}
                        label={`PG RCA category`}
                        onSelect={() => onEditPGCategory && onEditPGCategory()}
                        value={pgRcaCategory ? pgRcaCategory.name : ''}
                        fullWidth
                        multiline
                        startAdornment={
                            <PgRcaCategoryStartAdornment
                                resolvingUnitId={resolvingUnitId}
                                pgRcaCategoryId={pgRcaCategory?.id}
                            />
                        }
                        onClearValue={() => dispatch(selectPGCategory(null))}
                    />

                    {/* display legacy field (readonly) */}
                    {resolutionSection?.resolutionDescription && (
                        <TextField
                            {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                .RESOLUTION_DESCRIPTION_INPUT}
                            disabled
                            label="Resolution description"
                            value={resolutionSection.resolutionDescription}
                            fullWidth
                            multiline
                        />
                    )}
                    {issueStatusId && issueStatusId !== IssueStatuses.Draft && (
                        <section className={classes.reaportsAndTools}>
                            <ReportsView
                                generateRaport={generateRaport}
                                style={{ flexGrow: 1 }}
                            />
                            <TemplateView
                                issueType={issueType}
                                resolvingUnitOrganizational={
                                    resolvingUnitOrganizational
                                }
                                style={{ flexGrow: 1 }}
                            />
                            <ToolsView
                                generateRaport={generateRaport}
                                issueType={issueType}
                                style={{ flexGrow: 1 }}
                            />
                        </section>
                    )}
                    <FileManager
                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.RCA_ATTACHMENTS}
                        style={{ marginBottom: theme.spacing(2) }}
                        label="RCA Attachments (max file size is 100 MB)"
                        issueId={resolutionSection.issueId}
                        attachmentType={8}
                        attachments={rcaAttachments}
                        {...rcaAttachmentsField}
                        onFileUploaded={handleFileUpload}
                        onFileRemove={handleFileRemove}
                    />
                    <FileManager
                        {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION.ATTACHMENTS}
                        issueId={resolutionSection.issueId}
                        attachmentType={9}
                        label={'Attachments (max file size is 100 MB)'}
                        attachments={attachments}
                        {...attachmentsField}
                        onFileUploaded={handleFileUpload}
                        onFileRemove={handleFileRemove}
                    />
                </AccordionDetails>
                {disabled ? null : (
                    <AccordionActions style={{ marginTop: theme.spacing(3) }}>
                        {saveResolution.disabled ? null : (
                            <Button
                                {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                    .ACTION_BUTTONS.SAVE}
                                onClick={() => onSectionSave && onSectionSave()}
                            >
                                Save
                            </Button>
                        )}
                        <FlexGrow />
                        {revertToNotAssigned.disabled ? null : (
                            <Button
                                onClick={() => setRevertToNotAssign(true)}
                                eventName="Revert to not assigned"
                                {...dataTestId(
                                    'REVERT_TO_NOT_ASSIGNED_SUPPORT_DESK'
                                )}
                            >
                                Revert to not assigned
                            </Button>
                        )}
                        {returnToROFromInProcessVerificationAction.disabled ? null : (
                            <Button
                                {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                    .ACTION_BUTTONS.RETURN_TO_RO}
                                onClick={() => setReturnToRo(true)}
                                eventName="Return to Resolution Owner"
                            >
                                Return to Resolution Owner
                            </Button>
                        )}
                        {!resolvedActionActive ? null : (
                            <Button
                                {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                    .ACTION_BUTTONS.RESOLVE}
                                onClick={() => {
                                    handleCommitField(
                                        'isAllActionItemClose',
                                        resolvedActionActive
                                    )
                                    onResolved && onResolved()
                                }}
                                eventName="Resolve"
                                color="secondary"
                            >
                                Resolve
                            </Button>
                        )}
                        {verifyContainmentAction.disabled ? null : (
                            <Button
                                {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                    .ACTION_BUTTONS.CONFIRM_CA}
                                onClick={() =>
                                    onVerifyContainmentAction &&
                                    onVerifyContainmentAction()
                                }
                                eventName="Confirm containment actions"
                            >
                                Confirm Containment Actions
                            </Button>
                        )}
                        {verifiedAction.disabled ? null : (
                            <Button
                                {...ISSUE_VIEW_PAGE.ISSUE_RESOLUTION
                                    .ACTION_BUTTONS.VERIFIED}
                                onClick={() => onVerified && onVerified()}
                                eventName="Verified"
                                color="secondary"
                            >
                                Verified
                            </Button>
                        )}
                    </AccordionActions>
                )}
            </Accordion>
            <RevertToNotAssignedDialog
                open={revertToNotAssignOpen}
                rowVersion={resolutionSection.rowVersion}
                issueNumber={number}
                issueId={resolutionSection.issueId}
                onClose={() => setRevertToNotAssign(false)}
                onRevertToNotAssigned={handleRevertToNotAssigned}
            />
            <ReturnToResolutionOwnerDialog
                open={returnToRoOpen}
                message={`You are about to reject resolution for issue ${number}. Message will be sent to Resolution Owner and status of the issue will change to "In process". You need to provide a reason for doing so.`}
                minHeight={220}
                rowVersion={resolutionSection.rowVersion}
                number={number}
                issueId={resolutionSection.issueId}
                onReturnToRO={(formData) => handleReturnToRo(formData)}
                onClose={() => setReturnToRo(false)}
            />
        </>
    ) : null
}

Resolution.defaultProps = {
    disabled: false,
    containmentActionRequired: false,
}

export default Resolution
