import Launch from '@mui/icons-material/Launch'
import ButtonBase from '@mui/material/ButtonBase'
import useMediaQuery from '@mui/material/useMediaQuery'
import { makeStyles } from '@mui/styles'
import FlexGrow from 'components/Base/FlexGrow'
import OverdueChipLabel from 'components/Base/OverdueChipLabel'
import ActionChipLabel from 'components/Chips/ActionChipLabel'
import StatusChipLabel from 'components/Chips/StatusChipLabel'
import { appInsights } from 'configs/appInsights'
import { ISSUE_TILE_HEADER_ELEMENT } from 'consts/selectorIds'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueActionsTypes, IssueStatuses } from 'types/enums'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        header: {
            display: 'flex',
            width: '100%',
            textAlign: 'left',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        title: {
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            height: 20,
            flexWrap: 'wrap',
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(1.5),
            fontSize: '1.25rem',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        tags: {
            display: 'inline-flex',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            marginBottom: theme.spacing(1),
        },
        subject: {
            display: 'block',
            marginBottom: theme.spacing(1),
            fontSize: '1rem',
            fontFamily: theme.typography.fontFamily,
            fontWeight: theme.typography.fontWeightRegular as number,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
        },
        openIcon: {
            width: 16,
            height: 16,
            marginLeft: theme.spacing(0.5),
            color: theme.palette.grey[400],
        },
        overdue: {
            marginRight: theme.spacing(1),
        },
    }),
    { name: 'TileHeader' }
)

export interface TileHeaderProps
    extends Partial<{
        id: number
        number: string | ReactNode
        issueStatusId: IssueStatuses
        actionCode: IssueActionsTypes
        subject: string
        showActionStatus: boolean
        isOverdue: boolean
        isHighImpact: boolean
        to?: string
    }> {}

export const TileHeader = (props: TileHeaderProps) => {
    const classes = useStyles()
    const {
        id,
        number,
        issueStatusId,
        actionCode,
        subject,
        isOverdue,
        isHighImpact,
        showActionStatus,
        to,
    } = props
    const wrapTags = useMediaQuery('(max-width:460px)')
    const href = to || `/issue/${id}`
    const handleOpenIssue = () => {
        appInsights.trackEvent(
            { name: 'Open issue from dashboard' },
            { issueId: id, issueNo: `IssueNumber - ${number}` }
        )
    }
    const statusTags = (
        <>
            {isHighImpact || isOverdue ? (
                <OverdueChipLabel
                    className={classes.overdue}
                    isHighImpact={isHighImpact}
                    isOverdue={isOverdue}
                    {...ISSUE_TILE_HEADER_ELEMENT.LABELS}
                />
            ) : null}
            {showActionStatus ? (
                <ActionChipLabel
                    actionCode={actionCode}
                    {...ISSUE_TILE_HEADER_ELEMENT.LABELS}
                />
            ) : (
                <StatusChipLabel
                    issueStatusId={issueStatusId}
                    {...ISSUE_TILE_HEADER_ELEMENT.LABELS}
                />
            )}
        </>
    )
    return (
        <Link
            to={href}
            style={{
                fontSize: 'inherit',
                textDecoration: 'inherit',
                color: 'inherit',
                fontWeight: 'inherit',
            }}
        >
            <ButtonBase className={classes.header} onClick={handleOpenIssue}>
                <div
                    className={classes.title}
                    {...ISSUE_TILE_HEADER_ELEMENT.NUMBER}
                >
                    {number}
                    <Launch className={classes.openIcon} />
                    <FlexGrow />
                    {!wrapTags && statusTags}
                </div>
            </ButtonBase>
            <div
                className={classes.subject}
                {...ISSUE_TILE_HEADER_ELEMENT.SUBJECT}
            >
                {subject}
            </div>
            {wrapTags && <div className={classes.tags}> {statusTags}</div>}
        </Link>
    )
}

TileHeader.defaultProps = {
    isOverdue: false,
    isHighImpact: false,
    showActionStatus: false,
}

export default TileHeader
