import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import {
    saveEffectivenessSectionDone,
    setIssueActionFail,
    ISSUE_SAVE_EFFECTIVENESS_CALL,
    updateIssueRowVersion,
    IssueStoreState,
} from '..'

import { RootState } from 'store/types'
import { putEffectivenessSection } from 'api'
import { showSnackbar } from 'store/app'

export function* saveEffectivenessSectionAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const { effectivenessSection, changeLog } = (yield select(
            (s: RootState) => s.issue
        )) as IssueStoreState
        const { data: newEffectivenessSection } = yield call(
            putEffectivenessSection,
            effectivenessSection,
            changeLog?.effectiveness ?? null,
            cancelSource.token
        )
        yield put(saveEffectivenessSectionDone(newEffectivenessSection))
        yield put(updateIssueRowVersion(newEffectivenessSection.rowVersion))
        yield put(
            showSnackbar(
                'Effectiveness and sustainability verification section was saved'
            )
        )
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_SAVE_EFFECTIVENESS_CALL, error))
        yield put(
            showSnackbar(
                "Effectiveness and sustainability verification section wasn't saved",
                true
            )
        )
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* saveEffectivenessSectionSaga() {
    yield takeLatest(
        [ISSUE_SAVE_EFFECTIVENESS_CALL],
        saveEffectivenessSectionAction
    )
}
