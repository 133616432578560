import axios from 'axios'
import { call, cancelled, takeLatest, put, select } from 'redux-saga/effects'
import { deleteDraftDone, ISSUE_DELETE_CALL, setIssueActionFail } from '..'
import { IssueActionsTypes } from 'types/enums'
import { RootState } from 'store/types'
import { deleteDraft } from 'store/../api'
import { removeMyActionById } from 'store/myActions'
import { removeMyIssuesById } from 'store/myIssues'
import { replace } from 'connected-react-router'
import { showSnackbar } from 'store/app'
import { DASHBOARD } from 'routes'

export function* deleteDraftAction() {
    const cancelSource = axios.CancelToken.source()
    try {
        const issueId: number = yield select((s: RootState) => s.issue.issueId)
        yield call(deleteDraft, issueId, cancelSource.token)
        yield put(removeMyActionById(issueId, IssueActionsTypes.To_Submit))
        yield put(removeMyIssuesById(issueId))
        yield put(replace(DASHBOARD))
        yield put(showSnackbar('Draft deleted!', false))
        yield put(deleteDraftDone())
    } catch (error) {
        yield put(showSnackbar('Draft not delete!', true))
        yield put(setIssueActionFail(ISSUE_DELETE_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* deleteDraftSaga() {
    yield takeLatest([ISSUE_DELETE_CALL], deleteDraftAction)
}
