import { default as ServerDown } from './svg/ServerDown.svg'

export interface ServerDownSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const ServerDownSvg = (props: ServerDownSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? ServerDown}
            width={width}
            height={height}
            alt={alt ?? 'Server error'}
            {...other}
        />
    )
}

export default ServerDownSvg
