import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { EditorState } from 'draft-js'
import { AbbTheme } from 'styles/createAbbTheme'
import { TextColorInlineStyles } from '../types'
import { getAbbColor } from '../utils'
import { ColorButton } from './ColorButton'

export interface ColorConfig {
    name: TextColorInlineStyles
    color: string
    label: string
}

export interface ColorPalleteProps {
    editorState: EditorState
    onColorSelect: (colorInlineStyle: TextColorInlineStyles) => void
    onClose?: () => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            boxSizing: 'border-box',
            width: 166,
            padding: theme.spacing(2),
        },
    }),
    { name: 'ColorPallete' }
)

export const getColorsList: () => ColorConfig[] = () => [
    { name: 'ABB_RED', color: getAbbColor('ABB_RED'), label: 'ABB RED' },
    { name: 'ABB_BLACK', color: getAbbColor('ABB_BLACK'), label: 'ABB BLACK' },
    { name: 'ABB_WHITE', color: getAbbColor('ABB_WHITE'), label: 'ABB WHITE' },
    {
        name: 'ABB_GRAY_01',
        color: getAbbColor('ABB_GRAY_01'),
        label: 'ABB GRAY 01',
    },
    {
        name: 'ABB_GRAY_02',
        color: getAbbColor('ABB_GRAY_02'),
        label: 'ABB GRAY 02',
    },
    {
        name: 'ABB_GRAY_03',
        color: getAbbColor('ABB_GRAY_03'),
        label: 'ABB GRAY 03',
    },
    {
        name: 'ABB_GRAY_04',
        color: getAbbColor('ABB_GRAY_04'),
        label: 'ABB GRAY 04',
    },
    {
        name: 'ABB_GRAY_05',
        color: getAbbColor('ABB_GRAY_05'),
        label: 'ABB GRAY 05',
    },
    {
        name: 'ABB_GRAY_06',
        color: getAbbColor('ABB_GRAY_06'),
        label: 'ABB GRAY 06',
    },
    { name: 'ABB_BLUE', color: '#004c97', label: getAbbColor('ABB_BLUE') },
    { name: 'ABB_GREEN', color: '#007a33', label: getAbbColor('ABB_GREEN') },
    { name: 'ABB_YELLOW', color: '#ffd100', label: getAbbColor('ABB_YELLOW') },
]

export const ColorPallete = (props: ColorPalleteProps) => {
    const { onColorSelect, onClose } = props
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>
                {getColorsList().map((c) => (
                    <Grid key={c.name} item lg={4} xl={4} xs={4} md={4} sm={4}>
                        <ColorButton
                            colorConfig={c}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() => {
                                onColorSelect(c.name)
                                onClose && onClose()
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}
