import { Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    DataArea,
    FieldChip,
    Label,
    useDataAreaStyles,
    ValueView,
} from 'components'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes, memo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { IssueTypes } from 'types/enums'
import { Customer, CustomerContact, RedCardInfoEntry } from 'types/issueTypes'
import { getArray } from 'utils'
import { FollowUpAction, ProductServiceGroup } from '.'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            marginBottom: theme.spacing(2),
        },
        dataArea: {
            marginBottom: theme.spacing(2),
        },
        chips: {
            marginBottom: theme.spacing(1),
        },
    }),
    {
        name: 'AdditionalProductInformation',
    }
)

export interface NpsAdditionalInformationProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    npsAdditionalInfo: RedCardInfoEntry
    customer?: Customer
    customerContact?: CustomerContact
    issueType: IssueTypes
}

export const NpsAdditionalInformation = memo(
    (props: NpsAdditionalInformationProps) => {
        const {
            className,
            npsAdditionalInfo,
            customer,
            customerContact,
            issueType,
            ...other
        } = props
        const classes = useStyles(props)
        const dataAreaClasses = useDataAreaStyles(props)
        const details = npsAdditionalInfo?.details
        const createDate = npsAdditionalInfo?.createDate
        const createDateFormated = dayjs(createDate)
            .utc()
            .endOf('day')
            .format('YYYY-MM-DD')
        const category = details?.category
        const comment = details?.comment
        const translation = details?.translation
        const autoTranslation = details?.autoTranslation
        const surveyContext = details?.tsSurveyContext
        const productClasses = getArray(details, 'productClass')
        const productServiceGroups = getArray(details, 'productServiceGroups')
        const followUps = getArray(details, 'followUps')
        const followUpDetails = getArray(details, 'followUpDetails')
        return (
            <div className={clsx(className, classes.root)} {...other}>
                <Typography variant="h6">
                    Additional information from NPS
                </Typography>
                <DataArea className={classes.dataArea}>
                    {createDate && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Creation date"
                            value={createDateFormated}
                        />
                    )}
                    {category && (
                        <ValueView
                            className={dataAreaClasses.cell}
                            label="Category"
                            value={category}
                        />
                    )}
                    {surveyContext && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Survey context"
                            value={surveyContext}
                            singleLine={false}
                        />
                    )}
                    {comment && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Comment"
                            value={comment}
                            singleLine={false}
                        />
                    )}
                    {translation && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Translation"
                            value={translation}
                            singleLine={false}
                        />
                    )}
                    {autoTranslation && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Auto translation"
                            value={autoTranslation}
                            singleLine={false}
                        />
                    )}
                    {customer?.name && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Customer"
                            value={customer?.name}
                            singleLine={false}
                        />
                    )}
                    {customerContact && (
                        <ValueView
                            className={dataAreaClasses.singleRow}
                            label="Customer contact"
                            value={`
                            ${customerContact?.firstName} ${customerContact?.lastName} (${customerContact?.email})  
                             `}
                            singleLine={false}
                        />
                    )}
                </DataArea>
                {productClasses && (
                    <>
                        <Label>Product class</Label>
                        <div className={classes.chips}>
                            {productClasses?.map((c, i) => (
                                <FieldChip
                                    key={i}
                                    hint={c.cid}
                                    label={c.name}
                                />
                            ))}
                        </div>
                    </>
                )}
                {productServiceGroups && (
                    <>
                        <Label>Products & Services</Label>
                        {productServiceGroups.map((g, i) => (
                            <ProductServiceGroup key={i} details={g} />
                        ))}
                    </>
                )}
                {followUps && (
                    <div>
                        <Label>Follow-up Actions</Label>
                        {followUps.map((f, i) => (
                            <Typography key={i}>{f}</Typography>
                        ))}
                    </div>
                )}
                {followUpDetails && (
                    <>
                        <Label>Follow-up Actions</Label>
                        {followUpDetails.map((f, i) => (
                            <FollowUpAction key={i} details={f} />
                        ))}
                    </>
                )}
            </div>
        )
    },
    (prev, next) => prev.npsAdditionalInfo === next.npsAdditionalInfo
)

export default NpsAdditionalInformation
