import { default as AccessDenied } from './svg/AccessDenied.svg'

export interface AccessDeniedSvgProps
    extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const AccessDeniedSvg = (props: AccessDeniedSvgProps) => {
    const { src, width = 256, height = 256, alt, ...other } = props

    return (
        <img
            src={src ?? AccessDenied}
            width={width}
            height={height}
            alt={alt ?? 'Access denied'}
            {...other}
        />
    )
}

export default AccessDeniedSvg
