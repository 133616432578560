import { ChangeEvent, useEffect, useState } from 'react'

import {
    Checkbox,
    Collapse,
    FormControlLabel,
    FormGroup,
    Typography,
    useTheme,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { KeyboardDatePicker } from 'components/Base'
import dayjs, { Dayjs } from 'dayjs'
import { useMinTargetDate } from 'hooks'
import { AbbTheme } from 'styles/createAbbTheme'
import {
    ActionsKeys,
    IssueTypes,
    IssueValuesKeys,
    TargetResolvingDateChangeReasons,
} from 'types/enums'
import { CaptureSection, ContainmentAction } from 'types/issueTypes'
import TextField from '../../components/Base/TextField'
import TargetResolvingDataChangeReasonSelect from '../../components/Fields/TargetResolvingDataChangeReasonSelect'
import { ISSUE_VIEW_PAGE } from '../../consts/selectorIds'
import { useFieldWithError, useIssueAction } from '../matrix'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        field: {
            marginTop: theme.spacing(1),
        },
        label: {
            display: 'block',
            transform: 'translate(0, 1.5px) scale(0.75) !important',
            transformOrigin: '0px 0px',
            ...theme.typography.body1,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
    }),
    {
        name: 'TargetDateAndContainmentActions',
    }
)

export interface TargetDateAndContainmentActionsProps
    extends React.HtmlHTMLAttributes<HTMLDivElement>,
        ContainmentAction,
        Partial<{
            lightMode?: boolean
            onCaptureSectionChanged?: (
                propName: keyof CaptureSection,
                value: any
            ) => any
            captureSection: CaptureSection
            oldTargetResolutionAndVerificationDate: string
            disabled: boolean
            issueType: IssueTypes
            [key: string]: any
        }> {}

const TargetDateAndContainmentActions = (
    props: TargetDateAndContainmentActionsProps
) => {
    const {
        disabled,
        lightMode,
        issueType = IssueTypes.External,
        onCaptureSectionChanged,
        captureSection,
        oldTargetResolutionAndVerificationDate,
        ...other
    } = props
    const theme = useTheme()
    const minTargetResolutionAndVerificationDate = useMinTargetDate(5)
    const minContainmentActionDeadlineDate = useMinTargetDate(1)

    const [
        targetResolutionDateChangeReason,
        setTargetResolutionDateChangeReason,
    ] = useState<string>()
    const [containmentActionDescription, setContainmentActionDescription] =
        useState('')
    const handleCaptureSectionChanged = (
        key: keyof CaptureSection,
        value: any
    ) => onCaptureSectionChanged && onCaptureSectionChanged(key, value)
    const containmentActionDescriptionProp =
        captureSection?.containmentActionDescription

    useEffect(() => {
        setContainmentActionDescription(containmentActionDescriptionProp ?? '')
    }, [containmentActionDescriptionProp])
    const classes = useStyles(props)
    const targetResolutionAndVerificationDateField = useFieldWithError(
        IssueValuesKeys.TargetResolutionAndVerificationDate,
        {
            defaultHelperText:
                captureSection?.targetResolutionAndVerificationDate &&
                `Your local time: ${dayjs(
                    captureSection?.targetResolutionAndVerificationDate
                )
                    .utc()
                    .endOf('day')
                    .local()
                    .format('YYYY-MM-DD • HH:mm')}`,
            disabled,
        }
    )
    const containmentActionDescriptionField = useFieldWithError(
        IssueValuesKeys.ContainmentActionDescription,
        { disabled }
    )
    const deadlineDateField = useFieldWithError(
        IssueValuesKeys.ContainmentActionDeadlineDate,
        { disabled }
    )
    const isContainmentActionRequiredField = useFieldWithError(
        IssueValuesKeys.IsContainmentActionRequired,
        { disabled }
    )
    const isRootCauseRequiredByCustomerField = useFieldWithError(
        IssueValuesKeys.IsRootCauseRequiredByCustomer,
        { disabled }
    )
    const targetResolutionDateChangeReasonIdField = useFieldWithError(
        IssueValuesKeys.TargetResolutionDateChangeReasonId,
        { disabled }
    )
    const targetResolutionDateChangeReasonField = useFieldWithError(
        IssueValuesKeys.TargetResolutionDateChangeReason,
        { disabled }
    )
    const { disabled: extendTargetResolutionDateAction } = useIssueAction(
        ActionsKeys.ExtendTargetResolutionDate
    )

    const handleCaptureSectionDateChanged = (
        key: keyof CaptureSection,
        date: Dayjs
    ) =>
        handleCaptureSectionChanged(
            key,
            date.endOf('day').isValid ? date.endOf('day') : null
        )
    const isTargetResolutionAndVerificationDateChanged = dayjs(
        captureSection?.targetResolutionAndVerificationDate
    )
        .utc()
        .endOf('day')
        .isSame(
            dayjs(oldTargetResolutionAndVerificationDate).utc().endOf('day'),
            'day'
        )

    return (
        <div {...other}>
            <Typography variant="h6">
                Target date and containment actions
            </Typography>
            <KeyboardDatePicker
                className={classes.field}
                disablePast={true}
                label="Target resolution and verification date"
                minDate={minTargetResolutionAndVerificationDate}
                {...targetResolutionAndVerificationDateField}
                value={
                    captureSection?.targetResolutionAndVerificationDate
                        ? dayjs(
                              captureSection?.targetResolutionAndVerificationDate
                          ).utc()
                        : null
                }
                onChange={(date, value) => {
                    handleCaptureSectionDateChanged(
                        'targetResolutionAndVerificationDate',
                        date
                    )
                }}
                slotProps={{
                    textField: {
                        ...targetResolutionAndVerificationDateField,
                        required: true,
                        ...ISSUE_VIEW_PAGE.TARGET_DATE_AND_CONTAINMENT_ACTIONS
                            .TARGET_DATE_PICKER,
                    },
                }}
            />
            <Collapse
                in={
                    !extendTargetResolutionDateAction &&
                    !isTargetResolutionAndVerificationDateChanged
                }
            >
                <TargetResolvingDataChangeReasonSelect
                    selectedReason={
                        captureSection?.targetResolutionDateChangeReasonId
                    }
                    {...targetResolutionDateChangeReasonIdField}
                    required
                    className={classes.field}
                    onReasonChange={(e) =>
                        handleCaptureSectionChanged(
                            'targetResolutionDateChangeReasonId',
                            e
                        )
                    }
                    {...ISSUE_VIEW_PAGE.TARGET_DATE_AND_CONTAINMENT_ACTIONS
                        .TARGET_DATE_CHANGE_REASON_SELECT}
                />
                <Collapse
                    in={
                        captureSection?.targetResolutionDateChangeReasonId ===
                        TargetResolvingDateChangeReasons.Other
                    }
                    style={{ marginBottom: theme.spacing(2) }}
                >
                    <TextField
                        {...targetResolutionDateChangeReasonField}
                        variant="filled"
                        label="Reason description"
                        style={{ marginTop: theme.spacing(2) }}
                        value={targetResolutionDateChangeReason}
                        onChange={(e: any) =>
                            setTargetResolutionDateChangeReason(e.target.value)
                        }
                        onBlur={() =>
                            handleCaptureSectionChanged(
                                'targetResolutionDateChangeReason',
                                targetResolutionDateChangeReason
                            )
                        }
                        multiline
                        fullWidth
                        rows={4}
                        {...ISSUE_VIEW_PAGE.TARGET_DATE_AND_CONTAINMENT_ACTIONS
                            .TARGET_DATE_CHANGE_REASON_DESCRIPTION_TEXTFIELD}
                    />
                </Collapse>
            </Collapse>
            <Collapse in={!lightMode || issueType === IssueTypes.Internal_NC}>
                {issueType !== IssueTypes.Internal_NC && (
                    <FormGroup>
                        <FormControlLabel
                            {...ISSUE_VIEW_PAGE
                                .TARGET_DATE_AND_CONTAINMENT_ACTIONS
                                .RCA_REQUIRED_CHECKBOX}
                            checked={Boolean(
                                captureSection?.isRootCauseRequiredByCustomer
                            )}
                            disabled={
                                isRootCauseRequiredByCustomerField.disabled
                            }
                            onChange={(e: ChangeEvent<any>, checked) =>
                                handleCaptureSectionChanged(
                                    'isRootCauseRequiredByCustomer',
                                    checked
                                )
                            }
                            control={<Checkbox />}
                            label="Root Cause Analysis is required by Customer"
                        />
                        <FormControlLabel
                            {...ISSUE_VIEW_PAGE
                                .TARGET_DATE_AND_CONTAINMENT_ACTIONS
                                .CONTAINMENT_ACTIONS_CHECKBOX}
                            checked={Boolean(
                                captureSection?.isContainmentActionRequired
                            )}
                            disabled={isContainmentActionRequiredField.disabled}
                            control={<Checkbox />}
                            onChange={(e: ChangeEvent<any>, checked) => {
                                handleCaptureSectionChanged(
                                    'isContainmentActionRequired',
                                    checked
                                )
                                !checked &&
                                    handleCaptureSectionDateChanged(
                                        'containmentActionDeadlineDate',
                                        dayjs(null)
                                    )
                            }}
                            label="Containment Actions required"
                        />
                    </FormGroup>
                )}

                <Collapse
                    in={
                        Boolean(captureSection?.isContainmentActionRequired) ||
                        issueType === IssueTypes.Internal_NC
                    }
                >
                    <TextField
                        {...ISSUE_VIEW_PAGE.TARGET_DATE_AND_CONTAINMENT_ACTIONS
                            .REQUIRED_CONTAINMENT_ACTIONS_INPUT}
                        className={classes.field}
                        multiline
                        label="Required containment actions"
                        value={containmentActionDescription}
                        onChange={(e: any) => {
                            setContainmentActionDescription(
                                String(e.target.value)
                            )
                        }}
                        onBlur={() =>
                            handleCaptureSectionChanged(
                                'containmentActionDescription',
                                containmentActionDescription
                            )
                        }
                        {...containmentActionDescriptionField}
                    />
                    <KeyboardDatePicker
                        requiredField={true}
                        className={classes.field}
                        disablePast={true}
                        minDate={minContainmentActionDeadlineDate}
                        label="Containment actions deadline"
                        value={
                            captureSection?.containmentActionDeadlineDate &&
                            Boolean(
                                captureSection?.isContainmentActionRequired ||
                                    captureSection?.issueType ===
                                        IssueTypes.Internal_NC
                            )
                                ? dayjs(
                                      captureSection?.containmentActionDeadlineDate
                                  )
                                      .utc()
                                      .endOf('day')
                                : null
                        }
                        onChange={(date) => {
                            handleCaptureSectionDateChanged(
                                'containmentActionDeadlineDate',
                                date
                            )
                        }}
                        {...deadlineDateField}
                        slotProps={{
                            textField: {
                                ...deadlineDateField,
                                ...ISSUE_VIEW_PAGE
                                    .TARGET_DATE_AND_CONTAINMENT_ACTIONS
                                    .CONTAINMENT_ACTIONS_DEADLINE_PICKER,
                            },
                        }}
                    />
                    {captureSection?.containmentActionConfirmationDate && (
                        <KeyboardDatePicker
                            className={classes.field}
                            disabled
                            label="Containment actions executed on"
                            value={
                                captureSection?.containmentActionConfirmationDate
                                    ? dayjs(
                                          captureSection?.containmentActionConfirmationDate
                                      )
                                          .utc()
                                          .endOf('day')
                                    : null
                            }
                            onChange={() => {}}
                        />
                    )}
                </Collapse>
            </Collapse>
        </div>
    )
}

TargetDateAndContainmentActions.defaultProps = {
    lightMode: false,
    disabled: false,
}

export default TargetDateAndContainmentActions
