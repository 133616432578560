import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { AbbTheme } from 'styles/createAbbTheme'
import { ProcessFlowViewProps, useIssueProcessFlow } from '.'
import { TimelineConnector, TimelineDot, TimelineState } from '../../components'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            position: 'relative',
        },
        content: { marginBottom: theme.spacing(1) },
        step: {
            display: 'flex',
            width: '100%',
            minHeight: 48,
        },
        stepHeader: {
            display: 'flex',
            minHeight: 20,
            flexWrap: 'wrap',
            ...theme.typography.body2,
            fontWeight: theme.typography.fontWeightMedium as number,
        },
        timeline: {
            display: 'flex',
            flexDirection: 'column',
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
            alignItems: 'center',
            width: 32,
        },
        details: {
            ...theme.typography.body2,
            color: theme.palette.grey[400],
        },
    }),
    { name: 'VerticalProcessFlowView' }
)

export const VerticalProcessFlowView = (props: ProcessFlowViewProps) => {
    const {
        className: classNameProp,
        highImpact,
        issueStatus,
        issueType,
        solutionWillBeValidatedByCoordinator,
        isWaitingForExecution,
        dates,
        ...other
    } = props
    const classes = useStyles(props)
    const [processFlow, currentStep] = useIssueProcessFlow(
        issueStatus,
        highImpact,
        solutionWillBeValidatedByCoordinator,
        issueType,
        isWaitingForExecution,
        dates
    )
    const stepsCount = processFlow.length
    const getConnectorState = (
        step: number,
        currentStep: number
    ): TimelineState => {
        switch (true) {
            case step < currentStep - 1:
                return 'done'
            case step === currentStep - 1:
                return 'inprocess'
            default:
                return 'waiting'
        }
    }
    return (
        <div className={clsx(classes.root, classNameProp)} {...other}>
            {processFlow.map((s, i, arr) => (
                <div key={s.order} className={classes.step}>
                    <div className={classes.timeline}>
                        <TimelineDot
                            radius={12}
                            state={
                                s.finished
                                    ? 'done'
                                    : s.order === currentStep
                                    ? 'inprocess'
                                    : 'waiting'
                            }
                        />
                        {s.order !== stepsCount && (
                            <TimelineConnector
                                orientation="vertical"
                                state={getConnectorState(s.order, currentStep)}
                            />
                        )}
                    </div>
                    <div className={classes.content}>
                        <div className={classes.stepHeader}>{s.statusName}</div>
                        <div className={classes.details}>{s?.roleName}</div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default VerticalProcessFlowView
