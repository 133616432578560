import { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { SurveyTranslationContext, SurveyTranslationContextProps } from '..'
import { AbbTypography } from 'components'

export const SuccessfullySubscribedSurvey = () => {
    const { t } = useContext<SurveyTranslationContextProps>(
        SurveyTranslationContext
    )
    return (
        <>
            <Helmet>
                <title>
                    {t(
                        'Survey_Subscribe_Success',
                        'You have been successfully subscribed. Thank you for subscribing.'
                    )}
                </title>
            </Helmet>
            <AbbTypography variant="h2">
                {t(
                    'Survey_Subscribe_Success',
                    'You have been successfully subscribed. Thank you for subscribing.'
                )}
            </AbbTypography>
        </>
    )
}
