import { FormHelperText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import uniqueId from 'lodash/uniqueId'
import { AllHTMLAttributes, useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { AttachmentTypes } from 'types/enums'
import { Attachment } from 'types/issueTypes'
import { FileDownloadAll } from '.'
import AttachmentsView from './AttachmentsView'
import FileButton from './FileButton'
import FileUploadList from './FileUploadList'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            flexDirection: 'column',
        },
        actions: {
            paddingTop: theme.spacing(0.5),
            borderTop: `1px solid ${theme.palette.divider}`,
        },
        error: {
            marginBottom: theme.spacing(1),
        },
        buttons: {
            display: 'flex',
        },
    }),
    { name: 'FileManager' }
)

export interface FileManagerProps extends AllHTMLAttributes<HTMLDivElement> {
    issueId: number
    attachmentType: AttachmentTypes
    attachments: Array<Attachment>
    disabled: boolean
    required: boolean
    helperText: string
    error: boolean
    label?: string
    onFileUploaded?: (attachment: Attachment, id: string) => void
    onFileRemove?: (attachment: Attachment) => void
}

export interface AttachmentUploadDto {
    id: string
    uploading: boolean
    file: File
}

export const FileManager = (props: FileManagerProps) => {
    const {
        className,
        issueId,
        attachmentType,
        attachments,
        disabled,
        required,
        helperText,
        error,
        label,
        onFileUploaded,
        onFileRemove,
        ...other
    } = props
    const [uploadFilesList, setUploadFilesList] = useState<
        Array<AttachmentUploadDto>
    >([])
    const classes = useStyles()
    const handleFileUploaded = (attachment: Attachment, id: string) => {
        setUploadFilesList((files) => files.filter((file) => file.id !== id))
        onFileUploaded && onFileUploaded(attachment, id)
    }

    const handleFileRemove = (attachment: Attachment) => {
        onFileRemove && onFileRemove({ ...attachment })
    }

    const onFileRemoveFromPending = (id: string) => {
        setUploadFilesList((files) => [
            ...files.filter((file: AttachmentUploadDto) => {
                return file.id !== id
            }),
        ])
    }
    return (
        <div className={clsx(className, classes.root)} id={props.id} {...other}>
            <AttachmentsView
                loading={false}
                label={label}
                attachments={attachments}
                onFileRemove={handleFileRemove}
                disabled={disabled}
                required={required}
            />
            <FileUploadList
                files={uploadFilesList}
                issueId={issueId}
                attachmentType={attachmentType}
                onFileUploaded={handleFileUploaded}
                onFileRemoveFromPending={onFileRemoveFromPending}
            />
            <FormHelperText className={classes.error} error={error}>
                {helperText}
            </FormHelperText>
            <div className={classes.actions}>
                <div className={classes.buttons}>
                    {!disabled && (
                        <FileButton
                            onFilesSelected={(files) => {
                                setUploadFilesList((prev) => [
                                    ...prev,
                                    ...files.map(
                                        (file: File): AttachmentUploadDto => ({
                                            id: uniqueId(),
                                            uploading: false,
                                            file,
                                        })
                                    ),
                                ])
                            }}
                            multiple={true}
                        />
                    )}
                    {attachments.length > 1 && (
                        <FileDownloadAll
                            attachmentType={attachmentType}
                            issueId={issueId}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

FileManager.defaultProps = {
    disabled: true,
    required: false,
    error: false,
    helperText: null,
}

export default FileManager
