import { InfoOutlined } from '@mui/icons-material'
import { CircularProgress, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { HtmlHTMLAttributes, ReactNode, useState } from 'react'

export interface StartAdornmentInfoProps
    extends HtmlHTMLAttributes<HTMLDivElement> {
    loading?: boolean
    onLoadData?: () => void
    details: ReactNode
}

const useStyles = makeStyles(
    () => ({
        root: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    { name: 'StartAdornmentInfo' }
)

export const StartAdornmentInfo = (props: StartAdornmentInfoProps) => {
    const { className, details, onLoadData, loading = false, ...other } = props
    const [open, setOpen] = useState(false)
    const classes = useStyles()
    return (
        <Tooltip
            title={details}
            onMouseEnter={() => {
                onLoadData && onLoadData()
                details && setOpen(true)
            }}
            PopperProps={{
                style: { pointerEvents: 'auto' },
                onMouseEnter: () => details && setOpen(true),
                onMouseLeave: () => setOpen(false),
                onClick: (e) => {
                    e.stopPropagation()
                },
                open: open,
            }}
        >
            <div
                className={clsx(className, classes.root)}
                {...other}
                onMouseLeave={() => {
                    setOpen(false)
                }}
            >
                {loading ? (
                    <CircularProgress size={20} color="secondary" />
                ) : (
                    <InfoOutlined fontSize="small" />
                )}
            </div>
        </Tooltip>
    )
}
