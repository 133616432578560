import { Pagination } from '@mui/lab'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    CircularProgressLoader,
    NoResults,
    SearchResultsHeader,
    SearchResultsHeaderProps,
} from 'components'
import { HtmlHTMLAttributes, ReactNode } from 'react'

import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            overflow: 'hidden',
        },
        pagination: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        results: {},
    }),
    {
        name: 'SearchResultsWithPagination',
    }
)

export interface SearchResultsWithPaginationProps
    extends HtmlHTMLAttributes<HTMLDivElement>,
        SearchResultsHeaderProps {
    page: number
    onPageChange: (page: number) => void
    pageSize: number
    renderAction?: () => ReactNode
    errorTitle?: string
    errorSubTitle?: string
    errorVisible: boolean
}

export const SearchResultsWithPagination = (
    props: SearchResultsWithPaginationProps
) => {
    const {
        children,
        itemCount,
        isSearching,
        page,
        onPageChange,
        pageSize,
        renderAction,
        className,
        errorVisible,
        errorTitle = '',
        errorSubTitle = '',
        title,
        ...other
    } = props

    const classes = useStyles()
    const totalPages = Math.ceil(itemCount / pageSize)

    const renderResults = () =>
        itemCount > 0 && (
            <>
                <SearchResultsHeader
                    title={title}
                    isSearching={isSearching}
                    itemCount={itemCount}
                    renderAction={renderAction}
                />
                <div className={classes.results}>{children}</div>
                {pageSize < itemCount && (
                    <Pagination
                        className={classes.pagination}
                        count={totalPages}
                        hidePrevButton={page === 1}
                        hideNextButton={page === totalPages}
                        onChange={(_, page) => onPageChange(page)}
                        page={page}
                        size="large"
                        color="secondary"
                    />
                )}
            </>
        )

    return (
        <div className={clsx(classes.root, className)} {...other}>
            {isSearching ? (
                <CircularProgressLoader />
            ) : errorVisible ? (
                <NoResults title={errorTitle} subTitle={errorSubTitle} />
            ) : (
                renderResults()
            )}
        </div>
    )
}
