import { Route, Switch } from 'react-router-dom'
import IssueViewBar from './AppBars/IssueViewBar'
import LinkIssueBar from './AppBars/LinkIssueBar'
import NewIssueBar from './AppBars/CreateDraftAppBar'
import * as ROUTES from './routes'

export const IssueAppBarRoot = () => (
    <Switch>
        <Route path={ROUTES.ISSUE_NEW} component={NewIssueBar} exact />
        <Route path={ROUTES.ISSUE_VIEW} component={IssueViewBar} exact />
        <Route
            path={[ROUTES.ISSUE_LINK_ISSUE, ROUTES.ISSUE_LINK_ISSUE_PREVIEW]}
            component={LinkIssueBar}
            exact
        />
        <Route
            path={[
                ROUTES.ISSUE_NEW_RESOLVING_UNIT,
                ROUTES.ISSUE_VIEW_RESOLVING_UNIT,
                ROUTES.ISSUE_NEW_CUSTOMER_NAME,
                ROUTES.ISSUE_VIEW_CUSTOMER_NAME,
                ROUTES.ISSUE_NEW_SUPPLIER,
                ROUTES.ISSUE_VIEW_SUPPLIER,
                ROUTES.ISSUE_NEW_ABB_PRODUCT,
                ROUTES.ISSUE_VIEW_ABB_PRODUCT,
                ROUTES.ISSUE_NEW_CUSTOMER_CONTACT_NAME,
                ROUTES.ISSUE_VIEW_CUSTOMER_CONTACT_NAME,
                ROUTES.ISSUE_NEW_FAILURE_MODE,
                ROUTES.ISSUE_VIEW_FAILURE_MODE,
                ROUTES.ISSUE_VIEW_ACTION_LOGS,
            ]}
            render={() => null}
            exact
        />
        <Route />
    </Switch>
)

export default IssueAppBarRoot
