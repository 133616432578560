import Help from '@mui/icons-material/Help'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { useState } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { HELP_TIP } from '../../consts/selectorIds'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        icon: {
            height: 18,
            width: 18,
            color: theme.palette.grey[400],
        },
        tooltip: {
            padding: theme.spacing(2),
        },
    }),
    { name: 'HelpTip' }
)

export interface HelpTipProps extends IconButtonProps {
    title: string
    icon?: React.ReactNode
}

export const HelpTip = (props: HelpTipProps) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const classes = useStyles()
    const { title, icon, onClick, ...other } = props
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (onClick) {
            onClick(event)
        }
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (event) => {
        event.stopPropagation()
        setAnchorEl(null)
    }
    const open = Boolean(anchorEl)
    return (
        <>
            <IconButton {...other} onClick={handleClick} size="large">
                {icon ? (
                    icon
                ) : (
                    <Help className={classes.icon} {...HELP_TIP.BUTTON} />
                )}
            </IconButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                {...HELP_TIP.POPUP}
            >
                <Typography
                    className={classes.tooltip}
                    {...HELP_TIP.POPUP_MESSAGE}
                >
                    {title}
                </Typography>
            </Popover>
        </>
    )
}

export default HelpTip
