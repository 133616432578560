import {
    DateRangeRounded,
    LocalOfferRounded,
    PersonRounded,
} from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import ActionPlanActions from 'ActionPlan/components/ActionPlanActions'
import clsx from 'clsx'
import { CardView, CardViewResults } from 'components'
import { useResolvingUnitAndResolutionOwner } from 'hooks'
import { useMemo } from 'react'
import { humanizeDateTime } from 'utils/formaters'
import { DashboardItemProps } from '.'
import { ActionPlanStatusChip } from '../components'

const useStyles = makeStyles(
    () => ({
        root: {
            opacity: 1,
            '&$dragging': {
                opacity: 0.6,
            },
        },
        dragging: {},
    }),
    {
        name: 'DashboardItem',
    }
)

export const DashboardItem = (props: DashboardItemProps) => {
    const {
        item,
        onClickLabel,
        onMouseMoveLabel,
        onMouseDownLabel,
        isDragging,
    } = props
    const classes = useStyles(props)
    const { actionStatus, issueNumber } = item

    const results = useMemo<CardViewResults>(() => {
        const { actionCreator, actionOwner, actionType, actionDeadline } = item
        const actionTypeName = actionType.name

        return [
            {
                label: 'Action type',
                renderIcon: (props) => <LocalOfferRounded {...props} />,
                text: actionTypeName,
                fullWidth: true,
            },
            {
                label: 'Action creator',
                renderIcon: (props) => <PersonRounded {...props} />,
                text: actionCreator
                    ? `${actionCreator.firstName} ${actionCreator.lastName}`
                    : '',
                tooltip: actionCreator.email,
            },
            {
                label: 'Action owner',
                renderIcon: (props) => <PersonRounded {...props} />,
                text: actionOwner
                    ? `${actionOwner.firstName} ${actionOwner.lastName}`
                    : '',
                tooltip: actionOwner?.email,
            },
            {
                label: 'Action deadline',
                renderIcon: (props) => <DateRangeRounded {...props} />,
                text: humanizeDateTime(actionDeadline),
                fullWidth: true,
            },
        ]
    }, [])

    const collapsedResults = useResolvingUnitAndResolutionOwner(item, true)

    return (
        <CardView
            className={clsx(classes.root, isDragging && classes.dragging)}
            onMouseMoveLabel={onMouseMoveLabel}
            onClickLabel={() => onClickLabel(item)}
            onMouseDownLabel={onMouseDownLabel}
            results={results}
            collapsedResults={collapsedResults}
            title={item.actionName}
            subTitle={issueNumber}
            renderTag={() => <ActionPlanStatusChip status={actionStatus} />}
            renderActions={({ expanded, toggleExpanded }) => (
                <ActionPlanActions
                    expanded={expanded}
                    toggleExpanded={toggleExpanded}
                    item={item}
                    disabledSave
                />
            )}
        />
    )
}

export default DashboardItem
