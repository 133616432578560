import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ActionsView, TileContainer } from 'components'
import { appInsights } from 'configs/appInsights'
import dayjs from 'dayjs'
import { ReactNode, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import theme from 'styles/themeBase'
import { IssueStatuses } from 'types/enums'
import { MyAction2, MyIssue2 } from 'types/myDashboard'
import { dataTestId } from 'utils'
import { getResolutionUnitName } from 'utils/formaters'
import {
    ActionCountdownTimer,
    AssignedUsers,
    IssueInformation,
    TargetDate,
    TileDate,
    TileHeader,
} from '.'

export interface MyIssueItemProps {
    data: MyAction2 | MyIssue2
    renderActions?: () => ReactNode
    canExtendTargetResolutionDate?: boolean
    onExtendTargetResolutionDate?: (date: string) => void
    onOpenIssue?: () => void
}

export type RemainingTimeColor = Array<string | boolean>

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        progresBar: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            [theme.breakpoints.down(770)]: {
                alignItems: 'center',
            },
        },
        border: {
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        hover: {
            '&:hover': {
                cursor: 'pointer',
            },
            flexGrow: 1,
        },
        header: {
            display: 'flex',
            padding: '9px 16px',
            [theme.breakpoints.down(600)]: {
                flexWrap: 'wrap',
                justifyContent: 'center',
            },
        },
    }),
    { name: 'MyIssueItem' }
)

export const MyIssueItem = (props: MyIssueItemProps) => {
    const {
        data,
        renderActions,
        canExtendTargetResolutionDate = false,
        onExtendTargetResolutionDate,
        onOpenIssue,
    } = props

    const classes = useStyles()

    const getResolvingUnit = useMemo(
        () => getResolutionUnitName(data?.resolvingUnit),
        [data?.resolvingUnit]
    )

    const getFailureMode = useMemo(
        () => data?.failureModeTree?.join('/')?.split('/'),
        [data?.failureModeTree]
    )

    const getLastFailureMode = useMemo(
        () =>
            getFailureMode ? getFailureMode[getFailureMode?.length - 1] : null,
        [getFailureMode]
    )

    const labelTime = useMemo<RemainingTimeColor>(() => {
        if (data?.issueStatusId === IssueStatuses.Not_Assigned)
            return [dayjs(data?.assignProgressDate).utc().format(), true]

        if (
            [
                IssueStatuses.Resolved,
                IssueStatuses.Completed_Execution_Pending,
            ].includes(data?.issueStatusId)
        )
            return [dayjs(data?.completeProgressDate).utc().format(), true]
        else
            return [
                dayjs(data?.dates?.targetResolutionAndVerificationDate)
                    .utc()
                    .format(),
                false,
            ]
    }, [
        data?.dates,
        data?.issueStatusId,
        data?.assignProgressDate,
        data?.completeProgressDate,
    ])

    return (
        <TileContainer
            {...dataTestId(
                `DASHBOARD_ISSUE_TILE_CONTAINER_${data.issueNumber}`
            )}
        >
            <div className={clsx(classes.header)}>
                <div
                    className={clsx(classes.hover)}
                    onClick={() => {
                        onOpenIssue && onOpenIssue()
                        appInsights.trackEvent(
                            { name: 'Open issue from dashboard' },
                            {
                                issueId: data.issueId,
                                issueNo: `IssueNumber - ${data.issueNumber}`,
                            }
                        )
                    }}
                >
                    <TileHeader
                        issueNo={data.issueNumber}
                        issueStatus={data.issueStatusId}
                        issueIsHighImpact={data.isHighImpact}
                        issueVarification={data.isVerificationRequired}
                        issueRcaVariant={data.quickFixVariant}
                        issueSubject={data.subject}
                        issueTargetDate={labelTime}
                    />
                    <TileDate
                        dates={data.dates}
                        {...dataTestId(`ISSUE_TILE_DATES`)}
                    />
                </div>
                <div>
                    {data.issueStatusId !== IssueStatuses.Draft && (
                        <TargetDate
                            date={
                                data.dates.targetResolutionAndVerificationDate
                            }
                            style={{ marginLeft: 'auto' }}
                            canExtendTargetResolutionDate={
                                canExtendTargetResolutionDate
                            }
                            extendTargetDate={() =>
                                onExtendTargetResolutionDate(
                                    data.dates
                                        .targetResolutionAndVerificationDate
                                )
                            }
                            issueStatus={data.issueStatusId}
                        />
                    )}
                </div>
            </div>
            <div className={clsx(classes.border)} />
            <Grid
                container
                style={{
                    padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,
                }}
            >
                <Grid item xs={12} sm={9}>
                    <IssueInformation
                        resolvingUnit={getResolvingUnit}
                        issueType={data.issueTypeId}
                        product={data.productName}
                        affectedCustomer={data.customerName}
                        failureMode={getLastFailureMode}
                    />
                </Grid>
                {![
                    IssueStatuses.Draft,
                    IssueStatuses.Completed,
                    IssueStatuses.Completed_Execution_Pending,
                ].includes(data?.issueStatusId) && (
                    <Grid
                        className={clsx(classes.progresBar)}
                        item
                        xs={12}
                        sm={3}
                    >
                        <ActionCountdownTimer
                            labelStatus={data?.issueStatusId}
                            date={data?.dates}
                            progressBar={true}
                            labelTime={labelTime}
                            assignProgressDate={data.assignProgressDate}
                            completeProgressDate={data.completeProgressDate}
                        />

                        {[
                            IssueStatuses.In_Process,
                            IssueStatuses.In_Process_Overdue,
                        ].includes(data?.issueStatusId) && (
                            <ActionCountdownTimer
                                labelStatus={data?.issueStatusId}
                                labelResolution="Resolution"
                                resolutionProgress={data}
                                labelTime={labelTime}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
            <div className={clsx(classes.border)} />
            <Grid
                container
                style={{
                    padding: theme.spacing(0, 1.5),
                }}
            >
                <AssignedUsers
                    issueOwner={data.issueOwners}
                    resolutionOwner={data.resolutionOwner}
                    coordinators={data.issueCoordinators}
                />
            </Grid>
            {renderActions && (
                <>
                    <div className={clsx(classes.border)} />
                    <ActionsView style={{ marginRight: theme.spacing(2) }}>
                        {renderActions()}
                    </ActionsView>
                </>
            )}
        </TileContainer>
    )
}
