import { PersonAdd } from '@mui/icons-material'
import { IconButton, IconButtonProps, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'

const useStyles = makeStyles<Theme>(
    (theme) => ({
        root: {
            marginBottom: theme.spacing(1),
        },
        icon: {
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
        },
    }),
    { name: 'AddPersonButton' }
)

export const AddPersonButton = (props: IconButtonProps) => {
    const { onClick, className, ...other } = props
    const classes = useStyles()
    return (
        <IconButton
            className={clsx(className, classes.root)}
            size="small"
            onClick={onClick}
            {...other}
        >
            <PersonAdd className={classes.icon} />
        </IconButton>
    )
}
