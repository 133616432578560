import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { reopenIssue } from 'api'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import {
    removeMyActionById,
    reopenIssueDone,
    reopenIssueFail,
} from '../actions'
import { MY_ACTIONS_REOPEN_ISSUE_CALL } from '../actionTypes'
import { ReopenIssueCommand } from '../types'

export function* reopenIssueSaga() {
    yield takeLatest(
        MY_ACTIONS_REOPEN_ISSUE_CALL,
        function* (action: ReopenIssueCommand) {
            const cancelSource = axios.CancelToken.source()
            try {
                yield call(reopenIssue, action.payload, cancelSource.token)
                yield put(reopenIssueDone())
                yield put(
                    removeMyActionById(
                        action.payload.issueId,
                        IssueActionsTypes.Effectiveness_Sustainability_Check
                    )
                )
            } catch (error) {
                yield put(reopenIssueFail(error))
                yield put(showSnackbar('Reopen issue fail', true))
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
