import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
    loadAttachmentsDone,
    setIssueActionFail,
    ISSUE_ATTACHMENTS_CALL,
} from '..'
import { getAttachments } from 'api'
import { showSnackbar } from 'store/app'
import { LoadAttachmentsCommand } from '../types'

export function* loadAttachmentsAction(action: LoadAttachmentsCommand) {
    const cancelSource = axios.CancelToken.source()
    const issueId = action?.payload?.issueId
    try {
        const { data } = yield call(
            getAttachments,
            issueId,
            null,
            cancelSource.token
        )
        yield put(loadAttachmentsDone(data))
    } catch (error) {
        yield put(showSnackbar('Loading attachments fail', true))
        yield put(setIssueActionFail(ISSUE_ATTACHMENTS_CALL, error))
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* loadAttachmentsSaga() {
    yield takeLatest([ISSUE_ATTACHMENTS_CALL], loadAttachmentsAction)
}
