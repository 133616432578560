import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes, useMemo } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import theme from 'styles/themeBase'
import { IssueStatuses, QuickFixVariants } from 'types/enums'
import { Dates } from 'types/issueTypes'
import { MyAction2, MyIssue2 } from 'types/myDashboard'
import { dataTestId } from 'utils'
import { RemainingTimeColor } from './MyIssueItem'
import MyIssueStatusChipLabel from './MyIssueStatusChipLabel'
import { useRemainingTimeColor } from './utils'

interface ActionCountdownTimerProps extends HtmlHTMLAttributes<HTMLDivElement> {
    labelStatus: IssueStatuses
    date?: Dates
    assignProgressDate?: string
    completeProgressDate?: string
    labelResolution?: string
    progressBar?: boolean
    resolutionProgress?: MyAction2 | MyIssue2
    labelTime: RemainingTimeColor
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'block',
            position: 'relative',
            width: 'auto',
            maxWidth: 300,
            minWidth: 200,
            height: 20,
            backgroundColor: theme.palette.grey[100],
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: theme.shape.borderRadius,
            fontSize: theme.typography.caption.fontSize,
            fontWeight: 800,
            color: theme.palette.grey[50],
        },
        fillBar: {
            boxSizing: 'border-box',
            position: 'absolute',
            width: '20%',
            maxWidth: '100%',
            height: '100%',
            borderRadius: theme.shape.borderRadius,
        },
        labelContainer: {
            boxSizing: 'border-box',
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 10px',
        },
    }),
    { name: 'ActionCountdownTimer' }
)

export const ActionCountdownTimer = (props: ActionCountdownTimerProps) => {
    const {
        date,
        labelStatus,
        labelResolution,
        progressBar = false,
        resolutionProgress,
        assignProgressDate,
        completeProgressDate,
        labelTime,
        ...other
    } = props
    const classes = useStyles()

    const [remainingTime] = useMemo<RemainingTimeColor>(() => {
        if (labelStatus === IssueStatuses.Not_Assigned)
            return useRemainingTimeColor([
                dayjs(assignProgressDate).utc().format(),
                true,
            ])

        if (
            [
                IssueStatuses.Resolved,
                IssueStatuses.Completed_Execution_Pending,
            ].includes(labelStatus)
        )
            return useRemainingTimeColor([
                dayjs(completeProgressDate).utc().format(),
                true,
            ])
        else
            return useRemainingTimeColor([
                dayjs(date?.targetResolutionAndVerificationDate).utc().format(),
                false,
            ])
    }, [labelStatus, date])

    const calcFillBarBusinessDays = (fromDate: string, toDate: string) => {
        const date1 =
            dayjs().utc().businessMinutesDiff(dayjs(toDate).utc()) * 60000
        const date2 =
            dayjs(fromDate).utc().businessMinutesDiff(dayjs(toDate).utc()) *
            60000

        return +((date1 / date2) * 100).toFixed() < 0
            ? 100
            : ((date1 / date2) * 100).toFixed()
    }

    const progressBarFill = useMemo(() => {
        if (!date) return 0

        const duration = dayjs(date?.targetResolutionAndVerificationDate)
            .utc()
            .diff(dayjs().utc())

        const createDateDuration = dayjs(
            date?.targetResolutionAndVerificationDate
        )
            .utc()
            .diff(dayjs(date?.creationDate).utc())

        if (labelStatus === IssueStatuses.Not_Assigned)
            return calcFillBarBusinessDays(date?.submitDate, assignProgressDate)
        else if (
            [
                IssueStatuses.Resolved,
                IssueStatuses.Completed_Execution_Pending,
            ].includes(labelStatus)
        )
            return calcFillBarBusinessDays(
                date?.solutionSubmittedDate,
                completeProgressDate
            )
        else if (+((duration / createDateDuration) * 100).toFixed() < 0)
            return 100
        else return ((duration / createDateDuration) * 100).toFixed()
    }, [date])

    const label = useMemo(() => {
        if (labelStatus === 11 || labelStatus === 12) return 'Verify'
        if (labelStatus === 4 || labelStatus === 5) return 'Resolve'
        if (labelStatus === 7 || labelStatus === 13) return 'Complete'
        if (labelStatus === 3) return 'Assign'
    }, [labelStatus])

    const resolutionLabel = useMemo(() => {
        if (
            resolutionProgress?.isContainmentActionRequired &&
            (resolutionProgress?.isHighImpact ||
                [
                    QuickFixVariants.Create_RCA,
                    QuickFixVariants.Link_RCA,
                ].includes(resolutionProgress?.quickFixVariant))
        )
            return 4
        if (
            resolutionProgress?.isContainmentActionRequired ||
            resolutionProgress?.isHighImpact ||
            [QuickFixVariants.Create_RCA, QuickFixVariants.Link_RCA].includes(
                resolutionProgress?.quickFixVariant
            )
        )
            return 3
        else return 2
    }, [resolutionProgress])

    const resolutionProgressLabel = useMemo(() => {
        let counter = 0
        resolutionProgress?.whatContainmentActionsWereTaken && counter++

        resolutionProgress?.rcaAttachments.length > 0 &&
            ![QuickFixVariants.No_RCA].includes(
                resolutionProgress?.quickFixVariant
            ) &&
            counter++

        resolutionProgress?.whatWasTheRootCause && counter++

        resolutionProgress?.howDidWeResolveThisIssue && counter++

        return counter
    }, [resolutionProgress])

    const resolutionFill = useMemo(() => {
        return 100 / (resolutionLabel / resolutionProgressLabel)
    }, [resolutionLabel, resolutionProgressLabel])

    return (
        <div>
            <div className={clsx(classes.root)} {...other}>
                <MyIssueStatusChipLabel
                    className={clsx(classes.fillBar)}
                    date={labelTime}
                    issueStatusId={labelStatus}
                    style={{
                        width: `${
                            resolutionProgress
                                ? resolutionFill
                                : progressBarFill
                        }%`,
                        whiteSpace: 'nowrap',
                    }}
                    label={labelResolution}
                    isProgressBar={progressBar}
                    progressBarLabel={label}
                />

                <div className={clsx(classes.labelContainer)}>
                    <span
                        style={{ color: theme.palette.text.primary }}
                        {...dataTestId('ISSUE_TILE_ACTION_DATES')}
                    >
                        {date
                            ? remainingTime
                            : `${resolutionProgressLabel}/${resolutionLabel}`}
                    </span>
                </div>
            </div>
        </div>
    )
}
