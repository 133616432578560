import axios, { AxiosResponse } from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import {
    GenerateReportCommand,
    generateReportDone,
    setIssueActionFail,
    ISSUE_GENERATE_REPORT_CALL,
} from '..'

import FileSaver from 'file-saver'
import { generate8DRaport, generateDMAICRaport, generateRCATools } from 'api'
import { showSnackbar } from 'store/app'
import { getRcaReportTypeName } from 'utils/formaters'
import { RcaReportTypes } from 'types/enums'

export function* generateReportAction(action: GenerateReportCommand) {
    const { issueId, reportType } = action.payload
    const cancelSource = axios.CancelToken.source()
    try {
        let res = null
        let fileName = ''
        switch (reportType) {
            case RcaReportTypes.DMAIC:
                res = (yield call(
                    generateDMAICRaport,
                    issueId,
                    cancelSource.token
                )) as AxiosResponse<any>
                fileName = 'DMAIC-report.docx'
                break
            case RcaReportTypes.Report8D:
                res = (yield call(
                    generate8DRaport,
                    issueId,
                    cancelSource.token
                )) as AxiosResponse<any>
                fileName = '8D-report.docx'
                break
            case RcaReportTypes.RCA_Tools:
                res = (yield call(
                    generateRCATools,
                    issueId,
                    cancelSource.token
                )) as AxiosResponse<any>
                fileName = 'RCA_tools.xlsx'
                break
            default:
                yield put(setIssueActionFail(ISSUE_GENERATE_REPORT_CALL, {}))
                return
        }
        FileSaver.saveAs(res.data, fileName)
        yield put(generateReportDone())
    } catch (error) {
        yield put(setIssueActionFail(ISSUE_GENERATE_REPORT_CALL, error))
        yield put(
            showSnackbar(
                `Generate ${getRcaReportTypeName(reportType)} failed`,
                true
            )
        )
    } finally {
        if (yield cancelled()) {
            yield call(cancelSource.cancel, 'Abort')
        }
    }
}

export function* generateReportSaga() {
    yield takeLatest([ISSUE_GENERATE_REPORT_CALL], generateReportAction)
}
