import MuiSnackbar from '@mui/material/Snackbar'
import { withStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const Snackbar = withStyles((theme: AbbTheme) => ({
    anchorOriginBottomCenter: {
        minWidth: 320,
        bottom: theme.spacing(2),
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            left: '50%',
            right: 'auto',
            bottom: '0 !important',
            transform: 'translateX(-50%)',
        },
    },
}))(MuiSnackbar)

export default Snackbar
