import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { ListTitle } from 'components'
import { AbbTheme } from 'styles/createAbbTheme'
import { DashboardHeaderProps } from './types'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            width: '100%',
        },
        bar: {
            width: '100%',
            height: 4,
            margin: theme.spacing(2, 0),
        },
        Draft: { backgroundColor: theme.palette.grey[300] },
        InProgress: { backgroundColor: theme.palette.blue.main },
        Closed: { backgroundColor: theme.palette.green.main },
    }),
    { name: 'DashboardHeader' }
)

export const DashboardHeader = (props: DashboardHeaderProps) => {
    const { className, column, count, ...other } = props
    const { id, title } = column
    const classes = useStyles()

    return (
        <div className={clsx(className, classes.root)} {...other}>
            <ListTitle title={title} count={count} />
            <div className={clsx(classes.bar, classes[id])} />
        </div>
    )
}

export default DashboardHeader
