import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { HtmlHTMLAttributes } from 'react'
import { dataTestId } from 'utils'

import { DateValidationError } from '@mui/x-date-pickers'
import { KeyboardDatePicker } from 'components/Base'
import { AbbTheme } from 'styles/createAbbTheme'
const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        field: {
            width: `calc(50% - 8px)`,
        },
    }),
    { name: 'DateRangeSelect' }
)

export interface DateRangeSelectProps
    extends Omit<HtmlHTMLAttributes<HTMLDivElement>, 'onError'> {
    fromDate: string
    onFromDateChange: (date: string) => void
    toDate: string
    onToDateChange: (date: string) => void
    onError?: (error: DateValidationError, value: dayjs.Dayjs) => void
}

export const DateRangeSelect = (props: DateRangeSelectProps) => {
    const {
        className,
        fromDate,
        onFromDateChange,
        toDate,
        onToDateChange,
        onError,
        ...other
    } = props
    const classes = useStyles()

    return (
        <div className={clsx(classes.root)} {...other}>
            <KeyboardDatePicker
                className={classes.field}
                label="From"
                value={dayjs(fromDate)}
                slotProps={{
                    textField: { ...dataTestId('DATE_RANGE_FROM') },
                }}
                onChange={(date) => {
                    date?.isValid() && onFromDateChange(date?.toISOString())
                }}
                onError={onError}
                isReadOnly={false}
            />
            <KeyboardDatePicker
                className={classes.field}
                label="To"
                minDate={dayjs(fromDate)}
                value={dayjs(toDate)}
                slotProps={{
                    textField: { ...dataTestId('DATE_RANGE_TO') },
                }}
                onChange={(date) => {
                    date?.isValid() && onToDateChange(date?.toISOString())
                }}
                onError={onError}
                isReadOnly={false}
            />
        </div>
    )
}
