import axios from 'axios'
import { call, cancelled, put, takeLatest } from 'redux-saga/effects'
import { returnToIssueOwner } from 'api'
import { IssueActionsTypes } from 'types/enums'
import { showSnackbar } from 'store/app'
import { removeMyActionById, returnToIODone, returnToIOFail } from '../actions'
import { MY_ACTIONS_RETURN_TO_IO_CALL } from '../actionTypes'
import { ReturnToIssueOwnerCommand } from '../types'

export function* returnToIssueOwnerSaga() {
    yield takeLatest(
        MY_ACTIONS_RETURN_TO_IO_CALL,
        function* (action: ReturnToIssueOwnerCommand) {
            const cancelSource = axios.CancelToken.source()
            try {
                yield call(
                    returnToIssueOwner,
                    action.payload,
                    cancelSource.token
                )
                yield put(returnToIODone())
                yield put(showSnackbar('Issue was returned to IO'))
                yield put(
                    removeMyActionById(
                        action.payload.issueId,
                        IssueActionsTypes.To_Assign
                    )
                )
            } catch (error) {
                yield put(returnToIOFail(error))
                yield put(showSnackbar('Return issue to IO fail', true))
            } finally {
                if (yield cancelled()) {
                    yield call(cancelSource.cancel, 'Abort')
                }
            }
        }
    )
}
