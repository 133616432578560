import { Typography, TypographyProps } from '@mui/material'
import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import { CSSProperties, FC, ReactNode } from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { EMPTY_VALUE_PLACEHOLDER } from 'utils'

interface LinearContentProps extends TypographyProps<'div'> {
    renderIcon?: (props: CSSProperties) => ReactNode
    label?: string
    additionalStyle?: CSSProperties
}

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        root: {
            margin: theme.spacing(0.5),
        },
    }),
    { name: 'LinearContent' }
)

export const LinearContent: FC<LinearContentProps> = (props) => {
    const {
        className,
        renderIcon,
        label,
        children,
        additionalStyle,
        ...other
    } = props
    const classes = useStyles()
    return (
        <Typography
            component="div"
            className={clsx(classes.root)}
            style={additionalStyle}
            variant="body2"
            {...other}
        >
            {renderIcon &&
                renderIcon({
                    fontSize: 14,
                    marginRight: 8,
                })}
            <b>{label}</b>&nbsp;
            {children ? children : EMPTY_VALUE_PLACEHOLDER}
        </Typography>
    )
}
