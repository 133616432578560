import { Close } from '@mui/icons-material'
import {
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
    addUserToSupportGroup,
    deactivateUserFromSupportGroup,
    getSupportGroup,
} from 'api'
import {
    Button,
    Content,
    EmployeeSelect,
    NoResults,
    PageSection,
    PageTitle,
    SearchResultsHeader,
} from 'components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { showSnackbar } from 'store/app'
import { AbbTheme } from 'styles/createAbbTheme'
import { Employee } from 'types/issueTypes'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        content: {
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${theme.palette.divider}`,
        },
    }),
    { name: 'ManageSupportGroupPage' }
)

export const ManageSupportGroupPage = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const [employee, setEmployee] = useState<Employee>(null)
    const [supportGroup, setSupportGroup] = useState<Employee[]>([])
    const [loading, setLoading] = useState(false)
    const [loadingDesc, setLoadingDesc] = useState('')
    const [error, setError] = useState(null)
    const loadSupportDeskGroup = async () => {
        try {
            setLoading(true)
            setLoadingDesc('Loading support desk group...')
            const { data: group } = await getSupportGroup()
            setSupportGroup(group)
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    useEffect(() => {
        loadSupportDeskGroup()
    }, [])
    const handleAddSupportDesk = async () => {
        try {
            if (!employee?.geid) {
                dispatch(showSnackbar('Select user!', true))
                return
            }
            setLoading(true)
            setLoadingDesc('Adding user to support group...')
            await addUserToSupportGroup(employee.geid)
            setSupportGroup((prev) => [...prev, { ...employee }])
            setEmployee(null)
            dispatch(showSnackbar('New support desk user was added'))
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    const createDeleteFromSupportGroupHandler = (geid: string) => async () => {
        try {
            setLoading(true)
            setLoadingDesc('Deactivate user from support group...')
            await deactivateUserFromSupportGroup(geid)
            setSupportGroup((prev) => {
                const next = [...prev]
                const index = next.findIndex((x) => x.geid === geid)
                next.splice(index, 1)
                return next
            })
            setEmployee(null)
            dispatch(showSnackbar('User was deactivated!'))
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
            setLoadingDesc('')
        }
    }
    return (
        <Content
            loading={loading}
            loadingDesc={loadingDesc}
            error={error}
            onRetry={() => setError(null)}
        >
            <PageTitle title="Manage support desk group" />
            <PageSection
                renderActions={() => (
                    <>
                        <Button onClick={() => setEmployee(null)}>Clear</Button>
                        <Button
                            onClick={handleAddSupportDesk}
                            color="secondary"
                        >
                            Add user
                        </Button>
                    </>
                )}
            >
                <EmployeeSelect
                    label="System user"
                    employee={employee}
                    onEmployeeChanged={setEmployee}
                    required
                />
            </PageSection>
            {0 < supportGroup.length ? (
                <>
                    <SearchResultsHeader
                        isSearching={loading}
                        itemCount={supportGroup.length}
                    />
                    <List className={classes.content}>
                        {supportGroup.map((user) => (
                            <ListItem key={user.geid}>
                                <ListItemText
                                    primary={`${user.email} (${user.fullName})`}
                                    secondary={user?.geid}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        onClick={createDeleteFromSupportGroupHandler(
                                            user.geid
                                        )}
                                        size="large"
                                    >
                                        <Close />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>
                </>
            ) : (
                <NoResults
                    title="No users in support desk group"
                    subTitle="Work in progress"
                />
            )}
        </Content>
    )
}

export default ManageSupportGroupPage
