import { List } from '@mui/material'
import { useMemo } from 'react'
import { Customer } from 'types/issueTypes'
import { dataTestId } from '../../../utils'
import CustomerSearchItem from './CustomerSearchItem'
import CustomerSearchLoadMore from './CustomerSearchLoadMore'
import { CustomerSearchListProps } from './types'

export const CustomerSearchList = (props: CustomerSearchListProps) => {
    const { results, searchingMore, handleLoadMoreResults, onSelect } = props

    const resultList = useMemo(
        () =>
            results.map((customer: Customer, i) => {
                return (
                    <CustomerSearchItem
                        key={customer.guid + i}
                        customer={customer}
                        onSelect={() => onSelect(customer)}
                        {...dataTestId('GUID_' + customer?.guid)}
                    />
                )
            }),
        [results]
    )

    return (
        <>
            <List>{resultList}</List>
            <CustomerSearchLoadMore
                handleLoadMoreResults={handleLoadMoreResults}
                resultsLength={results.length}
                searchingMore={searchingMore}
            />
        </>
    )
}

export default CustomerSearchList
