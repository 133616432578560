import { CircularProgress, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useEffect, useRef, useState } from 'react'
import { useIntersection } from 'react-use'
import { PAGE_SIZE } from '../../../Issue/Pickers/CustomerSearchBase'

const useStyles = makeStyles<Theme>(
    () => ({
        loadMore: {
            display: 'inline-flex',
            height: 56,
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
    { name: 'CustomerSearchLoadMore' }
)

export interface CustomerSearchLoadMoreProps {
    resultsLength: number
    handleLoadMoreResults: () => void
    searchingMore: boolean
}

const CustomerSearchLoadMore = (props: CustomerSearchLoadMoreProps) => {
    const { handleLoadMoreResults, resultsLength, searchingMore } = props
    const isInitialMount = useRef(true)
    const [first, setFirst] = useState(false)
    const intersectionRef = useRef(null)
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1,
    })
    const classes = useStyles()

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
        } else {
            if (intersection && intersection.intersectionRatio < 1) {
            } else {
                if (!first) {
                    setFirst(true)
                } else {
                    if (0 === resultsLength % PAGE_SIZE) {
                        handleLoadMoreResults()
                    }
                }
            }
        }
    }, [intersection])

    return (
        <div ref={intersectionRef}>
            {searchingMore && (
                <div className={classes.loadMore}>
                    <CircularProgress color="secondary" size={36} />
                </div>
            )}
        </div>
    )
}

export default CustomerSearchLoadMore
