import ChipBase, { ChipProps } from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import isEmpty from 'lodash/isEmpty'
import { AbbTheme } from 'styles/createAbbTheme'

const useStyles = makeStyles(
    (theme: AbbTheme) => ({
        Root: {
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            ...theme.typography.caption,
        },
    }),
    { name: 'FieldChip' }
)

export interface FieldChipProps extends ChipProps {
    label: string
    hint?: string
}

export const FieldChip = (props: FieldChipProps) => {
    const { hint, ...other } = props
    const classes = useStyles()
    return isEmpty(hint) ? (
        <ChipBase {...other} className={classes.Root} variant="outlined" />
    ) : (
        <Tooltip title={hint} disableFocusListener disableTouchListener>
            <ChipBase {...other} className={classes.Root} variant="outlined" />
        </Tooltip>
    )
}

export default FieldChip
