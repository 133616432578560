import { makeStyles } from '@mui/styles'
import { AbbTheme } from 'styles/createAbbTheme'

export const useTilesStyles = makeStyles(
    (theme: AbbTheme) => ({
        dataArea: {
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            '@media (max-width: 480px)': {
                flexDirection: 'column',
            },
        },
        singleRow: {
            marginTop: theme.spacing(1.5),
            width: '100%',
        },
        cell: {
            display: 'flex',
            width: `calc(50% - ${theme.spacing(1.5)})`,
            padding: theme.spacing(0, 1.5, 0, 0),
            marginTop: theme.spacing(1.5),
            '&:nth-child(even)': {
                padding: theme.spacing(0, 0, 0, 1.5),
            },
            '@media (max-width: 480px)': {
                padding: 0,
                width: '100%',
                '&:nth-child(even)': {
                    padding: 0,
                },
            },
        },
        labelIcon: {
            width: 16,
            height: 16,
            marginRight: theme.spacing(1),
        },
        risk: {
            ...theme.typography.body2,
        },
        unsupport: {
            marginRight: theme.spacing(1),
            fontSize: 12,
            color: theme.palette.grey[400],
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
        },
    }),
    { name: 'Tile' }
)
