import axios, { AxiosResponse, CancelToken } from 'axios'
import {
    CreateCustomerContactCommand,
    CustomerContact,
    UpdateCustomerContactCommand,
} from 'types/issueTypes'

const controllerName = '/customer-contacts'

/**
 * @param {string} customerIdOrGuid - customer name
 * @param {string} searchPhrase - customer name
 * @param {CancelToken} cancelToken - Axios CancelToken
 */
export const searchContactsNames = (
    customerIdOrGuid: string,
    searchPhrase: string,
    issueId: number | null = null,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<Array<CustomerContact>>> =>
    axios.post(
        `${controllerName}/search`,
        {
            searchPhrase,
            customerIdOrGuid,
            issueId,
        },
        { cancelToken }
    )

export const createContactName = (
    customer: CreateCustomerContactCommand,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<CustomerContact>> =>
    axios.post(
        `${controllerName}`,
        {
            ...customer,
        },
        { cancelToken }
    )

export const updateContactName = (
    customerContactId: string,
    customer: UpdateCustomerContactCommand,
    cancelToken: CancelToken = null
): Promise<AxiosResponse<CustomerContact>> =>
    axios.put(
        `${controllerName}/${customerContactId}`,
        {
            ...customer,
        },
        { cancelToken }
    )
