import ExpandIcon from '@mui/icons-material/ExpandMore'
import { Collapse, Typography, useTheme } from '@mui/material'
import { AttachmentTypes, IssueTypes } from 'types/enums'
import { Attachment, CaptureSection } from 'types/issueTypes'
import Accordion from '../../components/Base/Accordion'
import AccordionDetails from '../../components/Base/AccordionDetails'
import AccordionSummary from '../../components/Base/AccordionSummary'
import AdditionalProductInformation from './AdditionalProductInformation'
import CaptureAttachments from './CaptureAttachments'
import CustomerSelection from './CustomerSelection'
import ImpactClassification from './ImpactClassification'
import InformationAboutIssue from './InformationAboutIssue'
import IssueOwnerAndResolvingUnit from './IssueOwnerAndResolvingUnit'
import ProductAndSubjectInformation from './ProductAndSubjectInformation/ProductAndSubjectInformation'
import TargetDateAndContainmentActions from './TargetDateAndContainmentActions'

import IssueTypeSection from './IssueTypeSection'
import { CAPTURE_SECTION_ID } from './utils'

export interface CaptureProps {
    expanded: boolean
    onExpand: (expanded: boolean) => void
    disabled: boolean
    captureSection: CaptureSection
    attachments: Array<Attachment>
    [key: string]: any
}

const Capture = (props: CaptureProps) => {
    const {
        captureSection,
        className,
        disabled,
        expanded,
        attachments = [],
        onExpand,
        ...other
    } = props

    const theme = useTheme()
    const style = { marginTop: theme.spacing(2) }
    const { issueType } = captureSection
    return (
        <Accordion
            id={CAPTURE_SECTION_ID}
            expanded={expanded}
            onChange={(event, value) => onExpand && onExpand(value)}
            {...other}
            className={className}
        >
            <AccordionSummary expandIcon={<ExpandIcon />}>
                <Typography variant="h5">Issue capture</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <IssueTypeSection
                    issueType={captureSection?.issueType}
                    issueCreationType={captureSection?.issueCreationType}
                />
                <InformationAboutIssue
                    style={{ ...style }}
                    issueType={captureSection?.issueType}
                    captureSection={captureSection}
                />
                <Collapse in={issueType !== IssueTypes.Internal_NC}>
                    <ImpactClassification
                        style={{ ...style }}
                        disabled={disabled}
                        highImpact={captureSection?.highImpact}
                    />
                </Collapse>
                <TargetDateAndContainmentActions
                    style={{ ...style }}
                    disabled={disabled}
                    issueType={issueType}
                    captureSection={captureSection}
                />
                <Collapse in={issueType !== IssueTypes.Internal_NC}>
                    <CustomerSelection
                        style={{ ...style }}
                        disabled={disabled}
                        issueType={captureSection?.issueType}
                        customer={captureSection?.customer}
                        customerContact={captureSection?.customerContact}
                    />
                </Collapse>
                <ProductAndSubjectInformation
                    style={{ ...style }}
                    disabled={disabled}
                    captureSection={captureSection}
                    resolvingUnit={captureSection?.resolvingUnit ?? null}
                    failureModeName={captureSection?.failureModeName ?? null}
                    failureModeDescription={
                        captureSection?.failureModeDescription ?? null
                    }
                    productName={captureSection?.product?.name ?? null}
                />
                <AdditionalProductInformation
                    style={{ ...style }}
                    disabled={disabled}
                    captureSection={captureSection}
                />

                <CaptureAttachments
                    style={{ ...style }}
                    disabled={disabled}
                    issueId={captureSection?.issueId}
                    attachments={
                        attachments?.filter(
                            (a) =>
                                a.attachmentType === AttachmentTypes.Information
                        ) ?? []
                    }
                />
                <IssueOwnerAndResolvingUnit
                    style={{ ...style }}
                    disabled={disabled}
                    issueOwner={captureSection?.issueOwner}
                    issueOwnerTeam={captureSection?.issueOwnerTeam ?? []}
                    isCopySentToParties={Boolean(
                        captureSection?.isCopySentToParties
                    )}
                    proposedResolutionOwner={
                        captureSection?.proposedResolutionOwner
                    }
                    partiesReference={captureSection?.partiesReference}
                />
            </AccordionDetails>
        </Accordion>
    )
}

Capture.defaultProps = {
    disabled: true,
}

export default Capture
