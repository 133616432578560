import {
    createAdminFailureModes,
    deleteAdminFailureModes,
    getAdminFailureModes,
    updateAdminFailureModes,
} from 'api'
import { TreeViewManagePage } from '../components/TreeViewManagePage'

export const FailureModesManagePage = () => {
    return (
        <TreeViewManagePage
            title="Failure modes"
            desc="This panel allows you to manage failure modes for Product Groups."
            searchTitle="Search Failure modes"
            nodeNameLabel="Failure mode name"
            getNodes={getAdminFailureModes}
            createNode={createAdminFailureModes}
            deleteNode={deleteAdminFailureModes}
            updateNode={updateAdminFailureModes}
            canEditAllNodes={false}
            to="https://abb.sharepoint.com/:p:/r/sites/NPS/CCRP/_layouts/15/Doc.aspx?sourcedoc=%7B3AD3AAFC-3ABC-40C6-802D-53C8038A29E0%7D&file=CCRP%20Managing%20Failure%20Mode.pptx&action=edit&mobileredirect=true"
        />
    )
}
