import { makeStyles } from '@mui/styles'
import clsx from 'clsx'
import {
    CompositeDecorator,
    ContentState,
    Editor,
    EditorState,
    getDefaultKeyBinding,
    Modifier,
    RichUtils,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import {
    HTMLAttributes,
    KeyboardEvent,
    useEffect,
    useRef,
    useState,
} from 'react'
import { AbbTheme } from 'styles/createAbbTheme'
import { dataTestId } from 'utils'
import { DraftHyperLink, findLinkEntities } from './decorators'
import { FormatRibbon } from './FormatRibbon'
import { FillTextColorInlineStyles, TextColorInlineStyles } from './types'
import { getInlineStylesMap, getInlineStylesMapForHtml } from './utils'

export interface RichTextEditorProps extends HTMLAttributes<HTMLDivElement> {
    value?: string
    classNameContent?: string
    onEditorBlur?: (value: string) => void
    onStateChange?: (state: EditorState, value: string) => void
}

const useStyles = makeStyles(
    (theme: AbbTheme) => {
        const addCursor = () => ({
            '&::before': {
                display: 'block',
                content: '"—"',
                fontSize: 'inherit',
                color: theme.palette.secondary.main,
            },
        })
        return {
            root: {
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                paddingBottom: theme.spacing(1),
                position: 'relative',
                '& h1': {
                    ...theme.typography.h1,
                    ...addCursor(),
                },
                '& h2': {
                    ...theme.typography.h2,
                    ...addCursor(),
                },
                '& h3': {
                    ...theme.typography.h3,
                    ...addCursor(),
                },
                '& h4': {
                    ...theme.typography.h4,
                    ...addCursor(),
                },
                '& h5': {
                    ...theme.typography.h5,
                },
                '& h6': {
                    ...theme.typography.h6,
                },
            },
            ribbon: {
                marginBottom: theme.spacing(1),
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
            },
            content: {
                display: 'flex',
                position: 'relative',
                minHeight: '50%',
                flex: 1,
                margin: theme.spacing(1),
                cursor: 'text',
            },
            block: {
                '&$center': {
                    textAlign: 'center !important' as any,
                },
                '&$left': {
                    textAlign: 'left !important' as any,
                },
                '&$right': {
                    textAlign: 'right !important' as any,
                },
            },
            center: {},
            left: {},
            right: {},
        }
    },
    { name: 'RichTextEditor' }
)

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: DraftHyperLink,
    },
])

export const RichTextEditor = (props: RichTextEditorProps) => {
    const {
        className,
        classNameContent,
        value = null,
        onEditorBlur,
        onStateChange,
        ...other
    } = props
    const editorRef = useRef<Editor>()
    const classes = useStyles()

    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty(decorator)
    )
    useEffect(() => {
        if (value) {
            setEditorState(
                EditorState.createWithContent(stateFromHTML(value), decorator)
            )
            onEditorBlur(
                getValue(
                    EditorState.createWithContent(
                        stateFromHTML(value),
                        decorator
                    )
                )
            )
        } else {
            setEditorState(EditorState.createEmpty(decorator))
        }
    }, [value])
    useEffect(() => {
        ;[
            ...document.getElementsByClassName('public-DraftStyleDefault-ltr'),
        ].forEach((e) => e.classList.toggle('public-DraftStyleDefault-ltr'))
    }, [editorState])
    const getValue: (state: EditorState) => string = (state) =>
        stateToHTML(state.getCurrentContent(), {
            inlineStyles: {
                BOLD: { element: 'b' },
                ...getInlineStylesMapForHtml(),
            },
            blockStyleFn: (block) => {
                const aligment = block.getData().get('aligment')
                return aligment ? { style: { textAlign: aligment } } : {}
            },
        })

    const handleStateChange = (state: EditorState) => {
        onStateChange && onStateChange(state, getValue(state))
        setEditorState(state)
    }
    const handleEditorBlur = () =>
        onEditorBlur && onEditorBlur(getValue(editorState))
    const handleUndo = () => setEditorState(EditorState.undo(editorState))
    const handleRedo = () => setEditorState(EditorState.redo(editorState))
    const handleBold = () =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
    const handleUnderLine = () =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
    const handleItalic = () =>
        setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
    const handleStrikethrough = () =>
        setEditorState(
            RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH')
        )
    const handleBulletList = () =>
        setEditorState(
            RichUtils.toggleBlockType(editorState, 'unordered-list-item')
        )
    const handleNumberList = () => {
        setEditorState(
            RichUtils.toggleBlockType(editorState, 'ordered-list-item')
        )
    }
    const handleIncreaseListIndent = (e: any) => {
        setEditorState(RichUtils.onTab(e, editorState, 3))
    }
    const handleDecreaseListIndent = (e: any) => {
        e.shiftKey = true
        setEditorState(RichUtils.onTab(e, editorState, 3))
    }
    const handleTypographySelect = (blockType: string) =>
        setEditorState(RichUtils.toggleBlockType(editorState, blockType))
    const blockAligment = (aligment: string) => {
        let newContentState: ContentState = null
        newContentState = Modifier.mergeBlockData(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            { aligment } as any
        )
        setEditorState(
            EditorState.push(editorState, newContentState, 'change-block-data')
        )
    }
    const handleLeftText = () => blockAligment('left')
    const handleCenterText = () => blockAligment('center')
    const handleRightText = () => blockAligment('right')
    const handleKeyBinding = (e: KeyboardEvent): string => {
        if (e.key !== 'Tab') {
            return getDefaultKeyBinding(e)
        }
        e.preventDefault()
        let currentState = editorState
        const selection = currentState.getSelection()
        const blockType = currentState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType()
        if (
            blockType === 'unordered-list-item' ||
            blockType === 'ordered-list-item'
        ) {
            setEditorState(RichUtils.onTab(e, currentState, 1))
        } else {
            let newContentState = Modifier.replaceText(
                currentState.getCurrentContent(),
                currentState.getSelection(),
                '    '
            )
            setEditorState(
                EditorState.push(
                    currentState,
                    newContentState,
                    'insert-characters'
                )
            )
        }
        return getDefaultKeyBinding(e)
    }
    const handleInlineStyleToggle = (
        customInlineStyle: TextColorInlineStyles | FillTextColorInlineStyles
    ) =>
        setEditorState(
            RichUtils.toggleInlineStyle(editorState, customInlineStyle)
        )
    const handleTagAdd = (tag: string) => {
        let currentState = editorState
        let newContentState = Modifier.insertText(
            currentState.getCurrentContent(),
            currentState.getSelection(),
            tag
        )

        setEditorState(
            EditorState.push(currentState, newContentState, 'insert-characters')
        )
    }
    return (
        <div className={clsx(className, classes.root)} {...other}>
            <FormatRibbon
                className={classes.ribbon}
                editorState={editorState}
                onUndo={handleUndo}
                onRedo={handleRedo}
                onBold={handleBold}
                onUnderline={handleUnderLine}
                onItalic={handleItalic}
                onStrikethrough={handleStrikethrough}
                onLeftText={handleLeftText}
                onCenterText={handleCenterText}
                onRightText={handleRightText}
                onBulletList={handleBulletList}
                onNumberList={handleNumberList}
                onIncreaseListIndent={handleIncreaseListIndent}
                onDecreaseListIndent={handleDecreaseListIndent}
                onTypographySelect={handleTypographySelect}
                onTextColorSelect={handleInlineStyleToggle}
                onFillTextColorSelect={handleInlineStyleToggle}
                onTagAdd={handleTagAdd}
            />
            <div
                className={clsx(classNameContent, classes.content)}
                onClick={() => editorRef.current?.focus()}
                {...dataTestId('EMAIL_CONTENT')}
            >
                <Editor
                    ref={editorRef}
                    editorState={editorState}
                    onChange={handleStateChange}
                    customStyleMap={getInlineStylesMap()}
                    blockStyleFn={(block) => {
                        const aligment = block.getData().get('aligment')
                        return clsx(classes.block, classes[aligment])
                    }}
                    onBlur={handleEditorBlur}
                    keyBindingFn={handleKeyBinding}
                />
            </div>
        </div>
    )
}
